// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { GatewayFile } from "../../GatewayInterface.type";

@Component({
  selector: "app-files",
  templateUrl: "./gateway-files.component.html",
  styleUrls: ["./gateway-files.component.scss"],
})
export class FilesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 10;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("6");
  gatewayId: number;
  unidadVenta: string;
  gatewayFiles: GatewayFile[];
  orderBy: object = { attribute: "date", reverse: true };
  from: string;
  to: string;
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "file-state",
      data: "fileStateParsed",
      search: "fileStateParsed",
      sort: "fileStateParsed",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayController: GatewayControllerService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.GatewayController.getGatewayFiles(
      this.gatewayId,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let gatewayFiles: GatewayFile[] = response["body"];
          gatewayFiles.forEach((file: GatewayFile) => {
            file.fileStateParsed =
              file.fileState != null
                ? this.translate.instant("fileState" + file.fileState)
                : this.translate.instant("unknown");
          });
          this.gatewayFiles = gatewayFiles;
        } else {
          this.gatewayFiles = [];
        }
      }
    });
  }
}
