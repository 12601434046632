// @angular
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MetrologicalControllerService } from "../../../../../services/server/MetrologicalController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import {
  Metrological,
  MetrologicalDevice,
} from "../../DataManagementInterface.type";

@Component({
  selector: "app-userform",
  templateUrl: "./data-management-metrological-form.component.html",
  styleUrls: ["./data-management-metrological-form.component.scss"],
})
export class MetrologicalFormComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  sessionProfile: string;

  // Metrológica
  metrologic: Metrological = new Metrological();
  meterList: MetrologicalDevice[];
  serialNumberLabel: string;
  metrologicReady: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private location: Location,
    private DataAnalysisController: DataAnalysisControllerService,
    private MetrologicalController: MetrologicalControllerService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let serialNumberLabel: string = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 100
    )?.label;
    this.serialNumberLabel = serialNumberLabel
      ? serialNumberLabel
      : "NRO_SERIE";
    this.metrologic.contador = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.metrologic.contadorNroSerie = history.state.data;
    if (this.metrologic.contador) {
      this.getData();
    } else {
      this.getMeterList();
    }
  }

  // Obtención de los datos de metrológica
  getData(): void {
    this.MetrologicalController.getDeviceMetrological(
      this.metrologic.contador
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]) {
          this.metrologic = response["body"];
        }
        this.metrologicReady = true;
      }
    });
  }

  // Obtención de la lista de contadores
  getMeterList(): void {
    this.DataAnalysisController.getMetersDropdown(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let meterList: MetrologicalDevice[] = response["body"]["nroSerieList"];
        meterList.sort((a, b) => {
          return a.nroSerie.localeCompare(b.nroSerie);
        });
        this.meterList = meterList;
        this.metrologicReady = true;
      }
    });
  }

  // Guardar metrología
  saveMetrologic(): void {
    if (!this.metrologic.contadorNroSerie) {
      this.metrologic.contadorNroSerie = this.meterList.find(
        (meter: MetrologicalDevice) => meter.id == this.metrologic.contador
      ).nroSerie;
    }

    this.MetrologicalController.saveMetrological(this.metrologic).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
        }
      }
    );
  }
}
