import { Component, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { CupsControllerService } from "../../../../../../services/server/CupsController.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";

@Component({
  selector: "app-device-cups-dialog",
  templateUrl: "./device-cups-dialog.component.html",
  styleUrls: ["./device-cups-dialog.component.scss"],
})
export class DeviceCupsDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  assignedCups: number;
  saveDisabled: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Asignación de CUPS existente
  assignCups(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("assign-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CupsController.meterAsignate(
          this.assignedCups,
          this.data.meterId
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Edición de CUPS asociado al contador
  editCups(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("edit-extra-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        if (!this.data.newCups.clave) {
          this.data.newCups.clave = this.data.meter.nroSerie;
        }
        let cleanCups: any = { ...this.data.newCups };
        delete cleanCups.selected;
        delete cleanCups.selectedBis;
        delete cleanCups.cups;
        delete cleanCups.showExtraInfo;
        delete cleanCups.meterLink;
        delete cleanCups.meterNroSerie;
        delete cleanCups.highlightClass;

        this.MeterController.editExtra(cleanCups).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("edit-extra-sucessfull")
            );
            this.SessionDataService.sendUpdateSearchBoxFlag();
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Nuevo CUPS
  newCups(): void {
    let data = {};
    data["clave"] = this.data.newCups.clave;
    data["entity"] = this.SessionDataService.getCurrentEntity()?.id;
    for (let element in this.data.newCups) {
      if (element.includes("col")) {
        data[element] = this.data.newCups[element];
      }
    }
    this.CupsController.createEntityCups(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendUpdateSearchBoxFlag();
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
