<!-- ANCHOR Mapa con opciones -->
<div
  *ngIf="!mapOnly"
  #mapContainer
  id="map-container"
  leaflet
  [leafletOptions]="mapOptions"
  [leafletLayersControl]="mapLayersControl"
  [leafletLayers]="mapLayers"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="
    showContextMenu = false;
    mapType == 'changeLocation'
      ? updateDeviceLocation($event)
      : mapType != 'coverageMap'
      ? resetMap()
      : null
  "
  (leafletDoubleClick)="openStreetView($event)"
  [leafletFitBounds]="fitBounds"
  (leafletMapMove)="moveFlag()"
>
  <div *ngIf="mapLoading" class="map-loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</div>

<!-- ANCHOR Mapa sin opciones -->
<div
  *ngIf="mapOnly"
  #mapContainer
  id="map-container"
  leaflet
  [leafletOptions]="mapOptions"
  [leafletLayers]="mapLayers"
  (leafletMapReady)="onMapReady($event)"
  (leafletClick)="
    mapType == 'changeLocation' ? updateDeviceLocation($event) : resetMap()
  "
  [leafletFitBounds]="fitBounds"
  (leafletMapMove)="moveFlag()"
>
  <div *ngIf="mapLoading" class="map-loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</div>

<leaflet-fullscreen-control
  *ngIf="!hideFullscreen"
  [map]="map"
  [options]="fullscreenOptions"
>
</leaflet-fullscreen-control>
