import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";

// Rutas sin redirección por cambio de agrupación, entidad o cliente
const ROUTES_WITHOUT_REDIRECTION: any = {
  agrupation: [
    "/principal",
    "/gateways/listado/asignados",
    "/gateways/detalle/",
    "/gateways/appeui",
    "/cobertura/mapa",
    "/cobertura/test",
    "/contraste-cobertura/",
    "/gestion-datos/",
    "/dispositivos/listado/interno",
    "/estado-red/agrupacion",
    "/estado-red/entidad",
    "/mantenimiento/",
    "/telecontrol",
    "/estado-red/kpis",
    "/listado/bateria-baja",
    "/analisis-datos/consultas",
    "/sensorica/fugas",
    "/analisis-datos/modelos-analiticos/",
    "/mantenimiento/reporte-comunicaciones",
    "/ficheros/aqualia",
    "/ficheros/plantillas/listado",
  ],
  entity: [
    "/principal",
    "/mantenimiento/",
    "/estado-red/entidad",
    "/entidades/listado",
    "/telecontrol",
    "/listado/bateria-baja",
    "/analisis-datos/consultas",
    "/listado/fabricantes-mbus/",
    "/listado/desconocidos-mbus",
    "/gateways/listado/en-stock",
    "/gateways/listado/disponibles",
    "/gateways/listado/asignados",
    "/mantenimiento/editar-agrupaciones",
    "/ficheros/descarga",
    "/ficheros/plantillas",
  ],
  client: [
    "/principal",
    "/mantenimiento/global",
    "/telecontrol",
    "/listado/bateria-baja",
    "/analisis-datos/consultas",
    "/ficheros/horarios",
  ],
};

@Injectable({
  providedIn: "root",
})
export class RouteCheckService {
  constructor(
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  // Comprobación de la ruta para redirigir a /principal
  stayOnRoute(type: string): boolean {
    let loading: boolean =
      this.SessionDataService.getCurrentSessionLoadingFlag();
    if (!this.router.url.includes("/principal") && !loading) {
      let stayOnRoute: boolean = false;
      ROUTES_WITHOUT_REDIRECTION[type].forEach((route: string) => {
        if (this.router.url.includes(route)) {
          stayOnRoute = true;
        }
      });
      return stayOnRoute;
    } else {
      return true;
    }
  }
}
