import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Interfaces
import { RecordData } from "./screen-recorder.type";

const MEDIA_DEVICES = navigator.mediaDevices as any;
declare var MediaRecorder: any;

@Component({
  selector: "app-screen-recorder",
  templateUrl: "./screen-recorder.component.html",
  styleUrls: ["./screen-recorder.component.scss"],
})
export class ScreenRecorderComponent implements OnDestroy {
  recorder: any;
  stream: any;
  frameCount: number = 0;
  blobarr: any[] = [];
  finalBlob: Blob | null = null;
  isRecording: boolean = false;
  @Input() recordData: RecordData;
  @Input() recorderType: string;
  @Output() recording = new EventEmitter<boolean>();

  constructor(private translate: TranslateService) {}

  ngOnDestroy(): void {
    this.blobarr.length = 0;
    if (this.isRecording) {
      this.recordStop();
    }
  }

  async startRecording() {
    var options;

    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      options = {
        videoBitsPerSecond: 2500000,
        mimeType: "video/webm; codecs=vp9",
      };
    } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
      options = {
        videoBitsPerSecond: 2500000,
        mimeType: "video/webm; codecs=vp8",
      };
    } else {
      options = { videoBitsPerSecond: 2500000, mimeType: "video/webm" };
    }

    try {
      this.stream = await MEDIA_DEVICES.getDisplayMedia({
        screen: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 },
          frameRate: { min: 10, ideal: 20, max: 25 },
        },
      });
    } catch (err) {
      this.isRecording = false;
    }

    if (this.stream) {
      this.recorder = new MediaRecorder(this.stream, options);
      let metadata: any;

      this.frameCount = 0;

      this.recorder.ondataavailable = (e: { data: any }) => {
        this.blobarr.push(e.data);
        this.frameCount += 1;
      };

      this.recorder.addEventListener("stop", () => {
        this.stream.getTracks().forEach(function (track: any) {
          track.stop();
        });
        this.isRecording = false;
      });

      this.recorder.start(500);
      this.recording.emit(true);
    }
  }

  downloadBlob() {
    let downloadLink = document.createElement("a");
    let downloadFile =
      this.translate.instant("screen-capture") +
      (this.recordData.fileName ? "-" + this.recordData.fileName : "") +
      (this.recordData.startDate ? "-" + this.recordData.startDate : "") +
      (this.recordData.endDate &&
      this.recordData.endDate != this.recordData.startDate
        ? "-" + this.recordData.endDate
        : "") +
      ".webm";
    downloadLink.href = window.URL.createObjectURL(
      new Blob(this.blobarr, { type: this.blobarr[0].type })
    );
    downloadLink.setAttribute("download", downloadFile);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 0);
  }

  recordStop() {
    if (this.recorder) {
      this.recorder.stop();
      this.recording.emit(false);
    }
  }

  recordStart() {
    this.isRecording = true;
    this.blobarr.length = 0;
    this.startRecording();
  }
}
