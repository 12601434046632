export const MAP_COLORS = {
  // Marcadores de mapa
  "marker-red": "rgba(211, 47, 47, 1)",
  "marker-green": "rgba(67, 160, 71, 1)",
  "marker-yellow": "rgba(255, 235, 59, 1)",
  "marker-orange": "#854000",
  "marker-limeGreen": "limeGreen",
  "marker-blue": "rgba(25, 118, 210, 1)",
  "marker-purple": "rgba(171, 71, 188, 1)",
  "marker-turquoise": "rgb(42, 192, 202)",
  "marker-gray": "rgba(158, 158, 158, 1)",
  "marker-cadetblue": "#5f9ea0",

  // Clúster
  "marker-cluster-lightRed": "rgba(255, 102, 89, 0.75)",
  "marker-cluster-lightOrange": "rgba(255, 169, 89, 0.75)",
  "marker-cluster-lightGreen": "rgba(102, 187, 106, 0.75)",
  "marker-cluster-lightPurple": "rgba(206, 147, 216, 0.75)",
  "marker-cluster-lightGray": "rgba(189, 189, 189, 0.75)",
  "marker-cluster-lightWhite": "rgba(255, 255, 255, 0.75)",
  "marker-cluster-lightYellow": "rgba(255, 244, 144, 0.75)",
  "marker-cluster-lightBlue": "rgba(187, 222, 251, 0.75)",
  "marker-cluster-gateway": "rgb(124, 223, 218)",
  "marker-cluster-lightCadetblue": "rgba(95, 158, 160, 0.5)",

  // Gradientes
  "marker-gradient-green": "#43a047",
  "marker-gradient-turquoise": "#06b8af",
  "marker-gradient-purple": "#ab47bc",
  "marker-gradient-red": "#d32f2f",
  "marker-gradient-blue": "#1976d2",
  "marker-gradient-yellow": "#ffae00",
  "marker-gradient-orange": "#854000",
  "marker-gradient-cadetBlue": "#5f9ea0",
};

export const SHAPE_COLORS = {
  polygon: "LightBlue",
  polygonLine: "Gray",
  line: "Blue",
  point: "Turquoise",
};

export const RANDOM_COLORS = [
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
  "#250662",
  "#cb5bea",
  "#228916",
  "#ac3e1b",
  "#df514a",
  "#539397",
  "#880977",
  "#f697c1",
  "#ba96ce",
  "#679c9d",
  "#c6c42c",
  "#5d2c52",
  "#48b41b",
  "#e1cf3b",
  "#5be4f0",
  "#57c4d8",
  "#a4d17a",
  "#be608b",
  "#96b00c",
  "#088baf",
  "#f158bf",
  "#e145ba",
  "#ee91e3",
  "#05d371",
  "#5426e0",
  "#4834d0",
  "#802234",
  "#6749e8",
  "#0971f0",
  "#8fb413",
  "#b2b4f0",
  "#c3c89d",
  "#c9a941",
  "#41d158",
  "#fb21a3",
  "#51aed9",
  "#5bb32d",
  "#807fb",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#1c271",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#1c271",
  "#9cb64a",
  "#996c48",
  "#9ab9b7",
  "#06e052",
  "#e3a481",
  "#0eb621",
  "#fc458e",
  "#b2db15",
  "#aa226d",
  "#792ed8",
  "#73872a",
  "#520d3a",
  "#cefcb8",
  "#a5b3d9",
  "#7d1d85",
  "#c4fd57",
  "#f1ae16",
  "#8fe22a",
  "#ef6e3c",
  "#243eeb",
  "#1dc18",
  "#dd93fd",
  "#3f8473",
  "#e7dbce",
  "#421f79",
  "#7a3d93",
  "#635f6d",
  "#93f2d7",
  "#9b5c2a",
  "#15b9ee",
  "#0f5997",
  "#409188",
  "#911e20",
  "#1350ce",
  "#10e5b1",
  "#fff4d7",
  "#cb2582",
  "#ce00be",
  "#32d5d6",
  "#17232",
  "#608572",
  "#c79bc2",
  "#00f87c",
  "#77772a",
  "#6995ba",
  "#fc6b57",
  "#f07815",
  "#8fd883",
  "#060e27",
  "#96e591",
  "#21d52e",
  "#d00043",
  "#b47162",
  "#1ec227",
  "#4f0f6f",
  "#1d1d58",
  "#947002",
  "#bde052",
  "#e08c56",
  "#28fcfd",
  "#bb09b",
  "#36486a",
  "#d02e29",
  "#1ae6db",
  "#3e464c",
  "#a84a8f",
  "#911e7e",
  "#3f16d9",
  "#0f525f",
  "#ac7c0a",
  "#b4c086",
  "#c9d730",
  "#30cc49",
  "#3d6751",
  "#fb4c03",
  "#640fc1",
  "#62c03e",
  "#d3493a",
  "#88aa0b",
  "#406df9",
  "#615af0",
  "#4be47",
  "#2a3434",
  "#4a543f",
  "#79bca0",
  "#a8b8d4",
  "#00efd4",
  "#7ad236",
  "#7260d8",
  "#1deaa7",
  "#06f43a",
  "#823c59",
  "#e3d94c",
  "#dc1c06",
  "#f53b2a",
  "#b46238",
  "#2dfff6",
  "#a82b89",
  "#1a8011",
  "#436a9f",
  "#1a806a",
  "#4cf09d",
  "#c188a2",
  "#67eb4b",
  "#b308d3",
  "#fc7e41",
  "#af3101",
  "#ff065",
  "#71b1f4",
  "#a2f8a5",
  "#e23dd0",
  "#d3486d",
  "#00f7f9",
  "#474893",
  "#3cec35",
  "#1c65cb",
  "#5d1d0c",
  "#2d7d2a",
  "#ff3420",
  "#5cdd87",
  "#a259a4",
  "#e4ac44",
  "#1bede6",
  "#8798a4",
  "#d7790f",
  "#b2c24f",
  "#de73c2",
  "#d70a9c",
  "#25b67",
  "#88e9b8",
  "#c2b0e2",
  "#86e98f",
  "#ae90e2",
  "#1a806b",
  "#436a9e",
  "#0ec0ff",
  "#f812b3",
  "#b17fc9",
  "#8d6c2f",
  "#d3277a",
  "#2ca1ae",
  "#9685eb",
  "#8a96c6",
  "#dba2e6",
  "#76fc1b",
  "#608fa4",
  "#20f6ba",
  "#07d7f6",
  "#dce77a",
  "#77ecca",
];
//   "#FFFFFF",
//   "#FCFD0C",
//   "#FCA4FA",
//   "#09DAFC",
//   "#D86606",
//   "#03883F",
//   "#153CFD",
//   "#AB0344",
//   "#00FBB4",
//   "#F9D2C0",
//   "#5D4C01",
//   "#7D7B86",
//   "#0D5252",
//   "#A29204",
//   "#188EF7",
//   "#370257",
//   "#F4717C",
//   "#D90DCB",
//   "#9BB2A1",
//   "#7F2B00",
//   "#6DC50B",
//   "#B8CBFB",
//   "#034204",
//   "#058E9E",
//   "#534049",
//   "#FEA912",
//   "#F3FDFB",
//   "#977DEA",
//   "#06335A",
//   "#E60E1F",
//   "#676E58",
//   "#2F0102",
//   "#E3FEBC",
//   "#725493",
//   "#A9937C",
//   "#926455",
//   "#FDE5FE",
//   "#03669E",
//   "#B579A0",
//   "#382000",
//   "#0BB687",
//   "#930177",
//   "#D8C37C",
//   "#FE966F",
//   "#3D3E2E",
//   "#A0F8ED",
//   "#9D6B06",
//   "#63760A",
//   "#B5AAB6",
//   "#ED167D",
//   "#879D5C",
//   "#001C07",
//   "#117B6B",
//   "#EFAFBB",
//   "#90556F",
//   "#83ADC0",
//   "#22172C",
//   "#516B73",
//   "#1BB8B6",
//   "#5B0036",
//   "#9102CC",
//   "#0B0AB0",
//   "#03FC4E",
//   "#700717",
//   "#01252D",
//   "#B48B8A",
//   "#CEC8B8",
//   "#798A86",
//   "#96CB8B",
//   "#FD5AC2",
//   "#BAC500",
//   "#41474F",
//   "#6572A7",
//   "#6A5F58",
//   "#C48B47",
//   "#C0525B",
//   "#A45211",
//   "#AE9FD3",
//   "#FED019",
//   "#D06E55",
//   "#C0F85A",
//   "#3E4F9B",
//   "#2F613F",
//   "#603D2E",
//   "#877150",
//   "#BCEBFE",
//   "#619376",
//   "#D96DFA",
//   "#1C6CFE",
//   "#5A3161",
//   "#02B1FA",
//   "#08AB3C",
//   "#016800",
//   "#A81C1F",
//   "#9C4AFE",
//   "#E6B380",
//   "#85A1FB",
//   "#CBE7D1",
//   "#455315",
//   "#723542",
//   "#00073A",
//   "#9072AD",
//   "#32211F",
//   "#5E5E74",
//   "#28DCC1",
//   "#FEF2CC",
//   "#A7539A",
//   "#C4CDD5",
//   "#175066",
//   "#764910",
//   "#FE4209",
//   "#8C8B6F",
//   "#0AD36D",
//   "#8091AB",
//   "#FC8A26",
//   "#533391",
//   "#91CACA",
//   "#A1A29E",
//   "#4B9002",
//   "#1D3F2E",
//   "#404060",
//   "#F8F08E",
//   "#2E82AA",
//   "#D2A716",
//   "#EB88B0",
//   "#7C6C1B",
//   "#201C07",
//   "#F1E6E5",
//   "#D6A593",
//   "#7E676E",
//   "#DFC4FA",
//   "#03727A",
//   "#AB9C63",
//   "#88A301",
//   "#BF0172",
//   "#FE0E55",
//   "#695BD0",
//   "#283104",
//   "#65814C",
//   "#1A988C",
//   "#531A02",
//   "#CFA4C6",
//   "#AA744D",
//   "#B6BD91",
//   "#485A51",
//   "#8B7C75",
//   "#614F34",
//   "#A9F5BE",
//   "#A66D76",
//   "#9B879A",
//   "#8BCEFE",
//   "#03B0CA",
//   "#BFD579",
//   "#232730",
//   "#858440",
//   "#72A3A2",
//   "#CD4302",
//   "#17E4E7",
//   "#88463D",
//   "#FE9A92",
//   "#6B516E",
//   "#161F70",
//   "#B5513D",
//   "#2F002B",
//   "#C899FE",
//   "#44202D",
//   "#80C4A6",
//   "#58776E",
//   "#D46483",
//   "#880D4B",
//   "#68017C",
//   "#A3AEC4",
//   "#C286CC",
//   "#E2E5FC",
//   "#BEADA5",
//   "#1C241F",
//   "#678F9E",
//   "#9456BC",
//   "#2C4142",
//   "#413508",
//   "#CD003B",
//   "#FDC3E9",
//   "#EFCED2",
//   "#B28405",
//   "#45382D",
//   "#FD4D48",
//   "#FDD8A3",
//   "#98ED83",
//   "#72736F",
//   "#02081D",
//   "#8E6D8A",
//   "#AFB357",
//   "#6D82DF",
//   "#65A86D",
//   "#D38251",
//   "#B40AA5",
//   "#D1539E",
//   "#BA62C5",
//   "#302451",
//   "#735054",
//   "#676339",
//   "#833697",
//   "#1A010A",
//   "#C5B08D",
//   "#0637B8",
//   "#5404DE",
//   "#C1DCDB",
//   "#B84E71",
//   "#189BC6",
//   "#FD17F3",
//   "#5B310A",
//   "#447B59",
//   "#8A5E39",
//   "#036E8B",
//   "#7D3A66",
//   "#0C9969",
//   "#55091F",
//   "#FE7331",
//   "#AA340A",
//   "#FCC465",
//   "#496C32",
//   "#BF8772",
//   "#9D8145",
//   "#687888",
//   "#1A57D8",
//   "#D5C9D9",
//   "#787394",
//   "#A19291",
//   "#0D78CD",
//   "#A6A58C",
//   "#8EF1FD",
//   "#3C4D2F",
//   "#5C6064",
//   "#95595B",
//   "#C57675",
//   "#0A4C7E",
//   "#4ED49D",
//   "#93BC5C",
//   "#BF7103",
//   "#006151",
//   "#A1B9BA",
//   "#ADAAFD",
//   "#C9A8AB",
//   "#4E2521",
//   "#FEBBAF",
//   "#0AFEEC",
//   "#D3015B",
//   "#F97A66",
//   "#DA9099",
//   "#54044F",
//   "#78E800",
//   "#558A88",
//   "#5DAB98",
//   "#94959E",
//   "#D5DFB6",
//   "#766AFD",
//   "#7E5B06",
//   "#595846",
//   "#4F6480",
//   "#F4FDE6",
//   "#869A7F",
//   "#5DA9FD",
//   "#8F8BB2",
//   "#7A2EFC",
//   "#B709E6",
//   "#012C28",
//   "#BEBCDF",
//   "#FE7AF0",
//   "#D50D9A",
//   "#E1D052",
//   "#FDE9D5",
//   "#203A49",
//   "#5F610A",
//   "#67A72B",
//   "#402E45",
//   "#1C1003",
//   "#894928",
//   "#022C07",
//   "#860203",
//   "#321201",
//   "#748B0A",
//   "#3B7B0B",
//   "#DE8D01",
//   "#6B42BA",
//   "#FE4D98",
//   "#943A58",
//   "#CDE70C",
//   "#CAA35D",
//   "#D6FEF9",
//   "#FEBA97",
//   "#80FBD7",
//   "#6B8168",
//   "#B9AC0B",
//   "#CD38FC",
//   "#5A535A",
//   "#BDC8B3",
//   "#8A9BA1",
//   "#745748",
//   "#8BCBE0",
//   "#A53F48",
//   "#2D2926",
//   "#555584",
//   "#067138",
//   "#11D92D",
//   "#5E324A",
//   "#6B99BC",
//   "#3AC0EC",
//   "#CC9873",
//   "#F906B8",
//   "#17233D",
//   "#FBA467",
//   "#BB6237",
//   "#8E8B84",
//   "#313A75",
//   "#7D082F",
//   "#396762",
//   "#957C00",
//   "#957A7F",
//   "#549349",
//   "#5C4A45",
//   "#A3D424",
//   "#722D1E",
//   "#9491D7",
//   "#8CB18A",
//   "#487C89",
//   "#DBEDFD",
//   "#280318",
//   "#4A0202",
//   "#53C7D3",
//   "#A1DAC9",
//   "#450487",
//   "#021A2A",
//   "#020157",
//   "#A1796C",
//   "#7A7650",
//   "#BF8EA2",
//   "#3B3845",
//   "#FEA3D5",
//   "#484723",
//   "#DBCEA7",
//   "#004C29",
//   "#CBC4C4",
//   "#AD8EB7",
//   "#7E6EC1",
//   "#DAB9A0",
//   "#64BC60",
//   "#AACBA9",
//   "#FDDB80",
//   "#AD67EC",
//   "#AB6188",
//   "#6D0AAA",
//   "#1D0832",
//   "#24301E",
//   "#4F4D49",
//   "#38585D",
//   "#394C64",
//   "#94B8DC",
//   "#D64D47",
//   "#911C2C",
//   "#426AB2",
//   "#2EA1A7",
//   "#D7DDD4",
//   "#03F385",
//   "#B1685C",
//   "#2C581E",
//   "#721757",
//   "#6BC8BB",
//   "#955902",
//   "#FE4879",
//   "#874B85",
//   "#CFA4E5",
//   "#471C3D",
//   "#E1F790",
//   "#E584C9",
//   "#C1FEE1",
//   "#95983E",
//   "#DDBBCE",
//   "#08BEA6",
//   "#506A55",
//   "#FBF7EA",
//   "#837C6B",
//   "#6F696F",
//   "#524077",
//   "#583D05",
//   "#9A7E65",
//   "#49236C",
//   "#A382CC",
//   "#B7BDB8",
//   "#816B5D",
//   "#6860A7",
//   "#0C8281",
//   "#466700",
//   "#B4D1E8",
//   "#DFDB9A",
//   "#308B71",
//   "#A33582",
//   "#2B232B",
//   "#5E82A8",
//   "#F5B70B",
//   "#716853",
//   "#657138",
//   "#059AE1",
//   "#E9A24F",
//   "#FE7198",
//   "#D98F84",
//   "#BBE4A7",
//   "#CC0300",
//   "#7C868D",
//   "#A3A0BA",
//   "#B09062",
//   "#755F36",
//   "#02BD0A",
//   "#729488",
//   "#573439",
//   "#F6E108",
//   "#544A5E",
//   "#D45433",
//   "#78DE90",
//   "#C0AC55",
//   "#42291C",
//   "#776287",
//   "#4B738D",
//   "#011315",
//   "#DD6CD3",
//   "#868303",
//   "#DA6EA3",
//   "#024149",
//   "#393D3A",
//   "#120B15",
//   "#E15964",
//   "#525F39",
//   "#D88029",
//   "#455966",
//   "#6F7B70",
//   "#909B90",
//   "#03B668",
//   "#B19387",
//   "#FEFDBD",
//   "#AF6624",
//   "#DDCBBF",
//   "#0B6070",
//   "#0C7051",
//   "#DD3F68",
//   "#A67C8D",
//   "#5F6C68",
//   "#4C361B",
//   "#7B82BA",
//   "#FE95AB",
//   "#92D659",
//   "#F3F5FD",
//   "#049625",
//   "#8AE0BA",
//   "#606ED6",
//   "#986F3D",
//   "#B94586",
//   "#B1933E",
//   "#2F5548",
//   "#AA6BA8",
//   "#5DA1B1",
//   "#9AA67C",
//   "#647B7E",
//   "#74465A",
//   "#C03231",
//   "#9F593B",
//   "#714A2D",
//   "#080F00",
//   "#465BF9",
//   "#708E3F",
//   "#E0BBB3",
//   "#DD6937",
//   "#9EB92D",
//   "#423A3B",
//   "#666E87",
//   "#8D5F9D",
//   "#003C90",
//   "#B39BB0",
//   "#AFB0B5",
//   "#ACCABE",
//   "#E4BF4C",
//   "#382F19",
//   "#6CBF86",
//   "#74687B",
//   "#84B7AD",
//   "#AFE5E3",
//   "#040082",
//   "#4B4BC2",
//   "#D3F8CE",
//   "#FB0739",
//   "#709262",
//   "#E0D6F8",
//   "#C1CAE1",
//   "#91A3CE",
//   "#C6BCFC",
//   "#798099",
//   "#2B384F",
//   "#02A487",
//   "#8A8EFD",
//   "#0D58AC",
//   "#8E3620",
//   "#3B483B",
//   "#78B6C0",
//   "#464300",
//   "#BC80FD",
//   "#E1A6A2",
//   "#9C38B9",
//   "#938360",
//   "#171C01",
//   "#337FFC",
//   "#C07B8A",
//   "#762F74",
//   "#843F01",
//   "#E08465",
//   "#CC972B",
//   "#C4B1D0",
//   "#5CABD1",
//   "#F96642",
//   "#B5AD9E",
//   "#455050",
//   "#87FEA7",
//   "#3E011B",
//   "#F3FB6B",
//   "#6D3935",
//   "#243033",
//   "#939260",
//   "#2C4100",
//   "#223E19",
//   "#805F5B",
//   "#498B5C",
//   "#A4B7C4",
//   "#5B4C9B",
//   "#BDB27D",
//   "#401B4C",
//   "#56A781",
//   "#B449B9",
//   "#D7DE65",
//   "#210A01",
//   "#8861DD",
//   "#FBCCA6",
//   "#6B213F",
//   "#8749D6",
//   "#DC9CB5",
//   "#95FE62",
//   "#E1E0C7",
//   "#C78CB7",
//   "#002155",
//   "#0D0828",
//   "#467E3B",
//   "#9B7BA1",
//   "#877889",
//   "#5F92F9",
//   "#C7FE96",
//   "#4B31B7",
//   "#302317",
//   "#8F712C",
//   "#8F8B9F",
//   "#A1B26F",
//   "#DE8EF0",
//   "#19413D",
//   "#AE7B30",
//   "#A39778",
//   "#F8EDFA",
//   "#66515D",
//   "#642429",
//   "#009D50",
//   "#3F2C33",
//   "#7D855A",
//   "#C25703",
//   "#F8C0FE",
//   "#694578",
//   "#FD2720",
//   "#4A4E60",
//   "#926E54",
//   "#5C734D",
//   "#7EA893",
//   "#8CA2B6",
//   "#A992F6",
//   "#844851",
//   "#CBC972",
//   "#7E5E74",
//   "#DBF8FE",
//   "#2B2894",
//   "#4F4736",
//   "#098458",
//   "#DE6F67",
//   "#302D45",
//   "#FEEAAB",
//   "#F38C50",
//   "#2E1218",
//   "#9C2365",
//   "#563E5B",
//   "#0C87CD",
//   "#002D1C",
//   "#B13759",
//   "#F6A690",
//   "#3A637C",
//   "#996CC9",
//   "#7550FB",
//   "#6CE4D7",
//   "#CB6CB3",
//   "#004D3B",
//   "#558472",
//   "#DC9458",
//   "#946D6B",
//   "#926777",
//   "#DC4ACC",
//   "#4B3632",
//   "#B18666",
//   "#C3A38A",
//   "#BA77DA",
//   "#935C86",
//   "#FEC8D9",
//   "#85AF6A",
//   "#67FEFD",
//   "#E0C08D",
//   "#81897A",
//   "#744C44",
//   "#AAB5E1",
//   "#BEB99C",
//   "#D3BEC0",
//   "#FC970D",
//   "#E6CEB1",
//   "#8E8287",
//   "#A8C9D4",
//   "#880090",
//   "#6E5D5E",
//   "#3D8DA7",
//   "#62625C",
//   "#83E2FD",
//   "#E3AC43",
//   "#A0998B",
//   "#998836",
//   "#8B3141",
//   "#3A6E5C",
//   "#E09DD6",
//   "#021A14",
//   "#515EB8",
//   "#2C2D16",
//   "#B5CD8F",
//   "#696691",
//   "#BF7739",
//   "#B67459",
//   "#DB354C",
//   "#191B1B",
//   "#8CA4A1",
//   "#A7524C",
//   "#E15000",
//   "#D4BD03",
//   "#7592C5",
//   "#650A01",
//   "#AC4FDC",
//   "#D6E3E6",
//   "#6BDA66",
//   "#A14134",
//   "#A296A2",
//   "#58541B",
//   "#A6ADA0",
//   "#EACCEC",
//   "#7A6148",
//   "#201450",
//   "#32182C",
//   "#6D371B",
//   "#9E1702",
//   "#02E8B8",
//   "#B3E3C0",
//   "#5E7F2C",
//   "#93AA49",
//   "#B14D22",
//   "#677075",
//   "#DCDAE1",
//   "#02D2C9",
//   "#786E6A",
//   "#9D9F03",
//   "#662D82",
//   "#01AC9E",
//   "#097821",
//   "#F1740F",
//   "#CDE0FE",
//   "#F3FED2",
//   "#77761E",
//   "#3F3E9A",
//   "#0274A4",
//   "#BDBBC9",
//   "#6E5C05",
//   "#BD6271",
//   "#924BA0",
//   "#263930",
//   "#E8A17D",
//   "#C38001",
//   "#437879",
//   "#79DBDF",
//   "#FDBA6E",
//   "#E72800",
//   "#FCE1DA",
//   "#46355D",
//   "#DF7A8C",
//   "#FD6CB0",
//   "#1E021E",
//   "#7C66A3",
//   "#A24471",
//   "#032ADF",
//   "#9D4D5F",
//   "#7A7D66",
//   "#7755B1",
//   "#E2DBD2",
//   "#0649BA",
//   "#30A203",
//   "#574B6F",
//   "#7CB309",
//   "#1C1C2C",
//   "#AC8F99",
//   "#5A35DD",
//   "#091018",
//   "#5C9A91",
//   "#855341",
//   "#AF1732",
//   "#6D9193",
//   "#877941",
//   "#75ECB0",
//   "#958875",
//   "#6C4F16",
//   "#5C4737",
//   "#FD109A",
//   "#56481E",
//   "#CAA375",
//   "#005F2A",
//   "#D2E49E",
//   "#415B43",
//   "#036D66",
//   "#E0F4EB",
// ];
