// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { EntityControllerService } from "../../../../services/server/EntityController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityConf, EntityDataDeleteResponse } from "../ManagementInterface.type";
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
// Componentes
import { ManagementEntityDialogComponent } from "./management-entity-dialog/management-entity-dialog.component";

@Component({
  selector: "app-management-entity",
  templateUrl: "./management-entity.component.html",
  styleUrls: ["./management-entity.component.scss"],
})
export class ManagementEntityComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  clientList: Client[];
  clientListSub: Subscription;

  // Tabla
  tableMaxReg: number = 100;
  tableData: EntityConf[];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "edit-config",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "execute-config",
          tooltip: "entity-execute-config",
          icon: "fas fa-level-down-alt run-icon",
          visible: { attribute: null, rule: true },
          disabled: true,
        },
      ],
      visible: true,
    },
    {
      title: "client",
      data: "clientName",
      search: "clientName",
      sort: "clientName",
      visible: true,
    },
    {
      title: "entity",
      data: "entityDescripcion",
      search: "entityDescripcion",
      sort: "entityDescripcion",
      visible: true,
    },
    {
      title: "enabled",
      data: "enabled",
      search: "enabled",
      sort: "enabled",
      alter: {
        condition: "enabled",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "days",
      data: "days",
      search: "days",
      sort: "days",
      visible: true,
    },
    {
      title: "readings",
      data: "readings",
      search: "readings",
      sort: "readings",
      alter: {
        condition: "readings",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "consumptions",
      data: "consumptions",
      search: "consumptions",
      sort: "consumptions",
      alter: {
        condition: "consumptions",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "events",
      data: "events",
      search: "events",
      sort: "events",
      alter: {
        condition: "events",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "frames",
      data: "frames",
      search: "frames",
      sort: "frames",
      alter: {
        condition: "frames",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "alarms",
      data: "alarms",
      search: "alarms",
      sort: "alarms",
      alter: {
        condition: "alarms",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
  ];

  // Edición de configuración
  editedEntityConf: EntityConf;
  dialog: Subscription;

  // Borrado
  deleteResponse: EntityDataDeleteResponse;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EntityController: EntityControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.clientList = this.SessionDataService.getCurrentClientList();

    this.clientListSub = this.SessionDataService
      .getClientList()
      .subscribe((clientList) => {
        this.clientList = clientList;
        this.ReloadComponentService.reload();
      });

    this.dialog = this.SessionDataService.getDialogAction().subscribe((dialogAction) => {
      if (dialogAction.action == "save") {
        this.editEntityConfig(dialogAction.data);
      }
    });

    // Carga del componente
    if (this.clientList) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientListSub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.EntityController.getAllEntitiesConfiguration().subscribe(
      (response) => {
        if (response["code"] == 0) {
          let tableData = response["body"];
          tableData.map((entityConf: EntityConf) => {
            if (entityConf.readings == null) {
              entityConf.readings = false;
            }
            if (entityConf.consumptions == null) {
              entityConf.consumptions = false;
            }
            if (entityConf.events == null) {
              entityConf.events = false;
            }
            if (entityConf.frames == null) {
              entityConf.frames = false;
            }
            if (entityConf.alarms == null) {
              entityConf.alarms = false;
            }
            if (entityConf.enabled == null) {
              entityConf.enabled = false;
            }
            entityConf.disabled = !entityConf.enabled;
          });

          // Comprobación de si todas las entidades tiene configuración
          this.clientList.forEach((client: Client) => {
            client.entityList.forEach((entity: Entity) => {
              if (
                !tableData.some(
                  (entityConf: EntityConf) =>
                    entityConf.entityDescripcion == entity.entity
                )
              ) {
                let newEntity: EntityConf = new EntityConf();
                newEntity.clientName = client.clientName;
                newEntity.entityDescripcion = entity.entity;
                newEntity.disabled = true;
                newEntity.entity = entity.id;
                tableData.push(newEntity);
              }
            });
          });

          this.tableData = tableData.sort((a, b) =>
            a.clientName?.localeCompare(b.clientName)
          );
        } else {
          this.tableData = [];
        }
      }
    );
  }

  // Acciones de la tabla
  tableActions(action: string, entity: EntityConf): void {
    switch (action) {
      case "edit-config":
        this.showConfigModal(entity);
        break;
      case "execute-config":
        this.deleteEntity(entity);
        break;
      default:
        break;
    }
  }

  // Edición de configuración
  showConfigModal(entity: EntityConf): void {
    this.editedEntityConf = JSON.parse(JSON.stringify(entity));
    if (!this.editedEntityConf.days) {
      this.editedEntityConf.days = 0;
    }
    this.MaterialDialogService.openDialog(
      ManagementEntityDialogComponent,
      this.editedEntityConf
    );
  }

  // Edición de la configuración de borrado de una entidad
  editEntityConfig(data: EntityConf): void {
    let newEntityConf = {
      id: data.id,
      entity: data.entity,
      days: data.days,
      readings: data.readings,
      consumptions: data.consumptions,
      events: data.events,
      frames: data.frames,
      alarms: data.alarms,
      enabled: data.enabled,
    };

    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("entity-edit-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.EntityController.saveEntityConf(newEntityConf).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("saved")
                );
                this.ReloadComponentService.reload();
              }
            }
          );
        }
      });
  }

  // Borrado de entidad
  deleteEntity(entity: EntityConf): void {
    this.deleteResponse = null;
    this.ToastService
      .fireAlertWithCaptcha(
        "question",
        this.translate.instant("entity-delete-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.EntityController.deleteEntityData(entity.entity).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.deleteResponse = response["body"];
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("entity-delete-success")
                );
              }
            }
          );
        }
      });
  }
}
