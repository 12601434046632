import { Injectable, OnDestroy } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
// import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "./ToastService.service";

@Injectable({
  providedIn: "root",
})
export class CheckForUpdateService implements OnDestroy {
  updateCheckInterval: any;
  updateCheckTimer: number = 60 * 1000;
  // updateSub: Subscription;

  constructor(
    private ToastService: ToastService,
    private translate: TranslateService,
    private update: SwUpdate
  ) {}

  ngOnDestroy(): void {
    // this.updateSub.unsubscribe();
    clearInterval(this.updateCheckInterval);
  }

  // Aviso de nueva versión
  startChecking(): void {
    // this.updateSub = this.update.versionUpdates.subscribe((event) => {
    //   switch (event.type) {
    //     case "VERSION_READY":
    //       this.ToastService
    //         .fireToastWithConfirmation(
    //           "info",
    //           this.translate.instant("new-version-available"),
    //           this.translate.instant("update")
    //         )
    //         .then((userConfirmation: boolean) => {
    //           if (userConfirmation) {
    //             document.location.reload();
    //           }
    //         });
    //       break;
    //     default:
    //       break;
    //   }
    // });

    if (this.update.isEnabled) {
      this.chekUpdate();
      this.updateCheckInterval = setInterval(
        () => this.chekUpdate(),
        this.updateCheckTimer
      );
    }
  }

  chekUpdate(): void {
    this.update.checkForUpdate().then((update) => {
      if (update) {
        this.ToastService
          .fireToastWithConfirmation(
            "info",
            this.translate.instant("new-version-available"),
            this.translate.instant("update"),
            this.translate.instant("cancel")
          )
          .then((userConfirmation: boolean) => {
            if (userConfirmation) {
              document.location.reload();
            }
          });
      }
    });
  }
}
