import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { AssociationControllerService } from "../../../../../services/server/AssociationController.service";
// Interfaces
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-data-management-configuration-dialog",
  templateUrl: "./data-management-configuration-dialog.component.html",
  styleUrls: ["./data-management-configuration-dialog.component.scss"],
})
export class DataManagementConfigurationDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: EntityDefinition;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de dato extra
  saveExtraColumn(): void {
    let data: EntityDefinition = {
      name: this.data.name,
      label: this.data.label,
      description: this.data.description,
      show: this.data.show,
      required: this.data.required,
      fieldType: 0,
    };

    let request: Observable<object>;
    if (this.data.id) {
      data.id = this.data.id;
      request = this.AssociationController.updateExtraData(
        this.SessionDataService.getCurrentEntity().id,
        data
      );
    } else {
      request = this.AssociationController.saveExtraData(
        this.SessionDataService.getCurrentEntity().id,
        data
      );
    }

    request.subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.data.id
            ? this.translate.instant("elements-edit")
            : this.translate.instant("elements-save")
        );
        this.SessionDataService.sendUpdateEntityCupsConfFlag();
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
