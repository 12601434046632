<!-- Modal de edición de EK280 -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "test-comm" | translate }} EK280</h5>
  </div>
  <div class="dialog-body ek280-test-dialog">
    <app-table-controller
      [columns]="columns"
      [rowNumbers]="false"
      [maxReg]="1"
      [onlyTable]="true"
      [paginationDisabled]="true"
      [transpose]="true"
      [noPinnedColumns]="true"
      [columnOrderDisabled]="true"
      [data]="testData"
    >
    </app-table-controller>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
