import { Injectable } from "@angular/core";
import {
  MapDevice,
  MapDeviceMinimal,
  MapDeviceTooltip,
} from "../../../interfaces/DeviceGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class MapDeviceMinimalParseService {
  constructor() {}

  // Conversión de objeto de dispositivo mínimo a objeto completo
  parseDevices(newDevices: MapDeviceMinimal[]): MapDevice[] {
    let devices: MapDevice[] = newDevices?.map((device: MapDeviceMinimal) => {
      let parsedDevice: MapDevice = {
        id: device.id,
        nroSerie: device.ns,
        longitude: device.lg,
        latitude: device.lt,
        lastRssi: device.lr,
        lastDataRate: device.ld,
        lastSnr: device.ls,
        unidadVentaGw: device.uv,
        comunica: device.c,
        alarm: device.a,
        alarmList: null,
        lastCommunication: device.lc,
        imagePath: null,
        haveImage: null,
        fabricante: device.f,
        devType: device.d,
        claveMaestraId: null,
        claveMaestraKey: null,
        isAssignedCups: null,
        metrologyType: device.mt,
        tipo: device.t,
        listaConcentradores: null,
        installation: null,
        agrupation: device.ag,
        hasAs: device.ha,
        distanciaAcustica: device.da,
      };
      return parsedDevice;
    });
    return devices;
  }

  // Adición de datos de tooltip a objeto de dispositivo existente
  parseTooltipData(device: MapDevice, tooltipData: MapDeviceTooltip): void {
    device.alarmList = tooltipData.al;
    device.imagePath = tooltipData.ip;
    device.haveImage = tooltipData.hi;
    device.claveMaestraId = tooltipData.cm;
    device.claveMaestraKey = tooltipData.ck;
    device.isAssignedCups = tooltipData.ac;
    device.listaConcentradores = tooltipData.cl?.map((concentrator) => {
      return {
        idConcentrador: concentrator.ic,
        nroSerieConcentrador: concentrator.nc,
      };
    });
    device.installation = tooltipData.i;
  }
}
