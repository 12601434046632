// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { DataAnalysisControllerService } from "../../../../services/server/DataAnalysisController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
import { AssociationControllerService } from "../../../../services/server/AssociationController.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { GraphFilterOptionSelected } from "../../../../modules/graph-module/GraphInterface.type";
import {
  GraphComparisonSelectedGroup,
  GraphComparisonSelection,
  GraphRequestData,
  GraphSumatoryData,
  SavedSelection,
} from "../DataAnalysisInterface.type";
import {
  MaterialSelectConfig,
  MaterialSelectGroup,
} from "../../../../modules/material-module/MaterialInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";
import { RANDOM_COLORS } from "../../../../modules/map-module/map-variables/MAP_COLORS";
// Componentes
import { GraphControllerComponent } from "../../../../modules/graph-module/graph-controller/graph-controller.component";

@Component({
  selector: "app-data-analysis-graph-comparison",
  templateUrl: "./data-analysis-graph-comparison.component.html",
  styleUrls: ["./data-analysis-graph-comparison.component.scss"],
})
export class DataAnalysisGraphComparisonComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Listados
  selection: GraphComparisonSelection[] = [
    { selected: null, dateRange: null },
    { selected: null, dateRange: null },
  ];
  savedSelections: SavedSelection[];
  selectedMeters: GraphComparisonSelectedGroup[];
  selectOptions: SavedSelection[];
  selectGroups: MaterialSelectGroup[] = [
    {
      value: "selection",
      name: this.translate.instant("saved-selections"),
      disabled: false,
    },
    {
      value: "association",
      name: this.translate.instant("meters-groups"),
      disabled: false,
    },
  ];
  maxInterval: number;

  // Gráfica
  graphEnabled: boolean = false;
  graphViewEnabled: boolean = false;
  graphActive: boolean = false;
  graphSeries: any[];
  graphData: GraphSumatoryData[];
  xAxisNames: string[][];
  graphType: number = 2;
  sumatory: number = 2;
  graphFilters: MaterialSelectConfig[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  @ViewChild(GraphControllerComponent)
  graphController: GraphControllerComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private GraphOptionsService: GraphOptionsService,
    private DataAnalysisController: DataAnalysisControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.savedSelections = this.SessionDataService.getCurrentSavedSelection();
    this.savedSelections?.map((selection) => {
      selection.id = selection.name;
      selection.group = "selection";
    });
    this.selectOptions = this.savedSelections ? this.savedSelections : [];
    this.getAssociations();
    this.setHighchartsOptions();
  }

  // Obtención del listado de asociaciones
  getAssociations(): void {
    this.AssociationController.getAssociationList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let associationList = response["body"];
        associationList.map(
          (association) => (association.group = "association")
        );
        this.selectOptions = [...this.selectOptions.concat(associationList)];
      }
    });
  }

  // Intervalo máximo de fechas
  checkMaxInterval(index: number): void {
    if (index == 0) {
      this.maxInterval = Math.round(
        (this.selection[0]?.dateRange?.endDate?.valueOf() -
          this.selection[0]?.dateRange?.startDate?.valueOf()) /
          (24 * 3600000)
      );
    }
  }

  // Habilitación de gráfica
  checkShowGraph(): void {
    setTimeout(() => {
      this.graphEnabled = this.selection?.every(
        (selectionData) =>
          selectionData?.selected != null &&
          selectionData?.dateRange?.startDate != null &&
          selectionData?.dateRange?.endDate != null
      );
    }, 0);
  }

  // Añadir selección
  addSelection(): void {
    this.selection.push({ selected: null, dateRange: null });
  }

  // Quitar selección
  dropSelection(i: number): void {
    this.selection.splice(i, 1);
  }

  // Visualizar gráfica
  showGraph(): void {
    let selectedMeters: GraphComparisonSelectedGroup[] = [];
    let requests: Observable<object>[] = [];
    let associations: GraphComparisonSelection[] = [];
    this.selection.forEach((itemSelected) => {
      // Selección guardada
      if (itemSelected.selected.group == "selection") {
        selectedMeters.push({
          name: itemSelected.selected.name,
          agrupation: itemSelected.selected.agrupation,
          dateRange: itemSelected.dateRange,
          meters: itemSelected.selected.meters,
        });
        // Asociación
      } else if (itemSelected.selected.group == "association") {
        requests.push(
          this.AssociationController.getAssociationDevices(
            itemSelected.selected.id
          )
        );
        associations.push(itemSelected);
      }
    });

    // Peticiones para asociaciones seleccionadas
    if (associations.length > 0) {
      forkJoin(requests).subscribe((responses) => {
        responses.forEach((response, i) => {
          if (response["code"] == 0) {
            selectedMeters.push({
              name: associations[i].selected.name,
              agrupation: associations[i].selected.agrupation,
              dateRange: associations[i].dateRange,
              meters: response["body"],
            });
          }
        });
        this.selectedMeters = selectedMeters;
        this.loadGraphData();
      });
    } else {
      this.selectedMeters = selectedMeters;
      this.loadGraphData();
    }
  }

  // Obtención de los datos del gráfico
  loadGraphData(): void {
    // Filtros
    if (!this.graphViewEnabled) {
      this.setTypeFilter(
        this.selectedMeters?.some((selection) =>
          selection?.meters?.some((meter) => meter.metrologyType == 2)
        )
      );
    }

    // Datos de gráfica
    this.graphData = [];
    let requests = this.selectedMeters.map((selection) => {
      let data: GraphRequestData = {
        meterList: selection.meters?.map((meter) => meter.id),
        agrupation: selection.agrupation,
        fromTimestamp: selection.dateRange.startDate.valueOf(),
        toTimestamp: selection.dateRange.endDate.valueOf(),
        graphType: this.graphType,
      };
      return this.DataAnalysisController.getGraphGroup(this.sumatory, data);
    });
    forkJoin(requests).subscribe((responses) => {
      responses.forEach((response) => {
        if (response["code"] == 0 && response["body"]) {
          this.graphData.push(response["body"]);
        }
      });
      this.getSeries();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    this.graphSeries = [];
    this.xAxisNames = [];
    this.selectedMeters.forEach((selection, i) => {
      let xAxis: number = i > 0 ? this.getXAxis(i) : 0;
      if (this.xAxisNames[xAxis]) {
        this.xAxisNames[xAxis].push(selection.name);
      } else {
        this.xAxisNames[xAxis] = [selection.name];
      }
      this.graphSeries.push({
        id: i,
        name: selection.name,
        data: this.graphData[i]?.readings,
        dataGrouping: { approximation: "sum" },
        tooltip: {
          pointFormat:
            "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b>",
          valueSuffix:
            this.graphType == 2 ? " m³" : this.graphType == 3 ? " Nm³" : " kWh",
          valueDecimals: 3,
        },
        color: RANDOM_COLORS[i],
        xAxis: xAxis,
      });
    });
    this.setChartsOptions();
  }

  // Obtención del eje de la serie
  getXAxis(index: number): number {
    let sameRangeSerieIndex: number = 0;
    let match: boolean = false;
    do {
      if (
        this.selectedMeters[
          sameRangeSerieIndex
        ].dateRange.startDate.valueOf() ==
          this.selectedMeters[index].dateRange.startDate.valueOf() &&
        this.selectedMeters[sameRangeSerieIndex].dateRange.endDate.valueOf() ==
          this.selectedMeters[index].dateRange.endDate.valueOf()
      ) {
        match = true;
      }
      sameRangeSerieIndex++;
    } while (!match && sameRangeSerieIndex < index);
    return match ? sameRangeSerieIndex - 1 : this.xAxisNames.length;
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    highchartsOptions.tooltip.split = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["legend"]["enabled"] = this.sumatory == 1 ? true : false;
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == 2 ? " m³" : this.graphType == 3 ? " Nm³" : " kWh");
    chartOptions["yAxis"][0]["labels"]["style"] = {
      color: this.sumatory == 2 ? "#000" : "#42a5f5",
    };
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == 2
        ? this.translate.instant("consumption")
        : this.graphType == 3
        ? this.translate.instant("consumption-normalized")
        : this.translate.instant("energy");
    chartOptions["yAxis"][0]["title"]["style"] = {
      color: this.sumatory == 2 ? "#000" : "#42a5f5",
      fontWeight: "bold",
    };
    chartOptions["xAxis"] = [];
    this.xAxisNames.forEach((xAxis, i) => {
      chartOptions["xAxis"].push({
        lineColor: RANDOM_COLORS[i],
        tickColor: RANDOM_COLORS[i],
        title: {
          text: xAxis.join(", "),
          style: {
            color: RANDOM_COLORS[i],
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            color: RANDOM_COLORS[i],
          },
        },
      });
    });
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
    this.graphViewEnabled = true;
  }

  // Actualización del tipo de gráfica
  updateGraphData(selected: GraphFilterOptionSelected): void {
    switch (selected.filterIndex) {
      case 0:
        this.graphType = selected.value;
        break;
      default:
        break;
    }
  }

  // Seteo del filtro de tipo de gráfica
  setTypeFilter(gas: boolean): void {
    if (gas) {
      this.graphFilters = [
        {
          title: this.translate.instant("type"),
          options: [
            { value: 2, name: this.translate.instant("consumption") },
            { value: 3, name: this.translate.instant("consumption-normalized") },
            { value: 4, name: this.translate.instant("energy") },
          ],
          selected: 3,
        },
      ];
      this.graphType = 3;
    } else {
      this.graphFilters = null;
    }
  }
}
