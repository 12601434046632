// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class HomeControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Datos de mapa por agrupación
  getMarkers(agrupationId: number): Observable<object> {
    return this.HttpRequestService.post(
      "/map/byagrupation/" + agrupationId,
      []
    );
  }

  // Localizaciones sin gateway
  getLocations(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/location/get/gw-empty/entity/" + entityId
    );
  }

  // Localizaciones de una entidad
  getEntityLocations(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/location/get/entity/" + entityId);
  }

  // Datos de localización
  getLocationData(locationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/historical/gateways/location/" + locationId
    );
  }

  // Datos de localización de un gateway
  getGatewayLocationData(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/historical/gateways/location/gw/" + gatewayId
    );
  }

  // Datos de las localizaciones previas de un gateway
  getGatewayPrevLocationData(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/historical/locations/gateway/" + gatewayId
    );
  }

  // Datos de tooltip para marcador de mapa
  getMarkerTooltip(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/map/tooltip/" + deviceId);
  }

  // Datos de tarjetas de agrupación
  getCards(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/cards/agrupation/" + agrupationId);
  }

  // Datos de mapa por agrupación
  getAllSf(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/map/sfdropdown/agrupation/" + agrupationId
    );
  }

  // Obtener imagen de un dispositivo
  getMeterImg(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/get-image/" + deviceId);
  }

  // Obtener imagen del gateway
  getGatewayImg(gatewayId: number, agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/get-image/" + gatewayId + "/agrupation/" + agrupationId
    );
  }

  getElementImg(
    deviceId: number,
    agrupationId: number,
    type: string
  ): Observable<object> {
    if (type == "meter") {
      return this.getMeterImg(deviceId);
    } else if (type == "gateway") {
      return this.getGatewayImg(deviceId, agrupationId);
    }
  }

  // Dispositivos LoRa pendientes de instalación
  getLoraPending(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/count/lora-notassigned/" + agrupationId
    );
  }
}
