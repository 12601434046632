<div class="device-type-filter-container">
  <div class="device-type-filter">
    <app-material-select
      [filter]="true"
      [noSelection]="true"
      [title]="'manufacturer' | translate"
      [bindValue]="'manufacturerId'"
      [bindLabel]="'manufacturerText'"
      [data]="manufacturerList"
      (selectedOption)="
        selectedManufacturer = $event?.manufacturerId;
        linkFilters ? getManufacturerModelList() : null;
        updateSelection()
      "
    ></app-material-select>
  </div>
  <div class="device-type-filter">
    <app-material-select
      [filter]="true"
      [noSelection]="true"
      [title]="'model' | translate"
      [bindValue]="'model'"
      [bindLabel]="'modelText'"
      [data]="linkFilters ? manufacturerModelList : modelList"
      (selectedOption)="selectedModel = $event?.model; updateSelection()"
    ></app-material-select>
  </div>
</div>
