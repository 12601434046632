<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "balance-find-possibles" | translate }}
    </h5>
  </div>
  <div class="dialog-body balance-find">
    <div *ngIf="mapMeters" class="balance-find-options">
      <!-- Similaridad de curvas -->
      <div class="balance-find-similarity">
        <mat-label
          >{{ "balance-similarity" | translate }}: {{ similarity }}
        </mat-label>
        <mat-slider
          min="1"
          [max]="differenceAccumulatedMax * 2"
          step="1"
          [(ngModel)]="similarity"
        >
          <input matSliderThumb />
        </mat-slider>
        <mat-hint
          >({{ "difference" | translate }}: {{ differenceAccumulatedMax }})
        </mat-hint>
      </div>
      <!-- Valor medio -->
      <div class="balance-find-average">
        <mat-form-field appearance="standard">
          <mat-label>{{ "balance-average-value" | translate }} </mat-label>
          <input matInput type="number" step="1" [(ngModel)]="meterAverage" />
          <mat-hint
            >({{ "difference" | translate }}: {{ differenceAverage }})</mat-hint
          >
        </mat-form-field>
      </div>
      <!-- Mostrar todos los contadores en rango -->
      <div class="balance-show-all">
        <mat-checkbox class="mat-option" [(ngModel)]="showAllMetersInRange">
          {{ "balance-show-all-meters" | translate }}
        </mat-checkbox>
      </div>
    </div>

    <app-map-controller
      [freeDrawEnabled]="true"
      [mapHeight]="500"
      [mapType]="'balanceDetail'"
      (selectedDevices)="cupsInRange = $event; selectionUpdated = true"
      (selectionLayers)="selectionLayers = $event"
      [gatewaysData]="[]"
      [metersData]="mapMeters"
    >
    </app-map-controller>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="getGraphs()"
      [disabled]="!cupsInRange || cupsInRange.length == 0"
    >
      <i class="fas fa-search"></i>
      {{ "search" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
