// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterService } from "../../MeterService.service";
// Interfaces
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import { MaterialSelectOption } from "../../../../../../modules/material-module/MaterialInterface.type";
import {
  METER_ORDER,
  METER_ORDER_STATE,
  MeterOrder,
} from "../../../DeviceInterface.type";
import { MapControllerComponent } from "../../../../../../modules/map-module/map-controller/map-controller.component";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";

@Component({
  selector: "app-meter-orders",
  templateUrl: "./meter-orders.component.html",
  styleUrls: ["./meter-orders.component.scss"],
})
export class MeterOrdersComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  sessionProfile: string;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;

  // Table
  searchBy: string = "agrupation";
  tableData: MeterOrder[];
  tableSelectedData: MeterOrder[];
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("meter-orders") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "cancel-selected-data",
      icon: "fas fa-eraser",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
    {
      title: "remove-selected-data",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
  ];
  extraColumns: EntityDefinition[];

  // Select
  orderSelect: MaterialSelectOption[];

  // Mapa
  @ViewChild(MapControllerComponent) MapController: MapControllerComponent;
  mapType: string = "meterList";
  mapHeight: number = 500;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.getCupsConf();

    // Escucha de cambios en entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() =>
      this.ReloadComponentService.reload()
    );

    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
          this.getCupsConf();
          this.setColumns();
        }
      );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.orderSelect = [
      {
        value: "entity",
        name:
          this.translate.instant("entity") + ": " + this.currentEntity.entity,
      },
      {
        value: "agrupation",
        name:
          this.translate.instant("groups") + ": " + this.currentAgrupation.name,
      },
    ];
    this.getData();
  }

  // Obtención de la configuración de CUPS
  getCupsConf(): void {
    if (this.entityCupsConf) {
      this.extraColumns = [...this.entityCupsConf];
      this.cups = this.extraColumns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      let cupsIndex: number = this.extraColumns.findIndex(
        (column: EntityDefinition) => column.colPosition == 0
      );
      if (cupsIndex >= 0) {
        this.extraColumns.splice(cupsIndex, 1);
      }
    }
  }

  // Obtención de los datos
  getData(): void {
    let url =
      this.searchBy == "entity"
        ? this.MeterController.getEntityOrders(this.currentEntity.id)
        : this.MeterController.getAgrupationOrders(this.currentAgrupation.id);
    url.subscribe((response) => {
      if (response["code"] == 0) {
        let meterOrders: MeterOrder[] = response["body"];
        meterOrders?.forEach((meterOrder: MeterOrder) => {
          meterOrder.meterLink =
            this.DeviceRouteSelectorService.getDeviceRouteUrl(
              meterOrder.metrologyType,
              meterOrder.contador
            );
          meterOrder.typeParsed = METER_ORDER[meterOrder.type]
            ? this.translate.instant(METER_ORDER[meterOrder.type])
            : meterOrder.type;
          meterOrder.cupsLink = meterOrder.createClaveMaestra
            ? "/cups/detalle/" + meterOrder.createClaveMaestra
            : null;
          meterOrder.stateParsed = METER_ORDER_STATE[meterOrder.state]
            ? this.translate.instant(METER_ORDER_STATE[meterOrder.state])
            : null;
          meterOrder.cancelDisabled = meterOrder.closeDate ? true : false;
        });
        this.tableData = meterOrders;
        this.setColumns();
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "cancel",
            tooltip: "cancel",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: "cancelDisabled",
            warning: true,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "groups",
        data: "agrupation",
        search: "agrupation",
        sort: "agrupation",
        visible: this.searchBy == "entity" ? true : null,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "meterLink",
      },
      {
        title: this.cups?.name,
        data: "createClave",
        search: "createClave",
        sort: "createClave",
        visible: this.cups != null ? true : null,
        link: "cupsLink",
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "stateParsed",
        visible: true,
      },
      {
        title: "creation-date",
        data: "createDate",
        search: "createDate",
        sort: "createDate",
        visible: true,
        date: true,
      },
      {
        title: "creation-user",
        data: "createUsername",
        search: "createUsername",
        sort: "createUsername",
        visible: true,
      },
      {
        title: "end-date",
        data: "closeDate",
        search: "closeDate",
        sort: "closeDate",
        visible: true,
        date: true,
      },
      {
        title: "close-user",
        data: "closeUsername",
        search: "closeUsername",
        sort: "closeUsername",
        visible: true,
      },
      {
        title: "type",
        data: "typeParsed",
        search: "typeParsed",
        sort: "typeParsed",
        visible: true,
      },
      {
        title: "comments",
        data: "comment",
        search: "comment",
        sort: "comment",
        visible: true,
        long: true,
      },
    ];
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meterOrder: MeterOrder): void {
    switch (action) {
      case "delete":
        this.MeterService.deleteMeterOrder([meterOrder.id]);
        break;
      case "cancel":
        this.MeterService.cancelMeterOrder([meterOrder.id]);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "remove-selected-data":
        this.MeterService.deleteMeterOrder(
          this.tableSelectedData.map((order) => order.id)
        );
        break;
      case "cancel-selected-data":
        this.MeterService.cancelMeterOrder(
          this.tableSelectedData.map((order) => order.id)
        );
        break;
      default:
        break;
    }
  }
}
