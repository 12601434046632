import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Input,
} from "@angular/core";
// Moment
import moment_timezone from "moment-timezone";

@Directive({
  selector: "[appDateRangeInput]",
})
export class DateRangeInputDirective {
  @Input() isSingleDate: boolean;
  @Output() dateRangeUpdated = new EventEmitter<any>();

  constructor(private el: ElementRef) {}

  // Evento de escritura
  @HostListener("keyup", ["$event?.target?.value"]) keyUpEvent(
    date: string
  ): void {
    if (date) {
      this.updateDatePicker(date);
    }
  }

  // Actualización de selector de fechas
  updateDatePicker(date: string): void {
    let startDate: string[];
    let endDate: string[];
    // Rango
    if (date.includes("-")) {
      startDate = date.split("-")[0].trim().split("/").reverse();
      endDate = date.split("-")[1].trim().split("/").reverse();
      // Fecha única
    } else {
      startDate = date.split("/").reverse();
      endDate = startDate;
    }
    // Formateo
    if (
      startDate[0].length == 4 &&
      startDate[1].length == 2 &&
      startDate[2].length == 2 &&
      endDate[0].length == 4 &&
      endDate[1].length == 2 &&
      endDate[2].length == 2
    ) {
      let startDateFormatted = new Date(
        startDate.join("-").replace("/", "-") + "T00:00:00"
      );

      if (this.isSingleDate) {
        this.dateRangeUpdated.emit({
          startDate: moment_timezone(startDateFormatted),
        });
      } else {
        let endDateFormatted = new Date(
          endDate.join("-").replace("/", "-") + "T23:59:59"
        );

        this.dateRangeUpdated.emit({
          startDate: moment_timezone(startDateFormatted),
          endDate: moment_timezone(
            endDateFormatted > startDateFormatted
              ? endDateFormatted
              : startDateFormatted
          ),
        });
      }
    }
  }
}
