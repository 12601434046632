// @angular
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicio propios
import { HttpRequestService } from "./HttpRequestService.service";
import { HttpRequestQueueService } from "./HttpRequestQueueService.service";
import { ToastService } from "../ToastService.service";
// Variables
import {
  ROUTES_WITHOUT_SPINNER,
  ROUTES_WITH_OPTIONAL_SPINNER,
} from "./TIMEOUTS";

@Injectable()
export class HttpRequestInterceptorService implements HttpInterceptor {
  /***************************************************************************/
  // Constructor
  /***************************************************************************/

  constructor(
    private HttpRequestService: HttpRequestService,
    private HttpRequestQueueService: HttpRequestQueueService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // Interceptor
  /***************************************************************************/

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtención de token
    let token: string = this.HttpRequestService.getToken();

    // Añadido del token en las llamadas
    let MODIFIED_REQUEST: HttpRequest<any> = req.clone({
      headers: req.headers.append("Authorization", "Token " + token),
    });

    // Comprobación de ruta Aqualia
    if (window.location.href.includes("ia.iotwater.es")) {
      MODIFIED_REQUEST = MODIFIED_REQUEST.clone({
        url: req.url.replace(
          "lorawansrv.arsonmetering.com",
          "srvia.iotwater.es"
        ),
      });
    }

    // Cálculo del timeout para las rutas que lo necesiten
    let requestTimeout = this.HttpRequestService.setUrlTimeout(req.url);

    // Comprobación de si se trata de una petición de cola
    if (req.url.includes("/queue")) {
      const MODIFIED_URL_REQUEST: HttpRequest<any> = MODIFIED_REQUEST.clone({
        url: req.url.replace("/queue", ""),
      });
      return this.HttpRequestQueueService.request(
        MODIFIED_URL_REQUEST,
        next,
        requestTimeout
      );
    } else {
      // Comprobación de spinner de carga en ruta
      let spinnerActive = !this.HttpRequestService.urlFilter(
        req.url,
        ROUTES_WITHOUT_SPINNER
      );

      // Spinner de carga opcional
      if (
        this.HttpRequestService.urlFilter(req.url, ROUTES_WITH_OPTIONAL_SPINNER)
      ) {
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("timeout-question")
        ).then((userConfirmation: boolean) => {
          spinnerActive = userConfirmation;
          // Petición
          return this.HttpRequestService.request(
            req,
            next,
            MODIFIED_REQUEST,
            requestTimeout,
            spinnerActive
          );
        });
      } else {
        // Petición
        return this.HttpRequestService.request(
          req,
          next,
          MODIFIED_REQUEST,
          requestTimeout,
          spinnerActive
        );
      }
    }
  }
}
