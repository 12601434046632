// @angular
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";

@Component({
  selector: "app-device-agrupation-dialog",
  templateUrl: "./device-agrupation-dialog.component.html",
  styleUrls: ["./device-agrupation-dialog.component.scss"],
})
export class DeviceAgrupationDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  updating: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private router: Router,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Cambiar agrupación del contador
  changeAgrupation(): void {
    this.ToastService
      .fireAlertWithOptions(
        "warning",
        this.translate.instant(
          this.data.devices?.length > 1
            ? "change-meters-question"
            : "change-meter-question"
        )
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.updating = true;
          this.changeAgrupationRequest();
        }
      });
  }

  // Petición
  changeAgrupationRequest(): void {
    if (this.data.devices?.length == 1) {
      this.MeterController.change(
        this.data.devices[0].id,
        this.data.selectedAgrupation.id
      ).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("change-meter-sucessfull")
          );
          this.closeModal();
        }
      });
    } else {
      let data = this.data.devices.map((device: any) => {
        return {
          id: device.id,
          nroSerie: device.nroSerie,
          metrologyType: device.metrologyType,
          agrupation: this.data.selectedAgrupation.id,
        };
      });
      this.requestQueue.setTask(
        "changeAgrupation",
        data,
        this.data.selectedAgrupation
      );
      this.closeModal();
    }
  }

  // Cierre de modal
  closeModal(): void {
    if (
      (this.router.url.includes("/dispositivos/detalle/") ||
        this.router.url.includes("/dispositivos/listado/seleccionados")) &&
      !this.SessionDataService.getCurrentAgrupation().showAllEntity
    ) {
      this.SessionDataService.sendAgrupation(this.data.selectedAgrupation);
    } else {
      this.ReloadComponentService.reload();
    }
    this.updating = false;
    this.SessionDataService.sendDialogAction({ action: "close" });
  }
}
