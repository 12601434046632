import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ManufacturerService {
  manufacturers: any;

  constructor(private http: HttpClient, private translate: TranslateService) {}

  loadManufacturers(): void {
    let headers = {
      headers: new HttpHeaders({
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      }),
    };
    this.http
      .get("../../../../assets/manufacturers/manufacturers.json", headers)
      .subscribe((manufacturers) => {
        this.manufacturers = manufacturers;
      });
  }

  // Obtención del fabricante/tipo del dispositivo
  getManufacturer(
    manufacturerCode: string,
    deviceCode: string,
    language: string
  ): any {
    if (parseInt(manufacturerCode) >= 0 && parseInt(deviceCode) >= 0) {
      let manufacturerText =
        this.manufacturers[manufacturerCode]?.manufacturerText[language];
      if (!manufacturerText) {
        manufacturerText =
          this.manufacturers[manufacturerCode]?.manufacturerText["es"];
      }
      let deviceText =
        this.manufacturers[manufacturerCode]?.devices[deviceCode]?.deviceText[
          language
        ];
      if (!deviceText) {
        deviceText =
          this.manufacturers[manufacturerCode]?.devices[deviceCode]?.deviceText[
            "es"
          ];
      }
      return {
        manufacturerText: manufacturerText
          ? manufacturerText
          : this.translate.instant("unknown"),
        deviceText: deviceText ? deviceText : this.translate.instant("unknown"),
      };
    } else {
      return {
        manufacturerText: this.manufacturers
          ? this.manufacturers["*"].manufacturerText[language]
          : null,
        deviceText: this.manufacturers
          ? this.manufacturers["*"].deviceText[language]
          : null,
      };
    }
  }

  // Obtención de todos los fabricantes
  getAllManufacturers(): any {
    return this.manufacturers;
  }

  // Obtención del tipo de dispositivo
  getDeviceType(manufacturerCode: number, deviceCode: number): string {
    return this.manufacturers[manufacturerCode]?.devices[deviceCode]?.type;
  }

  // Obtención de fabricante por id
  getManufacturerById(manufacturerCode: number): object {
    return this.manufacturers[manufacturerCode];
  }
}
