// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { CoverageControllerService } from "../../../../services/server/CoverageController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { CoverDevice } from "../CoverageInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
import { CoverageTestService } from "./CoverageTestService.service";
import { GatewayLocation } from "../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";

@Component({
  selector: "app-coverage",
  templateUrl: "./coverage-test.component.html",
  styleUrls: ["./coverage-test.component.scss"],
})
export class CoverageTestComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Mapa
  @ViewChild(MapControllerComponent)
  mapController: MapControllerComponent;
  mapDevices: CoverDevice[];
  originalMapDevices: CoverDevice[];
  locations: GatewayLocation[];
  mapType: string = "coverage";
  mapHeight: number;
  @ViewChild("coveragePanel") coveragePanel: ElementRef;
  entityFilter: boolean = true;
  avoidMapUpdate: boolean = true;
  avoidTableUpdate: boolean = false;

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.mapHeight = this.coveragePanel?.nativeElement?.offsetHeight;
  }
  coverageSelectOptions: MaterialSelectOption[] = [
    { value: true, name: this.translate.instant("entity-filter-active") },
    { value: false, name: this.translate.instant("entity-filter-inactive") },
  ];

  // Tabla de tests
  tableDevices: CoverDevice[];
  testColumns: object[] = [
    {
      title: "DEV EUI",
      data: "devEui",
      search: "devEui",
      sort: "devEui",
      visible: true,
    },
    {
      title: "locations",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
      noExport: true,
    },
    {
      title: "latitude",
      data: "latitud",
      search: "latitud",
      sort: "latitud",
      visible: true,
    },
    {
      title: "longitude",
      data: "longitud",
      search: "longitud",
      sort: "longitud",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "distance",
      data: "distance",
      search: "distance",
      sort: "distance",
      numerical: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "SNR",
      data: "snr",
      search: "snr",
      sort: "snr",
      numerical: true,
      visible: true,
    },
    {
      title: "comments",
      data: "comment",
      search: "comment",
      sort: "comment",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CoverageController: CoverageControllerService,
    private CoverageTestService: CoverageTestService,
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(
      () => (this.mapHeight = this.coveragePanel?.nativeElement?.offsetHeight),
      0
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getMapData();
  }

  // Obtención de los datos para el mapa
  getMapData(): void {
    let url = this.entityFilter
      ? this.CoverageController.getCoverage(this.currentAgrupation.id)
      : this.CoverageController.getCoverageArson(this.currentAgrupation.id);
    url.subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]["locationList"] != null) {
          this.locations = response["body"]["locationList"];
        } else {
          this.locations = [];
        }
        if (response["body"]["testCoberturaList"] != null) {
          let mapDevices: CoverDevice[] = response["body"]["testCoberturaList"];
          mapDevices.forEach((device) => {
            device.timestampParsed = this.DateParserService.parseDate(
              device.timestamp,
              "L HH:mm:ss"
            );
            // Listado de localizaciones de test
            if (device.locationList?.length > 0) {
              device.locationList.sort((a, b) => b.rssi - a.rssi);
              device.locationList.map((location) => {
                let locationData = this.locations.find(
                  (locationData) => locationData.id == location.id
                );
                location.latitude = locationData.latitude;
                location.longitude = locationData.longitude;
              });
              device.rssi = device.locationList[0].rssi;
              device.snr = device.locationList[0].snr;
              device.distance = device.locationList[0].distance;
              device.locationList[0].best = true;
              device.extraTableData = {
                columns: [
                  {
                    title: "latitude",
                    data: "latitude",
                    search: "latitude",
                    sort: "latitude",
                    visible: true,
                  },
                  {
                    title: "longitude",
                    data: "longitude",
                    search: "longitude",
                    sort: "longitude",
                    visible: true,
                  },
                  {
                    title: "distance",
                    data: "distance",
                    search: "distance",
                    sort: "distance",
                    numerical: true,
                    visible: true,
                  },
                  {
                    title: "RSSI",
                    data: "rssi",
                    search: "rssi",
                    sort: "rssi",
                    numerical: true,
                    visible: true,
                  },
                  {
                    title: "SNR",
                    data: "snr",
                    search: "snr",
                    sort: "snr",
                    numerical: true,
                    visible: true,
                  },
                ],
                highlightRow: [{ condition: "best", color: "green" }],
                data: device.locationList,
              };
            }
          });
          this.mapDevices = mapDevices;
          this.originalMapDevices = [...this.mapDevices];
          this.tableDevices = [...this.mapDevices];
        } else {
          this.mapDevices = [];
          this.originalMapDevices = [...this.mapDevices];
          this.tableDevices = [...this.mapDevices];
        }
      }
    });
  }

  // Borrado de test erróneo
  mapAction(action: string, test: CoverDevice): void {
    switch (action) {
      case "removeTest":
        this.CoverageTestService.removeTest(test);
        break;
      case "updateLocations":
        this.locations.map((location) => {
          location.postInstallation = location.createTimestamp > test.timestamp;
          let testLocation = test.locationList?.find(
            (testLocation) => testLocation.id == location.id
          );
          location.rssi = testLocation ? testLocation.rssi : null;
          location.snr = testLocation ? testLocation.snr : null;
          location.distance = testLocation ? testLocation.distance : null;
          location.pertenece = testLocation ? true : false;
        });
        this.mapController.showCoverageTestPath(test);
        break;
      default:
        break;
    }
  }

  // Activación de mapa 3d
  activate3dMap(): void {
    this.SessionDataService.sendCesiumData({
      active: true,
      devices: this.mapDevices,
      gateways: [],
      locations: this.locations,
      mapType: this.mapType,
      mapHeight: this.coveragePanel.nativeElement.offsetHeight,
      allowKml: true,
    });
  }

  // Actualización de datos de mapa por filtrado en tabla
  updateMapData(tableDevices: CoverDevice[]): void {
    if (this.avoidMapUpdate) {
      this.avoidMapUpdate = false;
    } else {
      this.avoidTableUpdate = true;
      this.mapController.searchUpdate(tableDevices);
    }
  }

  // Actualización de datos de tabla por filtrado en mapa
  updateTableData(mapDevices: CoverDevice[]): void {
    if (this.avoidTableUpdate) {
      this.avoidTableUpdate = false;
    } else {
      this.avoidMapUpdate = true;
      if (mapDevices) {
        this.tableDevices = [...mapDevices];
      } else {
        this.tableDevices = [...this.originalMapDevices];
      }
    }
  }
}
