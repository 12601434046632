<div class="home-map-filter">
  <!-- Filtros activos -->
  <div *ngIf="searchFiltersActive?.length > 0" class="table-search-filters">
    <div *ngFor="let filter of searchFiltersActive; index as i">
      <span>{{ filter.label }}</span
      ><span
        *ngIf="filter.label && !filter.espComparison"
        class="table-search-filter-sign"
        >=</span
      ><span [ngClass]="{ 'table-search-filter-inverse': filter.inverse }">{{
        filter.espComparison ? filter.inputDataString : filter.inputData
      }}</span>
      <i
        class="fas fa-strikethrough"
        (click)="invertActiveSearch(i)"
        [title]="'filter-inverted' | translate"
      ></i>
      <i
        class="fas fa-times"
        (click)="removeActiveSearch(i)"
        [title]="'filter-reset' | translate"
      ></i>
    </div>
  </div>

  <div
    class="home-map-filter-selector"
    *ngIf="filterOptionsByProfile?.length > 0"
  >
    <div>
      <!-- Desplegable de filtros -->
      <app-material-select
        [help]="'help-table-filters' | translate"
        [title]="'filter-by' | translate"
        [bindValue]="'value'"
        [bindLabel]="'name'"
        [selected]="preselectedFilter"
        [data]="filterOptionsByProfile"
        (selectedOption)="filterBySelectedOption($event)"
      ></app-material-select>
      <i
        class="fas fa-undo-alt pointer"
        (click)="resetFilters()"
        [title]="'filter-table-reset' | translate"
      ></i>
    </div>
  </div>
</div>
