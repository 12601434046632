import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeviceTypeService {
  constructor() {}

  // Obtención del tipo de dispositivo según su máscara de comunicaciones
  getDeviceTypeByMask(
    typeMask: number,
    metrologyType?: number,
    manufacturer?: number
  ): string {
    // Atributo "tipo": Es una máscara en un integer, que ocupa 32 bits, con lo que puede haber 32 tipos
    // All 0: Dispositivo externo sin comunicación
    // Bit 0: Lora
    // Bit 1: MBus
    // Bit 2: Wavenis
    // Bit 3: UNE
    // Bit 4: EK280/PLUM
    // Bit 5: Externo
    // Bit 6: ERM
    // Bit 7: NBIoT
    // Bit 8: TXN
    // Bit 9: Externo por API
    let meterTypeBinary: string = typeMask?.toString(2);

    if (typeMask == 0) {
      return "NO_LORA_NO_COM";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "1" &&
      !(meterTypeBinary?.charAt(meterTypeBinary.length - 4) == "1")
    ) {
      if (metrologyType == 5) {
        return "LW_MBUS_CON";
      } else {
        if (meterTypeBinary?.charAt(meterTypeBinary.length - 2) == "1") {
          return "LW_MBUS";
        } else {
          return "LW";
        }
      }
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 2) == "1"
    ) {
      return "MBUS";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 3) == "1"
    ) {
      return "WAVENIS";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "1" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 4) == "1"
    ) {
      return "LW_UNE_CON";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 4) == "1"
    ) {
      return "UNE";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 5) == "1"
    ) {
      if (manufacturer == 19) {
        return "EK280";
      } else if (manufacturer == 21) {
        return "PLUM";
      } else if (manufacturer == 15) {
        return "OWASYS";
      }
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 6) == "1"
    ) {
      return "EXTERNO";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 7) == "1"
    ) {
      return "ERM";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 8) == "1"
    ) {
      return "NBIOT";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 9) == "1"
    ) {
      return "TXN";
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 10) == "1"
    ) {
      return "API";
    } else {
      return null;
    }
  }

  // Obtención del tipo de dispositivo según su metrología
  getDeviceTypeByMetrology(metrologyType: number): string {
    switch (metrologyType) {
      case 1:
        return "SENSOR";
      case 3:
        return "LW_UNE";
      case 5:
        return "LW_MBUS";
      case 6:
        return "ACOUSTIC_SENSOR";
      default:
        return "METER";
    }
  }
}
