<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b
            >{{ "gateway-management" | translate }}:
            {{ gateway != null ? gateway : ("no-data" | translate) }}</b
          >
        </h5>
      </div>

      <div class="panel-body">
        <div class="management-gateway-groups">
          <app-material-select
            [title]="'group' | translate"
            [bindValue]="'id'"
            [bindLabel]="'value'"
            [noSelection]="true"
            [data]="groupOptions"
            (selectedOption)="groupSelected = $event?.id"
          ></app-material-select>

          <button mat-button (click)="createMaintenance()">
            {{ "new-maintenance" | translate }}
          </button>
        </div>

        <!-- Tabla de mantenimiento -->
        <app-table-controller
          [tableId]="'gatewayManagement'"
          [columns]="columnsMaintenance"
          [rowNumbers]="rowNumbersMaintenance"
          [maxReg]="tableMaxRegMaintenance"
          (tableAction)="
            tableActionsMaintenance($event.action.name, $event.element)
          "
          (extraTableAction)="
            extraTableActionsMaintenance(
              $event.action.name,
              $event.parentElement,
              $event.childElement
            )
          "
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getGatewayMaintenanceData($event.from, $event.to)"
          [specialFilter]="true"
          [data]="gatewayMaintenanceParentData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
