export class Manufacturer {
  public id?: string;
  public label?: string;
  public manufacturerText: {
    es: string;
    "es-ca": string;
    en: string;
    fr: string;
    it: string;
  };
  public type: string;
  public devices: object;

  constructor() {
    this.manufacturerText = {
      es: null,
      "es-ca": null,
      en: null,
      fr: null,
      it: null,
    };
    this.type = null;
    this.devices = null;
  }
}

export class DeviceType {
  public id?: string;
  public label?: string;
  public deviceText: {
    es: string;
    "es-ca": string;
    en: string;
    fr: string;
    it: string;
  };
  public type: string;

  constructor() {
    this.deviceText = {
      es: null,
      "es-ca": null,
      en: null,
      fr: null,
      it: null,
    };
    this.type = null;
  }
}

export const DEVICE_TYPES = [
  {
    id: "LW",
    label: "LoRaWAN",
  },
  {
    id: "LW MBUS",
    label: "LoRaWAN MBUS",
  },
  {
    id: "UNE",
    label: "UNE",
  },
  {
    id: "MBUS",
    label: "MBUS",
  },
  {
    id: "NO COM",
    label: "no-communication",
  },
  {
    id: "C5",
    label: "Cyble5",
  },
  {
    id: "EXTERNAL",
    label: "external",
  },
  {
    id: "EK280",
    label: "EK280",
  },
  {
    id: "PLUM",
    label: "PLUM",
  },
];
