import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { CesiumMapControllerComponent } from "../cesium-map-controller/cesium-map-controller.component";

@Component({
  selector: "app-cesium-map-container",
  templateUrl: "./cesium-map-container.component.html",
  styleUrls: ["./cesium-map-container.component.scss"],
})
export class CesiumMapContainerComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  agrupationSub: Subscription;

  // Cesium
  cesiumDataSub: Subscription;
  cesiumData: any;
  @ViewChild(CesiumMapControllerComponent)
  cesiumMapController: CesiumMapControllerComponent;
  @Output() cesiumMapActive = new EventEmitter<boolean>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private SessionDataService: SessionDataService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.cesiumDataSub = this.SessionDataService.getCesiumData().subscribe(
      (cesiumData) => {
        if (cesiumData?.devices) {
          this.cesiumData = cesiumData;
          this.cesiumMapActive.emit(this.cesiumData.active);
        } else if (this.cesiumData && !cesiumData?.active) {
          this.cesiumMapController?.resetMap();
        }
      }
    );

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() =>
      this.cesiumMapController?.resetMap()
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.cesiumDataSub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  resetMap(): void {
    this.cesiumData.active = false;
    this.cesiumMapActive.emit(false);
  }
}
