<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>

      <h5>
        <b>
          {{ entityCups?.name ? entityCups?.name : "CUPS" }}
          {{ "user-log" | translate }}:
          {{ cups != null ? cups : ("no-data" | translate) }}</b
        >
      </h5>
    </div>
    <div class="panel-body">
      <!-- Tabla de alarmas -->
      <app-table-controller
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="tableMaxReg"
        [dateRangeSelector]="true"
        [initialDate]="dataInitialDate"
        (dataRequest)="getData($event.from, $event.to)"
        [specialFilter]="true"
        [data]="tableData"
      >
      </app-table-controller>
    </div>
  </div>
</section>
