import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorService {
  constructor() {}

  isDarknessThan(color: string, threshold: number): boolean {
    color = color.substring(1); // strip #
    let rgb = parseInt(color, 16); // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue

    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < threshold;
  }

  lightenDarkenColor(col: string, amt: number) {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  getHexColor(namedColor: string) {
    let html = document.querySelector("html");
    let originalColor = html.style.color;
    html.style.color = namedColor;
    let rgbFormatBgColor = window.getComputedStyle(html).color;
    if (namedColor !== "black" && rgbFormatBgColor === "rgb(0, 0, 0)") {
      console.warn(`Not supported named color: ${namedColor}`);
    }
    html.style.color = originalColor;
    let regEx = /(\d+)/g;
    let result = rgbFormatBgColor.match(regEx);
    let hexColor = `#`;
    result.forEach((value) => {
      let hex = Number(value).toString(16);
      if (hex.length === 1) {
        hex = `0${hex}`;
      }
      hexColor += hex;
    });
    return hexColor;
  }

  hexToRgbA(hex: string, opacity: number) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
  }
}
