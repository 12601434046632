// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { ImportColumn } from "../../../../../modules/table-module/TableInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
// Componentes
import { ManufacturerDialogComponent } from "./manufacturer-dialog/manufacturer-dialog.component";
import { ValveStatesDialogComponent } from "./valve-states-dialog/valve-states-dialog.component";
// Variables
import { ImportResponseDevice, METER_IMPORT_COLUMNS } from "./import-columns";

@Component({
  selector: "app-device-import",
  templateUrl: "./device-import.component.html",
  styleUrls: ["./device-import.component.scss"],
})
export class DeviceImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Importación
  manufacturersList: {
    fabricante: string;
    manufacturer: string;
    DEV_TYPE: string;
    dispositivo: string;
  }[];
  devicesList: {
    fabricante: string;
    manufacturer: string;
    DEV_TYPE: string;
    dispositivo: string;
  }[];
  selectedManufacturer: string = "lw";
  importColumns: ImportColumn[];
  importPreselects: object[] = [
    { id: "lw", title: "LoRaWAN" },
    { id: "cyble5", title: "Cyble5IoT" },
    { id: "mbus", title: "WMBus" },
    { id: "lw-mbus", title: "Hub LoRaWAN MBus" },
    { id: "une", title: "UNE82326" },
    { id: "ek", title: "EK280 (MODBUS TCP)" },
    { id: "plum", title: "PLUM (GPRS)" },
    { id: "nocom", title: this.translate.instant("no-communication") },
  ];

  // Archivo csv
  errorFile: string;
  errorData: ImportResponseDevice[];
  errors: string[][];
  importFile: any;
  fileColumns: string[];
  nroSerieColumn: number;
  fileData: string[][];
  fileParts: string[][][];
  fileMaxLength: number = 500;

  // Tabla
  showTable: boolean = false;
  showResult: boolean = false;
  successDevices: number;
  exportFileName: string =
    this.translate.instant("import-result") +
    " " +
    this.DateParserService.getDate();
  orderBy: object = { attribute: "state", reverse: false };
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "state",
      data: "uploadState",
      search: "uploadState",
      sort: "uploadState",
      boolean: true,
      alter: {
        condition: "uploadState",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "manufacturer",
      data: "manufacturer",
      search: "manufacturer",
      sort: "manufacturer",
      extraInfo: true,
      visible: true,
    },
    {
      title: "device-type",
      data: "devType",
      search: "devType",
      sort: "devType",
      extraInfo: true,
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      extraInfo: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(): void {
    this.getImportColumns();
    this.getManufacturers();
  }

  // Obtención de las columnas a importar
  getImportColumns(): void {
    let importColumns: ImportColumn[] = JSON.parse(
      JSON.stringify(METER_IMPORT_COLUMNS)
    );
    importColumns.map((column: ImportColumn) => {
      column.profile =
        column.profilesList.length == 0
          ? true
          : column.profilesList.includes(this.sessionProfile);
      column.info = this.translate.instant(column.info);
      if (column.extraInfo) {
        column.extraInfo = this.translate.instant(column.extraInfo);
      }
    });
    this.importColumns = importColumns;
  }

  // Obtención de los datos de fabricantes
  getManufacturers(): void {
    let devicesData: object = this.ManufacturerService.getAllManufacturers();
    let devicesArray: {
      fabricante: string;
      manufacturer: string;
      DEV_TYPE: string;
      dispositivo: string;
    }[] = [];
    for (let manufacturer in devicesData) {
      for (let device in devicesData[manufacturer].devices) {
        if (manufacturer != "*") {
          devicesArray.push({
            fabricante: devicesData[manufacturer].manufacturerText[
              this.sessionLanguage
            ]
              ? devicesData[manufacturer].manufacturerText[this.sessionLanguage]
              : devicesData[manufacturer].manufacturerText["es"],
            manufacturer: manufacturer,
            DEV_TYPE: device,
            dispositivo: devicesData[manufacturer].devices[device].deviceText[
              this.sessionLanguage
            ]
              ? devicesData[manufacturer].devices[device].deviceText[
                  this.sessionLanguage
                ]
              : devicesData[manufacturer].devices[device].deviceText["es"],
          });
        }
      }
    }

    if (this.sessionProfile == "ARSON") {
      this.devicesList = [...devicesArray];
    } else {
      this.devicesList = [
        ...devicesArray.filter(
          (manufacturer: {
            fabricante: string;
            manufacturer: string;
            DEV_TYPE: string;
            dispositivo: string;
          }) => manufacturer.manufacturer != "7"
        ),
      ];
    }
    this.devicesList.sort((a, b) => a.fabricante.localeCompare(b.fabricante));
    this.manufacturersList = [];
    this.devicesList.forEach(
      (device: {
        fabricante: string;
        manufacturer: string;
        DEV_TYPE: string;
        dispositivo: string;
      }) => {
        if (
          !this.manufacturersList.find(
            (manufacturer: {
              fabricante: string;
              manufacturer: string;
              DEV_TYPE: string;
              dispositivo: string;
            }) => manufacturer.fabricante == device["fabricante"]
          )
        ) {
          this.manufacturersList.push(device);
        }
      }
    );
  }

  // Visualización del modal
  showModal(): void {
    this.selectedManufacturer = null;
    this.MaterialDialogService.openDialog(ManufacturerDialogComponent, {
      manufacturersList: this.manufacturersList,
      devicesList: this.devicesList,
      action: "manufacturers",
    });
  }

  // Modal de estados de válvula
  showValveModal(): void {
    this.MaterialDialogService.openDialog(ValveStatesDialogComponent);
  }

  // Comprobación de tamaño de archivo
  checkFile(file: File): void {
    this.importFile = file;
    let reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        let readedFile: any = reader.result;
        let filterRows = readedFile.split("\n");
        filterRows = filterRows.map((row: string) => {
          return row
            .split(";")
            .map((element: any) => element.replace("\r", ""));
        });
        this.fileColumns = [...filterRows[0]];
        this.nroSerieColumn = this.fileColumns.indexOf("NRO_SERIE");
        this.fileData = [...filterRows.slice(1)];
        if (this.fileData?.length > this.fileMaxLength) {
          this.ToastService.fireToast(
            "info",
            this.translate.instant("meter-import-file-parts")
          );
          this.getFileParts();
        }
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  }

  // Reseteo de archivo
  resetFileVariables(): void {
    this.errorFile = null;
    this.fileColumns = null;
    this.nroSerieColumn = null;
    this.fileData = null;
    this.fileParts = null;
  }

  // Obtención del archivo particionado
  getFileParts(): void {
    this.fileParts = [];
    let maxParts = Math.ceil(this.fileData.length / this.fileMaxLength);
    for (let i = 0; i < maxParts; i++) {
      this.fileParts[i] = this.fileData.slice(
        i * this.fileMaxLength,
        i * this.fileMaxLength + this.fileMaxLength
      );
      this.fileParts[i].unshift(this.fileColumns);
    }
  }

  // Reintentar fallidos
  retry(): void {
    this.fileData = [...this.errors];
    this.errorData = [];
    this.errorFile = null;
    this.fileParts = null;
    if (this.fileData?.length > this.fileMaxLength) {
      this.getFileParts();
    }
    if (this.fileParts?.length > 0) {
      this.SessionDataService.sendLockSpinner(true);
      this.processFilePart(0);
    } else {
      this.processFile(this.importFile, true);
    }
  }

  // Importación de dispositivo
  importDevice(): void {
    this.errorData = [];
    this.successDevices = 0;
    this.showResult = false;
    this.showTable = false;
    if (!this.importFile) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        "warning",
        this.translate.instant("question-import")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          if (this.fileParts?.length > 0) {
            this.SessionDataService.sendLockSpinner(true);
            this.processFilePart(0);
          } else {
            this.processFile(this.importFile, true);
          }
        }
      });
    }
  }

  // Finalización del procesamiento de archivo
  endFileProcess(): void {
    // Feedback
    if (this.errorData?.length == 0) {
      this.ToastService.fireToastWithConfirmation(
        "success",
        this.translate.instant("success-import") +
          ": " +
          this.successDevices +
          " " +
          this.translate.instant("imported-devices")
      );
    } else {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("some-meter-error")
      );
      // Descarga de archivo
      this.getErrorFile();
    }
    // Mostrar tabla
    this.showResult = true;
    this.showTable = this.errorData?.length > 0;
    if (this.showTable) {
      setTimeout(() => {
        this.refreshErrorTableData();
        this.viewportScroller.scrollToAnchor("import-errors");
      }, 0);
    }
  }

  // Refreso de datos de tabla
  refreshErrorTableData(): void {
    this.errorData = [...this.errorData];
  }

  // Procesamiento de archivo por partes
  processFilePart(index: number): void {
    this.SessionDataService.sendSpinnerText(
      this.translate.instant("meter-import-file-parts-process") +
        ": " +
        (index + 1) +
        "/" +
        this.fileParts.length
    );
    let blob = new Blob([
      this.fileParts[index]?.map((data: any) => data.join(";")).join("\r\n"),
    ]);
    let file = new File([blob], "file.csv");
    this.processFile(file, index == this.fileParts.length - 1, index);
  }

  // Procesamiento de archivo
  processFile(file: File, last: boolean, index?: number): void {
    let formData: FormData = new FormData();
    formData.set("file", file);
    this.MeterController.import(this.currentEntity.id, formData).subscribe(
      (response) => {
        if (response["code"] == 0 || response["code"] == 1) {
          response["body"]?.forEach((device: ImportResponseDevice) => {
            let deviceManufacturer: {
              manufacturerText: string;
              deviceText: string;
            } = this.ManufacturerService.getManufacturer(
              device?.idFabricante?.toString(),
              device?.idDevType?.toString(),
              this.sessionLanguage
            );
            device.manufacturer = deviceManufacturer.manufacturerText;
            device.devType = deviceManufacturer.deviceText;
            device.responseCodeText = this.translate.instant(
              "httpError" + device.codError
            );
            if (device.responseCodeText.includes("httpError")) {
              device.responseCodeText =
                this.translate.instant("error-text") + " " + device.codError;
            }

            // Tabla de errores
            if (device.codError != 0) {
              this.errorData.push(device);
            } else {
              this.successDevices++;
            }
          });
          if (last) {
            this.SessionDataService.sendLockSpinner(false);
            this.SessionDataService.clearSpinnerText();
            this.endFileProcess();
          } else {
            this.processFilePart(++index);
          }
        }
      }
    );
  }

  // Acciones de tabla
  tableActions(action: string, actionData: any): void {
    switch (action) {
      case "show-info":
        if (actionData == "show-valve-info") {
          this.showValveModal();
        } else {
          this.showModal();
        }
        break;
      case "update-preselect":
        this.selectedManufacturer = actionData;
        break;
      default:
        break;
    }
  }

  // Obtención del archivo filtrado con errores
  getErrorFile(): void {
    this.errors = this.fileData.filter((data: any) =>
      this.errorData.some(
        (error: ImportResponseDevice) =>
          error.nroSerie == data[this.nroSerieColumn]
      )
    );
    this.errorFile =
      this.fileColumns.join(";") +
      "\r\n" +
      this.errors.map((data: any) => data.join(";")).join("\r\n");
  }

  // Descarga de fichero de errores
  downloadErrorFile(): void {
    saveAs(
      new Blob([this.errorFile], {
        type: "text/csv",
      }),
      this.translate.instant("errors-list") + ".csv"
    );
  }

  // Modal de descarga de fichero KEM2
  kem2File(): void {
    this.MaterialDialogService.openDialog(ManufacturerDialogComponent, {
      action: "kem2",
    });
  }
}
