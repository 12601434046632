export const DEFAULT_FILES = {
  ATM: {
    profiles: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
    ],
    // Valleseco(71), El Tanque(96) y Lillo(107)
    entities: [71, 96, 107],
  },
  ATMGTnet: {
    profiles: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
    ],
    // Lillo(107)
    entities: [107],
  },
  GEISER: {
    profiles: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
    ],
    // Nortegas(5)
    clients: [5],
  },
  UGENA: {
    profiles: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
    ],
    // UGENA(109)
    entities: [109],
  },
  POSEIDONIA: {
    profiles: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
    ],
    // Puerto de Vigo(110)
    entities: [110],
  },
  IZFE: {
    profiles: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
    ],
    // Hernani(101)
    entities: [101],
  },
};

export interface DefaultFile {
  profiles: string[];
  clients: number[];
  entities: number[];
  agrupations: number[];
}
