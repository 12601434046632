// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { DragElementService } from "../../../services/shared/DragElementService.service";
// Interfaces
import { Task } from "./request-progress-task-interface";

@Component({
  selector: "app-request-progress-panel",
  templateUrl: "./request-progress-panel.component.html",
  styleUrls: ["./request-progress-panel.component.scss"],
})
export class RequestProgressPanelComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  wipSub: Subscription;
  wip: Task[];
  panelExpanded: boolean = true;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public DragElementService: DragElementService,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.wipSub = this.SessionDataService.getWip().subscribe((wip) => {
      this.wip = wip;
    });
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.wipSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Redirección a la tabla de progreso
  goToProgressTable(task: Task): void {
    this.SessionDataService.sendClient(task.client);
    this.SessionDataService.sendEntity(task.entity);
    this.SessionDataService.sendAgrupation(task.agrupation);
    this.router.navigate([task.url + "/" + task.taskIndex], {
      state: { data: task.taskIndex },
    });
  }
}
