<div class="login">
  <div class="error-page">
    <div class="panel panel-default">
      <div class="panel-body text-center">
        <i class="fas fa-exclamation-triangle"></i>
        <h3>{{ "access-denied" | translate }}</h3>
        <a class="btn btn-danger btn-block" (click)="goBack()">{{
          "back" | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
