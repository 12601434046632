// ANCHOR Índices de atributos de dispositivo
export const ATTRIBUTES = {
  NRO_SERIE: 0,
  MANUFACTURER: 1,
  DEV_TYPE: 2,
  ALIAS: 3,
  LORAWAN_VERSION: 4,
  DEV_EUI: 5,
  APP_EUI: 6,
  NETWORK_KEY: 7,
  APPLICATION_KEY: 8,
  TX_POWER: 9,
  MIN_SF: 10,
  RF_MODULE: 11,
  COMMENTS: 12,
  DESCRIPTION: 13,
  ADR_PERMISSION: 14,
  ACTIVATE: 15,
  AGRUPATION: 16,
  CYBLE5_ENC_KEY: 17,
  CYBLE5_AUTH_KEY: 18,
  MBUS_ADDRESS: 19,
  MBUS_KEY: 20,
  CODE: 21,
  SIM_ICCID: 22,
  LAST_READED_VALUE: 23,
  LAST_READED_YYYY_MM_DD: 24,
  SEAL: 25,
  TIMEZONE: 26,
  API: 27,
  USER: 28,
  PASSWORD: 29,
  EXTERNAL_ID: 30,
  VALVE_STATE: 31,
};

// ANCHOR Versiones LoRa
export const LORA_VERSION = ["1.0.1", "1.0.2", "1.0.3", "1.0.4"];

// ANCHOR SF
export const SF_LIST = [7, 8, 9, 10, 11, 12];

// ANCHOR TX POWER
export const TX_POWER_LIST = [0, 1, 2, 3, 4, 5, 6, 7];

// ANCHOR Estados de válvula
export const VALVE_STATES = [
  { id: 0, text: "valve-close" },
  {
    id: 1,
    text: "valve-open",
  },
  // { id: 2, text: "valve-pending" },
  // { id: 3, text: "close-command" },
  // { id: 4, text: "open-command" },
  // { id: 5, text: "read-command" },
  // { id: 6, text: "valve-disconnected" },
  // { id: 7, text: "valve-no-trigger" },
  // { id: 8, text: "valve-10-sent" },
  // { id: 9, text: "valve-50-sent" },
  // { id: 10, text: "valve-open-10" },
  // { id: 11, text: "valve-open-50" },
  // { id: 12, text: "valve-command-close-lost" },
  // { id: 13, text: "valve-command-open-lost" },
];

// ANCHOR Atributos genéricos
export const DEVICE_ATTRIBUTES = [
  // 0: NRO_SERIE
  {
    name: "NRO_SERIE",
    label: "serial-number",
    data: null,
    required: true,
    info: "nro-serie-import",
    maxlength: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 1: MANUFACTURER
  {
    name: "MANUFACTURER",
    label: "manufacturer",
    data: "",
    required: true,
    info: "manufacturer",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "select",
  },
  // 2: DEV_TYPE
  {
    name: "DEV_TYPE",
    label: "model",
    data: "",
    required: true,
    info: "dev-type-import",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "select",
  },
  // 3: ALIAS
  {
    name: "ALIAS",
    label: "alias",
    data: null,
    required: false,
    info: "alias-import",
    maxlength: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 4: LORAWAN_VERSION
  {
    name: "LORAWAN_VERSION",
    label: "lora-version",
    data: "",
    required: true,
    info: "lora-version-import",
    items: LORA_VERSION,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "select",
  },
  // 5: DEV_EUI
  {
    name: "DEV_EUI",
    label: "DEV EUI",
    data: null,
    required: true,
    info: "dev-eui-import",
    maxlength: 16,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 6: APP_EUI
  {
    name: "APP_EUI",
    label: "APP EUI",
    data: null,
    required: true,
    info: "app-eui-import",
    maxlength: 16,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 7: NETWORK_KEY
  {
    name: "NETWORK_KEY",
    label: "lora-version-import",
    data: null,
    required: false,
    info: "network-import",
    maxlength: 32,
    profile: ["ARSON"],
    type: "hex",
  },
  // 8: APPLICATION_KEY
  {
    name: "APPLICATION_KEY",
    label: "application-key-import",
    data: null,
    required: true,
    info: "application-key-import",
    maxlength: 32,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 9: TX_POWER
  {
    name: "TX_POWER",
    label: "max-power",
    data: "",
    required: false,
    info: "tx-import",
    items: TX_POWER_LIST,
    profile: ["ARSON"],
    type: "select",
  },
  // 10: MIN_SF
  {
    name: "MIN_SF",
    label: "sf-min",
    data: "",
    required: false,
    info: "sf-import",
    items: SF_LIST,
    profile: ["ARSON"],
    type: "select",
  },
  // 11: RF_MODULE
  {
    name: "RF_MODULE",
    label: "rf-module",
    data: null,
    required: false,
    info: "rf-module",
    maxlength: null,
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 12: COMMENTS
  {
    name: "COMMENTS",
    label: "comments",
    data: null,
    required: false,
    info: "comments",
    maxlength: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "textarea",
  },
  // 13: DESCRIPTION
  {
    name: "DESCRIPTION",
    label: "ek-description",
    data: null,
    required: false,
    info: "ek-description",
    maxlength: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "textarea",
  },
  // 14: ADR_PERMISSION
  {
    name: "ADR_PERMISSION",
    label: "adr-permission",
    data: null,
    required: false,
    info: "adr-import",
    maxlength: 1,
    profile: ["ARSON"],
    type: "boolean",
  },
  // 15: ACTIVATE
  {
    name: "ACTIVATE",
    label: "activate",
    data: null,
    required: false,
    info: "activate-import-meter",
    maxlength: 1,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "boolean",
  },
  // 16: AGRUPATION
  {
    name: "AGRUPATION",
    label: "groups",
    data: "",
    required: false,
    info: "groups",
    maxlength: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: null,
  },
  // 17: CYBLE5_ENC_KEY
  {
    name: "CYBLE5_ENC_KEY",
    label: "encrypt-key",
    data: null,
    required: true,
    info: "encrypt-key",
    maxlength: 32,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 18: CYBLE5_AUTH_KEY
  {
    name: "CYBLE5_AUTH_KEY",
    label: "authentication-key",
    data: null,
    required: true,
    info: "authentication-key",
    maxlength: 32,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 19: MBUS_ADDRESS
  {
    name: "MBUS_ADDRESS",
    label: "mbus-address",
    data: null,
    required: true,
    info: "mbus-address",
    maxlength: 16,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 20: MBUS_KEY
  {
    name: "MBUS_KEY",
    label: "mbus-key",
    data: null,
    required: true,
    info: "mbus-key",
    maxlength: 32,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 21: CODE
  {
    name: "CODE",
    label: "ek-code",
    data: null,
    required: true,
    info: "ek-code",
    maxlength: 8,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 22: SIM_ICCID
  {
    name: "SIM_ICCID",
    label: "sim-card",
    data: "",
    required: true,
    info: "sim-card",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: null,
  },
  // 23: LAST_READED_VALUE
  {
    name: "LAST_READED_VALUE",
    label: "last-know-value",
    data: "",
    required: false,
    info: "last-know-value",
    items: null,
    profile: ["ARSON"],
    type: "text",
  },
  // 24: LAST_READED_YYYY_MM_DD
  {
    name: "LAST_READED_YYYY_MM_DD",
    label: "date-last-value",
    data: "",
    required: false,
    info: "date-last-value",
    items: null,
    profile: ["ARSON"],
    type: "text",
  },
  // 25: SEAL
  {
    name: "SEAL",
    label: "seal",
    data: "",
    required: false,
    info: "seal",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 26: TIMEZONE
  {
    name: "TIMEZONE",
    label: "timezone",
    data: "",
    required: false,
    info: "timezone",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "select",
  },
  // 27: API
  {
    name: "API",
    label: "API",
    data: "",
    required: true,
    info: "API",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "select",
  },
  // 28: USER
  {
    name: "USER",
    label: "user",
    data: "",
    required: true,
    info: "user",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 29: PASSWORD
  {
    name: "PASSWORD",
    label: "password",
    data: "",
    required: true,
    info: "password",
    items: null,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "text",
  },
  // 30: EXTERNAL ID
  {
    name: "EXTERNAL_ID",
    label: "external-id",
    data: null,
    required: true,
    info: "external-id",
    maxlength: 8,
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    type: "hex",
  },
  // 31: VALVE_STATE
  {
    name: "VALVE_STATE",
    label: "valve-state",
    data: "",
    required: false,
    info: "vale-state",
    items: VALVE_STATES,
    profile: ["ARSON"],
    type: null,
  },
];

// ANCHOR Atributos para cada tipo de dispositivo
export const DEVICE_TYPES = [
  {
    id: "LW",
    title: "LoRaWAN",
    types: ["water", "gas"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "LORAWAN_VERSION",
      "DEV_EUI",
      "APP_EUI",
      "NETWORK_KEY",
      "APPLICATION_KEY",
      "TX_POWER",
      "MIN_SF",
      "RF_MODULE",
      "COMMENTS",
      "ADR_PERMISSION",
      "AGRUPATION",
      "ACTIVATE",
      "CYBLE5_ENC_KEY",
      "CYBLE5_AUTH_KEY",
      "LAST_READED_VALUE",
      "LAST_READED_YYYY_MM_DD",
      "SEAL",
      "TIMEZONE",
      "VALVE_STATE",
    ],
  },
  {
    id: "MBUS",
    title: "WMBus",
    types: ["water", "gas"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "RF_MODULE",
      "COMMENTS",
      "MBUS_ADDRESS",
      "AGRUPATION",
      "ACTIVATE",
      "MBUS_KEY",
      "LAST_READED_VALUE",
      "LAST_READED_YYYY_MM_DD",
      "SEAL",
      "TIMEZONE",
    ],
  },
  {
    id: "LW MBUS",
    title: "Hub LoRaWAN MBus",
    types: ["water", "gas"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "LORAWAN_VERSION",
      "DEV_EUI",
      "APP_EUI",
      "NETWORK_KEY",
      "APPLICATION_KEY",
      "TX_POWER",
      "MIN_SF",
      "RF_MODULE",
      "COMMENTS",
      "ADR_PERMISSION",
      "AGRUPATION",
      "ACTIVATE",
      "MBUS_ADDRESS",
      "MBUS_KEY",
      "LAST_READED_VALUE",
      "LAST_READED_YYYY_MM_DD",
      "SEAL",
      "TIMEZONE",
    ],
  },
  {
    id: "UNE",
    title: "UNE82326",
    types: ["water"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "RF_MODULE",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
      "MBUS_ADDRESS",
      "LAST_READED_VALUE",
      "LAST_READED_YYYY_MM_DD",
      "SEAL",
      "TIMEZONE",
    ],
  },
  {
    id: "EK280",
    title: "EK280 (MODBUS TCP)",
    types: ["gas"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "RF_MODULE",
      "COMMENTS",
      "DESCRIPTION",
      "AGRUPATION",
      "ACTIVATE",
      "CODE",
      "SIM_ICCID",
      "WINDOWS",
    ],
  },
  {
    id: "OWASYS",
    title: "OWASYS (MODBUS 4G)",
    types: ["gas"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "RF_MODULE",
      "COMMENTS",
      "DESCRIPTION",
      "AGRUPATION",
      "ACTIVATE",
      "CODE",
      "SIM_ICCID",
      "WINDOWS",
    ],
  },
  {
    id: "PLUM",
    title: "PLUM",
    types: ["gas"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "RF_MODULE",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
    ],
  },
  {
    id: "NBIoT",
    title: "NBIoT",
    types: ["water"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "RF_MODULE",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
    ],
  },
  {
    id: "NO COM",
    title: "no-lora-no-com",
    types: ["water"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
    ],
  },
  {
    id: "API",
    title: "api-external-device",
    types: ["water"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "RF_MODULE",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
      "API",
      "USER",
      "PASSWORD",
    ],
  },
  {
    id: "API_TOKEN",
    title: "api-external-token-device",
    types: ["water"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "RF_MODULE",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
      "USER",
      "PASSWORD",
      "EXTERNAL_ID",
    ],
  },
  {
    id: "EXTERNAL",
    title: "external",
    types: ["water"],
    attributes: [
      "NRO_SERIE",
      "DEV_TYPE",
      "MANUFACTURER",
      "ALIAS",
      "COMMENTS",
      "AGRUPATION",
      "ACTIVATE",
    ],
  },
];
