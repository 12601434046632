<div class="login">
  <div class="error-page">
    <div class="panel panel-default">
      <div class="panel-body text-center">
        <i class="fas fa-map-signs"></i>
        <h3>{{ "not-found" | translate }}</h3>
        <a class="btn btn-warning btn-block" (click)="goBack()">{{
          "back" | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
