import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DomControllerService {
  constructor() {}

  elementReady(selector: string): Promise<Element> {
    return new Promise((resolve, reject) => {
      let el = document.querySelector(selector);
      if (el) {
        resolve(el);
        return el;
      }
      new MutationObserver((mutationRecords, observer) => {
        // Query for elements matching the specified selector
        Array.from(document.querySelectorAll(selector)).forEach((element) => {
          resolve(element);
          //Once we have resolved we don't need the observer anymore.
          observer.disconnect();
        });
      }).observe(document.documentElement, {
        childList: true,
        subtree: true,
      });
    });
  }

  // Centrado de elemento en pantalla
  goToElement(selector: string): void {
    this.elementReady(selector).then((element: Element) => {
      setTimeout(
        () => element.scrollIntoView({ block: "start", inline: "nearest" }),
        0
      );
    });
  }
}
