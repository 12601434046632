<!-- Modal de filtrado de tabla -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="modal-title">{{ "filters" | translate }}</h5>
  </div>

  <div *ngIf="data.comparisonType" class="dialog-body">
    <!-- Comparación booleana -->
    <div *ngIf="data.comparisonType == 'boolean'" class="table-filter-boolean">
      <button
        mat-button
        class="mat-button-icon"
        [color]="booleanSelection ? 'primary' : null"
        (click)="booleanSelection = true"
      >
        <i class="fas fa-check-circle"></i>
      </button>
      <button
        mat-button
        class="mat-button-icon"
        [color]="booleanSelection == false ? 'primary' : null"
        (click)="booleanSelection = false"
      >
        <i class="fas fa-times-circle"></i>
      </button>
    </div>

    <!-- Comparación numérica -->
    <div
      *ngIf="data.comparisonType == 'numerical'"
      class="table-filter-numerical"
    >
      <ng-container
        *ngFor="let comparison of numericalComparisonList; index as i"
      >
        <div>
          <select
            id="numerical-comparison-select"
            [(ngModel)]="comparison.selected"
            (change)="addDisabled = comparison.selected == '=='"
          >
            <option
              *ngFor="let comparisonOption of comparisonOptions"
              [ngClass]="{ hidden: i > 0 && comparisonOption == '==' }"
              value="{{ comparisonOption }}"
            >
              {{ comparisonOption }}
            </option>
          </select>
          <input
            type="number"
            id="numerical-comparison-input"
            [(ngModel)]="comparison.value"
            placeholder="{{ 'value' | translate }}"
            (change)="checkNumericalInput()"
            (keyup)="checkNumericalInput()"
            (keyup.enter)="
              filterTable();
              SessionDataService.sendDialogAction({ action: 'close' })
            "
          />
        </div>
      </ng-container>
      <div>
        <button
          mat-button
          class="mat-button-icon"
          (click)="addNumericalComparison()"
          [disabled]="numericalComparisonList.length == 2"
        >
          <i class="fas fa-plus"></i>
        </button>
        <button
          mat-button
          class="mat-button-icon"
          (click)="removeNumericalComparison()"
          [disabled]="numericalComparisonList.length == 1"
        >
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>

    <!-- Comparación de fechas -->
    <div *ngIf="data.comparisonType == 'date'" class="table-filter-numerical">
      <ng-container *ngFor="let comparison of dateComparisonList; index as i">
        <div>
          <select
            id="date-comparison-select"
            [(ngModel)]="comparison.selected"
            (change)="addDisabled = comparison.selected == '=='"
          >
            <option
              *ngFor="let comparisonOption of comparisonOptions"
              [ngClass]="{
                hidden:
                  comparisonOption == '==' &&
                  (i > 0 || dateComparisonList.length > 1)
              }"
              value="{{ comparisonOption }}"
            >
              {{ comparisonOption }}
            </option>
          </select>
          <input
            type="date"
            id="date-comparison-input"
            [(ngModel)]="comparison.date"
            (change)="checkDateInput()"
          />
          <input
            *ngIf="comparison.selected != '=='"
            type="time"
            id="date-comparison-input"
            [(ngModel)]="comparison.time"
            (change)="checkDateInput()"
          />
        </div>
      </ng-container>
      <div>
        <button
          mat-button
          class="mat-button-icon"
          (click)="addDateComparison()"
          [disabled]="dateComparisonList.length == 2 || addDisabled"
        >
          <i class="fas fa-plus"></i>
        </button>
        <button
          mat-button
          class="mat-button-icon"
          (click)="removeDateComparison()"
          [disabled]="dateComparisonList.length == 1"
        >
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>

    <!-- Mostrar valores nulos -->
    <mat-checkbox
      class="dialog-checkbox"
      [color]="'primary'"
      [(ngModel)]="showNull"
      >{{ "show-null" | translate }}</mat-checkbox
    >
  </div>

  <div class="dialog-footer">
    <!-- Filtrar -->
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="
        filterTable(); SessionDataService.sendDialogAction({ action: 'close' })
      "
      [disabled]="
        (data.comparisonType == 'boolean' && booleanSelection == null) ||
        (data.comparisonType == 'numerical' && !numericalInputCheck) ||
        (data.comparisonType == 'date' && !dateInputCheck)
      "
    >
      <i class="fas fa-filter"></i>
      {{ "filter-map" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
