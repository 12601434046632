import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";

@Component({
  selector: "app-meter-unassigned-dialog",
  templateUrl: "./meter-unassigned-dialog.component.html",
  styleUrls: ["./meter-unassigned-dialog.component.scss"],
})
export class MeterUnassignedDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de cambios de edición
  saveMeter(): void {
    let data = {
      id: this.data.id,
      devEui: this.data.devEui,
      appEui: this.data.appEui,
      networkKey: this.data.networkKey,
      applicationKey: this.data.applicationKey,
      otherKeys: this.data.otherKeys,
    };
    this.MeterController.editMeterNotAssign(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("meter-success-edit")
        );
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }

  // Optimización de ngFor para el renderizado de los cambios
  trackByFn(index: number, item: any): number {
    return index;
  }
}
