<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "relaunch-tail" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <!-- Relanzar cola -->
    <div class="management-relaunch-tail">
      <app-material-select
        [title]="'relaunch-tail' | translate"
        [bindValue]="'gatewayId'"
        [bindLabel]="'gateway'"
        [data]="data"
        (selectedOption)="selectedGateway = $event?.gatewayId"
      ></app-material-select>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button class="mat-button-icon-text" (click)="relaunchTail()">
      <i class="fas fa-redo-alt"></i>
      {{ "relaunch-tail" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
