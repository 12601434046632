// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { AudioService } from "../../../../services/shared/AudioService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import { ALARM_STATES, AlarmData } from "../AlarmInterface.type";
// Componentes
import { AlarmEditDialogComponent } from "../alarm-edit-dialog/alarm-edit-dialog.component";
// Variables
import { LOCAL_TIMEZONE } from "../../../../global/LOCAL_TIMEZONE";

@Component({
  selector: "app-alarms-global",
  templateUrl: "./alarms-global.component.html",
  styleUrls: ["./alarms-global.component.scss"],
})
export class AlarmsGlobalComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tabla
  tableData: AlarmData[];
  prevAlarms: AlarmData[];
  activeAlarms: number;
  tableSelectedData: AlarmData[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  claveMaestraKeyVisibility: boolean = null;
  exportFileName: string =
    this.translate.instant("alarms-export") +
    " " +
    this.DateParserService.getDate();
  tableHighlightRow: TableHighlightRow[] = [
    { condition: "newAlarm", color: "red", title: "alarm-new" },
  ];
  quickFiltersExclusion: boolean[] = [true];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "active",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: false },
        active: false,
      },
      {
        name: "inactive",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: "disabled",
        columnSearch: "disabled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "enabled",
        columnSearch: "enabled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
  ];
  columns: (TableActionColumn | TableDataColumn)[];
  showAgrupationTimezone: boolean = false;

  // Modal
  dialog: Subscription;

  // Horas
  alarmHours: number = 1;
  alarmRefresh: number = 1;
  alarmTimer: any;
  alarmSound: string = "../../../../../assets/sounds/alarm.wav";
  soundActive: boolean = true;

  // Mapa
  mapType: string = "alarmsMeterList";
  mapHeight: number = 400;
  devices: AlarmData[];
  gateways: [] = [];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private AudioService: AudioService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MaterialDialogService: MaterialDialogService,
    private SessionDataService: SessionDataService,
    public ToastService: ToastService,
    public translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.dialog = this.SessionDataService.getDialogAction().subscribe((dialogAction) => {
      if (dialogAction.action == "reload") {
        this.getAlarms(this.from, this.to);
      }
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.dialog.unsubscribe();
    clearInterval(this.alarmTimer);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let now = this.DateParserService.getNow();
    this.getAlarms(
      (now.valueOf() - 3600000 * this.alarmHours).toString(),
      now.valueOf().toString()
    );
    this.updateAlarmRefresh();
  }

  // Obtención de los datos de alarmas
  getAlarms(from: string, to: string): void {
    this.from = from;
    this.to = to;
    if (this.tableData) {
      this.prevAlarms = JSON.parse(JSON.stringify(this.tableData));
    }
    this.AlarmController.getGlobalAlarmsFromTo(from, to).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let claveMaestraKeyArray: string[] = [];
          response["body"].forEach((alarm: AlarmData) => {
            alarm.metrologyType = alarm.metrology_type;
            // Claves maestras
            claveMaestraKeyArray.push(alarm.claveMaestraKey);
            // Código de alarma
            alarm.code != null
              ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
              : "";
            alarm.code != null
              ? (alarm.description = this.translate.instant(
                  "AlertDescription" + alarm.code
                ))
              : "";
            if (
              alarm.description == "" ||
              alarm.description == "AlertDescription" + alarm.code
            ) {
              alarm.description = this.translate.instant("no-details");
            }
            alarm.enabled = !alarm.disabled;
            // Estado de la alarma
            alarm.stateParsed = ALARM_STATES[alarm.state]
              ? this.translate.instant(ALARM_STATES[alarm.state])
              : null;
            // Información extra
            alarm.modalInfo = true;
            alarm.modalInfoData = {
              title: this.translate.instant("alarm-info"),
              html:
                `<h4><b> (` +
                this.translate.instant("code") +
                " " +
                alarm.code +
                ") " +
                alarm.name +
                `: </b></h4>
                <pre class="pre-without-format">` +
                alarm.description +
                `</pre>`,
            };
            // Zona horaria
            if (alarm.timezone && alarm.timezone != LOCAL_TIMEZONE) {
              this.showAgrupationTimezone = true;
            }
          });
          // Visibilidad de la columna de clave maestra
          if (claveMaestraKeyArray.length > 0) {
            if (claveMaestraKeyArray.reduce((a, b) => a || b)) {
              this.claveMaestraKeyVisibility = true;
            } else {
              this.claveMaestraKeyVisibility = null;
            }
          }
          this.tableData = response["body"];
          this.activeAlarms = this.tableData.filter(
            (alarm: AlarmData) => alarm.enabled
          )?.length;
          this.checkNewAlarms();
          this.setColumns();
          this.showOnMap();
        }
      }
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "disable",
            tooltip: "disable",
            icon: "fas fa-eraser disable",
            visible: { attribute: "disabled", rule: false },
            disabled: false,
            warning: true,
          },
          {
            name: "enable",
            tooltip: "enable",
            icon: "fas fa-plus enable",
            visible: { attribute: "disabled", rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: "CUPS",
        data: "claveMaestraKey",
        search: "claveMaestraKey",
        sort: "claveMaestraKey",
        visible: this.claveMaestraKeyVisibility,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "entity",
        data: "entity",
        search: "entity",
        sort: "entity",
        visible: true,
      },
      {
        title: "groups",
        data: "agrupation",
        search: "agrupation",
        sort: "agrupation",
        visible: true,
      },
      {
        title: "enabled",
        data: "enabled",
        search: "enabled",
        sort: "enabled",
        alter: {
          condition: "enabled",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "state",
        visible: true,
      },
      {
        title: "start-date-local",
        data: "initLocalDateParsed",
        search: "initLocalDateParsed",
        sort: "initDate",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "end-date-local",
        data: "finalLocalDateParsed",
        search: "finalLocalDateParsed",
        sort: "finalDate",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "start-date",
        data: "initDateParsed",
        search: "initDateParsed",
        sort: "initDate",
        date: true,
        visible: this.showAgrupationTimezone ? true : null,
      },
      {
        title: "end-date",
        data: "finalDateParsed",
        search: "finalDateParsed",
        sort: "finalDate",
        date: true,
        visible: this.showAgrupationTimezone ? true : null,
      },
      {
        title: "alarms",
        data: "name",
        search: "name",
        sort: "name",
        modalInfo: true,
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, alarm: AlarmData, rightClick?: boolean): void {
    switch (action) {
      case "show-detail":
        this.updateAgrupation(alarm);
        this.DeviceRouteSelectorService.getDeviceRoute(
          alarm.metrology_type,
          alarm.contador
        );
        break;
      case "edit":
        this.editAlarm(alarm);
        break;
      case "enable":
        this.enableAlarm(alarm);
        break;
      case "disable":
        this.disableAlarm(alarm);
        break;
      default:
        break;
    }
  }

  // Habilitar alarma
  enableAlarm(alarm: AlarmData): void {
    this.ToastService
      .fireAlertWithOptions(
        "warning",
        this.translate.instant("alarm-enabled-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.AlarmController.enableAlarms(alarm.id).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("alarm-enabled")
              );
              this.getAlarms(this.from, this.to);
            }
          });
        }
      });
  }

  // Deshabilitar alarma
  disableAlarm(alarm: AlarmData): void {
    this.ToastService
      .fireAlertWithOptions(
        "warning",
        this.translate.instant("alarm-disabled-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.AlarmController.disableAlarms(alarm.id).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("alarm-disabled")
              );
              this.getAlarms(this.from, this.to);
            }
          });
        }
      });
  }

  // Editar alarma
  editAlarm(alarm: AlarmData): void {
    this.MaterialDialogService.openDialog(AlarmEditDialogComponent, [alarm]);
  }

  // Actualización de los datos seleccionados en la tabla
  selectedDataUpdate(data: AlarmData[]): void {
    this.tableSelectedData = [...data];
  }

  // Obtención de alarmas
  updateAlarmRefresh(): void {
    clearInterval(this.alarmTimer);
    this.alarmTimer = setInterval(() => {
      let now = this.DateParserService.getNow();
      this.getAlarms(
        (now.valueOf() - 3600000 * this.alarmHours).toString(),
        now.valueOf().toString()
      );
    }, this.alarmRefresh * 60 * 1000);
  }

  // Actualización de la agrupación en curso acorde al contador seleccionado
  updateAgrupation(alarm: AlarmData): void {
    this.DeviceRouteSelectorService.updateEntity(alarm.entityId);
    this.DeviceRouteSelectorService.updateAgrupation(alarm.agrupationId);
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    let devices: AlarmData[] = [];
    this.tableData.forEach((device: AlarmData) => {
      let pushedDeviceIndex: number = devices?.findIndex(
        (pushedDevice: AlarmData) => pushedDevice.nroSerie == device.nroSerie
      );
      if (pushedDeviceIndex >= 0) {
        if (devices[pushedDeviceIndex].initDate < device.initDate) {
          devices.splice(pushedDeviceIndex, 1);
          devices.push(device);
        }
      } else {
        devices.push(device);
      }
    });
    this.devices = devices.filter((device: AlarmData) => device.enabled);
  }

  // Comprobación de si existen nuevas alarmas
  checkNewAlarms() {
    if (this.prevAlarms && this.tableData) {
      this.tableData.map((alarm: AlarmData) => {
        if (
          this.prevAlarms.find(
            (prevAlarm: AlarmData) => prevAlarm.id == alarm.id
          )
        ) {
          alarm.newAlarm = false;
        } else {
          alarm.newAlarm = true;
        }
      });
      if (
        this.soundActive &&
        this.tableData.some(
          (alarm: AlarmData) => alarm.newAlarm && alarm.enabled
        )
      ) {
        this.AudioService.play(this.alarmSound);
      }
    }
  }
}
