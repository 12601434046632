// @angular
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Interfaces
import {
  AllowedModels,
  DeviceManufacturer,
  DeviceModel,
} from "./device-type-filter.type";

@Component({
  selector: "app-device-type-filter",
  templateUrl: "./device-type-filter.component.html",
  styleUrls: ["./device-type-filter.component.scss"],
})
export class DeviceTypeFilterComponent implements OnInit {
  sessionLanguage: string;
  manufacturerList: DeviceManufacturer[];
  modelList: DeviceModel[];
  manufacturerModelList: DeviceModel[];
  selectedManufacturer: string;
  selectedModel: { manufacturerId: string; device: string };
  @Input() tableFilterActive: boolean;
  @Output() tableFilter = new EventEmitter<{ data: string; value: string }[]>();
  @Input()
  get deviceList(): any[] {
    return this._deviceList;
  }
  set deviceList(deviceList: any[]) {
    this._deviceList = deviceList;
    this.getDropdowns();
  }
  _deviceList: any;
  @Input()
  get allowedDevices(): AllowedModels[] {
    return this._allowedDevices;
  }
  set allowedDevices(allowedDevices: AllowedModels[]) {
    this._allowedDevices = allowedDevices;
    this.getDropdowns();
  }
  _allowedDevices: any;
  @Input() linkFilters: boolean;
  @Output() selectionUpdate = new EventEmitter<any>();

  constructor(
    private ManufacturerService: ManufacturerService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.getDropdowns();
  }

  // Obtención de los desplegables de fabricante y modelo
  getDropdowns(): void {
    let manufacturerData: object =
      this.ManufacturerService.getAllManufacturers();
    let modelList: DeviceModel[] = [];
    let manufacturerList: DeviceManufacturer[] = [];

    for (let manufacturer in manufacturerData) {
      let manufacturerAlreadyAdded: boolean = false;
      for (let device in manufacturerData[manufacturer].devices) {
        if (manufacturer != "*") {
          let deviceFound: boolean;

          // Comprobación de si el tipo de dispositivo existe en la lista de dispositivos
          if (this._deviceList) {
            deviceFound = this._deviceList?.some(
              (dev: any) =>
                dev.idFabricante == parseInt(manufacturer) &&
                dev.idDevType == parseInt(device)
            );
          }

          // Comprobación de si el modelo de dispositivo existe en la lista de fabricantes
          if (this._allowedDevices) {
            deviceFound = this._allowedDevices?.some(
              (allowedManufacturer: { id: number; devTypes: number[] }) => {
                return (
                  allowedManufacturer.id == parseInt(manufacturer) &&
                  allowedManufacturer.devTypes.includes(parseInt(device))
                );
              }
            );
          }

          // Filtrado
          if (
            (!this._deviceList && !this._allowedDevices) ||
            (this._deviceList && deviceFound) ||
            (this._allowedDevices && deviceFound)
          ) {
            modelList.push({
              model: { manufacturerId: manufacturer, device: device },
              modelText: manufacturerData[manufacturer].devices[device]
                .deviceText[this.sessionLanguage]
                ? manufacturerData[manufacturer].devices[device].deviceText[
                    this.sessionLanguage
                  ]
                : manufacturerData[manufacturer].devices[device].deviceText[
                    "es"
                  ],
            });
            if (!manufacturerAlreadyAdded) {
              manufacturerList.push({
                manufacturerId: manufacturer,
                manufacturerText: manufacturerData[manufacturer]
                  .manufacturerText[this.sessionLanguage]
                  ? manufacturerData[manufacturer].manufacturerText[
                      this.sessionLanguage
                    ]
                  : manufacturerData[manufacturer].manufacturerText["es"],
              });
              manufacturerAlreadyAdded = true;
            }
          }
        }
      }
    }

    // Lista de fabricantes
    this.manufacturerList = manufacturerList.sort((a, b) =>
      a?.manufacturerText?.localeCompare(b?.manufacturerText)
    );
    // Lista de modelos
    this.modelList = modelList.sort((a, b) =>
      a?.modelText?.localeCompare(b?.modelText)
    );
  }

  // Filtrado de modelo por fabricante
  getManufacturerModelList(): void {
    this.selectedModel = null;
    this.manufacturerModelList = this.modelList.filter(
      (modelType: DeviceModel) =>
        modelType.model.manufacturerId === this.selectedManufacturer
    );
  }

  // Actualización de selección
  updateSelection(): void {
    if (this.selectedManufacturer == null && this.linkFilters) {
      this.selectedModel = null;
    }
    this.selectionUpdate.emit({
      manufacturer: this.selectedManufacturer,
      model: this.selectedModel,
    });
    if (this.tableFilterActive) {
      this.getTableFilter();
    }
  }

  // Filtrado de la tabla por fabricante o modelo
  getTableFilter(): void {
    this.tableFilter.emit([
      {
        data: "fabricante",
        value: this.manufacturerList.find(
          (manufacturer: DeviceManufacturer) =>
            manufacturer.manufacturerId == this.selectedManufacturer
        )?.manufacturerText,
      },
      {
        data: "devType",
        value: this.modelList.find(
          (devType: DeviceModel) => devType.model == this.selectedModel
        )?.modelText,
      },
    ]);
  }

  // Limpieza de filtros
  resetFilter() {
    this.selectedManufacturer = null;
    this.selectedModel = null;
  }
}
