// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { AssociationControllerService } from "../../../../services/server/AssociationController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { DataManagementConfigurationDialogComponent } from "./data-management-configuration-dialog/data-management-configuration-dialog.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-mangementconfiguration",
  templateUrl: "./data-management-configuration.component.html",
  styleUrls: ["./data-management-configuration.component.scss"],
})
export class ManagementConfigurationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;

  // Guardar/Nuevo
  saveButtonTittle: string = this.translate.instant("save");
  saveButtonIcon: string = "fas fa-save";
  newIcon: string = "fa fa-plus";
  newTitle: string = this.translate.instant("new");

  // Tabla
  maxReg: number = 10;
  tableData: EntityDefinition[];
  exportFileName: string =
    this.translate.instant("extra-data-export") + " " + moment().format("ll");
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "edit",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "required",
      data: "required",
      search: "required",
      sort: "required",
      alter: {
        condition: "required",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "name",
      data: "name",
      search: "name",
      sort: "name",
      visible: true,
    },
    {
      title: "description",
      data: "description",
      search: "description",
      sort: "description",
      visible: true,
    },
    {
      title: "label",
      data: "label",
      search: "label",
      sort: "label",
      visible: true,
    },
    {
      title: "show-table",
      data: "show",
      search: "show",
      sort: "show",
      alter: {
        condition: "show",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
  ];

  // Datos extra
  serialNumber: EntityDefinition;
  nroSerieLabel: string;
  cups: EntityDefinition;
  cupsExtraDataEnabled: boolean;
  extraColumns: EntityDefinition[];

  // Flag de nuevo CUPS
  newCupsFlag: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de campos extra
  getData(): void {
    this.AssociationController.getCupsExtraData(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"] != null) {
          this.serialNumber = response["body"].find(
            (element: EntityDefinition) => element.colPosition == 100
          );
          this.nroSerieLabel = this.serialNumber
            ? this.serialNumber.label
            : "NRO_SERIE";
          this.cups = response["body"].find(
            (element: EntityDefinition) => element.colPosition == 0
          );
          if (this.cups) {
            this.cupsExtraDataEnabled = true;
          } else {
            this.cupsExtraDataEnabled = false;
            this.newCupsFlag = true;
            this.cups = new EntityDefinition();
          }
          this.extraColumns = response["body"].filter(
            (element: EntityDefinition) =>
              element.colPosition > 0 && element.colPosition < 99
          );
          this.tableData = this.extraColumns;
        } else {
          this.tableData = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, extraColumn: EntityDefinition): void {
    switch (action) {
      case "edit":
        this.newExtraColumn(extraColumn);
        break;
      case "delete":
        this.deleteExtraColumn(extraColumn);
        break;
      default:
        break;
    }
  }

  // Creación de nuevo dato extra
  newExtraColumn(extraColumn?: EntityDefinition): void {
    this.MaterialDialogService.openDialog(
      DataManagementConfigurationDialogComponent,
      extraColumn
        ? JSON.parse(JSON.stringify(extraColumn))
        : new EntityDefinition()
    );
  }

  // Actualización de estado de los datos extra
  updateCupsOptions(): void {
    if (!this.cupsExtraDataEnabled) {
      this.cupsExtraDataEnabled = !this.cupsExtraDataEnabled;
    }
  }

  // Guardado del label para el número de serie
  saveNroSerieLabel(): void {
    let data: EntityDefinition = {
      label: this.nroSerieLabel,
      required: true,
      fieldType: 2,
    };

    this.AssociationController.saveExtraData(
      this.currentEntity.id,
      data
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("serial-number-label-saved")
        );
        this.getData();
      }
    });
  }

  // Actualización del label para el número de serie
  updateNroSerieLabel(): void {
    let data: EntityDefinition = {
      id: this.serialNumber.id,
      label: this.nroSerieLabel,
      required: true,
      fieldType: 2,
      name: this.serialNumber.name,
      description: this.serialNumber.description,
    };

    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("edit-serial-number-label")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.AssociationController.updateExtraData(
            this.currentEntity.id,
            data
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("serial-number-label-edit")
              );
              this.getData();
            }
          });
        }
      });
  }

  // Guardado de cups
  saveCups(): void {
    this.cups.fieldType = 1;
    this.cups.required = true;

    this.AssociationController.saveExtraData(
      this.currentEntity.id,
      this.cups
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("key-saved"));
        this.AssociationController.listEntityConfig(
          this.currentEntity.id
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.cups = response["body"].find(
              (element: EntityDefinition) => element.colPosition == 0
            );
            this.getData();
            this.SessionDataService.clearUpdateNavFlag();
          }
        });
      }
    });
  }

  // Actualización de cups
  updateCups(): void {
    this.cups.required = true;
    this.cups.fieldType = 1;

    this.ToastService
      .fireAlertWithOptions("question", this.translate.instant("edit-key"))
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.AssociationController.updateExtraData(
            this.currentEntity.id,
            this.cups
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("key-edit")
              );
              this.getData();
              this.SessionDataService.clearUpdateNavFlag();
            }
          });
        }
      });
  }

  // Borrado de dato extra
  deleteExtraColumn(extraColumn: EntityDefinition): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("delete-extra-data")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.AssociationController.deleteExtraData(
            extraColumn.id,
            this.currentEntity.id
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("elements-deleted")
              );
              this.SessionDataService.sendUpdateEntityCupsConfFlag();
              this.getData();
            }
          });
        }
      });
  }
}
