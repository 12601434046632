import { Injectable } from "@angular/core";
import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from "@angular/router";
import { SessionDataService } from "./services/shared/SessionDataService.service";

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: DetachedRouteHandle } = {};

  constructor(private SessionDataService: SessionDataService) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[route.url.join("/") || route.parent.url.join("/")] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // return !!this.handlers[url];
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.handlers[route.url.join("/") || route.parent.url.join("/")];
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // return future.routeConfig === curr.routeConfig;
    // Flag de recarga de url
    let urlReloading = this.SessionDataService.getCurrentUrlReloadFlag();
    // Comprobación de que no haya recarga ni carga de datos de sesión
    if (!urlReloading && !this.SessionDataService.getCurrentSessionLoadingFlag()) {
      // Si la ruta coincide se evita la recarga
      if (future.url === curr.url) {
        return true;
      } else {
        return false;
      }
    } else {
      // Si existía recarga, se finaliza el flag
      if (urlReloading) {
        this.SessionDataService.clearUrlReloadFlag();
      }
      return false;
    }
  }
}
