import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  HostListener,
  TemplateRef,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment";
// Servicios propios
import { DatePickerConfigService } from "../../../../../services/shared/DatePickerConfigService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { SateliteControllerService } from "../../../../../services/server/SateliteController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
// Componentes
import { SateliteOffsetDialogComponent } from "./satelite-offset-dialog/satelite-offset-dialog.component";
// Interfaces
import {
  SateliteSchedule,
  SateliteTurn,
  WEEK,
  SateliteData,
  SateliteSaveData,
  SateliteTurnData,
} from "../../SateliteInterface.type";

@Component({
  selector: "app-satelite-scheduler",
  templateUrl: "./satelite-scheduler.component.html",
  styleUrls: ["./satelite-scheduler.component.scss"],
})
export class SateliteSchedulerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  timebarInititated: boolean = false;
  maxHour: number = 24;
  maxHourExtended: boolean = false;
  maxTurns: number = 6;
  maxTimeLine: number = 24;
  schedulerOffsetHour: number;
  schedulerOffsetMinute: number;
  schedulerDaysEmpty: boolean = false;
  schedulerIntervalEmpty: boolean = false;
  offsetSign: number = 1;
  @Input() device: any;
  sateliteScheduler: SateliteSchedule[];
  originalSateliteScheduler: SateliteSchedule[];
  currentScheduler: number = 0;
  otherScheduler: number = 1;
  daterangePickerLang: any;
  daterangePickerRanges: any;
  week = WEEK;
  dialog: Subscription;

  // Turnos
  @ViewChild("scheduleTurns") scheduleTurns: ElementRef;
  turnBackgroundColors: string[];
  turnBorderColors: string[];
  timeOpen: string;
  inputTimeout: any;

  // Guardado
  saveIcon = "fas fa-save";
  saveTitle = this.translate.instant("save");

  // Caudal
  @Input()
  get sateliteFlow(): number[][] {
    return this._sateliteFlow;
  }
  set sateliteFlow(sateliteFlow: number[][]) {
    this._sateliteFlow = sateliteFlow;
    this.getFlow();
  }
  _sateliteFlow: number[][];
  flowMinutes: number;
  flow: string;

  // Arrastrar turno
  mouseX: number;
  turnEdition: string;
  turnEditionIndex: number;
  preventTurnMove: boolean = false;

  // Tamaño del slider de activación
  activeSliderWidth: number = window.innerWidth > 980 ? 200 : 99;
  activeSliderHeight: number = window.innerWidth > 980 ? 34 : 40;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.activeSliderWidth = window.innerWidth > 980 ? 200 : 99;
    this.activeSliderHeight = window.innerWidth > 980 ? 34 : 40;
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DatePickerConfigService: DatePickerConfigService,
    private element: ElementRef,
    private MaterialDialogService: MaterialDialogService,
    private TemplateService: TemplateService,
    private translate: TranslateService,
    private SateliteController: SateliteControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.dialog = this.SessionDataService
      .getDialogAction()
      .subscribe((dialogAction: any) => {
        if (dialogAction.action == "offset") {
          this.schedulerOffsetHour = dialogAction.data.schedulerOffsetHour;
          this.schedulerOffsetMinute = dialogAction.data.schedulerOffsetMinute;
          this.offsetSign = dialogAction.data.offsetSign;
          this.timeCorrection();
        }
      });
    this.loadDatePicker();
    this.getData();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.element.nativeElement.style.setProperty(
      "--maxTimeLine",
      this.maxTimeLine
    );
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos
  getData(): void {
    this.SateliteController.getSateliteScheduler(this.device.id).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"]) {
          let sateliteData: SateliteData[] = response["body"];
          let sateliteScheduler = [];
          sateliteData.forEach((schedulerData: SateliteData) => {
            let turns: SateliteTurn[] = [];
            schedulerData.listaTurnos.forEach((turnData: SateliteTurnData) => {
              // Turnos
              turns.push({
                date: {
                  startTime:
                    (turnData.horaInicio > 9
                      ? turnData.horaInicio
                      : "0" + turnData.horaInicio) +
                    ":" +
                    (turnData.minutoInicio > 9
                      ? turnData.minutoInicio
                      : "0" + turnData.minutoInicio),
                  endTime:
                    (turnData.horaFin > 9
                      ? turnData.horaFin
                      : "0" + turnData.horaFin) +
                    ":" +
                    (turnData.minutoFin > 9
                      ? turnData.minutoFin
                      : "0" + turnData.minutoFin),
                },
                pulses: turnData.pulsos,
                id: turnData.id,
              });
            });
            // Programa
            sateliteScheduler.push({
              id: schedulerData.id,
              programa: schedulerData.programa,
              active: {
                enabled: schedulerData.activo,
                date: {
                  startDate: moment(
                    (schedulerData.diaDesde > 9
                      ? schedulerData.diaDesde
                      : "0" + schedulerData.diaDesde) +
                      "/" +
                      (schedulerData.mesDesde > 9
                        ? schedulerData.mesDesde
                        : "0" + schedulerData.mesDesde) +
                      "/" +
                      schedulerData.anyoDesde,
                    "DD-MM-YYYY"
                  ),
                  endDate: moment(
                    (schedulerData.diaHasta > 9
                      ? schedulerData.diaHasta
                      : "0" + schedulerData.diaHasta) +
                      "/" +
                      (schedulerData.mesHasta > 9
                        ? schedulerData.mesHasta
                        : "0" + schedulerData.mesHasta) +
                      "/" +
                      schedulerData.anyoHasta,
                    "DD-MM-YYYY"
                  ),
                },
              },
              interval: {
                selected: schedulerData.tipo == 0 ? "interval" : "days",
                byInterval:
                  schedulerData.tipo == 0 ? schedulerData.frecuencia[0] : null,
                byDay:
                  schedulerData.tipo == 1
                    ? schedulerData.frecuencia
                    : [0, 0, 0, 0, 0, 0, 0],
              },
              opcionCambioVI: schedulerData.opcionCambioVI,
              turns: turns,
            });
          });
          this.sateliteScheduler = sateliteScheduler.sort(
            (a, b) => a.programa - b.programa
          );
          this.originalSateliteScheduler = JSON.parse(
            JSON.stringify(this.sateliteScheduler)
          );
        } else if (response["code"] == 0) {
          this.sateliteScheduler = [];
          this.originalSateliteScheduler = [];
        }
      }
    );
  }

  // Comprobación de intervalo
  checkInterval(): void {
    if (
      this.sateliteScheduler[this.currentScheduler].interval.selected !=
        "days" &&
      this.sateliteScheduler[this.currentScheduler].interval.byInterval == null
    ) {
      this.sateliteScheduler[this.currentScheduler].interval.byInterval = 0;
    }
  }

  // Comprobación de selector de intervalo
  checkIntervalEmpty(): void {
    if (
      this.sateliteScheduler[this.currentScheduler].interval.selected !=
        "days" &&
      this.sateliteScheduler[this.currentScheduler].interval.byInterval == null
    ) {
      this.schedulerIntervalEmpty = true;
    } else if (
      this.sateliteScheduler[this.currentScheduler].interval.selected != "days"
    ) {
      this.schedulerIntervalEmpty = false;
    }
  }

  // Comprobación de selector de días
  checkDays(): void {
    if (
      !this.sateliteScheduler[this.currentScheduler].interval.byDay.some(
        (day) => day == 1
      )
    ) {
      this.sateliteScheduler[this.currentScheduler].interval.byDay[0] = 1;
    }
  }

  // Comprobación de selector de días
  checkDaysEmpty(): void {
    if (
      this.sateliteScheduler[this.currentScheduler].interval.selected ==
        "days" &&
      !this.sateliteScheduler[this.currentScheduler].interval.byDay.some(
        (day) => day == 1
      )
    ) {
      this.schedulerDaysEmpty = true;
    } else if (
      this.sateliteScheduler[this.currentScheduler].interval.selected == "days"
    ) {
      this.schedulerDaysEmpty = false;
    }
  }

  // Guardado de programa
  saveData(): void {
    let activeStartDate = moment(
      this.sateliteScheduler[this.currentScheduler].active.date.startDate
    ).format("DD-MM-YYYY");
    let activeEndDate = moment(
      this.sateliteScheduler[this.currentScheduler].active.date.endDate
    ).format("DD-MM-YYYY");
    let data: SateliteSaveData = {
      satelite: this.device.id,
      activo: this.sateliteScheduler[this.currentScheduler].active.enabled
        ? 1
        : 0,
      programa: this.sateliteScheduler[this.currentScheduler].programa,
      diaDesde: parseInt(activeStartDate.split("-")[0]),
      mesDesde: parseInt(activeStartDate.split("-")[1]),
      anyoDesde: parseInt(activeStartDate.split("-")[2]),
      diaHasta: parseInt(activeEndDate.split("-")[0]),
      mesHasta: parseInt(activeEndDate.split("-")[1]),
      anyoHasta: parseInt(activeEndDate.split("-")[2]),
      frecuencia:
        this.sateliteScheduler[this.currentScheduler].interval.selected ==
        "interval"
          ? [this.sateliteScheduler[this.currentScheduler].interval.byInterval]
          : this.sateliteScheduler[this.currentScheduler].interval.byDay,
      tipo:
        this.sateliteScheduler[this.currentScheduler].interval.selected ==
        "interval"
          ? 0
          : 1,
      opcionCambioVI:
        this.sateliteScheduler[this.currentScheduler].opcionCambioVI,
      listaTurnos: this.sateliteScheduler[this.currentScheduler].turns.map(
        (turn: SateliteTurn) => {
          return {
            minutoInicio: parseInt(turn.date.startTime.split(":")[1]),
            horaInicio: parseInt(turn.date.startTime.split(":")[0]),
            minutoFin: parseInt(turn.date.endTime.split(":")[1]),
            horaFin: parseInt(turn.date.endTime.split(":")[0]),
            pulsos: turn.pulses,
            id: turn.id,
          };
        }
      ),
    };
    this.SateliteController.saveSateliteScheduler(data).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast("success", this.translate.instant("saved"));
        }
      }
    );
  }

  // Carga del selector de fechas
  loadDatePicker(): void {
    let DatePickerConfigService = this.DatePickerConfigService.setDateRangePickerOptions();
    this.daterangePickerLang = DatePickerConfigService.daterangePickerLang;
    this.daterangePickerRanges = DatePickerConfigService.daterangePickerRanges;
  }

  // Actualización de programa en curso
  updateCurrentScheduler(i: number) {
    this.currentScheduler = i;
    this.otherScheduler = i == 0 ? 1 : 0;
    this.timebarInititated = false;
    setTimeout(() => this.drawTurns(), 0);
  }

  // Añadir programa
  addScheduler(): void {
    this.sateliteScheduler.push(new SateliteSchedule());
    this.resetTurns();
    this.updateCurrentScheduler(this.sateliteScheduler.length - 1);
    this.sateliteScheduler[this.currentScheduler].programa =
      this.currentScheduler;
    this.sateliteScheduler[this.currentScheduler].opcionCambioVI = 0;
    this.sateliteScheduler[this.currentScheduler].active.date = {
      startDate: this.DateParserService.getNow(),
      endDate: this.DateParserService.getNow(),
    };
  }

  // Añadir turno
  addTurn(turns: SateliteTurn[]): void {
    let startTime = this.getNewClockTime(
      true,
      turns[turns.length - 1]?.date?.endTime,
      turns.length,
      1,
      1
    );

    let endTime = this.getNewClockTime(
      false,
      turns[turns.length - 1]?.date?.endTime,
      turns.length,
      2,
      1
    );

    turns.push({
      id: null,
      date: { startTime: startTime, endTime: endTime },
      pulses: 0,
    });
  }

  // Borrar turno
  deleteTurn(turns: SateliteTurn[], index: number): void {
    turns.splice(index, 1);
    this.drawTurns();
  }

  // Dibujar turno
  drawTurns() {
    this.checkHourLimit();
    if (this.maxHour <= 24 && this.maxHourExtended) {
      this.resetSplitTurns();
      this.maxHourExtended = false;
    }
    setTimeout(() => {
      if (
        !this.timebarInititated &&
        this.sateliteScheduler[this.otherScheduler]
      ) {
        this.calculateTurns(this.otherScheduler);
        this.timebarInititated = true;
      }
      this.calculateTurns(this.currentScheduler);
      this.getValveOpenTime();
    }, 0);
  }

  // Comprobación de la hora límite de la barra de tiempos
  checkHourLimit(): void {
    if (
      this.sateliteScheduler[this.currentScheduler].turns[
        this.sateliteScheduler[this.currentScheduler].turns.length - 1
      ]?.date?.startTime >
      this.sateliteScheduler[this.currentScheduler].turns[
        this.sateliteScheduler[this.currentScheduler].turns.length - 1
      ]?.date?.endTime
    ) {
      this.maxHour =
        parseInt(
          this.sateliteScheduler[this.currentScheduler].turns[
            this.sateliteScheduler[this.currentScheduler].turns.length - 1
          ]?.date?.endTime?.substring(0, 2)
        ) + 25;
      this.maxHourExtended = true;
    } else {
      this.maxHour = 24;
    }
    this.element.nativeElement.style.setProperty("--maxTimeLine", this.maxHour);
  }

  // Obtención de los datos de turno
  calculateTurns(schedulerIndex: number): void {
    this.sateliteScheduler[schedulerIndex].turns.forEach((turn: any, i) => {
      // Minuto inicial
      let turnStartMinute = this.element.nativeElement.querySelector(
        "#t" + turn.date.startTime?.replace(":", "")
      );
      // Minuto final
      let turnEndMinute: any;
      if (
        i < this.sateliteScheduler[schedulerIndex].turns.length - 1 ||
        (i == this.sateliteScheduler[schedulerIndex].turns.length - 1 &&
          turn.date.startTime < turn.date.endTime)
      ) {
        turnEndMinute = this.element.nativeElement.querySelector(
          "#t" + turn.date.endTime?.replace(":", "")
        );
      } else {
        turnEndMinute = this.element.nativeElement.querySelector(
          "#o" + turn.date.endTime?.replace(":", "")
        );
      }
      if (turnStartMinute && turnEndMinute) {
        // Comprobación si el turno salta de línea
        if (
          Math.floor(
            parseInt(turnStartMinute.id.substring(1, 3)) / this.maxTimeLine
          ) !=
            Math.floor(
              parseInt(turnEndMinute.id.substring(1, 3)) / this.maxTimeLine
            ) ||
          turnEndMinute.id.includes("o")
        ) {
          // Límite de línea
          let timeLimit: number;
          let timeLimitElement: any;
          if (turnEndMinute.id.includes("o")) {
            // Elemento final de línea
            timeLimitElement =
              this.element.nativeElement.querySelector("#t2359");
          } else {
            timeLimit =
              Math.floor(
                parseInt(turnEndMinute.id.substring(1, 3)) / this.maxTimeLine
              ) * this.maxTimeLine;
            // Elemento final de línea
            timeLimitElement = this.element.nativeElement.querySelector(
              "#t" +
                (timeLimit < 10 ? "0" + (timeLimit - 1) : timeLimit - 1) +
                "59"
            );
          }
          // Ocultación de pulsos
          let hidePulses = this.checkPulsesPosition(
            turnStartMinute,
            turnEndMinute,
            timeLimit
          );
          this.drawTurnBox(
            turnStartMinute,
            timeLimitElement,
            i,
            schedulerIndex,
            1,
            hidePulses
          );
          // Elemento inicial de línea
          if (turnEndMinute.id.includes("o")) {
            timeLimitElement =
              this.element.nativeElement.querySelector("#o0000");
          } else {
            timeLimitElement = this.element.nativeElement.querySelector(
              "#t" + (timeLimit < 10 ? "0" + timeLimit : timeLimit) + "00"
            );
          }
          this.drawTurnBox(
            timeLimitElement,
            turnEndMinute,
            i,
            schedulerIndex,
            2,
            hidePulses
          );
        } else {
          this.drawTurnBox(turnStartMinute, turnEndMinute, i, schedulerIndex);
        }
      }
    });
  }

  // Dibujado de la caja de turno
  drawTurnBox(
    turnStartMinute: any,
    turnEndMinute: any,
    i: number,
    schedulerIndex?: number,
    elementIndex?: number,
    hidePulses?: number
  ): void {
    // Slider
    let turnSlider = this.element.nativeElement.querySelector(
      schedulerIndex == this.currentScheduler
        ? elementIndex == 2
          ? "#turnBis" + i
          : "#turn" + i
        : "#otherTurn" + i
    );
    // Top
    turnSlider.style.top =
      turnStartMinute.parentNode.offsetTop +
      turnStartMinute.offsetTop -
      (schedulerIndex == this.currentScheduler ? 5 : 0) +
      "px";
    // Left
    turnSlider.style.left =
      turnStartMinute.parentNode.offsetLeft + turnStartMinute.offsetLeft + "px";
    // Width
    turnSlider.style.width =
      turnEndMinute.parentNode.offsetLeft +
      turnEndMinute.offsetLeft +
      turnEndMinute.offsetWidth -
      (turnStartMinute.parentNode.offsetLeft + turnStartMinute.offsetLeft) +
      "px";

    // Comprobación de elemento con salto de línea
    if (elementIndex == 1) {
      turnSlider.classList.add("satelite-schedule-turn-slider-first");
    } else {
      turnSlider.classList.remove("satelite-schedule-turn-slider-first");
    }

    // Ocultación de pulsos en elementos con salto de línea
    if (elementIndex && hidePulses && elementIndex == hidePulses) {
      turnSlider.classList.add("schedule-turn-hide-pulses");
    } else {
      turnSlider.classList.remove("schedule-turn-hide-pulses");
    }

    // Dirección de los inputs de tiempo
    this.checkTimeDirection(turnSlider, elementIndex);

    // Visibilidad
    turnSlider.classList.remove("hidden");
  }

  // Reseteo de turnos
  resetTurns() {
    for (let i = 0; i < this.maxTurns; i++) {
      let turnSlider = this.element.nativeElement.querySelector("#turn" + i);
      let turnSliderBis = this.element.nativeElement.querySelector(
        "#turnBis" + i
      );
      turnSlider?.classList.add("hidden");
      turnSliderBis?.classList.add("hidden");
    }
  }

  // Reseteo de turnos extendidos
  resetSplitTurns() {
    for (let i = 0; i < this.maxTurns; i++) {
      let turnSliderBis = this.element.nativeElement.querySelector(
        "#turnBis" + i
      );
      turnSliderBis?.classList.add("hidden");
    }
  }

  // Comprobación de posición del dato de pulsos
  checkPulsesPosition(
    turnStartMinute: any,
    turnEndMinute: any,
    timeLimit: number
  ): number {
    if (turnEndMinute.id.includes("o")) {
      if (
        24 - parseInt(turnStartMinute.id.substring(1, 3)) >
        parseInt(turnEndMinute.id.substring(1, 3))
      ) {
        return 2;
      }
      return 1;
    } else {
      if (
        timeLimit - parseInt(turnStartMinute.id.substring(1, 3)) >
        parseInt(turnEndMinute.id.substring(1, 3)) - timeLimit
      ) {
        return 2;
      }
      return 1;
    }
  }

  // Comprobación de dirección del input de tiempo
  checkTimeDirection(turnSlider: any, elementIndex?: number): void {
    turnSlider.classList.remove("schedule-turn-slider-start-left");
    turnSlider.classList.remove("schedule-turn-slider-end-left");
    turnSlider.classList.remove("schedule-turn-slider-start-right");
    turnSlider.classList.remove("schedule-turn-slider-end-right");

    // Direcciones dependiendo de la posición
    if (
      elementIndex == 1 &&
      parseInt(turnSlider.style.left.replace("px", "")) + 60 >
        this.scheduleTurns.nativeElement.offsetWidth
    ) {
      turnSlider.classList.add("schedule-turn-slider-start-right");
    } else if (
      elementIndex == 2 &&
      parseInt(turnSlider.style.left.replace("px", "")) +
        parseInt(turnSlider.style.width.replace("px", "")) -
        60 <=
        0
    ) {
      turnSlider.classList.add("schedule-turn-slider-end-left");
    } else {
      turnSlider.classList.add("schedule-turn-slider-start-left");
      turnSlider.classList.add("schedule-turn-slider-end-right");
    }
  }

  // Cálculo de tiempo de válvula abierta
  getValveOpenTime(): void {
    this.timeOpen = "";
    this.flowMinutes = 0;
    this.sateliteScheduler[this.currentScheduler].turns.forEach(
      (turn: SateliteTurn, i) => {
        let startTime =
          parseInt(turn.date.startTime.substring(0, 2)) * 60 +
          parseInt(turn.date.startTime.substring(3));
        let endTime =
          parseInt(turn.date.endTime.substring(3)) +
          parseInt(turn.date.endTime.substring(0, 2)) * 60;
        if (startTime > endTime) {
          endTime += 24 * 60;
        }

        this.flowMinutes += endTime - startTime;
      }
    );
    this.timeOpen =
      Math.floor(this.flowMinutes / 60) +
      " " +
      this.translate.instant("hours") +
      " " +
      (this.flowMinutes % 60
        ? (this.flowMinutes % 60) + " " + this.translate.instant("minutes")
        : "");
    this.getFlow();
  }

  // Cáculo del caudal total
  getFlow(): void {
    if (
      this._sateliteFlow &&
      this._sateliteFlow.length > 0 &&
      this.flowMinutes
    ) {
      let validReadings = this._sateliteFlow.filter(
        (reading: number[]) => reading[1] > 0
      );
      let flowData =
        (validReadings
          .map((reading: number[]) => {
            return reading[1];
          })
          .reduce((a, b) => a + b, 0) /
          validReadings.length /
          60) *
        this.flowMinutes;
      this.flow =
        formatNumber(flowData, this.SessionDataService.getCurrentNumberFormat()) + " m³";
    } else {
      this.flow = this.translate.instant("unknown");
    }
  }

  // Actualización de tiempo por interacción con reloj
  updateTurnTime(
    start: boolean,
    turn: SateliteTurn,
    turnIndex: number,
    value?: number,
    speed?: number
  ): void {
    if (start) {
      let newStartTime = this.getNewClockTime(
        true,
        turn?.date?.startTime,
        turnIndex,
        value,
        speed
      );

      // Control de tiempo inicial menor que tiempo final
      if (
        newStartTime < turn.date.endTime ||
        turnIndex ==
          this.sateliteScheduler[this.currentScheduler].turns.length - 1
      ) {
        turn.date.startTime = newStartTime;
      }
    } else {
      let newEndTime = this.getNewClockTime(
        false,
        turn?.date?.endTime,
        turnIndex,
        value,
        speed
      );

      // Control de tiempo final mayor que tiempo inicial
      if (
        newEndTime > turn.date.startTime ||
        turnIndex ==
          this.sateliteScheduler[this.currentScheduler].turns.length - 1
      ) {
        turn.date.endTime = newEndTime;
      }
    }

    this.drawTurns();
  }

  // Timeout para evitar errores de comprobación en la introducción de las horas
  inputUpdatedTimeout(
    start: boolean,
    turn: SateliteTurn,
    turnIndex: number
  ): void {
    if (this.inputTimeout) {
      clearTimeout(this.inputTimeout);
    }
    this.inputTimeout = setTimeout(() => {
      this.updateTurnTime(start, turn, turnIndex);
    }, 500);
  }

  // Obtención del nuevo tiempo del reloj
  getNewClockTime(
    start: boolean,
    turnTime: string,
    turnIndex: number,
    value?: number,
    speed?: number
  ): any {
    let hour = parseInt(turnTime?.substring(0, 2));
    let minute = parseInt(turnTime?.substring(3));
    if (hour != null && minute != null) {
      if (value && speed) {
        minute += value * speed;
      }
      return this.checkTime(hour, minute, turnIndex, start);
    }
  }

  // Comprobar tiempo
  checkTime(
    hour: number,
    minute: number,
    turnIndex?: number,
    start?: boolean
  ): any {
    // Minuto mayor que 59
    if (minute > 59) {
      minute = 0;
      hour++;
    }

    // Minuto menor que 0
    if (minute < 0) {
      if (start && hour == 0) {
        minute = 0;
      } else {
        minute = 59;
        hour--;
      }
    }

    // Hora mayor que 24
    if (hour > 23) {
      if (start) {
        hour = 23;
        minute = 59;
      } else {
        hour = 0;
      }
    }

    // Hora menor que 0
    if (!start && hour < 0) {
      hour = 23;
    }

    return turnIndex != null
      ? this.checkCollisions(hour, minute, turnIndex, start)
      : this.getTimeString(hour, minute);
  }

  // Conversión del tiempo a string
  getTimeString(hour: number, minute: number) {
    return (
      (hour < 10 ? "0" + hour : hour) +
      ":" +
      (minute < 10 ? "0" + minute : minute)
    );
  }

  // Comprobación de colisiones entre turnos
  checkCollisions(
    hour: number,
    minute: number,
    turnIndex: number,
    start: boolean
  ) {
    let adjacentTurn: string;
    // Si es el tiempo inicial se compara con el final del anterior turno
    if (start) {
      adjacentTurn =
        turnIndex - 1 >= 0 &&
        this.sateliteScheduler[this.currentScheduler].turns[turnIndex - 1]
          ? this.sateliteScheduler[this.currentScheduler].turns[turnIndex - 1]
              .date.endTime
          : null;
      if (adjacentTurn >= this.getTimeString(hour, minute)) {
        return this.getNewClockTime(true, adjacentTurn, turnIndex, 1, 1);
      }
      // Si es el tiempo final se compara con el inicial del siguiente turno
    } else {
      adjacentTurn =
        turnIndex + 1 <= 6 &&
        this.sateliteScheduler[this.currentScheduler].turns[turnIndex + 1]
          ? this.sateliteScheduler[this.currentScheduler].turns[turnIndex + 1]
              .date.startTime
          : null;
      if (adjacentTurn <= this.getTimeString(hour, minute)) {
        return this.getNewClockTime(false, adjacentTurn, turnIndex, -1, 1);
      }
    }
    return this.getTimeString(hour, minute);
  }

  // Deshacer cambios en turno
  undoTurnChanges(turnIndex: number): void {
    this.sateliteScheduler[this.currentScheduler].turns[turnIndex] = JSON.parse(
      JSON.stringify(
        this.originalSateliteScheduler[this.currentScheduler].turns[turnIndex]
      )
    );
    this.drawTurns();
  }

  // Arrastre de turno en barra de tiempos
  editTurn(e: any): void {
    // Arrastre a la derecha
    if (!this.preventTurnMove && this.turnEdition && this.mouseX < e.pageX) {
      // Actualización de tiempo en base a la velocidad del ratón
      if (this.turnEdition == "move" || this.turnEdition == "resizeStart") {
        this.updateTurnTime(
          true,
          this.sateliteScheduler[this.currentScheduler].turns[
            this.turnEditionIndex
          ],
          this.turnEditionIndex,
          1,
          Math.floor(e.pageX - this.mouseX)
        );
      }
      if (this.turnEdition == "move" || this.turnEdition == "resizeEnd") {
        this.updateTurnTime(
          false,
          this.sateliteScheduler[this.currentScheduler].turns[
            this.turnEditionIndex
          ],
          this.turnEditionIndex,
          1,
          Math.floor(e.pageX - this.mouseX)
        );
      }
      // Arrastre a la izquierda
    } else if (!this.preventTurnMove && this.turnEdition) {
      // Actualización de tiempo en base a la velocidad del ratón
      if (this.turnEdition == "move" || this.turnEdition == "resizeStart") {
        this.updateTurnTime(
          true,
          this.sateliteScheduler[this.currentScheduler].turns[
            this.turnEditionIndex
          ],
          this.turnEditionIndex,
          -1,
          Math.floor(this.mouseX - e.pageX)
        );
      }
      if (this.turnEdition == "move" || this.turnEdition == "resizeEnd") {
        this.updateTurnTime(
          false,
          this.sateliteScheduler[this.currentScheduler].turns[
            this.turnEditionIndex
          ],
          this.turnEditionIndex,
          -1,
          Math.floor(this.mouseX - e.pageX)
        );
      }
    }
    // Actualización de las posición del ratón
    this.drawTurns();
    this.mouseX = e.pageX;
  }

  // Seteo de la posición del ratón
  setMousePosition(e: any, turnIndex: number): void {
    this.mouseX = e.pageX;
    if (e.target.id.includes("resize")) {
      if (e.target.id.includes("Start")) {
        this.turnEdition = "resizeStart";
      } else {
        this.turnEdition = "resizeEnd";
      }
    } else {
      this.turnEdition = "move";
    }
    this.turnEditionIndex = turnIndex;
  }

  // Limpieza de la posición del ratón
  clearMousePosition(): void {
    this.mouseX = null;
    this.turnEdition = null;
    this.turnEditionIndex = null;
  }

  // Visualización de dialog de offset
  showOffset(): void {
    this.MaterialDialogService.openDialog(SateliteOffsetDialogComponent, {
      schedulerOffsetHour: null,
      schedulerOffsetMinute: null,
      offsetSign: "1",
    });
  }

  // Corrección de tiempo
  timeCorrection(): void {
    this.sateliteScheduler[this.currentScheduler].turns.forEach(
      (turn: SateliteTurn, index) => {
        turn.date.startTime = this.getOffsetTime(turn.date.startTime, true);
        this.getNewClockTime(true, turn.date.startTime, index);
        turn.date.endTime = this.getOffsetTime(turn.date.endTime, false);
        this.getNewClockTime(false, turn.date.endTime, index);
      }
    );
    this.drawTurns();
    this.schedulerOffsetHour = null;
    this.schedulerOffsetMinute = null;
  }

  // Obtención de los tiempos modificados
  getOffsetTime(time: string, start: boolean) {
    let hour =
      parseInt(time?.substring(0, 2)) +
      (this.schedulerOffsetHour
        ? this.schedulerOffsetHour * this.offsetSign
        : 0);
    let minute =
      parseInt(time?.substring(3)) +
      (this.schedulerOffsetMinute
        ? this.schedulerOffsetMinute * this.offsetSign
        : 0);

    if (minute < 0) {
      minute += 60;
      hour--;
    }
    if (minute > 59) {
      minute -= 60;
      hour++;
    }
    if (hour < 0) {
      if (start) {
        hour = 0;
      } else {
        hour = 24 + hour;
      }
    }
    if (hour > 23) {
      if (start) {
        hour = 23;
      } else {
        hour -= 24;
      }
    }
    return this.getTimeString(hour, minute);
  }
}
