// @angular
import { Injectable, inject } from "@angular/core";
import { Router, ActivatedRouteSnapshot, CanActivateFn } from "@angular/router";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuardService {
  /***************************************************************************/
  // Constructor
  /***************************************************************************/

  constructor(
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // Funciones
  /***************************************************************************/

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    if (!expectedRole.includes(this.SessionDataService.getCurrentProfile())) {
      this.router.navigate(["/acceso-denegado"]);
      return false;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    // if(!expectedRole.includes(this.SessionDataService.getCurrentProfile()) && !(expectedRole.includes("TLC") && this.SessionDataService.getCurrentRemoteControlActive)) {
    if (!expectedRole.includes(this.SessionDataService.getCurrentProfile())) {
      this.router.navigate(["/acceso-denegado"]);
      return false;
    }
    return true;
  }
}

export const RoleGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot
): boolean => {
  return inject(RoleGuardService).canActivate(next);
};
