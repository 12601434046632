<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>
            {{ "gateway-update" | translate }}:
            {{ unidadVenta != null ? unidadVenta : ("no-data" | translate) }}</b
          >
        </h5>
      </div>

      <div class="panel-body">
        <h4>
          <b>{{ "file-import" | translate }}</b>
          <span class="gateway-update-version"
            >({{ "version-hardware" | translate }}:
            {{ hwVersion ? hwVersion : ("unknown" | translate) }},
            {{ "version-firmware" | translate }}:
            {{ fwVersion ? fwVersion : ("unknown" | translate) }})</span
          >
        </h4>

        <div class="gateway-update-panel">
          <div class="gateway-update-control">
            <!-- Fichero -->
            <app-file-import
              [removeButton]="true"
              [fileType]="'.hex'"
              (fileChange)="
                fileToImport = $event; checkFileVersion($event?.name)
              "
            ></app-file-import>

            <!-- Barra de progreso -->
            <div
              *ngIf="nonces"
              class="progress-bar"
              [ngClass]="
                nonces == 100
                  ? 'progress-bar-success progress-bar-striped'
                  : errorProgress
                  ? 'progress-bar-danger progress-bar-striped'
                  : 'progress-bar-striped active'
              "
              role="progressbar"
              attr.aria-valuenow="{{ nonces | number : '1.0-0' }}"
              [style.width]="nonces + '%'"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <b *ngIf="nonces == 100">{{ "update-finished" | translate }}</b>
              <b *ngIf="nonces != 100">{{ nonces | number : "1.0-0" }}%</b>
            </div>
          </div>

          <div *ngIf="fileToImport" class="gateway-update-control">
            <div class="gateway-update-buttons">
              <!-- Actualizar -->
              <button
                name="import"
                [disabled]="loading || versionFail"
                (click)="import()"
                class="btn btn-primary"
              >
                <i
                  class="fas"
                  [ngClass]="loading ? 'fa-sync-alt fa-spin' : 'fa-sync-alt'"
                ></i>
                {{
                  loading ? ("updating" | translate) : ("update" | translate)
                }}
              </button>
              <!-- Detener actualización -->
              <button
                [disabled]="!loading"
                name="stop"
                (click)="stop()"
                class="btn btn-danger"
              >
                <i class="fas fa-ban"></i>
                {{ "update-stop" | translate }}
              </button>
            </div>

            <!-- Sobreescribir ajustes -->
            <div class="gateway-update-checkbox">
              <mat-checkbox class="mat-option" [(ngModel)]="overwrite">
                {{ "overwrite-settings" | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
