import { Injectable } from "@angular/core";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { SepemDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/sepem-decoder.service";
import { KamstrupDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/kamstrup-decoder.service";
import { ConthidraDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/conthidra-decoder.service";
import { ConthidraERegisterDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/conthidra-eRegister-decoder.service";
import { LoraWanParserService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/lorawan-parser.service";
import { ItronDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/itron-decoder.service";
import { HoneywellDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/honeywell-decoder.service";
import { DhielDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/dhiel-decoder.service";
import { BmeterDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/bmeter-decoder.service";
import { LwMbusDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/lw-mbus-decoder.service";
import { SagemcomDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/sagemcom-decoder.service";
import { IntegraDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/integra-decoder.service";
import { ViewshineDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/viewshine-decoder.service";
import { HoneywellGasDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/honeywell-gas-decoder.service";
import { BetaDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/beta-decoder.service";
import { AimeiDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/aimei-decoder.service";
// Interfaces
import {
  AssignedDevice,
  DetailDevice,
  DetailDeviceAlarm,
  DetailDeviceGateway,
  DeviceCheckData,
  DeviceFrameLog,
} from "../DeviceInterface.type";
import { AlarmData } from "../../alarms/AlarmInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { LOCAL_TIMEZONE } from "../../../../global/LOCAL_TIMEZONE";
// Componentes
import { MeterLogFramesDialogComponent } from "./meter-detail/meter-logs/meter-log-frames/meter-log-frames-dialog/meter-log-frames-dialog.component";
import { MeterReviewDialogComponent } from "./meter-list/meter-review/meter-review-dialog/meter-review-dialog.component";

@Injectable({
  providedIn: "root",
})
export class MeterService {
  constructor(
    private BmeterDecoderService: BmeterDecoderService,
    private ConthidraDecoderService: ConthidraDecoderService,
    private ConthidraERegisterDecoderService: ConthidraERegisterDecoderService,
    private DateParserService: DateParserService,
    private DhielDecoderService: DhielDecoderService,
    private HoneywellDecoderService: HoneywellDecoderService,
    private IntegraDecoderService: IntegraDecoderService,
    private ItronDecoderService: ItronDecoderService,
    private KamstrupDecoderService: KamstrupDecoderService,
    private LoraWanParserService: LoraWanParserService,
    private LwMbusDecoderService: LwMbusDecoderService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private SagemcomDecoderService: SagemcomDecoderService,
    private SepemDecoderService: SepemDecoderService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private ViewshineDecoderService: ViewshineDecoderService,
    private HoneywellGasDecoderService: HoneywellGasDecoderService,
    private BetaDecoderService: BetaDecoderService,
    private AimeiDecoderService: AimeiDecoderService
  ) {}

  // Reseteo de contador
  resetMeter(meter: DetailDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "warning",
      this.translate.instant("device-question-desactivate") +
        " " +
        this.translate.instant("warning-permanent-action")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.SessionDataService.sendComponentData({ deactivation: true });
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }

  // Añadido del contador a lista de revisión
  toCheck(meter: DetailDevice | any): void {
    this.MaterialDialogService.openDialog(MeterReviewDialogComponent, meter);
  }

  // Obtención de la gráfica de dispositivo con alarma
  getAlarmGraph(
    meter: AssignedDevice | AlarmData,
    id?: number,
    metrologyType?: number
  ): void {
    let date = this.DateParserService.getLastDays(7);
    this.MeterController.getGraph(
      id ? id : meter.id,
      metrologyType
        ? metrologyType == METROLOGY_TYPE.GAS
          ? "3"
          : "2"
        : meter.metrologyType == METROLOGY_TYPE.GAS
        ? "3"
        : "2",
      String(date.startDate.valueOf()),
      String(date.endDate.valueOf())
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let numberFormat = this.SessionDataService.getCurrentNumberFormat();
        meter.loadGraph = false;
        meter.graphData = {
          title: meter.nroSerie,
          html:
            `<div class="dialog-last-consumptions">
                      <span><b>` +
            this.translate.instant("last-consumption-min") +
            ":</b> " +
            formatNumber(meter.lastConsumptionMin, numberFormat) +
            (meter.metrologyType == METROLOGY_TYPE.GAS ? " Nm³" : " m³") +
            `</span>
                <span><b>` +
            this.translate.instant("last-consumption-max") +
            ":</b> " +
            formatNumber(meter.lastConsumptionMax, numberFormat) +
            (meter.metrologyType == METROLOGY_TYPE.GAS ? " Nm³" : " m³") +
            `</span>
                <span><b>` +
            this.translate.instant("last-consumption-total") +
            ":</b> " +
            formatNumber(meter.lastConsumptionTotal, numberFormat) +
            (meter.metrologyType == METROLOGY_TYPE.GAS ? " Nm³" : " m³") +
            `</span>
                    </div>`,
          type: "area",
          series: [
            {
              id: "valor",
              name:
                meter.metrologyType == 2
                  ? this.translate.instant("consumption-normalized")
                  : this.translate.instant("consumption"),
              type: "area",
              data: response["body"]?.readings,
              dataGrouping: { approximation: "sum" },
              tooltip: {
                valueSuffix: meter.metrologyType == 2 ? " Nm³" : " m³",
                valueDecimals: 3,
              },
              color: "#42a5f5",
              navigatorOptions: {
                type: "area",
              },
            },
          ],
        };
      }
    });
  }

  // Limpieza de alarmas
  cleanAlarms(e: MouseEvent, meter: DetailDevice): void {
    e.stopPropagation();
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("clean-alarms-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.clearAlarms(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("command-sent")
            );
          }
        });
      }
    });
  }

  // Lectura de válvula
  readValve(meter: DetailDevice): void {
    this.ToastService.fireAlertWithOptions(
      "info",
      this.translate.instant("read-valve-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let requestUrl =
          meter.metrologyType != METROLOGY_TYPE.SATELITE
            ? this.MeterController.readValve(meter.id)
            : this.MeterController.readSateliteValve(meter.id);
        requestUrl.subscribe((response) => {
          if (response["code"] == 0) {
            if (meter.metrologyType != METROLOGY_TYPE.SATELITE) {
              meter.valveState = response["body"];
              this.SessionDataService.sendComponentData({ updateCards: true });
            } else {
              this.updateValveData(meter);
            }
            this.ToastService.fireToast(
              "success",
              this.translate.instant("read-valve-sucessfull")
            );
          }
        });
      }
    });
  }

  // Cierre de vávula
  closeValve(meter: DetailDevice): void {
    let toastTitle: string =
      meter.valveState == 0
        ? this.translate.instant("close-valve-already-question")
        : this.translate.instant("close-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          let requestUrl =
            meter.metrologyType != METROLOGY_TYPE.SATELITE
              ? this.MeterController.closeValve(meter.id)
              : this.MeterController.closeSateliteValve(meter.id);
          requestUrl.subscribe((response) => {
            if (response["code"] == 0) {
              if (meter.metrologyType != METROLOGY_TYPE.SATELITE) {
                meter.valveState = response["body"];
                this.SessionDataService.sendComponentData({
                  updateCards: true,
                });
              } else {
                this.updateValveData(meter);
              }
              this.ToastService.fireToast(
                "success",
                this.translate.instant("close-valve-sended")
              );
            }
          });
        }
      }
    );
  }

  // Cambio de modo del contador
  changeMode(mode: string, meter: DetailDevice): void {
    let selectedMode: string;
    switch (mode) {
      case "A":
        selectedMode = "1";
        break;
      case "A+":
        selectedMode = "2";
        break;
      case "B":
        selectedMode = "3";
        break;
    }

    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("mode-change-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.changeMode(meter.id, selectedMode).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("mode") +
                  " " +
                  mode +
                  " " +
                  this.translate.instant("mode-changed")
              );
            }
          }
        );
      }
    });
  }

  // Apertura de válvula
  openValve(meter: DetailDevice, value?: number): void {
    let toastTitle: string =
      meter.valveState == 1 || meter.valveState == 2
        ? this.translate.instant("open-valve-already-question")
        : this.translate.instant("open-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          let requestUrl =
            meter.metrologyType != METROLOGY_TYPE.SATELITE
              ? !value
                ? this.MeterController.openValve(meter.id)
                : value == 10
                ? this.MeterController.openValve10(meter.id)
                : value == 50
                ? this.MeterController.openValve50(meter.id)
                : null
              : this.MeterController.openSateliteValve(meter.id);
          requestUrl.subscribe((response) => {
            if (response["code"] == 0) {
              if (meter.metrologyType != METROLOGY_TYPE.SATELITE) {
                meter.valveState = response["body"];
                this.SessionDataService.sendComponentData({
                  updateCards: true,
                });
              } else {
                this.updateValveData(meter);
              }
              this.ToastService.fireToast(
                "success",
                this.translate.instant("open-valve-sucessfull")
              );
            }
          });
        }
      }
    );
  }

  // Actualización de los datos de válvula
  updateValveData(meter: DetailDevice): void {
    this.MeterController.updateValveData(meter.id).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        meter.lastReadedValue = response["body"]?.lastValue;
        meter.lastReadedTimestamp = response["body"]?.lastTimestamp;
        meter.lastStateTimestamp = response["body"]?.lastStateTimestamp;
        meter.valveState = response["body"]?.valveState;
        meter.confirmedConfiguration = response["body"]?.confirmedConfiguration;
        let meterAlarms = response["body"]?.activeAlarms;
        if (meterAlarms != null) {
          meterAlarms.forEach((alarm: DetailDeviceAlarm) => {
            alarm.initDateParsed = this.DateParserService.parseDate(
              alarm.initDate,
              "L HH:mm:ss"
            );
            alarm.code != null
              ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
              : "";
          });
          this.SessionDataService.sendComponentData({
            meterAlarms: meterAlarms,
          });
        }
        this.SessionDataService.sendComponentData({ updateValveCards: true });
      }
    });
  }

  // Ordenamiento del array de gateways
  sortMeterGatewayArray(
    meterGatewayList: DetailDeviceGateway[]
  ): DetailDeviceGateway[] {
    let mainGateway: DetailDeviceGateway;
    let redundantGateways: DetailDeviceGateway[] = [];

    meterGatewayList.forEach((gateway: DetailDeviceGateway) => {
      if (gateway.principal) {
        mainGateway = gateway;
      } else {
        redundantGateways.push(gateway);
      }
    });

    redundantGateways.sort((a, b) => {
      if (!a.rssi && !b.rssi) {
        return 0;
      } else if (!a.rssi) {
        return 1;
      } else if (!b.rssi) {
        return -1;
      } else {
        return b.rssi - a.rssi;
      }
    });

    if (mainGateway) {
      meterGatewayList = [mainGateway].concat(redundantGateways);
    } else {
      meterGatewayList = redundantGateways;
    }

    return meterGatewayList;
  }

  // Parseo de número de serie de MBUS desconocidos para fabricantes SAP(304C) y RAN(2E48)
  parseUnknownMbusSerialNumber(dirMbus: string): string {
    let manufacturers = ["304C", "2E48"];
    if (manufacturers.includes(dirMbus?.substring(0, 4))) {
      let serialNumber: string;
      // Descartar la parte inicial para el cálculo
      let dirMbusArray = dirMbus.substring(4).split("");
      let dirMbusFlippedArray: string[] = [];
      let dirMbusBinary: string;
      let dirMbusAscii: string[] = [];
      // Voltear los pares hexadecimal
      for (let i = dirMbusArray.length - 1; i > 0; i -= 2) {
        dirMbusFlippedArray.push(dirMbusArray[i - 1]);
        dirMbusFlippedArray.push(dirMbusArray[i]);
      }
      dirMbusFlippedArray.shift();
      // Se pasa a binario para obtener letras del número de serie
      dirMbusBinary = dirMbusFlippedArray
        .slice(0, 4)
        .map((data) => {
          let binaryData = parseInt(data, 16).toString(2);
          if (binaryData.length < 4) {
            let binaryDataLength = 4 - binaryData.length;
            for (let i = 0; i < binaryDataLength; i++) {
              binaryData = "0" + binaryData;
            }
          }
          return binaryData;
        })
        .reduce((a, b) => a.concat(b));
      // Se divide en partes de 5 bits y se pasa a caracteres ASCII
      for (let i = 0; i < dirMbusBinary.length - 1; i += 5) {
        dirMbusAscii.push(
          String.fromCharCode(
            64 + parseInt(dirMbusBinary.substring(i, i + 5), 2)
          )
        );
      }
      // Por último, se pasa de hexadecimal a decimal los últimos 7 bytes
      let dirMbusLowBytes = String(
        parseInt(dirMbusFlippedArray.slice(4).join(""), 16)
      );
      if (dirMbusLowBytes.length < 8) {
        dirMbusLowBytes = "0" + dirMbusLowBytes;
      }
      serialNumber =
        dirMbusAscii[0] +
        dirMbusLowBytes.substring(0, 2) +
        dirMbusAscii[1] +
        dirMbusAscii[2] +
        dirMbusLowBytes.substring(2);
      return serialNumber;
    } else {
      return;
    }
  }

  // Comprobación de fabricante para parseo de trama
  checkMeterManufacturerParser(meter: DetailDevice): string {
    if (meter.idFabricante == 4 && meter.idDevType != 2) {
      return "DIEHL";
    } else if (
      meter.idFabricante == 5 &&
      (meter.idDevType == 0 || meter.idDevType == 1 || meter.idDevType == 3)
    ) {
      return "HONEYWELL";
    } else if (
      meter.idFabricante == 6 &&
      (meter.idDevType == 0 || meter.idDevType == 1)
    ) {
      return "ITRON";
    } else if (
      meter.idFabricante == 1 &&
      (meter.idDevType == 3 || meter.idDevType == 4)
    ) {
      return "CONTHIDRA";
    } else if (meter.idFabricante == 8 && meter.idDevType == 2) {
      return "SAGEMCOM";
    } else if (meter.idFabricante == 16 && meter.idDevType == 0) {
      return "INTEGRA";
    } else if (meter.idFabricante == 1 && meter.idDevType == 7) {
      return "CONTHIDRA_EREGISTER";
    } else if (meter.idFabricante == 30 && meter.idDevType == 0) {
      return "SEWERIN";
    } else if (meter.idFabricante == 28 && meter.idDevType == 0) {
      return "KAMSTRUP";
    } else if (meter.idFabricante == 23) {
      return "BMETER";
    } else if (meter.idFabricante == 15 && meter.idDevType == 2) {
      return "LW_MBUS";
    } else if (meter.idFabricante == 27 && meter.idDevType == 0) {
      return "VIEWSHINE";
    } else if (meter.idFabricante == 5 && meter.idDevType == 7) {
      return "HONEYWELL_GAS";
    } else if (meter.idFabricante == 18 && meter.idDevType == 0) {
      return "BETA";
    } else if (
      meter.idFabricante == 25 &&
      (meter.idDevType == 0 || meter.idDevType == 1)
    ) {
      return "AIMEI";
    }
    return null;
  }

  // Visualizar resultado de parseo
  showParserResult(
    payload: string,
    parsedPayloadResult: object,
    error?: boolean
  ): void {
    this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
      payload: payload,
      payloadParsed: parsedPayloadResult,
      error: error,
      action: "parser",
    });
  }

  // Parseo de payload de trama
  parsePayload(meter: DetailDevice, meterFrame: any, date?: string): void {
    if (!meter.parser) {
      meter.parser = this.checkMeterManufacturerParser(meter);
    }
    let parsedPayloadResult: any;
    switch (meter.parser) {
      case "SEWERIN":
        parsedPayloadResult = this.SepemDecoderService.sepem_decoder({
          bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
        });
        break;
      case "KAMSTRUP":
        parsedPayloadResult = this.KamstrupDecoderService.kamstrup_decoder({
          bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
        });
        break;
      case "CONTHIDRA":
        parsedPayloadResult =
          this.ConthidraDecoderService.conthidra_MyWater2_decoder({
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          });
        break;
      case "CONTHIDRA_EREGISTER":
        parsedPayloadResult =
          this.ConthidraERegisterDecoderService.conthidra_eRegister_decoder(
            {
              bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
            },
            meterFrame.fport
          );
        break;
      case "DIEHL":
        parsedPayloadResult = this.DhielDecoderService.diehl_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          date
        );
        break;
      case "HONEYWELL":
        parsedPayloadResult = this.HoneywellDecoderService.diehl_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          date
        );
        break;
      case "ITRON":
        parsedPayloadResult = this.ItronDecoderService.diehl_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          date
        );
        break;
      case "BMETER":
        parsedPayloadResult = this.BmeterDecoderService.bmeter_decoder({
          bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
        });
        break;
      case "LW_MBUS":
        parsedPayloadResult = this.LwMbusDecoderService.LoRaWAN_MBus_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport,
          meterFrame.LORAWAN_HEADER?.FTYPE
        );
        break;
      case "SAGEMCOM":
        parsedPayloadResult = this.SagemcomDecoderService.Sagemcom_Gas_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "INTEGRA":
        parsedPayloadResult = this.IntegraDecoderService.Integra_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "VIEWSHINE":
        parsedPayloadResult = this.ViewshineDecoderService.Viewshine_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "HONEYWELL_GAS":
        parsedPayloadResult =
          this.HoneywellGasDecoderService.Honeywell_Gas_decoder(
            { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
            meterFrame.fport
          );
        break;
      case "BETA":
        parsedPayloadResult = this.BetaDecoderService.Beta_Gas_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "AIMEI":
        parsedPayloadResult = this.AimeiDecoderService.Aimei_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      default:
        break;
    }
    if (parsedPayloadResult && parsedPayloadResult.errors) {
      this.showParserResult(
        meterFrame.payload,
        parsedPayloadResult.errors,
        true
      );
    } else if (parsedPayloadResult) {
      this.showParserResult(meterFrame.payload, parsedPayloadResult.data);
    }
  }

  // Parseo de trama LoRaWAN
  parseLorawanFrame(meter: DetailDevice, frame: DeviceFrameLog): void {
    if (!meter.parser) {
      meter.parser = this.checkMeterManufacturerParser(meter);
    }
    let parsedFrame = this.LoraWanParserService.LoRaWAN_parser({
      bytes: this.SepemDecoderService.hexToBytes(frame.allFrameData),
    });
    if (
      parsedFrame["LORAWAN_HEADER"] &&
      parsedFrame["payload"] != null &&
      parsedFrame["payload"] != "" &&
      (parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "UNCONFIRMED DATA UP" ||
        parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "CONFIRMED DATA UP" ||
        (meter.parser == "LW_MBUS" &&
          (parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "UNCONFIRMED DATA DOWN" ||
            parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "CONFIRMED DATA DOWN")))
    ) {
      this.parsePayload(
        meter,
        parsedFrame,
        this.DateParserService.parseDate(
          frame.timestamp,
          "DD/MM/YYYY HH:mm:ss",
          LOCAL_TIMEZONE
        )
      );
    } else {
      if (
        parsedFrame["LORAWAN_HEADER"] &&
        (parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "UNCONFIRMED DATA DOWN" ||
          parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "CONFIRMED DATA DOWN")
      ) {
        this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
          frame: frame.allFrameData,
          payloadParsed: {
            FTYPE: parsedFrame["LORAWAN_HEADER"]["FTYPE"],
            FCtrl: parsedFrame["LORAWAN_HEADER"]["FCtrl"],
          },
          action: "parser",
        });
      } else {
        this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
          frame: frame.allFrameData,
          payloadParsed: { FTYPE: parsedFrame["LORAWAN_HEADER"]["FTYPE"] },
          action: "parser",
        });
      }
    }
  }

  // Parseo de dispositivos
  parseAssignedMeterList(meterList: any[]): AssignedDevice[] {
    return meterList.map((meter) => {
      return {
        id: meter.id,
        nroSerie: meter.n,
        lastCommunication: meter.lc,
        lastReadedValue: meter.lv,
        clave: meter.c,
        col01: meter.c1,
        col02: meter.c2,
        col03: meter.c3,
        col04: meter.c4,
        col05: meter.c5,
        col06: meter.c6,
        col07: meter.c7,
        col08: meter.c8,
        col09: meter.c9,
        col10: meter.c10,
        col11: meter.c11,
        col12: meter.c12,
        col13: meter.c13,
        col14: meter.c14,
        col15: meter.c15,
        col16: meter.c16,
        col17: meter.c17,
        col18: meter.c18,
        col19: meter.c19,
        col20: meter.c20,
        isAssigned: meter.ia,
        metrologyType: meter.m,
        latitude: meter.lt,
        longitude: meter.lg,
        consumptionCardsLastMonth: meter.clm,
        valveState: meter.v,
        precinto: meter.p,
        agrupation: meter.a,
        fabricante: meter.f,
        devType: meter.t,
        rfModule: meter.r,
        comments: meter.co,
        gateway: meter.g,
        lastConsumptionMax: meter.cmx,
        lastConsumptionMin: meter.cmn,
        lastConsumptionTotal: meter.cmt,
        fcv: meter.fcv,
        pcs: meter.pcs,
        pc: meter.pc,
        tipo: meter.tp,
      };
    });
  }

  // Desasignar contador como permanente a LW MBUS
  unassignLwMbusPermanent(concentratorId: number, childMeters: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant(
        childMeters.length > 1
          ? "lw-mbus-meters-unfix-question"
          : "lw-mbus-meter-unfix-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteLwMbusMainMeters({
          element: concentratorId,
          listElements: childMeters,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("command-sent")
            );
            this.SessionDataService.sendComponentData({
              realoadLwMusTable: true,
            });
          }
        });
      }
    });
  }

  // Asignar contador como permanente a LW MBUS
  assignLwMbusPermanent(concentratorId: number, childMeters: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant(
        childMeters.length > 1
          ? "lw-mbus-meters-fix-question"
          : "lw-mbus-meter-fix-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.addLwMbusMainMeters({
          element: concentratorId,
          listElements: childMeters,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("command-sent")
            );
            this.SessionDataService.sendComponentData({
              realoadLwMusTable: true,
            });
          }
        });
      }
    });
  }

  // Borrado de orden
  deleteMeterOrder(meterOrders: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("question-deleted-selected")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteOrder(meterOrders).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("deleted-success")
            );
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }

  // Cancelación de orden
  cancelMeterOrder(meterOrders: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("question-cancel-selected")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.cancelOrder(meterOrders).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("cancelled-success")
            );
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }
}
