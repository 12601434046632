// @angular
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { EkConfigurationControllerService } from "../../../../../services/server/EkConfigurationController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  DeviceEk280,
  DeviceEk280Sim,
  DeviceSim,
} from "../../DeviceInterface.type";
import { DetailDevice, SIM_STATES } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-sim-table",
  templateUrl: "./device-sim-table.component.html",
  styleUrls: ["./device-sim-table.component.scss"],
})
export class DeviceSimTableComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  @Input() sessionProfile: string;

  // Tabla de SIM
  @Input() meter: DetailDevice;
  ekConfigData: DeviceEk280;
  @Output() ekConfigDataFlag = new EventEmitter<any>();
  @Output() simListFlag = new EventEmitter<any>();
  maxReg: number = 1;
  simData: DeviceEk280Sim;
  simList: DeviceSim[];
  simSelected: number;
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "iccid",
      data: "iccid",
      search: "iccid",
      sort: "iccid",
      visible: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "ip",
      data: "ip",
      search: "ip",
      sort: "ip",
      visible: true,
    },
  ];
  tableData: object[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EkConfigurationController: EkConfigurationControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getData();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos
  getData(): void {
    let dataRequests: Observable<object>[] =
      this.sessionProfile == "ARSON"
        ? [
            this.EkConfigurationController.getEkConfigData(this.meter.id),
            this.EkConfigurationController.getSimList(),
          ]
        : [this.EkConfigurationController.getEkConfigData(this.meter.id)];

    forkJoin(dataRequests).subscribe((responses) => {
      // Respuesta del servicio de datos de EK
      if (responses[0]["code"] == 0) {
        let ekConfigData: DeviceEk280 = responses[0]["body"]?.ek280;
        let simData: DeviceEk280Sim = responses[0]["body"]?.sim;
        if (simData) {
          simData.stateParsed = simData.estado
            ? this.translate.instant(SIM_STATES[simData.estado])
            : this.translate.instant("unknown");
        }
        ekConfigData.iccid = simData?.iccid;
        ekConfigData.simId = simData?.id;

        let windows: { source: string; parsed: string }[] = [
          { source: "ventana1", parsed: "ventana1Parsed" },
          { source: "ventana2", parsed: "ventana2Parsed" },
          { source: "ventana3", parsed: "ventana3Parsed" },
        ];
        windows.forEach((window: { source: string; parsed: string }) => {
          if (ekConfigData[window.source]) {
            let hour: number = Math.floor(ekConfigData[window.source] / 100);
            let hourParsed: string =
              hour < 10 ? "0" + hour.toString() : hour.toString();
            let minutes: number = ekConfigData[window.source] % 100;
            let minutesParsed: string =
              minutes < 10 ? "0" + minutes.toString() : minutes.toString();
            ekConfigData[window.parsed] = hourParsed + ":" + minutesParsed;
          }
        });

        this.simData = simData;
        this.tableData = this.simData ? [this.simData] : [];
        this.ekConfigData = ekConfigData;
      }

      // Respuesta de servicio de listado de SIM
      if (responses[1] && responses[1]["code"] == 0) {
        this.simList = responses[1]["body"] ? responses[1]["body"] : [];
        this.simList.unshift({
          id: parseInt(this.ekConfigData?.simId),
          iccid: this.ekConfigData?.iccid,
        });
        this.simSelected = this.simList[0].id;
      }

      if (
        this.sessionProfile == "ARSON" ||
        this.sessionProfile == "ADMIN_ENTIDAD" ||
        this.sessionProfile == "ADMIN_CLIENTE" ||
        this.sessionProfile == "ADMIN_AGRUPACION"
      ) {
        this.simListFlag.emit(this.simList);
        this.ekConfigDataFlag.emit(this.ekConfigData);
      }
    });
  }

  // Guardado de SIM
  saveSim(): void {
    this.EkConfigurationController.saveEkSim(
      this.meter.id,
      this.simSelected
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.simList = null;
        this.simData = null;
        this.getData();
      }
    });
  }
}
