import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ScrollingModule } from "@angular/cdk/scrolling";

// Graph
import { GraphModule } from "../graph-module/graph.module";

// Map
import { MapModule } from "../map-module/map.module";

// Nav
import { NavModule } from "../nav-module/nav.module";

// Subscriber
import { SubscriberCupsComponent } from "./subscriber-cups/subscriber-cups.component";
import { SubscriberMainComponent } from "./subscriber-main/subscriber-main.component";
import { SubscriberNavComponent } from "./subscriber-nav/subscriber-nav.component";
import { SubscriberEditComponent } from "./subscriber-edit/subscriber-edit.component";
import { SubscriberCustomAlarmsComponent } from "./subscriber-cups/subscriber-custom-alarms/subscriber-custom-alarms";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,
    ScrollingModule,
    MatFormFieldModule,
    MatInputModule,

    // Graph
    GraphModule,

    // Map
    MapModule,

    // Nav
    NavModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    SubscriberCupsComponent,
    SubscriberMainComponent,
    SubscriberNavComponent,
    SubscriberEditComponent,
    SubscriberCustomAlarmsComponent,
  ],
  exports: [
    SubscriberCupsComponent,
    SubscriberMainComponent,
    SubscriberNavComponent,
    SubscriberEditComponent,
    SubscriberCustomAlarmsComponent,
  ],
})
export class SubscriberModule {}
