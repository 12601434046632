<!-- Sustitución -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5
      *ngIf="
        data.show != 'file' && data.show != 'report' && data.show != 'edit'
      "
      class="dialog-title"
    >
      {{ data.substitution.nroSerieOld }} {{ "replaced-by" | translate }}
      {{ data.substitution.nroSerieNew }}
    </h5>
    <!-- Fichero -->
    <h5 *ngIf="data.show == 'file'" class="dialog-title">
      {{ "file-download" | translate }}
    </h5>
    <!-- Informe -->
    <h5 *ngIf="data.show == 'report'" class="dialog-title">
      {{ "substitution-file-download" | translate }}
    </h5>
    <!-- Edición -->
    <h5 *ngIf="data.show == 'edit'" class="dialog-title">
      {{ data.substitution.nroSerieOld }}
      {{ "replaced-by" | translate }}
      {{ data.substitution.nroSerieNew }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Imágenes -->
    <div *ngIf="data.show == 'images'" class="substitution-images">
      <div class="substitution-image">
        <div>
          <span
            ><b>{{ "previous" | translate }}:</b>
            {{ data.substitution.nroSerieOld }}</span
          >
          <!-- Nueva imagen -->
          <button
            *ngIf="sessionProfile == 'ARSON'"
            mat-button
            (click)="updateOldImage.click()"
          >
            {{ "update-image" | translate }}
            <input
              #updateOldImage
              class="hidden"
              type="file"
              id="file"
              (change)="updateImage(data.substitution.nroSerieOld, $event)"
              accept="image/x-png,image/jpeg,image/ico"
            />
          </button>
        </div>
        <img
          *ngIf="data.oldImage; else imageNotFound"
          class="map-modal-img img-rounded"
          src="data:image/jpg;base64,{{ data.oldImage }}"
        />
      </div>
      <div class="substitution-image">
        <div>
          <span
            ><b>{{ "new" | translate }}:</b>
            {{ data.substitution.nroSerieNew }}</span
          >
          <!-- Nueva imagen -->
          <button
            *ngIf="sessionProfile == 'ARSON'"
            mat-button
            (click)="updateNewImage.click()"
          >
            {{ "update-image" | translate }}
            <input
              #updateNewImage
              class="hidden"
              type="file"
              id="file"
              (change)="updateImage(data.substitution.nroSerieNew, $event)"
              accept="image/x-png,image/jpeg,image/ico"
            />
          </button>
        </div>
        <img
          *ngIf="data.newImage; else imageNotFound"
          class="map-modal-img img-rounded"
          src="data:image/jpg;base64,{{ data.newImage }}"
        />
      </div>
    </div>

    <!-- Audio -->
    <div *ngIf="data.show == 'audio'" class="substitution-audio">
      <audio *ngIf="data.audio" controls autoplay>
        <source src="data:audio/mpeg;base64,{{ data.audio }}" />
      </audio>
      <span *ngIf="!data.audio">{{ "audio-not-found" | translate }}</span>
    </div>

    <!-- Fichero -->
    <div *ngIf="data.show == 'file'" class="substitution-file">
      <mat-checkbox class="mat-option" [(ngModel)]="fileData">
        {{ "file" | translate }}
      </mat-checkbox>
      <mat-checkbox class="mat-option" [(ngModel)]="fileImages">
        {{ "file-images" | translate }}
      </mat-checkbox>
      <mat-checkbox class="mat-option" [(ngModel)]="fileAudios">
        {{ "file-audios" | translate }}
      </mat-checkbox>
    </div>

    <!-- Edición -->
    <div *ngIf="data.show == 'edit'" class="substitution-edit">
      <span
        ><b>{{ "fix-substitution" | translate }}</b>
      </span>
      <div class="substitution-edit-option">
        <!-- Nro serie -->
        <mat-form-field appearance="standard">
          <mat-label>
            <span>{{ "serial-number" | translate }} (*)</span>
          </mat-label>
          <input
            matInput
            [(ngModel)]="data.substitution.nroSerieOld"
            placeholder="{{ 'serial-number' | translate }}..."
          />
        </mat-form-field>
        <!-- Valor -->
        <mat-form-field appearance="standard">
          <mat-label>
            <span>{{ "value" | translate }} (*)</span>
          </mat-label>
          <input
            matInput
            type="number"
            step="0.001"
            [(ngModel)]="data.substitution.valueOld"
            placeholder="{{ 'value' | translate }}..."
          />
        </mat-form-field>
      </div>
      <div class="substitution-edit-buttons">
        <!-- Corrección-->
        <button
          mat-button
          class="mat-button-icon-text"
          (click)="fixSubstitution()"
        >
          <i class="fas fa-wrench"></i>
          {{ "fix" | translate }}
        </button>
        <!-- Nueva imagen -->
        <button
          *ngIf="sessionProfile == 'ARSON'"
          mat-button
          class="mat-button-icon-text"
          (click)="updateOldImage.click()"
        >
          <i class="fas fa-image"></i>
          {{ "update-image" | translate }} {{ data.substitution.nroSerieOld }}
          <input
            #updateOldImage
            class="hidden"
            type="file"
            id="file"
            (change)="updateImage(data.substitution.nroSerieOld, $event)"
            accept="image/x-png,image/jpeg,image/ico"
          />
        </button>
      </div>

      <hr />

      <span
        ><b>{{ "update-substitution" | translate }}</b></span
      >
      <div class="substitution-edit-option">
        <!-- Último valor -->
        <mat-form-field appearance="standard">
          <mat-label>
            <span>{{ "last-know-value" | translate }}</span>
          </mat-label>
          <input
            matInput
            [(ngModel)]="data.substitution.valueOld"
            placeholder="{{ 'last-know-value' | translate }}..."
          />
        </mat-form-field>
        <!-- Valor inicial -->
        <mat-form-field appearance="standard">
          <mat-label>
            <span>{{ "value-new" | translate }}</span>
          </mat-label>
          <input
            matInput
            [(ngModel)]="data.substitution.valueNew"
            placeholder="{{ 'value-new' | translate }}..."
          />
        </mat-form-field>
        <!-- Precinto -->
        <mat-form-field appearance="standard">
          <mat-label>
            <span>{{ "seal" | translate }}</span>
          </mat-label>
          <input
            matInput
            [(ngModel)]="data.substitution.precinto"
            placeholder="{{ 'seal' | translate }}..."
          />
        </mat-form-field>
      </div>
      <div class="substitution-edit-option-text">
        <!-- Comentarios -->
        <mat-form-field appearance="standard">
          <mat-label>{{ "comments" | translate }}</mat-label>
          <textarea
            matInput
            [(ngModel)]="data.substitution.comments"
            placeholder="{{ 'comments' | translate }}..."
          ></textarea>
        </mat-form-field>
      </div>
      <div class="substitution-edit-buttons">
        <!-- Edición -->
        <button
          mat-button
          class="mat-button-icon-text"
          (click)="updatesubstitutionData()"
        >
          <i class="fas fa-sync-alt"></i>
          {{ "update" | translate }}
        </button>
        <!-- Nueva imagen -->
        <button
          *ngIf="sessionProfile == 'ARSON'"
          mat-button
          class="mat-button-icon-text"
          (click)="updateNewImage.click()"
        >
          <i class="fas fa-image"></i>
          {{ "update-image" | translate }} {{ data.substitution.nroSerieNew }}
          <input
            #updateNewImage
            class="hidden"
            type="file"
            id="file"
            (change)="updateImage(data.substitution.nroSerieNew, $event)"
            accept="image/x-png,image/jpeg,image/ico"
          />
        </button>
      </div>
    </div>

    <!-- Informe -->
    <div *ngIf="data.show == 'report'" class="reports-container" #pdf>
      <!-- Carga de fichero de datos -->
      <div class="report-file" *ngIf="!reportReady && !fromCups">
        <span>{{ "substitution-data-file" | translate }}:</span>
        <app-file-import
          [fileType]="'.csv'"
          (fileReady)="processFile($event)"
        ></app-file-import>
      </div>

      <!-- Spinner de carga -->
      <div class="loading-spinner" *ngIf="!reportReady && fromCups">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>

      <!-- Informes -->
      <ng-container *ngIf="reportReady">
        <ng-container *ngFor="let report of reportList; index as i">
          <hr *ngIf="i > 0" />
          <div class="report-container">
            <div class="report-title">
              <img [src]="entityLogo" />
              <div><b>Contrato: </b>{{ report.contrato }}</div>
            </div>
            <!-- Datos de CUPS -->
            <div class="report-cups-info">
              <table>
                <tr>
                  <th>Cliente</th>
                  <td>{{ report.cliente }}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Domicilio</th>
                  <td>{{ report.domicilio }}</td>
                  <th class="report-second-column">Emplazto</th>
                  <td>{{ report.emplazto }}</td>
                </tr>
                <tr>
                  <th>Localidad</th>
                  <td>{{ report.localidad }}</td>
                  <th class="report-second-column">Zona</th>
                  <td>{{ report.zona }}</td>
                </tr>
                <tr>
                  <th>Motivo</th>
                  <td>{{ report.motivo }}</td>
                  <th class="report-second-column">Teléfono</th>
                  <td>{{ report.telefono }}</td>
                </tr>
              </table>
            </div>
            <!-- Datos de esfera -->
            <div class="report-sphere">
              <table>
                <tr>
                  <th>Contador</th>
                  <th>Esf.</th>
                  <th>Marca</th>
                  <th>Modelo</th>
                  <th>Calibre</th>
                  <th>Nº serie</th>
                  <th class="report-th-last">F. instal.</th>
                </tr>
                <tr class="report-reading">
                  <td>{{ report.lectura0 ? report.lectura0[0] : "" }}</td>
                  <td>{{ report.lectura0 ? report.lectura0[1] : "" }}</td>
                  <td>{{ report.lectura0 ? report.lectura0[2] : "" }}</td>
                  <td>{{ report.lectura0 ? report.lectura0[3] : "" }}</td>
                  <td>{{ report.lectura0 ? report.lectura0[4] : "" }}</td>
                  <td>{{ report.lectura0 ? report.lectura0[5] : "" }}</td>
                  <td>{{ report.lectura0 ? report.lectura0[6] : "" }}</td>
                </tr>
              </table>

              <table>
                <tr>
                  <th colspan="6">1ª esfera</th>
                </tr>
                <tr>
                  <th>Per/Año</th>
                  <th>Fecha</th>
                  <th>Lectura</th>
                  <th>Ori</th>
                  <th>Consumo</th>
                  <th class="report-th-last">Obs</th>
                </tr>
                <tr class="report-reading">
                  <td>{{ report.lectura1 ? report.lectura1[0] : "" }}</td>
                  <td>{{ report.lectura1 ? report.lectura1[1] : "" }}</td>
                  <td>{{ report.lectura1 ? report.lectura1[2] : "" }}</td>
                  <td>{{ report.lectura1 ? report.lectura1[3] : "" }}</td>
                  <td>{{ report.lectura1 ? report.lectura1[4] : "" }}</td>
                  <td>{{ report.lectura1 ? report.lectura1[5] : "" }}</td>
                </tr>
                <tr class="report-reading">
                  <td>{{ report.lectura2 ? report.lectura2[0] : "" }}</td>
                  <td>{{ report.lectura2 ? report.lectura2[1] : "" }}</td>
                  <td>{{ report.lectura2 ? report.lectura2[2] : "" }}</td>
                  <td>{{ report.lectura2 ? report.lectura2[3] : "" }}</td>
                  <td>{{ report.lectura2 ? report.lectura2[4] : "" }}</td>
                  <td>{{ report.lectura2 ? report.lectura2[5] : "" }}</td>
                </tr>
                <tr class="report-reading">
                  <td>{{ report.lectura3 ? report.lectura3[0] : "" }}</td>
                  <td>{{ report.lectura3 ? report.lectura3[1] : "" }}</td>
                  <td>{{ report.lectura3 ? report.lectura3[2] : "" }}</td>
                  <td>{{ report.lectura3 ? report.lectura3[3] : "" }}</td>
                  <td>{{ report.lectura3 ? report.lectura3[4] : "" }}</td>
                  <td>{{ report.lectura3 ? report.lectura3[5] : "" }}</td>
                </tr>
                <tr class="report-reading">
                  <td>{{ report.lectura4 ? report.lectura4[0] : "" }}</td>
                  <td>{{ report.lectura4 ? report.lectura4[1] : "" }}</td>
                  <td>{{ report.lectura4 ? report.lectura4[2] : "" }}</td>
                  <td>{{ report.lectura4 ? report.lectura4[3] : "" }}</td>
                  <td>{{ report.lectura4 ? report.lectura4[4] : "" }}</td>
                  <td>{{ report.lectura4 ? report.lectura4[5] : "" }}</td>
                </tr>
              </table>

              <div class="report-comments">
                <span><b>Observaciones del cambio:</b> </span>
                {{ report.comments }}
              </div>

              <table [ngStyle]="{ width: '45rem' }">
                <tr>
                  <th [ngStyle]="{ width: '30rem' }">Nº contador retirado</th>
                  <th [ngStyle]="{ width: '15rem' }">Lectura</th>
                </tr>
                <tr>
                  <td>{{ report.nroSerieOld }}</td>
                  <td>{{ report.valueOld }}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th [ngStyle]="{ width: '30rem' }">Nº contador instalado</th>
                  <th>Precinto</th>
                  <th>Marca/modelo</th>
                  <th>Calibre</th>
                  <th>Lectura</th>
                  <th>Año fabricación</th>
                </tr>
                <tr>
                  <td>{{ report.nroSerieNew }}</td>
                  <td>{{ report.precinto }}</td>
                  <td>{{ report.newModel }}</td>
                  <td>{{ report.newCalibre }}</td>
                  <td>{{ report.valueNew }}</td>
                  <td>{{ report.newFabricacion }}</td>
                </tr>
              </table>
            </div>
            <!-- Módulo RF -->
            <div class="report-rf-module">
              <table>
                <tr>
                  <th colspan="4">Telelectura actual</th>
                </tr>
                <tr>
                  <th class="report-th-no-border">Equipo comunicación</th>
                  <td class="report-td-border">{{ report.rfModule }}</td>
                  <th class="report-th-no-border">Modelo</th>
                  <td>{{ report.rfModuleModel }}</td>
                </tr>
              </table>
            </div>
            <!-- Usuario -->
            <div class="report-user">
              <table>
                <tr>
                  <th>Operario</th>
                  <td>{{ report.userName }}</td>
                  <th>Empresa</th>
                  <td>IoT Water Analytics S.L.</td>
                  <th>Fecha</th>
                  <td>{{ report.replaceDate }}</td>
                </tr>
              </table>
            </div>
            <!-- Fotos -->
            <div class="report-images">
              <div>
                <span
                  ><b>{{ "previous" | translate }}: </b>
                  {{ report.nroSerieOld }}</span
                >
                <img
                  *ngIf="report.oldImage; else imageNotFound"
                  class="img-rounded"
                  src="data:image/jpg;base64,{{ report.oldImage }}"
                />
              </div>
              <div>
                <span
                  ><b>{{ "new" | translate }}: </b>
                  {{ report.nroSerieNew }}</span
                >
                <img
                  *ngIf="report.newImage; else imageNotFound"
                  class="img-rounded"
                  src="data:image/jpg;base64,{{ report.newImage }}"
                />
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Cerrar -->
  <div class="dialog-footer">
    <!-- Fichero -->
    <button
      *ngIf="data.show == 'file'"
      mat-button
      (click)="downloadSubstitutionFile()"
    >
      {{ "download" | translate }}
    </button>
    <!-- Informe -->
    <button
      *ngIf="data.show == 'report'"
      mat-button
      class="mat-button-icon-text"
      (click)="downloadPdf()"
      [disabled]="!reportReady"
    >
      <i [ngClass]="'fas fa-download'"></i>
      {{ "download" | translate }}
    </button>

    <!-- Cerrar -->
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>

  <!-- Imagen no encontrada -->
  <ng-template #imageNotFound>
    <div class="image-not-found">
      <i class="fas fa-image"></i>
      <span>{{ "image-not-found" | translate }}</span>
    </div>
  </ng-template>
</div>
