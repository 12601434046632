import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicio propios
import { TemplateService } from "../../../../services/shared/TemplateService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Interfaces
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import { DeviceType, Manufacturer } from "./manufacturer.type";
// Variables
import { DEVICE_TYPES } from "./manufacturer.type";

@Component({
  selector: "app-management-manufacturers",
  templateUrl: "./management-manufacturers.component.html",
  styleUrls: ["./management-manufacturers.component.scss"],
})
export class ManagementManufacturersComponent implements OnInit {
  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  manufacturers: object;
  manufacturerList: Manufacturer[];
  selectedManufacturer: Manufacturer;
  deviceTypeList: DeviceType[];
  selectedDeviceType: DeviceType;
  editionTarget: Manufacturer | DeviceType;
  currentLanguage: string;
  langs: string[] = ["es", "en", "es-ca", "fr", "it"];
  newEditionActive: string = null;
  devicePreselect: string;
  manufacturerPreselect: string;
  readonly DEVICE_TYPES = DEVICE_TYPES;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "download",
      icon: "fas fa-download",
      text: this.translate.instant("download"),
      visible: true,
    },
    {
      action: "save",
      icon: "fas fa-save",
      text: this.translate.instant("save"),
      visible: true,
    },
    {
      action: "merge-files",
      icon: "fas fa-exchange-alt",
      text: this.translate.instant("merge-files"),
      visible: true,
    },
  ];
  @ViewChild("mergeInput") mergeInput: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private http: HttpClient,
    private ManagementController: ManagementControllerService,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentLanguage = this.SessionDataService.getCurrentLanguage();

    let headers = {
      headers: new HttpHeaders({
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      }),
    };
    this.http
      .get("../../../../../assets/manufacturers/manufacturers.json", headers)
      .subscribe((manufacturers) => {
        this.manufacturers = manufacturers;
        this.getManufaturerList();
      });
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del listado de fabricantes
  getManufaturerList(): void {
    let manufacturerList: Manufacturer[] = [];
    for (let manufacturer in this.manufacturers) {
      if (manufacturer != "*") {
        manufacturerList.push(this.manufacturers[manufacturer]);
        manufacturerList[manufacturerList.length - 1].id = manufacturer;
        manufacturerList[manufacturerList.length - 1].label = this
          .manufacturers[manufacturer].manufacturerText[this.currentLanguage]
          ? this.manufacturers[manufacturer].manufacturerText[
              this.currentLanguage
            ]
          : this.manufacturers[manufacturer].manufacturerText["es"];
      }
    }
    let newManufacturer = new Manufacturer();
    newManufacturer.id = "-1";
    newManufacturer.label = this.translate.instant("new");
    manufacturerList.sort((a, b) => a.label.localeCompare(b.label));
    manufacturerList.unshift(newManufacturer);
    this.manufacturerList = manufacturerList;
  }

  // Obtención del listado de tipos de dispositivo
  getDeviceTypeList(): void {
    let deviceTypeList = [];
    for (let deviceType in this.selectedManufacturer.devices) {
      deviceTypeList.push(this.selectedManufacturer.devices[deviceType]);
      deviceTypeList[deviceTypeList.length - 1].id = deviceType;
      deviceTypeList[deviceTypeList.length - 1].label = this
        .selectedManufacturer.devices[deviceType].deviceText[
        this.currentLanguage
      ]
        ? this.selectedManufacturer.devices[deviceType].deviceText[
            this.currentLanguage
          ]
        : this.selectedManufacturer.devices[deviceType].deviceText["es"];
    }
    let newDevice = new DeviceType();
    newDevice.id = "-1";
    newDevice.label = this.translate.instant("new");
    deviceTypeList.sort((a, b) => a.label.localeCompare(b.label));
    deviceTypeList.unshift(newDevice);
    this.deviceTypeList = deviceTypeList;
  }

  // Selección de tipo de dispositivo en fabricante
  selectType(type: string): void {
    let types = this.editionTarget.type?.split(", ");
    if (types?.includes(type)) {
      types.splice(types.indexOf(type), 1);
      this.checkManufacturerTypes(type, true);
    } else if (types) {
      types.push(type);
      this.checkManufacturerTypes(type, false);
    } else {
      types = [type];
      this.checkManufacturerTypes(type, false);
    }
    this.editionTarget.type = types.join(", ");
  }

  // Comprobación de tipos en fabricante
  checkManufacturerTypes(type: string, included: boolean): void {
    let manufacturerTypes = this.selectedManufacturer.type.split(", ");
    if (!included && !manufacturerTypes.includes(type)) {
      manufacturerTypes.push(type);
      this.selectedManufacturer.type = manufacturerTypes.join(", ");
    } else if (included && manufacturerTypes.includes(type)) {
      let manufacturerDevices = [];
      for (let device in this.selectedManufacturer.devices) {
        manufacturerDevices.push(this.selectedManufacturer.devices[device]);
      }
      if (!manufacturerDevices.some((device) => device.type.includes(type))) {
        manufacturerTypes.splice(manufacturerTypes.indexOf(type), 1);
        this.selectedManufacturer.type = manufacturerTypes.join(", ");
      }
    }
  }

  // Descarga de fichero de fabricantes actualizados
  downloadManufacturers(manufacturers: object): void {
    saveAs(
      new Blob([JSON.stringify(manufacturers)], {
        type: "application/json",
      }),
      "manufacturers.json"
    );
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "download":
        this.downloadManufacturers(this.getNewManufacturersJSON());
        break;
      case "save":
        this.saveManufacturers();
        break;
      case "merge-files":
        this.mergeInput.nativeElement.click();
        break;
      default:
        break;
    }
  }

  // Guardar fabricantes
  saveManufacturers(): void {
    let manufacturers = this.getNewManufacturersJSON();
    let formData: FormData = new FormData();
    let blob = new Blob([JSON.stringify(manufacturers)], {
      type: "application/json",
    });
    let file = new File([blob], "manufacturers.json");
    formData.set("file", file);
    this.ManagementController.saveManufacturers(formData).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("manufacturers-updated")
          );
        }
      }
    );
  }

  // Recreación de archivo original
  getNewManufacturersJSON(): object {
    let manufacturers = {};
    for (let manufacturer in this.manufacturers) {
      if (this.manufacturers[manufacturer].id) {
        manufacturers[this.manufacturers[manufacturer].id] = {
          manufacturerText: this.manufacturers[manufacturer].manufacturerText,
          type: this.manufacturers[manufacturer].type,
        };
        let devices = {};
        for (let device in this.manufacturers[manufacturer].devices) {
          devices[device] = {
            deviceText:
              this.manufacturers[manufacturer].devices[device].deviceText,
            type: this.manufacturers[manufacturer].devices[device].type,
          };
        }
        manufacturers[this.manufacturers[manufacturer].id].devices = devices;
      }
      manufacturers["*"] = this.manufacturers["*"];
    }
    return manufacturers;
  }

  // Fusión de archivos de idiomas
  mergeFiles(files: any): void {
    for (let file in files) {
      if (files[file]?.name?.includes(".json")) {
        this.readFile(files[file]);
      }
    }
  }

  // Lectura de fichero
  readFile(file: File): void {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let fileManufacturers = JSON.parse(e.target.result);
      let newManufacturers = this.getNewManufacturersJSON();

      // Comprobación de actualizaciones en los fabricantes del archivo subido
      for (let manufacturer in fileManufacturers) {
        if (newManufacturers[manufacturer]) {
          fileManufacturers[manufacturer].manufacturerText =
            newManufacturers[manufacturer].manufacturerText;
          fileManufacturers[manufacturer].type =
            newManufacturers[manufacturer].type;

          for (let device in fileManufacturers[manufacturer].devices) {
            if (newManufacturers[manufacturer].devices[device]) {
              fileManufacturers[manufacturer].devices[device].deviceText =
                newManufacturers[manufacturer].devices[device].deviceText;
              fileManufacturers[manufacturer].devices[device].type =
                newManufacturers[manufacturer].devices[device].type;
            }
          }
        }
      }

      // Añadido de nuevos fabricantes/tipos respecto al archivo subido
      for (let manufacturer in newManufacturers) {
        if (!fileManufacturers[manufacturer]) {
          fileManufacturers[manufacturer] = newManufacturers[manufacturer];
        } else {
          for (let device in newManufacturers[manufacturer].devices) {
            if (!fileManufacturers[manufacturer].devices[device]) {
              fileManufacturers[manufacturer].devices[device] =
                newManufacturers[manufacturer].devices[device];
            }
          }
        }
      }

      saveAs(
        new Blob([JSON.stringify(fileManufacturers)], {
          type: "application/json",
        }),
        "manufacturers.json"
      );
    };
    if (file) {
      reader.readAsText(new Blob([file], { type: file.type }));
    }
  }

  // Reseteo del fichero seleccionado
  resetFile(): void {
    this.mergeInput.nativeElement.value = null;
  }

  // Guardado nuevo elemento
  saveNew(): void {
    if (this.newEditionActive == "manufacturer") {
      this.manufacturers[this.selectedManufacturer.id] =
        this.selectedManufacturer;
      this.getManufaturerList();
      this.selectedManufacturer = this.manufacturerList.find(
        (manufacturer) => manufacturer.id == this.selectedManufacturer.id
      );
      this.manufacturerPreselect = this.selectedManufacturer.id;
    } else if (this.newEditionActive == "device") {
      this.manufacturers[this.selectedManufacturer.id].devices[
        this.selectedDeviceType.id
      ] = this.selectedDeviceType;
      this.getDeviceTypeList();
      this.selectedDeviceType = this.deviceTypeList.find(
        (device) => device.id == this.selectedDeviceType.id
      );
      this.devicePreselect = this.selectedDeviceType.id;
    }
    this.newEditionActive = "saved";
  }
}
