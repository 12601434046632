import {
  Component,
  Inject,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogComponent } from "../MaterialInterface.type";
import { MaterialDialogHtmlAnchorDirective } from "./material-dialog-html-anchor.directive";
import { MaterialDialogItem } from "./material-dialog-item";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-material-dialog",
  templateUrl: "./material-dialog.component.html",
  styleUrls: ["./material-dialog.component.scss"],
})
export class MaterialDialogComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @ViewChild(MaterialDialogHtmlAnchorDirective, { static: true })
  htmlAnchor: MaterialDialogHtmlAnchorDirective;
  componentData: MaterialDialogItem;
  dialog: Subscription;
  routerEvents: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private dialogRef: MatDialogRef<MaterialDialogComponent>,
    private router: Router,
    private SessionDataService: SessionDataService,
    @Inject(MAT_DIALOG_DATA) public data: MaterialDialogItem
  ) {
    this.componentData = data;
  }

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "close") {
          this.closeDialog();
        }
      }
    );

    // Escucha de los cambios de url al finalizar la navegación
    this.routerEvents = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeDialog();
      });
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.dialog.unsubscribe();
    this.routerEvents.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(() => (this.loadComponent(this.componentData), 0));
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(data: any): void {
    const viewContainerRef = this.htmlAnchor.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<DialogComponent>(
      data.component
    );
    componentRef.instance.data = data.data;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
