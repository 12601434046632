<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b
          >{{ "meter-user-events" | translate }}:
          {{ meter != null ? meter.nroSerie : ("no-data" | translate) }}</b
        >
      </h5>

      <!-- Menú de opciones del panel -->
      <app-material-panel-menu
        *ngIf="sessionProfile == 'ARSON'"
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>

    <div class="panel-body">
      <!-- Tabla de gateways -->
      <app-table-controller
        [export]="true"
        [exportFileName]="'meter-user-events' | translate"
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="tableMaxReg"
        [dateRangeSelector]="true"
        [initialDate]="dataInitialDate"
        (dataRequest)="getData($event.from, $event.to)"
        [orderBy]="orderBy"
        [specialFilter]="true"
        [data]="meterUserEvents"
      >
      </app-table-controller>
    </div>
  </div>
</section>
