<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "graph-comparison" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <div
          *ngIf="selectOptions; else elseBlock"
          class="graph-comparison-selectors"
        >
          <div *ngFor="let itemSelected of selection; index as i">
            <!-- Selector de asociaciones -->
            <app-material-select
              class="meters-graph-select"
              [filter]="true"
              [fullWidth]="true"
              [title]="'selection' | translate"
              [bindValue]="'id'"
              [bindLabel]="'name'"
              [bindGroup]="'group'"
              [groups]="selectGroups"
              [data]="selectOptions"
              (selectedOption)="
                itemSelected.selected = $event; checkShowGraph()
              "
            ></app-material-select>
            <app-material-date-selector
              [initialDateNull]="true"
              [maxInterval]="i > 0 ? maxInterval : null"
              [minInterval]="i > 0 ? maxInterval : null"
              [hint]="i > 0 ? ('graph-comparison-interval' | translate) : null"
              (dateRangeUpdated)="
                itemSelected.dateRange = $event;
                checkShowGraph();
                checkMaxInterval(i)
              "
            ></app-material-date-selector>

            <div class="graph-comparison-selectors-drop">
              <span *ngIf="i > 1" (click)="dropSelection(i)"
                ><i class="fas fa-trash-alt"></i
              ></span>
            </div>
          </div>
        </div>
        <div *ngIf="selectOptions" class="graph-comparison-buttons">
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="addSelection()"
          >
            <i class="fas fa-tachometer-alt"></i>{{ "select-new" | translate }}
          </button>
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="graphActive = true; graphViewEnabled = false; showGraph()"
            [disabled]="!graphEnabled"
          >
            <i class="fas fa-chart-area"></i>{{ "show-graph" | translate }}
          </button>
        </div>

        <!-- Gráfica -->
        <div *ngIf="graphActive">
          <hr />
          <app-graph-controller
            *ngIf="graphViewEnabled; else elseBlock"
            [highchartsOptions]="highchartsOptions"
            [data]="chartOptions"
            [chartConstructor]="chartConstructor"
            [noDateSelector]="true"
            [filters]="graphFilters"
            (filterFlag)="updateGraphData($event)"
            (dataRequest)="showGraph()"
          >
          </app-graph-controller>
        </div>
      </div>
    </div>
  </div>
</section>
