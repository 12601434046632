<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default network-state-panel">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>

          <!-- Título -->
          <div class="network-state-client">
            <app-material-select
              class="material-header-select"
              [title]="'entity-evolution-global' | translate"
              [bindValue]="'clientId'"
              [bindLabel]="'clientName'"
              [selected]="initialSelectedClient"
              [data]="clientDropdownData"
              (selectedOption)="selectedClient = $event; updateTable()"
            ></app-material-select>
          </div>

          <!-- Botón actualizar -->
          <app-material-panel-menu
            [onlyButton]="true"
            [icon]="updateIcon"
            [title]="updateTitle"
            (action)="tableData = null; getData()"
          >
          </app-material-panel-menu>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'networkStateAllEntity'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [avoidRepaint]="true"
          (tableAction)="tableActions($event.action.name, $event.element)"
          (extraTableAction)="
            extraTableActions(
              $event.action.name,
              $event.parentElement,
              $event.childElement
            )
          "
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
