import { Injectable } from "@angular/core";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Parseador de fechas
import * as moment_timezone from "moment-timezone";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import {
  GatewayLocation,
  GatewayLocationData,
} from "../../../interfaces/GatewayGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class MapGatewayTooltipService {
  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Tooltip de gateways
  /***************************************************************************/

  // Creación del tooltip para cada gateway
  getGatewayTooltip(
    gateway: any,
    meterType: any,
    sessionProfile: string,
    currentAgrupation: Agrupation,
    mapType: string,
    mainGateway: any
  ): string {
    let tooltip: string;
    let titleClass: string =
      ((!gateway.contadoresPrincipal ||
        gateway.contadoresPrincipal?.length == 0) &&
        (!gateway.contadoresRedundante ||
          gateway.contadoresRedundante?.length == 0) &&
        mapType != "onlyGateways") ||
      (mapType == "onlyGateways" &&
        gateway.nroMainContadores == 0 &&
        gateway.nroRedundantContadores == 0)
        ? "gatewaywarning-gradient"
        : gateway.comunica
        ? "gateway-gradient"
        : "gatewayerror-gradient";
    let dateFormat = this.SessionDataService.getCurrentDateFormat();

    // Tooltip
    switch (mapType) {
      // ANCHOR Mapa de sospechas
      case "warnings":
        tooltip = `<div class="map-tooltip">
                          <span class="map-tooltip-close-background"></span>
                          <table>
                            <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title gatewaywarning-gradient">
                            <th colspan="2" title="` +
          this.translate.instant("show-detail") +
          `">
                              <b>` +
          this.translate.instant("gateway") +
          `: </b>`;
        tooltip +=
          `<a class="map-link" href="#/gateways/detalle/gateway/` +
          gateway.id +
          `"> ` +
          (gateway.unidadVenta != null
            ? gateway.unidadVenta
            : this.translate.instant("no-data")) +
          ` <i class="fas fa-link"></i>
                              </a>`;
        // Imagen
        if (gateway.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                          </tr>
                          <tr class="map-separator-tr"></tr>`;
        tooltip +=
          `                <tr>
                            <td colspan="2">
                              ` +
          this.translate.instant("warnings" + gateway.warning) +
          `
                           </tr>`;
        break;
      // ANCHOR Mapa de cobertura
      case "coverage":
        tooltip = `<div class="map-tooltip">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title gateway-gradient">
                      <th colspan="2" title="` +
          this.translate.instant("show-detail") +
          `">
                        <b>` +
          this.translate.instant("gateway") +
          `: </b>`;
        if (sessionProfile == "ARSON") {
          tooltip +=
            `<a class="map-link" href="#/gateways/detalle/gateway/` +
            gateway.id +
            `"> ` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data")) +
            ` <i class="fas fa-link"></i>
                        </a>`;
        } else {
          tooltip +=
            gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data");
        }
        // Imagen
        if (gateway.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip +=
          `</th>
                    </tr>
                    <tr class="map-separator-tr"></tr>
                    <tr>
                      <td>
                        <b>` +
          this.translate.instant("installation-date") +
          `: </b>
                      </td>
                      <td>` +
          (gateway.installation != null
            ? moment_timezone(gateway.installation)
                .tz(currentAgrupation.timezone)
                .format(dateFormat + " HH:mm")
            : this.translate.instant("no-data")) +
          `</td>
                    </tr>`;
        break;
      // ANCHOR Mapa de detalle de contador
      case "meterDetail":
        tooltip = `<div class="map-tooltip">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        if (sessionProfile == "ARSON") {
          tooltip +=
            `<tr class="map-tooltip-title gateway-gradient">
                        <th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `">
                          <b>` +
            this.translate.instant("gateway") +
            `: </b>
                          <a class="map-link"  href="#/gateways/detalle/gateway/` +
            gateway.id +
            `">` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data")) +
            ` <i class="fas fa-link"></i>
                          </a>`;
        } else {
          tooltip =
            `<tr>
                      <th>
                        <b>` +
            this.translate.instant("gateway") +
            ": </b>" +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data"));
        }
        // Imagen
        if (gateway.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                </tr>
                <tr class="map-separator-tr"></tr>`;
        if (!gateway.otherEntity) {
          tooltip +=
            `<tr>
                        <td>
                          <b>RSSI:</b> 
                        </td>
                        <td>` +
            (gateway.rssi != null
              ? gateway.rssi + "dBm"
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>` +
            this.translate.instant("main-meter-number") +
            `:</b>
                        </td>
                        <td>` +
            (gateway.mainMeters != null
              ? gateway.mainMeters
              : this.translate.instant("no-data")) +
            `</td>
                      </tr>`;
        } else {
          tooltip +=
            `<tr>
                        <td>
                          <b>` +
            this.translate.instant("main-meter-number") +
            `:</b>
                        </td>
                        <td>` +
            (gateway.mainMeters != null
              ? gateway.mainMeters
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>`;
          tooltip +=
            `<tr>
                <td>
                  <button type="button" class="btn btn-default assignGateway">` +
            this.translate.instant("assign") +
            `</button>
                </td>
              </tr>`;
        }
        break;
      // ANCHOR Mapa de detalle de CUPS
      case "cupsDetail":
        tooltip = `<div class="map-tooltip">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        if (sessionProfile == "ARSON") {
          tooltip +=
            `<tr class="map-tooltip-title gateway-gradient">
                        <th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `">
                          <b>` +
            this.translate.instant("gateway") +
            `:</b>
                          <a class="map-link"  href="#/gateways/detalle/gateway/` +
            gateway.id +
            `"> ` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data")) +
            ` <i class="fas fa-link"></i>
                        </a>`;
        } else {
          tooltip +=
            `<tr class="map-tooltip-title gateway-gradient">
                        <th colspan="2">
                          <b>` +
            this.translate.instant("gateway") +
            ": </b>" +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data"));
        }
        // Imagen
        if (gateway.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
          </tr>
          <tr class="map-separator-tr"></tr>`;
        tooltip +=
          `<tr>
                      <td>
                        <b>RSSI:</b> 
                      </td>
                      <td>` +
          (gateway.rssi != null
            ? gateway.rssi + "dBm"
            : this.translate.instant("no-data")) +
          `
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>` +
          this.translate.instant("frames-received") +
          `:</b>
                      </td>
                      </tr>
                      <tr>
                      <td>` +
          this.translate.instant("main-meter-number") +
          `:</b>
                      </td>
                      <td>` +
          (gateway.mainMeters != null
            ? gateway.mainMeters
            : this.translate.instant("no-data")) +
          `
                      </td>
                    </tr>`;
        break;
      // ANCHOR Mapa de detalle de gateway
      case "gatewayDetail":
        tooltip = `<div class="map-tooltip">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        if (sessionProfile == "ARSON" && !gateway.other) {
          tooltip +=
            `<tr class="map-tooltip-title gateway-gradient">
                        <th colspan="2">
                          <b>` +
            this.translate.instant("gateway") +
            `:</b> ` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data")) +
            `       </th>
                      <tr>
                      <tr class="map-separator-tr"></tr>
                      <tr>
                        <td>
                          <b>RSSI:</b> 
                        </td>
                        <td>` +
            (gateway.lastRssi != null
              ? gateway.lastRssi + "dBm"
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>` +
            this.translate.instant("frames-received") +
            `:</b>
                        </td>
                      </tr>`;
        }
        if (gateway.other == true) {
          tooltip +=
            `<tr class="map-tooltip-title gateway-gradient">
                        <th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `">
                          <b>` +
            this.translate.instant("gateway") +
            `:</b> 
                          <a class="map-link"  href="#/gateways/detalle/gateway/` +
            gateway.id +
            `"> ` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data")) +
            ` <i class="fas fa-link"></i>
                          </a>`;
          // Imagen
          if (gateway.haveImage == true) {
            tooltip +=
              `<i class="fas fa-image photoModal pointer" title="` +
              this.translate.instant("show-image") +
              `"></i>`;
          }
          tooltip +=
            `</th>
                      </tr>
                      <tr class="map-separator-tr"></tr>
                      <tr>
                        <td>
                          <button type="button" class="btn btn-default resetGateway">` +
            this.translate.instant("reset-to") +
            " " +
            mainGateway.unidadVenta +
            `</button>
                        </td>
                      </tr>`;
        }
        break;
      // ANCHOR Mapa por defecto
      default:
        tooltip = `<div class="map-tooltip">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        if (sessionProfile == "ARSON") {
          tooltip +=
            `<tr class="map-tooltip-title ` +
            titleClass +
            `">
                        <th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `">
                          <b>` +
            this.translate.instant("gateway") +
            `: </b>
                          <a class="map-link gateway-map-link"  href="#/gateways/detalle/gateway/` +
            gateway.id +
            `"> ` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data")) +
            `
                            <i class="fas fa-link"></i>
                          </a>`;
        } else {
          tooltip +=
            `<tr class="map-tooltip-title ` +
            titleClass +
            `">
                        <th colspan="2">
                          <b>` +
            this.translate.instant("gateway") +
            `:</b> ` +
            (gateway.unidadVenta != null
              ? gateway.unidadVenta
              : this.translate.instant("no-data"));
        }
        // Imagen
        if (gateway.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
        </tr>
        <tr class="map-separator-tr"></tr>`;
        tooltip +=
          `<tr>
                      <td>
                        <b>` +
          this.translate.instant("main-meters") +
          `:</b> 
                      </td>
                      <td>` +
          meterType.mainMeter +
          `</td>
                    </tr>
                    <tr>
                      <td>
                        <b>` +
          this.translate.instant("redundant-meter-number") +
          `:</b> 
                      </td>
                      <td>` +
          meterType.redundantMeter +
          `
                      </td>
                    </tr>`;
        if (mapType == "coverageMap") {
          tooltip +=
            `<tr class="pointer filterGateway">
              <td>
                <button type="button" class="btn btn-default"> 
                  <i class="fas fa-filter"></i>` +
            this.translate.instant("show-gateway-cover") +
            `
                </button>
              </td>
            </tr>`;
        }
        break;
    }
    tooltip += "</table></div>";
    return tooltip;
  }

  // ANCHOR Localización
  getLocationTooltip(
    mapType: string,
    location: GatewayLocation,
    locationsData: GatewayLocationData[],
    currentAgrupation: Agrupation
  ): string {
    // Tooltip
    let tooltip: string;
    let dateFormat = this.SessionDataService.getCurrentDateFormat();
    switch (mapType) {
      // Cobertura
      case "coverage":
        tooltip = `<div class="map-tooltip">
        <span class="map-tooltip-close-background"></span>
        <table>
          <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title gatewaylocation-gradient">
            <th colspan="2">
              <b>` +
          this.translate.instant("location") +
          `</b>`;
        // Botón borrar localización
        tooltip +=
          `    <i class="fas fa-trash removeLocation pointer" title="` +
          this.translate.instant("delete") +
          `"></i>`;
        tooltip += `</th>
          </tr>
          <tr class="map-separator-tr"></tr>`;
        // Latitud y longitud
        tooltip +=
          `<tr>
                <td>
                   <b>` +
          this.translate.instant("latitude") +
          `:</b> 
                </td>
                <td>` +
          location.latitude +
          `</td>
            </tr>
            <tr>
                <td>
                   <b>` +
          this.translate.instant("longitude") +
          `:</b> 
                </td>
                <td>` +
          location.longitude +
          `</td>
            </tr>`;
        break;
      // Mapa por defecto
      default:
        tooltip = `<div class="map-tooltip">
        <span class="map-tooltip-close-background"></span>
        <table>
          <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title gatewaylocation-gradient">
            <th colspan="2">
              <b>` +
          this.translate.instant("location") +
          `</b>`;
        // Botón borrar localización
        tooltip +=
          `    <i class="fas fa-trash removeLocation pointer" title="` +
          this.translate.instant("delete") +
          `"></i>`;
        tooltip += `</th>
          </tr>
          <tr class="map-separator-tr"></tr>`;
        // Latitud y longitud
        tooltip +=
          `<tr>
                <td>
                   <b>` +
          this.translate.instant("latitude") +
          `:</b> 
                </td>
                <td>` +
          location.latitude +
          `</td>
            </tr>
            <tr>
                <td>
                   <b>` +
          this.translate.instant("longitude") +
          `:</b> 
                </td>
                <td>` +
          location.longitude +
          `</td>
            </tr>
            <tr>
            <td>
               <b>` +
          this.translate.instant("gateways") +
          `:</b> 
            </td>`;
        // Gateways en la ubicación
        locationsData.forEach((locationData) => {
          tooltip +=
            `<tr>
                <td>` +
            locationData.unidadVenta +
            `</td>
              <td>` +
            (locationData.timestamp != null
              ? moment_timezone(locationData.timestamp)
                  .tz(currentAgrupation.timezone)
                  .format(dateFormat + " HH:mm")
              : this.translate.instant("no-data")) +
            `</td>
            </tr>`;
        });
        break;
    }
    tooltip += "</table></div>";
    return tooltip;
  }

  // Tooltip para localizaciones de cobertura activas
  getLocationPerteneceTooltip(location: GatewayLocation): string {
    // Tooltip
    let tooltip: string = `<div class="location-tooltip">
    <table>
      <tr></tr>`;
    // RSSI, SNR y distancia
    tooltip +=
      `<tr>
            <td><b>` +
      this.translate.instant("distance") +
      "</b>: </td><td>" +
      (location.distance != null
        ? location.distance + " m"
        : this.translate.instant("no-data")) +
      `     </td>
        </tr>
        <tr><td>
              <b>RSSI: </b>
            </td><td>` +
      (location.rssi != null
        ? location.rssi + " dBm"
        : this.translate.instant("no-data")) +
      `
            </td>
        </tr><tr>
            <td>
              <b>SNR: </b>
            </td><td>` +
      (location.snr != null
        ? location.snr
        : this.translate.instant("no-data")) +
      `
            </td>
          </tr>`;
    tooltip += "</table></div>";
    return tooltip;
  }

  // Label para localizaciones de cobertura activas
  getLocationPerteneceLabel(location: GatewayLocation): string {
    // Tooltip
    let tooltip: string =
      this.translate.instant("distance") +
      ": " +
      (location.distance != null
        ? location.distance + " m"
        : this.translate.instant("no-data")) +
      "\nRSSI: " +
      (location.rssi != null
        ? location.rssi + " dBm"
        : this.translate.instant("no-data")) +
      "\nSNR: " +
      (location.snr != null ? location.snr : this.translate.instant("no-data"));
    return tooltip;
  }

  // Obtención del título de gateway
  getGatewayTitle(gateway: any): string {
    return (
      `<div class="map-title"><div>` +
      (gateway.unidadVenta != null
        ? gateway.unidadVenta
        : this.translate.instant("no-data")) +
      `</div>` +
      `<div class="map-tooltip-mouse-info">
    <i class="fas fa-mouse mouse-left-click"></i>
    <span>` +
      this.translate.instant("more-info") +
      `</span></div></div>`
    );
  }

  // Obtención del título de localización
  getLocationTitle(location: any): string {
    return (
      `<div class="map-title"><div>` +
      this.translate.instant("location") +
      `</div>` +
      `<div class="map-tooltip-mouse-info">
      <i class="fas fa-mouse mouse-left-click"></i>
      <span>` +
      this.translate.instant("more-info") +
      `</span></div></div>`
    );
  }
}
