import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Directives
import { DateRangeInputDirective } from "./date-range-input/date-range-input.directive";
import { ClickOutsideDirective } from "./click-outside-directive/click-outside.directive";
import { HexInputDirective } from "./hex-input-directive/hex-input.directive";
import { MonthDaysInputDirective } from "./month-days-input-directive/month-days-input.directive";
import { LongPressDirective } from "./long-press-directive/long-press.directive";
import { CloseDropdownOnScrollDirective } from "./close-dropdown-on-scroll/close-dropdown-on-scroll.directive";
import { CronInputDirective } from "./cron-input-directive/cron-input.directive";
import { HelpDirective } from "./help-directive/help.directive";
import { TabInputDirective } from "./tab-directive/tab-directive";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    // Directives
    ClickOutsideDirective,
    HexInputDirective,
    MonthDaysInputDirective,
    LongPressDirective,
    CronInputDirective,
    CloseDropdownOnScrollDirective,
    HelpDirective,
    TabInputDirective,
    DateRangeInputDirective,
  ],
  exports: [
    // Directives
    ClickOutsideDirective,
    HexInputDirective,
    MonthDaysInputDirective,
    LongPressDirective,
    CronInputDirective,
    CloseDropdownOnScrollDirective,
    HelpDirective,
    TabInputDirective,
    DateRangeInputDirective,
  ],
})
export class DirectivesModule {}
