/**
 * Decodes the given frmPayload for the Viewshine protocol.
 *
 * @param {any} frmPayload - The frmPayload to be decoded.
 * @param {any} fport - The FPort of the frame.
 *
 * @returns {Object}
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class ZhongYiDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  // Comandos
  readonly CMD_ACKERR = 0x00;
  readonly CMD_ACKOK = 0x01;
  readonly CMD_PAFDS = 0x02;
  readonly CMD_QPAFDS = 0x02;
  readonly CMD_SVALVE = 0x04;
  readonly CMD_PPERID = 0x06;
  readonly CMD_QPERID = 0x06;
  readonly CMD_SPERID = 0x06;
  readonly CMD_PPTIME = 0x07;
  readonly CMD_QPTIME = 0x07;
  readonly CMD_SPTIME = 0x07;
  readonly CMD_PDEVS = 0x09;
  readonly CMD_PFWVER = 0x0b;
  readonly CMD_QFWVER = 0x0b;

  readonly CMD_DESCRIPCIONES = [
    "ACKERR",
    "ACKOK",
    "PAFDS",
    "???",
    "SVALVE",
    "???",
    "PPERID",
    "PPTIME",
    "???",
    "PDEVS",
    "???",
    "PFWVER",
  ];

  readonly VALVE_STATUS = ["Abierta", "Cerrada", "Desconocido!!!!!", "Anormal"];

  zhongyi_valve_decoder(frmPayload, fport) {
    if (frmPayload) {
      if (frmPayload.bytes) {
        var outputData = {};
        var outputErrors = [];
        if (fport != 0x0a) {
          return {
            errors: ["PUERTO NO VÁLIDO: " + fport.toString()],
          };
        } else {
          var comando = frmPayload.bytes[0];
          switch (comando) {
            case this.CMD_PAFDS:
              if (frmPayload.bytes.length != 23) {
                return {
                  errors: [
                    "LONGITUD ERRÓNEA: " +
                      frmPayload.bytes.length.toString() +
                      "bytes (debe ser  23 bytes)",
                  ],
                };
              } else {
                return {
                  data: this.pafdsFrameDecoder(frmPayload.bytes),
                };
              }
              break;
            case this.CMD_PDEVS:
              if (frmPayload.bytes.length != 3) {
                return {
                  errors: [
                    "LONGITUD ERRÓNEA: " +
                      frmPayload.bytes.length.toString() +
                      "bytes (debe ser  3 bytes)",
                  ],
                };
              } else {
                return {
                  data: this.pdevsFrameDecoder(frmPayload.bytes),
                };
              }
              break;
          }
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frmPayload."],
      };
    }
  }

  parseaAlarmas(alarmas) {
    var objetoAlarmas = {};
    if (alarmas != 0) {
      if (alarmas & (1 << 0)) {
        objetoAlarmas["Bateria baja"] = true;
      }
      if (alarmas & (1 << 2)) {
        objetoAlarmas["Fallo de válvula"] = true;
      }
    }
    return objetoAlarmas;
  }

  /* Decoder de PDEVS Frame*/
  pdevsFrameDecoder(bytes) {
    var outputData = {
      Tipo_Trama: "PDEVS - Report device status",
      Valvula: this.VALVE_STATUS[bytes[1] & 0x03],
      FrameID: bytes[2],
    };
    return outputData;
  }

  /* Decoder de PAFDS Frame*/
  pafdsFrameDecoder(bytes) {
    var outputData = { Tipo_Trama: "PAFDS - Report equipment status" };
    var index = 1;
    var deviceNumber = "";
    for (; index < 11; index++) {
      if (bytes[index] != 0) {
        deviceNumber += String.fromCharCode(parseInt(bytes[index], 10));
      }
    }
    index += 4; // Reserved bytes
    var coverStatus = bytes[index++];
    var valveStatus = bytes[index++] & 0x03;
    var alarmas = bytes[index++];
    var batLevel = this.readU16Lsb(bytes, index);
    index += 2;
    var rssi = bytes[index++];
    if (rssi & 0x80) {
      rssi = rssi - 0xff - 1;
    }
    var snr = bytes[index++];
    if (snr & 0x80) {
      snr = snr - 0xff - 1;
    }
    var frameID = bytes[index++];

    outputData["Dispositivo"] = deviceNumber;
    outputData["Cover_Status"] = coverStatus ? "WARNING" : "OK";
    outputData["Valvula"] = this.VALVE_STATUS[valveStatus];
    if (alarmas != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(alarmas);
    }
    outputData["Bateria"] = (batLevel / 100).toFixed(2) + " V";
    outputData["RSSI"] = rssi.toString() + " dBm";
    outputData["SNR"] = snr.toString() + " dB";
    outputData["FrameID"] = frameID;
    return outputData;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  zhongyi_valve_descriptor(frame) {
    if (frame) {
      var frameBytes = this.SepemDecoderService.hexToBytes(frame);
      var fType = (frameBytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            if (fport != 0x0a) {
              return "";
            } else {
              var comando = frameBytes[9 + fOptsLen];
              if (comando > this.CMD_QFWVER) {
                return "???";
              } else {
                return this.CMD_DESCRIPCIONES[comando];
              }
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          var fOptsLen = frameBytes[5] & 0x0f;
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
