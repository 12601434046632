// @angular
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Interfaces
import { ContrastData, ImageOverlay } from "../MapInterface.type";

@Component({
  selector: "app-map-coverage-contrast",
  templateUrl: "./map-coverage-contrast.component.html",
  styleUrls: ["./map-coverage-contrast.component.scss"],
})
export class MapCoverageContrastComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Contraste
  contrastKml: any[] = [];
  contrastImages: any[] = [];
  contrastImageUrl: any;
  imageOverlayArray: ImageOverlay[] = [];
  @Output() updateMapFlag = new EventEmitter<ImageOverlay[]>();
  files: ContrastData[] = [];
  @ViewChild("importInput") importInput: ElementRef;

  // Botón
  importIcon: string = "fas fa-layer-group";
  importTitle: string = this.translate.instant("coverage-contrast-show");
  resetIcon: string = "fas fa-undo-alt";
  resetTitle: string = this.translate.instant("reset");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private translate: TranslateService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga de archivo XML
  fileChanged(e: any): void {
    if (e.target.files) {
      for (let file in e.target.files) {
        if (e.target.files[file]?.name) {
          this.parseDocument(e.target.files[file]);
        }
      }
    }
  }

  // Parseo de archivo XML
  parseDocument(file: any): void {
    let fileReader = new FileReader();

    if (file?.name.includes("kml")) {
      fileReader.onload = (e: any) => {
        let fileId = file.name.replace(".kml", "");
        let fileFound = this.files.find(
          (contrast: ContrastData) => contrast.id == fileId
        );
        if (fileFound) {
          fileFound.coords = this.extractGoogleCoords(e.target.result);
        } else {
          this.files.push({
            id: fileId,
            coords: this.extractGoogleCoords(e.target.result),
          });
        }
      };
      fileReader.readAsText(file);
      this.contrastKml.push(file.name);
    } else if (file?.name.includes("png")) {
      fileReader.onload = (e: any) => {
        let fileId = file.name.replace(".png", "");
        let fileFound = this.files.find(
          (contrast: ContrastData) => contrast.id == fileId
        );
        if (fileFound) {
          fileFound.image = e.target.result;
        } else {
          this.files.push({
            id: fileId,
            image: e.target.result,
          });
        }
      };
      fileReader.readAsDataURL(file);
      this.contrastImages.push(file.name);
    }
  }

  // Extracción de coordenadas
  extractGoogleCoords(plainText: any): {
    north: number;
    south: number;
    east: number;
    west: number;
  } {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let north: number = parseFloat(
      xmlDoc.getElementsByTagName("north")[0].innerHTML
    );
    let south: number = parseFloat(
      xmlDoc.getElementsByTagName("south")[0].innerHTML
    );
    let east: number = parseFloat(
      xmlDoc.getElementsByTagName("east")[0].innerHTML
    );
    let west: number = parseFloat(
      xmlDoc.getElementsByTagName("west")[0].innerHTML
    );
    return { north: north, south: south, east: east, west: west };
  }

  // Contraste
  showContrast(): void {
    this.files.forEach((file: ContrastData) => {
      if (file.coords && file.image) {
        this.imageOverlayArray.push({
          name: this.translate.instant("coverage") + " " + file.id,
          coords: [
            [file.coords.north, file.coords.west],
            [file.coords.south, file.coords.east],
          ],
          image: file.image,
        });
      }
    });

    this.updateMapFlag.emit(this.imageOverlayArray);
  }

  // Reset
  reset(): void {
    this.contrastKml = [];
    this.contrastImages = [];
    this.imageOverlayArray = [];
    this.files = [];
    this.importInput.nativeElement.value = null;
    this.updateMapFlag.emit(this.imageOverlayArray);
  }
}
