<div class="subscriber-nav-container">
  <!-- Logo link -->
  <div
    class="subscriber-logo-link pointer"
    [routerLink]="['/abonado/principal']"
  >
    <a class="subscriber-top-logo">
      <i *ngIf="logoLoading" class="fas fa-spinner fa-pulse"></i>
      <img
        *ngIf="logoImgUrl"
        [hidden]="logoLoading"
        (load)="logoLoading = false"
        [src]="logoImgUrl"
        alt="Logo"
      />
    </a>
  </div>

  <div class="subscriber-right-controls">
    <div class="subscriber-nav-user-container">
      <div class="subscriber-nav-language-container">
        <div class="subscriber-nav-language">
          <!-- Selector de idioma -->
          <ul>
            <li
              *ngFor="let language of languageList; index as i"
              class="pointer"
            >
              <a
                class="subscriber-nav-language-option"
                [ngClass]="{ 'subscriber-nav-inactive-language': i > 0 }"
                (click)="switchLanguage(language.id)"
                title="{{ language.title | translate }}"
              >
                <img src="{{ language.img }}" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="subscriber-nav-user-control">
        <!-- Usuario -->
        <div class="subscriber-nav-user">
          <div class="subscriber-nav-user-row pointer">
            <!-- Notificaciones -->
            <div
              class="subscriber-notification pointer"
              (click)="
                pendingNotifications = 0; notificationModal.showNotifications()
              "
              title="{{ 'notifications' | translate }}"
            >
              <a>
                <i
                  class="fas fa-bell fa-fw"
                  [ngClass]="{
                    'user-notification-active': pendingNotifications > 0
                  }"
                ></i>
              </a>
            </div>
            <span (click)="goToConfig()">
              <div class="subscriber-user-text">{{ sessionUser }}</div>
              <div class="subscriber-nav-icon">
                <i class="fa fa-cog fa-fw subscriber-user-icon"></i></div
            ></span>
          </div>
          <div class="subscriber-nav-logout-row pointer" (click)="logout()">
            <div class="subscriber-logout-text">{{ "logout" | translate }}</div>
            <div class="subscriber-nav-icon">
              <i class="fa fa-sign-out-alt fa-fw subscriber-logout-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
