import { Component, Input } from "@angular/core";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-management-entity-dialog",
  templateUrl: "./management-entity-dialog.component.html",
  styleUrls: ["./management-entity-dialog.component.scss"],
})
export class ManagementEntityDialogComponent {
  @Input() data: any;

  constructor(public SessionDataService: SessionDataService) {}
}
