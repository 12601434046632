// @angular
import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { TableDataColumn } from "../../../../../../modules/table-module/TableInterface.type";
import { GatewayEntityResponse } from "../../../GatewayInterface.type";

@Component({
  selector: "app-gateway-instock-dialog",
  templateUrl: "./gateway-instock-dialog.component.html",
  styleUrls: ["./gateway-instock-dialog.component.scss"],
})
export class GatewayInstockDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  entityList: Entity[];
  selectedEntity: number;
  errorList: GatewayEntityResponse[];
  columns: TableDataColumn[] = [
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "error-text",
      data: "error",
      search: "error",
      sort: "error",
      visible: true,
    },
  ];
  gatewayChannels: object = [
    { value: 7, label: 3 },
    { value: 31, label: 5 },
  ];
  preselecte4dChannel: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.entityList = this.SessionDataService.getCurrentEntityList();
    this.errorList = null;
    this.selectedEntity = this.data?.entity;
    this.preselecte4dChannel = this.data?.gateway?.canales;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Creación de nuevo gateway
  newGateway(): void {
    this.GatewayController.newGateway(this.data.gateway).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("success-add-gateway")
          );
          this.SessionDataService.sendDialogAction({ action: "reload" });
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      }
    );
  }

  // Cambio de entidad
  changeEntity(): void {
    let request =
      this.data?.action == "newEntity"
        ? this.GatewayController.setEntity(
            this.selectedEntity,
            this.data.gateways
          )
        : this.selectedEntity != null
        ? this.GatewayController.changeEntity(
            this.selectedEntity,
            this.data.gateways
          )
        : this.GatewayController.removeEntity(this.data.gateways);

    if (this.data?.action != "newEntity") {
      this.ToastService.fireAlertWithOptions(
        "question",
        this.translate.instant("gateway-change-entity-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.changeEntityRequest(request);
        }
      });
    } else {
      this.changeEntityRequest(request);
    }
  }

  changeEntityRequest(request): void {
    request.subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      } else if (response["code"] == 1) {
        this.ToastService.fireToast(
          "error",
          this.translate.instant("some-element-error")
        );
        this.errorList = response["body"]
          ?.filter(
            (gatewayResponse: GatewayEntityResponse) =>
              gatewayResponse.code != 0
          )
          ?.map(
            (gatewayResponse: GatewayEntityResponse) =>
              (gatewayResponse.error = gatewayResponse.code
                ? this.translate.instant("httpError" + gatewayResponse.code)
                : "-")
          );
      }
    });
  }
}
