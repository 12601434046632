<!-- Gráfica de consumo -->
<div id="meter-graph" class="panel panel-default">
  <div class="panel-heading">
    <div class="panel-nav">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "graph-consumption" | translate }}</b>
      </h5>

      <!-- Ver patrones -->
      <div class="graph-patterns" *ngIf="sessionProfile == 'ARSON'">
        <button
          mat-button
          class="mat-button-icon-text"
          (click)="goToConsumptionProfiles()"
        >
          {{
            meterConsumptionProfile?.profileId
              ? ("profile" | translate) +
                ": " +
                ("consumptionProfile" + meterConsumptionProfile.profileId
                  | translate)
              : ("consumption-profile-without" | translate)
          }}
        </button>
      </div>
    </div>
  </div>

  <div class="panel-body meter-detail-graph">
    <!-- Gráfica -->
    <app-graph-controller
      [highchartsOptions]="highchartsOptions"
      [avoidDuplicates]="[true]"
      [data]="chartOptions"
      [chartConstructor]="chartConstructor"
      [dateRange]="defaultDateRange"
      [pointDeletion]="true"
      (dataRequest)="loadGraphData($event.from, $event.to, $event.alt)"
      [filters]="graphFilters"
      (filterFlag)="graphType = $event.value"
      [seriesToCheck]="[0]"
      [seriesToUpdate]="[0]"
      [graphLegend]="graphLegend"
      (serieToggled)="getSeries(from, to, $event[0].visible)"
    >
    </app-graph-controller>

    <!-- Gráfica circular -->
    <div
      #graphCircle
      *ngIf="circleChartOptions"
      class="leak-graph-circle leak-graph-circle-initial"
      draggable="true"
      (dragstart)="DragElementService.getDrapgPoint(graphCircle, $event)"
      (dragend)="
        DragElementService.setPosition(
          graphCircle,
          $event,
          'leak-graph-circle-initial',
          '--graph-circle-y',
          '--graph-circle-x'
        )
      "
    >
      <app-graph-controller
        [highchartsOptions]="circleHighchartOptions"
        [noOptionsParse]="true"
        [onlyGraph]="true"
        [data]="circleChartOptions"
      >
      </app-graph-controller>
    </div>
  </div>
</div>

<!-- Gráfica de consumo -->
<div
  *ngIf="meter?.metrologyType == METROLOGY_TYPE.SATELITE"
  id="satelite-graph"
  class="panel panel-default"
>
  <div class="panel-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>
    <h5>
      <b>{{ "graph" | translate }} RSSI/{{ "tension" | translate }}</b>
    </h5>
  </div>

  <div class="panel-body meter-detail-graph">
    <!-- Gráfica -->
    <app-graph-controller
      [highchartsOptions]="highchartsOptions"
      [data]="sateliteChartOptions"
      [chartConstructor]="chartConstructor"
      [dateRange]="defaultDateRange"
      (dataRequest)="loadSateliteGraphData($event.from, $event.to)"
    >
    </app-graph-controller>
  </div>
</div>

<!-- Spinner de carga -->
<ng-template #elseBlock>
  <div class="loading-spinner">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</ng-template>
