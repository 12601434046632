<section>
  <div class="container-fluid">
    <div class="panel panel-default subscriber-cups-panel">
      <div class="panel-body subscriber-cups-data-panel">
        <!-- CUPS -->
        <div class="subscriber-cups-left-block">
          <div #cupsContainer class="subscriber-cups-elements-container">
            <div
              *ngIf="_cupsData?.contadores?.length > 0"
              class="subscriber-cups-map-container"
            >
              <app-map-controller
                [mapType]="mapType"
                [mapOnly]="true"
                [mapHeight]="mapHeight"
                [initialZoom]="initialZoom"
                [hideMenu]="true"
                [disableLegend]="true"
                [disableMeasure]="true"
                [disableCluster]="true"
                (actionFlag)="selectMeter($event)"
                [gatewaysData]="[]"
                [metersData]="_cupsData?.contadores"
              >
              </app-map-controller>
            </div>
            <div
              *ngFor="let cups of cupsList; index as i"
              class="subscriber-cups-element"
              [ngClass]="
                cups.id == currentCupsId
                  ? 'subscriber-cups-element-active'
                  : 'subscriber-cups-element-inactive'
              "
            >
              <!-- Mapa de contador -->
              <div class="subscriber-cups-button">
                <button (click)="updateCurrentCups(cups.id, i)">
                  {{ cups.clave }}
                </button>
              </div>
              <div
                *ngIf="_cupsData?.contadores?.length > 0"
                class="subscriber-cups-map"
                [ngClass]="{
                  'subscriber-cups-map-active': cups.id == currentCupsId
                }"
              >
                <div class="subscriber-map-overlay"></div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="_cupsData" class="subscriber-cups-right-block">
          <!-- Contadores -->
          <div class="subscriber-cups-meters">
            <div
              class="subscriber-cups-separator subscriber-cups-meters-separator no-select"
            >
              <div class="subscriber-cups-separator-title">
                <i
                  class="fas fa-tachometer-alt subscriber-cups-separator-icon"
                ></i>
                <div class="subscriber-cups-title subscriber-cups-meters-title">
                  {{ "meters" | translate }}
                </div>
              </div>

              <!-- Notificaciones -->
              <div
                class="subscriber-cups-notification pointer"
                (click)="
                  _cupsData.notificacionesPendientes = 0;
                  notificationModal.showNotifications(
                    cupsList[this.currentCupsIndex].id
                  )
                "
                title="{{ 'notifications' | translate }}"
              >
                <a>
                  <i
                    class="fas fa-bell fa-fw"
                    [ngClass]="{
                      'cups-notification-active':
                        _cupsData?.notificacionesPendientes > 0
                    }"
                  ></i>
                </a>
              </div>

              <div class="subscriber-cups-meters-list">
                <app-material-select
                  [fullWidth]="true"
                  [bindValue]="'id'"
                  [bindLabel]="'nroSerie'"
                  [selected]="initialMeterSelection"
                  [data]="cupsList[currentCupsIndex]?.contadoresList"
                  (selectedOption)="
                    selectedMeterId = $event?.id;
                    getCupsTable(_cupsData);
                    updateGraphFilters($event);
                    loadGraphData(from, to)
                  "
                ></app-material-select>
              </div>
            </div>

            <!-- Tabla de datos -->
            <div class="subscriber-cups-data">
              <div class="subscriber-cups-table-container">
                <div
                  class="subscriber-cups-table"
                  *ngFor="let data of tableData"
                >
                  <div class="subscriber-table-title">
                    {{ data.text | translate }}
                    <span *ngIf="data.subText">{{ data.subText }}</span>
                  </div>
                  <div class="subscriber-table-data">
                    {{
                      data.data != null ? data.data : ("unknown" | translate)
                    }}
                  </div>
                </div>
              </div>

              <!-- Alarmas personalizadas -->
              <!-- <div
                *ngIf="sessionUser == 'borrarAbonado'"
                class="subscriber-custom-alarms"
              >
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="showAlarmsConfig = !showAlarmsConfig"
                >
                  <i class="fas fa-cog"></i>
                  {{ "alarms-custom" | translate }}
                </button>
                <div *ngIf="showAlarmsConfig">
                  <app-subscriber-custom-alarms
                    [meterId]="selectedMeterId"
                  ></app-subscriber-custom-alarms>
                </div>
              </div> -->
            </div>
          </div>

          <div class="subscriber-cups-graph-container">
            <!-- Gráfica -->
            <div class="subscriber-cups-separator no-select">
              <div class="subscriber-cups-separator-title">
                <i class="fas fa-chart-pie subscriber-cups-separator-icon"></i>
                <div class="subscriber-cups-title">
                  {{ "graph-consumption" | translate }}
                </div>
              </div>
            </div>

            <!-- Gráfica -->
            <div class="subscriber-cups-graph">
              <app-graph-controller
                [highchartsOptions]="highchartsOptions"
                [data]="chartOptions"
                [chartConstructor]="chartConstructor"
                [dateRange]="defaultDateRange"
                (dataRequest)="loadGraphData($event.from, $event.to)"
                [filters]="graphFilters"
                (filterFlag)="graphType = $event.value"
              >
              </app-graph-controller>
            </div>
          </div>
        </div>
      </div>

      <div class="subscriber-cups-advice">
        <i class="fas fa-exclamation-triangle"></i>
        {{ "subscriber-data-notice" | translate }}
      </div>
    </div>
  </div>
</section>
