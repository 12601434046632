import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";

@Component({
  selector: "app-panel-back",
  templateUrl: "./panel-back.component.html",
  styleUrls: ["./panel-back.component.scss"],
})
export class PanelBackComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionProfile: string;

  // Body
  body = document.body;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private location: Location,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Redirección a la página anterior
  back(): void {
    // Evita las barras de scroll en la interfaz de abonado
    if (this.sessionProfile == "ABONADO") {
      this.body.style.overflow = "hidden";
      this.location.back();
    } else {
      let currentAgrupation: Agrupation =
        this.SessionDataService.getCurrentAgrupation();
      if (currentAgrupation && currentAgrupation.name != "-") {
        this.location.back();
      } else {
        this.ToastService.fireToast(
          "warning",
          this.translate.instant("must-selected-agrupation")
        );
      }
    }
  }
}
