import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Turf
import * as Turf from "@turf/turf";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { GraphOptionsService } from "../../../modules/graph-module/GraphOptionsService.service";
import { DataAnalysisControllerService } from "../../../services/server/DataAnalysisController.service";
import { SensoryControllerService } from "../../../services/server/SensoryController.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../services/shared/DeviceRouteSelectorService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
// Interfaces
import { GRAPH_CONFIG } from "../../../modules/graph-module/GRAPH_CONFIG";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../interfaces/DeviceGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class SensoryLeakService {
  graphData: any;
  graphSeries: any[];
  devices: any[];
  thresholdData: any[];

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GraphOptionsService: GraphOptionsService,
    private router: Router,
    private SensoryController: SensoryControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string, sensors: MapDevice[]): void {
    this.graphData = [];
    this.getSensorData(from, to, sensors);
  }

  getSensorData(from: string, to: string, sensors: MapDevice[]): void {
    let acousticSensors = sensors.filter(
      (sensor) => sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
    );
    let meterSensors = sensors.filter((sensor) => sensor.hasAs);
    let requests: Observable<Object>[] = [];
    if (acousticSensors.length > 0) {
      requests.push(
        this.SensoryController.getAcousticReadingsByGroup(
          acousticSensors.map((sensor) => sensor.id),
          this.SessionDataService.getCurrentAgrupation().id,
          from,
          to
        )
      );
    }
    if (meterSensors.length > 0) {
      requests.push(
        this.SensoryController.getLeakDetectorDataByGroup(
          meterSensors.map((sensor) => sensor.id),
          this.SessionDataService.getCurrentAgrupation().id,
          from,
          to
        )
      );
    }
    forkJoin(requests).subscribe((responses) => {
      responses.forEach((response) => {
        if (response["code"] == 0) {
          let sensorReadings = response["body"];
          sensorReadings.forEach((sensor) => {
            this.graphData.push({
              id: sensor.id,
              nroSerie: sensor.nroSerie,
              hourlyReadings: this.getSensorHourlyData(
                parseInt(from),
                parseInt(to),
                sensor.readings
              ),
            });
          });
        }
      });
      this.getSeries(sensors);
    });
  }

  getSensorHourlyData(from: number, to: number, readings: any): number[][] {
    let hourReadings = [];
    for (let i = from; i <= to; i += 60 * 60 * 1000) {
      let closeReading = readings.find(
        (reading) =>
          reading.timestamp >= i && reading.timestamp < i + 24 * 60 * 60 * 1000
      );
      if (closeReading) {
        let newReading = JSON.parse(JSON.stringify(closeReading));
        newReading.timestamp = i;
        hourReadings.push(newReading);
      } else {
        hourReadings.push({ timestamp: i });
      }
    }
    return hourReadings;
  }

  // Obtención de datos para umbral de filtrado
  getTresholdData(sensors: MapDevice[]): void {
    let date = this.DateParserService.getLastDays(1);
    let acousticSensors = sensors
      .filter(
        (sensor) => sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
      )
      .map((sensor) => sensor.id);
    let detectors = sensors
      .filter(
        (sensor) => sensor.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR
      )
      .map((sensor) => sensor.id);
    let requests: Observable<object>[] = [];
    if (acousticSensors.length > 0) {
      requests.push(
        this.SensoryController.getLastAcousticReadings(
          this.SessionDataService.getCurrentAgrupation().id,
          { meters: acousticSensors, timestamp: date.startDate.valueOf() }
        )
      );
    }
    if (detectors.length > 0) {
      requests.push(
        this.SensoryController.getLastLeakDetectorData(
          this.SessionDataService.getCurrentAgrupation().id,
          { meters: detectors, timestamp: date.startDate.valueOf() }
        )
      );
    }
    forkJoin(requests).subscribe((responses) => {
      this.thresholdData = [];
      if (responses[0]["code"] == 0) {
        responses[0]["body"].forEach((data) => {
          this.thresholdData.push(
            acousticSensors.length > 0
              ? {
                  contador: data.sensor,
                  value: data.noise,
                  timestamp: data.timestamp,
                }
              : data
          );
        });
      }
      if (responses[1] && responses[1]["code"] == 0) {
        responses[1]["body"].forEach((data) => {
          this.thresholdData.push(data);
        });
      }
      this.SessionDataService.sendComponentData({
        action: "thresholdData",
        data: this.thresholdData,
      });
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(sensors: MapDevice[]): void {
    this.graphSeries = [];
    sensors.forEach((sensor) => {
      let sensorReadings = this.graphData.find(
        (data) => data.id == sensor.id
      )?.hourlyReadings;
      if (sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR) {
        this.graphSeries.push(
          // Ruido
          {
            id: "sensor-noise-" + sensor.nroSerie,
            name: this.translate.instant("noise") + " - " + sensor.nroSerie,
            type: "line",
            fillOpacity: 0.2,
            data: sensorReadings.map((data) => [data.timestamp, data.noise]),
            dataGrouping: { approximation: "sum" },
            tooltip: {
              valueDecimals: 0,
              pointFormat:
                "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
              valueSuffix: "",
            },
            yAxis: 0,
            color: "#ef5350",
            events: {
              click: () => {
                this.ToastService.fireAlertWithOptions(
                  "question",
                  this.translate.instant("question-show-meter") +
                    " " +
                    sensor.nroSerie +
                    "?"
                ).then((userConfirmation: boolean) => {
                  if (userConfirmation) {
                    this.router.navigate([
                      this.DeviceRouteSelectorService.getDeviceRouteUrl(
                        sensor.metrologyType,
                        sensor.id
                      ),
                    ]);
                  }
                });
              },
            },
          },
          // Frecuencia
          {
            id: "sensor-frequency-" + sensor.nroSerie,
            name: this.translate.instant("frequency") + " - " + sensor.nroSerie,
            type: "line",
            data: sensorReadings.map((data) => [
              data.timestamp,
              data.frequency,
            ]),
            dataGrouping: { approximation: "sum" },
            tooltip: {
              valueDecimals: 0,
              pointFormat:
                "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
              valueSuffix: " Hz",
            },
            yAxis: 1,
            color: "#000",
          },
          // Amplitud
          {
            id: "sensor-amplitude-" + sensor.nroSerie,
            name: this.translate.instant("amplitude") + " - " + sensor.nroSerie,
            type: "line",
            data: sensorReadings.map((data) => [
              data.timestamp,
              data.amplitude,
            ]),
            dataGrouping: { approximation: "sum" },
            tooltip: {
              valueDecimals: 0,
              pointFormat:
                "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
              valueSuffix: "",
            },
            yAxis: 2,
            color: "#42a5f5",
          },
          // Temperatura
          {
            id: "sensor-temperature-" + sensor.nroSerie,
            name:
              this.translate.instant("temperature") + " - " + sensor.nroSerie,
            type: "line",
            data: sensorReadings.map((data) => [data.timestamp, data.temp]),
            dataGrouping: { approximation: "sum" },
            tooltip: {
              valueDecimals: 0,
              pointFormat:
                "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
              valueSuffix: "°C",
            },
            yAxis: 3,
            color: "#ff9800",
          }
        );
      } else {
        this.graphSeries.push(
          // Ruido
          {
            id: "sensor-noise-" + sensor.nroSerie,
            name: this.translate.instant("noise") + " - " + sensor.nroSerie,
            type: "line",
            fillOpacity: 0.2,
            data: sensorReadings.map((data) => [data.timestamp, data.value]),
            dataGrouping: { approximation: "sum" },
            tooltip: {
              valueDecimals: 0,
              pointFormat:
                "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
              valueSuffix: "",
            },
            yAxis: 0,
            events: {
              click: (e) => {
                this.ToastService.fireAlertWithOptions(
                  "question",
                  this.translate.instant("question-show-meter") +
                    " " +
                    sensor.nroSerie +
                    "?"
                ).then((userConfirmation: boolean) => {
                  if (userConfirmation) {
                    this.router.navigate([
                      this.DeviceRouteSelectorService.getDeviceRouteUrl(
                        sensor.metrologyType,
                        sensor.id
                      ),
                    ]);
                  }
                });
              },
            },
          }
        );
      }
    });
    this.setChartsOptions(sensors);
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): Options {
    const self = this;
    let defaultHighchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    defaultHighchartsOptions["plotOptions"]["series"]["marker"] = {
      enabled: false,
    };
    defaultHighchartsOptions["plotOptions"]["series"]["point"] = {
      events: {
        mouseOver: (e: any) => {
          self.SessionDataService.sendComponentData({
            action: "heatLayerTimestamp",
            timestamp: e.target.x,
          });
        },
      },
    };
    defaultHighchartsOptions["plotOptions"]["series"]["events"] = {
      mouseOver: (e: any) => {
        self.SessionDataService.sendComponentData({
          action: "highlightSensor",
          serie: e.target,
        });
      },
    };
    return defaultHighchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(sensors: MapDevice[]): void {
    const self = this;
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["chart"]["zoomType"] = "xz";
    if (
      sensors.some(
        (sensor) => sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
      )
    ) {
      chartOptions["legend"]["enabled"] = true;
      chartOptions["yAxis"] = [
        // Ruido
        {
          height: null,
          title: {
            text: this.translate.instant("noise"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#ef5350",
            },
          },
          opposite: false,
          min: 0,
          // max: 3000,
          showLastLabel: true,
          endOnTick: false,
        },
        // Frecuencia
        {
          height: null,
          title: {
            text: this.translate.instant("frequency"),
            style: {
              color: "#000",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value} Hz",
            style: {
              color: "#000",
            },
          },
          opposite: false,
          min: 0,
          max: 1000,
          showLastLabel: true,
          endOnTick: false,
        },
        // Amplitud
        {
          height: null,
          title: {
            text: this.translate.instant("amplitude"),
            style: {
              color: "#4caf50",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#4caf50",
            },
          },
          opposite: true,
          min: 0,
          max: 3000,
          showLastLabel: true,
          endOnTick: false,
        },
        // Temperatura
        {
          height: null,
          title: {
            text: this.translate.instant("temperature"),
            style: {
              color: "#ff9800",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}°C",
            style: {
              color: "#ff9800",
            },
          },
          opposite: true,
          min: 0,
          max: 50,
          showLastLabel: true,
          endOnTick: false,
        },
      ];
    } else {
      chartOptions["legend"]["enabled"] = true;
      chartOptions["yAxis"] = [
        // Ruido
        {
          height: null,
          title: {
            text: this.translate.instant("noise"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#ef5350",
            },
          },
          opposite: false,
          min: 0,
          // max: 3000,
          showLastLabel: true,
          endOnTick: false,
        },
      ];
    }
    chartOptions["series"] = this.graphSeries;
    this.SessionDataService.sendComponentData({
      action: "noiseGraph",
      chartOptions: chartOptions,
      sensorReadings: this.graphData,
    });
  }

  // Visualización de consumo de contadores en rango
  getMeterReadings(
    from: string,
    to: string,
    sensors: MapDevice[],
    devices: MapDevice[],
    sumatory: number
  ): void {
    let meterReadings: any;
    let metersInRange = [];
    sensors.forEach((sensor) => {
      metersInRange = [
        ...metersInRange,
        ...devices.filter(
          (meter) =>
            (Turf.distance(
              Turf.point([
                parseFloat(sensor.longitude),
                parseFloat(sensor.latitude),
              ]),
              Turf.point([
                parseFloat(meter.longitude),
                parseFloat(meter.latitude),
              ]),
              {
                units: "kilometers",
              }
            ) <=
              sensor.extraRange / 1000 ||
              (sensor.longitude == meter.longitude &&
                sensor.latitude == meter.latitude)) &&
            !metersInRange.some((meterInRange) => meterInRange.id == meter.id)
        ),
      ];
    });
    this.DataAnalysisController.getGraphGroup(sumatory, {
      meterList: metersInRange.map((meter: any) => meter.id),
      agrupation: this.SessionDataService.getCurrentAgrupation().id,
      fromTimestamp: parseInt(from),
      toTimestamp: parseInt(to),
      graphType: 2,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        meterReadings = response["body"];
        this.SessionDataService.sendComponentData({
          action: "metersData",
          sumatory: sumatory,
          readings: meterReadings,
          metersInRange: metersInRange.map((meter: any) => meter.id),
        });
      }
    });
  }

  getMeterSeries(
    sensors: MapDevice[],
    meterReadings: any,
    chartOptions: any,
    sumatory: number
  ): void {
    let sensorSeries = chartOptions.series.filter((serie) =>
      serie.id.includes("sensor")
    );
    // let sensorData = sensorSeries.map((sensor) => sensor.data);
    // let maxData = sensorData.reduce((a, b) => (a.lenght > b.lenght ? a : b));
    chartOptions.series = sensorSeries;
    if (sumatory == 2) {
      chartOptions.series.push({
        id: "consumption",
        name: this.translate.instant("consumption"),
        type: "area",
        marker: { enabled: false },
        data: meterReadings?.readings ? meterReadings?.readings : [],
        dataGrouping: { approximation: "sum" },
        tooltip: {
          valueSuffix: " m³",
          valueDecimals: 3,
        },
        color: "#42a5f5",
        navigatorOptions: {
          type: "area",
        },
        yAxis: sensors.some(
          (sensor) => sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
        )
          ? 4
          : 1,
      });
    } else {
      meterReadings.forEach((meter, i) => {
        chartOptions.series.push({
          id: "meter-" + meter.nroSerie,
          name: meter.nroSerie,
          type: "line",
          marker: { enabled: false },
          data: meter.readings,
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueSuffix: " m³",
            valueDecimals: 3,
          },
          navigatorOptions: {
            type: "line",
          },
          yAxis: sensors.some(
            (sensor) => sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
          )
            ? 4
            : 1,
        });
      });
    }

    chartOptions["yAxis"].map((yAxis) => (yAxis.height = "60%"));
    chartOptions["yAxis"][
      sensors.some(
        (sensor) => sensor.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
      )
        ? 4
        : 1
    ] = {
      height: "35%",
      top: "65%",
      offset: 0,
      opposite: false,
      labels: {
        format: "{value} m³",
        style: {
          color: "#42a5f5",
        },
      },
      title: {
        text: this.translate.instant("consumption"),
        style: {
          color: "#42a5f5",
          fontWeight: "bold",
        },
      },
    };

    // let plotLines = maxData.map((data) => data[0]);
    // chartOptions["xAxis"] = {
    //   plotLines: plotLines.map((timestamp) => {
    //     return {
    //       color: "gray",
    //       width: 1,
    //       value: timestamp,
    //       dashStyle: "dot",
    //     };
    //   }),
    // };
    this.SessionDataService.sendComponentData({
      action: "metersGraph",
      chartOptions: chartOptions,
    });
  }
}
