<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <!-- Filtrado por entidad -->
        <div class="alarm-select">
          <app-material-select
            class="material-header-select"
            [title]="'alarms' | translate"
            [fullWidth]="true"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="dateRange != 'full'"
            [data]="alarmSelectOptions"
            (selectedOption)="alarmSelect = $event?.value"
          ></app-material-select>
        </div>

        <!-- Botón nuevo -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="'fas fa-binoculars'"
          [title]="'reviewed-list' | translate"
          (action)="goToPending()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de alarmas -->
        <app-table-controller
          [tableId]="'alarmsList'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [quickFiltersExclusion]="quickFiltersExclusion"
          [quickFilters]="quickFilters"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getAlarms($event.from, $event.to)"
          (selectedDataUpdateFlag)="selectedDataUpdate($event)"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>

        <div id="map-panel" class="map-panel">
          <!-- Mapa de contadores -->
          <app-map-controller
            *ngIf="devices && gateways"
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            [gatewaysData]="gateways"
            [metersData]="devices"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
