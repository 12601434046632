// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { DataAnalysisControllerService } from "../../../../services/server/DataAnalysisController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { Balance } from "../DataAnalysisInterface.type";

@Component({
  selector: "app-hydraulicBalance",
  templateUrl: "./data-analysis-balance.component.html",
  styleUrls: ["./data-analysis-balance.component.scss"],
})
export class HydraulicBalanceComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Stream de datos de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Tabla
  tableData: Balance[];
  exportFileName: string =
    this.translate.instant("balance-export") + " " + moment().format("ll");
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  columns: (TableActionColumn | TableDataColumn)[];

  // Gráficas
  graphBalance: Balance;

  // Nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setColumns();
    this.getData();
  }

  // Obtención de la lista de balances
  getData(): void {
    this.DataAnalysisController.getBalanceList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let tableData: Balance[] = response["body"];
        this.tableData = tableData;
      } else {
        this.tableData = [];
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "graph",
            tooltip: "graph",
            icon: "fas fa-chart-area",
            visible: {
              attribute: null,
              rule:
                this.sessionProfile == "ARSON" ||
                this.sessionProfile == "ADMIN_ENTIDAD" ||
                this.sessionProfile == "ADMIN_AGRUPACION",
            },
            disabled: false,
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: {
              attribute: null,
              rule:
                this.sessionProfile == "ARSON" ||
                this.sessionProfile == "ADMIN_ENTIDAD" ||
                this.sessionProfile == "ADMIN_AGRUPACION",
            },
            disabled: false,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: {
              attribute: null,
              rule:
                this.sessionProfile == "ARSON" ||
                this.sessionProfile == "ADMIN_ENTIDAD" ||
                this.sessionProfile == "ADMIN_AGRUPACION",
            },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "name",
        data: "name",
        search: "name",
        sort: "name",
        visible: true,
      },
      {
        title: "description",
        data: "description",
        search: "description",
        sort: "description",
        visible: true,
      },
      {
        title: "fathers",
        data: "parents",
        search: "parents",
        sort: "parents",
        visible: true,
      },
      {
        title: "childs",
        data: "childs",
        search: "childs",
        sort: "childs",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, balance: Balance): void {
    switch (action) {
      case "show-detail":
        this.router.navigate(["/analisis-datos/grupo/" + balance.id], {
          state: { data: balance },
        });
        break;
      case "edit":
        this.router.navigate(["/analisis-datos/editar/" + balance.id], {
          state: { data: balance },
        });
        break;
      case "delete":
        this.delete(balance);
        break;
      case "graph":
        this.graphBalance = null;
        setTimeout(() => (this.graphBalance = { ...balance }), 0);
        break;
      default:
        break;
    }
  }

  // Nuevo balance
  newBalance(): void {
    this.router.navigate(["/analisis-datos/nuevo"]);
  }

  // Eliminación de balance
  delete(balance: Balance): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("hydraulic-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.DataAnalysisController.delete(balance.id).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("hydraulic-deleted")
                );
                this.getData();
              }
            }
          );
        }
      });
  }
}
