// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { EkConfigurationControllerService } from "../../../../services/server/EkConfigurationController.service";
// Componentes
import { Ek280ConfigurationComponent } from "../../devices/ek-280/ek280-configuration/ek280-configuration.component";
import { Ek280TestComponent } from "../../devices/ek-280/ek280-test/ek280-test.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  DeviceEk280,
  DeviceEk280Sim,
  DeviceSim,
  SIM_STATES,
} from "../../devices/DeviceInterface.type";

@Component({
  selector: "app-control-test",
  templateUrl: "./control-test.component.html",
  styleUrls: ["./control-test.component.scss"],
})
export class ControlTestComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  entityList: Entity[];
  entityListSub: Subscription;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  sessionProfile: string;

  // Tabla
  tableSelectedData: any[];
  tableMaxReg: number = 20;
  tableData: any[];
  columns: (TableActionColumn | TableDataColumn)[];
  extraColumns: EntityDefinition[];
  quickFilters: TableQuickFilter[][];
  quickFiltersExclusion: boolean[] = [];
  highlightRow: object[] = [
    { condition: "newWarning", color: "red", title: "incidents-new" },
  ];

  // Menú de componente
  panelMenuOptions: PanelMenuOption[];

  // Fuente de datos
  selectedDataSource: Entity;

  // Modal de configuración de EK280
  ekConfigData: DeviceEk280;
  simData: DeviceEk280Sim;
  simList: DeviceSim[];
  simSelected: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private EkConfigurationController: EkConfigurationControllerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityList = this.SessionDataService.getCurrentEntityList();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    this.entityListSub = this.SessionDataService.getEntityList().subscribe(
      (entityList) => (this.entityList = entityList)
    );

    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
          this.getCupsConf();
          this.getData();
        }
      );

    // Carga del componente
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.entityListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Configuración de CUPS
    this.getCupsConf();

    // Entidad en curso
    this.selectedDataSource = this.entityList[0];

    // Obtención de los datos
    this.getData();
  }

  // Obtención de la configuración de CUPS
  getCupsConf(): void {
    if (this.entityCupsConf) {
      this.extraColumns = [...this.entityCupsConf];
      this.cups = this.extraColumns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      let cupsIndex: number = this.extraColumns.findIndex(
        (column: EntityDefinition) => column.colPosition == 0
      );
      if (cupsIndex >= 0) {
        this.extraColumns.splice(cupsIndex, 1);
      }
    }
    this.setColumns();
  }

  // Obtención de los datos
  getData(): void {
    // let meterList: SubstitutionDevice[] = [];
    // this.getDataSourceUrl().subscribe((response) => {
    //   if (response["code"] == 0) {
    //     meterList = response["body"];
    //     meterList.forEach((meter: SubstitutionDevice) => {
    //       meter.newDeviceLink =
    //         meter.idNew != null && this.sessionProfile != "ADMIN_INSTALLATION"
    //           ? this.DeviceRouteSelectorService.getDeviceRouteUrl(
    //               meter.metrologyTypeNew,
    //               meter.idNew
    //             )
    //           : null;
    //       meter.oldDeviceLink =
    //         meter.idOld != null && this.sessionProfile != "ADMIN_INSTALLATION"
    //           ? this.DeviceRouteSelectorService.getDeviceRouteUrl(
    //               meter.metrologyTypeOld,
    //               meter.idOld
    //             )
    //           : null;
    //       if (meter.cm) {
    //         for (let attribute in meter.cm) {
    //           meter[attribute] = meter.cm[attribute];
    //         }
    //       }
    //       meter.cups = meter.isAssigned ? meter.clave : null;
    //       meter.imageDisabled = !meter.newHasImage && !meter.oldHasImage;
    //       meter.audioDisabled = !meter.hasAudio;
    //       meter.pending = false;
    //       meter.editDisabled = meter.resubstitutedBy != null;
    //     });
    //     this.setColumns();
    //     this.meterList = this.sortMeters(meterList);
    //     this.getMapData();
    //     this.getSeries();
    //   }
    // });
    this.tableData = [];
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "ek-config",
            tooltip: "configuration",
            icon: "fas fa-gears",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "ek-test",
            tooltip: "test-comm",
            icon: "fas fa-broadcast-tower",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: "groups",
        data: "agrupation",
        search: "agrupation",
        sort: "agrupation",
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "newDeviceLink",
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupationId",
          check: "id",
        },
      },
      {
        title: "rf-module",
        data: "rfModuleNew",
        search: "rfModuleNew",
        sort: "rfModuleNew",
        visible: true,
      },
      {
        title: this.cups?.name,
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: this.cups != null ? true : null,
      },
    ];

    this.extraColumns?.forEach((extraColumn: EntityDefinition) => {
      if (extraColumn.show) {
        let attributePosition: string =
          extraColumn.colPosition <= 9
            ? "col0" + extraColumn.colPosition
            : "col" + extraColumn.colPosition;
        if (extraColumn.colPosition != 100) {
          let newColumn: TableDataColumn = {
            title: extraColumn.name,
            data: attributePosition,
            search: attributePosition,
            sort: attributePosition,
            long: true,
            visible: true,
          };
          columns.push(newColumn);
        }
      }
    });
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: any): void {
    switch (action) {
      case "ek-config":
        this.MaterialDialogService.openDialog(Ek280ConfigurationComponent, {
          sessionProfile: this.sessionProfile,
          editModalData: JSON.parse(JSON.stringify(this.ekConfigData)),
          simList: this.simList,
          meterId: meter.id,
        });
        break;
      case "ek-test":
        this.MaterialDialogService.openDialog(Ek280TestComponent, meter);
        break;
      default:
        break;
    }
  }

  // Nuevo EK280
  newEk(): void {
    this.router.navigate(["/dispositivos/nuevo"], {
      state: { data: { type: "owasys" } },
    });
  }

  // Obtención de los datos
  getEkData(meter): void {
    let dataRequests: Observable<object>[] =
      this.sessionProfile == "ARSON"
        ? [
            this.EkConfigurationController.getEkConfigData(meter.id),
            this.EkConfigurationController.getSimList(),
          ]
        : [this.EkConfigurationController.getEkConfigData(meter.id)];

    forkJoin(dataRequests).subscribe((responses) => {
      // Respuesta del servicio de datos de EK
      if (responses[0]["code"] == 0) {
        let ekConfigData: DeviceEk280 = responses[0]["body"]?.ek280;
        let simData: DeviceEk280Sim = responses[0]["body"]?.sim;
        if (simData) {
          simData.stateParsed = simData.estado
            ? this.translate.instant(SIM_STATES[simData.estado])
            : this.translate.instant("unknown");
        }
        ekConfigData.iccid = simData?.iccid;
        ekConfigData.simId = simData?.id;

        let windows: { source: string; parsed: string }[] = [
          { source: "ventana1", parsed: "ventana1Parsed" },
          { source: "ventana2", parsed: "ventana2Parsed" },
          { source: "ventana3", parsed: "ventana3Parsed" },
        ];
        windows.forEach((window: { source: string; parsed: string }) => {
          if (ekConfigData[window.source]) {
            let hour: number = Math.floor(ekConfigData[window.source] / 100);
            let hourParsed: string =
              hour < 10 ? "0" + hour.toString() : hour.toString();
            let minutes: number = ekConfigData.ventana1 % 100;
            let minutesParsed: string =
              minutes < 10 ? "0" + minutes.toString() : minutes.toString();
            ekConfigData[window.parsed] = hourParsed + ":" + minutesParsed;
          }
        });

        this.simData = simData;
        this.ekConfigData = ekConfigData;
      }

      // Respuesta de servicio de listado de SIM
      if (responses[1] && responses[1]["code"] == 0) {
        this.simList = responses[1]["body"] ? responses[1]["body"] : [];
        this.simList.unshift({
          id: parseInt(this.ekConfigData?.simId),
          iccid: this.ekConfigData?.iccid,
        });
        this.simSelected = this.simList[0].id;
      }
    });
  }
}
