import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options, Point, PointClickEventObject } from "highcharts";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { SuspicionsService } from "../../../../../services/shared/SuspicionsService.service";
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { SensoryControllerService } from "../../../../../services/server/SensoryController.service";
import { DragElementService } from "../../../../../services/shared/DragElementService.service";
// Interfaces
import {
  DetailDevice,
  DetailDeviceAlarm,
  DetailDeviceAlarmSerie,
  DetailDeviceAlarmSerieData,
  DetailDeviceDataSerie,
  DetailDeviceGraphData,
  DetailDeviceSateliteGraphData,
} from "../../DeviceInterface.type";
import { MaterialSelectConfig } from "../../../../../modules/material-module/MaterialInterface.type";
import { ConsumptionProfile } from "../../../data-analysis/data-analysis-models/data-analysis-models-edit/data-analysis-patterns";
// Componentes
import { GraphControllerComponent } from "../../../../../modules/graph-module/graph-controller/graph-controller.component";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
import { LOCAL_TIMEZONE } from "../../../../../global/LOCAL_TIMEZONE";

@Component({
  selector: "app-device-consumption-graph",
  templateUrl: "./device-consumption-graph.component.html",
  styleUrls: ["./device-consumption-graph.component.scss"],
})
export class DeviceConsumptionGraphComponent implements OnInit {
  sessionProfile: string;
  suspicionActive: boolean;
  @Input() meter: DetailDevice;
  @Input() defaultDateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
  @Output() getValveCardsFlag = new EventEmitter<number[][]>();
  readonly METROLOGY_TYPE = METROLOGY_TYPE;
  meterConsumptionProfile: ConsumptionProfile;

  // Gráfica
  graphSeries: (DetailDeviceAlarmSerie | DetailDeviceDataSerie)[];
  graphData: DetailDeviceGraphData;
  graphType: number;
  graphLegend: string;
  graphFilters: MaterialSelectConfig[] = [
    {
      title: this.translate.instant("type"),
      options: [],
      selected: 2,
    },
  ];
  graphAlarms = {
    id: "dataseriesErrors",
    name: this.translate.instant("alarms"),
    type: "flags",
    onSeries: "dataseries",
    shape: "flag",
    color: "black",
    width: 16,
    style: {
      color: "white",
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        cursor: "pointer",
      },
    },
    states: {
      hover: {
        fillColor: "#1976d2",
      },
    },
    yAxis: 1,
    data: [],
  };
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  graphInitiated: boolean = false;
  valveGraphData: number[][];
  sateliteChartOptions: object;
  sateliteGraphData: DetailDeviceSateliteGraphData[];
  sateliteGraphSeries: DetailDeviceDataSerie[];
  @ViewChild(GraphControllerComponent)
  graphController: GraphControllerComponent;
  from: string;
  to: string;

  // Satelite
  sateliteFlow: number[][];

  // Acciones de punto
  allowPointActions = false;
  pointActionArray: number[];

  // Fugas
  circleHighchartOptions: Options;
  circleChartOptions: any;
  @ViewChild("graphCircle") graphCircle: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    public DragElementService: DragElementService,
    private GraphOptionsService: GraphOptionsService,
    private MeterController: MeterControllerService,
    private router: Router,
    private SensoryController: SensoryControllerService,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.suspicionActive = this.SessionDataService.getCurrentSuspicionActive();
    this.graphType = this.meter.metrologyType == METROLOGY_TYPE.GAS ? 3 : 2;
    this.graphFilters[0].selected =
      this.meter.metrologyType == METROLOGY_TYPE.GAS ? 3 : 2;
    if (this.meter.metrologyType != METROLOGY_TYPE.GAS) {
      this.graphFilters[0].options.splice(2);
    }
    this.loadGraph();
    if (this.sessionProfile == "ARSON") {
      this.getConsumptionProfiles();
    }
  }

  /***************************************************************************/
  // ANCHOR Gráfica
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.setHighchartsOptions();
    this.setGraphFilters();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string, alt?: boolean): void {
    this.pointActionArray = [];
    this.allowPointActions = alt;
    this.from = from;
    this.to = to;
    let meterRequest = this.MeterController.getGraph(
      this.meter.id,
      this.graphType.toString(),
      from,
      to
    );
    let valveStateRequest = this.MeterController.getValveStateGraph(
      this.meter.id,
      from,
      to
    );
    let acousticSensorRequest = this.SensoryController.getAcousticReadings(
      this.meter.id,
      this.from,
      this.to
    );
    let requests =
      this.meter.metrologyType == METROLOGY_TYPE.SATELITE
        ? [meterRequest, valveStateRequest]
        : this.meter.hasAs
        ? [meterRequest, acousticSensorRequest]
        : [meterRequest];

    forkJoin(requests).subscribe((responses) => {
      if (responses[0]["code"] == 0 && responses[0]["body"]) {
        let graphData: DetailDeviceGraphData = {
          alarms: responses[0]["body"]["alarms"],
          readings: responses[0]["body"]["readings"],
        };
        this.graphData = graphData;
        // Datos iniciales para cálculo de caudal de satélite
        if (!this.graphInitiated) {
          this.sateliteFlow = JSON.parse(
            JSON.stringify(responses[0]["body"]["readings"])
          );
        }
      } else {
        this.graphData = {
          alarms: [],
          readings: [],
        };
      }

      if (responses[1] && responses[1]["code"] == 0) {
        if (this.meter.hasAs) {
          this.graphData.sensorReadings = responses[1]["body"];
        } else {
          let valveStates = responses[1]["body"]?.map(
            (valveState: {
              id: string;
              timestamp: number;
              meter: number;
              status: number;
            }) => {
              return {
                x: valveState.timestamp,
                y: 0.99,
                tooltipText:
                  valveState.status == 1
                    ? this.translate.instant("opened")
                    : this.translate.instant("closed"),
                marker: { enabled: true },
                color: valveState.status == 1 ? "#008000" : "#FF0000",
              };
            }
          );
          // Si no existe estado de vávula inicial para satélite, se toma el valor de la tarjeta
          if (valveStates?.length == 0) {
            valveStates = [
              {
                x: this.graphData.readings[0]
                  ? this.graphData.readings[0][0]
                  : [],
                y: 0.99,
                tooltipText:
                  this.meter.valveState == 1
                    ? this.translate.instant("opened")
                    : this.translate.instant("closed"),
                marker: { enabled: true },
                color: this.meter.valveState == 1 ? "#008000" : "#FF0000",
              },
            ];
          }
          this.graphData.valveStates = valveStates;
        }
      }

      // Tarjetas de válvula satélite
      if (
        !this.graphInitiated &&
        this.meter.metrologyType == METROLOGY_TYPE.SATELITE
      ) {
        this.valveGraphData = this.graphData.readings;
        this.getValveCardsFlag.emit(this.valveGraphData);
      }

      this.graphInitiated = true;
      this.getSeries(from, to, true);
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(from?: any, to?: any, showSensorData?: boolean): void {
    from = parseInt(from);
    to = parseInt(to);
    const self = this;
    let alarmsSeries: DetailDeviceAlarmSerieData[] = [];
    let graphSeries: (DetailDeviceAlarmSerie | DetailDeviceDataSerie)[] = [];

    // Si las lecturas tienen un timestamp final mayor que los estados de válvula, se añade un punto final a estos para que coincidan
    if (
      this.meter.metrologyType == METROLOGY_TYPE.SATELITE &&
      this.graphData.readings.length > 0 &&
      this.graphData.valveStates.length > 0 &&
      this.graphData.readings[this.graphData.readings.length - 1][0] >
        this.graphData.valveStates[this.graphData.valveStates.length - 1].x
    ) {
      let lastPoint = JSON.parse(
        JSON.stringify(
          this.graphData.valveStates[this.graphData.valveStates.length - 1]
        )
      );
      lastPoint.x =
        this.graphData.readings[this.graphData.readings.length - 1][0];
      lastPoint.marker.enabled = false;
      this.graphData.valveStates.push(lastPoint);
    }

    // Comprobación de alarmas en rango de lecturas
    if (this.graphData.readings.length == 0) {
      this.graphData.readings = [
        [from, null],
        [to, null],
      ];
    }
    this.checkAlarms();

    // Alarmas
    this.graphData.alarms.forEach((alarm: DetailDeviceAlarm) => {
      alarm.code != null
        ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
        : "";
      alarm.code != null
        ? (alarm.description = this.translate.instant(
            "AlertDescription" + alarm.code
          ))
        : "";
      if (
        alarm.description == "" ||
        alarm.description == "AlertDescription" + alarm.code
      ) {
        alarm.description = this.translate.instant("no-details");
      }

      let isSuspicion = this.SuspicionsService.isSuspicion(alarm);
      if (
        !isSuspicion ||
        (this.sessionProfile == "ARSON" && this.suspicionActive)
      ) {
        alarmsSeries.push({
          events: {
            click: function () {
              self.ToastService.fireToast("info", alarm.description);
            },
            mouseOver: function () {
              if (alarm.initDate && alarm.finalDate) {
                self.showAlarm(
                  alarm.initDate,
                  alarm.finalDate > parseInt(self.to)
                    ? parseInt(self.to)
                    : alarm.finalDate
                );
              }
            },
            mouseOut: function () {
              if (alarm.initDate && alarm.finalDate) {
                self.hideAlarm();
              }
            },
          },
          x: this.DateParserService.parseDateWithoutFormat(alarm.initDate),
          y: 0,
          zIndex: 99,
          title: "!",
          text: alarm.finalDate
            ? "<b>" +
              alarm.name +
              "</b>. " +
              this.translate.instant("start-date") +
              ": (" +
              this.DateParserService.getMomentDate(alarm.initDate, "L") +
              ") - " +
              this.translate.instant("fixed") +
              ": (" +
              this.DateParserService.getMomentDate(alarm.finalDate, "L") +
              ")"
            : "<b>" + alarm.name + ".</b> ",
          fillColor: this.SuspicionsService.isSuspicion(alarm)
            ? "#ffae00"
            : alarm.finalDate
            ? "#43a047"
            : "#d32f2f",
        });
      }
    });

    this.graphAlarms.data = alarmsSeries;
    graphSeries[1] = this.graphAlarms;

    // Lecturas
    graphSeries[0] = {
      id: "valor",
      name:
        this.graphType == 1
          ? this.translate.instant("value")
          : this.graphType == 2
          ? this.translate.instant("consumption")
          : this.graphType == 3
          ? this.translate.instant("consumption-normalized")
          : this.translate.instant("energy"),
      type: this.graphType == 1 ? "line" : "area",
      data: this.graphData.readings,
      dataGrouping: { approximation: "sum" },
      tooltip: {
        valueSuffix:
          this.graphType == 1 || this.graphType == 2
            ? " m³"
            : this.graphType == 3
            ? this.meter.idFabricante == 8 && this.meter.idDevType == 4
              ? " Kg"
              : " Nm³"
            : " kWh",
        valueDecimals: 3,
      },
      color: "#42a5f5",
      zIndex: -1,
      navigatorOptions: {
        type: this.graphType == 1 ? "line" : "area",
      },
    };

    // Acciones de punto
    if (
      this.allowPointActions &&
      this.SessionDataService.getCurrentProfile() == "ARSON"
    ) {
      graphSeries[0]["events"] = {
        click: function (e: PointClickEventObject) {
          self.pointAction(e.point);
        },
      };
    }

    // Estado de válvula
    if (this.meter.metrologyType == METROLOGY_TYPE.SATELITE) {
      graphSeries[2] = {
        id: "valveState",
        name: this.translate.instant("valve-state"),
        type: "line",
        data: this.graphData.valveStates,
        step: true,
        yAxis: 2,
        zIndex: 9,
        dataGrouping: { enabled: false },
        showInNavigator: false,
        tooltip: {
          pointFormatter: function () {
            return (
              self.translate.instant("valve-state") +
              `:<b> ` +
              this.series.options.data[this.index].tooltipText +
              `</b>`
            );
          },
        },
        marker: {
          lineWidth: 1,
          lineColor: "white",
          radius: 5,
          symbol: "diamond",
        },
        lineWidth: 5,
        zoneAxis: "x",
        zones: this.getValveZones(),
      };
    }

    if (this.graphData.sensorReadings?.length > 0 && showSensorData) {
      let hourReadings = [];
      if (
        this.graphData.sensorReadings?.length != this.graphData.readings.length
      ) {
        for (
          let i = this.graphData.readings[0][0];
          i <= this.graphData.readings[this.graphData.readings.length - 1][0];
          i += 60 * 60 * 1000
        ) {
          let closeReading = this.graphData.sensorReadings.find(
            (reading) =>
              reading.timestamp >= i &&
              reading.timestamp < i + 24 * 60 * 60 * 1000
          );
          if (closeReading) {
            hourReadings.push([i, closeReading.value]);
          } else {
            hourReadings.push([i, null]);
          }
        }
      } else {
        hourReadings = this.graphData.sensorReadings.map((reading) => {
          return [reading.timestamp, reading.value];
        });
      }
      graphSeries.push(
        // Ruido
        {
          id: "noise",
          name: this.translate.instant("noise"),
          type: "area",
          fillOpacity: 0.2,
          data: hourReadings,
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            valueSuffix: "",
          },
          yAxis: 2,
          xAxis: 0,
          color: "#ef5350",
          showInNavigator: false,
        }
      );
    }

    // Fugas
    if (this.graphData.readings.every((reading) => reading[1])) {
      let minReading = this.graphData.readings
        .filter((reading) => reading[1] > 0)
        .map((reading) => reading[1])
        .reduce((a, b) => Math.min(a, b));
      let totalLeak = minReading * this.graphData.readings.length;
      let totalConsumption = this.graphData.readings
        .map((reading) => reading[1])
        .reduce((a, b) => a + b);
      graphSeries.push({
        id: "leaks",
        name: this.translate.instant("possible-leak"),
        type: "area",
        fillOpacity: 0.4,
        data: this.graphData.readings.map((reading) => {
          return [reading[0], minReading];
        }),
        dataGrouping: { approximation: "sum" },
        tooltip: {
          valueSuffix:
            this.graphType == 1 || this.graphType == 2
              ? " m³"
              : this.graphType == 3
              ? this.meter.idFabricante == 8 && this.meter.idDevType == 4
                ? " Kg"
                : " Nm³"
              : " kWh",
        },
        color: "#ffbb45",
        zIndex: -1,
        showInNavigator: false,
      });
      this.setCircleHighchartsOptions();
      this.setCircleChartsOptions(totalLeak, totalConsumption);
    }

    this.graphSeries = graphSeries;
    this.setChartsOptions();
  }

  // Acción de click en punto de gráfica
  pointAction(point: Point): void {
    this.pointActionArray.push(point.x);
    if (this.pointActionArray.length == 2) {
      this.ToastService.fireAlertWithOptions(
        "question",
        this.pointActionArray[0] == this.pointActionArray[1]
          ? this.translate.instant("consumption-update-question") +
              this.DateParserService.parseDate(
                this.pointActionArray[0],
                "YYYY/MM/DD HH:mm:ss",
                LOCAL_TIMEZONE
              ) +
              "?"
          : this.translate.instant("consumption-update-question") +
              this.DateParserService.parseDate(
                this.pointActionArray[0],
                "YYYY/MM/DD HH:mm:ss",
                LOCAL_TIMEZONE
              ) +
              " - " +
              this.DateParserService.parseDate(
                this.pointActionArray[1],
                "YYYY/MM/DD HH:mm:ss",
                LOCAL_TIMEZONE
              ) +
              "?"
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MeterController.updateReadings(
            this.meter.id,
            this.pointActionArray[0],
            this.pointActionArray[1]
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.translate.instant("action-success");
              this.loadGraphData(this.from, this.to);
            }
          });
        }
        this.pointActionArray = [];
      });
    }
  }

  // Comprobación si las alarmas están en el rango de las lecturas para evitar error en gráfica
  checkAlarms(): void {
    let alarmsInRange: DetailDeviceAlarm[] = [];
    this.graphData.alarms.forEach((alarm: DetailDeviceAlarm) => {
      if (
        alarm.initDate >= this.graphData.readings[0][0] &&
        alarm.initDate <=
          this.graphData.readings[this.graphData.readings.length - 1][0]
      ) {
        alarmsInRange.push(alarm);
      }
    });
    this.graphData.alarms = alarmsInRange;
  }

  // Visualización de periodo de alarma activa
  showAlarm(start: number, end: number): void {
    this.graphController.addTimestampsLine(
      start,
      end,
      "alarm",
      this.translate.instant("alarm-duration")
    );
  }

  // Visualización de periodo de alarma activa
  hideAlarm(): void {
    this.graphController.removeTimestampsLine("alarm");
  }

  // Obtención de las zonas de apertura y cierre de la válvula
  getValveZones(): { value: number; color: string }[] {
    let zones: { value: number; color: string }[] = [];
    for (let i = 0; i < this.graphData.valveStates?.length - 1; i++) {
      zones.push({
        value: this.graphData.valveStates[i].x,
        color: this.graphData.valveStates[i].color,
      });
      if (i < this.graphData.valveStates?.length - 1) {
        zones.push({
          value: this.graphData.valveStates[i + 1].x - 1,
          color: this.graphData.valveStates[i].color,
        });
      }
    }
    return zones;
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    const self = this;
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("meters-export")
      );
    highchartsOptions["scrollbar"] = {
      barBackgroundColor: "rgba(0,0,0,0.25)",
      buttonBackgroundColor: "rgba(255,255,255,1)",
      buttonBorderRadius: 5,
      trackBorderRadius: 5,
    };
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    // highchartsOptions["plotOptions"]["column"]["pointWidth"] = 100;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    const self = this;
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] = this.meter.hasAs;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == 1 || this.graphType == 2
        ? " m³"
        : this.graphType == 3
        ? " Nm³"
        : " kWh");
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == 1
        ? this.translate.instant("value")
        : this.graphType == 2
        ? this.translate.instant("consumption")
        : this.graphType == 3
        ? this.translate.instant("consumption-normalized")
        : this.translate.instant("energy");
    // Duración de alarmas
    chartOptions["yAxis"][1] = {
      min: 0,
      max: 1,
      visible: false,
    };
    // Estado de válvula
    if (this.meter.metrologyType == METROLOGY_TYPE.SATELITE) {
      chartOptions["yAxis"][2] = {
        visible: false,
        min: 0,
        max: 1.1,
        endOnTick: false,
      };
    }
    if (this.meter.hasAs) {
      chartOptions["xAxis"] = [
        {
          visible: true,
        },
        {
          visible: false,
        },
      ];
      chartOptions["yAxis"][2] =
        // Ruido
        {
          title: {
            text: this.translate.instant("noise"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#ef5350",
            },
          },
          opposite: true,
          min: 0,
          max: 500,
          showLastLabel: true,
          endOnTick: false,
        };
    }
    chartOptions["series"] = this.graphSeries;
    chartOptions["series"][1].tooltip = {
      headerFormat: "",
    };
    this.chartOptions = chartOptions;
  }

  // Seteo de los filtros dependiendo del tipo de balance
  setGraphFilters(): void {
    if (this.meter.metrologyType == METROLOGY_TYPE.GAS) {
      this.graphFilters[0].options = [
        { value: 1, name: this.translate.instant("index") },
        { value: 2, name: this.translate.instant("hour-consumption") },
        { value: 3, name: this.translate.instant("consumption-normalized") },
        { value: 4, name: this.translate.instant("energy") },
      ];
      this.graphFilters[0].selected = 3;
    } else {
      this.graphFilters[0].options = [
        { value: 1, name: this.translate.instant("index") },
        { value: 2, name: this.translate.instant("hour-consumption") },
      ];
    }
  }

  // Obtención de los datos del gráfico de satélite
  loadSateliteGraphData(from: string, to: string): void {
    this.MeterController.getSateliteGraph(this.meter.id, from, to).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"]) {
          this.sateliteGraphData = response["body"].sateliteData;
        } else {
          this.sateliteGraphData = [];
        }
        this.getSateliteSeries();
      }
    );
  }

  // Obtención de las series de datos para la gráfica
  getSateliteSeries(): void {
    let sateliteSeries: DetailDeviceDataSerie[] = [];

    // RSSI
    sateliteSeries[0] = {
      id: "rssi",
      name: "RSSI",
      type: "line",
      data: this.sateliteGraphData?.map(
        (data: DetailDeviceSateliteGraphData) => {
          return [data.tm, data.rssi];
        }
      ),
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
      },
      navigatorOptions: {
        type: "line",
      },
      color: "#42a5f5",
      dataGrouping: { enabled: false },
    };

    // Tensión
    sateliteSeries[1] = {
      id: "tension",
      name: this.translate.instant("tension"),
      type: "line",
      data: this.sateliteGraphData?.map(
        (data: DetailDeviceSateliteGraphData) => {
          return [data.tm, data.vcc];
        }
      ),
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        valueSuffix: "v ",
        valueDecimals: 3,
      },
      navigatorOptions: {
        type: "line",
      },
      yAxis: 1,
      color: "#ef5350",
      dataGrouping: { enabled: false },
    };

    this.sateliteGraphSeries = sateliteSeries;
    this.setSateliteChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setSateliteChartsOptions(): void {
    let sateliteChartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete sateliteChartOptions["chart"]["navigatorOptions"];
    sateliteChartOptions["legend"]["enabled"] = false;
    sateliteChartOptions["chart"]["height"] = "35%";
    sateliteChartOptions["rangeSelector"].allButtonsEnabled = false;
    sateliteChartOptions["yAxis"] = [
      // RSSI
      {
        title: {
          text: "RSSI",
          style: {
            color: "#42a5f5",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            color: "#42a5f5",
          },
        },
        visible: true,
        opposite: false,
      },
      // Tensión
      {
        title: {
          text: this.translate.instant("tension"),
          style: {
            color: "#ef5350",
            fontWeight: "bold",
          },
        },
        labels: {
          format: "{value} v",
          style: {
            color: "#ef5350",
          },
        },
        visible: true,
        opposite: true,
      },
    ];
    sateliteChartOptions["series"] = this.sateliteGraphSeries;
    this.sateliteChartOptions = sateliteChartOptions;
  }

  getConsumptionProfiles(): void {
    this.DataAnalysisController.getConsumptionProfiles().subscribe(
      (response) => {
        this.meterConsumptionProfile = response["body"]?.find(
          (consumptionProfile: ConsumptionProfile) =>
            consumptionProfile.meterId == this.meter.id
        );
        if (!this.meterConsumptionProfile) {
          this.meterConsumptionProfile = {
            profileId: null,
            meterId: this.meter.id,
          };
        }
      }
    );
  }

  goToConsumptionProfiles(): void {
    this.router.navigate(["/analisis-datos/modelos-analiticos/editar"], {
      state: { data: [this.meterConsumptionProfile.meterId] },
    });
  }

  // Asignación de las opciones concretas para la gráfica
  setCircleHighchartsOptions(): void {
    let cirlceHighchartsOptions: Options = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    cirlceHighchartsOptions.scrollbar = { enabled: false };
    cirlceHighchartsOptions.plotOptions.series.dataGrouping = {
      forced: true,
      units: [["hour", [1]]],
    };
    cirlceHighchartsOptions.plotOptions.pie = {
      size: 125,
      innerSize: null,
      dataLabels: {
        enabled: true,
        crop: false,
        distance: "40",
        formatter: function () {
          return (
            this.point.name +
            "<br>" +
            (this.point.percentage % 1
              ? this.point.percentage.toFixed(2)
              : this.point.percentage) +
            "%<br>" +
            this.point.value
          );
        },
        style: {
          fontWeight: "normal",
          fontSize: "1.2rem",
        },
        connectorWidth: 1,
      },
    };
    cirlceHighchartsOptions.plotOptions.series.marker.enabled = false;
    cirlceHighchartsOptions.tooltip = { enabled: false };
    cirlceHighchartsOptions.exporting = { enabled: false };
    this.circleHighchartOptions = cirlceHighchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setCircleChartsOptions(totalLeak: number, totalConsumption: number): void {
    const self = this;
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();
    let circleChartOptions: object = {};
    circleChartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete circleChartOptions["yAxis"];
    delete circleChartOptions["rangeSelector"];
    circleChartOptions["navigator"] = false;
    circleChartOptions["chart"] = {
      width: 200,
      height: 100,
      type: "pie",
      style: {
        overflow: "visible",
      },
      // styledMode: true
    };
    circleChartOptions["series"] = [
      {
        id: "cheese",
        name: this.translate.instant("cheese"),
        animation: false,
        data: [
          {
            color: "#03a9f4",
            name: this.translate.instant("consumption"),
            y: (totalConsumption - totalLeak) / totalConsumption,
            value:
              formatNumber(totalConsumption - totalLeak, numberFormat) +
              (self.graphType == 1 || self.graphType == 2
                ? " m³"
                : self.graphType == 3
                ? " Nm³"
                : " kWh"),
          },
          {
            color: "#ffbb45",
            name: this.translate.instant("possible-leak"),
            y: totalLeak / totalConsumption,
            value:
              formatNumber(totalLeak, numberFormat) +
              (this.graphType == 1 || this.graphType == 2
                ? " m³"
                : this.graphType == 3
                ? " Nm³"
                : " kWh"),
          },
        ],
        dataGrouping: { approximation: "sum" },
        dataLabels: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>" +
              (this.point.percentage % 1
                ? this.point.percentage.toFixed(2)
                : this.point.percentage) +
              "%<br>" +
              formatNumber(this.point.y, numberFormat) +
              (self.graphType == 1 || self.graphType == 2
                ? "m³"
                : self.graphType == 3
                ? "Nm³"
                : "kWh")
            );
          },
          alignTo: "connectors",
          crop: "false",
          overflow: "allow",
          allowOverlap: true,
          distance: "5%",
        },
      },
    ];
    this.circleChartOptions = circleChartOptions;
  }
}
