/**
 * Decodes the given frmPayload for the Viewshine protocol.
 *
 * @param {any} frmPayload - The frmPayload to be decoded.
 * @param {any} fport - The FPort of the frame.
 *
 * @returns {Object}
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class SonicoNanoDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  gwfSonicoNano_decoder(frmPayload, fport, fechaString) {
    if (frmPayload && fechaString) {
      if (frmPayload.bytes) {
        if (fport != 0x68) {
          return {
            errors: [
              "PUERTO ERRÓNEO: " + fport.toString() + "(debe ser  0x68)",
            ],
          };
        } else if (frmPayload.bytes.length != 51) {
          return {
            errors: [
              "LONGITUD ERRÓNEA: " +
                frmPayload.bytes.length.toString() +
                "bytes (debe ser  50 bytes)",
            ],
          };
        } else {
          let date = fechaString.split(/\D/);
          let fecha = new Date(
            date[2],
            date[1] - 1,
            date[0],
            date[3],
            date[4],
            date[5]
          ); /* Los valores son desde la hora en punto anterior a la trama*/
          return this.dataFrameDecoder(frmPayload.bytes, fport, fecha);
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frmPayload."],
      };
    }
  }

  roundFloat(value, multiplier) {
    return Math.round(value * multiplier * Math.pow(10, 6)) / Math.pow(10, 6);
  }

  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  parseaAlarmas(alarmas) {
    var objetoAlarmas = {};
    var alarms = [
      "no_error",
      "tampering",
      "water_leak",
      "water_burst",
      "air_in_pipe",
      "empty_pipe",
      "reverse_flow",
      "no_usage",
      "battery_low",
      "water_temperature",
      "ambient_temperature",
      "com_met_communication",
      "internal_critical",
      "warning",
      "reserved_1",
      "reserved_2",
      "nfc_error",
      "ipc_error",
      "external_flash_error",
      "config_error",
      "rtc_error",
      "unexpected_reset",
      "lora_downlink_error",
      "reserved_3",
      "reserved_4",
      "reserved_5",
      "reserved_6",
      "reserved_7",
      "reserved_8",
      "reserved_9",
      "configuration_changed",
      "power_saving_scheme",
    ];

    alarmas &= 0xc0ffffff;
    for (var i = 0; i < alarms.length; i++) {
      if ((alarmas >> i) & 0x01) {
        objetoAlarmas[alarms[i]] = true;
      }
    }

    return objetoAlarmas;
  }

  /* Decoder de Report Frame*/
  dataFrameDecoder(bytes, fport, fechaTrama) {
    var data: any = {};
    var index = 0;
    var volumeMultiplier = 1;

    switch (bytes[0]) {
      case 0x10:
        volumeMultiplier = Math.pow(10, -6);
        break;
      case 0x11:
        volumeMultiplier = Math.pow(10, -5);
        break;
      case 0x12:
        volumeMultiplier = Math.pow(10, -4);
        break;
      case 0x13:
        volumeMultiplier = Math.pow(10, -3);
        break;
      case 0x14:
        volumeMultiplier = Math.pow(10, -2);
        break;
      case 0x15:
        volumeMultiplier = Math.pow(10, -1);
        break;
      case 0x16:
        volumeMultiplier = Math.pow(10, 0);
        break;
      case 0x17:
        volumeMultiplier = Math.pow(10, 1);
        break;
      default:
        return {
          errors: ["Peso de pulso no válido"],
        };
    }
    data.FechaRX = fechaTrama.toLocaleString("es-ES");
    let referenceValue = this.roundFloat(
      this.readU32Lsb(bytes, 1),
      volumeMultiplier
    );
    data.Valor_Actual = referenceValue.toFixed(3) + " m3";
    data.Flujo_Inverso =
      this.roundFloat(this.readU32Lsb(bytes, 5), volumeMultiplier).toFixed(3) +
      " m3";

    let transmission_vs_volume_02_time_difference_value = null;
    let transmission_vs_volume_02_time_difference_unit = null;

    if (bytes[9] & 0x80) {
      // b7 = 1

      if (bytes[9] & 0x40) {
        // b6+b7 = 1
        if (bytes[9] & 0x20) {
          // b5+b6+b7 = 1
          transmission_vs_volume_02_time_difference_value = bytes[9] & 0x1f;
          transmission_vs_volume_02_time_difference_unit = " dias";
        } else {
          // b5 = 0 ; b6+b7 = 1
          transmission_vs_volume_02_time_difference_value =
            18 + (bytes[9] & 0x1f);
          transmission_vs_volume_02_time_difference_unit = " horas";
        }
      } else {
        // b5+b6 = 0 ; b7 = 1
        transmission_vs_volume_02_time_difference_value = 2 + (bytes[9] & 0x3f);
        transmission_vs_volume_02_time_difference_unit = " cuartos de hora";
      }
    } else {
      // b5+b6+b7 = 0
      transmission_vs_volume_02_time_difference_value = bytes[9] & 0x7f;
      transmission_vs_volume_02_time_difference_unit = " minutos";
    }
    data.Tiempo_desde_log =
      transmission_vs_volume_02_time_difference_value.toString() +
      transmission_vs_volume_02_time_difference_unit;

    var alarmas = this.readU32Lsb(bytes, 10);
    alarmas &= 0xc0ffffff;
    if (alarmas != 0) {
      data.Alarmas = this.parseaAlarmas(alarmas);
    }
    data.Bateria = ((bytes[13] & 0x3f) / 2).toString() + " años";
    data.Numero_Serie =
      (bytes[17].toString(16).length === 2
        ? bytes[17].toString(16)
        : "0" + bytes[17].toString(16)) +
      (bytes[16].toString(16).length === 2
        ? bytes[16].toString(16)
        : "0" + bytes[16].toString(16)) +
      (bytes[15].toString(16).length === 2
        ? bytes[15].toString(16)
        : "0" + bytes[15].toString(16)) +
      (bytes[14].toString(16).length === 2
        ? bytes[14].toString(16)
        : "0" + bytes[14].toString(16));

    data.Temperatura_Agua =
      this.roundFloat(this.readU16Lsb(bytes, 18) * 0.01, 1).toFixed(2) + " ºC";
    data.Temperatura_Ambiente =
      this.roundFloat(this.readU16Lsb(bytes, 20) * 0.01, 1).toFixed(2) + " ºC";
    data.Caudal_Maximo_Ayer = this.readU16Lsb(bytes, 22).toString() + " l/h";
    let min_flow_liters_per_hour_previous_day = this.readU16Lsb(bytes, 24);
    data.Caudal_Minimo_Ayer =
      min_flow_liters_per_hour_previous_day == 0xffff
        ? "desconocido"
        : min_flow_liters_per_hour_previous_day.toString() + " l/h";
    let max_flow_temperature_celsius_previous_day = this.roundFloat(
      this.readU16Lsb(bytes, 26) * 0.01,
      1
    );
    data.Temperatura_Maxima_Agua_Ayer =
      max_flow_temperature_celsius_previous_day == 527.36
        ? "desconocida"
        : max_flow_temperature_celsius_previous_day.toFixed(2) + " ºC";
    let compactcontrol_scaler = Math.pow(10, (bytes[28] & 0x03) - 3);
    var cc_periods = ["15min", "horario", "diario", "mensual"];
    data.Periodo_Log = cc_periods[(bytes[28] >> 2) & 0x07];
    if (((bytes[28] >> 2) & 0x07) != 1) {
      data.Error = "Sólo se parsea log horario!!!";
    } else {
      data.Valores = {};
      for (var i = 0; i < 11; i++) {
        var temp_vol_diff = this.readU16Lsb(bytes, 29 + 2 * i);
        var delta = 0;
        if (i == 0) {
          fechaTrama.setMinutes(0);
          fechaTrama.setSeconds(0);
        } else {
          fechaTrama = new Date(fechaTrama - 60 * 60 * 1000);
        }
        switch (temp_vol_diff) {
          case 0x7ffd: // overflow
            data.Valores[fechaTrama.toLocaleString("es-ES")] = "Delta overflow";
            break;
          case 0x7ffe: // internal data processing error
            data.Valores[fechaTrama.toLocaleString("es-ES")] =
              "Internal data processing error";
            break;
          case 0x7fff: // no data registered
            data.Valores[fechaTrama.toLocaleString("es-ES")] =
              "no data registered";
            break;
          default:
            if (temp_vol_diff > 0x7fff) {
              delta = this.roundFloat(
                temp_vol_diff - 0xffff,
                compactcontrol_scaler
              );
            } else {
              delta = this.roundFloat(temp_vol_diff, compactcontrol_scaler);
            }

            if (i == 0) {
              referenceValue = this.roundFloat(referenceValue - delta, 1);

              data.Valores[fechaTrama.toLocaleString("es-ES")] =
                referenceValue.toFixed(3) + " m3";
            } else {
              referenceValue = this.roundFloat(referenceValue - delta, 1);
              data.Valores[fechaTrama.toLocaleString("es-ES")] =
                referenceValue.toFixed(3) + " m3";
            }
        }
      }
    }
    return {
      data: data,
    };
  }
}
