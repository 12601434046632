export const MANUFACTURER_ALARMS = {
  "1": "CLOCK_ERROR",
  "2": "OVERFLOWING_METER",
  "3": "SOSPECHA_INCREMENTO_ANOMALO",
  "50": "GENERICA_GLOBAL",
  "51": "SUSTITUCION_MODULO_CONTADOR",
  "100": "DIEHL_CAUSE_RESERVED",
  "101": "DIEHL_CAUSE_MODULE_TEMPERED",
  "102": "DIEHL_CAUSE_BACKFLOW",
  "103": "DIEHL_CAUSE_FLOW_PROGRESS",
  "104": "DIEHL_CAUSE_STOP_PROGRESS",
  "120": "DIEHL_FLOW_PERSISTENCE_DURING",
  "121": "DIEHL_FLOW_PERSISTENCE_PROGRESS",
  "122": "DIEHL_FLOW_PERSISTENCE_IMPACTING",
  "125": "DIEHL_METROLOGY_OVERFLOW_SMALL",
  "126": "DIEHL_METROLOGY_OVERFLOW_LARGE",
  "127": "DIEHL_SYSTEM_BATTERY",
  "128": "DIEHL_SYSTEM_CLOCK_UPDATED",
  "129": "DIEHL_SYSTEM_MODULE_RECONF",
  "130": "DIEHL_SYSTEM_NOISE_DEFENSE",
  "131": "DIEHL_SYSTEM_LOW_TEMPERATURE",
  "132": "DIEHL_SYSTEM_CYCLE_AUTH_REACHED",
  "133": "DIEHL_TAMPER_REVERSED",
  "134": "DIEHL_TAMPER_MODULE_TEMPERED",
  "135": "DIEHL_TAMPER_ACQUISITION",
  "136": "DIEHL_BACKFLOW",
  "137": "DIEHL_SYSTEM_DATABASE_PURGE",
  "138": "DIEHL_STOP_PERSISTENCE",
  "139": "DIEHL_HOUR_OVER_23",
  "140": "DIEHL_FRAME_00",
  "150": "DIEHL_MBUS_SUSPECTED_LEAKAGE_CURRENTLY",
  "151": "DIEHL_MBUS_SUSPECTED_LEAKAGE_PREVIOUSLY",
  "152": "DIEHL_MBUS_MECHANICAL_FRAUD_CURRENTLY",
  "153": "DIEHL_MBUS_MECHANICAL_FRAUD_PREVIOUSLY",
  "154": "DIEHL_MBUS_MAGNETIC_FRAUD_CURRENTLY",
  "155": "DIEHL_MBUS_MAGNETIC_FRAUD_PREVIOUSLY",
  "156": "DIEHL_MBUS_FW_CHECKSUM_ERROR",
  "157": "DIEHL_MBUS_BACKFLOW_CURRENTLY",
  "158": "DIEHL_MBUS_BACKFLOW_PREVIOUSLY",
  "159": "DIEHL_SOSPECHA_CONSUMO_ERRONEO",
  "160": "DIEHL_SOSPECHA_EXTREMA_CONSUMO_ERRONEO",
  "200": "ITRON_MODULE_TAMPERED",
  "202": "ITRON_FLOW_PERSISTENCE_DURING",
  "203": "ITRON_FLOW_PERSISTENCE_PROGRESS",
  "204": "ITRON_FLOW_PERSISTENCE_IMPACTING",
  "205": "ITRON_BATTERY",
  "206": "ITRON_MODULE_RECONF",
  "207": "ITRON_REVERSED_METER",
  "208": "ITRON_ACQUISITION",
  "209": "ITRON_BACKFLOW",
  "210": "ITRON_HOUR_OVER_23",
  "211": "ITRON_FRAME_00",
  "220": "ITRON_CYBLE5_REVERSED_METER",
  "221": "ITRON_CYBLE5_METER_BLOCKED",
  "222": "ITRON_CYBLE5_BATTERY",
  "223": "ITRON_CYBLE5_DETECTION",
  "224": "ITRON_CYBLE5_MAGNETIC_TAMPER",
  "225": "ITRON_CYBLE5_MEMORIZED_REMOVAL",
  "226": "ITRON_CYBLE5_WMBUS_CREDIT",
  "227": "ITRON_CYBLE5_PREVIOUS_DAY_BACKFLOW",
  "228": "ITRON_CYBLE5_PREVIOUS_DAY_LEAKAGE",
  "229": "ITRON_CYBLE5_PREVIOUS_DAY_PEAK_FLOW",
  "230": "ITRON_CYBLE5_PREVIOUS_DAY_TEMPERATURE_ABOVE",
  "231": "ITRON_CYBLE5_PREVIOUS_DAY_TEMPERATURE_BELOW",
  "232": "ITRON_CYBLE5_PREVIOUS_MONTH_VOLUME_ABOVE_THRESHOLD",
  "233": "ITRON_CYBLE5_PREVIOUS_MONTH_VOLUME_BELOW_THRESHOLD",
  "250": "ITRON_INTELIS_WSOURCE_REVERSED_METER",
  "251": "ITRON_INTELIS_WSOURCE_METER_BLOCKED",
  "252": "ITRON_INTELIS_WSOURCE_BATTERY",
  "253": "ITRON_INTELIS_WSOURCE_BROKEN_PIPE",
  "254": "ITRON_INTELIS_WSOURCE_MAGNETIC_TAMPER",
  "255": "ITRON_INTELIS_WSOURCE_MEMORIZED_REMOVAL",
  "256": "ITRON_INTELIS_WSOURCE_WMBUS_CREDIT",
  "257": "ITRON_INTELIS_WSOURCE_NFC_CREDIT",
  "258": "ITRON_INTELIS_WSOURCE_PREVIOUS_DAY_BACKFLOW",
  "259": "ITRON_INTELIS_WSOURCE_PREVIOUS_DAY_LEAKAGE",
  "260": "ITRON_INTELIS_WSOURCE_PREVIOUS_DAY_PEAK_FLOW",
  "261": "ITRON_INTELIS_WSOURCE_PREVIOUS_DAY_TEMPERATURE_ABOVE",
  "262": "ITRON_INTELIS_WSOURCE_PREVIOUS_DAY_TEMPERATURE_BELOW",
  "263": "ITRON_INTELIS_WSOURCE_PREVIOUS_DAY_AIR_IN_PIPE",
  "264": "ITRON_INTELIS_WSOURCE_VOLUME_ABOVE_THRESHOLD_MONTHLY",
  "265": "ITRON_INTELIS_WSOURCE_VOLUME_BELOW_THRESHOLD_MONTHLY",
  "266": "ITRON_INTELIS_WSOURCE_SCALING",
  "267": "ITRON_INTELIS_WSOURCE_COMPUTED_BATTERY",
  "300": "HONEYWELL_FLOW_PERS_DURING",
  "301": "HONEYWELL_FLOW_PERS_PROGRESS",
  "302": "HONEYWELL_FLOW_PERS_IMPACTING",
  "305": "HONEYWELL_OVERFLOW_SMALL",
  "306": "HONEYWELL_OVERFLOW_LARGE",
  "307": "HONEYWELL_BATTERY",
  "308": "HONEYWELL_CLOCK_UPDATED",
  "309": "HONEYWELL_MODULE_CONFIGURED",
  "310": "HONEYWELL_NOISE_DEF",
  "311": "HONEYWELL_LOW_TEMPERATUE",
  "312": "HONEYWELL_CYCLE_AUTHORIZED",
  "313": "HONEYWELL_REVERSED_METER",
  "314": "HONEYWELL_MAGNETIC_TAMPER",
  "315": "HONEYWELL_MODULE_TAMPERED",
  "316": "HONEYWELL_ACQUISITION",
  "317": "HONEYWELL_BACKFLOW",
  "318": "HONEYWELL_HOUR_OVER_23",
  "319": "HONEYWELL_FRAME_00",
  "330": "HONEYWELL_MBUS_BATTERY_LOW",
  "331": "HONEYWELL_MBUS_LEAK",
  "332": "HONEYWELL_MBUS_NO_FLOW",
  "333": "HONEYWELL_MBUS_INSTALL_TAMPER",
  "334": "HONEYWELL_MBUS_MAGNETIC_TAMPER",
  "335": "HONEYWELL_MBUS_BACKFLOW",
  "336": "HONEYWELL_MBUS__EXTREME_LEAK",
  "337": "HONEYWELL_MBUS_WIRE_CUT_B",
  "338": "HONEYWELL_MBUS_WIRE_CUT_C",
  "339": "HONEYWELL_MBUS_WIRE_CUT_D",
  "340": "HONEYWELL_MBUS_WIRE_CUT_REED_FAULT",
  "341": "HONEYWELL_MBUS_REED_FAULT_A",
  "342": "HONEYWELL_MBUS_BACKFLOW_MONTH",
  "343": "HONEYWELL_MBUS_PERMANENT_ERROR",
  "344": "HONEYWELL_MBUS_REVERSED_METER",
  "345": "HONEYWELL_MBUS_FROST",
  "346": "HONEYWELL_MBUS_OVERSIZED_METER",
  "347": "HONEYWELL_MBUS_OUT_OF_RANGE ",
  "380": "HONEYWELL_WAVENIS_FRAUDE_MECANICO",
  "381": "HONEYWELL_WAVENIS_ERROR_PERMANENTE",
  "382": "HONEYWELL_WAVENIS_FLUJO_INVERSO",
  "383": "HONEYWELL_WAVENIS_REED_FAULT_INPUT_A_DETECTED",
  "384": "HONEYWELL_WAVENIS_REED_FAULT_INPUT_B_DETECTED",
  "385": "HONEYWELL_WAVENIS_FUGA_EXTREMA",
  "386": "HONEYWELL_WAVENIS_WIRE_CUT_INPUT_B_DETECTED",
  "387": "HONEYWELL_WAVENIS_WIRE_CUT_INPUT_A_DETECTED",
  "388": "HONEYWELL_WAVENIS_BATERIA_BAJA",
  "389": "HONEYWELL_WAVENIS_FUGA",
  "400": "AXIOMA_LOW_BATTERY",
  "401": "AXIOMA_PERMANENT",
  "402": "AXIOMA_BACKFLOW",
  "403": "AXIOMA_BURST",
  "404": "AXIOMA_LEAKAGE",
  "405": "AXIOMA_LOW_TEMPERATURE",
  "406": "AXIOMA_DRY",
  "407": "AXIOMA_TAMPER",
  "408": "AXIOMA_NO_COMSUMPTION",
  "409": "AXIOMA_NEGATIVE_FLOW",
  "601": "FIDEGAS_D195I_METANO_OK",
  "602": "FIDEGAS_D195I_METANO_ALARMA",
  "603": "FIDEGAS_D195I_METANO_AVERIA",
  "604": "FIDEGAS_D195I_METANO_PETICION_TEST",
  "605": "FIDEGAS_D195I_METANO_FIN_VIDA",
  "606": "FIDEGAS_D195I_BP_OK",
  "607": "FIDEGAS_D195I_BP_ALARMA",
  "608": "FIDEGAS_D195I_BP_AVERIA",
  "609": "FIDEGAS_D195I_BP_PETICION_TEST",
  "610": "FIDEGAS_D195I_BP_FIN_VIDA",
  "611": "FIDEGAS_D195I_CO_OK",
  "612": "FIDEGAS_D195I_CO_ALARMA_50_PPM",
  "613": "FIDEGAS_D195I_CO_ALARMA_100_PPM",
  "614": "FIDEGAS_D195I_CO_ALARMA_300_PPM",
  "615": "FIDEGAS_D195I_CO_AVERIA",
  "616": "FIDEGAS_D195I_CO_PETICION_TEST",
  "617": "FIDEGAS_D195I_CO_FIN_VIDA",
  "700": "AQUACITY_FIDEGAS_MC_D30X_CO_NO_AJUSTADO",
  "701": "AQUACITY_FIDEGAS_MC_D30X_CO_OK",
  "702": "AQUACITY_FIDEGAS_MC_D30X_CO_ALARMA_30_PPM",
  "703": "AQUACITY_FIDEGAS_MC_D30X_CO_ALARMA_100_PPM",
  "704": "AQUACITY_FIDEGAS_MC_D30X_CO_ALARMA_300_PPM",
  "705": "AQUACITY_FIDEGAS_MC_D30X_CO_AVERIA",
  "706": "AQUACITY_FIDEGAS_MC_D30X_CO_PETICION_TEST",
  "707": "AQUACITY_FIDEGAS_MC_D30X_CO_FIN_VIDA",
  "708": "AQUACITY_FIDEGAS_MC_D30X_CO_AJUSTE_CALENTAMIENTO",
  "709": "AQUACITY_FIDEGAS_MC_D30X_CO_AJUSTE_ZERO",
  "750": "AQUACITY_FIDEGAS_MC_D30X_NAT_BP_NO_AJUSTADO",
  "751": "AQUACITY_FIDEGAS_MC_D30X_NAT_OK",
  "752": "AQUACITY_FIDEGAS_MC_D30X_NAT_ALARMA",
  "753": "AQUACITY_FIDEGAS_MC_D30X_NAT_AVERIA",
  "754": "AQUACITY_FIDEGAS_MC_D30X_NAT_PETICION_TEST",
  "755": "AQUACITY_FIDEGAS_MC_D30X_NAT_FIN_VIDA",
  "756": "AQUACITY_FIDEGAS_MC_D30X_NAT_AJUSTE_CALENTAMIENTO",
  "757": "AQUACITY_FIDEGAS_MC_D30X_NAT_AJUSTE_ZERO",
  "758": "AQUACITY_FIDEGAS_MC_D30X_BP_OK",
  "759": "AQUACITY_FIDEGAS_MC_D30X_BP_ALARMA",
  "760": "AQUACITY_FIDEGAS_MC_D30X_BP_AVERIA",
  "761": "AQUACITY_FIDEGAS_MC_D30X_BP_PETICION_TEST",
  "762": "AQUACITY_FIDEGAS_MC_D30X_BP_FIN_VIDA",
  "763": "AQUACITY_FIDEGAS_MC_D30X_BP_AJUSTE_CALENTAMIENTO",
  "764": "AQUACITY_FIDEGAS_MC_D30X_BP_AJUSTE_ZERO",
  "765": "FIDEGAS_NO_COMUNICA",
  "800": "SAGEMCOM_LOW_AIR_TEMPERATURE",
  "801": "SAGEMCOM_HIGH_AIR_TEMPERATURE",
  "802": "SAGEMCOM_LOW_WATER_TEMPERATURE",
  "803": "SAGEMCOM_HIGH_WATER_TEMPERATURE",
  "804": "SAGEMCOM_FRAUD_SUSPICION",
  "805": "SAGEMCOM_SMALL_WATER_LEACKAGE",
  "806": "SAGEMCOM_HARD_WATER_LEACKAGE",
  "807": "SAGEMCOM_AIR_BUBBLES",
  "808": "SAGEMCOM_NO_WATER",
  "809": "SAGEMCOM_HARDWARE_ERROR",
  "810": "SAGEMCOM_NO_CONSUMPTION",
  "811": "SAGEMCOM_WATER_BACKFLOW",
  "812": "SAGEMCOM_LOW_BATTERY",
  "813": "SAGEMCOM_CRITICAL_BATTERY",
  "814": "SAGEMCOM_METROLOGICAL_CRC_ERROR",
  "815": "SAGEMCOM_TAMPERING",
  "816": "SAGEMCOM_WATER_OVERFLOW",
  "840": "SAGEMCOM_GAS_MECHANICAL_TAMPER",
  "841": "SAGEMCOM_GAS_BATTERY_LOW",
  "842": "SAGEMCOM_GAS_EXTERNAL_ALARM",
  "843": "SAGEMCOM_GAS_BATTERY_CUT_DISCONNECTED",
  "844": "SAGEMCOM_GAS_HW_SW_ERROR",
  "845": "SAGEMCOM_GAS_CONNECTION_INTERRUPT",
  "846": "SAGEMCOM_GAS_MAGNETIC_TAMPER",
  "847": "SAGEMCOM_GAS_UNAUTHORIZED_ACCESS_ATTEMPT",
  "848": "SAGEMCOM_GAS_CREDIT_LIMIT_EXCEEDED",
  "849": "SAGEMCOM_GAS_LEAKAGE",
  "850": "SAGEMCOM_GAS_NO_FLOW",
  "851": "SAGEMCOM_GAS_CONSUMPTION_OUT_RANGE",
  "852": "SAGEMCOM_GAS_SENSOR_OUT_RANGE",
  "853": "SAGEMCOM_GAS_CLOCK_SYNC_ERROR",
  "854": "SAGEMCOM_GAS_METER_SET_TO_VB",
  "855": "SAGEMCOM_GAS_LOCAL_OPERATOR_MENU_ACCESSED",
  "856": "SAGEMCOM_BACKFLOW_ANOMALO",
  "857": "SAGEMCOM_SOSPECHA_CONSUMO_NEGATIVO",
  "858": "SAGEMCOM_GAS_APP_BUSY",
  "859": "SAGEMCOM_GAS_APP_ERROR",
  "860": "SAGEMCOM_GAS_EXTERNAL_OR_FLOW_ALARM",
  "861": "SAGEMCOM_GAS_PERMANENT_ERROR",
  "862": "SAGEMCOM_GAS_DISCARDED_FRAME",
  "900": "SENSING_LABS_WIRECUT",
  "901": "SENSING_LABS_LEAKAGE",
  "902": "SENSING_LABS_LOW_BATTERY",
  "903": "SENSING_LABS_FLOW_STOP",
  "1000": "G2_MISURATORI_GENERICA",
  "1001": "G2_MISURATORI_BACKFLOW",
  "1002": "G2_MISURATORI_FRAUD",
  "1003": "G2_MISURATORI_BATTERY",
  "1004": "G2_MISURATORI_LEAKAGE",
  "1100": "CONTHIDRA_JANZ_OVER_CONSUMPTION",
  "1101": "CONTHIDRA_JANZ_UNDER_CONSUMPTION",
  "1102": "CONTHIDRA_JANZ_BLOCKED_METER",
  "1103": "CONTHIDRA_JANZ_WATER_LEAK",
  "1104": "CONTHIDRA_JANZ_LOW_BATTERY",
  "1105": "CONTHIDRA_JANZ_RADIO_MODULE_REMOVED",
  "1106": "CONTHIDRA_JANZ_BACKFLOW",
  "1130": "CONTHIDRA_MYWATER2_MAX_FLOW",
  "1131": "CONTHIDRA_MYWATER2_SABOTAGE",
  "1132": "CONTHIDRA_MYWATER2_LEAKAGE",
  "1133": "CONTHIDRA_MYWATER2_REVERSE_FLOW",
  "1134": "CONTHIDRA_MYWATER2_EXTREME_CONDITIONS",
  "1135": "CONTHIDRA_MYWATER2_BLOCKED_WATERMETER",
  "1136": "CONTHIDRA_MYWATER2_ERROR_HARDWARE_TEMPORAL",
  "1137": "CONTHIDRA_MYWATER2_ERROR_HARDWARE_PERMANENT",
  "1138": "CONTHIDRA_MYWATER2_REMOVE",
  "1139": "CONTHIDRA_MYWATER2_FLAG_LOCAL_COMM",
  "1140": "CONTHIDRA_MYWATER2_MIN_FLOW",
  "1141": "CONTHIDRA_MYWATER2_LOW_BATTERY",
  "1142": "CONTHIDRA_WRONG_DATE_PARSE",
  "1200": "ZENNER_LOW_BATTERY",
  "1201": "ZENNER_BATTERY_END_LIFE",
  "1202": "ZENNER_HW_ERROR",
  "1203": "ZENNER_COIL_MANIPULATION",
  "1204": "ZENNER_OVERSIZED",
  "1205": "ZENNER_UNDERSIZED",
  "1206": "ZENNER_BLOCKAGE",
  "1207": "ZENNER_BACKFLOW",
  "1208": "ZENNER_LEAK",
  "1209": "ZENNER_BURST",
  "1210": "ZENNER_TAMPER",
  "1211": "ZENNER_REMOVAL",
  "1212": "ZENNER_REVERSE_INSTALLATION",
  "1213": "ZENNER_ERROR",
  "1214": "ZENNER_NO_CONSUMPTION",
  "1215": "ZENNER_DRY",
  "1216": "ZENNER_FROST",
  "1301": "AIUT_ERROR_MAX_HOUR_FLOW",
  "1302": "AIUT_ERROR_MAX_TEMPORAL_FLOW",
  "1303": "AIUT_ERROR_SABOTAGE",
  "1304": "AIUT_ERROR_REMOVE",
  "1305": "AIUT_FLAG_OPTO",
  "1306": "AIUT_FLAG_BUTTON",
  "1307": "AIUT_FLAG_LARGE_CLOCK_SET",
  "1308": "AIUT_FLAG_VOLUME_SET",
  "1309": "AIUT_FLAG_ENERGY_SET",
  "1310": "AIUT_FLAG_INSTALLATION",
  "1311": "AIUT_ERROR_EXTREME_TEMPERATURE",
  "1312": "AIUT_ERROR_RTC",
  "1313": "AIUT_ERROR_EEPROM",
  "1314": "AIUT_ERROR_COUNTER_COPY",
  "1315": "AIUT_ERROR_FLASH",
  "1400": "ZHONG_YI_BATTERY_UNDERVOLTAGE",
  "1401": "ZHONG_YI_BATTERY_POWER",
  "1402": "ZHONG_YI_BACKFLOW",
  "1403": "ZHONG_YI_VALVE_MALFUNCTION",
  "1404": "ZHONG_YI_VIBRATION_ALARM",
  "1405": "ZHONG_YI_LEAK",
  "1406": "ZHONG_YI_METERING_FAILURE",
  "1407": "ZHONG_YI_VALVE_ABNORMAL_STATE",
  "1500": "WUAVENIS_END_BATTERY_LIFE",
  "1501": "WUAVENIS_WIRECUT",
  "1502": "WUAVENIS_LOW_THRESHOLD",
  "1503": "WUAVENIS_HIGH_THRESHOLD",
  "1504": "WUAVENIS_REED_FAULT",
  "1505": "WUAVENIS_BACKFLOW",
  "1600": "FIDEGAS_MC_D30X_CO_NO_AJUSTADO",
  "1605": "FIDEGAS_MC_D30X_CO_OK",
  "1610": "FIDEGAS_MC_D30X_CO_ALARMA_30_PPM",
  "1615": "FIDEGAS_MC_D30X_CO_ALARMA_50_PPM",
  "1620": "FIDEGAS_MC_D30X_CO_ALARMA_100_PPM",
  "1625": "FIDEGAS_MC_D30X_CO_ALARMA_300_PPM",
  "1630": "FIDEGAS_MC_D30X_CO_AVERIA",
  "1635": "FIDEGAS_MC_D30X_CO_PETICION_TEST",
  "1640": "FIDEGAS_MC_D30X_CO_FIN_VIDA",
  "1645": "FIDEGAS_MC_D30X_CO_AJUSTE_CALENTAMIENTO",
  "1650": "FIDEGAS_MC_D30X_CO_AJUSTE_ZERO",
  "1655": "FIDEGAS_MC_D30X_CO_PERDIDA_ALIMENTACION",
  "1680": "FIDEGAS_MC_D30X_NAT_NO_AJUSTADO",
  "1685": "FIDEGAS_MC_D30X_NAT_OK",
  "1690": "FIDEGAS_MC_D30X_NAT_ALARMA",
  "1695": "FIDEGAS_MC_D30X_NAT_SOBRERANGO",
  "1700": "FIDEGAS_MC_D30X_NAT_AVERIA",
  "1705": "FIDEGAS_MC_D30X_NAT_PETICION_TEST",
  "1710": "FIDEGAS_MC_D30X_NAT_FIN_VIDA",
  "1715": "FIDEGAS_MC_D30X_NAT_AJUSTE_CALENTAMIENTO",
  "1720": "FIDEGAS_MC_D30X_NAT_AJUSTE_ZERO",
  "1725": "FIDEGAS_MC_D30X_NAT_PERDIDA_ALIMENTACION",
  "1730": "FIDEGAS_MC_D30X_BP_NO_AJUSTADO",
  "1735": "FIDEGAS_MC_D30X_BP_OK",
  "1740": "FIDEGAS_MC_D30X_BP_ALARMA",
  "1745": "FIDEGAS_MC_D30X_BP_AVERIA",
  "1750": "FIDEGAS_MC_D30X_BP_PETICION_TEST",
  "1755": "FIDEGAS_MC_D30X_BP_FIN_VIDA",
  "1760": "FIDEGAS_MC_D30X_BP_AJUSTE_CALENTAMIENTO",
  "1765": "FIDEGAS_MC_D30X_BP_AJUSTE_ZERO",
  "1770": "FIDEGAS_MC_D30X_BP_PERDIDA_ALIMENTACION",
  "1780": "FIDEGAS_MC_D30X_STATE_FALLO_VCC",
  "1781": "FIDEGAS_MC_D30X_STATE_FALLO_TRANSMISION_LORA",
  "1782": "FIDEGAS_MC_D30X_STATE_FALLO_COMM_CHIP_LORA",
  "1783": "FIDEGAS_MC_D30X_STATE_FALLO_RAM",
  "1784": "FIDEGAS_MC_D30X_STATE_FALLO_EEPROM",
  "1785": "FIDEGAS_MC_D30X_STATE_FALLO_FLASH",
  "1786": "FIDEGAS_MC_D30X_STATE_FALLO_PROGRAMA",
  "1787": "FIDEGAS_MC_D30X_STATE_FALLO_ALIMENTACION_GENERAL",
  "1800": "kAsicAlarmTamper",
  "1801": "kAsicAlarmAirBubbles",
  "1802": "kAsicAlarmBurst",
  "1803": "kAsicAlarmLeak",
  "1804": "kAsicAlarmFrost",
  "1805": "kAsicAlarmHeat",
  "1806": "kAsicAlarmOverTemp",
  "1807": "kAsicAlarmNoConsumption",
  "1808": "kFlagStBatteryLow",
  "1809": "kAsicAlarmReverseFlow",
  "1810": "kAsicAlarmOverFlow",
  "1811": "kAsicAlarmDry",
  "1812": "kAsicAlarmLimitMinWaterTemp",
  "1813": "kAsicAlarmLimitMaxWaterTemp",
  "1814": "kAsicAlarmLimitMinAmbTemp",
  "1815": "kAsicAlarmLimitMaxAmbTemp",
  "1816": "kAsicWrongDateParse",
  "1817": "kAsicWrongDataParse",
  "1900": "uneAlarmResetFrio",
  "1901": "uneAlarmResetFinStandby",
  "1902": "uneAlarmResetDesconocidoCaliente",
  "1903": "uneAlarmResetErrorPila",
  "1904": "uneAlarmResetWatchdogFW",
  "1905": "uneAlarmResetWatchdogHW",
  "1906": "uneAlarmResetErrorMemoriaDinamica",
  "1907": "uneAlarmResetErrorCreacionProceso",
  "1908": "uneAlarmResetDesbordamientoComandos",
  "1909": "uneAlarmResetDesbordamientoLorawan",
  "1910": "uneAlarmResetDesbordamientoDataflash",
  "1911": "uneAlarmResetDesbordamientoAplicacion",
  "1912": "uneAlarmResetDesbordamientoUNE",
  "1913": "uneAlarmFechaHoraReseteadas",
  "1914": "uneAlarmAlimentacionBaja",
  "1915": "uneAlarmAlimentacionCritica",
  "1916": "uneAlarmErrorProceso",
  "1917": "uneAlarmErrorObjeto",
  "1918": "uneAlarmContadoresComunicacionesCorruptos",
  "1919": "uneAlarmErrorUltimaLectura",
  "1920": "uneAlarmErrorUltimaEscritura",
  "1921": "uneAlarmErrorUltimoBorrdoSector",
  "1922": "uneAlarmErrorUltimoBorradoCompleto",
  "1923": "uneAlarmErrorMemoria",
  "1924": "uneAlarmResetEspontaneoModem",
  "1925": "CONTAZARA_LOW_BATTERY",
  "2000": "SATELITE_NO_TENSION_DISPARO",
  "2001": "SATELITE_NO_TENSION_DISPARO_HRCO",
  "2002": "SATELITE_VALVULA_DESCONECTADA",
  "2003": "SATELITE_VALVULA_DESCONECTADA_HRCO",
  "2004": "SATELITE_ALARMA_ENTRADA_1",
  "2005": "SATELITE_ALARMA_ENTRADA_1_HRCO",
  "2006": "SATELITE_ALARMA_ENTRADA_2",
  "2007": "SATELITE_ALARMA_ENTRADA_2_HRCO",
  "2008": "SATELITE_CAUDAL_1",
  "2009": "SATELITE_CAUDAL_1_HRCO",
  "2010": "SATELITE_CAUDAL_2",
  "2011": "SATELITE_CAUDAL_2_HRCO",
  "2012": "SATELITE_CAUDAL_3",
  "2013": "SATELITE_CAUDAL_3_HRCO",
  "2014": "SATELITE_CAUDAL_4",
  "2015": "SATELITE_CAUDAL_4_HRCO",
  "2016": "SATELITE_PULSOS_VALVULA_CERRADA",
  "2017": "SATELITE_NO_PULSOS_VALVULA_ABIERTA",
  "2018": "SATELITE_PULSOS_VALVULA_CERRADA_HRCO",
  "2019": "SATELITE_NO_PULSOS_VALVULA_ABIERTA_HRCO",
  "2020": "SATELITE_ALARMA_UMBRAL_MINIMO",
  "2021": "SATELITE_ALARMA_UMBRAL_MAXIMO",
  "2022": "SATELITE_ALARMA_UMBRAL_MINIMO_HRCO",
  "2023": "SATELITE_ALARMA_UMBRAL_MAXIMO_HRCO",
  "2024": "SATELITE_CONSUMO_ERRONEO",
  "2025": "SATELITE_CONSUMO_1",
  "2026": "SATELITE_CONSUMO_2",
  "2027": "SATELITE_CONSUMO_3",
  "2028": "SATELITE_CONSUMO_4",
  "2029": "SATELITE_CONSUMO_5",
  "2030": "SATELITE_CONSUMO_6",
  "2031": "SATELITE_CONSUMO_7",
  "2032": "SATELITE_CONSUMO_8",
  "2033": "SATELITE_CONSUMO_HRCO_1",
  "2034": "SATELITE_CONSUMO_HRCO_2",
  "2035": "SATELITE_CONSUMO_HRCO_3",
  "2036": "SATELITE_CONSUMO_HRCO_4",
  "2037": "SATELITE_CONSUMO_HRCO_5",
  "2038": "SATELITE_CONSUMO_HRCO_6",
  "2039": "SATELITE_CONSUMO_HRCO_7",
  "2040": "SATELITE_CONSUMO_HRCO_8",
  "2100": "BETA_GAS_MECHANICAL_TAMPER",
  "2101": "BETA_GAS_BATTERY_LOW",
  "2102": "BETA_GAS_HW_SW_ERROR",
  "2103": "BETA_GAS_MAGNETIC_TAMPER",
  "2104": "BETA_GAS_UNAUTHORIZED_ACCESS_ATTEMPT",
  "2105": "BETA_GAS_LEAKAGE",
  "2106": "BETA_GAS_NO_FLOW",
  "2107": "BETA_GAS_CONSUMPTION_OUT_RANGE",
  "2108": "BETA_GAS_SENSOR_OUT_RANGE",
  "2109": "BETA_GAS_CLOCK_SYNC_ERROR",
  "2150": "HIDROCONTA_METER_MANIPULATION",
  "2151": "HIDROCONTA_BACKFLOW",
  "2152": "HIDROCONTA_LEAKAGE",
  "2153": "HIDROCONTA_NO_FLOW",
  "2154": "HIDROCONTA_UNDER_CONSUMPTION",
  "2155": "HIDROCONTA_LOW_BATTERY",
  "2200": "AIMEI_ABNORMAL_STORAGE",
  "2201": "AIMEI_ABNORMAL_MODULE",
  "2202": "AIMEI_ABNORMAL_MEASUREMENT",
  "2203": "AIMEI_DEVICE_ON",
  "2204": "AIMEI_TEMPERATURE_OVERRUN",
  "2205": "AIMEI_BLANK_PIPE",
  "2206": "AIMEI_POSITIVE_FLOW_OVERRUN",
  "2207": "AIMEI_REVERSE_FLOW_OVERRUN",
  "2208": "AIMEI_WATER_LEAK",
  "2209": "AIMEI_TUBE_BURST",
  "2210": "AIMEI_REVERSE_FLOW",
  "2211": "AIMEI_BATTERY_UNDERVOLTAGE",
  "2212": "AIMEI_BATTERY_LOW_VOLTAGE",
  "2213": "AIMEI_HIGH_TEMPERATURE_OVERRUN",
  "2214": "AIMEI_COMMUNICATION_BATTERY_UNDERVOLTAGE",
  "2215": "AIMEI_COMMUNICATION_BATTERY_LOW_VOLTAGE",
  "2216": "AIMEI_TIMEZONE_ERROR",
  "2230": "AIMEI_TK_L_LOW_BATTERY",
  "2231": "AIMEI_TK_L_LOW_VOLTAJE",
  "2232": "AIMEI_TK_L_LEAKAGE",
  "2233": "AIMEI_TK_L_REVERSE_FLOW",
  "2234": "AIMEI_TK_L_NO_WATER_FOR_LONG_TIME",
  "2235": "AIMEI_TK_L_FORWARD_FLOW_OVERRUN",
  "2236": "AIMEI_TK_L_HIGH_TEMPERATURE",
  "2237": "AIMEI_TK_L_LOW_TEMPERATURE",
  "2238": "AIMEI_TK_L_METERING_MODULE_ABNORMAL",
  "2239": "AIMEI_TK_L_RF_ABNORMAL",
  "2240": "AIMEI_TK_L_RTC_ABNORMAL",
  "2241": "AIMEI_TK_L_STORAGE_ABNORMAL",
  "2242": "AIMEI_TK_L_DISASSEMBLE_THE_METER",
  "2243": "AIMEI_TK_L_HAPPENED_REVERSE_FLOW",
  "2244": "AIMEI_TK_L_HAPPENED_LEAKAGE",
  "2250": "MBUS_CONNECTION_INTERRUPT",
  "2251": "MBUS_BATTERY_LOW",
  "2252": "MBUS_MAGNETIC_TAMPER",
  "2253": "MBUS_ACCESO_NO_AUTORIZADO",
  "2254": "MBUS_LIMITE_CREDITO_EXCEDIDO",
  "2255": "MBUS_MBUS_LEAK",
  "2256": "MBUS_NO_FLOW",
  "2257": "MBUS_APP_OCUPADA",
  "2258": "MBUS_PERMANENT_ERROR",
  "2259": "MBUS_MANIPULACION",
  "2260": "MBUS_OUT_OF_RANGE",
  "2261": "MBUS_SENSOR_OUT_OF_RANGE",
  "2262": "MBUS_ERROR_SINCRONIZACION_RELOJ",
  "2263": "MBUS_ACCESO_LOCAL_MENU_OPERADOR",
  "2264": "MBUS_CONTADOR_CAMBIADO_A_VB",
  "2265": "MBUS_ERROR_SW_HW",
  "2266": "MBUS_CORTE_DESCONEXION_BATERIA",
  "2267": "MBUS_ALARMA_EXTERNA",
  "2268": "MBUS_BACKFLOW",
  "2269": "MBUS_LEAK",
  "2270": "MBUS__EXTREME_LEAK",
  "2271": "MBUS_OVERSIZED_METER",
  "2272": "MBUS_FROST",
  "2274": "MBUS_APP_ERROR",
  "2275": "MBUS_REVERSED_METER",
  "2276": "MBUS_ALARMA_CAUDAL",
  "2277": "MBUS_MECHANICAL_FRAUD_CURRENTLY",
  "2278": "MBUS_TEMPORARY_ERROR",
  "2279": "MBUS_BATTERY_OVER",
  "2280": "MBUS_BATTERY_ERROR",
  "2281": "MBUS_ACCURACY_UNSAFE",
  "2282": "MBUS_SLEEP",
  "2283": "MBUS_NOT_PROTECTED",
  "2284": "MBUS_NDC_MODULE_BATTERY_WARNING",
  "2285": "MBUS_NDC_MODULE_CONNECTION_LOST",
  "2300": "ADD_ENPROTECH_WM_WV_COMM_ERROR",
  "2301": "ADD_ENPROTECH_WM_WV_TAMPER",
  "2302": "ADD_ENPROTECH_WM_WV_MAGNETIC_FIELD",
  "2303": "ADD_ENPROTECH_WM_CLOCK_INVALID",
  "2304": "ADD_ENPROTECH_WM_TAMPER",
  "2305": "ADD_ENPROTECH_WM_MAGNETIC_FIELD",
  "2306": "ADD_ENPROTECH_WM_LOW_BAT",
  "2307": "ADD_ENPROTECH_WM_HARDWARE_FAULT",
  "2308": "ADD_ENPROTECH_WM_FIRMWARE_CHANGED",
  "2309": "ADD_ENPROTECH_WM_LEAKAGE",
  "2310": "ADD_ENPROTECH_WM_DRY",
  "2311": "ADD_ENPROTECH_WM_BACKFLOW",
  "2312": "ADD_ENPROTECH_WM_BURST",
  "2313": "ADD_ENPROTECH_WM_LOW_TEMP",
  "2330": "SEWERIN_LEAKAGE_POSSIBLE",
  "2370": "BMETER_HYDRODIGIT_WATER_LEAKAGE",
  "2371": "BMETER_HYDRODIGIT_WRONG_INSTALLATION",
  "2372": "BMETER_HYDRODIGIT_OVERFLOW",
  "2373": "BMETER_HYDRODIGIT_BURST",
  "2374": "BMETER_HYDRODIGIT_REVERSE_FLOW",
  "2375": "BMETER_HYDRODIGIT_LOW_BATTERY",
  "2376": "BMETER_LRX_MAGNETIC",
  "2377": "BMETER_LRX_REMOVAL",
  "2378": "BMETER_LRX_SENSOR_FRAUD",
  "2379": "BMETER_LRX_LEAKAGE",
  "2380": "BMETER_LRX_REVERSE_FLOW",
  "2381": "BMETER_LRX_LOW_BATTERY",
  "2350": "KAMSTRUP_DRY",
  "2351": "KAMSTRUP_REVERSE",
  "2352": "KAMSTRUP_LEAK",
  "2353": "KAMSTRUP_BURST",
  "2354": "KAMSTRUP_TAMPER",
  "2355": "KAMSTRUP_LOW_BATTERY",
  "2356": "KAMSTRUP_LOW_AMBIENT_TEMPERATURE",
  "2357": "KAMSTRUP_HIGH_AMBIENT_TEMPERATURE",
  "2358": "KAMSTRUP_FLOW_ABOVE_Q4",
  "2359": "KAMSTRUP_NO_CONSUMPTION",
  "2400": "CONTHIDRA_EREGISTER_MECHANICAL_FRAUD",
  "2401": "CONTHIDRA_EREGISTER_MAGNETIC_FRAUD",
  "2402": "CONTHIDRA_EREGISTER_SUSPECTED_LEAKAGE",
  "2403": "CONTHIDRA_EREGISTER_OVERFLOW",
  "2404": "CONTHIDRA_EREGISTER_BACKFLOW",
  "2405": "CONTHIDRA_EREGISTER_NO_CONSUMPTION",
  "2406": "CONTHIDRA_EREGISTER_METER_REVERSED",
  "2407": "CONTHIDRA_EREGISTER_OUT_OF_OPERATING_TEMPERATURE",
  "2408": "CONTHIDRA_EREGISTER_LOW_BATTERY_VOLTAGE",
  "2409": "CONTHIDRA_EREGISTER_LOW_BATTERY_CHARGE",
  "2410": "CONTHIDRA_EREGISTER_EXPIRED_SEALING_PERIOD",
  "2411": "CONTHIDRA_EREGISTER_CONFIG_SET_TO_DEFAULT_VALUE",
  "2412": "CONTHIDRA_EREGISTER_METROLOGICAL_WRONG_CHECKSUM",
  "2500": "VS_BACKFLOW",
  "2501": "VS_AIR_PIPE",
  "2502": "VS_LEAK",
  "2503": "VS_UNEXPECTED_TEMPERATURE",
  "2504": "VS_UNEXPECTED_FLOW",
  "2505": "VS_LOW_BATTERY",
  "2506": "VS_GP30_FAILURE",
  "2507": "VS_EEPROM_FAILURE",
  "2508": "VS_ERROR_REPORT_WRONG_LENGTH",
  "2509": "VS_ERROR_ALARM_WRONG_LENGTH",
  "2510": "VS_ERROR_BATTERY_WRONG_LENGTH",
  "2550": "NBIOT_FILE_CLOCK_SYNC_FAILURE",
  "2551": "NBIOT_FILE_METROLOGICAL_EVENT_LOG_COMPLETED",
  "2552": "NBIOT_FILE_METROLOGICAL_EVENT_LOG_90_COMPLETED",
  "2553": "NBIOT_FILE_MEASURE_ERROR",
  "2554": "NBIOT_FILE_SW_ERROR",
  "2555": "NBIOT_FILE_FLOW_ERROR",
  "2556": "NBIOT_FILE_MEMORY_ERROR",
  "2557": "NBIOT_FILE_BATTERY_LESS_10",
  "2558": "NBIOT_FILE_LOW_BATTERY",
  "2559": "NBIOT_FILE_TAMPERING",
  "2560": "NBIOT_FILE_VALVE_EVENT",
  "2561": "NBIOT_FILE_VALVE_EVENT_2",
  "2562": "NBIOT_FILE_VALVE_CLOSED_LEAKS",
  "2600": "HONEYWELL_BK_G4_E_MECHANICAL_TAMPER",
  "2601": "HONEYWELL_BK_G4_E_LOW_BATTERY",
  "2602": "HONEYWELL_BK_G4_E_HARDWARE_OR_SOFTWARE_ERROR",
  "2603": "HONEYWELL_BK_G4_E_MAGNETIC_TAMPER",
  "2604": "HONEYWELL_BK_G4_E_UNAUTHORISED_ACCESS_ATTEMPT",
  "2605": "HONEYWELL_BK_G4_E_LEAKAGE",
  "2606": "HONEYWELL_BK_G4_E_NO_FLOW",
  "2607": "HONEYWELL_BK_G4_E_CONSUMPTION_OUT_OF_RANGE",
  "2608": "HONEYWELL_BK_G4_E_SENSOR_OUT_OF_RANGE",
  "2609": "HONEYWELL_BK_G4_E_CLOCK_SYNCHRONIZATION_ERROR",
  "2610": "HONEYWELL_BK_G4_E_VIBRATION_MOVEMENT_OR_TILT",
  "2611": "HONEYWELL_BK_G4_E_SETTINGS_SWITCHED",
  "2612": "HONEYWELL_BK_G4_E_UNEXPECTED_VALVE_CLOSURE",
  "2613":
    "HONEYWELL_BK_G4_E_VALVE_COULD_NOT_BE_OPENED_AS_GAS_FLOW_CHECK_FAILED",
  "2614": "HONEYWELL_BK_G4_E_EXTERNAL_ALARM",
  "2615": "HONEYWELL_BK_G4_E_BATTERY_OUTAGE_OR_DISCONNECTION",
  "2616": "HONEYWELL_BK_G4_E_INTERRUPTED_CONNECTION",
  "2617": "HONEYWELL_BK_G4_E_CREDIT_LIMIT_EXCEEDED",
};
