import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[appMonthDaysInput]",
})
export class MonthDaysInputDirective {
  @Input() monthDays: string;
  @Input() monthMaxDay: number;

  constructor(private el: ElementRef) {}

  // Control de entrada de teclado hexadecimal
  @HostListener("keypress", ["$event"]) numberOnly(e: any): boolean {
    let lastChar = this.monthDays
      ? this.monthDays[this.monthDays?.length - 1]
      : null;
    let lastSeparator = this.monthDays?.lastIndexOf(",");
    let regex: RegExp;
    if (this.monthMaxDay) {
      regex = !lastChar
        ? new RegExp("^[1-9,]+$")
        : lastChar == ","
        ? new RegExp("^[0-9]+$")
        : lastSeparator < this.monthDays.length - 2 ||
          parseInt(lastChar) > this.monthMaxDay[0]
        ? new RegExp("^[,]+$")
        : parseInt(lastChar) < this.monthMaxDay[0]
        ? new RegExp("^[0-9,]+$")
        : new RegExp("^[0-" + this.monthMaxDay[1] + ",]+$");
    } else {
      regex = !lastChar
        ? new RegExp("^[1-9,]+$")
        : lastChar == ","
        ? new RegExp("^[0-9]+$")
        : lastSeparator < this.monthDays.length - 2 || parseInt(lastChar) > 3
        ? new RegExp("^[,]+$")
        : parseInt(lastChar) < 3
        ? new RegExp("^[0-9,]+$")
        : new RegExp("^[0-1,]+$");
    }

    let str: string = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
}
