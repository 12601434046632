<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <!-- Título -->
        <div class="gateway-assigned-agrupation">
          <app-material-select
            class="material-header-select"
            [title]="'gateway-list-network' | translate"
            [bindValue]="'id'"
            [bindLabel]="'name'"
            [selected]="currentAgrupation?.id"
            [data]="agrupationList"
            (selectedOption)="selectedAgrupation = $event; getData()"
          ></app-material-select>
        </div>

        <!-- Botón exportar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="exportIcon"
          [title]="exportTitle"
          (action)="exportAllGateways()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewaysAssigned'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [highlightRow]="tableHighlightRow"
          [columns]="columns"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [specialFilter]="true"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [data]="tableData"
        >
        </app-table-controller>

        <!-- Gráfica -->
        <div id="load-graph">
          <app-graph-controller
            *ngIf="highchartsOptions"
            id="gateways-assigned-graph"
            [highchartsOptions]="highchartsOptions"
            [data]="chartOptions"
            [chartConstructor]="chartConstructor"
            [columns]="true"
            [dateRange]="defaultDateRange"
            (dataRequest)="loadGraphData($event.from, $event.to)"
            [colorByPoint]="colorByPoint"
            [seriesToggle]="seriesToggle"
            (serieToggled)="sateliteShowVcc = $event[0].visible; getSeries()"
          >
          </app-graph-controller>
        </div>
      </div>
    </div>
  </div>
</section>
