// ANCHOR Datos de entidad de la lista de entidades
export interface EntityListEntity {
  // BackEnd
  id: number;
  descripcion: string;
  comments: string;
  clientName: string;

  // FrontEnd
  highlightEntity: boolean;
}

// ANCHOR Entidad
export class Entity {
  public id?: number;
  public descripcion: string;
  public alias: string;
  public comments: string;
  public apiId: number;
  public isAqualia: boolean;
  public defaultAgrupation: number;
  public client: number;
  public loadBalancerId: number;
  public limitDays: number;
  public imageFile: any;
  public updateImage?: boolean | number;
  public entity?: number;
  public dDays?: number;
  public readings?: boolean;
  public consumptions?: boolean;
  public events?: boolean;
  public frames?: boolean;
  public alarms?: boolean;
  public ddConf?: boolean;
  public timezone?: string;

  constructor() {
    this.id = null;
    this.descripcion = null;
    this.alias = null;
    this.comments = null;
    this.apiId = null;
    this.isAqualia = null;
    this.defaultAgrupation = null;
    this.client = null;
    this.loadBalancerId = null;
    this.limitDays = null;
    this.imageFile = null;
    this.updateImage = null;
    this.entity = null;
    this.dDays = null;
    this.readings = null;
    this.consumptions = null;
    this.events = null;
    this.frames = null;
    this.alarms = null;
    this.ddConf = null;
  }
}

// ANCHOR Visibilidad de entidad
export interface EntityVisibility {
  id: number;
  limitDays: number;
}
