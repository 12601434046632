<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5 *ngIf="!balanceId">
          <b> {{ "hydraulic-groups-add" | translate }}</b>
        </h5>
        <h5 *ngIf="balanceId">
          <b>
            {{ "hydraulic-groups-edit" | translate }}:
            {{ balance.name != null ? balance.name : null }}</b
          >
        </h5>

        <!-- Botón guardar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="saveIcon"
          [title]="saveTitle"
          [disabled]="
            balance?.name == null ||
            balance?.name == '' ||
            balance?.description == null ||
            balance?.description == ''
          "
          (action)="saveBalance()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div *ngIf="balance && tableData" class="balance-form">
          <mat-form-field appearance="standard">
            <mat-label>{{ "name" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="balance.name"
              placeholder="{{ 'name' | translate }}..."
              (change)="exportFileName = balance.name"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ "description" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="balance.description"
              placeholder="{{ 'description' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'dataAnalysisBalanceNew'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="maxReg"
          [cupsFilters]="filters"
          [initialLockSelected]="true"
          (selectedDataUpdateFlag)="selectMapByTable()"
          [data]="tableData"
        >
        </app-table-controller>

        <!-- Mapa de balance -->
        <div class="balance-detail-map">
          <app-map-controller
            [mapType]="mapType"
            [mapHeight]="500"
            [activateAllLayers]="activateAllLayers"
            (actionFlag)="updateByMap([$event.meter], $event.type)"
            [freeDrawEnabled]="true"
            (selectedDevices)="updateByMap($event)"
            [gatewaysData]="[]"
            [metersData]="mapData"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
