<!-- Desplegable de clientes, entidad o agrupaciones -->
<div
  #navbarTopDropdown
  class="navbar-top-dropdown-container"
  (click)="toggleVisibility(true); focusInput(elementFilter, type)"
  (mouseenter)="toggleVisibility(true); focusInput(elementFilter, type)"
  (mouseleave)="toggleVisibility(false)"
>
  <button class="navbar-top-dropdown-button">
    <i class="{{ classes }}"></i>
    {{ title | translate }} ({{ currentElement?.itemsCount }})
    <span class="caret"></span>
  </button>
  <p *ngIf="!currentElement" class="navbar-top-selected">-</p>
  <p *ngIf="currentElement" class="navbar-top-selected">
    {{
      currentElement[attributeShowed + "Parsed"]
        ? currentElement[attributeShowed + "Parsed"]
        : currentElement[attributeShowed]
    }}
    <sup *ngIf="currentElement?.virtual" class="navbar-top-selected-virtual"
      ><i class="fas fa-circle"></i><span>V</span></sup
    >
  </p>
  <div #dropdown class="navbar-top-dropdown">
    <!-- Listado de clientes, entidades o agrupaciones -->
    <div *ngIf="allowList" [routerLink]="[listLink]">
      <i class="fa fa-list fa-fw navbar-top-dropdown-icon"></i>
      {{ "list" | translate }}
    </div>
    <!-- Nuevo cliente, entidad o agrupación -->
    <div *ngIf="allowNew" [routerLink]="[newLink]">
      <i class="fa fa-plus fa-fw navbar-top-dropdown-icon"></i>
      {{ "new" | translate }}
    </div>
    <hr class="material-menu-separator" />
    <!-- Filtro de clientes, entidades o agrupaciones -->
    <div *ngIf="elementList" class="navbar-top-dropdown-filter">
      <form class="navbar-top-form" autocomplete="off">
        <input
          #elementFilter
          class="navbar-top-dropdown-input"
          type="text"
          name="elementFilter"
          autocomplete="off"
          (keyup)="dropdownFilter($event.target.value, type)"
          placeholder="{{ 'search' | translate }}..."
        />
      </form>
    </div>
    <hr class="material-menu-separator" />
    <!-- Selector de clientes, entidades o agrupaciones -->
    <ul *ngIf="elementList" class="navbar-top-dropdown-select">
      <li
        *ngFor="let element of elementList"
        [ngClass]="{ 'navbar-dropdown-highlighted': element[highlight] }"
        (click)="
          resetSearchBox(elementFilter, type);
          updateCurrentElement(element);
          toggleVisibility(false)
        "
      >
        <div
          *ngIf="element[highlight]"
          class="navbar-top-selected-virtual-icon"
        >
          <i class="fas fa-circle"></i><span>V</span>
        </div>
        <div class="navbar-top-dropdown-option">
          {{ element[attributeShowed] }}
          <span *ngIf="element[attributeShowed + 'Parsed']">{{
            element[attributeShowed + "Parsed"]
          }}</span>
          <span *ngIf="element.alias">{{ element.alias }}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
