import { Component, Input } from "@angular/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Interfaces
import { TableDataColumn } from "../../../../../modules/table-module/TableInterface.type";

@Component({
  selector: "app-control-historic-dialog",
  templateUrl: "./control-historic-dialog.component.html",
  styleUrls: ["./control-historic-dialog.component.scss"],
})
export class ControlHistoricDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  columns: TableDataColumn[] = [
    {
      title: "date",
      data: "initDateParsed",
      search: "initDateParsed",
      sort: "initDate",
      date: true,
      visible: true,
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "serial-number",
      data: "contadorNroSerie",
      search: "contadorNroSerie",
      sort: "contadorNroSerie",
      visible: true,
    },
    {
      title: "event",
      data: "eventDetails",
      search: "eventDetails",
      sort: "eventDetails",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(public SessionDataService: SessionDataService) {}
}
