// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Servicios propios
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { GatewayVersionParserService } from "../../GatewaVersionParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { Gateway } from "../../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { GatewayDetailDialogComponent } from "../../gateway-detail/gateway-detail-dialog/gateway-detail-dialog.component";

@Component({
  selector: "app-gateways-damaged",
  templateUrl: "./gateways-damaged.component.html",
  styleUrls: ["./gateways-damaged.component.scss"],
})
export class GatewaysDamagedComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;

  // Table
  tableMaxReg: number = 100;
  tableData: Gateway[];
  columns: (TableActionColumn | TableDataColumn | TableSelectColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "change-state",
          tooltip: "change-state",
          icon: "fas fa-arrows-alt-h",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "AMEI",
      data: "amei",
      search: "amei",
      sort: "amei",
      visible: true,
    },
    {
      title: "version-firmware",
      data: "fwVersion",
      search: "fwVersion",
      sort: "fwVersion",
      visible: true,
    },
    {
      title: "version-hardware",
      data: "hwVersion",
      search: "hwVersion",
      sort: "hwVersion",
      visible: true,
    },
    {
      title: "band",
      data: "bandaParsed",
      search: "bandaParsed",
      sort: "bandaParsed",
      visible: true,
    },
    {
      title: "micro",
      data: "microParsed",
      search: "microParsed",
      sort: "microParsed",
      visible: true,
    },
    {
      title: "gateway-options-mramqspi",
      data: "mramQspi",
      search: "mramQspi",
      sort: "mramQspi",
      boolean: true,
      alter: {
        condition: "mramQspi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramspi",
      data: "mramSpi",
      search: "mramSpi",
      sort: "mramSpi",
      boolean: true,
      alter: {
        condition: "mramSpi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-gps",
      data: "gps",
      search: "gps",
      sort: "gps",
      boolean: true,
      alter: {
        condition: "gps",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-port",
      data: "localPort",
      search: "localPort",
      sort: "localPort",
      boolean: true,
      alter: {
        condition: "localPort",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-debug",
      data: "debug",
      search: "debug",
      sort: "debug",
      boolean: true,
      alter: {
        condition: "debug",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "LTE",
      data: "lte",
      search: "lte",
      sort: "lte",
      boolean: true,
      alter: {
        condition: "lte",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      visible: true,
    },
    {
      title: "installation",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      visible: true,
    },
    {
      title: "last-file",
      data: "lastFileReceivedParsed",
      search: "lastFileReceivedParsed",
      sort: "lastFileReceived",
      date: true,
      visible: true,
    },
    {
      title: "signal",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-battery",
      data: "lastVbatParsed",
      search: "lastVbatParsed",
      sort: "lastVbat",
      numerical: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private GatewayVersionParserService: GatewayVersionParserService,
    private MaterialDialogService: MaterialDialogService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.GatewayController.getDamagedGateways(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          if (response["body"].length > 0) {
            let tableData: Gateway[] = response["body"];
            tableData.forEach((gateway: Gateway) => {
              // Versión
              gateway.bandaParsed = this.GatewayVersionParserService.getBand(
                gateway.banda
              );
              gateway.microParsed = this.GatewayVersionParserService.getMicro(
                gateway.micro
              );
              // Opciones
              if (gateway.options != null) {
                this.GatewayVersionParserService.setGatewayOptions(gateway);
              }
            });
            this.tableData = tableData;
          } else {
            this.tableData = [];
          }
        }
      }
    );
  }

  // Acciones de la tabla
  tableActions(action: string, gateway: Gateway): void {
    switch (action) {
      case "show-gateway":
        this.router.navigate(["/gateways/detalle/gateway/" + gateway.id]);
        break;
      case "change-state":
        this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
          action: "changeState",
          gateway: gateway,
        });
        break;
      default:
        break;
    }
  }
}
