// @angular
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
// Moment
import moment from "moment-timezone";
import moment_timezone from "moment-timezone";
// Servicios propios
import { OutputFilesControllerService } from "../../../../services/server/OutputFilesController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import {
  AqualiaJob,
  AqualiaJobAgrupation,
  AqualiaJobFile,
} from "../OutputFileInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
// Componentes
import { OutputFilesAqualiaDialogComponent } from "./output-files-aqualia-dialog/output-files-aqualia-dialog.component";

@Component({
  selector: "app-output-files-aqualia",
  templateUrl: "./output-files-aqualia.component.html",
  styleUrls: ["./output-files-aqualia.component.scss"],
})
export class OutputFilesAqualiaComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Jobs
  jobTableMaxReg: number = 20;
  jobList: AqualiaJob[];
  jobHighlightRow: TableHighlightRow[] = [
    { condition: "highlightError", color: "red" },
  ];
  jobColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "agrupations",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
    },
    {
      title: "group",
      data: "group",
      search: "group",
      sort: "group",
      visible: true,
    },
    {
      title: "name",
      data: "name",
      search: "name",
      sort: "name",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "file-sent",
      data: "fileSent",
      search: "fileSent",
      sort: "fileSent",
      visible: true,
    },
    {
      title: "readings-total-errors",
      data: "error",
      search: "error",
      sort: "error",
      visible: true,
    },
  ];

  // Agrupaciones
  jobAgrupationTableMaxReg: number = 20;
  jobAgrupationList: AqualiaJobAgrupation[];
  highlightAgrupationError: TableHighlightRow[] = [
    { condition: "highlightError", color: "red" },
    { condition: "highlightWarning", color: "yellow" },
  ];
  agrupationColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-sended-file",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "pending",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
    },
    {
      title: "entity",
      data: "entityDescription",
      search: "entityDescription",
      sort: "entityDescription",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupationDescription",
      search: "agrupationDescription",
      sort: "agrupationDescription",
      visible: true,
    },
    {
      title: "name",
      data: "nameFile",
      search: "nameFile",
      sort: "nameFile",
      visible: true,
    },
    {
      title: "sent",
      data: "sended",
      search: "sended",
      sort: "sended",
      alter: {
        condition: "sended",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "file-size",
      data: "sizeFileFtp",
      search: "sizeFileFtp",
      sort: "sizeFileFtp",
      visible: true,
    },
    {
      title: "error-text",
      data: "error",
      search: "error",
      sort: "error",
      visible: true,
    },
  ];
  filesLoading: boolean = false;
  fileToShowName: string;
  fileToShow: any;

  // Selector de fecha
  daterangePickerLang: any;
  daterangePickerRanges: any;
  dateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: moment().endOf("day"),
  };
  dateRangeSelected: { startDate: moment.Moment; endDate: moment.Moment };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private OutputFilesController: OutputFilesControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos
  getData(): void {
    let from = moment_timezone(this.dateRangeSelected.startDate)
      .tz(this.currentAgrupation.timezone, true)
      .startOf("day")
      .valueOf()
      .toString();
    let to = moment_timezone(this.dateRangeSelected.endDate)
      .tz(this.currentAgrupation.timezone, true)
      .endOf("day")
      .valueOf()
      .toString();
    let jobList = [];
    this.OutputFilesController.getAqualiaFiles(from, to).subscribe(
      (response) => {
        if (response["code"] == 0) {
          jobList = response["body"];
          jobList.forEach((job: AqualiaJob) => {
            this.setJobAgrupations(job);
            job.fileSent = job.agrupations?.length;
            job.extraTableData = {
              rowNumbers: true,
              data: job.agrupations,
              columns: this.agrupationColumns,
              highlightRow: this.highlightAgrupationError,
            };
            let agrupationsWithErrors: string[] = job.agrupations
              .filter(
                (agrupationWithError: AqualiaJobAgrupation) =>
                  agrupationWithError.error != null &&
                  agrupationWithError.error != ""
              )
              .map((agrupationWithError: AqualiaJobAgrupation) => {
                return agrupationWithError.agrupationDescription;
              });
            job.error = agrupationsWithErrors.length;
            job.highlightError = agrupationsWithErrors.length > 0;
          });
        }
        this.jobList = jobList;
      }
    );
  }

  // Acciones de la tabla
  jobAgrupationTableActions(
    action: string,
    parent: AqualiaJob & AqualiaJobAgrupation,
    child: AqualiaJobAgrupation & AqualiaJobFile
  ): void {
    switch (action) {
      case "show-sended-file":
        this.readFile(
          child.entity,
          child.agrupation,
          child.nameFile,
          child.sended
        );
        break;
      case "show-pending-file":
        this.readFile(
          parent.entity,
          parent.agrupation,
          child.nameFile,
          child.sended
        );
        break;
      default:
        break;
    }
  }

  // Detalles de job
  setJobAgrupations(job: AqualiaJob): void {
    if (job.agrupations) {
      job.agrupations.forEach((agrupation: AqualiaJobAgrupation) => {
        // Tabla de archivos
        if (agrupation.files) {
          agrupation.highlightWarning = agrupation.files.some(
            (file: AqualiaJobFile) => file.wasPending
          );
          agrupation.highlightError = false;
          let sendedFile = agrupation.files.find(
            (file: AqualiaJobFile) => !file.wasPending
          );
          if (sendedFile) {
            agrupation.nameFile = sendedFile.nameFile;
            agrupation.sizeFileFtp = sendedFile.sizeFileFtp;
            agrupation.sended = sendedFile.sended;
          }
          if (
            agrupation.files.some((file: AqualiaJobFile) => file.wasPending)
          ) {
            agrupation.extraTableData = {
              rowNumbers: true,
              data: agrupation.files.filter(
                (file: AqualiaJobFile) => file.wasPending
              ),
              columns: [
                {
                  title: "action",
                  data: [
                    {
                      name: "show-pending-file",
                      tooltip: "show-detail",
                      icon: "fas fa-eye",
                      visible: { attribute: null, rule: true },
                      disabled: false,
                    },
                  ],
                  visible: true,
                },
                {
                  title: "name",
                  data: "nameFile",
                  search: "nameFile",
                  sort: "nameFile",
                  visible: true,
                },
                {
                  title: "pending",
                  data: "wasPending",
                  search: "wasPending",
                  sort: "wasPending",
                  alter: {
                    condition: "wasPending",
                    skins: [
                      { rule: true, class: "fas fa-calendar pending-true" },
                      {
                        rule: false,
                        class: "fas fa-calendar-check pending-false",
                      },
                    ],
                  },
                  visible: true,
                },
                {
                  title: "sent",
                  data: "sended",
                  search: "sended",
                  sort: "sended",
                  alter: {
                    condition: "sended",
                    skins: [
                      { rule: true, class: "fas fa-check-circle" },
                      { rule: false, class: "fas fa-times-circle" },
                    ],
                  },
                  visible: true,
                },
                {
                  title: "file-size",
                  data: "sizeFileFtp",
                  search: "sizeFileFtp",
                  sort: "sizeFileFtp",
                  visible: true,
                },
              ],
            };
          }
        } else {
          agrupation.highlightError = true;
        }
        if (agrupation.error == "!") {
          agrupation.highlightWarning = true;
        }
      });
    }
    job.agrupations = job.agrupations
      .sort((a, b) => Number(b.highlightError) - Number(a.highlightError))
      .sort((a, b) => Number(b.highlightWarning) - Number(a.highlightWarning));
  }

  // Lectura de fichero
  readFile(
    entity: number,
    agrupation: number,
    fileName: string,
    sended: boolean
  ): void {
    this.OutputFilesController.readAqualiaFile({
      entity: entity,
      agrupation: agrupation,
      fileName: fileName,
      sended: sended,
    }).subscribe((response: JSON) => {
      this.fileToShowName = fileName;
      this.fileToShow = response;
      this.MaterialDialogService.openDialog(OutputFilesAqualiaDialogComponent, {
        fileToShowName: fileName,
        fileToShow: response,
      });
    });
  }
}
