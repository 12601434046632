<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "network-optimization" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tarea de optimización de entidades -->
        <div class="management-network-task">
          <app-material-select
            [fullWidth]="true"
            [hint]="'network-optimize-entity-advice' | translate"
            [filter]="true"
            [multiple]="true"
            [clearable]="true"
            [title]="'network-optimization-entities' | translate"
            [bindValue]="'id'"
            [bindLabel]="'entity'"
            [selected]="taskSelectedEntities"
            [data]="globalEntityList"
            (selectedOption)="updateTaskSelectedEntities($event)"
          ></app-material-select>
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="updateTaskEntities()"
          >
            <i class="fas fa-sync-alt"> </i>{{ "update" | translate }}
          </button>
        </div>

        <hr />

        <!-- Optimización manual de entidades/agrupaciones -->
        <div class="management-network-optimization">
          <!-- Selección de opción entidad/agrupación -->
          <div>
            <app-material-select
              [title]="'network-optimize-manual' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [selected]="'entity'"
              [data]="optimizationOptions"
              (selectedOption)="
                currentOptimization = $event; updateOptimizationList()
              "
            ></app-material-select>
            <!-- Selección entidad/agrupación -->
            <app-material-select
              class="management-network-optimization-select"
              [fullWidth]="true"
              [title]="
                currentOptimization.value == 'entity'
                  ? ('entity' | translate)
                  : ('groups' | translate)
              "
              [filter]="true"
              [groups]="selectGroups"
              [bindGroup]="groupsBindValue"
              [bindValue]="'id'"
              [bindLabel]="'name'"
              [data]="optimizationList"
              (selectedOption)="
                optimizationListSelected = $event; updateTableData()
              "
            ></app-material-select>
          </div>
          <!-- Optimizar -->
          <div class="management-network-optimize-buttons">
            <!-- Simulación -->
            <div class="management-network-simulation">
              <button
                mat-button
                class="mat-button-icon-text"
                (click)="!simulating ? simulateOptimization() : null"
                [disabled]="!tableData"
              >
                <i
                  [ngClass]="
                    simulating ? 'fas fa-spinner fa-pulse' : 'fas fa-eye'
                  "
                ></i
                >{{
                  simulating
                    ? ("management-network-simulating" | translate) + "..."
                    : ("management-network-simulation" | translate)
                }}
              </button>
            </div>
            <!-- Optimizar selección -->
            <button
              mat-button
              class="mat-button-icon-text"
              (click)="optimizeSelection()"
              [disabled]="!tableSelectedData || tableSelectedData.length == 0"
            >
              <i class="fas fa-rocket"></i
              >{{ "network-optimize-selection" | translate }}
            </button>
            <!-- Optimizar todo -->
            <button
              mat-button
              class="mat-button-icon-text"
              (click)="optimizeSelection(true)"
              [disabled]="!tableData || tableData.length == 0"
            >
              <i class="fas fa-rocket"></i
              >{{ "network-optimize-all" | translate }}
            </button>
          </div>
        </div>

        <!-- Tabla de selección de dispositivos -->
        <div class="management-network-table">
          <div *ngIf="updating" class="loading-spinner">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
          <!-- Tabla -->
          <app-table-controller
            *ngIf="tableData"
            [tableId]="'networkOptimization'"
            [columns]="columns"
            [rowNumbers]="true"
            [highlightRow]="tableHighlightRow"
            [legendAlwaysActive]="true"
            [maxReg]="100"
            (selectedDataUpdateFlag)="tableSelectedData = $event"
            [specialFilter]="true"
            (getExtraTableDataFlag)="
              $event.extraTableData.data ? null : getExtraTableData($event)
            "
            [data]="tableData"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
