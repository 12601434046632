import { Injectable } from "@angular/core";
import * as data from "../../../assets/clients/clients-parser.json";
import { Client } from "../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../interfaces/AgrupationGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class ClientParserService {
  clients: any = (data as any).default;

  constructor() {}

  parseClient(client: Client): void {
    if (data[client.clientName]) {
      client.entityList.forEach((entity: Entity) => {
        if (data[client.clientName][entity.entity]) {
          entity.entityParsed = data[client.clientName][entity.entity];
        }
        entity.agrupations.forEach((agrupation: Agrupation) => {
          if (data[client.clientName][agrupation.name]) {
            agrupation.nameParsed = data[client.clientName][agrupation.name];
          }
        });
      });
    }
  }
}
