import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  HostListener,
  AfterViewInit,
} from "@angular/core";
// Leaflet
import * as L from "leaflet";
import { tileLayer } from "leaflet";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-snake",
  templateUrl: "./snake.component.html",
  styleUrls: ["./snake.component.scss"],
})
export class SnakeComponent implements OnInit, OnDestroy, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tamaños y velocidad
  snakeBoardWidth: number = 400;
  snakeBoardHeight: number = 400;
  snakeSize: number = 40;
  snakeSpeed: number;
  startSpeed: number = 500;
  boardReady: boolean = false;

  // Puntos
  bytes: number = 0;
  bits: number = 0;

  // Reloj
  timerMinutes: number = 0;
  timerSeconds: number = 0;
  timerInterval: ReturnType<typeof setInterval>;
  gameStarted: boolean = false;

  // Tablero
  @ViewChild("snakeBoard") snakeBoard: ElementRef;

  // Serpiente
  food: number[] = [];
  @ViewChild("food") foodElement: ElementRef;
  badFood: number[][] = [];
  snakeHead: number[];
  @ViewChild("snakeHead") snakeHeadElement: ElementRef;
  snakeBody: number[][] = [];
  snakePositions: number[][] = [];
  snakeDirection: string = "up";
  snakeInterval: ReturnType<typeof setInterval>;
  @ViewChild("snakeContainer") snakeContainer: ElementRef;

  // Modal
  snakeModalHtml: string;

  // Mapa
  mapOptions: any;

  // Movimiento
  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: any) {
    event.preventDefault();
    switch (event.key) {
      case "ArrowUp":
        if (this.snakeDirection != "down") {
          this.snakeDirection = "up";
        }
        break;
      case "ArrowDown":
        if (this.snakeDirection != "up") {
          this.snakeDirection = "down";
        }
        break;
      case "ArrowRight":
        if (this.snakeDirection != "left") {
          this.snakeDirection = "right";
        }
        break;
      case "ArrowLeft":
        if (this.snakeDirection != "righy") {
          this.snakeDirection = "left";
        }
        break;
      default:
        break;
    }
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.SessionDataService.clearGameActive();
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.snakeModalHtml = this.translate.instant("snake-start-text");
    this.mapOptions = {
      center: [43.31882, -3.004561],
      zoomSnap: 0.25,
      zoom: 10,
      edgeBufferTiles: 5,
      attributionControl: true,
      preferCanvas: true,
      updateWhenIdle: true,
      updateWhenZooming: false,
      layers: tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

        {
          attribution:
            "Map data &copy; <a href='http://openstreetmap.org'>OpenStreetMap</a>",
          maxZoom: 18,
        }
      ),
    };
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.snakeBoardWidth =
      Math.floor((window.innerWidth * 0.6) / this.snakeSize) * this.snakeSize;
    this.snakeBoardHeight =
      Math.floor((window.innerHeight * 0.6) / this.snakeSize) * this.snakeSize;

    // Tamaño de tablero
    this.snakeContainer.nativeElement.style.setProperty(
      "--snake-board-width",
      this.snakeBoardWidth
    );
    this.snakeContainer.nativeElement.style.setProperty(
      "--snake-board-height",
      this.snakeBoardHeight
    );
    // Tamaño de elemento de serpiente
    this.snakeContainer.nativeElement.style.setProperty(
      "--snake-size",
      this.snakeSize
    );

    this.boardReady = true;
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
    clearInterval(this.snakeInterval);
    this.SessionDataService.clearGameActive();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Comienzo del juego
  startGame(): void {
    this.snakeModalHtml = null;
    this.bytes = 0;
    this.bits = 0;
    this.timerMinutes = 0;
    this.timerSeconds = 0;
    this.food = [];
    this.badFood = [];
    this.snakeSpeed = this.startSpeed;
    // Posición inicial
    this.snakeHead = [
      Math.round(this.snakeBoardWidth / (2 * this.snakeSize)),
      Math.round(this.snakeBoardHeight / (2 * this.snakeSize)),
    ];
    this.snakeBody = [];
    this.snakePositions = [];
    this.snakeDirection = "up";
    this.timerInterval = setInterval(() => this.updateTimer(), 1000);
    this.updateFoodPosition();
    this.snakeInterval = setInterval(
      () => this.updateSnakePosition(),
      this.snakeSpeed
    );
    this.gameStarted = true;
  }

  // Parar juego
  stopGame(): void {
    clearInterval(this.timerInterval);
    clearInterval(this.snakeInterval);
  }

  // Actualización de reloj
  updateTimer(): void {
    this.timerSeconds += 1;
    if (this.timerSeconds > 59) {
      this.timerMinutes++;
      this.timerSeconds = 0;
    }
    if (
      this.snakeBody.length > 4 &&
      this.timerSeconds % 2 &&
      Math.round(Math.random())
    ) {
      this.updateBadFoodPosition();
    }
  }

  // Comprobación de colisión
  checkCollision(): void {
    if (
      this.snakeHead[0] == this.food[0] &&
      this.snakeHead[1] == this.food[1]
    ) {
      this.addSnakeBody();
      this.bits++;
      if (this.bits == 8) {
        this.bits = 0;
        this.bytes++;
      }
      if (this.snakeSpeed > 100) {
        this.snakeSpeed -= 100;
        clearInterval(this.snakeInterval);
        this.snakeInterval = setInterval(
          () => this.updateSnakePosition(),
          this.snakeSpeed
        );
      }
      this.updateFoodPosition();
    }

    if (
      this.badFood.some(
        (element: number[]) =>
          this.snakeHead[0] == element[0] && this.snakeHead[1] == element[1]
      ) ||
      this.snakeBody.some(
        (element: number[]) =>
          this.snakeHead[0] == element[0] && this.snakeHead[1] == element[1]
      )
    ) {
      this.gameLost();
    }
  }

  // Añadir elemento a la serpiente
  addSnakeBody(): void {
    if (this.snakeBody.length > 0) {
      this.snakeBody.push([
        this.snakePositions[this.snakeBody.length - 1][0],
        this.snakePositions[this.snakeBody.length - 1][1],
        Math.round(Math.random()),
      ]);
    } else {
      this.snakeBody.push([
        this.snakePositions[0][0],
        this.snakePositions[0][1],
        Math.round(Math.random()),
      ]);
    }
  }

  // Actualización de la posición de la serpiente
  updateSnakePosition(): void {
    this.snakePositions.unshift([...this.snakeHead]);
    switch (this.snakeDirection) {
      case "up":
        if (
          this.snakeHead[1] * this.snakeSize <
          this.snakeBoardHeight - this.snakeSize
        ) {
          this.snakeHead[1] += 1;
        } else {
          this.gameLost();
        }
        break;
      case "down":
        if (this.snakeHead[1] > 0) {
          this.snakeHead[1] -= 1;
        } else {
          this.gameLost();
        }
        break;
      case "right":
        if (
          this.snakeHead[0] * this.snakeSize <
          this.snakeBoardWidth - this.snakeSize
        ) {
          this.snakeHead[0] += 1;
        } else {
          this.gameLost();
        }
        break;
      case "left":
        if (this.snakeHead[0] > 0) {
          this.snakeHead[0] -= 1;
        } else {
          this.gameLost();
        }
        break;
      default:
        break;
    }

    // Colisión
    this.checkCollision();

    // Actualización de posición
    this.updateSnakeElementPosition();
  }

  // Actualización de la posición de los elementos HTML
  updateSnakeElementPosition(): void {
    // Cabeza
    this.snakeHeadElement.nativeElement.style.setProperty(
      "--snake-head-left",
      this.snakeHead[0] * this.snakeSize
    );
    this.snakeHeadElement.nativeElement.style.setProperty(
      "--snake-head-bottom",
      this.snakeHead[1] * this.snakeSize
    );
    this.snakeHeadElement.nativeElement.classList.remove("hidden");

    // Cuerpo
    this.snakeBody.forEach((snakeBodyElement: number[], i) => {
      snakeBodyElement[0] = this.snakePositions[i][0];
      snakeBodyElement[1] = this.snakePositions[i][1];
      let htmlElement = document.getElementById("snake-body-" + i);
      if (htmlElement) {
        htmlElement.style.left = snakeBodyElement[0] * this.snakeSize + "px";
        htmlElement.style.bottom = snakeBodyElement[1] * this.snakeSize + "px";
        htmlElement.classList.remove("hidden");
      }
    });

    if (
      this.snakeBody.some(
        (position) =>
          position[0] == this.snakeHead[0] && position[1] == this.snakeHead[1]
      )
    ) {
      this.gameLost();
    }
  }

  // Actualización de la posición de la comida
  updateFoodPosition(): void {
    let newFoodPosition: number[];
    do {
      newFoodPosition = [
        Math.floor((Math.random() * this.snakeBoardWidth) / this.snakeSize),
        Math.floor((Math.random() * this.snakeBoardHeight) / this.snakeSize),
      ];
    } while (!this.checkFoodPosition(newFoodPosition));

    this.food = newFoodPosition;

    this.foodElement.nativeElement.style.setProperty(
      "--food-left",
      this.food[0] * this.snakeSize
    );
    this.foodElement.nativeElement.style.setProperty(
      "--food-bottom",
      this.food[1] * this.snakeSize
    );
    this.foodElement.nativeElement.classList.remove("hidden");
  }

  // Actualización de la posición de la comida mala
  updateBadFoodPosition(): void {
    let newBadFood: number[];
    do {
      newBadFood = [
        Math.floor((Math.random() * this.snakeBoardWidth) / this.snakeSize),
        Math.floor((Math.random() * this.snakeBoardHeight) / this.snakeSize),
      ];
    } while (!this.checkFoodPosition(newBadFood));

    this.badFood.push(newBadFood);

    setTimeout(() => {
      let htmlElement = document.getElementById(
        "bad-food-" + (this.badFood.length - 1)
      );
      if (htmlElement) {
        htmlElement.style.setProperty(
          "--food-left",
          String(newBadFood[0] * this.snakeSize)
        );
        htmlElement.style.setProperty(
          "--food-bottom",
          String(newBadFood[1] * this.snakeSize)
        );
        htmlElement.classList.remove("hidden");
      }
    }, 0);
  }

  // Comprobación de la posición de la comida
  checkFoodPosition(food: number[]): boolean {
    if (
      // Colisión con cabeza
      (this.snakeHead[0] == food[0] && this.snakeHead[1] == food[1]) ||
      // Colisión con cuerpo
      this.snakePositions.some(
        (position: number[]) => position[0] == food[0] && position[1] == food[1]
      ) ||
      // Colisión con comida mala
      this.badFood.some(
        (position: number[]) => position[0] == food[0] && position[1] == food[1]
      ) ||
      (food[0] == this.food[0] && food[1] == this.food[1])
    ) {
      return false;
    }
    return true;
  }

  // Juego perdido
  gameLost(): void {
    this.stopGame();
    this.snakeModalHtml =
      `<div class='snake-end-game'><span><b>` +
      this.translate.instant("snake-end-text") +
      `</b></span>
    <span><b>` +
      this.translate.instant("time") +
      `:</b> ` +
      (this.timerMinutes > 9 ? this.timerMinutes : "0" + this.timerMinutes) +
      `:` +
      (this.timerSeconds > 9 ? this.timerSeconds : "0" + this.timerSeconds) +
      `, <b>Bytes:</b> ` +
      this.bytes +
      `, <b>bits:</b> ` +
      this.bits +
      `</span></div>`;
  }
}
