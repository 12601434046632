// ANCHOR Usuario
export class User {
  // BackEnd
  public active?: boolean;
  public creationDate?: number;
  public email: string;
  public firebaseToken?: string;
  public id?: number;
  public isTlc: boolean;
  public language: string;
  public lastLogin?: number;
  public lastLoginIp?: string;
  public messageChannels: number;
  public name: string;
  public notifications: boolean;
  public password: string;
  public phoneNumber: number;
  public phoneNumber2?: number;
  public profile: number;
  public surname: string;
  public username: string;

  // FrontEnd
  public clientId?: number;
  public cupsList?: number[];
  public entityList?: UserEntity[];
  public entityListIds?: number[];
  public selectedEntities?: any;
  public selectedAgrupations?: any;
  public selectedClients?: number;
  public selectedCups?: any;
  public profileText?: string;

  constructor() {
    this.active = null;
    this.creationDate = null;
    this.email = null;
    this.firebaseToken = null;
    this.id = null;
    this.isTlc = null;
    this.language = null;
    this.lastLogin = null;
    this.lastLoginIp = null;
    this.messageChannels = 0;
    this.name = null;
    this.notifications = false;
    this.password = null;
    this.phoneNumber = null;
    this.phoneNumber2 = null;
    this.profile = null;
    this.surname = null;
    this.username = null;
  }
}

// ANCHOR Guardado de usuario
export interface UserSave {
  user: User;
  client: number;
  entities: number[];
  agrupations: number[];
  claveMaestraList?: number[];
}

// ANCHOR Entidad de usuario
export interface UserEntity {
  entityId: number;
  entityName: string;
  agrupationList: UserAgrupation[];
}

// ANCHOR Agrupación de usuario
export interface UserAgrupation {
  apiId: number;
  aquacityDomain: string;
  aqualiaFtpHour: string;
  descripcion: string;
  entity: number;
  ftpPath: string;
  gestionFicherosPath: string;
  id: number;
  ignoreLorawanFrames: boolean;
  mongoCollection: string;
  mongoCollectionCh: string;
  showAllEntity: boolean;
  timezone: string;
}

// ANCHOR Todas las agrupaciones de usuario
export interface UserAllAgrupation {
  id: number;
  descripcion: string;
  allAgrupation: boolean;
}

// ANCHOR CUPS de usuario
export interface UserCups {
  id: number;
  clave: string;
}

// ANCHOR Respuesta de importación de usuario
export interface UserResponse {
  // BackEnd
  responseCode: number;
  username: string;
  cupsErrorList: {
    userId: number;
    alreadyAssignedCups: UserResponseCups[];
  };

  // FrontEnd
  responseCodeText?: string;
  state?: boolean;
}

// ANCHOR Cups de la respuesta de importación de usuario
export interface UserResponseCups {
  username: string;
  claveMaestraId: number;
  claveMastra: string;
}

// ANCHOR Datos de usuario para la tabla de importación
export interface UserImportTableData {
  username: string;
  responseCodeText: string;
  errorCode: number;
  state: boolean;
  extraInfoData: string;
  showExtraInfo: boolean;
}
