<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>
          {{ "frames-logs" | translate }}:
          {{ meter != null ? meter.nroSerie : ("no-data" | translate) }}</b
        >
      </h5>

      <!-- Menú de opciones del panel -->
      <app-material-panel-menu
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>
    <div class="panel-body">
      <!-- Envío de trama -->
      <div class="frames-log-controls">
        <div
          *ngIf="sessionProfile == 'ARSON' && meterFrames"
          class="frames-logs-send-frame"
        >
          <mat-form-field appearance="standard">
            <mat-label>{{ "port" | translate }}</mat-label>
            <input
              matInput
              appHexInput
              maxlength="2"
              type="text"
              [(ngModel)]="frameToSend.port"
              placeholder="{{ 'port' | translate }}..."
              required
            />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ "Payload" | translate }}</mat-label>
            <input
              matInput
              appHexInput
              type="text"
              [(ngModel)]="frameToSend.payload"
              placeholder="Payload..."
              required
            />
          </mat-form-field>
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="sendFrame()"
            [disabled]="!frameToSend.port || !frameToSend.payload"
          >
            <i class="fas fa-satellite-dish"></i>
            {{ "frame-send" | translate }}
          </button>
        </div>

        <!-- Filtros de tramas repetidas -->
        <div class="frames-logs-filters">
          <button
            *ngIf="sessionProfile == 'ARSON' && meterFrames"
            mat-button
            class="mat-button-icon"
            (click)="relaunchFrames()"
          >
            <div class="filters-icon">
              <i class="fas fa-sync-alt"></i>
            </div>
            {{ "frames-relaunch" | translate }}
          </button>

          <!-- Resaltar tramas repetidas -->
          <button
            *ngIf="meterFrames"
            mat-button
            class="mat-button-icon"
            [ngClass]="{ inactive: !framesHighlighted }"
            (click)="
              framesHighlighted = !framesHighlighted;
              updateFramesFilter(framesHighlighted)
            "
            [disabled]="
              !originalMeterFrames || originalMeterFrames?.length == 0
            "
          >
            <div class="filters-icon">
              <i *ngIf="!framesHighlighted" class="fas fa-slash"></i>
              <i class="fas fa-eye"></i>
            </div>
            {{ "highlight-frames" | translate }}
          </button>

          <!-- Filtrado de tramas repetidas -->
          <button
            *ngIf="meterFrames"
            mat-button
            class="mat-button-icon"
            [ngClass]="{ inactive: !framesFiltered }"
            (click)="framesFiltered = !framesFiltered; updateFramesFilter()"
            [disabled]="
              !originalMeterFrames || originalMeterFrames?.length == 0
            "
          >
            <div class="filters-icon">
              <i *ngIf="!framesFiltered" class="fas fa-slash"></i>
              <i class="fas fa-filter"></i>
            </div>
            {{ "filter-frames" | translate }}
          </button>
        </div>
      </div>

      <!-- Tabla de gateways -->
      <app-table-controller
        [export]="true"
        [exportFileName]="exportFileName"
        [columns]="columns"
        [rowNumbers]="true"
        [orderBy]="orderBy"
        [maxReg]="tableMaxReg"
        (tableAction)="tableActions($event.action.name, $event.element)"
        [tableGlobalActions]="tableGlobalActions"
        (tableGlobalAction)="tableGlobalAction($event.title)"
        [highlightRow]="tableHighlightRow"
        [dateRangeSelector]="true"
        [initialDate]="dataInitialDate"
        (dataRequest)="getData($event.from, $event.to)"
        [specialFilter]="true"
        (selectedDataUpdateFlag)="tableSelectedData = $event"
        [data]="meterFrames"
      >
      </app-table-controller>
    </div>
  </div>
</section>
