<div class="material-tree">
  <div class="material-tree-parent-node" *ngFor="let parentNode of _nodes">
    <button
      mat-icon-button
      [disableRipple]="true"
      (click)="
        parentNode.expanded = !parentNode.expanded;
        checkAllExpanded();
        parentExpanded.emit()
      "
    >
      <mat-icon class="mat-icon-rtl-mirror">
        <i
          class="fas"
          [ngClass]="
            parentNode.expanded ? 'fa-chevron-down' : 'fa-chevron-right'
          "
        ></i>
      </mat-icon>
    </button>
    <mat-checkbox
      class="material-tree-checkbox"
      [(ngModel)]="parentNode.checked"
      [(indeterminate)]="parentNode.indeterminate"
      (change)="updateSelection(parentNode)"
      >{{ parentNode.text }}</mat-checkbox
    >
    <div
      class="material-tree-childs"
      [ngClass]="{ hidden: !parentNode.expanded }"
    >
      <div
        class="material-tree-child-node"
        *ngFor="let node of parentNode.children"
      >
        <button mat-icon-button disabled></button>
        <mat-checkbox
          class="material-tree-checkbox"
          [(ngModel)]="node.checked"
          (change)="updateSelection()"
          >{{ node.text }}</mat-checkbox
        >
      </div>
    </div>
  </div>
</div>
