/**
 * Decodes the given input for the Honeywell 868 HB-G3 Sigfox protocol.
 *
 * @param {any} input - The input to be decoded.
 *              fecha - Fecha de la trama en formato dd/mm/yyyy hh:mm:ss
 *
 * @returns {Object} An object with the following possible structures:
 * 1. On error:
 *   - { errors: string[]; data?: undefined; }
 *  2. On success:
 *   - { data: {}; errors?: undefined; }
 *
 * @example
 *   const result = honeywell_sigfox_decoder({bytes:payloadInHex,fecha:fechaString});
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class SigfoxDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  readonly METERKEYS = [0, 1, 1, 1, 1, 1, 10, 1, 10, 100, 1000, 100];

  readonly FLOW_PERSISTENCE_CODING = [
    "No persistence",
    "0 min < Duration < 5 min",
    "5 min < Duration < 15 min",
    "15 min < Duration < 60 min",
    "60 min < Duration < 3 h",
    "3 h < Duration < 6 h",
    "6 h < Duration < 12 h",
    "12 h < Duration < 24 h",
    "24 h < Duration < 2 days",
    "2 days < Duration < 4 days",
    "4 days < Duration < 8 daysn",
    "8 days < Duration < 15 days",
    "15 days < Duration < 30 days",
    "30 days < Duration < 90 days",
    "90 days < Duration < 180 days",
    "Duration > 180 days",
  ];

  readonly MACRO_ALARMAS = [
    "Nothing to report",
    "Past persistence during the period",
    "In progress persistence",
    "In progress impacting persistence",
  ];

  honeywell_sigfox_decoder(frmPayload, fecha) {
    if (frmPayload && fecha) {
      if (frmPayload.bytes) {
        var outputData = {};
        var outputErrors = [];
        var index = 0;
        let date = fecha.split(/\D/);
        let fechaTrama = new Date(
          date[2],
          date[1] - 1,
          date[0],
          date[3],
          date[4],
          date[5]
        ); /* Los valores son desde la hora en punto anterior a la trama*/
        // [dia, mes, anio, hora, minuto, seg] = fecha.split(/\D/);
        // let fechaTrama = new Date(anio, mes - 1, dia, hora, minuto, seg);
        switch (frmPayload.bytes[0] & 0x0f /*Tipo de trama*/) {
          case 0x0f /* DS51_11 */:
            return this.DS12C_decoder(frmPayload.bytes, fechaTrama);
          case 0x01 /* DS51_12 */:
            return this.DS12S_1_decoder(frmPayload.bytes, fechaTrama);
          case 0x02 /* DS51_13 */:
            return this.DS12S_2_decoder(frmPayload.bytes, fechaTrama);
          case 0x03 /* DS51_14 */:
            return this.DS12S_3_decoder(frmPayload.bytes, fechaTrama);
          case 0x04 /* DS51_15 */:
            return this.DS12S_4_decoder(frmPayload.bytes, fechaTrama);
          case 0x05 /* DS51_16 */:
            return this.DS12S_5_decoder(frmPayload.bytes, fechaTrama);
          case 0x06 /* DS51_17 */:
            return this.DS12S_6_decoder(frmPayload.bytes, fechaTrama);
          case 0x07 /* DS51_18 */:
            return this.DS12S_7_decoder(frmPayload.bytes, fechaTrama);
          case 0x00 /* DS51_0E */:
            return this.DS12S_Event_decoder(frmPayload.bytes, fechaTrama);
          default:
            return {
              errors: [
                "Tipo de trama no definido: 0x" +
                  (frmPayload.bytes[0] & 0x0f).toString(16).padStart(1, "0"),
              ],
            };
        }
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown Input."],
      };
    }
  }

  /*Parseo de las microalarmas*/
  DS51_MIAL_parse(MIAL, MAAL) {
    var microalarmas = {};
    if (MAAL & 0x02) {
      /*Meterology alarms*/
      if (MIAL[0] & 0x01) {
        microalarmas["b0"] = "Blocked meter";
      }
      if (MIAL[0] & 0x40) {
        microalarmas["b6"] = "Small size";
      }
      if (MIAL[0] & 0x80) {
        microalarmas["b7"] = "Large size";
      }
    }
    if (MAAL & 0x04) {
      /*System alarms*/
      if (MIAL[1] & 0x80) {
        microalarmas["b15"] = "Battery";
      }
      if (MIAL[2] & 0x01) {
        microalarmas["b16"] = "Clock updated";
      }
      if (MIAL[2] & 0x08) {
        microalarmas["b19"] = "Module reconfigured";
      }
      if (MIAL[2] & 0x40) {
        microalarmas["b22"] = "Noise defense: radio reception suspended";
      }
      if (MIAL[3] & 0x01) {
        microalarmas["b24"] = "Low temperature (radio reception suspension)";
      }
      if (MIAL[3] & 0x02) {
        microalarmas["b25"] = "Number of alarm cycle authorized reached";
      }
    }
    if (MAAL & 0x08) {
      /*Tamper alarms*/
      if (MIAL[3] & 0x08) {
        microalarmas["b27"] = "Reversed meter";
      }
      if (MIAL[3] & 0x10) {
        microalarmas["b29"] = "Magnetic tampered";
      }
      if (MIAL[3] & 0x20) {
        microalarmas["b29"] = "Module tampered (unmounted)";
      }
      if (MIAL[2] & 0x40) {
        microalarmas["b30"] = "Acquisition stage failure";
      }
    }
    if (MAAL & 0x10) {
      /*Water quality*/
      if (MIAL[4] & 0x01) {
        microalarmas["b32"] = "Backflow L2";
      }
    }
    return microalarmas;
  }

  /*Devuelve el valor correspondiente a un códugo EC1*/
  /* -1: overflow; -1: anomaly*/
  RestituyeEC1(EC1Code) {
    var valor = 0;

    const EC1 = [
      { codeIni: 0, codeMax: 10, valIni: 0, valMax: 10, valStep: 1 },
      { codeIni: 11, codeMax: 20, valIni: 12, valMax: 30, valStep: 2 },
      { codeIni: 21, codeMax: 54, valIni: 35, valMax: 200, valStep: 5 },
      { codeIni: 55, codeMax: 74, valIni: 210, valMax: 400, valStep: 10 },
      { codeIni: 75, codeMax: 94, valIni: 420, valMax: 800, valStep: 20 },
      { codeIni: 95, codeMax: 114, valIni: 840, valMax: 1600, valStep: 40 },
      { codeIni: 115, codeMax: 134, valIni: 1680, valMax: 3200, valStep: 80 },
      { codeIni: 135, codeMax: 154, valIni: 3360, valMax: 6400, valStep: 160 },
      { codeIni: 155, codeMax: 174, valIni: 6720, valMax: 12800, valStep: 320 },
      {
        codeIni: 175,
        codeMax: 194,
        valIni: 13440,
        valMax: 25600,
        valStep: 640,
      },
      {
        codeIni: 195,
        codeMax: 214,
        valIni: 26880,
        valMax: 51200,
        valStep: 1280,
      },
      {
        codeIni: 215,
        codeMax: 234,
        valIni: 53760,
        valMax: 102400,
        valStep: 2560,
      },
      {
        codeIni: 235,
        codeMax: 253,
        valIni: 107520,
        valMax: 199680,
        valStep: 5120,
      },
    ];

    if (EC1Code == 254) {
      valor = -1;
    } else if (EC1Code == 255) {
      valor = -2;
    } else {
      for (let i = 0; i < 13; i++) {
        if (EC1Code <= EC1[i].codeMax) {
          valor = EC1[i].valIni + EC1[i].valStep * (EC1Code - EC1[i].codeIni);
          break;
        }
      }
    }
    return valor;
  }

  /* Parseado DS12C*/
  DS12C_decoder(bytes, fechaTrama) {
    var sequenceNumber = (bytes[0] >> 4) & 0x0f;
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;
    var DS12_MIAL = bytes[2];
    var index00 = this.readU32Lsb(bytes, 3);
    var meterKey = (bytes[9] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_DFQ0 = bytes[9] & 0x0f;
    var DS12_QMIN = bytes[10];
    var DS12_QMAX = bytes[11];

    var valor00h = index00 * pesoPulso;

    fechaTrama.setHours(0);
    fechaTrama.setMinutes(0);
    fechaTrama.setSeconds(0);

    var outputData = {
      Tipo_Trama: "DS12C",
    };

    outputData[fechaTrama.toLocaleString("es-ES")] =
      valor00h.toLocaleString("es-ES") + " litros";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    if (DS12_MIAL & 0x0c) {
      outputData["Macro_MicroAlarms"] = {};
      if (DS12_MIAL & (1 << 2)) {
        outputData["Macro_MicroAlarms"]["Acquisition stage failure"] = true;
      }
      if (DS12_MIAL & (1 << 3)) {
        outputData["Macro_MicroAlarms"]["Module tampered (unmounted)"] = true;
      }
    }

    if (DS12_DFQ0) {
      outputData["Zero_Flow_Persistence"] =
        this.FLOW_PERSISTENCE_CODING[DS12_DFQ0];
    }
    if (DS12_QMIN == 254) {
      outputData["Min_Flow_Yesterday"] = "OVERFLOW";
    } else if (DS12_QMIN == 255) {
      outputData["Min_Flow_Yesterday"] = "ANOMALY";
    } else {
      outputData["Min_Flow_Yesterday"] =
        (this.RestituyeEC1(DS12_QMIN) * pesoPulso).toLocaleString("es-ES") +
        " litros/h";
    }
    if (DS12_QMAX == 254) {
      outputData["Max_Flow_Yesterday"] = "OVERFLOW";
    } else if (DS12_QMAX == 255) {
      outputData["Max_Flow_Yesterday"] = "ANOMALY";
    } else {
      outputData["Max_Flow_Yesterday"] =
        (this.RestituyeEC1(DS12_QMAX) * pesoPulso).toLocaleString("es-ES") +
        " litros/h";
    }
    return { data: outputData };
  }

  /* Parseado DS12S_1*/
  DS12S_1_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;

    var outputData = {
      Tipo_Trama: "DS12S_1",
    };

    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    return { data: outputData };
  }

  /* Parseado DS12S_2*/
  DS12S_2_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;
    var DS12_MIAL = bytes.slice(2, 2 + 5);
    var index00 = this.readU32Lsb(bytes, 7);
    var valor00h = index00 * pesoPulso;

    fechaTrama.setHours(0);
    fechaTrama.setMinutes(0);
    fechaTrama.setSeconds(0);

    var outputData = {
      Tipo_Trama: "DS12S_2",
    };

    outputData[fechaTrama.toLocaleString("es-ES")] =
      valor00h.toLocaleString("es-ES") + " litros";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    DS12_MIAL[0] &= 0xc1;
    DS12_MIAL[1] &= 0x80;
    DS12_MIAL[2] &= 0x49;
    DS12_MIAL[3] &= 0x7b;
    DS12_MIAL[4] &= 0x01;
    if (
      DS12_MIAL[0] |
      DS12_MIAL[1] |
      DS12_MIAL[2] |
      DS12_MIAL[3] |
      DS12_MIAL[4]
    ) {
      outputData["Micro_MicroAlarms"] = {};
      if (DS12_MIAL[0] & (1 << 0)) {
        outputData["Micro_MicroAlarms"]["Blocked meter"] = true;
      }
      if (DS12_MIAL[0] & (1 << 6)) {
        outputData["Micro_MicroAlarms"]["Overflow_Small_size"] = true;
      }
      if (DS12_MIAL[0] & (1 << 7)) {
        outputData["Micro_MicroAlarms"]["Overflow_Large_size"] = true;
      }
      if (DS12_MIAL[1] & (1 << (15 - 8))) {
        outputData["Micro_MicroAlarms"]["Battery"] = true;
      }
      if (DS12_MIAL[2] & (1 << (16 - 16))) {
        outputData["Micro_MicroAlarms"]["Clock_updated"] = true;
      }
      if (DS12_MIAL[2] & (1 << (19 - 16))) {
        outputData["Micro_MicroAlarms"]["Module_reconfigured"] = true;
      }
      if (DS12_MIAL[2] & (1 << (22 - 16))) {
        outputData["Micro_MicroAlarms"]["Battery"] = true;
      }
      if (DS12_MIAL[3] & (1 << (24 - 24))) {
        outputData["Micro_MicroAlarms"]["Low_temperature_Radio_suspension"] =
          true;
      }
      if (DS12_MIAL[3] & (1 << (25 - 24))) {
        outputData["Micro_MicroAlarms"][
          "Number_of_alarm_cycle_authorized_reached"
        ] = true;
      }
      if (DS12_MIAL[3] & (1 << (27 - 24))) {
        outputData["Micro_MicroAlarms"]["Reversed_meter"] = true;
      }
      if (DS12_MIAL[3] & (1 << (28 - 24))) {
        outputData["Micro_MicroAlarms"]["Magnetic_tamper"] = true;
      }
      if (DS12_MIAL[3] & (1 << (29 - 24))) {
        outputData["Micro_MicroAlarms"]["Module_tampered"] = true;
      }
      if (DS12_MIAL[3] & (1 << (30 - 24))) {
        outputData["Micro_MicroAlarms"]["Acquisition_stage_failure"] = true;
      }
      if (DS12_MIAL[4] & (1 << (32 - 32))) {
        outputData["Micro_MicroAlarms"]["Backflow_L2"] = true;
      }
    }
    return { data: outputData };
  }

  /* Parseado DS12S_3*/
  DS12S_3_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;
    var DS12_QMAXS = bytes[3];
    var DS12_DFQ = bytes[4] & 0x0f;

    var outputData = {
      Tipo_Trama: "DS12S_3",
    };
    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }

    if (DS12_QMAXS == 254) {
      outputData["Max_Flow_Week"] = "OVERFLOW";
    } else if (DS12_QMAXS == 255) {
      outputData["Max_Flow_Week"] = "ANOMALY";
    } else {
      outputData["Max_Flow_Week"] =
        (this.RestituyeEC1(DS12_QMAXS) * pesoPulso).toLocaleString("es-ES") +
        " litros/h";
    }
    if (DS12_DFQ) {
      outputData["Flow>0_Persistence_Yesterday"] =
        this.FLOW_PERSISTENCE_CODING[DS12_DFQ];
    }
    return { data: outputData };
  }

  /* Parseado DS12S_4*/
  DS12S_4_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;

    var outputData = {
      Tipo_Trama: "DS12S_4",
    };
    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    return { data: outputData };
  }

  /* Parseado DS12S_5*/
  DS12S_5_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;

    var outputData = {
      Tipo_Trama: "DS12S_5",
    };
    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    return { data: outputData };
  }

  /* Parseado DS12S_6*/
  DS12S_6_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;
    var DS12_RNAS = bytes[3];
    var DS12_RVCS = bytes[4];

    var outputData = {
      Tipo_Trama: "DS12S_6",
    };
    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    if (DS12_RNAS == 254) {
      outputData["Number_Alternations_Week"] = "OVERFLOW";
    } else if (DS12_RNAS == 255) {
      outputData["Number_Alternations_Week"] = "ANOMALY";
    } else {
      outputData["Number_Flow_Alternations_Week"] =
        this.RestituyeEC1(DS12_RNAS).toLocaleString("es-ES");
    }
    if (DS12_RVCS == 254) {
      outputData["Cumulated_Backflow_Volume_Week"] = "OVERFLOW";
    } else if (DS12_RVCS == 255) {
      outputData["Cumulated_Backflow_Volume_Week"] = "ANOMALY";
    } else {
      outputData["Cumulated_Backflow_Volume_Week"] = (
        this.RestituyeEC1(DS12_RVCS) * pesoPulso
      ).toLocaleString("es-ES");
    }

    return { data: outputData };
  }

  /* Parseado DS12S_7*/
  DS12S_7_decoder(bytes, fechaTrama) {
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_MAAL = (bytes[1] & 0x60) >> 5;
    var DS12_DAT = this.readU32Lsb(bytes, 3);
    var referenceEpoch = new Date(
      2012,
      0,
      1,
      0,
      0,
      0
    ); /* El timestamp del contador está referido a 1-1-2012 00:00:00 UTC*/
    var txTimestamp = new Date(DS12_DAT * 1000 + referenceEpoch.getTime());

    var outputData = {
      Tipo_Trama: "DS12S_7",
    };
    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";

    if (DS12_MAAL) {
      outputData["Macro_Alarms"] = this.MACRO_ALARMAS[DS12_MAAL];
    }
    outputData["Date_Time"] = txTimestamp.toLocaleString("es-ES") + " UTC";
    return { data: outputData };
  }

  /* Parseado DS12S_Event*/
  DS12S_Event_decoder(bytes, fechaTrama) {
    var sequenceNumber = (bytes[0] >> 4) & 0x0f;
    var DS12_ALC = bytes[1];
    var DS12_MIAL = bytes.slice(2, 2 + 5);
    var meterKey = (bytes[7] >> 4) & 0x0f;
    var pesoPulso = this.METERKEYS[meterKey];
    var DS12_RVC = bytes[8];
    var DS12_DFQ2 = bytes[10];

    var outputData = {
      Tipo_Trama: "DS12_EVENT",
    };
    outputData["Alarm_cause"] = {};
    if (DS12_ALC & (1 << 2)) {
      outputData["Alarm_cause"]["Module_tampered"] = true;
    }
    if (DS12_ALC & (1 << 3)) {
      outputData["Alarm_cause"]["Backflow_L2"] = true;
    }
    if (DS12_ALC & (1 << 4)) {
      outputData["Alarm_cause"]["Flow_persistence_in_progress"] = true;
    }
    if (DS12_ALC & (1 << 5)) {
      outputData["Alarm_cause"]["Stop_persistence_in_progress"] = true;
    }

    DS12_MIAL[0] &= 0xc1;
    DS12_MIAL[1] &= 0x80;
    DS12_MIAL[2] &= 0x49;
    DS12_MIAL[3] &= 0x7b;
    DS12_MIAL[4] &= 0x01;
    if (
      DS12_MIAL[0] |
      DS12_MIAL[1] |
      DS12_MIAL[2] |
      DS12_MIAL[3] |
      DS12_MIAL[4]
    ) {
      outputData["Micro_MicroAlarms"] = {};
      if (DS12_MIAL[0] & (1 << 0)) {
        outputData["Micro_MicroAlarms"]["Blocked meter"] = true;
      }
      if (DS12_MIAL[0] & (1 << 6)) {
        outputData["Micro_MicroAlarms"]["Overflow_Small_size"] = true;
      }
      if (DS12_MIAL[0] & (1 << 7)) {
        outputData["Micro_MicroAlarms"]["Overflow_Large_size"] = true;
      }
      if (DS12_MIAL[1] & (1 << (15 - 8))) {
        outputData["Micro_MicroAlarms"]["Battery"] = true;
      }
      if (DS12_MIAL[2] & (1 << (16 - 16))) {
        outputData["Micro_MicroAlarms"]["Clock_updated"] = true;
      }
      if (DS12_MIAL[2] & (1 << (19 - 16))) {
        outputData["Micro_MicroAlarms"]["Module_reconfigured"] = true;
      }
      if (DS12_MIAL[2] & (1 << (22 - 16))) {
        outputData["Micro_MicroAlarms"]["Battery"] = true;
      }
      if (DS12_MIAL[3] & (1 << (24 - 24))) {
        outputData["Micro_MicroAlarms"]["Low_temperature_Radio_suspension"] =
          true;
      }
      if (DS12_MIAL[3] & (1 << (25 - 24))) {
        outputData["Micro_MicroAlarms"][
          "Number_of_alarm_cycle_authorized_reached"
        ] = true;
      }
      if (DS12_MIAL[3] & (1 << (27 - 24))) {
        outputData["Micro_MicroAlarms"]["Reversed_meter"] = true;
      }
      if (DS12_MIAL[3] & (1 << (28 - 24))) {
        outputData["Micro_MicroAlarms"]["Magnetic_tamper"] = true;
      }
      if (DS12_MIAL[3] & (1 << (29 - 24))) {
        outputData["Micro_MicroAlarms"]["Module_tampered"] = true;
      }
      if (DS12_MIAL[3] & (1 << (30 - 24))) {
        outputData["Micro_MicroAlarms"]["Acquisition_stage_failure"] = true;
      }
      if (DS12_MIAL[4] & (1 << (32 - 32))) {
        outputData["Micro_MicroAlarms"]["Backflow_L2"] = true;
      }
    }
    outputData["Peso_Pulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";
    if (DS12_RVC == 254) {
      outputData["Cumulated_Backflow"] = "OVERFLOW";
    } else if (DS12_RVC == 255) {
      outputData["Cumulated_Backflow"] = "ANOMALY";
    } else {
      outputData["Cumulated_Backflow"] = (
        this.RestituyeEC1(DS12_RVC) * pesoPulso
      ).toLocaleString("es-ES");
    }
    if (DS12_DFQ2) {
      outputData["Flow>0_Persistence"] =
        this.FLOW_PERSISTENCE_CODING[DS12_DFQ2];
    }
    return { data: outputData };
  }

  /* Helper Methods */
  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  sigfox_Descriptor(frmPayload) {
    var frameBytes = this.SepemDecoderService.hexToBytes(frmPayload);
    if (frmPayload && frameBytes) {
      switch (frameBytes[0] & 0x0f /*Tipo de trama*/) {
        case 0x0f:
          return "DS12C";
        case 0x01:
          return "DS12S_1";
        case 0x02:
          return "DS12S_2";
        case 0x03:
          return "DS12S_3";
        case 0x04:
          return "DS12S_4";
        case 0x05:
          return "DS12S_5";
        case 0x06:
          return "DS12S_6";
        case 0x07:
          return "DS12S_7";
        case 0x00:
          return "DS12S_Event";
        default:
          return (
            "UNDEFINED 0x" +
            (frameBytes[0] & 0x0f).toString(16).padStart(1, "0")
          );
      }
    } else {
      return "";
    }
  }
}
