<!-- Gráfica de consumo -->
<div class="panel panel-default">
  <div class="panel-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>
    <h5>
      <b>{{ "sensor-data" | translate }}</b>
    </h5>
  </div>

  <div class="panel-body meter-detail-graph">
    <!-- Gráfica -->
    <app-graph-controller
      [highchartsOptions]="highchartsOptions"
      [data]="chartOptions"
      [chartConstructor]="chartConstructor"
      [dateRange]="defaultDateRange"
      (dataRequest)="loadGraphData($event.from, $event.to)"
    >
    </app-graph-controller>
  </div>
</div>
