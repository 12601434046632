import { Injectable } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { DeviceTypeService } from "../../../services/shared/DeviceTypeService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class MapDeviceFilterService {
  constructor(
    private DeviceTypeService: DeviceTypeService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Arrays de contadores
  /***************************************************************************/

  // Comprobación de los contadores con posición válida
  checkMeters(data: any[], bounds?: any[]): any {
    let goodLocationDevices: any[] = [];
    let wrongLocationDevices: any[] = [];

    // Guardado del array de contadores original
    data?.map((element: any) => {
      if (element.latitud && element.longitud) {
        element.latitude = element.latitud;
        element.longitude = element.longitud;
      }
    });
    // Filtrado de los contadores sin coordenadas de posición
    data?.forEach((element: any) => {
      if (
        element.latitude != null &&
        element.longitude != null &&
        Math.abs(element.latitude) <= 90 &&
        Math.abs(element.longitude) <= 180
      ) {
        goodLocationDevices.push(element);
      } else {
        wrongLocationDevices.push(element);
      }
    });

    // Carga de mapa tras desactivación de dispositivo
    let deviceDeactivated =
      this.SessionDataService.getCurrentComponentData()?.deactivation;

    // Aviso de dispositivos sin localización
    if (wrongLocationDevices.length > 0 && !deviceDeactivated) {
      let wrongLocationInfo = wrongLocationDevices
        .map((device: any) => {
          return device.nroSerie;
        })
        .join(", ");
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("wrong-location-some"),
        null,
        null,
        null,
        "<div class='map-device-location-error'>" + wrongLocationInfo + "<div>"
      );
    }

    // Limpieza del flag de desactivación
    if (deviceDeactivated) {
      this.SessionDataService.clearComponentData();
    }

    data = goodLocationDevices;

    // Guardado de coordenadas de los contadores para calcular la posición inicial del mapa
    if (bounds && data?.length > 0) {
      data.map((element) => {
        bounds.push([element.latitude, element.longitude]);
      });
    }
    // Guardado del array de contadores original con posiciones válidas
    return [...data];
  }

  // Filtrado de contadores para cada capa
  deviceFilter(devices: any[], layer: string): any[] {
    return devices?.filter((device: any) => {
      let deviceTypeByMask: string = this.DeviceTypeService.getDeviceTypeByMask(
        device.tipo,
        device.metrologyType,
        device.fabricante ? device.fabricante : device.idFabricante
      );
      let hasGateway: boolean =
        device.unidadVentaGw != null || device.gateway != null;
      return (
        // ANCHOR Contadores externos sin comunicación
        (layer == "NO_LORA_NO_COM" && deviceTypeByMask == "NO_LORA_NO_COM") ||
        // ANCHOR Contadores con comunicación
        (layer == "OK" &&
          (deviceTypeByMask == "LW" || deviceTypeByMask == "LW_MBUS") &&
          device.comunica &&
          hasGateway) ||
        // ANCHOR Contadores no asignados
        (layer == "NO_ASIGNADO" &&
          (deviceTypeByMask == "LW" || deviceTypeByMask == "LW_MBUS") &&
          !hasGateway) ||
        // ANCHOR Contadores sin comunicación
        (layer == "NO_COMUNICA" &&
          (deviceTypeByMask == "LW" || deviceTypeByMask == "LW_MBUS") &&
          !device.comunica &&
          hasGateway) ||
        // ANCHOR Concentradores con comunicación
        (layer == "CONCENTRATOR_OK" &&
          (deviceTypeByMask == "LW_MBUS_CON" ||
            deviceTypeByMask == "LW_UNE_CON") &&
          device.comunica &&
          hasGateway) ||
        // ANCHOR Concentradores no asignados
        (layer == "CONCENTRATOR_NO_ASIGNADO" &&
          (deviceTypeByMask == "LW_MBUS_CON" ||
            deviceTypeByMask == "LW_UNE_CON") &&
          !hasGateway) ||
        // ANCHOR Concentradores sin comunicación
        (layer == "CONCENTRATOR_NO_COMUNICA" &&
          (deviceTypeByMask == "LW_MBUS_CON" ||
            deviceTypeByMask == "LW_UNE_CON") &&
          !device.comunica &&
          hasGateway) ||
        // ANCHOR Contadores no LoraWAN
        (layer == "NO_LORA" &&
          (deviceTypeByMask == "EXTERNO" ||
            deviceTypeByMask == "ERM" ||
            deviceTypeByMask == "EK280" ||
            deviceTypeByMask == "OWASYS" ||
            deviceTypeByMask == "PLUM" ||
            deviceTypeByMask == "API")) ||
        // ANCHOR Contadores MBus comunica
        (layer == "MBUS_COMUNICA" &&
          deviceTypeByMask == "MBUS" &&
          device.comunica) ||
        // ANCHOR Contadores MBus fijados sin comunicación
        (layer == "MBUS_MAIN_NO_COMUNICA" &&
          deviceTypeByMask == "MBUS" &&
          device.main &&
          !device.comunica) ||
        // ANCHOR Contadores MBus no comunica
        (layer == "MBUS" &&
          deviceTypeByMask == "MBUS" &&
          !device.main &&
          !device.comunica) ||
        // ANCHOR Contadores Wavenis
        (layer == "WAVENIS" && deviceTypeByMask == "WAVENIS") ||
        // ANCHOR Contadores UNE con comunicación
        (layer == "UNE_OK" && deviceTypeByMask == "UNE" && device.comunica) ||
        // ANCHOR Contadores UNE sin comunicación
        (layer == "UNE_NO_COMUNICA" &&
          deviceTypeByMask == "UNE" &&
          !device.comunica) ||
        // ANCHOR Contadores con cobertura
        (layer == "COVERAGE_OK" && device.locationList != null) ||
        // ANCHOR Contadores sin cobertura
        (layer == "COVERAGE_ERROR" && device.locationList == null) ||
        // ANCHOR Contadores padre de balance
        (layer == "FATHER" && device.meterType == "FATHER") ||
        // ANCHOR Contadores hijo de balance
        (layer == "CHILD" && device.meterType == "CHILD") ||
        // ANCHOR Contadores de la lista de dispositivos
        (layer == "METER_LIST" &&
          device.meterType != "FATHER" &&
          device.meterType != "CHILD") ||
        // ANCHOR Contadores de la lista de alarmas
        (layer == "ALARMS_METER_LIST" && !device.newAlarm) ||
        (layer == "ALARMS_METER_LIST_NEW" && device.newAlarm) ||
        // ANCHOR Contadores principales de gateway
        (layer == "GATEWAY_DETAIL_MAIN_METER" && device.main) ||
        // ANCHOR Contadores redundantes de gateway
        (layer == "GATEWAY_DETAIL_REDUNDANT_METER" &&
          !device.main &&
          !device.assignable) ||
        // ANCHOR Contadores de la lista de dispositivos seleccionables
        (layer == "GATEWAY_DETAIL_ASSIGNABLE_METER" && device.assignable) ||
        // ANCHOR Contadores pendientes de sustitución
        (layer == "SUBSTITUTIONS_PENDING" && device.pending) ||
        // ANCHOR Contadores sustituidos
        (layer == "SUBSTITUTIONS_REPLACED" && !device.pending) ||
        // ANCHOR Dispositivos desconocidos
        (layer == "UNKNOWN_DEVICE" && !device.concentrator) ||
        // ANCHOR Concentradores MBUS
        (layer == "MBUS_CONCENTRATOR" && device.concentrator) ||
        // ANCHOR Contador de mapa detalle
        layer == "METER_LOCATION_METER" ||
        // ANCHOR Sensor de fuga
        (layer == "LEAK_SENSOR" && !device.filter) ||
        // ANCHOR Sensor de fuga: Por encima de filtro
        (layer == "LEAK_SENSOR_OVER" && device.filter == "over") ||
        // ANCHOR Sensor de fuga: Por debajo de filtro
        (layer == "LEAK_SENSOR_BELOW" && device.filter == "below") ||
        // ANCHOR Sensor de fuga: Sin dato de filtro
        (layer == "LEAK_SENSOR_NO_DATA" && device.filter == "noData") ||
        // ANCHOR NBIOT
        (layer == "NBIOT" && deviceTypeByMask == "NBIOT") ||
        // ANCHOR TXN
        (layer == "TXN" && deviceTypeByMask == "TXN")
      );
    });
  }
}
