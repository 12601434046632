<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "meters-info" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <div class="col-md-12">
          <!-- Tabla -->
          <app-table-controller
            [tableId]="'dataManagementInfo'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [maxReg]="maxReg"
            [cupsFilters]="filters"
            [specialFilter]="true"
            [data]="tableData"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
