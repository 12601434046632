// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { ManufacturerService } from "../../../../../../../services/shared/ManufacturerService.service";
import { RouteCheckService } from "../../../../../../../services/shared/RouteCheckService.service";
import { MaterialDialogService } from "../../../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componente
import { AlarmsListComponent } from "../../../../../alarms/alarms-configuration/alarms-table/alarms-table.component";
import { AlarmsDialogComponent } from "../../../../../alarms/alarms-configuration/alarms-dialog/alarms-dialog.component";
import {
  DeviceAlarm,
  AlarmConfiguredDevice,
  DeviceAlarmChecks,
  DeviceAlarmUpdate,
} from "../../../../../alarms/AlarmInterface.type";
import { AlarmsCustomPanelComponent } from "../../../../../alarms/alarms-custom-config/alarms-custom-panel/alarms-custom-panel.component";

@Component({
  selector: "app-alarmconfiguration",
  templateUrl: "./meter-alarms-configuration.component.html",
  styleUrls: ["./meter-alarms-configuration.component.scss"],
})
export class AlarmsMeterConfigurationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionLanguage: string;

  // Alarmas
  meter: string;
  meterId: number;
  devicesList: DeviceAlarm[];
  devicesNotification: boolean = true;
  configuredDevices: AlarmConfiguredDevice[];
  alarmsType: string = "agrupation";
  @ViewChild(AlarmsListComponent) alarmsList: AlarmsListComponent;
  saveButtonTittle: string = this.translate.instant("save");
  saveButtonIcon: string = "fas fa-save";

  /***************************************************************************/
  // ANCHOR Construtor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private ToastService: ToastService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit() {
    // Carga de valores iniciales
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy() {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent() {
    this.meter = history.state.data;
    this.meterId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getData();
  }

  // Obtención de la lista de alarmas
  getData() {
    this.AlarmController.getMeterAlarms(this.meterId).subscribe((response) => {
      if (response["code"] == 0 && response["body"] != null) {
        let devicesList: DeviceAlarm[] = response["body"]["allMeterAlarms"];

        devicesList.forEach((device: DeviceAlarm) => {
          let manufacturer: { manufacturerText: string; deviceText: string } =
            this.ManufacturerService.getManufacturer(
              device?.fabricante?.toString(),
              device?.devType?.toString(),
              this.sessionLanguage
            );
          device["manufacturer"] =
            manufacturer.manufacturerText +
            (manufacturer.deviceText ? " - " + manufacturer.deviceText : "");
          device["configured"] = false;

          // Creación del campo checks asociado a los códigos de alarma del dispositivo
          device["checks"] = Object.keys(device.alarmList).map(function (key) {
            return {
              id: device.alarmList[key],
              check: true,
              notification: true,
            };
          });

          // Comprobación de las alarmas de agrupación
          device.checks.forEach((alarm: DeviceAlarmChecks) => {
            response["body"]["disabledList"].forEach(
              (alarmDisabled: number) => {
                if (alarm.id == alarmDisabled) {
                  alarm.check = false;
                }
              }
            );
          });

          // Comprobación de las notificaciones de agrupación
          device.checks.forEach((alarm: DeviceAlarmChecks) => {
            response["body"]["disabledNotificationList"].forEach(
              (notification: number) => {
                if (alarm.id == notification) {
                  alarm.notification = false;
                }
              }
            );
          });

          device.allAlarmsCheck = !device.checks.find(
            (alarm: DeviceAlarmChecks) => !alarm.check
          );
          device.allNotificationsCheck = !device.checks.find(
            (alarm: DeviceAlarmChecks) => !alarm.notification
          );
        });

        this.devicesList = [
          ...devicesList.sort((a, b) =>
            a.manufacturer.localeCompare(b.manufacturer)
          ),
        ];
      }
    });
  }

  // Mostrar modal de elementos configurados
  showModal(device: DeviceAlarm) {
    this.MaterialDialogService.openDialog(AlarmsDialogComponent, {
      configuredDevices: this.configuredDevices,
      device: device,
    });
  }

  // Guardar cambios
  saveAlarms() {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("change-alarms")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let updatedDevicesList: DeviceAlarm[] =
          this.alarmsList.getUpdatedDevicesList();
        let disabledAlarms: number[] = [];
        let disabledNotifications: number[] = [];

        updatedDevicesList.map((device: DeviceAlarm) =>
          device.checks.map((alarm: DeviceAlarmChecks) => {
            if (!alarm.check) {
              disabledAlarms.push(alarm.id);
            }
            if (!alarm.notification) {
              disabledNotifications.push(alarm.id);
            }
          })
        );

        let data: DeviceAlarmUpdate = {
          meterId: this.meterId,
          disabledList: disabledAlarms,
          disabledNotificationList: disabledNotifications,
        };

        this.AlarmController.updateAlarmsMeter(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("change-alarms-sucessfull")
            );
          }
        });

        // Alarmas personalizadas
        this.alarmsList.saveCustomAlarms();
      }
    });
  }
}
