// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DataAnalysisControllerService } from "../../../../services/server/DataAnalysisController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MapSizeService } from "../../../../modules/map-module/map-services/MapSizeService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { DomControllerService } from "../../../../services/shared/DomControllerService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { HeatMapConsumption } from "../DataAnalysisInterface.type";

@Component({
  selector: "app-advanced",
  templateUrl: "./data-analysis-advanced-analytics.component.html",
  styleUrls: ["./data-analysis-advanced-analytics.component.scss"],
})
export class AdvancedAnalyticsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Mapa
  consumptionList: HeatMapConsumption[];
  originalMapData: number[][];
  mapData: number[][];
  mapHeight: number;
  mapType: string = "heatMap";
  mapPadding: number = 150;
  bounds: number[][] = [];
  timestampParsed: string;
  radius: number = 25;
  consumptionMaxValue: number = 0;
  consumptionRange: number = 0;
  consumptionFilter: boolean = true;
  @ViewChild("advancedAnalyticsPanel") advancedAnalyticsPanel: ElementRef;
  @ViewChild("mapContainer") mapContainer: ElementRef;

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.mapHeight = this.mapSize.calcMapHeight(
      this.advancedAnalyticsPanel,
      this.mapContainer,
      this.mapPadding
    );
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    private DomControllerService: DomControllerService,
    private mapSize: MapSizeService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación e idioma
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.DomControllerService.elementReady("#advanced-analytics-filters").then(
      () =>
        (this.mapHeight = this.mapSize.calcMapHeight(
          this.advancedAnalyticsPanel,
          this.mapContainer,
          this.mapPadding
        ))
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos del mapa
  getData(): void {
    this.DataAnalysisController.heatmap(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let mapData: number[][] = [];
          let consumptionList: HeatMapConsumption[] =
            response["body"]["consumptionList"];
          if (consumptionList != null) {
            this.timestampParsed = this.DateParserService.parseDate(
              response["body"]["consumptionCalculatedTime"],
              "MM/YYYY"
            );
            consumptionList.forEach((consumption: HeatMapConsumption) => {
              if (
                consumption.latitude != null &&
                consumption.longitude != null
              ) {
                if (
                  Math.abs(consumption.latitude) <= 90 &&
                  Math.abs(consumption.longitude) <= 180
                ) {
                  this.bounds.push([
                    consumption.latitude,
                    consumption.longitude,
                  ]);
                }
                mapData.push([
                  consumption.latitude,
                  consumption.longitude,
                  consumption.consumption,
                ]);
              }
            });
            this.consumptionMaxValue = Math.max.apply(
              Math,
              mapData.map(function (o) {
                return o[2];
              })
            );
            this.consumptionRange = this.consumptionMaxValue;
            this.consumptionList = consumptionList;
            this.originalMapData = [...mapData];
            this.mapData = mapData;
          } else {
            this.mapData = [];
            this.ToastService.fireToast("info", this.translate.instant("no-values"));
          }
        }
      }
    );
  }

  // Actualización de los datos del mapa
  updateMapData(): void {
    this.mapData = [];
    if (this.consumptionFilter) {
      this.mapData = this.originalMapData.filter((consumption: number[]) => {
        if (consumption[2] <= Math.round(this.consumptionRange)) {
          return true;
        }
      });
    } else {
      this.mapData = [...this.originalMapData];
    }
  }

  // Reseteo del filtro al cambiar de opción
  resetFilter(consumptionFilter: boolean): void {
    this.consumptionFilter = consumptionFilter;
    this.mapData = [...this.originalMapData];
    this.consumptionRange = this.consumptionMaxValue;

    setTimeout(() => this.updateMapData(), 0);
  }
}
