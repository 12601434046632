<div *ngIf="cards?.length > 0; else elseBlock" class="cards-panel">
  <!-- ANCHOR Menu de las tarjetas -->
  <div class="cards-menu">
    <button
      mat-icon-button
      class="card-menu-icon"
      [matMenuTriggerFor]="cardMenu"
    >
      <mat-icon><i class="fas fa-ellipsis-v"></i></mat-icon>
    </button>

    <mat-menu #cardMenu="matMenu">
      <!-- ANCHOR Visibilidad de gráficos-->
      <button *ngIf="graphVisibility" mat-menu-item (click)="hideGraphs()">
        <mat-icon class="card-mat-icon">
          <i [ngClass]="graphsHidden ? 'fas fa-eye' : 'fas fa-eye-slash'"></i
        ></mat-icon>
        <span>{{ "show-hide-graphs" | translate }}</span>
      </button>

      <!-- ANCHOR Visibilidad -->
      <button
        mat-menu-item
        [ngClass]="{ 'option-highlighted-green': visibilitySelection }"
        (click)="
          visibilitySelection = !visibilitySelection;
          orderSelection = false;
          visibilityUpdated()
        "
      >
        <mat-icon class="card-mat-icon"><i class="fas fa-eye"></i></mat-icon>
        <span>{{ "cards-select" | translate }}</span>
      </button>

      <!-- ANCHOR Selección de orden -->
      <button
        mat-menu-item
        [ngClass]="{ 'option-highlighted-green': orderSelection }"
        (click)="orderSelection = !orderSelection; visibilitySelection = false"
      >
        <mat-icon class="card-mat-icon"
          ><i class="fas fa-arrows-alt"></i
        ></mat-icon>
        <span>{{ "cards-order" | translate }}</span>
      </button>

      <hr class="card-menu-separator" />

      <!-- ANCHOR Guardar configuración -->
      <button
        mat-menu-item
        (click)="
          visibilitySelection = false;
          orderSelection = false;
          saveCardsConfig();
          visibilityUpdated()
        "
      >
        <mat-icon class="card-mat-icon"><i class="fas fa-save"></i></mat-icon>
        <span>{{ "save-configuration" | translate }}</span>
      </button>

      <!-- ANCHOR Borrar configuración -->

      <button
        mat-menu-item
        class="option-highlighted-red"
        (click)="
          visibilitySelection = false;
          orderSelection = false;
          deleteCardsConfig()
        "
      >
        <mat-icon class="card-mat-icon"><i class="fas fa-eraser"></i></mat-icon>
        <span>{{ "delete-configuration" | translate }}</span>
      </button>
    </mat-menu>
  </div>

  <div #cardsContainer class="cards-container">
    <!-- ANCHOR Tarjetas -->
    <ng-container>
      <ng-container *ngFor="let card of cards">
        <!-- Contenedor de tarjeta -->
        <div
          *ngIf="
            (!card.hideCondition && card.visibility) ||
            (!card.hideCondition && visibilitySelection)
          "
          [ngClass]="[
            card.containerClasses ? card.containerClasses : card - container,
            cardStretch ? 'card-container-stretch' : ''
          ]"
          [draggable]="orderSelection"
          (dragstart)="card.dragSelection = true; cardDrag(card)"
          (dragend)="card.dragSelection = false"
          (drop)="orderSelection ? cardDrop($event, card) : null"
          (dragover)="cardDragover($event)"
        >
          <!-- Selección de visibilidad -->
          <div
            *ngIf="visibilitySelection"
            class="card-hide-icon-container"
            (click)="
              card.visibility = !card.visibility;
              card.hiddenSelection = !card.visibility
            "
          >
            <div
              class="card-hide-icon"
              [ngClass]="
                card.visibility ? 'card-hide-icon-open' : 'card-hide-icon-close'
              "
            >
              <i
                [ngClass]="card.visibility ? 'fas fa-eye' : 'fas fa-eye-slash'"
              ></i>
            </div>
          </div>

          <!-- Selección de orden -->
          <div *ngIf="orderSelection" class="card-hide-icon-container">
            <div
              class="card-drag-icon"
              [ngClass]="{
                'card-drag-icon-disabled':
                  draggedCard && draggedCard.type != card.type
              }"
            >
              <i class="fas fa-arrows-alt"></i>
            </div>
          </div>

          <!-- ANCHOR Tarjeta -->
          <app-card
            [ngClass]="{
              'card-disabled': visibilitySelection || orderSelection
            }"
            [card]="card"
            [visibilitySelection]="visibilitySelection"
            [orderSelection]="orderSelection"
            (cardActionFlag)="cardAction($event)"
            (cardClickEventFlag)="cardClickEvent($event)"
            [cardHeightFixed]="cardHeightFixed"
          >
          </app-card>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
