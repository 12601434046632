import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { DataAnalysisControllerService } from "../../../../../../services/server/DataAnalysisController.service";
// Interfaces
import { BalanceAlarmThreshold } from "../../../DataAnalysisInterface.type";

@Component({
  selector: "app-data-analysis-balance-dialog",
  templateUrl: "./data-analysis-balance-dialog.component.html",
  styleUrls: ["./data-analysis-balance-dialog.component.scss"],
})
export class DataAnalysisBalanceDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Reseteo de umbral
  resetThreshold(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("reset-threshold-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: BalanceAlarmThreshold = {
          sector: this.data.balanceId,
          observationThreshold: null,
          criticalThreshold: null,
          intervalDays: null,
        };
        this.DataAnalysisController.updateTheshold(data).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("reset-threshold-sucessfull")
              );
              this.SessionDataService.sendReloadPanelFlag();
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          }
        );
      }
    });
  }

  // Guardado de umbral
  saveThreshold(): void {
    let data: BalanceAlarmThreshold = {
      sector: this.data.balanceId,
      observationThreshold:
        this.data.balanceThresholds.observationThreshold == ""
          ? null
          : this.data.balanceThresholds.observationThreshold,
      criticalThreshold:
        this.data.balanceThresholds.criticalThreshold == ""
          ? null
          : this.data.balanceThresholds.criticalThreshold,
      intervalDays:
        this.data.balanceThresholds.intervalDays == ""
          ? null
          : this.data.balanceThresholds.intervalDays,
    };

    if (data.observationThreshold == null && data.criticalThreshold == null) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-observation-critical")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        "warning",
        this.data.edit
          ? this.translate.instant("update-threshold-question")
          : this.translate.instant("save-threshold-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let url = this.data.edit
            ? this.DataAnalysisController.updateTheshold(data)
            : this.DataAnalysisController.saveThreshold(data);
          url.subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.data.edit
                  ? this.translate.instant("update-threshold-sucessfull")
                  : this.translate.instant("save-threshold-sucessfull")
              );
              this.SessionDataService.sendReloadPanelFlag();
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          });
        }
      });
    }
  }
}
