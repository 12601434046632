import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WeatherApiControllerService {
  weatherApiKey = "b70942f0b172fe588c9bcb502d79428b";

  constructor(private http: HttpClient) {}

  // API de tiempo meteorológico: https://openweathermap.org/weather-conditions
  getWeatherData(
    latitude: number,
    longitude: number,
    lang: string
  ): Observable<object> {
    return this.http.get(
      "https://api.openweathermap.org/data/2.5/weather?units=metric&lat=" +
        latitude +
        "&lon=" +
        longitude +
        "&lang=" +
        lang +
        "&appid=" +
        this.weatherApiKey
    );
  }
}
