<!-- Foto de dispositivo -->
<div *ngIf="data.show == 'photo'" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      <h5 *ngIf="data.deviceType == 'meter'" class="modal-title">
        {{ "meter-photo" | translate }}
      </h5>
      <h5 *ngIf="data.deviceType == 'gateway'" class="modal-title">
        {{ "gateway-photo" | translate }}
      </h5>
    </h5>
  </div>
  <div class="dialog-body">
    <div *ngIf="data.elementPhoto == null">
      <div class="row text-center">
        <span class="fas fa-image fa-2x"> {{ "loading" | translate }}...</span>
      </div>
    </div>
    <img
      *ngIf="data.elementPhoto != null"
      class="map-modal-img img-rounded"
      src="data:image/jpg;base64,{{ data.elementPhoto }}"
    />
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>

<!-- Exportación de KML -->
<div *ngIf="data.show == 'kml'" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "kml-export" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <div class="map-modal-kml-export">
      <app-material-select
        class="material-header-select"
        [title]="'export' | translate"
        [bindValue]="'value'"
        [bindLabel]="'name'"
        [selected]="'gateways'"
        [data]="kmlExport"
        (selectedOption)="kmlSelected = $event.value"
      ></app-material-select>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="exportKml()">
      {{ "export" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
