<div class="dialog-container">
  <!-- Título del modal -->
  <div class="dialog-header">
    <h5 class="dialog-title">
      <b
        >{{ (data.sensors.length == 1 ? "sensor" : "sensors") | translate }}
        {{
          data.sensors.length == 1 ? ": " + data?.sensors[0].nroSerie : ""
        }}</b
      >
    </h5>
  </div>

  <div class="dialog-body">
    <div class="leak-dialog-noise-graph">
      <!-- Gráfica -->
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [noTypeSelector]="true"
        [data]="chartOptions"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to)"
      >
      </app-graph-controller>
    </div>
  </div>

  <div class="dialog-footer control-modal-footer">
    <button mat-button (click)="showMeters()" [disabled]="metersGraph">
      {{ "show-meters" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
