import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MaterialDialogItem } from "./material-dialog-item";
import { MaterialDialogComponent } from "./material-dialog.component";

@Injectable({
  providedIn: "root",
})
export class MaterialDialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(dialogComponent: any, dialogData?: any) {
    let data = new MaterialDialogItem(dialogComponent, dialogData);
    this.dialog.open(MaterialDialogComponent, { data, autoFocus: false });
  }
}
