import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Shape
import * as shp from "shpjs";
// Interfaces
import {
  ContrastData,
  ImageOverlay,
  MapKmlAssociation,
} from "../MapInterface.type";

@Injectable({
  providedIn: "root",
})
export class MapKmlService {
  // Polígonos
  currentMapKmlAssociations: MapKmlAssociation[];
  mapKmlAssociations = new Subject<MapKmlAssociation[]>();

  // Imagen
  currentMapKmlImage: ImageOverlay[];
  mapKmlImage = new Subject<ImageOverlay[]>();

  // Puntos
  currentMapKmlPoints: MapKmlAssociation[];
  mapKmlPoints = new Subject<MapKmlAssociation[]>();

  // Líneas
  currentMapShapefile: any;
  mapShapefile = new Subject<any>();

  constructor(private translate: TranslateService) {}

  // MapKmlAssociation
  sendMapKmlAssociations(mapKmlAssociations: MapKmlAssociation[]): void {
    this.mapKmlAssociations.next(mapKmlAssociations);
    this.currentMapKmlAssociations = mapKmlAssociations;
  }

  clearMapKmlAssociations(): void {
    this.mapKmlAssociations.next(null);
    this.currentMapKmlAssociations = null;
  }

  getMapKmlAssociations(): Observable<MapKmlAssociation[]> {
    return this.mapKmlAssociations.asObservable();
  }

  getCurrentMapKmlAssociations(): MapKmlAssociation[] {
    return this.currentMapKmlAssociations;
  }

  // MapKmlImage
  sendMapKmlImage(mapKmlImage: ImageOverlay[]): void {
    this.mapKmlImage.next(mapKmlImage);
    this.currentMapKmlImage = mapKmlImage;
  }

  clearMapKmlImage(): void {
    this.mapKmlImage.next(null);
    this.currentMapKmlImage = null;
  }

  getMapKmlImage(): Observable<ImageOverlay[]> {
    return this.mapKmlImage.asObservable();
  }

  getCurrentMapKmlImage(): ImageOverlay[] {
    return this.currentMapKmlImage;
  }

  // MapKmlPoints
  sendMapKmlPoints(mapKmlPoints: MapKmlAssociation[]): void {
    this.mapKmlPoints.next(mapKmlPoints);
    this.currentMapKmlPoints = mapKmlPoints;
  }

  clearMapKmlPoints(): void {
    this.mapKmlPoints.next(null);
    this.currentMapKmlPoints = null;
  }

  getMapKmlPoints(): Observable<MapKmlAssociation[]> {
    return this.mapKmlPoints.asObservable();
  }

  getCurrentMapKmlPoints(): MapKmlAssociation[] {
    return this.currentMapKmlPoints;
  }

  // Shapefile
  sendMapShapefile(mapShapefile: any): void {
    this.mapShapefile.next(mapShapefile);
    this.currentMapShapefile = mapShapefile;
  }

  clearMapShapefile(): void {
    this.mapShapefile.next(null);
    this.currentMapShapefile = null;
  }

  getMapShapefile(): Observable<any> {
    return this.mapShapefile.asObservable();
  }

  getCurrentMapShapefile(): any {
    return this.currentMapShapefile;
  }

  // Parseo de archivo XML
  parsePolygonKml(file: any): void {
    let fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      this.extractAssociationsCoords(e.target.result);
    };
    fileReader.readAsText(file);
  }

  // Extracción de coordenadas
  extractAssociationsCoords(plainText: any): void {
    let associations: MapKmlAssociation[] = [];
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");

    let kmlAssociations = xmlDoc.getElementsByTagName("Placemark");
    let coords = xmlDoc.getElementsByTagName("coordinates");

    Array.from(kmlAssociations).forEach((association, i) => {
      let coordsStrings = coords[i].innerHTML.split(" ");
      let coordsNumbers = coordsStrings.map((coord) => coord.split(","));

      associations.push({
        // name: association.getElementsByTagName("name")[0].innerHTML,
        coords: coordsNumbers
          .map((coord) => [parseFloat(coord[1]), parseFloat(coord[0])])
          .filter((coord) => coord[0] && coord[1]),
      });
    });
    this.sendMapKmlAssociations(associations);
  }

  // Parseo de todos los archivos asociados
  parseImageKmlFiles(files: any): void {
    let filesParsed: ContrastData[] = [];
    for (let file in files) {
      if (files[file]?.name) {
        this.parseImageKml(
          files[file],
          filesParsed,
          files.length == Object.keys(files).indexOf(file) + 1
        );
      }
    }
  }

  // Parseo de archivo XML
  parseImageKml(file: any, filesParsed: any, parseEnd: boolean): void {
    let fileReader = new FileReader();
    if (file?.name.includes("kml")) {
      fileReader.onload = (e: any) => {
        let fileId = file.name.replace(".kml", "");
        let fileFound = filesParsed.find(
          (contrast: ContrastData) => contrast.id == fileId
        );
        if (fileFound) {
          fileFound.coords = this.extractGoogleCoords(e.target.result);
        } else {
          filesParsed.push({
            id: fileId,
            coords: this.extractGoogleCoords(e.target.result),
          });
        }

        if (parseEnd) {
          this.showContrast(filesParsed);
        }
      };
      fileReader.readAsText(file);
    } else if (file?.name.includes("png")) {
      fileReader.onload = (e: any) => {
        let fileId = file.name.replace(".png", "");
        let fileFound = filesParsed.find(
          (contrast: ContrastData) => contrast.id == fileId
        );
        if (fileFound) {
          fileFound.image = e.target.result;
        } else {
          filesParsed.push({
            id: fileId,
            image: e.target.result,
          });
        }

        if (parseEnd) {
          this.showContrast(filesParsed);
        }
      };
      fileReader.readAsDataURL(file);
    }
  }

  // Extracción de coordenadas
  extractGoogleCoords(plainText: any): {
    north: number;
    south: number;
    east: number;
    west: number;
  } {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let north: number = parseFloat(
      xmlDoc.getElementsByTagName("north")[0].innerHTML
    );
    let south: number = parseFloat(
      xmlDoc.getElementsByTagName("south")[0].innerHTML
    );
    let east: number = parseFloat(
      xmlDoc.getElementsByTagName("east")[0].innerHTML
    );
    let west: number = parseFloat(
      xmlDoc.getElementsByTagName("west")[0].innerHTML
    );
    return { north: north, south: south, east: east, west: west };
  }

  // Contraste
  showContrast(filesParsed: any): void {
    let kmlImage = [];
    filesParsed.forEach((file: ContrastData) => {
      if (file.coords && file.image) {
        kmlImage.push({
          name: this.translate.instant("coverage") + " " + file.id,
          coords: [
            [file.coords.north, file.coords.west],
            [file.coords.south, file.coords.east],
          ],
          image: file.image,
        });
      }
    });
    this.sendMapKmlImage(kmlImage);
  }

  // Parseo de archivo XML por puntos
  parsePointsKml(file: any): void {
    let fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      this.extractPointsCoords(e.target.result);
    };
    fileReader.readAsText(file);
  }

  // Parseo de archivo shapefile
  parseShapefile(file: any): void {
    let fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      shp.parseZip(e.target.result).then((data) => {
        this.sendMapShapefile(data);
      });
    };
    fileReader.readAsArrayBuffer(file);
  }

  // Extracción de coordenadas
  extractPointsCoords(plainText: any): void {
    let points: MapKmlAssociation[] = [];
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let kmlAssociations = xmlDoc.getElementsByTagName("Folder");

    Array.from(kmlAssociations).forEach((association) => {
      if (association.getElementsByTagName("Folder").length == 0) {
        let coords = association.getElementsByTagName("coordinates");
        let parsedCoords = [];
        Array.from(coords).forEach((coord) => {
          parsedCoords.push(coord.innerHTML.split(","));
        });
        points.push({
          name: association.getElementsByTagName("name")[0].innerHTML,
          coords: parsedCoords
            .map((coord) => [parseFloat(coord[1]), parseFloat(coord[0])])
            .filter((coord) => coord[0] && coord[1]),
        });
      }
    });
    this.sendMapKmlPoints(points);
  }
}
