<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "manufacturers-management" | translate }}</b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>

        <input
          #mergeInput
          class="hidden"
          type="file"
          accept=".json"
          id="file"
          (click)="resetFile()"
          (change)="mergeFiles($event.target.files)"
          multiple
        />
      </div>

      <div class="panel-body">
        <div class="management-manufacturers-selector">
          <!-- Selector de fabricantes -->
          <app-material-select
            [filter]="true"
            [noSelection]="true"
            [title]="'manufacturer' | translate"
            [bindValue]="'id'"
            [bindLabel]="'label'"
            [data]="manufacturerList"
            [selected]="manufacturerPreselect"
            (selectedOption)="
              selectedManufacturer = $event;
              getDeviceTypeList();
              editionTarget = selectedManufacturer;
              newEditionActive =
                editionTarget?.id == '-1' ? 'manufacturer' : null
            "
          ></app-material-select>

          <!-- Selector de dispositivos -->
          <app-material-select
            *ngIf="selectedManufacturer?.id != '-1'"
            [filter]="true"
            [noSelection]="true"
            [title]="'device-type' | translate"
            [bindValue]="'id'"
            [bindLabel]="'label'"
            [data]="deviceTypeList"
            [selected]="devicePreselect"
            (selectedOption)="
              selectedDeviceType = $event;
              editionTarget = selectedDeviceType
                ? selectedDeviceType
                : selectedManufacturer;
              newEditionActive = editionTarget?.id == '-1' ? 'device' : null
            "
          ></app-material-select>

          <!-- Guardar nuevo -->
          <button
            *ngIf="newEditionActive && newEditionActive != 'saved'"
            mat-button
            class="mat-button-icon-text"
            (click)="saveNew()"
          >
            <i class="fas fa-save"></i>
            {{ "save" | translate }}
          </button>
        </div>

        <!-- Configuración de fabricante/tipo de dispositivo -->
        <div *ngIf="editionTarget" class="management-manufacturers-edition">
          <div class="management-manufacturers-edition-title">
            {{
              (editionTarget.manufacturerText
                ? "manufacturer-edition"
                : "manufacturer-device-edition"
              ) | translate
            }}
          </div>
          <!-- ID -->
          <div>
            <span [title]="'manufacturer-id-tip' | translate">ID</span
            ><input
              type="number"
              [(ngModel)]="editionTarget.id"
              [title]="'manufacturer-id-tip' | translate"
            />
          </div>
          <!-- Nombre -->
          <div class="management-manufacturers-edition-name">
            <span [title]="'manufacturer-name-tip' | translate">{{
              "name" | translate
            }}</span>
            <div>
              <ng-container *ngFor="let lang of langs">
                <div>
                  <span>{{ lang != "es-ca" ? lang.toUpperCase() : "CA" }}</span>
                  <!-- Fabricante -->
                  <input
                    *ngIf="editionTarget.manufacturerText"
                    type="text"
                    [(ngModel)]="editionTarget.manufacturerText[lang]"
                    [title]="'manufacturer-name-tip' | translate"
                  />
                  <!-- Dispositivo -->
                  <input
                    *ngIf="editionTarget.deviceText"
                    type="text"
                    [(ngModel)]="editionTarget.deviceText[lang]"
                    [title]="'manufacturer-name-tip' | translate"
                  /></div
              ></ng-container>
            </div>
          </div>
          <!-- Tipos de dispositivo -->
          <div *ngIf="selectedDeviceType">
            <span [title]="'manufacturer-type-tip' | translate">{{
              "dev-type-import" | translate
            }}</span>
            <div class="management-manufacturers-device-types">
              <span
                *ngFor="let type of DEVICE_TYPES"
                [ngClass]="{
                  'management-manufacturer-type-selected': editionTarget.type
                    ?.split(', ')
                    ?.includes(type.id)
                }"
                (click)="selectType(type.id)"
              >
                {{ type.label | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
