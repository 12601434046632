// @angular
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { UserControllerService } from "../../../../services/server/UserController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { UserService } from "../UserService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { User } from "../../../../interfaces/UserGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";

@Component({
  selector: "app-userlist",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UsersListComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentUser: string;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 25;
  columns: (TableActionColumn | TableDataColumn)[];
  userList: User[];
  exportFileName: string =
    this.translate.instant("user-export") +
    " " +
    this.DateParserService.getDate();
  highlightRow: TableHighlightRow[] = [
    { condition: "highlightUser", color: "green", title: "active-user" },
  ];

  // Menú de opciones del componente
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "update",
      icon: "fas fa-sync-alt",
      text: this.translate.instant("update"),
      visible: true,
    },
    {
      action: "new-user",
      icon: "fas fa-plus",
      text: this.translate.instant("new-user"),
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private UserService: UserService,
    private UserController: UserControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentUser = this.SessionDataService.getCurrentUser();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios en los valores de agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    if (
      this.sessionProfile == "ARSON" ||
      this.sessionProfile == "ADMIN_CLIENTE" ||
      this.sessionProfile == "ADMIN_ENTIDAD" ||
      this.sessionProfile == "ADMIN_AGRUPACION"
    ) {
      this.panelMenuOptions.push({
        action: "import-user",
        icon: "fas fa-file-import",
        text: this.translate.instant("file-import-subscribers"),
        visible: true,
      });
    }
    this.setTableColumns();
    this.getUserList();
  }

  // Obtención de la lista de usuarios
  getUserList(): void {
    this.UserController.getUsers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          if (response["body"].length > 0) {
            let userList: User[] = response["body"];
            userList.forEach((user: User) => {
              user.profile != null
                ? (user["profileText"] = this.translate.instant(
                    "profiles" + user.profile
                  ))
                : "-";
              user.activeText = user.active
                ? this.translate.instant("yes")
                : this.translate.instant("no");
              user.disableErase = user.active;
              user.disableDisable = !user.active;
              user.highlightUser = this.currentUser == user.username;
            });
            this.userList = userList.filter((user: User) => user.profile != 1);
          } else {
            this.userList = [];
          }
        }
      }
    );
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "update":
        this.ReloadComponentService.reload();
        break;
      case "new-user":
        this.router.navigate(["/usuarios/formulario/nuevo"]);
        break;
      case "import-user":
        this.router.navigate(["/usuarios/importar"]);
        break;
      default:
        break;
    }
  }

  // Seteo de las columnas de la tabla
  setTableColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "disable",
            tooltip: "disable",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: "disableDisable",
            warning: true,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: this.sessionProfile == "ARSON" },
            disabled: "disableErase",
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "actives",
        data: "activeText",
        search: "activeText",
        sort: "active",
        alter: {
          condition: "active",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "name",
        data: "name",
        search: "name",
        sort: "name",
        visible: true,
      },
      {
        title: "surname",
        data: "surname",
        search: "surname",
        sort: "surname",
        visible: true,
      },
      {
        title: "creation-date",
        data: "creationDateParsed",
        search: "creationDateParsed",
        sort: "creationDate",
        date: true,
        visible: true,
      },
      {
        title: "last-login",
        data: "lastLoginParsed",
        search: "lastLoginParsed",
        sort: "lastLogin",
        date: true,
        visible: true,
      },
      {
        title: "profile",
        data: "profileText",
        search: "profileText",
        sort: "profileText",
        visible: true,
      },
      {
        title: "Email",
        data: "email",
        search: "email",
        sort: "email",
        visible: true,
      },
      {
        title: "phone",
        data: "phoneNumber",
        search: "phoneNumber",
        sort: "phoneNumber",
        visible: true,
      },
      {
        title: "last-login-ip",
        data: "lastLoginIp",
        search: "lastLoginIp",
        sort: "lastLoginIp",
        visible: this.sessionProfile == "ARSON",
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, user: User): void {
    switch (action) {
      case "edit":
        this.router.navigate(["/usuarios/formulario/editar/" + user.id]);
        break;
      case "disable":
        this.UserService.disableUser(user);
        break;
      case "delete":
        this.UserService.deleteUser(user);
        break;
      default:
        break;
    }
  }
}
