<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b> {{ "battery-load" | translate }}</b>
        </h5>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Menú de opciones del panel -->
        <div class="low-battery-data-select">
          <!-- Cliente -->
          <app-material-select
            [filter]="true"
            [title]="'client' | translate"
            [bindValue]="'clientId'"
            [bindLabel]="'clientName'"
            [selected]="currentClient?.clientId"
            [data]="clientList"
            (selectedOption)="updateClient($event)"
          ></app-material-select>

          <!-- Entidad -->
          <app-material-select
            *ngIf="entityList"
            [filter]="true"
            [title]="'entity' | translate"
            [bindValue]="'id'"
            [bindLabel]="'entity'"
            [selected]="-1"
            [data]="entityList"
            (selectedOption)="updateEntity($event)"
          ></app-material-select>

          <!-- Agrupación -->
          <app-material-select
            *ngIf="agrupationList"
            [filter]="true"
            [title]="'groups' | translate"
            [bindValue]="'id'"
            [bindLabel]="'name'"
            [selected]="-1"
            [data]="agrupationList"
            (selectedOption)="selectedAgrupation = $event.id"
          ></app-material-select>

          <mat-form-field appearance="standard">
            <mat-label>{{ "battery-only" | translate }} (%)</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="batteryLoad"
              placeholder="{{ 'battery-load' | translate }}..."
            />
          </mat-form-field>

          <button mat-button class="mat-button-icon" (click)="getData()">
            <i class="fas fa-search"></i>
          </button>
        </div>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'metersLowBattery'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>

        <div class="meter-list-map">
          <app-map-selector
            *ngIf="mapSelectorActive"
            id="meter-list-map"
            [dataList]="meterList"
            [originalDataList]="originalMeterList"
            [tableSelectedData]="tableSelectedData"
            [table]="tableController"
            [filterAttribute]="'nroSerie'"
            [anchorToScroll]="'table-panel'"
            (updateData)="meterList = $event"
          >
          </app-map-selector>
        </div>
      </div>
    </div>
  </div>
</section>
