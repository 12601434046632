import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataConverterService {
  constructor() {}

  // Conversión del archivo zip en base64
  convertDataToBinary(data: any) {
    let raw = window.atob(data);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  // Blob de descarga
  getDownloadBlobFromByteFile(data: any, contentType: string): Blob {
    return new Blob([this.convertDataToBinary(data)], {
      type: contentType,
    });
  }
}
