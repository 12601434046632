<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <!-- Menú de opciones del panel -->
        <div class="telemetry-data-select">
          <app-material-select
            class="material-header-select"
            [title]="'entity' | translate"
            [bindValue]="'id'"
            [bindLabel]="'entity'"
            [selected]="currentEntity?.id"
            [data]="entityList"
            (selectedOption)="selectedDataSource = $event; getData()"
          ></app-material-select>
        </div>

        <!-- Botón de nuevo EK -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="'fas fa-plus'"
          [title]="'new' | translate"
          (action)="newEk()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'controlTest'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [quickFilters]="quickFilters"
          [quickFiltersExclusion]="quickFiltersExclusion"
          [highlightRow]="highlightRow"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
