<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b
            >{{ "gateway-operator-historical" | translate }}:
            {{ unidadVenta }}</b
          >
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewayOperators'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [data]="gatewayOperators"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
