<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <!-- Queries -->
        <app-material-select
          *ngIf="queries"
          class="material-header-select"
          [fullWidth]="true"
          [title]="'queries-preset' | translate"
          [bindValue]="'id'"
          [bindLabel]="'name'"
          [selected]="1"
          [data]="queries"
          (selectedOption)="selectedQuerie = $event; tableData = null"
        ></app-material-select>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Parámetros -->
        <div *ngIf="selectedQuerie" class="query-params-container">
          <div class="query-params-title">
            <h4>{{ "query-params" | translate }}</h4>
          </div>
          <div class="query-params">
            <!-- Fecha -->
            <div
              class="querie-param"
              *ngFor="let date of selectedQuerie.params.date; index as i"
            >
              <div
                *ngIf="!date.range && date.hour"
                class="querie-date-param"
                (contextmenu)="openDateMenu($event)"
              >
                <label for="{{ date.label }}">{{
                  date.label | translate
                }}</label>
                <input
                  type="datetime-local"
                  id="{{ date.label }}"
                  name="{{ date.label }}"
                  [(ngModel)]="date.value"
                />
                <!-- Menú de selector de fecha -->
                <div
                  class="date-selector-menu"
                  [matMenuTriggerFor]="dateMenu"
                  #dateMenuTrigger="matMenuTrigger"
                >
                  <mat-menu #dateMenu="matMenu" class="date-menu">
                    <button
                      mat-menu-item
                      (click)="copyDate()"
                      *ngIf="
                        selectedQuerie.params.date[0]?.value &&
                        selectedQuerie.params.date[1]?.value
                      "
                    >
                      <mat-icon class="material-menu-icon">
                        <i class="fas fa-copy"></i
                      ></mat-icon>
                      <span>{{ "copy" | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="pasteDate()">
                      <mat-icon class="material-menu-icon">
                        <i class="fas fa-paste"></i
                      ></mat-icon>
                      <span>{{ "paste" | translate }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div *ngIf="date.range">
                <app-material-date-selector
                  [dateRangeLabel]="
                    (date.label | translate) +
                    (date.labelIndex ? ' ' + (i + 1) : '')
                  "
                  [initialDateNull]="true"
                  (dateRangeUpdated)="date.value = $event"
                ></app-material-date-selector>
              </div>
            </div>

            <!-- Selector -->
            <div
              class="querie-param"
              *ngFor="let select of selectedQuerie.params.select"
            >
              <app-material-select
                [filter]="select.filter"
                [title]="select.title | translate"
                [bindValue]="select.bindLabel"
                [bindLabel]="select.bindValue"
                [selected]="select[0].bindValue"
                [data]="select.data"
                (selectedOption)="select.value = $event[select.bindValue]"
              ></app-material-select>
            </div>

            <!-- Input -->
            <div
              class="querie-param"
              *ngFor="let input of selectedQuerie.params.input"
            >
              <mat-form-field appearance="standard">
                <mat-label>{{ input.label | translate }} </mat-label>
                <input
                  matInput
                  type="input.type"
                  [(ngModel)]="input.value"
                  [placeholder]="
                    input.placeholder ? (input.placeholder | translate) : null
                  "
                />
                <span *ngIf="input.units" matSuffix>{{ input.units }}</span>
              </mat-form-field>
            </div>
          </div>

          <!-- Buscar -->
          <div class="querie-paramas-buttons">
            <button mat-button class="mat-button-icon-text" (click)="getData()">
              <i class="fas fa-search"></i>
              {{ "search" | translate }}
            </button>
          </div>
        </div>

        <!-- Tabla -->
        <app-table-controller
          *ngIf="tableData"
          [tableId]="'queries'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [data]="tableData"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
        >
        </app-table-controller>

        <div class="queries-list-map">
          <app-map-selector
            *ngIf="mapSelectorActive"
            id="queries-list-map"
            [dataList]="tableData"
            [originalDataList]="originalTableData"
            [tableSelectedData]="tableSelectedData"
            [table]="tableController"
            [filterAttribute]="'id'"
            [anchorToScroll]="'table-panel'"
            (updateData)="tableData = $event"
          >
          </app-map-selector>
        </div>
      </div>
    </div>
  </div>
</section>
