<div class="table-pagination">
  <div class="mat-paginator table-page-reg">
    {{ "table-pagination-showing" | translate }}
    <b>{{ _dataLength > 0 ? currentPage * _maxReg + 1 : 0 }}</b>
    {{ "table-pagination-to" | translate }}
    <b>{{
      _dataLength > (currentPage + 1) * _maxReg
        ? (currentPage + 1) * _maxReg
        : _dataLength
    }}</b>
    {{ "table-pagination-total" | translate }}
    <b>{{ _dataLength }}</b>
    {{ "table-pagination-registers" | translate }}
  </div>

  <div class="table-paginator">
    <mat-paginator
      [length]="_dataLength"
      [pageIndex]="currentPage"
      [pageSize]="_maxReg"
      [pageSizeOptions]="pageSizeOptions"
      aria-label="Select page"
      (page)="goTo = $event.pageIndex + 1; paginatorUpdated.emit($event)"
    >
    </mat-paginator>
    <div class="table-paginator-go-to">
      <mat-label>{{ "table-paginator-go-to" | translate }}:</mat-label>
      <mat-select
        [(ngModel)]="goTo"
        (selectionChange)="goToPage.emit(goTo - 1)"
      >
        <mat-option
          *ngFor="let pageNumber of pageNumbers"
          [value]="pageNumber"
          [label]="pageNumber"
          >{{ pageNumber }}</mat-option
        >
      </mat-select>
    </div>
  </div>
</div>
