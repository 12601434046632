<div class="dialog-container managament-global-dialog">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "clean-tables-to" | translate }}:</h5>
  </div>
  <div class="dialog-body">
    <!-- Borrado de tablas -->
    <!-- Selector de rango de fechas -->
    <div class="management-global-dialog-date">
      <app-material-date-selector
        [singleDatePicker]="true"
        [dateRange]="dateRange"
        [maxDate]="maxDate"
        (dateRangeUpdated)="dateRangeSelected = $event"
      ></app-material-date-selector>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="cleanTables()"
      [disabled]="!dateRangeSelected?.endDate"
      title="{{ 'clean-tables-to' | translate }}"
    >
      <i class="fas fa-broom"></i>
      {{ "clean-tables" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
