// @angular
import { Component, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { User } from "../../../../interfaces/UserGlobalInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
import { ManagementUserEvent } from "../ManagementInterface.type";

@Component({
  selector: "app-management-user-events",
  templateUrl: "./management-user-events.component.html",
  styleUrls: ["./management-user-events.component.scss"],
})
export class ManagementUserEventsComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Búsqueda
  eventSelect: MaterialSelectOption[];
  selectedEvent: number;

  // Tabla
  tableData: ManagementUserEvent[] = [];
  tableMaxReg: number = 20;
  exportFileName: string =
    this.translate.instant("users") + " " + this.DateParserService.getDate();
  tableSelectedData: User[];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "gateway",
      data: "gwUnidadVenta",
      search: "gwUnidadVenta",
      sort: "gwUnidadVenta",
      visible: true,
      link: "gatewayLink",
    },
    {
      title: "meter",
      data: "meterNroSerie",
      search: "meterNroSerie",
      sort: "meterNroSerie",
      visible: true,
      link: "meterLink",
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "command-response",
      data: "responseCommandParsed",
      search: "responseCommandParsed",
      sort: "responseCommandParsed",
      visible: true,
    },
    {
      title: "details",
      data: "eventDetails",
      search: "eventDetails",
      sort: "eventDetails",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private ManagementController: ManagementControllerService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getEventList();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  getEventList(): void {
    let eventSelect = [];
    for (let i = 0; i < 255; i++) {
      let event = this.translate.instant("UserEvent" + i);
      if (!event.includes("UserEvent")) {
        eventSelect.push({ value: i, name: event });
      } else {
        i = i + (9 - (i % 10));
      }
    }
    this.eventSelect = eventSelect.sort((a, b) => a.name.localeCompare(b.name));
    this.selectedEvent = this.eventSelect[0].value;
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.ManagementController.getUsersEvents(
      this.selectedEvent,
      from,
      to
    ).subscribe((response) => {
      let tableData: ManagementUserEvent[] = [];
      if (response["code"] == 0) {
        tableData = response["body"];
        tableData.forEach((event: ManagementUserEvent) => {
          event.meterLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            event.metrologyType,
            event.contador
          );
          event.gatewayLink = "/gateways/detalle/gateway/" + event.gateway;
          event.responseCommandParsed =
            event.responseCommand != null
              ? this.translate.instant("httpError" + event.responseCommand)
              : "-";
        });
      }
      this.tableData = tableData;
    });
  }
}
