<div class="water-effect-container">
  <div id="background-wrap">
    <div class="scale-0225">
      <div class="side-ways-8"><div class="bubble x8"></div></div>
    </div>
    <div class="scale-0075">
      <div class="side-ways-2">
        <div class="bubble x2"></div>
      </div>
    </div>
    <div class="scale-0150">
      <div class="side-ways-10">
        <div class="bubble x10"></div>
      </div>
    </div>
    <div class="scale-0100">
      <div class="side-ways-3">
        <div class="bubble x3"></div>
      </div>
    </div>
    <div class="scale-0275">
      <div class="side-ways-1">
        <div class="bubble x1"></div>
      </div>
    </div>
    <div class="scale-0125">
      <div class="side-ways-4">
        <div class="bubble x4"></div>
      </div>
    </div>
    <div class="scale-0175">
      <div class="side-ways-6">
        <div class="bubble x6"></div>
      </div>
    </div>
    <div class="scale-0250">
      <div class="side-ways-9">
        <div class="bubble x9"></div>
      </div>
    </div>
    <div class="scale-0200">
      <div class="side-ways-7">
        <div class="bubble x7"></div>
      </div>
    </div>
    <div class="scale-0300">
      <div class="side-ways-5">
        <div class="bubble x5"></div>
      </div>
    </div>
  </div>
</div>
