import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Angular Material
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MaterialModule } from "../material-module/material.module"; 

// Nav
import { NavModule } from "../nav-module/nav.module";

// Notification
import { NotificationModalComponent } from "./notification-modal/notification-modal.component";
import { NotificationImportComponent } from "./notification-import/notification-import.component";

// Table
import { TableModule } from "../table-module/table.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,

    // Angular Material
    MatButtonModule,
    MatIconModule,
    MaterialModule,

    // Nav
    NavModule,

    // Table
    TableModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [NotificationModalComponent, NotificationImportComponent],
  exports: [NotificationModalComponent, NotificationImportComponent],
})
export class NotificationModule {}
