<!-- Edición de cron -->
<div *ngIf="data && data.action == 'editCron'" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "cron-expression" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <div class="cron-edit-container">
      <div class="cron-edit-exp">
        <!-- Cron actual -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-current" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.currentTrigger.cronExpression"
            disabled="true"
          />
        </mat-form-field>
        <!-- Nuevo cron -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-new" | translate }}
          </mat-label>
          <input
            matInput
            class="cron-new"
            [ngClass]="cronValid ? 'cron-new-validated' : 'cron-new-error'"
            type="text"
            [(ngModel)]="data.updatedCronExp"
            disabled="true"
          />
        </mat-form-field>
      </div>

      <div class="cron-edit-exp-items">
        <!-- Segundos -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-second" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[0]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>

        <!-- Minutos -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-minute" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[1]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>

        <!-- Hora -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-hour" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[2]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>

        <!-- Día del mes -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-day" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[3]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>

        <!-- Month -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-month" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[4]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>

        <!-- Día de la semana -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-week" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[5]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>

        <!-- Año -->
        <mat-form-field appearance="standard">
          <mat-label>
            {{ "cron-year" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="data.cronExp[6]"
            (keyup)="
              data.updatedCronExp = data.cronExp.join(' '); validateCron()
            "
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="dialog-footer">
    <button mat-button (click)="updateCronExpression()" [disabled]="!cronValid">
      {{ "update" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>

<!-- Edición de misfire -->
<div *ngIf="data && data.action == 'editMisfire'" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "job-edit-mf" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <div class="cron-edit-misfire-options">
      <mat-radio-group [(ngModel)]="data.updatedMisfire">
        <!-- ONCE_NOW -->
        <mat-radio-button [value]="'ONCE_NOW'"> ONCE_NOW </mat-radio-button>
        <!-- DO_NOTHING -->
        <mat-radio-button [value]="'DO_NOTHING'"> DO_NOTHING </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="updateMisfire()">
      {{ "update" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
