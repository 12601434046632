// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";

@Component({
  selector: "app-cupsmain",
  templateUrl: "./subscriber-main.component.html",
  styleUrls: ["./subscriber-main.component.scss"],
})
export class SubscriberMainComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentSubscriberCupsList: any;
  subscriberCupsListSub: Subscription;
  currentSubscriberCupsData: any;
  subscriberCupsDataSub: Subscription;
  sessionLanguage: string;
  languageSub: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentSubscriberCupsList =
      this.SessionDataService.getCurrentSubscriberCupsList();
    this.currentSubscriberCupsData =
      this.SessionDataService.getCurrentSubscriberCupsData();

    this.subscriberCupsListSub = this.SessionDataService
      .getSubscriberCupsList()
      .subscribe((subscriberCupsList) => {
        this.currentSubscriberCupsList = subscriberCupsList;
      });

    this.subscriberCupsDataSub = this.SessionDataService
      .getSubscriberCupsData()
      .subscribe((subscriberCupsData) => {
        this.currentSubscriberCupsData = subscriberCupsData;
      });

    // Escucha de cambios en idioma
    this.languageSub = this.SessionDataService.getLanguage().subscribe((language) => {
      this.sessionLanguage = language;
      this.ReloadComponentService.reload();
    });

    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.subscriberCupsListSub.unsubscribe();
    this.subscriberCupsDataSub.unsubscribe();
    this.languageSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {}
}
