/**
 * Decodes the given frmPayload for the Viewshine protocol.
 *
 * @param {any} frmPayload - The frmPayload to be decoded.
 * @param {any} fport - The FPort of the frame.
 *
 * @returns {Object}
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class ConstratDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  readonly VALVE_STATUS = ["Abierta", "Cerrada", "NO VÁLIDO"];
  readonly ERROR_TAMPER = [
    "No intrusion",
    "Intrusion alarm sensor 1",
    "Intrusion alarm sensor 2",
    "NO VÁLIDO",
  ];

  constrat_valvula_decoder(frmPayload, fport) {
    if (frmPayload) {
      if (frmPayload.bytes) {
        var outputData = {};
        var outputErrors = [];
        if (fport != 0x08) {
          return {
            errors: ["PUERTO NO VÁLIDO: " + fport.toString()],
          };
        } else {
          var tramaStr = frmPayload.bytes
            .map((byte) => String.fromCharCode(byte))
            .join("");
          if (tramaStr == "CONSTRAT") {
            return {
              data: { Tipo_Trama: "PING", data: tramaStr },
            };
          } else {
            var parametros = tramaStr.split(",");
            if (parametros.length != 8) {
              return {
                errors: ["TRAMA DESCONOCIDA: " + tramaStr],
              };
            } else {
              var valveStatus = parametros[0];
              if (valveStatus > this.VALVE_STATUS.length - 2) {
                valveStatus = this.VALVE_STATUS.length - 1;
              }
              var antiCorrosionCount = parametros[1];
              var antiCorrosionMax = parametros[2];
              var dsCnt = parametros[3];
              var dsMax = parametros[4];
              var errorCode = parametros[5];
              var errorTamper = parametros[6];
              if (errorTamper > this.ERROR_TAMPER.length - 2) {
                errorTamper = this.ERROR_TAMPER.length - 1;
              }

              var batteryLevel = parametros[7];

              outputData = {
                Tipo_Trama: "STATUS_" + this.VALVE_STATUS[valveStatus],
                Estado_Valvula: this.VALVE_STATUS[valveStatus],
                Anti_Corrosion_Cnt: antiCorrosionCount,
                Anti_Corrosion_Max: antiCorrosionMax,
                Awakening_counter: dsCnt,
                Awakening_max: dsMax,
                Error: errorCode == 1,
                Error_Tamper: this.ERROR_TAMPER[errorTamper],
                Valve_operations: batteryLevel,
              };
              return {
                data: outputData,
              };
            }
          }
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frmPayload."],
      };
    }
  }
}
