<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "edit-values" | translate }}
    </h5>
  </div>
  <div class="dialog-body satelite-modify-dialog">
    <!-- Valor -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "last-know-value" | translate }} (m³)</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="data.lastReadedValue"
        autocomplete="off"
        required
      />
    </mat-form-field>
    <!-- Overflow -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "satelite-max-value" | translate }} (m³)</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="data.overflow"
        autocomplete="off"
        required
      />
    </mat-form-field>
    <!-- Pulse weight -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "satelite-pulse" | translate }} (m³)</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="data.pulseWeight"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="modifySateliteValues()"
      [disabled]="
        data.lastReadedValue == null ||
        data.lastReadedValue == '' ||
        data.overflow == null ||
        data.overflow == ''
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
