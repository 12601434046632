<!-- Modal de cambio de entidad -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "detect-outliers" | translate }}
    </h5>
  </div>
  <div class="dialog-body outliers-dialog">
    <!-- Fechas -->
    <app-material-date-selector
      [dateRange]="dateRange"
      [initialDateNull]="true"
      (dateRangeUpdated)="updateDateRange($event)"
    ></app-material-date-selector>
    <!-- Método -->
    <app-material-select
      class="outliers-method-selector"
      [title]="'sarima-method' | translate"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      [selected]="defaultMethod"
      [data]="methodList"
      (selectedOption)="method = $event.id"
    ></app-material-select>
    <!-- threshold -->
    <app-material-select
      *ngIf="method == 'twitter'"
      class="outliers-method-selector"
      [title]="'threshold-alarm' | translate"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      [noSelection]="true"
      [selected]="defaultThreshold"
      [data]="thresholdList"
      (selectedOption)="threshold = $event?.id"
    ></app-material-select>
    <mat-form-field
      *ngIf="method == 'average-hourly' || method == 'average-daily'"
      class="material-header-input outliers-average-threshold"
      appearance="standard"
    >
      <mat-label>{{ "threshold-alarm" | translate }}</mat-label>
      <input matInput type="number" min="1" step="0.1" [(ngModel)]="threshold" />
    </mat-form-field>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="!date?.from || !date?.to"
      (click)="detectSelectionOutliers()"
    >
      <i class="fas fa-chart-bar"></i>
      {{ "detect-outliers" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
