import { Component, Input, OnInit } from "@angular/core";
// Highcharts
import { Options } from "highcharts";
// Servicio propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Variables
import { GRAPH_CONFIG } from "../../graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-table-dialog",
  templateUrl: "./table-dialog.component.html",
  styleUrls: ["./table-dialog.component.scss"],
})
export class TableDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  highchartsOptions: Options;
  chartOptions: any;
  parsedHtml: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(public SessionDataService: SessionDataService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.data.graph) {
      this.data.graph.series.map(
        (serie) => (serie.type = serie.type ? serie.type : this.data.graph.type)
      );
      this.setHighchartsOptions();
      this.setChartsOptions();
    }

    if (this.data.html) {
      this.parsedHtml = this.data.html.replace(/\n/g, "<br />");
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    highchartsOptions.plotOptions.series.marker.enabled = false;
    highchartsOptions["tooltip"] = {
      enabled: true,
      outside: false,
    };
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["rangeSelector"];
    chartOptions["series"] = this.data.graph.series;
    chartOptions["yAxis"] = [{ title: { text: "" } }];
    chartOptions["legend"] = {
      enabled: false,
    };
    chartOptions["navigator"] = {
      enabled: false,
    };
    this.chartOptions = chartOptions;
  }
}
