// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class LanguageControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Actualizar idioma de usuario
  changeLang(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/user/update-language/", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }
}
