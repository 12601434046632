// @angular
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { UserControllerService } from "../../../services/server/UserController.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";

@Component({
  selector: "app-subscriber-edit",
  templateUrl: "./subscriber-edit.component.html",
  styleUrls: ["./subscriber-edit.component.scss"],
})
export class SubscriberEditComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  sessionLanguage: string;
  languageSub: Subscription;

  // Lista de idiomas
  languagesList: object = [
    { id: "ES", descripcion: "Castellano" },
    { id: "EN", descripcion: "English" },
    { id: "CA", descripcion: "Catalá" },
    { id: "FR", descripcion: "Français" },
    { id: "IT", descripcion: "Italiano" },
  ];
  languageInitialSelection: string;

  // Contraseña
  showPassword: boolean = false;
  showPasswordRequeriments: boolean = false;
  repeatedPassword: string;

  // Usuario
  userData: any = {
    email: null,
    language: null,
    name: null,
    password: null,
    phoneNumber: null,
    phoneNumber2: null,
    profile: null,
    surname: null,
    username: null,
  };

  // Body
  body = document.body;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private UserController: UserControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language) => {
        this.sessionLanguage = language;
      }
    );

    this.getUserData();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.languageSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos de usuario para edición
  getUserData(): void {
    this.UserController.getSubscriberData().subscribe((response) => {
      if (response["code"] == 0) {
        let userData: any = response["body"];
        userData.password = null;
        this.userData = userData;
        this.languageInitialSelection = this.userData.language;
      }
    });
  }

  // Reseteo del formulario
  reset(event: any): void {
    event.preventDefault();
    this.ReloadComponentService.reload();
  }

  // Guardado de los datos de usuario
  saveUserData(): void {
    if (
      this.userData.password &&
      this.userData.password !== this.repeatedPassword
    ) {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("passwords-not-match")
      );
    } else {
      let data: any = {
        username: this.userData.username,
        password: this.userData.password,
        name: this.userData.name,
        surname: this.userData.surname,
        email: this.userData.email,
        phoneNumber: this.userData.phoneNumber,
        phoneNumber2: this.userData.phoneNumber2,
        language: this.userData.language,
      };

      // Guardado de datos
      this.UserController.updateSubscriberData(data).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
        }
      });
    }
  }

  // Actualización de idioma
  updateLanguage(): void {
    this.SessionDataService.sendLanguage(
      this.userData.language == "CA"
        ? "es-ca"
        : this.userData.language.toLowerCase()
    );
  }

  // Redirección a la página anterior
  back(): void {
    // Evita las barras de scroll en la interfaz de abonado
    this.body.style.overflow = "hidden";
    this.router.navigate(["/abonado/principal"]);
  }
}
