// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { MeterService } from "../../../MeterService.service";
import { FrameDescriptorService } from "./frame-parsers/frame-descriptor.service";
import { SagemcomDecoderService } from "./frame-parsers/sagemcom-decoder.service";
import { IntegraDecoderService } from "./frame-parsers/integra-decoder.service";
import { ViewshineDecoderService } from "./frame-parsers/viewshine-decoder.service";
import { HoneywellGasDecoderService } from "./frame-parsers/honeywell-gas-decoder.service";
import { BetaDecoderService } from "./frame-parsers/beta-decoder.service";
import { AimeiDecoderService } from "./frame-parsers/aimei-decoder.service";
// Interfaces
import { Entity } from "../../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableHighlightRow,
  TableSelectColumn,
} from "../../../../../../../modules/table-module/TableInterface.type";
import {
  DetailDevice,
  DeviceFrameLog,
  DialogAction,
} from "../../../../DeviceInterface.type";
// Componentes
import { MeterLogFramesDialogComponent } from "./meter-log-frames-dialog/meter-log-frames-dialog.component";
import { PanelMenuOption } from "../../../../../../../modules/material-module/MaterialInterface.type";
import { TableControllerComponent } from "../../../../../../../modules/table-module/table-controller/table-controller.component";

@Component({
  selector: "app-meterlogframes",
  templateUrl: "./meter-log-frames.component.html",
  styleUrls: ["./meter-log-frames.component.scss"],
})
export class MeterLogFramesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 50;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  meter: DetailDevice;
  meterFrames: DeviceFrameLog[];
  originalMeterFrames: DeviceFrameLog[];
  framesFiltered: boolean = false;
  framesHighlighted: boolean = false;
  orderBy: object = { attribute: "timestamp", reverse: true };
  exportFileName: string =
    this.translate.instant("frames-export") +
    " " +
    this.DateParserService.getDate();
  from: string;
  to: string;
  tableSelectedData: DeviceFrameLog[];
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightRepeated",
      color: "yellow",
      title: "frames-repeated",
    },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "erase-selection",
      icon: "fas fa-trash",
      selectionRequired: true,
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  manufacturersWithDecryptor: string[] = [
    "LW_MBUS",
    "SAGEMCOM",
    "INTEGRA",
    "VIEWSHINE",
    "HONEYWELL_GAS",
    "BETA",
    "AIMEI",
  ];

  // Modal
  dialog: Subscription;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private FrameDescriptorService: FrameDescriptorService,
    private IntegraDecoderService: IntegraDecoderService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SagemcomDecoderService: SagemcomDecoderService,
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private ViewshineDecoderService: ViewshineDecoderService,
    private HoneywellGasDecoderService: HoneywellGasDecoderService,
    private BetaDecoderService: BetaDecoderService,
    private AimeiDecoderService: AimeiDecoderService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Acción de modal
    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: DialogAction) => {
        this.getDialogAction(dialogAction);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meter = history.state.data;
    this.meter.parser = this.MeterService.checkMeterManufacturerParser(
      this.meter
    );
    this.setColumns();
    this.setPanelMenuOptions();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.framesHighlighted = false;
    this.framesFiltered = false;
    this.MeterController.getMeterFrames(
      this.meter.id,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let meterFrames: DeviceFrameLog[] = response["body"];
          if (this.meter.parser) {
            meterFrames.map((frame) => (frame.parserAvailable = true));
          }
          this.meterFrames = meterFrames;
          this.originalMeterFrames = meterFrames;
          if (this.manufacturersWithDecryptor.includes(this.meter.parser)) {
            this.getFramesType();
          }
        } else {
          this.meterFrames = [];
          this.originalMeterFrames = [];
        }
      }
    });
  }

  // Seteo de columnas
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "frame-parse",
            tooltip: "frame-parse",
            icon: "fas fa-language",
            visible: {
              attribute: "parserAvailable",
              rule: true,
            },
            disabled: "micError",
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "gateway",
        data: "gwUnidadVenta",
        search: "gwUnidadVenta",
        sort: "gwUnidadVenta",
        visible: true,
      },
      {
        title: "sent-hour",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "channel",
        data: "channelParsed",
        search: "channelParsed",
        sort: "channel",
        numerical: true,
        visible: true,
      },
      {
        title: "Mic error",
        data: "micError",
        search: "micError",
        sort: "micError",
        alter: {
          condition: "micError",
          skins: [
            { rule: true, class: "fas fa-exclamation-triangle red" },
            { rule: false, class: "" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "SF",
        data: "sfParsed",
        search: "sfParsed",
        sort: "sf",
        numerical: true,
        visible: true,
      },
      {
        title: "RSSI",
        data: "rssiParsed",
        search: "rssiParsed",
        sort: "rssi",
        numerical: true,
        visible: true,
      },
      {
        title: "SNR",
        data: "snrParsed",
        search: "snrParsed",
        sort: "snr",
        numerical: true,
        visible: true,
      },
      {
        title: "type",
        data: "frameType",
        search: "frameType",
        sort: "frameType",
        visible: this.manufacturersWithDecryptor.includes(this.meter.parser)
          ? true
          : null,
      },
      {
        title: "frame",
        data: "allFrameData",
        search: "allFrameData",
        sort: "allFrameData",
        long: true,
        visible: true,
      },
    ];
  }

  // Obtención del tipo de tramas
  getFramesType(): void {
    switch (this.meter.parser) {
      case "SAGEMCOM":
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType =
              this.SagemcomDecoderService.Sagemcom_Gas_Descriptor(
                frame.allFrameData
              ))
        );
        break;
      case "INTEGRA":
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType = this.IntegraDecoderService.Integra_Descriptor(
              frame.allFrameData
            ))
        );
        break;
      case "VIEWSHINE":
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType =
              this.ViewshineDecoderService.Viewshine_Descriptor(
                frame.allFrameData
              ))
        );
        break;
      case "HONEYWELL_GAS":
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType =
              this.HoneywellGasDecoderService.Honeywell_Gas_Descriptor(
                frame.allFrameData
              ))
        );
        break;
      case "BETA":
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType = this.BetaDecoderService.Beta_Gas_Descriptor(
              frame.allFrameData
            ))
        );
        break;
      case "AIMEI":
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType = this.AimeiDecoderService.Aimei_Descriptor(
              frame.allFrameData
            ))
        );
        break;
      default:
        this.meterFrames.map(
          (frame: DeviceFrameLog) =>
            (frame.frameType =
              this.FrameDescriptorService.LoRaWAN_MBus_Descriptor(
                frame.allFrameData
              ))
        );
        break;
    }
  }

  // Actualización del componente
  updateData(): void {
    this.meterFrames = [];
    this.getData(this.from, this.to);
  }

  // Filtrado de tramas
  updateFramesFilter(highlightRepeated?: boolean): void {
    if (this.framesFiltered || highlightRepeated) {
      let filteredMeterFrames = [];
      this.originalMeterFrames.forEach((meterFrame: DeviceFrameLog) => {
        let frameFound = filteredMeterFrames.findIndex(
          (filteredMeterFrame) =>
            filteredMeterFrame.allFrameData == meterFrame.allFrameData
        );

        if (frameFound >= 0) {
          if (
            filteredMeterFrames[frameFound].timestamp >= meterFrame.timestamp
          ) {
            filteredMeterFrames[frameFound].highlightRepeated =
              highlightRepeated;
            filteredMeterFrames[frameFound] = meterFrame;
          }
        } else {
          filteredMeterFrames.push(meterFrame);
        }
      });
      this.framesFiltered ? (this.meterFrames = filteredMeterFrames) : null;
      this.framesHighlighted ? this.tableController.setHighlightedRows() : null;
    } else {
      this.originalMeterFrames.map(
        (meter) => (meter.highlightRepeated = false)
      );
      this.framesHighlighted = false;
      this.framesFiltered = false;
      this.meterFrames = [...this.originalMeterFrames];
    }
  }

  // Relanza las tramas seleccionadas
  relaunchFramesSelected(actionData: DialogAction): void {
    let framesWithMicError: number[] = [];
    let framesWithoutMicError: number[] = [];
    let relaunchRequests = [];

    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("frames-relaunch-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.tableSelectedData?.forEach((frame: DeviceFrameLog) => {
          frame.micError
            ? framesWithMicError.push(frame.id)
            : framesWithoutMicError.push(frame.id);
        });

        // Petición para dispositivos con Mic error
        if (framesWithMicError.length > 0) {
          relaunchRequests.push(
            this.MeterController.relaunchMeterIdMicFrames(
              this.meter.id,
              actionData.relaunchWithAlarms,
              framesWithMicError
            )
          );
        }

        // Petición para dispositivos sin Mic error
        if (framesWithoutMicError.length > 0 && !actionData.relaunchOnlyMic) {
          relaunchRequests.push(
            this.MeterController.relaunchMeterIdFrames(
              this.meter.id,
              actionData.relaunchWithAlarms,
              framesWithoutMicError
            )
          );
        }

        // Peticiones secuenciales para evitar errores de procesamiento de tramas
        this.spinner.show("spinner-hard");
        if (relaunchRequests.length > 0) {
          relaunchRequests[0].subscribe((response1) => {
            if (relaunchRequests.length > 1) {
              relaunchRequests[1].subscribe((response2) => {
                if (response1["code"] == 0 && response2["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("frames-relaunch-success")
                  );
                }
                this.meterFrames = [];
                this.getData(this.from, this.to);
                this.spinner.hide("spinner-hard");
              });
            } else if (response1["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("frames-relaunch-success")
              );
              this.meterFrames = [];

              this.getData(this.from, this.to);
              this.spinner.hide("spinner-hard");
            } else {
              this.spinner.hide("spinner-hard");
            }
          });
        }
      }
    });
  }

  // Relanza las tramas en rango de fecha
  relaunchFramesInRange(actionData: DialogAction): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("frames-relaunch-question-date")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let startDate =
          actionData.relaunchInitDate.split("-").reverse().join("/") +
          " " +
          (actionData.relaunchInitHour ? actionData.relaunchInitHour : "00:00");
        let endDate =
          actionData.relaunchFinalDate.split("-").reverse().join("/") +
          " " +
          (actionData.relaunchFinalHour
            ? actionData.relaunchFinalHour
            : "23:59");

        startDate = this.DateParserService.toTimestamp(startDate, "L HH:mm");
        endDate = this.DateParserService.toTimestamp(endDate, "L HH:mm");

        // Petición para dispositivos con Mic error
        let relaunchRequests = [
          this.MeterController.relaunchMeterMicFrames(
            this.meter.id,
            actionData.relaunchWithAlarms,
            startDate,
            endDate
          ),
        ];

        // Petición para dispositivos sin Mic error
        if (!actionData.relaunchOnlyMic) {
          relaunchRequests.push(
            this.MeterController.relaunchMeterFrames(
              this.meter.id,
              actionData.relaunchWithAlarms,
              startDate,
              endDate
            )
          );
        }

        // Peticiones secuenciales para evitar errores de procesamiento de tramas
        this.spinner.show("spinner-hard");
        relaunchRequests[0].subscribe((response1) => {
          if (relaunchRequests.length > 1) {
            relaunchRequests[1].subscribe((response2) => {
              if (response1["code"] == 0 && response2["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("frames-relaunch-success")
                );
              }
              this.meterFrames = [];
              this.getData(this.from, this.to);
              this.spinner.hide("spinner-hard");
            });
          } else if (response1["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("frames-relaunch-success")
            );
            this.meterFrames = [];
            this.getData(this.from, this.to);
            this.spinner.hide("spinner-hard");
          } else {
            this.spinner.hide("spinner-hard");
          }
        });
      }
    });
  }

  // Modal relanzamiento de tramas
  relaunchFrames(): void {
    this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
      meters: this.tableSelectedData,
      action: "relaunch",
    });
  }

  // Borrado de tramas
  deleteFrames(frame?: DeviceFrameLog): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-frames-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteMeterFrames(
          this.meter.id,
          frame ? [frame.id] : this.tableSelectedData.map((frame) => frame.id)
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("frames-deleted")
            );
            this.getData(this.from, this.to);
          }
        });
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, frame: DeviceFrameLog): void {
    switch (action) {
      case "delete":
        this.deleteFrames(frame);
        break;
      case "frame-parse":
        this.MeterService.parseLorawanFrame(this.meter, frame);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "erase-selection":
        this.deleteFrames();
        break;
      default:
        break;
    }
  }

  // Acción de modal
  getDialogAction(dialogAction: DialogAction): void {
    switch (dialogAction.action) {
      case "relaunchFramesSelected":
        this.relaunchFramesSelected(dialogAction);
        break;
      case "relaunchFramesInRange":
        this.relaunchFramesInRange(dialogAction);
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "event-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("event-log"),
        visible: this.sessionProfile == "ARSON",
      },
      {
        action: "user-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("user-log"),
        visible: true,
      },
      {
        action: "communication-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("communication-log"),
        visible:
          this.sessionProfile == "ARSON" || this.currentAgrupation.id == 60,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "event-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/eventos/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "user-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/usuarios/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "communication-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/comunicaciones/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      default:
        break;
    }
  }
}
