// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Interfaces
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../table-module/TableInterface.type";

@Component({
  selector: "app-notification-modal",
  templateUrl: "./notification-modal.component.html",
  styleUrls: ["./notification-modal.component.scss"],
})
export class NotificationModalComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  notificationData: any;
  notificationDataSub: Subscription;
  sessionProfile: string;

  // Tabla
  orderBy: object = { attribute: "fechaEnvío", reverse: false };
  tableMaxReg: number = 10;
  highlightRow: object[] = [{ condition: "pending", color: "yellow" }];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.notificationData =
      this.SessionDataService.getCurrentNotificationData();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    this.notificationDataSub =
      this.SessionDataService.getNotificationData().subscribe(
        (notificationData) => {
          this.notificationData = notificationData;
        }
      );

    this.setColumns();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.notificationDataSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  setColumns(): void {
    this.columns = [
      {
        title: "date",
        data: "fechaEnvioParsed",
        search: "fechaEnvio",
        sort: "fechaEnvio",
        visible: true,
      },
      {
        title: "header",
        data: "cabecera",
        search: "cabecera",
        sort: "cabecera",
        long: true,
        visible: this.sessionProfile != "ABONADO" ? true : null,
      },
      {
        title: "message",
        data: "mensaje",
        search: "mensaje",
        sort: "mensaje",
        long: true,
        visible: true,
      },
    ];
  }
}
