<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <div class="coverage-panel-heading">
          <h5 *ngIf="sessionProfile != 'ARSON'">
            <b>{{ "coverage" | translate }} </b>
          </h5>

          <!-- Filtrado por entidad -->
          <div *ngIf="sessionProfile == 'ARSON'" class="coverage-entity-filter">
            <app-material-select
              class="material-header-select"
              [title]="'coverage' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [selected]="true"
              [data]="coverageSelectOptions"
              (selectedOption)="entityFilter = $event?.value; getMapData()"
            ></app-material-select>
          </div>
        </div>
      </div>

      <div class="panel-body">
        <div #coveragePanel class="coverage-panel">
          <app-map-controller
            *ngIf="mapHeight"
            [mapType]="mapType"
            [map3d]="true"
            (activate3dMapFlag)="activate3dMap()"
            [mapHeight]="mapHeight"
            (actionFlag)="mapAction($event.action, $event.data)"
            [allowKml]="true"
            [filter]="'coverage'"
            (filterUpdateFlag)="updateTableData($event)"
            [locationsData]="locations"
            [gatewaysData]="[]"
            [metersData]="mapDevices"
          >
          </app-map-controller>
        </div>

        <div class="coverage-table">
          <!-- Tabla -->
          <app-table-controller
            [tableId]="'coverageTable'"
            [export]="true"
            [columns]="testColumns"
            [rowNumbers]="true"
            [maxReg]="100"
            [specialFilter]="true"
            (dataFilteredFlag)="updateMapData($event)"
            [data]="tableDevices"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
