import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import moment from "moment";
import { Card } from "../../../modules/card-module/CardInterface.type";
import { LwMbusMainMeter, MbusMeter } from "./ConcentratorInterface.type";
import { ConsumptionProfile } from "../data-analysis/data-analysis-models/data-analysis-models-edit/data-analysis-patterns";

// ANCHOR Estados de válvula
export const VALVE_STATES = {
  0: {
    text: "valve-close",
    background: "bg-red-gradient",
    img: "valve-closed",
  },
  1: {
    text: "valve-open",
    background: "bg-green-gradient",
    img: "valve-open",
  },
  2: {
    text: "valve-pending",
    background: "bg-yellow-gradient",
  },
  3: {
    text: "close-command",
    background: "bg-yellow-gradient",
  },
  4: {
    text: "open-command",
    background: "bg-yellow-gradient",
  },
  5: {
    text: "read-command",
    background: "bg-yellow-gradient",
  },
  6: {
    text: "valve-disconnected",
    background: "bg-yellow-gradient",
  },
  7: {
    text: "valve-no-trigger",
    background: "bg-yellow-gradient",
  },
  8: {
    text: "valve-10-sent",
    background: "bg-yellow-gradient",
  },
  9: {
    text: "valve-50-sent",
    background: "bg-yellow-gradient",
  },
  10: {
    text: "valve-open-10",
    background: "bg-yellow-gradient",
  },
  11: {
    text: "valve-open-50",
    background: "bg-yellow-gradient",
  },
  12: {
    text: "valve-command-close-lost",
    background: "bg-red-gradient",
  },
  13: {
    text: "valve-command-open-lost",
    background: "bg-red-gradient",
  },
  "*": { text: "unknown", background: "bg-gray" },
};

export interface ValveStateData {
  // Back End
  id: string;
  meter: number;
  status: number;
  timestamp: number;

  // Front End
  stateParsed: string;
}

// ANCHOR Estados de SIM
export const SIM_STATES = {
  1: "sim-state-active",
  2: "sim-state-paired",
  3: "sim-state-registered",
  4: "sim-state-down",
  ACTIVE: 1,
  PAIRED: 2,
  REGISTERED: 3,
  DOWN: 4,
};

// ANCHOR Tipos de orden de trabajo
export const METER_ORDER = {
  1: "substitution-order-rf",
  2: "substitution-order-meter",
  3: "substitution-order-all",
  SUSTITUTION_RF_MODULE: 1,
  SUSTITUTION_ONLY_DEVICE: 2,
  SUSTITUTION_DEVICE_COMPLETE: 3,
};

// ANCHOR Estados de orden de trabajo
export const METER_ORDER_STATE = {
  1: "pending",
  2: "cancelled",
  3: "finished",
  PENDING: 1,
  CANCELLED: 2,
  FINISHED: 3,
};

// ANCHOR Orden de trabajo
export interface MeterOrder {
  // Back End
  id: number;
  contador: number;
  type: number;
  comment: string;
  nroSerie: string;
  metrologyType: number;
  activate: number;
  latitude: number;
  longitude: number;
  entity: string;
  agrupation: string;
  createClave: string;
  createClaveMaestra: number;
  createUserId: number;
  createUsername: string;
  createDate: number;
  closeUserId: number;
  closeUsername: string;
  closeDate: number;
  state: number;

  // Fron End
  typeParsed?: string;
  meterLink?: string;
  cupsLink?: string;
  stateParsed?: string;
  cancelDisabled?: boolean;
}

// ANCHOR Datos de dispositivo
export interface DetailDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  agrupation: number;
  alias: string;
  devAddr: string;
  flags: string;
  devEui: string;
  appEui: string;
  networkKey: string;
  idFabricante: number;
  idDevType: number;
  minSf: number;
  txPower: number;
  keys: string[];
  lastRssi: number;
  lastDataRate: number;
  activate: number;
  longitude: number;
  latitude: number;
  gateway: string;
  timezone: string;
  lastJoin: number;
  lastSnr: number;
  lastCommunication: number;
  rfModule: string;
  comments: string;
  adrPermission: number;
  meterImage: string[];
  lastReadedValue: number;
  lastReadedTimestamp: number;
  installation: number;
  metrologyType: number;
  valveState: number;
  lastStateTimestamp: number;
  lastBatValue: number;
  cyble5EncKey: string;
  cyble5AuthKey: string;
  cyble5PulseWeight: number;
  isBMode: boolean;
  consumptionCards: {
    monthlyAverage: number;
    currentMonth: number;
    lastMonth: number;
    lastMonthlyValue: number;
    dailyAverage: number;
  };
  metrologicalConf: {
    id: number;
    contador: number;
    contadorNroSerie: string;
    conversionFactor: number;
    pcs: string;
  };
  tipo: number;
  enRevision: boolean;
  revisionComments: string;
  address: string;
  mbusKey: string;
  listaConcentradores: {
    idConcentrador: number;
    nroSerieConcentrador: string;
  }[];
  comunica: boolean;
  precinto: string;
  lastFlow?: number;
  lastAverageFlow?: number;
  lastFlowTimestamp?: number;
  lwMBusComunicaciones?: MbusMeter[];
  hasNc: boolean;
  lastConsumptionMax?: number;
  lastConsumptionMin?: number;
  lastConsumptionTotal?: number;
  hasAs?: boolean;
  revisionState?: number;
  valveStateChangedTimestamp?: number;

  // FrontEnd
  lastCommunicationParsed?: string;
  lastJoinParsed?: string;
  lastReadedTimestampParsed?: string;
  installationParsed?: string;
  redundantGateways?: DetailDeviceGateway[];
  type?: string;
  isLora?: boolean;
  initialState?: string;
  confirmedConfiguration?: boolean;
  isAddEnprotechValve?: boolean;
  isBMeter?: boolean;
  totalMeters?: number;
  parser?: string;
  mainConcentrators?: LwMbusMainMeter[];
}

// ANCHOR Datos de gateway
export interface DetailDeviceGateway {
  // BackEnd
  id: number;
  unidadVenta: string;
  longitude: string;
  latitude: string;
  rssi: number;
  rssiTimestamp: number;
  snr: number;
  mainMeters: number;
  state?: number;

  // FrontEnd
  otherEntity?: boolean;
  principal?: boolean;
  mainGateway?: boolean;
  idFabricante?: string;
  idMeter?: number;
  rssiTimestampParsed?: string;
  asignateDisabled?: boolean;
  showNoncesDisabled?: boolean;
  gatewayLink?: string;
  stateParsed?: string;
}

// ANCHOR Datos de alarma
export interface DetailDeviceAlarm {
  // BackEnd
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
  latitude: number;
  longitude: number;

  // FrontEnd
  initDateParsed?: string;
  name?: string;
  description?: string;
}

// ANCHOR Datos de concentrador
export interface DetailDeviceConcentrator {
  idConcentrador: number;
  version: string;
  version_modem: string;
  nroContadores: number;
  nroTx: number;
  consumoNroTx: number;
  tiempoUsoBusUne: number;
  consumoTiempoUsoBusUne: number;
  nroLecturas: number;
  nroColisiones: number;
  nroTramas: number;
  nroRepeticiones: number;
  listaHijos: [
    {
      id: number;
      nroSerie: string;
      valor: number;
      timestamp: number;
    }
  ];
  consumoStandBy: number;
  timestamp: number;
  vbat: number;
}

// ANCHOR Datos de gráfica
export interface DetailDeviceGraphData {
  readings: any[][];
  alarms: DetailDeviceAlarm[];
  valveStates?: any;
  sensorReadings?: any;
}

// ANCHOR Datos de gráfica
export interface DetailDeviceSateliteGraphData {
  tm: number;
  rssi: number;
  vcc: number;
}

// ANCHOR Serie de alarmas
export interface DetailDeviceAlarmSerie {
  id: string;
  name: string;
  type: string;
  onSeries: string;
  shape: string;
  color: string;
  width: number;
  style: {
    color: string;
  };
  plotOptions: {
    series: {
      marker: {
        enabled: boolean;
      };
      cursor: string;
    };
  };
  states: {
    hover: {
      fillColor: string;
    };
  };
  data: DetailDeviceAlarmSerieData[];
}

// ANCHOR Datos de serie de alarmas
export interface DetailDeviceAlarmSerieData {
  events: any;
  x: moment.Moment;
  y?: number;
  zIndex?: number;
  title: string;
  text: string;
  fillColor: string;
}

// ANCHOR Serie de datos
export interface DetailDeviceDataSerie {
  id: string;
  name: string;
  type: string;
  data: any;
  marker?: any;
  tooltip?: any;
  color?: string;
  navigatorOptions?: any;
  yAxis?: number;
  xAxis?: number;
  step?: boolean;
  zIndex?: number;
  dataGrouping?: any;
  showInNavigator?: boolean;
  enableMouseTracking?: boolean;
  lineWidth?: number;
  zoneAxis?: string;
  zones?: any;
  events?: any;
  fillOpacity?: number;
}

// ANCHOR Datos de tarjetas
export interface DetailDeviceCardsData {
  meterDetailLastKnownValue?: Card;
  meterDetailLastDataMonth?: Card;
  meterDetailLastMonthConsume?: Card;
  meterDetailConsumeMonthAverage?: Card;
  meterDetailCurrentMonthConsume?: Card;
  meterDetailConsumeAverageDaily?: Card;
  meterDetailBattery?: Card;
  meterDetailMode?: Card;
  meterDetailValve?: Card;
  meterDetailConsumeLastWeek?: Card;
  meterDetailSateliteValve?: Card;
  meterDetailLastFlow?: Card;
  meterDetailLastAverageFlow?: Card;
  meterDetailLastMinConsumption?: Card;
  meterDetailLastMaxConsumption?: Card;
  meterDetailLastTotalConsumption?: Card;
}

// ANCHOR Datos de tarjetas de estado
export interface DetailDeviceStateCards {
  meterDetailSfRssiSnr: Card;
  meterDetailLastFrame: Card;
  meterDetailLastJoin: Card;
  meterDetailMainGateway: Card;
  meterDetailRedundantGateways: Card;
}

// ANCHOR Atributo de dipositivo
export interface DeviceAttribute {
  name: string;
  label: string;
  data: any;
  required: boolean;
  info: string;
  type: string;
  maxlength?: number;
  items?: any[];
  highlight?: boolean;
  profile?: string[];
}

// ANCHOR Atributo de localización del dispositivo
export interface DeviceLocation {
  name: string;
  label: string;
  data: number;
  required: boolean;
  info: string;
}

// ANCHOR Tipo de dispositivo
export interface DeviceType {
  id: string;
  title: string;
  attributes: string[];
}

// ANCHOR Tarjeta SIM
export interface SimCard {
  agrupation: string;
  entity: string;
  estado: number;
  fechaActivacion: number;
  fechaEliminacion: number;
  fechaRegistro: number;
  iccid: string;
  id: number;
  nombre: string;
  nserie: string;
}

// ANCHOR Tarjeta SIM de selector
export interface SimCardMin {
  text: string;
  id: number;
}

// ANCHOR Respuesta de servicio de importación
export interface DeviceImportResonse {
  // BackEnd
  nroSerie: string;
  idFabricante: number;
  idDevType: number;
  uploadState: boolean;
  codError: number;

  // FronteEnd
  responseCodeText?: string;
}

// ANCHOR Ventana de EK280
export class EkWindow {
  public time: string;
  public duration: number;
  public highlightTime?: boolean;
  public highlightDuration?: boolean;

  constructor(window: number) {
    this.time = window == 1 ? "06:05" : window == 2 ? "11:05" : "18:05";
    this.duration = 15;
  }
}

// ANCHOR Dispositivo listado gprs interno
export interface InternalGprsDevice {
  // BackEnd
  id: number;
  tipo: string;
  nserie: string;
  descripcion: string;
  iccidSim: string;
  activo: true;

  // FrontEnd
  disableStatusUpdate: boolean;
}

// ANCHOR Columnas por defecto de importación de lecturas
export const READINGS_IMPORT_COLUMNS = [
  {
    title: "NRO_SERIE",
    info: "nro-serie-import",
    required: true,
    profile: true,
  },
  {
    title: "VALOR",
    info: "reading-value",
    required: true,
    profile: true,
  },
  {
    title: "FECHA",
    info: "reading-date",
    required: true,
    profile: true,
  },
];

// ANCHOR Columnas por defecto de importación de imágenes
export const IMAGES_IMPORT_COLUMNS = [
  {
    title: "NRO_SERIE",
    info: "nro-serie-import",
    required: true,
    profile: true,
  },
  {
    title: "URL",
    info: "image-url",
    required: true,
    profile: true,
  },
];

// ANCHOR Respuesta de importación de lecturas
export interface ImportResponse {
  // BackEnd
  lecturasGuardadas: number;
  totalFilas: number;
  totalErrores: number;
  contadoresNoExistentesONoAutorizados: string[];
  listaErrorValores: string[];

  // Front end
  contadoresNoExistentesParsed: string;
}

// ANCHOR Lista de tarjetas SIM
export interface SimCard {
  // BackEnd
  id: number;
  iccid: string;
  fechaRegistro: number;
  fechaActivacion: number;
  fechaEliminacion: number;
  estado: number;
  nombre: string;
  nserie: string;
  agrupation: string;
  entity: string;
  ip: string;

  // FrontEnd
  fechaRegistroParsed: string;
  fechaActivacionParsed: string;
  fechaEliminacionParsed: string;
  estadoParsed: string;
  isPlum: boolean;
  updateFail: boolean;
}

// ANCHOR Respuesta de importación de SIM
export interface SimResponse {
  // BackEnd
  iccid: string;
  responseCode: number;

  // FrontEnd
  responseCodeText: string;
  state: boolean;
}

// ANCHOR Módulo RF
export interface RF_MODULE {
  id: number;
  rfModule: number;
}

// ANCHOR Sector de dispositivo
export interface DeviceSector {
  // BackEnd
  id: number;
  name: string;
  description: string;
  type: number;
  agrupation: number;

  // FrontEnd
  typeParsed?: string;
  showDisable?: boolean;
}

// ANCHOR Actualización de SF y ADDR
export interface SfAdrUpdateRequestData {
  contadorId: number;
  min_sf: number;
  adrPermission: number;
  txPower: number;
}

// ANCHOR Datos de SIM
export interface DeviceSim {
  id: number;
  iccid: string;
}

// ANCHOR Datos EK280
export interface DeviceEk280 {
  // BackEnd
  codigo: string;
  nserie: string;
  descripcion: string;
  ventana1: number;
  ventana2: number;
  ventana3: number;
  duracion1: number;
  duracion2: number;
  duracion3: number;

  // FrontEnd
  iccid?: string;
  simId?: string;
}

// ANCHOR Datos de SIM
export interface DeviceEk280Sim {
  // BackEnd
  id: string;
  iccid: string;
  estado: number;
  ip: string;

  // FrontEnd
  stateParsed?: string;
}

// ANCHOR Dispositivo de listado no asignados
export interface UnassignedDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  devEui: string;
  appEui: string;
  networkKey: string;
  applicationKey: string;
  otherKeys: string[];
  idFabricante: number;
  idDevType: number;
  fechaAlta: number;
  agrupation: number;
  longitude: number;
  latitude: number;
  gateway: number;
  isActive: boolean;
  comunica: boolean;
  activate: number;
  metrologyType: number;
  tipo: number;
  rfModule: string;
  lastCommunication: number;
  precinto: string;
  activationUser: string;
  devNonce: string;
  appNonce: string;

  // FrontEnd
  isActiveText?: string;
  canBeDeactivated?: boolean;
  fechaAltaParsed?: string;
  fabricante?: string;
  devType?: string;
  metrologyTypeParsed?: string;
  editDisabled?: boolean;
  selectedAgrupation?: string;
  agrupations?: Agrupation[];
  selected?: boolean;
  deviceLink?: string;
  lastCommunicationParsed: string;
  isConcentrator?: boolean;
  nonces?: boolean;
  agrupationParsed?: string;
}

// ANCHOR Dispositivo de sustitución
export interface SubstitutionDevice {
  // BackEnd
  id: number;
  agrupation: string;
  agrupationId: number;
  latitude: number;
  longitude: number;
  nroSerieNew: string;
  idNew: number;
  nroSerieOld: string;
  idOld: number;
  timestamp: number;
  user: string;
  valueNew: number;
  valueOld: number;
  metrologyTypeOld: number;
  metrologyTypeNew: number;
  resubstitutedBy: number;
  cm: {
    clave: string;
    col01: string;
    col02: string;
    col03: string;
    col04: string;
    col05: string;
    col06: string;
    col07: string;
    col08: string;
    col09: string;
    col10: string;
    col11: string;
    col12: string;
    col13: string;
    col14: string;
    col15: string;
    col16: string;
    col17: string;
    col18: string;
    col19: string;
    col20: string;
    isAssigned: boolean;
  };
  oldHasImage: boolean;
  newHasImage: boolean;
  hasAudio: boolean;
  comments: string;
  rfModuleNew: string;
  devTypeModuleNew: number;
  devTypeNew: number;
  fabricanteNew: number;
  precinto: string;

  // FrontEnd
  timestampParsed?: string;
  newDeviceLink?: string;
  oldDeviceLink?: string;
  cups?: string;
  clave?: string;
  col01?: string;
  col02?: string;
  col03?: string;
  col04?: string;
  col05?: string;
  col06?: string;
  col07?: string;
  col08?: string;
  col09?: string;
  col10?: string;
  col11?: string;
  col12?: string;
  col13?: string;
  col14?: string;
  col15?: string;
  col16?: string;
  col17?: string;
  col18?: string;
  col19?: string;
  col20?: string;
  isAssigned?: boolean;
  showAgrupation?: boolean;
  imageDisabled?: boolean;
  audioDisabled?: boolean;
  valueOldParsed?: string;
  valueNewParsed?: string;
  pending?: boolean;
  name?: string;
  surname?: string;
  editDisabled?: boolean;
  selected?: boolean;
  selectDisabled?: boolean;
}

// ANCHOR Dispositivo en revisión
export interface ReviewDevice {
  // BackEnd
  meterId: number;
  meterNroSerie: string;
  agrupationName: string;
  comments: string;
  timestamp: number;
  username: string;
  lastReadedTimestamp: number;
  latitude: number;
  longitude: number;
  metrologyType: number;
  cups?: string;
  cupsId?: number;
  state?: number;

  // FrontEnd
  cupsParsed?: string;
  timestampParsed?: string;
  lastReadedTimestampParsed?: string;
  modalInfo?: boolean;
  modalInfoData?: {
    title: string;
    html: string;
  };
  nroSerie?: string;
  selected?: boolean;
  deviceLink?: string;
  cupsLink?: string;
  highlightFinished?: boolean;
  highlightOpened?: boolean;
  highlightInProcess?: boolean;
  stateParsed?: string;
}

// ANCHOR Estado de revisión
export const REVIEW_STATES = {
  0: "OPENED",
  1: "IN_PROCESS",
  2: "CLOSED",
  OPENED: 0,
  IN_PROCESS: 1,
  CLOSED: 2,
};

// ANCHOR Dispositivo de listado pendientes
export interface PendingDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  lastReadedTimestamp: number;
  lastReadedValue: number;
  clave: string;
  col01: string;
  col02: string;
  col03: string;
  col04: string;
  col05: string;
  col06: string;
  col07: string;
  col08: string;
  col09: string;
  col10: string;
  col11: string;
  col12: string;
  col13: string;
  col14: string;
  col15: string;
  col16: string;
  col17: string;
  col18: string;
  col19: string;
  col20: string;
  metrologyType: number;
  latitude: number;
  longitude: number;
  isAssigned: boolean;
  agrupation: string;

  // FrontEnd
  selected?: boolean;
  pending?: boolean;
  lastReadedTimestampParsed?: string;
  cups?: string;
}

// ANCHOR Dispositivo listado interno
export interface InternalDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  sf: number;
  sfMin: number;
  adrPermission: boolean;
  rssi: number;
  snr: number;
  lastCommunication: number;
  lastReadedValue: number;
  lastJoinTimestamp: number;
  lastReadedTimestamp: number;
  activate: number;
  fabricante: number;
  devType: number;
  installation: number;
  metrologyType: number;
  isBMode: boolean;
  latitude: number;
  longitude: number;
  rfModule: string;
  nroRedundantGateways: number;
  alias: string;
  pc?: number;
  tipo: number;

  // FrontEnd
  activateParsed?: string;
  installationParsed?: string;
  lastCommunicationParsed?: string;
  lastJoinTimestampParsed?: string;
  lastReadedTimestampParsed?: string;
  lastReadedValueParsed?: string;
  isBModeParsed?: string;
  metrologyTypeParsed?: string;
  selected?: boolean;
  deviceLink?: string;
  fabricanteParsed?: string;
  devTypeParsed?: string;
  isConcentrator?: boolean;
  pcParsed?: string;
  isLora?: boolean;
  hasImage?: boolean;
  deviceType?: string;
}

// ANCHOR Respuesta de limpieza de gateways redundantes
export interface CleanResponse {
  activateResponseList: GatewayCleanResponse[];
  meter: string;
}

// ANCHOR Respuesta de cada gateway
export interface GatewayCleanResponse {
  responseCode: number;
  nroSerie?: string;
  unidadVenta: string;
  gatewayId?: number;
}

// ANCHOR Dispositivo minificado listado interno
export interface InternalDeviceMinimal {
  id: number;
  // Nro serie
  ns: string;
  sf: number;
  min_sf: number;
  adr_pr: number;
  rssi: number;
  snr: number;
  // Last comunication
  lc: number;
  // Last readed value
  lrv: number;
  // Last join timestamp
  ljt: number;
  // Last readed timestamp
  lrt: number;
  // Activate
  act: number;
  // Fabricante
  f: number;
  // Devtype
  d: number;
  // Installation
  i: number;
  // Metrology type
  mt: number;
  isBMode: boolean;
  // Latitude
  lt: number;
  // Longitude
  lg: number;
  // RfModule
  rf: string;
  // NroRedundantGateways
  nrg: number;
  // Alias
  a: string;
  // Consumo máximo
  cmx: number;
  // Consumo mínimo
  cmn: number;
  // Consumo total
  cmt: number;
  // Lecturas MBUS
  pc: number;
  // Tipo
  tp: number;
  // Imagen
  im: boolean;
}

// ANCHOR Dispositivo desactivado
export interface DeactivatedDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  lastCommunication: number;
  lastReadedValue: number;
  metrologyType: number;
  latitude: number;
  longitude: number;
  agrupation: number;

  // FrontEnd
  metrologyTypeParsed?: string;
  lastCommunicationParsed?: string;
  lastReadedValueParsed?: string;
  deviceLink?: string;
  isConcentrator?: boolean;
}

// ANCHOR Dispositivo con batería baja
export interface LowBatteryDevice {
  // BackEnd
  id: number;
  contador: number;
  nro_serie: string;
  fechaBateria: number;
  installation: number;
  value: number;
  agrupation: number;
  latitude: number;
  longitude: number;
  fabricante: string;
  devType: string;

  // FrontEnd
  fechaBateriaParsed?: string;
  installationParsed?: string;
  valueParsed?: string;
  agrupationName?: string;
  days?: number;
  fabricanteParsed?: string;
  devTypeParsed?: string;
}

// ANCHOR Dispositivo en red
export interface AssignedDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  lastCommunication: number;
  lastReadedValue: number;
  clave: string;
  col01: string;
  col02: string;
  col03: string;
  col04: string;
  col05: string;
  col06: string;
  col07: string;
  col08: string;
  col09: string;
  col10: string;
  col11: string;
  col12: string;
  col13: string;
  col14: string;
  col15: string;
  col16: string;
  col17: string;
  col18: string;
  col19: string;
  col20: string;
  isAssigned: boolean;
  metrologyType: number;
  latitude: number;
  longitude: number;
  consumptionCardsLastMonth: number;
  valveState: number;
  precinto: string;
  agrupation: number;
  fabricante: number;
  devType: number;
  rfModule: string;
  comments: string;
  gateway: number;
  lastConsumptionMax: number;
  lastConsumptionMin: number;
  lastConsumptionTotal: number;
  fcv?: number;
  pcs?: string;
  pc?: number;
  tipo: number;
  comunica?: boolean;
  valveStateChangedTimestamp?: number;

  // FrontEnd
  metrologyTypeParsed?: string;
  lastCommunicationParsed?: string;
  lastReadedValueParsed?: string;
  cups?: string;
  selected?: boolean;
  consumptionCardsLastMonthParsed?: string;
  deviceLink?: string;
  valveStateParsed?: string;
  agrupationName?: string;
  deviceType?: string;
  isConcentrator?: boolean;
  loadGraph?: boolean;
  graphData?: any;
  pcParsed?: string;
  meterLink?: string;
  consumptionProfile?: ConsumptionProfile;
  consumptionProfileName?: string;
  pattern?: ConsumptionProfile;
  patternAssign?: boolean;
  inNetwork?: boolean;
}

// ANCHOR Dispositivo con error de activación
export interface ActivationErrorDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  latitude: number;
  longitude: number;
  rfModule: string;
  comments: string;
  timestamp: number;
  agrupation: string;
  userId: number;
  username: string;
  responseCode: number;
  processed: number;
  precinto: string;

  // FrontEnd
  timestampParsed?: string;
  processedParsed?: string;
  finished?: boolean;
  selected?: boolean;
  responseCodeParsed?: string;
}

// ANCHOR Datos de comunicación de EK280
export interface ekTestData {
  // BackEnd
  fechaHora: number;
  numeroSerie: string;
  volumenSinCorregirTotal: number;
  volumenCorregidoTotal: number;
  energiaTotal: number;
  volumenSinCorregirError: number;
  volumenCorregidoTotalError: number;
  energiaErrorTotalError: number;
  presion: number;
  temperatura: number;
  factorCorreccion: number;
  factorCompresibilidadMedicion: number;
  factorCompresibilidadBase: number;
  caudalSinCorregirTotal: number;
  caudalCorregidoTotal: number;
  estado: number;
  nivelRecepcionGSM: number;
  tensionBateriaModem: number;
  tiempoRestanteVidaBateria: number;

  // FrontEnd
  fechaHoraParsed: string;
}

// ANCHOR Datos de log de usuario
export interface DeviceUserLog {
  // BackEnd
  id: number;
  contador: number;
  meterNroSerie: string;
  gateway: number;
  gwUnidadVenta: string;
  username: string;
  eventType: number;
  eventDetails: string;
  responseCommand: number;
  timestamp: number;

  // FrontEnd
  timestampParsed?: string;
  eventTypeParsed?: string;
  responseCommandParsed?: string;
}

// ANCHOR Datos de log de tramas
export interface DeviceFrameLog {
  // BackEnd
  id: number;
  gateway: number;
  gwUnidadVenta: string;
  sf: number;
  rssi: number;
  channel: number;
  timestamp: number;
  loraHeader: string;
  payload: string;
  allFrameData: string;
  micError: boolean;
  snr: number;
  fport: number;

  // FrontEnd
  timestampParsed?: string;
  highlightRepeated?: boolean;
  parserAvailable?: boolean;
  frameType?: string;
}

// ANCHOR Acción de log de tramas
export interface DialogAction {
  action: string;
  relaunchOnlyMic: boolean;
  relaunchWithAlarms: boolean;
  relaunchInitDate?: string;
  relaunchInitHour?: string;
  relaunchFinalDate?: string;
  relaunchFinalHour?: string;
}

// ANCHOR Datos de lo de evento
export interface DeviceEventLog {
  // BackEnd
  id: number;
  contador: number;
  gateway: number;
  unidadVenta: string;
  frameType: string;
  command: string;
  subcommand: string;
  devEui: string;
  responseCommand: number;
  timestamp: number;
  nroSerie: string;

  // FrontEnd
  timestampParsed?: string;
}

// ANCHOR Log de comunicación
export interface DeviceCommunicationLog {
  // BackEnd
  id: number;
  gateway: number;
  gwUnidadVenta: string;
  sf: number;
  rssi: number;
  channel: number;
  timestamp: number;
  loraHeader: string;
  payload: string;
  allFrameData: string;
  micError: boolean;
  snr: number;
  fport: number;

  // FrontEnd
  timestampParsed?: string;
  parseDisabled?: boolean;
}

// ANCHOR Mover lecturas/consumos
export interface DeviceReadingsMoveData {
  // BackEnd
  minTimestampReadings: number;
  maxTimestampReadings: number;
  countReadings: number;
  minTimestampConsumptions: number;
  maxTimestampConsumptions: number;
  countConsumptions: number;

  // FrontEnd
  minTimestampReadingsParsed?: number;
  maxTimestampReadingsParsed?: number;
  minTimestampConsumptionsParsed?: number;
  maxTimestampConsumptionsParsed?: number;
}

// ANCHOR Fabricantes MBUS
export interface MbusManufacturer {
  // Back End
  id?: number;
  clave?: string;
  identificador: string;

  // Fron End
  identificadorParsed: string;
}

// ANCHOR Configuración EK280
export interface Ek280Config {
  codigo: string;
  nserie: string;
  descripcion: string;
  ventana1: number;
  ventana2: number;
  ventana3: number;
  duracion1: number;
  duracion2: number;
  duracion3: number;
  simId: number;
}

// ANCHOR Datos de desasignación
export interface DeviceDeallocateData {
  contador: number;
  gateways: number[];
}

// ANCHOR Datos de dispositivo editado
export interface EditedDeviceData {
  meterId: number;
  newNroSerie: string;
  newComments: string;
  newPrecinto: string;
  newDistanciaAcustica?: number;
}

// ANCHOR Datos de activación de dispositivo
export interface DeviceNetworkData {
  id: number;
  devEui: string;
  appEui: string;
  networkKey: string;
  applicationKey: string;
  otherKeys: string[];
}

// ANCHOR Activación de dispositivo
export interface DeviceActivationData {
  nroSerie: string;
  latitude: number;
  longitude: number;
  entity: number;
  agrupation: number;
  rfModule: string;
  comments: string;
  precinto: string;
}

// ANCHOR Mover a mantenimiento
export interface DeviceCheckData {
  contador: number;
  comments: string;
  state: number;
}

// ANCHOR Datos de relanzamiento de tramas
export interface NonceData {
  relaunchFrames: boolean;
  devNonce: string;
  appNonce: string;
  fcnt: number;
  gatewayList?: number[];
}

// ANCHOR Coordenadas de dispositivo
export interface DeviceCoords {
  id: number;
  latitude: number;
  longitude: number;
}

// ANCHOR Actualización de SF
export interface DeviceSfAdrUpdateData {
  contadorId: number;
  min_sf: number;
  adrPermission: number;
}

// ANCHOR Datos de movimiento de lecturas
export interface DeviceMoveData {
  fromMeter: string;
  toMeter: string;
}

// ANCHOR Datos de movimiento de lecturas
export interface DeviceRemoveData {
  listaContadores: number[];
  agrupacion: number;
}

// ANCHOR Datos de revisión
export interface DeviceReviewData {
  agrupation: number;
  fromTimestamp: string | number;
  toTimestamp: string | number;
}

// ANCHOR Datos de batería
export interface DeviceBatteryData {
  cliente: number;
  entidad: number;
  agrupacion: number;
  bateria: number;
}

// ANCHOR Datos de actualización de sustitución
export interface SubstitutionUpdateData {
  valueNew: number;
  valueOld: number;
  precinto: string;
  comments: string;
}

// ANCHOR Datos de corrección de sustitución
export interface SubstitutionCorrectionData {
  nroSerie: string;
  value: number;
}

// ANCHOR Datos de borrado de fabricante MBUS
export interface MbusManufacturersDeletionData {
  id: number;
  listaIds: number[];
}

// ANCHOR Datos de borrado de fabricante MBUS
export interface MbusManufacturersSaveData {
  entidad: number;
  lista: MbusManufacturer[];
}

// ANCHOR Datos de API
export interface ApiData {
  id: number;
  authenticateUrl: string;
  historicUrl: string;
  entity: number;
  name: string;
  text?: string;
}
