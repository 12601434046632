<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>
            {{ "gateway-logs" | translate }}:
            {{ unidadVenta != null ? unidadVenta : ("no-data" | translate) }}</b
          >
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewayEvents'"
          [columns]="columns"
          [export]="true"
          [exportFileName]="'gateway-logs' | translate"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [maxReg]="tableMaxReg"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          [specialFilter]="true"
          [data]="gatewayEvents"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
