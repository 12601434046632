import { Component, Input } from "@angular/core";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-graph-controller-dialog",
  templateUrl: "./graph-controller-dialog.component.html",
  styleUrls: ["./graph-controller-dialog.component.scss"],
})
export class GraphControllerDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  seriesId: string;
  displacement: number;
  displacementSign: string = "add";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(public SessionDataService: SessionDataService) {}
}
