<!-- Modal de selección de App EUI -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="modal-title">{{ "save" | translate }} App EUI</h5>
  </div>
  <div class="dialog-body">
    <mat-form-field
      class="activation-dialog-input-number"
      appearance="standard"
    >
      <mat-label>{{ "introduce" | translate }} App EUI</mat-label>
      <input
        matInput
        appHexInput
        class="gateway-appeui-input"
        [ngClass]="{
          'gateway-appeui-error': appEuiInput?.length > 0
        }"
        [(ngModel)]="appEuiInput"
        autocomplete="off"
        placeholder="{{ 'introduce' | translate }} App Eui (16 {{
          'character' | translate
        }})"
        maxlength="16"
        minlength="16"
      />
    </mat-form-field>
    <app-material-select
      [multiple]="true"
      [clearable]="true"
      [fullWidth]="true"
      [groups]="groups"
      [title]="'entity-appeui' | translate"
      [bindValue]="'id'"
      [bindLabel]="'appEui'"
      [bindGroup]="'manufacturerText'"
      [data]="data"
      (selectedOption)="appEuiSelected = $event"
    ></app-material-select>
  </div>

  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="saveAppEui()"
      [disabled]="
        !(this.appEuiSelected?.length > 0) &&
        (!this.appEuiInput ||
          this.appEuiInput?.length == 0 ||
          this.appEuiInput?.length != 16)
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
