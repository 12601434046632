import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../services/shared/ToastService.service";
import { UserControllerService } from "../../../services/server/UserController.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
// Interface
import { User } from "../../../interfaces/UserGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private UserController: UserControllerService
  ) {}

  // Deshabilitación de usuario
  disableUser(user: User): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("question-user-disable")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.UserController.disableUser(user.id).subscribe((response) => {
            if (response["code"] == 0) {
              if (user.username == this.SessionDataService.getCurrentUser()) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = this.router.url.substring(
                  0,
                  this.router.url.indexOf("#")
                );
              }
              this.ToastService.fireToast(
                "success",
                this.translate.instant("user-disabled")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }

  // Borrado de usuario
  deleteUser(user: User): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("question-user-delete")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.UserController.deleteUser(user.id).subscribe((response) => {
            if (response["code"] == 0) {
              if (user.username == this.SessionDataService.getCurrentUser()) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = this.router.url.substring(
                  0,
                  this.router.url.indexOf("#")
                );
              }
              this.ToastService.fireToast(
                "success",
                this.translate.instant("user-deleted")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }
}
