// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
// Componentes
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
// Interfaces
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import { PendingDevice } from "../../../DeviceInterface.type";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-meter-pending-substitutions",
  templateUrl: "./meter-pending-substitutions.component.html",
  styleUrls: ["./meter-pending-substitutions.component.scss"],
})
export class MeterPendingSubstitutionsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;

  // Tabla
  tableSelectedData: PendingDevice[];
  tableMaxReg: number = 100;
  meterList: PendingDevice[];
  originalMeterList: PendingDevice[];
  exportFileName: string =
    this.translate.instant("substitution-pending") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  extraColumns: EntityDefinition[];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
  ];

  // Redirección
  stayOnRoute: boolean = false;

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DomControllerService: DomControllerService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService
      .getAgrupation()
      .subscribe((agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation") ||
          this.sessionProfile == "ADMIN_INSTALLATION"
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        } else {
          this.stayOnRoute = false;
        }
      });

    this.entityCupsConfSub = this.SessionDataService
      .getEntityCupsConf()
      .subscribe((entityCupsConf) => {
        this.entityCupsConf = entityCupsConf;
        this.getCupsConf();
        this.setColumns();
      });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.updating = true;
    this.mapSelectorActive = false;
    this.MeterController.getPendingSubstitutions(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let meterList: PendingDevice[] = response["body"];
        meterList.forEach((meter: PendingDevice) => {
          meter.cups = meter.isAssigned ? meter.clave : null;
        });
        this.meterList = meterList;
        this.originalMeterList = meterList;
      } else {
        this.meterList = [];
        this.originalMeterList = [];
      }
      this.updating = false;
      this.getCupsConf();
      this.setColumns();
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableSelectColumn | TableDataColumn)[] = [
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "deviceLink",
      },
      {
        title: "groups",
        data: "agrupation",
        search: "agrupation",
        sort: "agrupation",
        visible: this.currentAgrupation.showAllEntity ? true : null,
      },
      {
        title: "alias",
        data: "alias",
        search: "alias",
        sort: "alias",
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValue",
        search: "lastReadedValue",
        sort: "lastReadedValue",
        visible: true,
        date: true,
      },
      {
        title: "date",
        data: "lastReadedTimestampParsed",
        search: "lastReadedTimestampParsed",
        sort: "lastReadedTimestamp",
        visible: true,
        date: true,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: true,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: true,
      },
      {
        title: this.cups?.name,
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: this.cups != null ? true : null,
      },
    ];
    this.extraColumns?.forEach((extraColumn: EntityDefinition) => {
      if (extraColumn.show) {
        let attributePosition: string =
          extraColumn.colPosition <= 9
            ? "col0" + extraColumn.colPosition
            : "col" + extraColumn.colPosition;
        if (extraColumn.colPosition != 100) {
          let newColumn: TableDataColumn = {
            title: extraColumn.name,
            data: attributePosition,
            search: attributePosition,
            sort: attributePosition,
            long: true,
            visible: true,
          };
          columns.push(newColumn);
        }
      }
    });
    this.columns = columns;
  }

  // Actualización de los datos
  updateData(): void {
    this.meterList = null;
    this.getData();
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Obtención de la configuración de CUPS
  getCupsConf(): void {
    if (this.entityCupsConf) {
      this.extraColumns = [...this.entityCupsConf];
      this.cups = this.extraColumns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      let cupsIndex: number = this.extraColumns.findIndex(
        (column: EntityDefinition) => column.colPosition == 0
      );
      if (cupsIndex >= 0) {
        this.extraColumns.splice(cupsIndex, 1);
      }
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      default:
        break;
    }
  }
}
