// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class ControlAlertsControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Listado de la configuración disponible
  getAlerts(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/alert-conf/list/" + entityId);
  }

  // Listado de la configuración de alarmas del CUPS
  getAlertsByCups(cupsId: number): Observable<object> {
    return this.HttpRequestService.get("/alert-conf/cm/list/" + cupsId);
  }

  // Pantalla principal de una alerta
  getInfo(alertId: number): Observable<object> {
    return this.HttpRequestService.get("/alert/main/" + alertId);
  }

  // Cierre de la alerta
  finishAlert(alertId: number): Observable<object> {
    return this.HttpRequestService.get("/alert/close/" + alertId);
  }

  // Alertas activas por permisos del usuario
  getMarkersAndCardClientAll(): Observable<object> {
    return this.HttpRequestService.get("/alert/map/");
  }

  // Alertas activas en una agrupación seleccionada
  getMarkersAndCardClientByAgrupation(
    agrupationId: number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alert/map-by-agrupation/" + agrupationId
    );
  }

  // Alertas activas en una entidad seleccionada
  getMarkersAndCardClientByEntity(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/alert/map-by-entity/" + entityId);
  }

  // Alertas activas en un cliente seleccionado
  getMarkersAndCardClientByClient(clientId: number): Observable<object> {
    return this.HttpRequestService.get("/alert/map-by-client/" + clientId);
  }

  // Pantalla principal de una alerta
  getAlertData(alertId: number): Observable<object> {
    return this.HttpRequestService.get("/alert/history/main/" + alertId);
  }

  // Alertas por permisos del usuario
  getHistoricAlertsByUser(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alert/history/from/" + from + "/to/" + to
    );
  }

  // Alertas en una agrupación seleccionada
  getHistoricAlertsByAgrupation(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alert/history-by-agrupation/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Alertas en una entidad seleccionada
  getHistoricAlertsByEntity(
    entityId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alert/history-by-entity/" + entityId + "/from/" + from + "/to/" + to
    );
  }

  // Alertas en un cliente seleccionado
  getHistoricAlertsByClient(
    clientId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alert/history-by-client/" + clientId + "/from/" + from + "/to/" + to
    );
  }
}
