<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div #homePanel class="panel-body home-panel">
        <div
          #homeCards
          class="home-cards-container"
          [ngClass]="{
            'home-cards-full-column': devicesTotal == 0 || graphsHidden
          }"
        >
          <!-- Tarjetas de datos -->
          <app-cards-controller
            *ngIf="cardsData"
            id="home-cards"
            [cardsId]="'home'"
            [cardWidth]="cardWidth"
            [cardsGap]="cardsGap"
            [cardEditable]="cardEditable"
            [graphVisibility]="devicesTotal != 0"
            (hideGraphsFlag)="graphsHidden = $event"
            [cardsOrder]="cardsOrder"
            [data]="cardsData"
            (visibilityUpdatedFlag)="resizeMap()"
            [loadingCards]="loadingCards"
          >
          </app-cards-controller>
        </div>

        <!-- Gráfica -->
        <div
          #homeGraphs
          *ngIf="devicesTotal != 0 && !graphsHidden"
          class="home-graphs-container"
        >
          <!-- Gráfica circular -->
          <app-graph-controller
            *ngIf="mainHighchartsOptions; else elseBlock"
            [highchartsOptions]="mainHighchartsOptions"
            [noOptionsParse]="true"
            [onlyGraph]="true"
            [disableShowPoints]="true"
            [data]="mainChartOptions"
          >
          </app-graph-controller>

          <!-- Gráfica pictograma -->
          <div class="home-picto-graphs">
            <app-graph-controller
              class="home-alarms-graph"
              *ngIf="secondaryHighchartsOptions"
              [highchartsOptions]="secondaryHighchartsOptions"
              [noOptionsParse]="true"
              [onlyGraph]="true"
              [disableShowPoints]="true"
              [data]="secondaryChartOptions"
            >
            </app-graph-controller>
            <!-- <app-graph-controller
              class="home-alarms-graph"
              *ngIf="gaugeHighchartsOptions"
              [highchartsOptions]="gaugeHighchartsOptions"
              [noOptionsParse]="true"
              [onlyGraph]="true"
              [disableShowPoints]="true"
              [data]="gaugeChartOptions"
            >
            </app-graph-controller> -->
          </div>
        </div>

        <!-- Mapa de contadores -->
        <div class="home-map-container" #mapContainer>
          <div>
            <app-map-controller
              *ngIf="mapHeight"
              [mapHeight]="mapHeight"
              [filter]="mapFilter"
              [map3d]="sessionProfile == 'ARSON'"
              [mapType]="mapType"
              [drawAgrupationOutline]="drawAgrupationOutline"
              [freeDrawEnabled]="true"
              [allowKml]="true"
              [exportKml]="true"
              [mapSearch]="mapSearch"
              (selectedDevices)="goToTable($event)"
              (activate3dMapFlag)="activate3dMap()"
              [gatewaysData]="gateways"
              [metersData]="devices"
              [locationsData]="gatewayLocations"
            >
            </app-map-controller>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
