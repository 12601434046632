<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "replace-cups-meter" | translate }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <div>
          <h4>
            <b>{{ "file-import" | translate }}</b>
          </h4>
          <h5 class="meter-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="importDevice($event)"
          ></app-file-import>
        </div>

        <app-import-table [importColumns]="importColumns"> </app-import-table>

        <h5 *ngIf="showTable">
          <b>{{ "meter-list" | translate }}</b>
        </h5>

        <!-- Tabla -->
        <ng-container *ngIf="showTable">
          <hr />
          <app-table-controller
            [tableId]="'metersImport'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [data]="tableData"
          >
          </app-table-controller>
        </ng-container>
      </div>
    </div>
  </div>
</section>
