// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { InternalGprsDevice } from "../DeviceInterface.type";

@Component({
  selector: "app-device-gprs-list",
  templateUrl: "./device-gprs-list.component.html",
  styleUrls: ["./device-gprs-list.component.scss"],
})
export class DeviceGprsListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Table
  tableData: InternalGprsDevice[];
  exportFileName: string =
    this.translate.instant("internal-list-gprs") +
    " " +
    this.DateParserService.getDate();
  tableMaxReg: number = 100;
  columns: (TableDataColumn | TableActionColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "activate",
          tooltip: "activate",
          icon: "fas fa-plug",
          visible: { attribute: "activo", rule: false },
          disabled: "disableStatusUpdate",
        },
        {
          name: "deactivate",
          tooltip: "deactivate",
          icon: "fas fa-power-off",
          visible: { attribute: "activo", rule: true },
          disabled: "disableStatusUpdate",
        },
      ],
      visible: true,
    },
    {
      title: "active",
      data: "activo",
      search: "activo",
      sort: "activo",
      alter: {
        condition: "activo",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "type",
      data: "tipo",
      search: "tipo",
      sort: "tipo",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nserie",
      search: "nserie",
      sort: "nserie",
      visible: true,
    },
    {
      title: "SIM",
      data: "iccidSim",
      search: "iccidSim",
      sort: "iccidSim",
      visible: true,
    },
    {
      title: "description",
      data: "descripcion",
      search: "descripcion",
      sort: "descripcion",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga del componente
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.updating = true;
    this.MeterController.getInternalGprsList().subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let tableData: InternalGprsDevice[] = response["body"];
        tableData.forEach((device: InternalGprsDevice) => {
          device.tipo = device.tipo.toUpperCase();
          device.disableStatusUpdate =
            device.iccidSim || device.tipo == "PLUM" ? false : true;
        });
        this.tableData = tableData;
      } else {
        this.tableData = [];
      }
      this.updating = false;
    });
  }

  // Acciones de la tabla
  tableActions(action: string, device: InternalGprsDevice): void {
    switch (action) {
      case "activate":
        this.updateStatus(device, true);
        break;
      case "deactivate":
        this.confirmAction(device, false);
        break;
      default:
        break;
    }
  }

  // Confirmación de activación/desactivación de dispositivo
  confirmAction(device: InternalGprsDevice, status: boolean): void {
    let question =
      device.tipo == "PLUM"
        ? this.translate.instant("device-plum-question-desactivate") +
          ". " +
          this.translate.instant("device-question-desactivate")
        : this.translate.instant("ek-question-desactivate") +
          ". " +
          this.translate.instant("device-question-desactivate");
    this.ToastService.fireAlertWithOptions("question", question).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          this.updateStatus(device, status);
        }
      }
    );
  }

  // Activación/Desactivación de dispositivo
  updateStatus(device: InternalGprsDevice, status: boolean): void {
    this.MeterController.updateStatus(device.nserie, status).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            status
              ? this.translate.instant("device-activated-success")
              : this.translate.instant("device-deactivated-succes")
          );
        }
        this.getData();
      }
    );
  }
}
