import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Leaflet
import { LeafletModule } from "@asymmetrik/ngx-leaflet";

// Snake
import { SnakeComponent } from "./snake/snake.component";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,

    // Leaflet
    LeafletModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [SnakeComponent],
  exports: [SnakeComponent],
})
export class GamesModule {}
