import { Injectable } from "@angular/core";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
import { AcEntity } from "angular-cesium";
declare var Cesium: any;
// Servicio propios
import { MapGatewayTooltipService } from "../../map-module/map-services/MapGatewayTooltipService.service";
// Variables de mapa
import { CESIUM_MAP_CONSTS } from "../CESIUM_MAP_CONSTS";

@Injectable({
  providedIn: "root",
})
export class CesiumMapGatewayIconService {
  constructor(
    private mapGatewayTooltip: MapGatewayTooltipService,
    private translate: TranslateService
  ) {}

  // Obtención del icono para los gateways
  getGatewayIcon(mapType: string, gateway: any, tooltip: string): any {
    let iconUrl: string;
    let title: string;

    switch (mapType) {
      default:
        // Imagen de icono
        iconUrl =
          gateway.contadoresPrincipal?.length == 0 &&
          gateway.contadoresRedundante?.length == 0
            ? CESIUM_MAP_CONSTS.markerVariables["GATEWAY"].nodeviceIconUrl
            : gateway.comunica ||
              (mapType == "coverage" && !gateway.postInstallation)
            ? CESIUM_MAP_CONSTS.markerVariables["GATEWAY"].okIconUrl
            : mapType == "coverage" && gateway.postInstallation
            ? CESIUM_MAP_CONSTS.markerVariables["GATEWAY"].grayIconUrl
            : CESIUM_MAP_CONSTS.markerVariables["GATEWAY"].errorIconUrl;
        // Título
        title =
          gateway.comunica == true || mapType == "coverage"
            ? this.translate.instant("gateway") + " - " + gateway.unidadVenta
            : this.translate.instant("gateway-no-communicate") +
              " - " +
              gateway.unidadVenta;
        break;
    }

    return this.convertToCesiumObj("gateway", gateway, iconUrl, tooltip);
  }

  // Obtención del icono para las localizaciones
  getLocationIcon(mapType: string, location: any, tooltip: string): any {
    let iconUrl: string;
    let title: string;
    let label: object;

    switch (mapType) {
      default:
        // Imagen de icono
        iconUrl = CESIUM_MAP_CONSTS.markerVariables["GATEWAY"].locationUrl;
        // Título
        title = this.translate.instant("location");
        // Label
        label = {
          text: location.showLabel
            ? this.mapGatewayTooltip.getLocationPerteneceLabel(location)
            : null,
        };
        break;
    }

    return this.convertToCesiumObj(
      "location",
      location,
      iconUrl,
      tooltip,
      label
    );
  }

  // Conversión a entidad Cesium
  convertToCesiumObj(
    type: string,
    device: any,
    iconUrl: string,
    tooltip: string,
    label?: object
  ): any {
    return new AcEntity({
      id: device.id,
      name: type == "gateway" ? device.unidadVenta : device.id,
      image: iconUrl,
      tooltip: tooltip,
      type: type,
      position: Cesium.Cartesian3.fromDegrees(
        device.longitude,
        device.latitude,
        1
      ),
      color: device.postInstallation
        ? new Cesium.Color(1.0, 1.0, 1.0, 0.5)
        : null,
      label: label,
      show: true,
    });
  }
}
