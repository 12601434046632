<div *ngIf="_importColumns" class="import-table-container">
  <div *ngIf="importPreselects" class="import-table-preselect">
    <label for="preselect">{{ "preselect" | translate }}:</label>
    <select
      #preselect
      id="preselect"
      *ngIf="importPreselects"
      (change)="
        tableAction({ action: 'update-preselect', data: $event.target.value });
        preselectData($event.target.value)
      "
    >
      <option
        *ngFor="let preselect of importPreselects"
        value="{{ preselect.id }}"
      >
        {{ preselect.title }}
      </option>
    </select>
  </div>
  <div class="import-table-overflow-container">
    <table class="import-table">
      <tr class="import-table-tr">
        <th>
          <button
            class="btn btn-primary import-table-template"
            (click)="exportTemplate()"
            title="{{ 'import-table-template' | translate }}"
          >
            <i class="fas fa-file-download"></i>
          </button>
        </th>
        <th class="import-table-th">
          {{ "file-columns" | translate }}
        </th>
        <th class="import-table-th">
          {{ "details" | translate }}
        </th>
      </tr>
      <tr class="import-table-separator"></tr>
      <ng-container *ngFor="let column of _importColumns">
        <tr
          *ngIf="column.profile && column.visible"
          class="import-table-tr"
          [ngClass]="{ 'import-table-tr-required': column.required }"
        >
          <td>
            <div class="import-table-check">
              <input
                type="checkbox"
                name="columnCheck"
                value="{{ column.selected }}"
                (change)="column.selected = !column.selected"
                [checked]="column.selected"
              />
            </div>
          </td>
          <td class="import-table-td">
            {{ column.title }}
            <span *ngIf="column.required">(*)</span>
          </td>
          <td class="import-table-td">
            {{ column.info }}
            <span class="import-table-extra-info" *ngIf="column.extraInfo"
              >({{ column.extraInfo }})</span
            >
            <button
              *ngIf="column.action"
              (click)="
                tableAction({ action: 'show-info', data: column.action.data })
              "
              title="{{ column.action.title | translate }}"
            >
              <i class="{{ column.action.icon }}"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
