import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MaterialTreeNode } from "../../MaterialInterface.type";

@Component({
  selector: "app-material-tree",
  templateUrl: "./material-tree.component.html",
  styleUrls: ["./material-tree.component.scss"],
})
export class MaterialTreeComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input()
  get nodes(): MaterialTreeNode[] {
    return this._nodes;
  }
  set nodes(nodes: MaterialTreeNode[]) {
    this._nodes = nodes;
    if (this._nodes) {
      this.checkAllSelected();
    }
  }
  _nodes: MaterialTreeNode[];
  @Output() expandedUpdate = new EventEmitter<boolean>();
  @Output() selectedChange = new EventEmitter<any>();
  @Output() parentExpanded = new EventEmitter<any>();

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.checkAllSelected();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Comprobación de expansión
  checkAllExpanded(): void {
    this.expandedUpdate.emit(
      this._nodes.every((node: MaterialTreeNode) => node.expanded)
    );
  }

  // Actualización de la selección
  updateSelection(parentNode?: MaterialTreeNode): void {
    if (parentNode) {
      parentNode.children.map(
        (node: MaterialTreeNode) => (node.checked = parentNode.checked)
      );
    } else {
      this.checkAllSelected();
    }
    this.selectedChange.emit();
  }

  // Comprobación de selección total/parcial
  checkAllSelected(): void {
    this._nodes.map((node: MaterialTreeNode) => {
      if (node.children.every((node: MaterialTreeNode) => node.checked)) {
        node.checked = true;
        node.indeterminate = false;
      } else {
        node.checked = false;
        node.indeterminate = node.children.some(
          (node: MaterialTreeNode) => node.checked
        );
      }
    });
  }
}
