// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  GatewayAcumulatedCharge,
  GatewayRamAcumulatedCharge,
  GatewayRamState,
  GatewayRamWeekAcumulatedCharge,
} from "../../GatewayInterface.type";
import { MaterialSelectOption } from "../../../../../modules/material-module/MaterialInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-gatewayload",
  templateUrl: "./gateway-load.component.html",
  styleUrls: ["./gateway-load.component.scss"],
})
export class LoadComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;

  // Gateway
  gatewayId: number;
  unidadVenta: string;

  // Opciones de búsqueda
  initialOptionSelected: number = 0;
  finalOptionSelected: number = 2;
  initialOptionSelect: MaterialSelectOption[] = [
    { value: 0, name: this.translate.instant("load-state-charge") },
    { value: 1, name: this.translate.instant("acumulate-load") },
    { value: 2, name: this.translate.instant("load-week") },
  ];
  finalOptionSelect: MaterialSelectOption[] = [
    { value: 0, name: this.translate.instant("load-state-charge") },
    { value: 1, name: this.translate.instant("acumulate-load") },
    { value: 2, name: this.translate.instant("load-week") },
  ];

  // Gráfica
  graphSeries: object[];
  graphData: { x: number; y: number; light: number }[];
  graphTooltip: object = {
    valueDecimals: 3,
    pointFormatter: function () {
      return (
        `<span style="color:` +
        this.color +
        `">` +
        this.series.name +
        `</span>: <b>` +
        Highcharts.numberFormat(this.y, 3) +
        ` mAh</b> | 
             <span style="color:` +
        this.color +
        `">
             Tiempo de luz: </span><b>` +
        Math.floor(this.series.options.data[this.index]["light"] / 60)
          .toString()
          .padStart(2, "0") +
        ":" +
        (this.series.options.data[this.index]["light"] % 60)
          .toString()
          .padStart(2, "0") +
        "</b><br>"
      );
    },
  };
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";

  // Datos
  gatewayCharge: GatewayRamAcumulatedCharge;
  gatewayState: GatewayRamState;
  gatewayWeekData: GatewayRamWeekAcumulatedCharge;

  // Reseteo
  resetIcon: string = "fas fa-undo-alt";
  resetTitle: string = this.translate.instant("reset-gateway-load");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private GraphOptionsService: GraphOptionsService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(): void {
    this.GatewayController.getGraphLoad(
      this.gatewayId,
      this.initialOptionSelected.toString(),
      this.finalOptionSelected.toString(),
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        this.gatewayCharge = response["body"]["chgReadRamAcumulatedCharge"];
        this.gatewayState = response["body"]["chgReadRamState"];
        this.gatewayState.chargeStateParsed = this.translate.instant(
          "GatewayLoadState" + this.gatewayState.chargerState
        );
        this.gatewayWeekData =
          response["body"]["chgReadRamWeekAcumulatedCharge"];
        let graphData: GatewayAcumulatedCharge[] =
          response["body"]["chgReadRamWeekAcumulatedCharge"][
            "chgAcumulatedChargeList"
          ];
        if (
          graphData.length > 0 &&
          (this.initialOptionSelected == 2 || this.finalOptionSelected == 2)
        ) {
          this.graphData = graphData.map((element: GatewayAcumulatedCharge) => {
            return {
              x: element.timestamp,
              y: element.chargeAcumulated,
              light: element.lightMinutes,
            };
          });
        } else {
          this.graphData = [];
        }
        this.getSeries();
      }
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    let series: object[] = [
      {
        id: "cargaAcumuladaSemanal",
        name: this.translate.instant("load-week"),
        data: this.graphData,
        tooltip: this.graphTooltip,
        type: "column",
        navigatorOptions: {
          type: "column",
        },
        dataGrouping: { approximation: "sum" },
      },
    ];
    this.graphSeries = series;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("load-export")
      );
    highchartsOptions.plotOptions.series.dataGrouping.units = [["day", [1]]];
    highchartsOptions.title.text = this.translate.instant("load-week");
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["rangeSelector"]["buttons"].shift();
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["legend"]["enabled"] = false;
    chartOptions["yAxis"][0]["title"]["text"] =
      this.translate.instant("load-week");
    chartOptions["yAxis"]["label"] = { format: "{value} mAh" };
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }

  // Actualización del desplegable de opción final
  initialOptionChange(): void {
    let newFinalOptions = this.initialOptionSelect.slice(
      this.initialOptionSelect.findIndex((option: MaterialSelectOption) => {
        if (option.value == this.initialOptionSelected) {
          return option;
        }
      })
    );
    if (
      !newFinalOptions.find(
        (option: MaterialSelectOption) =>
          option.value == this.finalOptionSelected
      )
    ) {
      this.finalOptionSelected = newFinalOptions[0].value;
    }
    this.finalOptionSelect = newFinalOptions;
  }

  // Reseteo del regulador de carga
  resetLoad(): void {
    this.GatewayController.resetLoad(this.gatewayId).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("reset-sucessfull")
        );
      }
    });
  }
}
