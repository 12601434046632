// @angular
import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";

@Component({
  selector: "app-device-entity-dialog",
  templateUrl: "./device-entity-dialog.component.html",
  styleUrls: ["./device-entity-dialog.component.scss"],
})
export class DeviceEntityDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  updating: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Cambiar agrupación del contador
  changeEntity(): void {
    this.ToastService
      .fireAlertWithOptions(
        "warning",
        this.translate.instant(
          this.data.devices?.length > 1
            ? "change-meters-entity-question"
            : "change-meter-entity-question"
        )
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.updating = true;
          this.changeEntityRequest();
        }
      });
  }

  // Petición
  changeEntityRequest(): void {
    if (this.data.devices?.length == 1) {
      this.MeterController
        .changeDeviceEntity(this.data.devices[0].id, this.data.selectedEntity.id)
        .subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("change-meter-entity-sucessfull")
            );
            this.closeModal();
          }
        });
    } else {
      let data = this.data.devices.map((device: any) => {
        return {
          id: device.id,
          nroSerie: device.nroSerie,
          metrologyType: device.metrologyType,
          entity: this.data.selectedEntity.id,
        };
      });
      this.requestQueue.setTask("changeEntity", data, this.data.selectedEntity);
      this.closeModal();
    }
  }

  // Cierre de modal
  closeModal(): void {
    this.ReloadComponentService.reload();
    this.updating = false;
    this.SessionDataService.sendDialogAction({ action: "close" });
  }
}
