// @angular
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Subscription, takeUntil } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Moment
import * as moment from "moment";
// Servicios propios
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { DragElementService } from "../../../../../services/shared/DragElementService.service";
import { HttpRequestService } from "../../../../../services/shared/HttpRequestServices/HttpRequestService.service";
// Componentes
import { DataAnalysisBalanceCheckerComponent } from "./data-analysis-balance-checker/data-analysis-balance-checker.component";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  Balance,
  BalanceGraphData,
  BalanceGraphRequestData,
  BalanceGraphSerie,
} from "../../DataAnalysisInterface.type";
import { MaterialSelectConfig } from "../../../../../modules/material-module/MaterialInterface.type";
import { PossibleCups } from "./data-analysis-balance-checker/data-analysis-balance-checker-interface";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-balance-graphs",
  templateUrl: "./data-analysis-balance-graphs.component.html",
  styleUrls: ["./data-analysis-balance-graphs.component.scss"],
})
export class BalanceGraphsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Inicialización de componente
  componentInitiated: boolean = false;

  // Variables de sesión
  sessionProfile: string;
  dialog: Subscription;

  // Inputs
  @Input() currentAgrupation: Agrupation;
  @Input() graphDataLoaded: BalanceGraphData;
  @Input()
  get data(): Balance {
    return this._data;
  }
  set data(data: Balance) {
    this._data = data;

    this.setGraphFilters();
    this.defaultDateRange = {
      startDate: moment().startOf("day").subtract("7", "days"),
      endDate: moment().endOf("day"),
    };
  }
  _data: Balance;

  // Gráfica
  graphSeries: BalanceGraphSerie[];
  circleGraphSeries: BalanceGraphSerie[];
  graphData: BalanceGraphData;
  graphType: number;
  graphFilters: MaterialSelectConfig[] = [
    {
      title: this.translate.instant("type"),
      options: [],
      selected: null,
    },
  ];
  highchartsOptions: Options;
  chartOptions: object;
  circleChartOptions: object;
  circleHighchartOptions: Options;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment };
  from: string;
  to: string;
  showPercentageDifference: boolean = false;
  absoluteDifferenceSerie: BalanceGraphSerie;
  percentageDifferenceSerie: BalanceGraphSerie;
  parentSerie: BalanceGraphSerie;
  childSerie: BalanceGraphSerie;
  loading: boolean = false;
  @ViewChild("balanceGraphs") balanceGraphs: ElementRef;
  @ViewChild("graphCircle") graphCircle: ElementRef;
  vnr: string;

  // Contadores posibles
  possibleMeters: PossibleCups[];
  @ViewChild("cdkVirtualScrollViewPort")
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  overDifference: boolean = false;
  maxMeters: number;
  parentList: number[];
  childList: number[];
  sortPossiblesBy: number = 0;
  differenceMax: number;
  possiblesOrder: object[] = [
    { name: this.translate.instant("balance-order-similarity"), value: 0 },
    { name: this.translate.instant("balance-order-distance"), value: 1 },
    { name: this.translate.instant("balance-average-value"), value: 2 },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    public DragElementService: DragElementService,
    private GraphOptionsService: GraphOptionsService,
    private HttpRequestService: HttpRequestService,
    private MaterialDialogService: MaterialDialogService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.defaultDateRange = {
      startDate: this.graphDataLoaded
        ? moment(this.graphDataLoaded.initDate)
        : moment().startOf("day").subtract("7", "days"),
      endDate: this.graphDataLoaded
        ? moment(this.graphDataLoaded.finalDate)
        : moment().endOf("day"),
    };
    this.setHighchartsOptions();
    this.setCircleHighchartsOptions();

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "balance-update") {
          this.possibleMeters = dialogAction.data;
          this.maxMeters = dialogAction.maxMeters;
          this.parentList = dialogAction.parentList;
          this.childList = dialogAction.childList;
          this.differenceMax = dialogAction.differenceMax;

          if (this.possibleMeters.length > 0) {
            this.graphCircle?.nativeElement.style.setProperty(
              "--graph-circle-initial-y",
              this.graphCircle?.nativeElement.offsetTop + 260 + "px"
            );
          }
        }
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.graphCircle?.nativeElement.style.setProperty(
      "--graph-circle-initial-y",
      this.balanceGraphs?.nativeElement.offsetTop + 50 + "px"
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.loading = true;
    if (this.graphDataLoaded && !this.componentInitiated) {
      setTimeout(() => {
        this.graphData = JSON.parse(JSON.stringify(this.graphDataLoaded));
        this.getSeries(this.graphData["childReadings"]);
        this.componentInitiated = true;
      }, 0);
    } else {
      let data: BalanceGraphRequestData = {
        meterList: null,
        sector: this._data.id,
        fromTimestamp: from,
        toTimestamp: to,
        graphType: this.graphType,
      };

      this.DataAnalysisController.getBalanceReadings(data)
        .pipe(takeUntil(this.HttpRequestService.getRequestCancel()))
        .subscribe((response) => {
          if (response["code"] == 0 && response["body"]) {
            let graphData: BalanceGraphData = response["body"];
            this.graphData = graphData;
          } else {
            this.graphData = null;
          }
          this.getSeries(this.graphData["childReadings"]);
        });
    }
  }

  // Obtención de las series de datos para la gráfica
  getSeries(childReadings?: number[][]): void {
    // Lecturas del padre
    this.parentSerie = {
      id: "parent",
      name: this.translate.instant("fathers"),
      data: this.graphData["parentReadings"],
      dataGrouping: { approximation: "sum" },
      tooltip: {
        valueSuffix:
          this.graphType == 2 ? " m³" : this.graphType == 3 ? " Nm³" : " kWh",
        valueDecimals: 3,
      },
      color: "#03a9f4",
      opacity: 0.35,
      type: "area",
      navigatorOptions: {
        type: "area",
      },
      displace: true,
    };

    // Lecturas de los hijos
    this.childSerie = {
      id: "child",
      name: this.translate.instant("childs"),
      data: childReadings,
      dataGrouping: { approximation: "sum" },
      tooltip: {
        valueSuffix:
          this.graphType == 2 ? " m³" : this.graphType == 3 ? " Nm³" : " kWh",
        valueDecimals: 3,
      },
      color: "#ff9800",
      opacity: 0.75,
      type: "area",
      navigatorOptions: {
        type: "area",
      },
      displace: true,
    };

    // Diferencia en valores absolutos
    this.absoluteDifferenceSerie = {
      id: "difference",
      name: this.translate.instant("difference"),
      data: this.graphData["parentReadings"]?.map((reading: number[]) => {
        let childReading: number[] = childReadings.find(
          (childReading: number[]) => childReading[0] == reading[0]
        );
        if (childReading) {
          return [reading[0], reading[1] - childReading[1]];
        } else {
          return reading;
        }
      }),
      dataGrouping: { approximation: "sum" },
      tooltip: {
        valueSuffix:
          this.graphType == 2 ? " m³" : this.graphType == 3 ? " Nm³" : " kWh",
        valueDecimals: 3,
      },
      color: "#4caf50",
      yAxis: 1,
      type: "column",
      navigatorOptions: {
        type: "column",
      },
    };

    // Diferencia en porcentajes
    this.percentageDifferenceSerie = {
      id: "difference",
      name: this.translate.instant("difference"),
      data: this.graphData["parentReadings"]?.map((reading: number[]) => {
        let childReading: number[] = childReadings.find(
          (childReading: number[]) => childReading[0] == reading[0]
        );
        if (childReading) {
          return [
            reading[0],
            ((reading[1] - childReading[1]) * 100) / reading[1],
          ];
        } else {
          return [reading[0], 100];
        }
      }),
      dataGrouping: { approximation: "sum" },
      tooltip: {
        valueSuffix: " %",
        valueDecimals: 1,
      },
      color: "#4caf50",
      yAxis: 1,
      type: "column",
      navigatorOptions: {
        type: "column",
      },
    };

    // Comprobación de valor diferencial
    if (
      this.parentSerie.data?.length == this.childSerie.data?.length &&
      this.percentageDifferenceSerie.data?.some((value) => value[1] > 15)
    ) {
      this.overDifference = true;
    }

    // Serie de gráfica doble
    let series: BalanceGraphSerie[] = [
      this.parentSerie,
      this.childSerie,
      this.absoluteDifferenceSerie,
    ];
    series.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    // Serie de gráfica circular
    let seriesCircle: BalanceGraphSerie[] = [];
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();
    let acumulatedDifference =
      this.graphData.loadBalancingContainer?.parentAcumulatedConsumption -
      this.graphData.loadBalancingContainer?.childAcumulatedConsumption;
    if (this.absoluteDifferenceSerie?.data?.length > 0) {
      let totalHours = Math.round(
        (this.absoluteDifferenceSerie.data[
          this.absoluteDifferenceSerie.data.length - 1
        ][0] -
          this.absoluteDifferenceSerie.data[0][0]) /
          3600000
      );
      this.vnr = formatNumber(acumulatedDifference / totalHours, numberFormat);
    }

    if (this.graphData.loadBalancingContainer?.childsPercentaje != null) {
      seriesCircle = [
        {
          id: "cheese",
          name: this.translate.instant("cheese"),
          animation: false,
          data: [
            {
              color: "#03a9f4",
              name: this.translate.instant("performance"),
              y: this.graphData.loadBalancingContainer?.childsPercentaje,
              value: this.graphData.loadBalancingContainer
                ?.childAcumulatedConsumption
                ? "(" +
                  formatNumber(
                    this.graphData.loadBalancingContainer
                      ?.childAcumulatedConsumption,
                    numberFormat
                  ) +
                  (this._data.metrologyType == 2 ? " Nm³" : " m³") +
                  ")"
                : "",
            },
            {
              color: "#f44336",
              name: this.translate.instant("unbilled"),
              y: this.graphData.loadBalancingContainer?.differencePercentaje,
              value: acumulatedDifference
                ? "(" +
                  formatNumber(acumulatedDifference, numberFormat) +
                  (this._data.metrologyType == 2 ? " Nm³" : " m³") +
                  ")"
                : "",
            },
          ],
          dataGrouping: { approximation: "sum" },
        },
      ];
    }

    this.graphSeries = series;
    this.circleGraphSeries = seriesCircle;
    this.setChartsOptions();
    this.setCircleChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    highchartsOptions.scrollbar = { enabled: false };
    highchartsOptions.plotOptions.series.marker.enabled = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = {};
    chartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["chart"]["zoomType"] = "xz";
    chartOptions["yAxis"][0]["height"] = "60%";
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == 2 ? " m³" : this.graphType == 3 ? " Nm³" : " kWh");
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == 2
        ? this.translate.instant("consumption")
        : this.graphType == 3
        ? this.translate.instant("consumption-normalized")
        : this.translate.instant("energy");
    chartOptions["yAxis"].push({
      height: "35%",
      top: "65%",
      offset: 0,
      labels: {
        align: "left",
        format: "{value} " + (this.showPercentageDifference ? "%" : "m³"),
        style: {
          color: "#4caf50",
        },
      },
      title: {
        text: this.translate.instant("difference"),
        style: {
          color: "#4caf50",
          fontWeight: "bold",
        },
      },
    });
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
    this.loading = false;
  }

  // Asignación de las opciones concretas para la gráfica
  setCircleHighchartsOptions(): void {
    let cirlceHighchartsOptions: Options = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    cirlceHighchartsOptions.scrollbar = { enabled: false };
    cirlceHighchartsOptions.plotOptions.series.dataGrouping = {
      forced: true,
      units: [["hour", [1]]],
    };

    cirlceHighchartsOptions.plotOptions.pie = {
      size: 125,
      innerSize: null,
      dataLabels: {
        enabled: true,
        crop: false,
        distance: "40",
        formatter: function () {
          return (
            this.point.name +
            "<br>" +
            (this.point.percentage % 1
              ? this.point.percentage.toFixed(2)
              : this.point.percentage) +
            "%<br>" +
            this.point.value
          );
        },
        style: {
          fontWeight: "normal",
          fontSize: "1.2rem",
        },
        connectorWidth: 1,
      },
    };

    cirlceHighchartsOptions.plotOptions.series.marker.enabled = false;
    cirlceHighchartsOptions.tooltip = { enabled: false };
    cirlceHighchartsOptions.exporting = {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
      filename:
        this.translate.instant("graph-export") + " " + moment().format("ll"),
    };

    this.circleHighchartOptions = cirlceHighchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setCircleChartsOptions(): void {
    let circleChartOptions: object = {};
    circleChartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete circleChartOptions["yAxis"];
    delete circleChartOptions["rangeSelector"];
    circleChartOptions["navigator"] = false;
    circleChartOptions["chart"] = {
      width: 400,
      height: 100,
      type: "pie",
      style: {
        overflow: "visible",
      },
      // styledMode: true
    };
    circleChartOptions["series"] = this.circleGraphSeries;
    this.circleChartOptions = circleChartOptions;
  }

  // Seteo de los filtros dependiendo del tipo de balance
  setGraphFilters(): void {
    if (this._data.metrologyType == METROLOGY_TYPE.GAS) {
      this.graphFilters[0].options = [
        { value: 2, name: this.translate.instant("consumption") },
        { value: 3, name: this.translate.instant("consumption-normalized") },
        { value: 4, name: this.translate.instant("energy") },
      ];
      this.graphFilters[0].selected = 3;
      this.graphType = 3;
    } else {
      this.graphFilters[0].options = [
        { value: 2, name: this.translate.instant("consumption") },
      ];
      this.graphFilters[0].selected = 2;
      this.graphType = 2;
    }
  }

  // Actualización de la diferencia
  updateDifference() {
    this.showPercentageDifference = !this.showPercentageDifference;
    let series: BalanceGraphSerie[] = [
      this.parentSerie,
      this.childSerie,
      this.showPercentageDifference
        ? this.percentageDifferenceSerie
        : this.absoluteDifferenceSerie,
    ];
    series.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.graphSeries = series;
    this.setChartsOptions();
  }

  // Desplazar serie
  displaceSeries(
    serieId: string,
    displacement: number,
    axis: number,
    sign: string
  ): void {
    if (axis == 0) {
      this.graphData[
        serieId == "parent" ? "parentReadings" : "childReadings"
      ].map((data: number[]) => (data[0] = data[0] + 3600000 * displacement));
      this.getSeries(this.graphData["childReadings"]);
    } else {
      this.graphData[
        serieId == "parent" ? "parentReadings" : "childReadings"
      ].map(
        (data: number[]) =>
          (data[1] =
            sign == "add" ? data[1] + displacement : data[1] * displacement)
      );
      this.getSeries(this.graphData["childReadings"]);
    }
  }

  // Comprobación de balance
  checkBalance(): void {
    this.MaterialDialogService.openDialog(DataAnalysisBalanceCheckerComponent, {
      from: this.from,
      to: this.to,
      difference: this.absoluteDifferenceSerie.data,
      balance: this._data,
      currentAgrupation: this.currentAgrupation,
      graphType: this.graphType,
    });
  }

  // Actualización de gráfica
  updateGraphPossibles(): void {
    if (this.possibleMeters.some((meter) => meter.selected)) {
      let possiblesReadings: number[][] = [];
      this.possibleMeters.forEach((meter) => {
        meter.readings.forEach((reading) => {
          if (meter.selected) {
            let possibleReadingIndex = possiblesReadings.findIndex(
              (possibleReading) => possibleReading[0] == reading[0]
            );
            if (possibleReadingIndex >= 0) {
              possiblesReadings[possibleReadingIndex] = [
                reading[0],
                reading[1] + possiblesReadings[possibleReadingIndex][1],
              ];
            } else {
              possiblesReadings.push(reading);
            }
          }
        });
      });

      let newChildReadings = this.graphData.childReadings.map(
        (reading: number[]) => {
          let possibleReading = possiblesReadings.find(
            (possibleReading) => possibleReading[0] == reading[0]
          );
          return [
            reading[0],
            reading[1] + (possibleReading ? possibleReading[1] : 0),
          ];
        }
      );

      this.getSeries(newChildReadings);
    } else {
      this.getSeries(this.graphData["childReadings"]);
    }
  }

  // Añadir hijo a balance
  addChild(cupsData: PossibleCups): void {
    this.DataAnalysisController.addChild(
      this.currentAgrupation.id,
      this.data.id,
      [cupsData.cups.id]
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("balance-updated")
        );
        if (!cupsData.selected) {
          cupsData.selected = true;
          this.updateGraphPossibles();
        }
        cupsData.disabled = true;
      }
    });
  }

  // Quitar hijo de balance
  removeChild(cupsData: PossibleCups): void {
    this.DataAnalysisController.removeChild(
      this.currentAgrupation.id,
      this.data.id,
      [cupsData.cups.id]
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("balance-updated")
        );
        if (!cupsData.selected) {
          cupsData.selected = false;
          this.updateGraphPossibles();
        }
        cupsData.selected = false;
        cupsData.disabled = false;
      }
    });
  }

  // Ordenamiento de los contadores posibles
  balancePossibleSort(): void {
    let orderedMeters = [...this.possibleMeters];
    this.possibleMeters = null;
    orderedMeters.sort((a, b) =>
      this.sortPossiblesBy == 0
        ? // Por similaridad
          a.similarity - b.similarity
        : this.sortPossiblesBy == 1
        ? // Por distancia
          parseInt(a.distance.replace(".", "")) -
          parseInt(b.distance.replace(".", ""))
        : // Por valor promedio
          parseFloat(
            b.average.split(" ")[0].replace(".", "").replace(",", ".")
          ) -
          parseFloat(a.average.split(" ")[0].replace(".", "").replace(",", "."))
    );
    this.possibleMeters = [...orderedMeters];
  }
}
