<div *ngIf="recorderType == 'map'" class="map-screen-recorder">
  <button
    *ngIf="!isRecording"
    class="screen-recorder-control"
    (click)="recordStart()"
    title="{{ 'record' | translate }}"
  >
    <i class="fas fa-circle"></i>
  </button>
  <button
    *ngIf="isRecording"
    class="screen-recorder-control"
    (click)="recordStop()"
    title="{{ 'screen-capture-stop' | translate }}"
  >
    <i class="fas fa-stop"></i>
  </button>
  <button
    class="screen-recorder-download"
    (click)="downloadBlob()"
    [disabled]="blobarr?.length == 0"
    title="{{ 'download' | translate }}"
  >
    <i class="fas fa-download"></i>
  </button>
</div>

<div *ngIf="recorderType == 'menu'" class="menu-screen-recorder">
  <button *ngIf="!isRecording" mat-menu-item (click)="recordStart()">
    <mat-icon class="material-menu-icon"><i class="fas fa-circle"></i></mat-icon>
    <span>{{ "record" | translate }}</span>
  </button>
  <button *ngIf="isRecording" mat-menu-item (click)="recordStop()">
    <mat-icon class="material-menu-icon"><i class="fas fa-stop"></i></mat-icon>
    <span>{{ "screen-capture-stop" | translate }}</span>
  </button>
  <button
    mat-menu-item
    (click)="downloadBlob()"
    [disabled]="blobarr?.length == 0"
  >
    <mat-icon class="material-menu-icon"><i class="fas fa-download"></i></mat-icon>
    <span>{{ "download" | translate }}</span>
  </button>
</div>
