export const METER_IMPORT_COLUMNS = [
  {
    title: "NRO_SERIE",
    info: "nro-serie-import",
    required: true,
    profilesList: [],
    preselect: [],
  },
  {
    title: "DEV_TYPE",
    info: "dev-type-import",
    required: true,
    profilesList: [],
    preselect: [],
    action: { title: "show-detail", icon: "fas fa-eye", data: "show-detail" },
  },
  {
    title: "MANUFACTURER",
    info: "manufacturer",
    required: true,
    profilesList: [],
    preselect: [],
    action: { title: "show-detail", icon: "fas fa-eye", data: "show-detail" },
  },
  {
    title: "ALIAS",
    info: "alias-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: "LORAWAN_VERSION",
    info: "lora-version-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "DEV_EUI",
    info: "dev-eui-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "APP_EUI",
    info: "app-eui-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "NETWORK_KEY",
    info: "network-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "APPLICATION_KEY",
    info: "application-key-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "TX_POWER",
    info: "tx-import",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "MIN_SF",
    info: "sf-import",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "LONGITUDE",
    info: "longitude-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: "LATITUDE",
    info: "latitude-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: "RF_MODULE",
    info: "rf-module",
    required: false,
    profilesList: [],
    preselect: ["lw", "lw-mbus", "cyble5", "mbus", "une"],
  },
  {
    title: "RF_MODULE",
    info: "rf-module",
    required: true,
    profilesList: [],
    preselect: ["ek", "plum"],
  },
  {
    title: "COMMENTS",
    info: "comments",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: "CYBLE5_ENC_KEY",
    info: "encrypt-key",
    extraInfo: "Itron Cyble5IoT",
    required: true,
    profilesList: [],
    preselect: ["cyble5"],
  },
  {
    title: "CYBLE5_AUTH_KEY",
    info: "authentication-key",
    extraInfo: "Itron Cyble5IoT",
    required: true,
    profilesList: [],
    preselect: ["cyble5"],
  },
  {
    title: "MBUS_ADDRESS",
    info: "mbus-address",
    extraInfo: "only-mbus",
    required: true,
    profilesList: ["ARSON"],
    preselect: ["mbus", "lw-mbus"],
  },
  {
    title: "MBUS_KEY",
    info: "mbus-key",
    extraInfo: "only-mbus",
    required: true,
    profilesList: ["ARSON"],
    preselect: ["mbus", "lw-mbus"],
  },
  {
    title: "MBUS_ADDRESS",
    info: "une-key",
    extraInfo: "une-only",
    required: true,
    profilesList: ["ARSON"],
    preselect: ["une"],
  },
  {
    title: "ADR_PERMISSION",
    info: "adr-import",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["lw", "lw-mbus", "cyble5"],
  },
  {
    title: "AGRUPATION",
    info: "agrupation-import",
    required: false,
    profilesList: ["ARSON", "ADMIN_CLIENTE", "ADMIN_ENTIDAD"],
    preselect: ["lw", "lw-mbus", "cyble5", "mbus", "une", "nocom"],
  },
  {
    title: "AGRUPATION",
    info: "agrupation-import",
    required: true,
    profilesList: [],
    preselect: ["ek", "plum"],
  },
  {
    title: "ACTIVATE",
    info: "activate-import-meter",
    required: false,
    profilesList: ["ARSON", "ADMIN_CLIENTE", "ADMIN_ENTIDAD"],
    preselect: ["lw", "lw-mbus", "cyble5", "mbus", "une", "nocom"],
  },
  {
    title: "CODE",
    info: "ek-code",
    extraInfo: "only-ek",
    required: true,
    profilesList: [],
    preselect: ["ek"],
  },
  {
    title: "SIM_ICCID",
    info: "ek-iccid",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "DESCRIPTION",
    info: "ek-description",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "WINDOW_1",
    info: "window-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "DURATION_1",
    info: "duration-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "WINDOW_2",
    info: "window-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "DURATION_2",
    info: "duration-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "WINDOW_3",
    info: "window-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "DURATION_3",
    info: "duration-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: ["ARSON"],
    preselect: ["ek"],
  },
  {
    title: "LAST_READED_VALUE",
    info: "last-know-value",
    required: false,
    profilesList: ["ARSON"],
    preselect: [],
  },
  {
    title: "LAST_READED_YYYY_MM_DD",
    info: "date-last-value",
    extraInfo: "date-format",
    required: false,
    profilesList: ["ARSON"],
    preselect: [],
  },
  {
    title: "SEAL",
    info: "seal",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: "TIMEZONE",
    info: "timezone-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
];

export interface ImportResponseDevice {
  // BackEnd
  nroSerie: string;
  idFabricante: number;
  idDevType: number;
  uploadState: boolean;
  codError: number;

  // FrontEnd
  manufacturer?: string;
  devType?: string;
  responseCodeText?: string;
}
