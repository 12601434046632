import { Injectable, inject } from "@angular/core";
import { CanDeactivateFn } from "@angular/router";
import { Observable } from "rxjs";
import { SessionDataService } from "./SessionDataService.service";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: "root",
})
class CanDeactivateGuardService {
  constructor(private SessionDataService: SessionDataService) {}

  canDeactivate(): boolean {
    let newTabUrl = this.SessionDataService.getCurrentNewTab();
    let cancelNavigation = this.SessionDataService.getCurrentCancelNavigation();
    if (newTabUrl || cancelNavigation) {
      this.SessionDataService.clearNewTab();
      this.SessionDataService.clearCancelNavigation();
      return false;
    } else {
      return true;
    }
  }
}

export const CanDeactivateGuard: CanDeactivateFn<any> = (): boolean => {
  return inject(CanDeactivateGuardService).canDeactivate();
};
