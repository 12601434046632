<label
  for="status"
  #switch
  class="switch"
  (click)="status = !status; statusUpdate.emit(status)"
>
  <span
    class="slider-text"
    [ngClass]="status ? 'slider-text-left' : 'slider-text-right'"
    >{{ status ? ("active" | translate) : ("inactive" | translate) }}</span
  >
  <input name="status" type="checkbox" [(ngModel)]="status" />
  <span class="slider" [ngClass]="{ round: round }"></span>
</label>
