<div class="graph-container">
  <div *ngIf="_data.graphTitle" class="graph-heatmap-title">
    {{ _data.graphTitle }}
  </div>
  <highcharts-chart
    class="highcharts-chart-heatmap"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [(update)]="updateFlag"
  >
  </highcharts-chart>
</div>
