<div class="dialog-container">
  <div class="dialog-header" [ngSwitch]="data.action">
    <h5 class="dialog-title" *ngSwitchCase="'detail'">
      {{ ("gateway" | translate) + ": " + data.gateway.unidadVenta }}
    </h5>
    <h5 class="dialog-title" *ngSwitchCase="'edit'">
      {{ "edit" | translate }}
    </h5>
    <h5 class="dialog-title" *ngSwitchCase="'replace'">
      {{ "gateway-replace" | translate }}
    </h5>
    <h5 class="dialog-title" *ngSwitchCase="'location'">
      {{ "location-historical" | translate }}
    </h5>
    <h5 class="dialog-title" *ngSwitchCase="'changeState'">
      {{ "change-state" | translate }} ({{
        GATEWAY_STATES[data.gateway.state] | translate
      }})
    </h5>
  </div>
  <!-- Detalle -->
  <div class="dialog-body" [ngSwitch]="data.action">
    <div *ngSwitchCase="'detail'" class="gateway-detail-info">
      <div>
        <table class="gateway-detail-dialog-table">
          <tr *ngFor="let element of data.gatewayData">
            <ng-container *ngIf="!element.hidden">
              <td>
                <b>{{ element.title }}</b>
              </td>
              <td>
                <ng-container *ngIf="element.list">
                  <div class="gateway-detail-dialog-list">
                    <span *ngFor="let subelement of element.list">{{
                      subelement
                    }}</span>
                  </div>
                </ng-container>
                <ng-continer *ngIf="!element.list">
                  {{ element.text ? element.text : " " }}</ng-continer
                >
              </td>
            </ng-container>
          </tr>
        </table>
      </div>
      <div>
        <img
          *ngIf="data.sanitazedGatewayImage"
          class="img-responsive img-rounded"
          [src]="data.sanitazedGatewayImage"
        />
      </div>
    </div>
    <!-- Edición -->
    <div *ngSwitchCase="'edit'">
      <!-- Imagen de gateway -->
      <span class="btn btn-block btn-default btn-file">
        {{ fileName != null ? fileName : ("image-search" | translate) }}
        <input
          type="file"
          id="file"
          (change)="fileChangeEvent($event)"
          accept="image/x-png,image/jpeg"
        />
      </span>
      <img
        *ngIf="data.sanitazedGatewayImage"
        [src]="data.sanitazedGatewayImage"
        class="img-responsive img-rounded logo"
      />
    </div>
    <!-- Histórico de localización -->
    <div *ngSwitchCase="'location'">
      <table class="gateway-location-table">
        <tr>
          <th>{{ "gateway" | translate }}</th>
          <th>{{ "date" | translate }}</th>
        </tr>
        <tr *ngFor="let gateway of locationData">
          <td>
            {{ gateway.unidadVenta }}
          </td>
          <td>
            {{ gateway.timestampParsed }}
          </td>
        </tr>
      </table>
    </div>
    <!-- Sustitución -->
    <div *ngSwitchCase="'replace'">
      <!-- Gateways -->
      <app-material-select
        [fullWidth]="true"
        [title]="'gateways' | translate"
        [bindValue]="'id'"
        [bindLabel]="'unidadVenta'"
        [data]="data.gatewayList"
        (selectedOption)="data.gatewayReplaceSelected = $event"
      ></app-material-select>
    </div>
    <!-- Cambiar estado -->
    <div *ngSwitchCase="'changeState'">
      <mat-radio-group
        class="gateway-change-state-options"
        [(ngModel)]="newState"
        required
      >
        <mat-radio-button
          *ngFor="let option of gatewayStates[data.gateway.state]; index as i"
          class="mat-radio"
          [value]="i"
        >
          <span>{{ option.state }}</span>
        </mat-radio-button>
      </mat-radio-group>
      <div
        *ngIf="
          data.gateway.state == GATEWAY_STATES.ACTIVATED &&
          newState == GATEWAY_STATES.IN_STOCK &&
          data.gateway.mainMeters > 0
        "
        class="gateway-activated-main-meters-advise"
      >
        <i class="fas fa-exclamation-circle"></i>
        <span>{{ "gateway-remove-main-meters" | translate }}</span>
      </div>

      <!-- Comentarios del cambio de estado -->
      <div
        *ngIf="data.gateway.state > GATEWAY_STATES.AVAILABLE"
        class="gateway-change-state-comments"
      >
        <mat-form-field appearance="standard">
          <mat-label>{{ "comments" | translate }}</mat-label>
          <textarea matInput [(ngModel)]="comments"></textarea>
        </mat-form-field>
      </div>

      <div
        *ngIf="data.gateway.state > GATEWAY_STATES.AVAILABLE"
        class="gateway-change-state-image"
      >
        <span class="btn btn-block btn-default btn-file">
          {{ fileName != null ? fileName : ("image" | translate) }}
          <input
            type="file"
            id="file"
            (change)="fileChangeEvent($event)"
            accept="image/x-png,image/jpeg"
          />
        </span>
        <img
          *ngIf="data.sanitazedGatewayImage"
          [src]="data.sanitazedGatewayImage"
          class="img-responsive img-rounded logo"
        />
      </div>
    </div>
    <div *ngSwitchDefault></div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <!-- Quitar imagen -->
    <button
      *ngIf="data.action == 'edit'"
      mat-button
      class="mat-button-icon-text"
      (click)="removeIcon()"
      [disabled]="!data.sanitazedGatewayImage"
    >
      <i class="fas fa-images"></i>
      {{ "remove-image" | translate }}
    </button>
    <!-- Editar -->
    <button
      *ngIf="data.action == 'edit'"
      mat-button
      class="mat-button-icon-text"
      [disabled]="!data.updateImage"
      (click)="editGateway()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <!-- Sustituir -->
    <button
      *ngIf="data.action == 'replace'"
      mat-button
      [disabled]="data.gatewayReplaceSelected == null"
      (click)="replaceGateway()"
    >
      {{ "replace" | translate }}
    </button>
    <!-- Cambiar estado -->
    <button
      *ngIf="
        data.action == 'changeState' &&
        data.gateway.state == GATEWAY_STATES.ACTIVATED &&
        newState == 0 &&
        data.gateway.mainMeters > 0
      "
      mat-button
      [disabled]="data.gateway.mainMeters == 0"
      (click)="reassignMainDevices()"
    >
      {{ "reassign" | translate }}
    </button>
    <button
      *ngIf="data.action == 'changeState'"
      mat-button
      [disabled]="
        newState == null ||
        (data.gateway.state == GATEWAY_STATES.ACTIVATED &&
          newState == 0 &&
          data.gateway.mainMeters > 0)
      "
      (click)="updateState()"
    >
      {{ "save" | translate }}
    </button>
    <!-- Cerrar -->
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
