<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <div class="panel-nav">
          <!-- Selector de Búsqueda Global/Entidad -->
          <div class="management-warnings-control">
            <app-material-select
              class="material-header-select"
              [title]="'incidents' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [selected]="'all'"
              [data]="selectorWarningTypes"
              (selectedOption)="
                warningTypeSelected = $event;
                getTableData();
                setColumns();
                getMapData()
              "
            ></app-material-select>
          </div>
        </div>

        <!-- Actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [title]="'update' | translate"
          [icon]="'fas fa-sync-alt fa-spin'"
          (action)="getWarnings()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de sospechas -->
        <app-table-controller
          [tableId]="'warningsGlobalList'"
          [columns]="columns"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [highlightRow]="highlightRow"
          [legendAlwaysActive]="true"
          (selectedDataUpdateFlag)="tableSelectedData = $event; updateMap()"
          [data]="tableData"
        >
        </app-table-controller>

        <!-- Mapa de sospechas -->
        <app-map-controller
          *ngIf="
            (this.warningTypeSelected?.value == 'all' &&
              (this.gateways.length > 0 || this.devices.length > 0)) ||
            (this.warningTypeSelected?.value == 'gateways' &&
              this.gateways.length > 0) ||
            (this.warningTypeSelected?.value == 'meters' &&
              this.devices.length > 0)
          "
          [mapHeight]="mapHeight"
          [mapType]="mapType"
          [gatewaysData]="mapGateways"
          [metersData]="mapDevices"
          [activateAllLayers]="activateAllLayers"
        >
        </app-map-controller>
      </div>
    </div>
  </div>
</section>
