import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConthidraDecoderService {
  constructor() {}

  conthidra_MyWater2_decoder(input) {
    var nivelesBateria = {
      0: "<5%",
      1: "<10&",
      2: "10%",
      3: "15%",
      4: "20%",
      5: "25%",
      6: "30%",
      7: "40%",
      8: "50%",
      9: "60%",
      10: "70%",
      11: "80%",
      12: "85%",
      13: "90%",
      14: "95%",
      15: "100%",
    };

    if (input) {
      if (input.bytes) {
        if (input.bytes.length < 52) {
          var outputData = {};
          var outputErrors = [];
          var index = 0;
          if (input.bytes.length != 49 && input.bytes.length != 17) {
            return {
              errors: [
                "LONGITUD ERRÓNEA: " +
                  input.bytes.length.toString() +
                  "bytes (debe ser  49 o 17 bytes)",
              ],
            };
          }
          /* Parte Común */
          var frameVersion = (input.bytes[index] >> 4) & 0x0f;
          if (frameVersion != 3 && frameVersion != 4) {
            return {
              errors: [
                "FRAME VERSION DESCONOCIDA: " +
                  frameVersion.toString() +
                  "(debe ser  3 (valores) o 4 (alarmas))",
              ],
            };
          }
          outputData["FrameVersion"] = frameVersion;
          var fwVersion =
            "0" +
            (input.bytes[index + 0] & 0x0f).toString(16).padStart(1, "0") +
            "." +
            input.bytes[index + 1].toString(16).padStart(2, "0");
          index += 2;
          outputData["FwVersion"] = fwVersion;
          var device_SN = this.readU32Lsb(input.bytes, index);
          outputData["Device_SN"] = device_SN;
          index += 4;
          var meter_SN =
            this.readU32Lsb(input.bytes, index) +
            (input.bytes[index + 4] << 32);
          index += 5;
          outputData["Meter_SN"] = meter_SN;
          var timestamp = this.readU32Lsb(input.bytes, index);
          index += 4;
          outputData["TimeStamp"] = timestamp;
          var date = new Date(timestamp * 1000);
          outputData["Fecha"] = date.toLocaleString();
          var alarmas =
            input.bytes[index] | ((input.bytes[index + 1] >> 4) << 8);
          var bateria = input.bytes[index + 1] & 0x0f;
          index += 2;
          outputData["Alarmas"] = {};
          outputData["Alarmas"]["Mascara_Alarmas"] =
            "0x" + alarmas.toString(16).padStart(3, "0");
          if (alarmas != 0) {
            if (alarmas & (1 << 0)) {
              outputData["Alarmas"]["ERROR_MAX_FLOW"] =
                "Overflow during 15 minutes";
            }

            if (alarmas & (1 << 1)) {
              outputData["Alarmas"]["ERROR_SABOTAGE"] = "Magnetic fraud";
            }
            if (alarmas & (1 << 2)) {
              outputData["Alarmas"]["ERROR_LEAKAGE"] = "Leakage during x hours";
            }
            if (alarmas & (1 << 3)) {
              outputData["Alarmas"]["ERROR_REVERSE_FLOW"] =
                "x pulses in reverse";
            }
            if (alarmas & (1 << 4)) {
              outputData["Alarmas"]["ERROR_EXTREME_CONDITIONS"] =
                "Extreme temperature";
            }
            if (alarmas & (1 << 5)) {
              outputData["Alarmas"]["BLOCKED_WATERMETER"] =
                "No flow for x days";
            }
            if (alarmas & (1 << 6)) {
              outputData["Alarmas"]["ERROR_HARDWARE_TEMPORAL"] =
                "Temporary hardware error";
            }
            if (alarmas & (1 << 7)) {
              outputData["Alarmas"]["ERROR_HARDWARE_PERMANENT"] =
                "Permanent hardware error";
            }
            if (alarmas & (1 << 8)) {
              outputData["Alarmas"]["ERROR_REMOVE"] = "Sensor removed";
            }
            if (alarmas & (1 << 9)) {
              outputData["Alarmas"]["FLAG_LOCAL_COMM"] =
                "Local communications has been initiated and data was transferred";
            }
            if (alarmas & (1 << 10)) {
              outputData["Alarmas"][""] = "";
            }
            if (alarmas & (1 << 11)) {
              outputData["Alarmas"]["ERROR_LOW_BATTERY"] =
                "Battery level bellow 20%";
            }
          }
          outputData["Bateria"] =
            nivelesBateria[bateria] + " (" + bateria.toString() + ")";
          if (frameVersion == 3) {
            var deltas = [];
            for (let indiceDelta = 0; indiceDelta < 12; indiceDelta++) {
              deltas[indiceDelta] = this.read16Lsb(input.bytes, index);
              index += 2;
            }

            var info = (input.bytes[index] >> 4) & 0x0f;
            outputData["Info"] =
              info.toString() + " - " + (info ? "Extreme" : "Standar");
            var frameIndex = input.bytes[index++] & 0x0f;
            if (!info) {
              outputData["FrameIndex"] =
                frameIndex.toString() +
                " - " +
                (frameIndex ? "00h00-12h00" : "12h00-00h00");
              if (frameIndex > 1) {
                return {
                  errors: [
                    "FRAME_INDEX ERRÓNEO: " +
                      frameIndex.toString() +
                      "(debe ser 0 o 1 para standard profile)",
                  ],
                };
              }
            } else {
              outputData["FrameIndex"] = frameIndex.toString();
              if (frameIndex > 7) {
                return {
                  errors: [
                    "FRAME_INDEX ERRÓNEO: " +
                      frameIndex.toString() +
                      "(debe ser 0-7 para extreme profile)",
                  ],
                };
              }
            }

            var latchPulseOffset = this.cogeBits(input.bytes, index, 20);
            var counterCoefficient = input.bytes[index + 2] & 0x0f;
            index += 3;
            var pulseCounter = this.readU32Lsb(input.bytes, index);
            var litrosPulso = 1;
            switch (counterCoefficient) {
              case 0x00:
                litrosPulso = 1000;
                break;
              case 0x01:
                litrosPulso = 10000;
                break;
              case 0x02:
                litrosPulso = 100000;
                break;
              case 0x0c:
                litrosPulso = 0.1;
                break;
              case 0x0d:
                litrosPulso = 1;
                break;
              case 0x0e:
                litrosPulso = 10;
                break;
              case 0x0f:
                litrosPulso = 100;
                break;
              default:
                return {
                  errors: [
                    "COUNTER_COEFFICIENT ERRÓNEO: " +
                      counterCoefficient.toString(16).padStart(2, "0"),
                  ],
                };
                break;
            }
            var valores = [pulseCounter];
            for (let indiceDelta = 0; indiceDelta < 12; indiceDelta++) {
              if (deltas[indiceDelta] == 0x8000) {
                /*Delta no válido*/
                for (
                  ;
                  indiceDelta < 12;
                  indiceDelta++ // Los valores a partir de ahí no pueden extraerse
                ) {
                  valores[indiceDelta + 1] = 0x80000000;
                }
                break;
              } else {
                valores[indiceDelta + 1] =
                  valores[indiceDelta] - deltas[indiceDelta];
              }
            }

            outputData["Period_Volume_Usage"] = {};
            for (let indiceDelta = 0; indiceDelta < 12; indiceDelta++) {
              var valorAscii =
                deltas[indiceDelta] == 0x8000
                  ? "NV"
                  : (deltas[indiceDelta] * litrosPulso).toString() + " l";
              outputData["Period_Volume_Usage"][
                "Delta_" + (12 - indiceDelta).toString().padStart(2, "0")
              ] =
                valorAscii +
                " (" +
                (frameIndex ? 11 - indiceDelta : 23 - indiceDelta)
                  .toString()
                  .padStart(2, "0") +
                "h-" +
                (frameIndex ? 12 - indiceDelta : 24 - indiceDelta)
                  .toString()
                  .padStart(2, "0") +
                "h)";
              index += 2;
            }
            outputData["Latch_Pulse_Offset"] =
              latchPulseOffset.toString() +
              " pulsos - Valor actual: " +
              ((pulseCounter + latchPulseOffset) * litrosPulso).toString() +
              " litros";
            outputData["Counter_Coefficient"] =
              "0x" +
              counterCoefficient.toString(16).padStart(1, "0") +
              " (" +
              litrosPulso.toString() +
              " litros/pulso)";
            outputData["Pulse_Counter"] =
              pulseCounter.toString() +
              " pulsos - Valor " +
              (frameIndex ? "12h00" : "00h00") +
              ": " +
              (pulseCounter * litrosPulso).toString() +
              " litros";
            outputData["Valores (UTC)"] = {};
            for (let nValor = 0; nValor < 13; nValor++) {
              if (valores[nValor] == 0x80000000) {
                var valorAscii = "NV";
              } else {
                var valorAscii =
                  (valores[nValor] * litrosPulso).toString() + " litros";
              }
              outputData["Valores (UTC)"][
                (frameIndex ? 12 - nValor : 24 - nValor)
                  .toString()
                  .padStart(1, "0") + "h"
              ] = valorAscii;
            }
          }
          return {
            data: outputData,
          };
        } else {
          return {
            errors: ["Tamaño del FRMPayload excesivo."],
          };
        }
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown Input."],
      };
    }
  }

  /* Helper Methods */
  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  read16Lsb(bytes, start) {
    var res = this.readU16Lsb(bytes, start);
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  cogeBits(bytes, start, numeroBits) {
    var res = 0;
    var indice = 0;
    while (numeroBits > 8) {
      res <<= 8;
      res |= bytes[start + indice++];
      numeroBits -= 8;
    }
    if (numeroBits) {
      res <<= numeroBits;
      res |= bytes[start + indice] >> (8 - numeroBits);
    }
    return res;
  }
}
