/**
 * Decodes the given frmPayload for the Kamstrup protocol.
 *
 * @param {any} frmPayload - The frmPayload to be decoded.
 * @param {any} fport - The FPort of the frame.
 *
 * @returns {Object}
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";
import { SessionDataService } from "../../../../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../../../../services/shared/DateParserService.service";

@Injectable({
  providedIn: "root",
})
export class ConthidraERegisterDecoderService {
  timezone: string;
  referenceEpoch;

  constructor(
    private DateParserService: DateParserService,
    private SessionDataService: SessionDataService
  ) {}

  conthidra_eRegister_decoder(frmPayload, fport) {
    this.timezone = this.SessionDataService.getCurrentAgrupation()?.timezone;
    this.referenceEpoch =
      Date.UTC(2000, 0, 1) +
      this.DateParserService.getTimezoneOffsetMinutes(this.timezone) *
        60 *
        1000;

    if (frmPayload) {
      if (frmPayload.bytes) {
        var outputData = {};
        var outputErrors = [];
        switch (fport) {
          case 1:
          case 2:
            return {
              data: this.dailyFrameDecoder(frmPayload.bytes, fport),
            };
            break;
          case 5:
            return {
              data: this.weeklyFrameDecoder(frmPayload.bytes, fport),
            };
            break;
          case 8:
            return {
              data: this.infoFrameDecoder(frmPayload.bytes),
            };
            break;
          case 9:
            return {
              data: this.eventFrameDecoder(frmPayload.bytes),
            };
            break;
          default:
            return {
              errors: ["TIPO DE TRAMA DESCONOCIDO. Puerto " + fport.toString()],
            };
            break;
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frmPayload."],
      };
    }
  }

  /* Decoder de Info Frame*/
  infoFrameDecoder(bytes) {
    var outputData = { Tipo_Trama: "INFO FRAME" };
    if (bytes.length != 49) {
      return {
        errors: [
          "LONGITUD ERRÓNEA: " +
            bytes.length.toString() +
            "bytes (debe ser  49 bytes)",
        ],
      };
    }
    var rawTxTimestamp = this.readU32Lsb(bytes, 0);
    var txTimestamp = this.DateParserService.parseDate(
      rawTxTimestamp * 1000 + this.referenceEpoch,
      "L HH:mm:ss"
    );
    outputData["TX_timestamp"] = rawTxTimestamp.toString() + "  " + txTimestamp;
    var serialNumber = "";
    for (let indice = 4; indice < 24; indice++) {
      if (bytes[indice] != 0) {
        serialNumber += String.fromCharCode(parseInt(bytes[indice], 10));
      }
    }
    outputData["Metrological_serial_number"] = serialNumber;
    var firmwareVersion = this.readU32Lsb(bytes, 24);
    outputData["Firmware_version"] = firmwareVersion
      .toString(16)
      .padStart(8, "0");
    var batteryCharge = bytes[28];
    outputData["Battery_charge"] = batteryCharge.toString() + " %";
    var POD = "";
    for (let indice = 29; indice < 49; indice++) {
      if (bytes[indice] != 0) {
        POD += String.fromCharCode(parseInt(bytes[indice], 10));
      }
    }
    outputData["POD"] = POD;

    return outputData;
  }

  /* Decoder de Daily Frame*/
  dailyFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "DAILY FRAME" };
    if (bytes.length != 37) {
      return {
        errors: [
          "LONGITUD ERRÓNEA: " +
            bytes.length.toString() +
            "bytes (debe ser  37 bytes)",
        ],
      };
    }
    var index = 0;
    var rawTxTimestamp = this.readU32Lsb(bytes, 0);
    var txTimestamp = this.DateParserService.parseDate(
      rawTxTimestamp * 1000 + this.referenceEpoch,
      "L HH:mm:ss"
    );
    outputData["Fecha"] = txTimestamp;
    var alarmas = this.readU16Lsb(bytes, 4);
    if (alarmas != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(alarmas);
    }
    var vif = bytes[6];
    var pesoPulso = this.vifPesoPulso(vif);
    if (pesoPulso == -1) {
      return {
        errors: ["VIF ERRÓNEO: 0x" + vif.toString(16).padStart(2, "0")],
      };
    }
    outputData["PesoPulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";
    var endDayValue = this.readU32Lsb(bytes, 7);
    var endDayDate = this.DateParserService.parseDateWithoutFormat(
      rawTxTimestamp * 1000 + this.referenceEpoch
    ).startOf("day");
    outputData["End_Day_Value"] =
      (endDayValue * pesoPulso).toLocaleString("es-ES") +
      " litros (" +
      this.DateParserService.getMomentDate(endDayDate, "L HH:mm:ss") +
      ")";
    var baseValue = this.readU32Lsb(bytes, 11);
    var baseDate = endDayDate.subtract({ hours: fport == 1 ? 13 : 1 });
    var deltas = [];
    for (let ndxDelta = 0; ndxDelta < 11; ndxDelta++) {
      deltas[ndxDelta] = this.read16Lsb(bytes, 15 + 2 * ndxDelta);
    }
    var valor = baseValue * pesoPulso;
    var valueDate: any = baseDate;
    outputData["VALORES"] = {
      [this.DateParserService.getMomentDate(valueDate, "L HH:mm:ss")]:
        (baseValue * pesoPulso).toLocaleString("es-ES") + " litros",
    };
    for (let ndxDelta = 0; ndxDelta < 11; ndxDelta++) {
      valueDate = valueDate.subtract({ hours: 1 });
      valor -= deltas[ndxDelta];
      outputData["VALORES"][
        this.DateParserService.getMomentDate(valueDate, "L HH:mm:ss")
      ] = valor.toLocaleString("es-ES") + " litros";
    }
    return outputData;
  }

  /* Decoder de Weekly Frame*/
  weeklyFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "WEEKLY FRAME" };
    if (bytes.length != 29) {
      return {
        errors: [
          "LONGITUD ERRÓNEA: " +
            bytes.length.toString() +
            "bytes (debe ser  29 bytes)",
        ],
      };
    }
    var index = 0;
    var rawTxTimestamp = this.readU32Lsb(bytes, 0);
    var txTimestamp = this.DateParserService.parseDate(
      rawTxTimestamp * 1000 + this.referenceEpoch,
      "L HH:mm:ss"
    );
    outputData["Fecha"] = txTimestamp;
    var alarmas = this.readU16Lsb(bytes, 4);
    if (alarmas != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(alarmas);
    }
    var vif = bytes[6];
    var pesoPulso = this.vifPesoPulso(vif);
    if (pesoPulso == -1) {
      return {
        errors: ["VIF ERRÓNEO: 0x" + vif.toString(16).padStart(2, "0")],
      };
    }
    outputData["PesoPulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";
    var endDayValue = this.readU32Lsb(bytes, 7);
    var endDayDate = this.DateParserService.parseDateWithoutFormat(
      rawTxTimestamp * 1000 + this.referenceEpoch
    ).startOf("day");
    var deltas = [];
    for (let ndxDelta = 0; ndxDelta < 6; ndxDelta++) {
      deltas[ndxDelta] = this.read24Lsb(bytes, 11 + 3 * ndxDelta);
    }
    var valor = endDayValue * pesoPulso;
    var valueDate: any = endDayDate;
    outputData["VALORES"] = {
      [this.DateParserService.getMomentDate(valueDate, "L HH:mm:ss")]:
        valor.toLocaleString("es-ES") + " litros",
    };
    for (let ndxDelta = 0; ndxDelta < 6; ndxDelta++) {
      valueDate = valueDate.subtract({ hours: 1 });
      valor -= deltas[ndxDelta];
      outputData["VALORES"][
        this.DateParserService.getMomentDate(valueDate, "L HH:mm:ss")
      ] = valor.toLocaleString("es-ES") + " litros";
    }
    return outputData;
  }

  /* Decoder de Event Frame*/
  eventFrameDecoder(bytes) {
    var outputData = { Tipo_Trama: "EVENT FRAME" };
    if (bytes.length != 11) {
      return {
        errors: [
          "LONGITUD ERRÓNEA: " +
            bytes.length.toString() +
            "bytes (debe ser  11 bytes)",
        ],
      };
    }
    var index = 0;
    var rawTxTimestamp = this.readU32Lsb(bytes, 0);
    var txTimestamp = this.DateParserService.parseDate(
      rawTxTimestamp * 1000 + this.referenceEpoch,
      "L HH:mm:ss"
    );
    outputData["Fecha"] = txTimestamp;
    var alarmas = this.readU16Lsb(bytes, 4);
    if (alarmas != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(alarmas);
    }
    var vif = bytes[6];
    var pesoPulso = this.vifPesoPulso(vif);
    if (pesoPulso == -1) {
      return {
        errors: ["VIF ERRÓNEO: 0x" + vif.toString(16).padStart(2, "0")],
      };
    }
    outputData["PesoPulso"] =
      pesoPulso.toLocaleString("es-ES") + " litros/pulso";
    var currentValue = this.readU32Lsb(bytes, 7);
    outputData["Curret_Readout"] =
      (currentValue * pesoPulso).toLocaleString("es-ES") + " litros)";
    return outputData;
  }

  /* Helper Methods */
  cogeBits(bytes, start, numeroBits) {
    var res = 0;
    var indice = 0;
    while (numeroBits > 8) {
      res <<= 8;
      res |= bytes[start + indice++];
      numeroBits -= 8;
    }
    if (numeroBits) {
      res <<= numeroBits;
      res |= bytes[start + indice] >> (8 - numeroBits);
    }
    return res;
  }

  parseaAlarmas(alarmas) {
    var objetoAlarmas = {};
    if (alarmas != 0) {
      if (alarmas & (1 << 0)) {
        objetoAlarmas["Mechanical Fraud IS"] =
          "Device is removed from the meter now";
      }
      if (alarmas & (1 << 1)) {
        objetoAlarmas["Magnetic Fraud IS"] =
          "A fraud with a magnet is detected now";
      }
      if (alarmas & (1 << 2)) {
        objetoAlarmas["Suspected Leakage IS"] =
          "A leakage could be present now";
      }
      if (alarmas & (1 << 3)) {
        objetoAlarmas["Overflow IS"] = "Maximum flow rate is exceeded now";
      }
      if (alarmas & (1 << 4)) {
        objetoAlarmas["Backflow IS"] =
          "Reverse water flow exceeds the maximum threshold now";
      }
      if (alarmas & (1 << 5)) {
        objetoAlarmas["No Consumption IS"] =
          "Meter could show no water consumption now";
      }
      if (alarmas & (1 << 6)) {
        objetoAlarmas["Meter Reversed IS"] =
          "Meter could be mounted in the wrong verse";
      }
      if (alarmas & (1 << 7)) {
        objetoAlarmas["Out Of Operating Temperature"] =
          "Device operating out of working temperature range";
      }
      if (alarmas & (1 << 10)) {
        objetoAlarmas["Low Battery Voltage IS"] =
          "Device battery voltage is below minimum value";
      }
      if (alarmas & (1 << 11)) {
        objetoAlarmas["Low Battery Charge"] =
          "Remaining battery charge (calculated) is below threshold";
      }
      if (alarmas & (1 << 12)) {
        objetoAlarmas["Expired Sealing Period"] =
          "Current date passed sealing period";
      }
      if (alarmas & (1 << 13)) {
        objetoAlarmas["Config Set To Default Value"] =
          "Configuration has been reset to default";
      }
      if (alarmas & (1 << 14)) {
        objetoAlarmas["Metrological Wrong Checksum"] =
          "Program memory is corrupted";
      }
    }
    return objetoAlarmas;
  }

  vifPesoPulso(vif) {
    switch (vif) {
      case 0x10:
        return 0.001;
      case 0x11:
        return 0.01;
      case 0x12:
        return 0.1;
      case 0x13:
        return 1;
      case 0x14:
        return 10;
      case 0x15:
        return 100;
      case 0x16:
        return 1000;
      case 0x17:
        return 10000;
      default:
        return -1;
    }
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  read16Lsb(bytes, start) {
    var res = this.readU16Lsb(bytes, start);
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  read24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    if (res & 0x800000) {
      res = res - 0xffffff - 1;
    }
    return res;
  }

  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  Conthidra_eRegister_Descriptor(frame) {
    if (frame && frame.bytes) {
      var fType = (frame.bytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frame.bytes[5] & 0x0f;
          if (frame.bytes.length >= 9 + fOptsLen + 4) {
            let fport = frame.bytes[8 + fOptsLen];
            switch (fport) {
              case 1:
              case 2:
                return "DAILY FRAME";
              case 5:
                return "WEEKELY FRAME";
              case 8:
                return "INFO FRAME";
              case 9:
                return "EVENT FRAME";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          var fOptsLen = frame.bytes[5] & 0x0f;
          var fOptsLen = frame.bytes[5] & 0x0f;
          if (frame.bytes.length >= 9 + fOptsLen + 4) {
            let fport = frame.bytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
