import { Theme } from "./symbols";

export const WATER_THEME: Theme = {
  name: "water",
  properties: {
    // Global
    "--color-black": "rgba(0, 0, 0, 1)",
    "--color-white": "rgba(255, 255, 255, 1)",
    "--table-border-color": "rgba(224, 224, 224, 0.75)",
    "--btn-primary": "#1976d2",
    "--btn-primary-light": "#64a9ed",
    "--btn-primary-dark": "#0e4377",
    "--btn-red": "red",
    "--btn-red-light": "#ff6666",
    "--btn-red-dark": "#990000",
    "--btn-inactive": "#cdcecf",
    "--btn-inactive-light": "#e6e7e8",
    "--btn-inactive-dark": "#9a9b9c",
    "--btn-active": "green",
    "--btn-active-light": "#00e600",
    "--btn-active-dark": "#001a00",
    "--btn-warning": "#f57c00",
    "--btn-warning-light": "#ffae5c",
    "--btn-warning-dark": "#8f4800",
    "--btn-color": "white",
    "--btn-inactive-color": "#c9c9c9",
    "--btn-cancel-color": "black",

    // Navbar Top
    "--navbar-top-base-background": "white",
    "--navbar-top-hover-background": "#0a364a",
    "--navbar-top-color": "black",
    "--navbar-top-menu-color": "rgba(0,0,0,.87)",
    "--dropdown-background": "#fff",
    "--dropdown-li-odd": "white",
    "--dropdown-li-even": "white",
    "--dropdown-border": "#dbc5ab",
    "--dropdown-shadow": "gray",
    "--dropdown-filter": "black",
    "--dropdown-highlight": "rgb(235, 235, 235)",
    "--dropdown-subelement": "gray",
    "--dropdown-hover": "rgba(0,0,0,.04)",
    "--font-base-color": "black",
    "--font-hover-color": "white",
    "--search-background": "#0e4b67",
    "--search-background-light": "#126185",
    "--search-background-dark": "#0a364a",
    "--search-noselected-background": "#cdcecf",
    "--search-noselected-background-light": "#e8e8e9",
    "--search-noselected-background-dark": "#b4b5b7",
    "--search-color": "white",
    "--search-noselected-color": "rgb(190, 190, 190)",
    "--search-noselected-color-light": "#c9c9c9",
    "--clock-background": "#0e4b67",
    "--clock-background-neutral": "#6e93a3",
    "--clock-background-dark": "#0a364a",
    "--clock-border": "#0a364a",
    "--clock-color": "black",
    "--clock-data-background": "rgb(245, 245, 245)",
    "--clock-data-background-neutral": "white",
    "--clock-data-shadow": "rgb(194, 194, 194)",
    "--clock-data-border": "rgb(187, 187, 187)",
    "--clock-data-text-color": "white",
    "--clock-data-text-shadow": "black",
    "--weather-link": "rgb(11, 98, 185)",
    "--weather-disabled": "gray",
    "--weather-info-shadow": "gray",
    "--weather-info-source": "#efefef",
    "--weather-info-color": "black",
    "--search-border": "#dbc5ab",
    "--responive-current-data": "rgb(207, 207, 207)",
    "--responsive-menu-background": "black",
    "--responsive-menu-color": "white",
    "--virtual-agrupation": "#a9aeff",
    "--notification-icon": "yellow",

    // Navbar Side
    "--sidebar-background": "#0e4b67",
    "--sidebar-background-dark": "#0a364a",
    "--sidebar-color": "#F0F8FF",
    "--sidebar-hover-color": "white",
    "--sidebar-submenu-color": "black",
    "--sidebar-submenu-border": "#fffbe8",
    "--sidebar-submenu-background": "#2b7a9e",
    "--sidebar-submenu-background-dark": "#fff",
    "--sidebar-logo-background": "white",
    "--sidebar-box-shadow": "gray",
    "--sidebar-text-shadow": "#001E53",
    "--sidebar-menu-border-hover": "#fffbe8",
    "--sidebar-shine-background-light": "rgba(255, 255, 255, .10)",
    "--sidebar-shine-background-dark": "rgba(0, 0, 0, .25)",
    "--sidebar-shine-square-light": "#0a364a",
    "--sidebar-shine-square-dark": "#146c94",

    // Responsive Nav
    "--nav-background": "white",
    "--nav-menu-color": "black",
    "--nav-user-color": "white",
    "--nav-user-background": "black",
    "--search-title": "rgb(204, 204, 204)",
    "--dropdown-background-light": "#ebecfe",

    // Cards
    "--cards-menu-background": "#f1f2ff",
    "--cards-menu-border": "gray",
    "--cards-menu-dropdown-hover": "#c9c9c8",
    "--cards-menu-dropdown-hover-color": "white",
    "--cards-menu-dropdown-border": "#ddd",
    "--cards-menu-dropdown-background": "white",
    "--cards-menu-dropdown-color": "black",
    "--card-visible": "rgb(77, 173, 77)",
    "--card-hidden": "rgb(223, 68, 68)",
    "--card-drag": "rgb(64, 96, 184)",
    "--card-visible-dark": "#2e662e",
    "--card-hidden-dark": "#a11c1c",
    "--card-drag-dark": "#26396c",
    "--card-drag-disabled": "gray",
    "--cards-menu-icon": "rgb(226, 226, 226)",
    "--cards-menu-icon-dark": "#c9c9c9",
    "--cards-menu-icon-color": "gray",
    "--cards-menu-icon-hover": "#446378",
    "--cards-menu-icon-hover-dark": "#446378",
    "--cards-menu-icon-color-hover": "white",

    // Table controller
    "--table-column-visible": "green",
    "--table-column-visible-light": "#00b300",
    "--table-column-visible-neutral": "#004d00",
    "--table-column-visible-dark": "#001a00",
    "--table-column-visible-color": "white",
    "--table-column-not-visible": "#c5c5c5",
    "--table-column-not-visible-light": "#dedede",
    "--table-column-not-visible-neutral": "#acacac",
    "--table-column-not-visible-dark": "#929292",
    "--table-column-not-visible-color": "gray",
    "--table-column-not-visible-color-light": "#999999",
    "--table-filter-dropdown-select-hover": "black",
    "--table-filter-dropdown-hover-color": "white",
    "--table-filter-dropdown-border": "#ddd",
    "--table-filter-dropdown-background": "white",
    "--table-filter-dropdown-even": "#D3D5FD",
    "--table-filter-dropdown-hover-background": "#c9c9c8",
    "--table-filter-dropdown-color": "black",
    "--table-filter-help": "gray",
    "--table-filter-help-hover": "black",
    "--table-columns-list-background": "#f1f2ff",
    "--table-columns-list-border": "gray",
    "--table-button-background": "rgb(25, 118, 210)",
    "--table-button-color": "white",
    "--table-column-all-visible": "black",
    "--table-column-all-visible-dark": "black",
    "--table-column-all-visible-light": "#686868",
    "--table-quick-filter-inactive": "rgb(187, 187, 187)",
    "--table-quick-filter-active": "black",
    "--table-quick-filter-color": "white",
    "--table-panel-background": "white",
    "--table-btn-primary": "rgb(25, 118, 210)",
    "--table-menu-icon": "rgb(226, 226, 226)",
    "--table-menu-icon-dark": "#c9c9c9",
    "--table-menu-icon-color": "gray",
    "--table-menu-icon-hover": "#446378",
    "--table-menu-icon-hover-dark": "#446378",
    "--table-menu-icon-color-hover": "white",

    // Table
    "--table-rows-even": "#f2f2f2",
    "--table-rows-odd": "white",
    "--table-color": "black",
    "--table-th-background": "white",
    "--table-th-color": "black",
    "--table-border": "#ddd",
    "--table-border-light": "#f7f7f7",
    "--table-border-dark": "#c4c4c4",
    "--table-highlight-background-red": "rgb(215, 68, 68)",
    "--table-highlight-background-yellow": "rgba(255, 255, 147, 0.877)",
    "--table-highlight-background-green": "rgb(202, 252, 182)",
    "--table-highlight-background-blue": "rgb(212, 245, 255)",
    "--table-highlight-color-white": "white",
    "--table-highlight-color-black": "black",
    "--table-tr-hover-background": "#ddd",
    "--table-tr-hover-color": "black",
    "--table-sort-icon-inactive": "rgb(185, 184, 184)",
    "--table-sort-icon-active": "black",
    "--table-pagination-button-background": "white",
    "--table-pagination-button-border": "rgb(212, 212, 212)",
    "--table-pagination-page-background": "white",
    "--table-pagination-page-color": "black",
    "--table-pagination-button-color": "black",
    "--table-pagination-button-disabled": "rgb(212, 212, 212)",
    "--table-action-button-color": "gray",
    "--table-action-button-highlight-color": "white",
    "--table-action-button-disabled-color": "rgb(224, 224, 224)",
    "--table-action-button-hover-color": "black",
    "--table-extra-row-background": "#fafafa",
    "--table-extra-info-row-background": "rgb(247, 247, 247)",
    "--table-success-result": "green",
    "--table-fail-result": "rgb(206, 22, 22)",
    "--table-warning-result": "rgb(255, 255, 0)",

    // Filters
    "--filters-border": "#D3D5FD",

    // Import table
    "--import-table-border": "#ddd",
    "--import-table-required": "black",
    "--import-table-title": "gray",

    // User form
    "--user-form-password-requeriments-background": "white",
    "--user-form-password-requeriments-border": "#D3D5FD",
    "--user-form-subscriber-separator-border": "#ddd",

    // Meter detail
    "--meter-detail-nonces-match-background": "green",
    "--meter-detail-nonces-match-color": "white",
    "--meter-detail-nonces-warning-background": "red",
    "--meter-detail-nonces-warning-color": "white",

    // Panel heading
    "--panel-heading-icon-background": "rgb(216, 216, 216)",
    "--panel-heading-icon-color": "black",
    "--panel-heading-icon-hover-color": "white",
    "--panel-heading-icon-border": "white",
    "--panel-heading-notification": "rgb(255, 255, 209)",

    // Panel menu
    "--panel-menu-background": "white",
    "--panel-menu-color": "black",
    "--panel-menu-hover-background": "#c9c9c8",
    "--panel-menu-hover-color": "white",
    "--panel-menu-hover-icon": "#446378",
    "--panel-menu-border": "#ddd",
    "--panel-menu-border-light": "#ebebeb",
    "--panel-menu-container-background": "rgb(216, 216, 216)",
    "--update-button-background": "rgb(216, 216, 216)",
    "--update-button-hover-background": "#446378",
    "--update-button-hover-color": "white",

    // Footer
    "--footer-background": "white",
    "--footer-separator": "black",
    "--footer-border": "#ddd",

    // Alarms
    "--alarm-dropdown-color": "black",
    "--alarm-dropdown-hover-background": "#446378",
    "--alarm-dropdown-hover-color": "white",
  },
};
