export interface GraphFilterOptionSelected {
  value: any;
  filterIndex: number;
}

export interface GraphColorByPoint {
  serie: number;
  color: string;
  conditionIndex: number;
  conditionValue: number | string;
  coloredData?: object[];
  legend?: string;
}

export interface GraphHeatmapAxisData {
  title?: any;
  categories: string[];
}

export interface GraphHeatmapData {
  graphTitle?: string;
  serieTitle: string;
  serie: any[];
}

export interface AverageData {
  serie: any;
  average: number[];
}

export class SarimaData {
  p: number;
  d: number;
  q: number;
  P: number;
  D: number;
  Q: number;
  m: number;
  pred: number;
  auto: boolean;
  method: object;
  optimizer: object;
  defaultMethod: number;
  defaultOptimizer: number;
  selectedMethod: number;
  selectedOptimizer: number;
  verbose: boolean;
  result: any;
  exogResult: any;
  predResult: number[][];
  exogPredResult: number[][];
  serie: number[][];
  exogSerie: number[][];
  exog: boolean;
  exogData: number[][];
  exogK: number;
  exogPeriod: number;

  constructor() {
    this.p = 0;
    this.d = 0;
    this.q = 0;
    this.P = 0;
    this.D = 0;
    this.Q = 0;
    this.m = 0;
    this.pred = 1;
    this.auto = true;
    this.selectedMethod = 0;
    this.selectedOptimizer = 6;
    this.verbose = true;
    this.defaultMethod = 0;
    this.defaultOptimizer = 6;
    this.exog = false;
    this.exogK = 2;
    this.exogPeriod = 7;
    this.method = [
      { id: 0, type: "Exact Maximum Likelihood Method (Default)" },
      { id: 1, type: "Conditional Method - Sum Of Squares" },
      { id: 2, type: "Box-Jenkins Method" },
    ];
    this.optimizer = [
      { id: 0, type: "Nelder-Mead" },
      { id: 1, type: "Newton Line Search" },
      { id: 2, type: "Newton Trust Region - Hook Step" },
      { id: 3, type: "Newton Trust Region - Double Dog-Leg" },
      { id: 4, type: "Conjugate Gradient" },
      { id: 5, type: "BFGS" },
      { id: 6, type: "Limited Memory BFGS (Default)" },
      { id: 7, type: "BFGS Using More Thuente Method" },
    ];
  }
}

export const OUTLIERS_METHODS = [
  { id: "twitter", name: "Twitter’s Anomaly Detection" },
  { id: "average-hourly", name: "average-hourly" },
  { id: "average-daily", name: "average-daily" },
  { id: "3-sigma", name: "3-sigma" },
  // { id: "GRABBS", name: "GRABBS" },
  { id: "IRQ", name: "IRQ" },
  { id: "MAD", name: "MAD" },
  { id: "MD", name: "MD" },
];

export const TWITTER_THRESHOLDS = [
  { id: "med_max", name: "med_max" },
  { id: "p95", name: "p95" },
  { id: "p99", name: "p99" },
];

export interface OutliersData {
  meterId: number;
  outliers: number[][];
  meterSeries: number[][];
  method: string;
  dateRange: { startDate: moment.Moment; endDate: moment.Moment };
}
