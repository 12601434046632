export const MENU = [
  // ANCHOR Principal
  {
    name: "main",
    link: "/principal",
    icon: "fa fa-home fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-home",
  },
  // ANCHOR Telecontrol
  {
    name: "telecontrol",
    link: null,
    icon: "fas fa-binoculars fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-telecontrol",
    submenu: [
      // Panel de alertas
      {
        name: "telecontrol-panel",
        link: "/telecontrol/alertas-activas",
        icon: "fas fa-exclamation-circle fa-fw",
        profile: [],
        help: "help-menu-telecontrol-alert-panel",
      },
      // Histórico
      {
        name: "telecontrol-historic",
        link: "/telecontrol/historico",
        icon: "fas fa-calendar-alt fa-fw",
        profile: [],
        help: "help-menu-telecontrol-alert-historic",
      },
      // Configuracion de alertas de entidad
      {
        name: "entity-alerts-config",
        link: "/alarmas/entidad/configuracion",
        icon: "fas fa-sitemap fa-fw",
        subicon: "fas fa-cog fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        help: "help-menu-telecontrol-alert-config",
      },
      // Telemedida
      {
        name: "telemetry",
        link: "/telecontrol/telemedida",
        icon: "fas fa-tachometer-alt fa-fw",
        profile: ["ARSON"],
        help: "help-menu-telecontrol-telemetry",
      },
      // Equipos test
      {
        name: "test",
        link: "/telecontrol/test",
        icon: "fas fa-list-check fa-fw",
        profile: ["ARSON"],
        help: "help-menu-telecontrol-test",
      },
    ],
  },
  // ANCHOR Gateways
  {
    name: "gateways",
    link: null,
    icon: "gateway-icon",
    profile: ["ARSON"],
    help: "help-menu-gateway",
    submenu: [
      // Instalados
      {
        name: "installed-gateway",
        link: "/gateways/listado/asignados",
        icon: "fas fa-circle-nodes fa-fw",
        profile: [],
        help: "help-menu-gateway-installed",
      },
      // Pendientes
      {
        name: "available",
        link: "/gateways/listado/disponibles",
        icon: "fas fa-clipboard-check fa-fw",
        profile: [],
        help: "help-menu-gateway-available",
      },
      // Inactivos
      {
        name: "in-stock",
        link: "/gateways/listado/en-stock",
        icon: "fas fa-warehouse fa-fw",
        profile: [],
        help: "help-menu-gateway-in-stock",
      },
      // Averiados
      {
        name: "damaged",
        link: "/gateways/listado/averiados",
        icon: "fas fa-times-circle fa-fw",
        profile: [],
        help: "help-menu-gateway-damaged",
      },
      // Reparación
      {
        name: "repair",
        link: "/gateways/listado/reparacion",
        icon: "fas fa-tools fa-fw",
        profile: [],
        help: "help-menu-gateway-repair",
      },
      // APP EUI
      {
        name: "App EUI",
        link: "/gateways/appeui",
        icon: "fas fa-broadcast-tower fa-fw",
        profile: [],
        help: "help-menu-gateway-appeui",
      },
    ],
  },
  // ANCHOR Mantenimiento
  {
    name: "management",
    link: null,
    icon: "fas fa-tools fa-fw",
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
    help: "help-menu-management",
    submenu: [
      // Global
      {
        name: "global",
        link: null,
        icon: "fas fa-globe fa-fw",
        profile: [
          "ARSON",
          "ADMIN_CLIENTE",
          "USUARIO_CLIENTE",
          "ADMIN_ENTIDAD",
          "USUARIO_ENTIDAD",
          "ADMIN_AGRUPACION",
          "USUARIO_AGRUPACION",
        ],
        submenu: [
          // Gateways
          {
            name: "gateways",
            link: "/mantenimiento/global",
            icon: "gateway-submenu-icon",
            profile: ["ARSON"],
            help: "help-menu-management-global-gateways",
          },
          // Comandos en cola
          {
            name: "commands-tail",
            link: "/mantenimiento/cola-comandos",
            icon: "fas fa-terminal fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-commands",
          },
          // Concentradores
          {
            name: "concentrators",
            link: "/mantenimiento/concentradores",
            icon: "fas fa-tint fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-global-concentrators",
          },
          // Alarmas
          {
            name: "alarms",
            link: "/mantenimiento/alarmas",
            icon: "fas fa-exclamation-triangle fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-alarms",
          },
          // Incidencias
          {
            name: "incidents",
            link: "/mantenimiento/incidencias",
            icon: "fas fa-info-circle fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-incidents",
          },
          // Búsquedas
          {
            name: "search-global",
            link: "/mantenimiento/busqueda-global",
            icon: "fas fa-search fa-fw",
            profile: [
              "ARSON",
              "ADMIN_CLIENTE",
              "USUARIO_CLIENTE",
              "ADMIN_ENTIDAD",
              "USUARIO_ENTIDAD",
              "ADMIN_AGRUPACION",
              "USUARIO_AGRUPACION",
            ],
            help: "help-menu-management-search",
          },
          // Usuarios
          {
            name: "users",
            link: "/mantenimiento/usuarios",
            icon: "fas fa-users  fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-users",
          },
          // Eventos de usuarios
          {
            name: "user-events",
            link: "/mantenimiento/eventos",
            icon: "fas fa-user fa-fw",
            subicon: "fas fa-calendar-day",
            profile: ["ARSON"],
            help: "help-menu-management-users",
          },
          // Reporte de comunicaciones
          {
            name: "communication-report",
            link: "/mantenimiento/reporte-comunicaciones",
            icon: "fas fa-satellite-dish fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-report",
          },
        ],
      },
      // Entidad
      {
        name: "entity",
        link: null,
        icon: "fas fa-sitemap fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        submenu: [
          // Gateways
          {
            name: "gateways",
            link: "/mantenimiento/gateways",
            icon: "gateway-submenu-icon",
            profile: ["ARSON"],
            help: "help-menu-management-gateways",
          },
          // Red
          {
            name: "network-optimization",
            link: "/mantenimiento/red",
            icon: "fas fa-rocket fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-optimization",
          },
          // Borrado de datos de entidad
          {
            name: "entity-data-erase",
            link: "/mantenimiento/borrado-datos",
            icon: "fas fa-eraser fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-deletion",
          },
          // Edición de agrupaciones
          {
            name: "agrupations-edition",
            link: "/mantenimiento/editar-agrupaciones",
            icon: "fas fa-draw-polygon fa-fw",
            profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
            help: "help-menu-management-edition",
          },
        ],
      },
      // Gestión
      {
        name: "configure",
        link: null,
        icon: "fas fa-cog fa-fw",
        profile: ["ARSON"],
        submenu: [
          // Cron
          {
            name: "Cron",
            link: "/mantenimiento/cron",
            icon: "fas fa-clock fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-cron",
          },
          // IPs bloqueadas
          {
            name: "IP",
            link: "/ip",
            icon: "fas fa-ethernet fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-ips",
          },
          // Gestión de idiomas
          {
            name: "language",
            link: "/mantenimiento/idiomas",
            icon: "fas fa-language fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-language",
          },
          // Gestión de fabricantes
          {
            name: "manufacturer",
            link: "/mantenimiento/fabricantes",
            icon: "fas fa-industry fa-fw",
            profile: ["ARSON"],
            help: "help-menu-management-manufacturer",
          },
        ],
      },
    ],
  },
  // ANCHOR Cobertura
  {
    name: "coverage",
    link: null,
    icon: "fa fa-signal fa-fw",
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
    help: "help-menu-coverage",
    submenu: [
      // Test de cobertura
      {
        name: "coverage-test",
        link: "/cobertura/test",
        icon: "fa fa-clipboard-check fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        help: "help-menu-coverage-test",
      },
      // Mapa de cobertura
      {
        name: "coverage-map",
        link: "/cobertura/mapa",
        icon: "fas fa-map-marked-alt fa-fw",
        profile: ["ARSON"],
        help: "help-menu-coverage-map",
      },
      // Mapa de cobertura MBus
      {
        name: "coverage-map-mbus",
        link: "/cobertura/mapa-mbus",
        icon: "fas fa-map-marked-alt fa-fw",
        subicon: "fas fa-tint fa-fw",
        profile: ["ARSON"],
        help: "help-menu-coverage-map",
      },
    ],
  },
  // ANCHOR Gestión de datos
  {
    name: "custom-data",
    link: null,
    icon: "fas fa-database fa-fw",
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    help: "help-menu-customized-data",
    submenu: [
      // Configuración de datos
      {
        name: "data-config",
        link: "/gestion-datos/configuracion",
        icon: "fas fa-cog fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        help: "help-menu-customized-config",
      },
      // Metrológica
      {
        name: "metrological",
        link: "/gestion-datos/metrologia/listado",
        icon: "fas fa-clipboard-list fa-fw",
        profile: [
          "ARSON",
          "ADMIN_ENTIDAD",
          "ADMIN_CLIENTE",
          "ADMIN_AGRUPACION",
        ],
        help: "help-menu-customized-metrology",
      },
      {
        name: "meters-info",
        link: null,
        icon: "fas fa-tachometer-alt fa-fw",
        profile: [
          "ARSON",
          "ADMIN_ENTIDAD",
          "ADMIN_CLIENTE",
          "ADMIN_AGRUPACION",
        ],
        submenu: [
          // Información de contadores
          {
            name: "list",
            link: "/gestion-datos/informacion",
            icon: "fas fa-clipboard-list fa-fw",
            disabled: "entityCupsConf",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
            ],
            help: "help-menu-customized-meters-data",
          },
          // Carga de contadores
          {
            name: "import",
            link: "/gestion-datos/importar/contadores",
            icon: "fas fa-file-arrow-up fa-fw",
            disabled: "entityCupsConf",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
            ],
            help: "help-menu-customized-meters-data",
          },
        ],
      },
      {
        name: "cups-info",
        link: null,
        icon: "fas fa-location-dot fa-fw",
        disabled: "entityCupsConf",
        profile: [
          "ARSON",
          "ADMIN_ENTIDAD",
          "ADMIN_CLIENTE",
          "ADMIN_AGRUPACION",
        ],
        submenu: [
          // CUPS
          {
            name: "list",
            link: "/gestion-datos/cups",
            icon: "fas fa-clipboard-list fa-fw",
            disabled: "entityCupsConf",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
            ],
            help: "help-menu-cups-data",
          },
          // Carga de cups
          {
            name: "import",
            link: "/gestion-datos/importar/cups",
            icon: "fas fa-file-arrow-up fa-fw",
            disabled: "entityCupsConf",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
            ],
            help: "help-menu-customized-meters-data",
          },
          // Sustitución de dispositivo
          {
            name: "meter-substitution",
            link: "/dispositivos/sustituir/",
            icon: "fas fa-exchange-alt fa-fw",
            profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
            disabled: "entityCupsConf",
            help: "help-menu-device-replace",
          },
          // Notificaciones
          {
            name: "notifications",
            link: "/gestion-datos/notificaciones",
            icon: "fas fa-bell  fa-fw",
            disabled: "cups",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
            ],
            help: "help-menu-customized-notifications",
          },
        ],
      },
    ],
  },
  // ANCHOR Dispositivos
  {
    name: "device",
    link: null,
    icon: "fas fa-tachometer-alt fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
      "ADMIN_INSTALLATION",
    ],
    help: "help-menu-device",
    submenu: [
      // Listados
      {
        name: "lists",
        link: null,
        icon: "fas fa-clipboard-list fa-fw",
        profile: [
          "ARSON",
          "ADMIN_CLIENTE",
          "USUARIO_CLIENTE",
          "ADMIN_ENTIDAD",
          "USUARIO_ENTIDAD",
          "ADMIN_AGRUPACION",
          "USUARIO_AGRUPACION",
          "ADMIN_INSTALLATION",
        ],
        submenu: [
          // En red
          {
            name: "in-network",
            link: "/dispositivos/listado/asignados",
            icon: "fas fa-circle-nodes fa-fw",
            profile: [
              "ARSON",
              "ADMIN_CLIENTE",
              "USUARIO_CLIENTE",
              "ADMIN_ENTIDAD",
              "USUARIO_ENTIDAD",
              "ADMIN_AGRUPACION",
              "USUARIO_AGRUPACION",
            ],
            help: "help-menu-device-in-network",
          },
          // Pendiente
          {
            name: "pending",
            link: "/dispositivos/listado/no-asignados",
            icon: "fas fa-hourglass-half fa-fw",
            profile: [
              "ARSON",
              "ADMIN_CLIENTE",
              "USUARIO_CLIENTE",
              "ADMIN_ENTIDAD",
              "USUARIO_ENTIDAD",
              "ADMIN_AGRUPACION",
              "USUARIO_AGRUPACION",
            ],
            help: "help-menu-device-pending",
          },
          // Desactivados
          {
            name: "deactivated",
            link: "/dispositivos/listado/desactivados",
            icon: "fas fa-power-off fa-fw",
            profile: [
              "ARSON",
              "ADMIN_CLIENTE",
              "USUARIO_CLIENTE",
              "ADMIN_ENTIDAD",
              "USUARIO_ENTIDAD",
              "ADMIN_AGRUPACION",
              "USUARIO_AGRUPACION",
            ],
            help: "help-menu-device-deactivated",
          },
          // Listado revisión
          {
            name: "to-pending",
            link: "/dispositivos/listado/revision",
            icon: "fas fa-binoculars fa-fw",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
            ],
            help: "help-menu-device-revision",
          },
          // Sustituciones
          {
            name: "substitutions",
            link: "/dispositivos/listado/sustituciones",
            icon: "fas fa-exchange-alt fa-fw",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
              "ADMIN_INSTALLATION",
            ],
            help: "help-menu-device-substitutions",
          },
          // Sustituciones pendientes
          {
            name: "substitutions-pending",
            link: "/dispositivos/listado/sustituciones-pendientes",
            icon: "fas fa-exchange-alt fa-fw",
            subicon: "fas fa-hourglass-half fa-fw",
            profile: [
              "ARSON",
              "ADMIN_ENTIDAD",
              "ADMIN_CLIENTE",
              "ADMIN_AGRUPACION",
              "ADMIN_INSTALLATION",
            ],
            help: "help-menu-device-pending-substitutions",
          },
          // Órdenes de trabajo
          {
            name: "meter-orders",
            link: "/dispositivos/listado/ordenes",
            icon: "fas fa-list-check fa-fw",
            profile: ["ARSON"],
            help: "help-menu-device-orders",
          },
          // Listado interno
          {
            name: "internal-list",
            link: "/dispositivos/listado/interno",
            icon: "arson-submenu-icon",
            profile: ["ARSON"],
            help: "help-menu-device-internal",
          },
          // Listado interno gprs
          {
            name: "internal-list-gprs",
            link: "/dispositivos/listado/interno-gprs",
            icon: "arson-submenu-icon",
            subicon: "fas fa-signal",
            profile: ["ARSON"],
            help: "help-menu-device-internal-gprs",
          },
          // Sin comunicación
          {
            name: "no-communication",
            link: "/dispositivos/listado/sin-comunicacion",
            icon: "fas fa-broadcast-tower fa-fw",
            subicon: "fas fa-xmark",
            profile: ["ARSON"],
            help: "help-menu-device-without-com",
          },
          // Listado errores de activación
          {
            name: "activation-error-list",
            link: "/dispositivos/listado/error-activacion",
            icon: "fas fa-exclamation fa-fw",
            profile: ["ARSON"],
            help: "help-menu-device-errors",
          },
          // Batería baja
          {
            name: "low-battery-list",
            link: "/dispositivos/listado/bateria-baja",
            icon: "fas fa-battery-quarter fa-fw",
            profile: ["ARSON"],
            help: "help-menu-device-low-battery",
          },
          // Desconocidos MBUS
          {
            name: "unknown-devices",
            link: "/dispositivos/listado/desconocidos-mbus",
            icon: "fas fa-question fa-fw",
            profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
            help: "help-menu-mbus-manufacturers",
          },
        ],
      },
      // Importaciones
      {
        name: "imports",
        link: null,
        icon: "fas fa-file-upload fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        submenu: [
          // Precarga de datos
          {
            name: "data-preload",
            link: "/dispositivos/importar/",
            icon: "fas fa-tachometer-alt fa-fw",
            profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
            help: "help-menu-device-preload",
          },
          // Carga de lecturas
          {
            name: "readings-import",
            link: "/dispositivos/importar-lecturas/",
            icon: "fas fa-calendar-alt fa-fw",
            profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
            help: "help-menu-device-readings-import",
          },
          // Carga de coordenadas
          {
            name: "coords-import",
            link: "/dispositivos/importar-coordenadas/",
            icon: "fas fa-map-marker-alt fa-fw",
            profile: ["ARSON"],
            help: "help-menu-device-coords-import",
          },
          // Carga de imágenes
          {
            name: "images-import",
            link: "/dispositivos/importar-imagenes/",
            icon: "fas fa-image fa-fw",
            profile: ["ARSON"],
            help: "help-menu-device-images-import",
          },
          // Desactivación de dispositivo
          {
            name: "deactivate",
            link: "/dispositivos/desactivar-fichero/",
            icon: "fas fa-power-off fa-fw",
            profile: ["ARSON"],
            help: "help-menu-device-deactivate",
          },
        ],
      },
      // Nuevo
      {
        name: "new",
        link: "/dispositivos/nuevo",
        icon: "fas fa-plus fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        help: "help-menu-device-new",
      },
      // Tarjetas SIM
      {
        name: "sim-cards",
        link: "/dispositivos/sim",
        icon: "fas fa-sim-card fa-fw",
        profile: ["ARSON"],
        help: "help-menu-device-sim",
      },
      {
        name: "mbus-manufacturers",
        link: null,
        icon: "fas fa-industry fa-fw",
        profile: ["ARSON"],
        help: "help-menu-mbus-manufacturers",
        submenu: [
          // Fabricantes MBUS
          {
            name: "common-key",
            link: "/dispositivos/listado/fabricantes-mbus/clave-comun",
            icon: "fas fa-key fa-fw",
            profile: ["ARSON"],
            help: "help-menu-mbus-manufacturers",
          },
          // Candiudatos MBUS
          {
            name: "private-key",
            link: "/dispositivos/listado/fabricantes-mbus/clave-primaria",
            icon: "fas fa-key fa-fw",
            subicon: "fas fa-lock",
            profile: ["ARSON"],
            help: "help-menu-mbus-candidates",
          },
        ],
      },
    ],
  },
  // ANCHOR Alarmas
  {
    name: "alarms",
    link: null,
    icon: "fas fa-exclamation-triangle fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-alarms",
    submenu: [
      // Ver alarmas globales
      {
        name: "show-alarms-global",
        link: "/alarmas/global",
        icon: "fa fa-globe fa-fw",
        profile: ["ARSON"],
        help: "help-menu-alarms-global",
      },
      // Ver alarmas
      {
        name: "show-alarms",
        link: "/alarmas/listado",
        state: { data: "month" },
        icon: "fa fa-map fa-fw",
        profile: [],
        help: "help-menu-alarms-show",
      },
      // Contadores con alarmas continuadas
      {
        name: "meters-with-alarms-continued",
        link: "/alarmas/dispositivos",
        icon: "fa fa-tachometer-alt fa-fw",
        profile: ["ARSON"],
        help: "help-menu-alarms-show",
      },
      // Configuración de alarmas de agrupación
      {
        name: "agrupation-alarms-config",
        link: "/alarmas/agrupacion/configuracion",
        icon: "fas fa-map fa-fw",
        subicon: "fas fa-cog fa-fw",
        profile: [
          "ARSON",
          "ADMIN_ENTIDAD",
          "ADMIN_CLIENTE",
          "ADMIN_AGRUPACION",
        ],
        help: "help-menu-alarms-config",
      },
      // Configuración de plantilla de alarmas de entidad
      {
        name: "entity-alarms-config",
        link: "/alarmas/entidad/plantilla",
        icon: "fas fa-sitemap fa-fw",
        subicon: "fas fa-cog fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        help: "help-menu-alarms-template",
      },
      // Configuración de alarmas personalizadas
      {
        name: "alarms-custom-config",
        link: "/alarmas/personalizadas/configuracion",
        icon: "fas fa-cog fa-fw",
        profile: ["ARSON"],
        disabled: "disabled",
        help: "help-menu-alarms-config",
      },
    ],
  },
  // ANCHOR Análisis de datos
  {
    name: "data-analysis",
    link: null,
    icon: "fas fa-magnifying-glass-chart fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-data-analysis",
    submenu: [
      // Asociaciones de contadores
      {
        name: "meters-groups",
        link: "/analisis-datos/asociaciones",
        icon: "fas fa-layer-group  fa-fw",
        profile: [],
        help: "help-menu-customized-meter-assoc",
      },
      // Gráfica de contadores
      {
        name: "meter-graph",
        link: "/analisis-datos/grafica",
        icon: "fas fa-chart-area fa-fw",
        profile: [],
        help: "help-menu-data-analysis-graph",
      },
      // Comparativa de gráficas
      {
        name: "graph-comparison",
        link: "/analisis-datos/comparativa-graficas",
        icon: "fas fa-chart-area fa-fw",
        subicon: "fas fa-arrows-left-right fa-fw",
        profile: [],
        help: "help-menu-data-analysis-graph-comparison",
      },
      // Balance hidraúlico
      {
        name: "hydraulic-balance",
        link: "/analisis-datos/balance",
        icon: "fas fa-balance-scale-left",
        profile: [],
        help: "help-menu-data-analysis-balance",
      },
      // Analíticas avanzadas
      {
        name: "advanced-analytics",
        link: "/analisis-datos/analitica-avanzada",
        icon: "fas fa-chart-area fa-fw",
        subicon: "fas fa-search fa-fw",
        profile: [],
        help: "help-menu-data-analysis-analytics",
      },
      // Evolución de consumo
      {
        name: "consumption-evolution",
        link: "/analisis-datos/evolucion-consumo",
        icon: "fas fa-map fa-fw",
        subicon: "fas fa-clock fa-fw",
        profile: [],
        help: "help-menu-data-analysis-evolution",
      },
      // Consultas
      {
        name: "queries-preset",
        link: "/analisis-datos/consultas",
        icon: "fas fa-database fa-fw",
        profile: [],
        help: "help-menu-data-analysis-queries",
      },
      // Modelos analíticos
      {
        name: "analytical-models",
        link: null,
        icon: "fas fa-brain fa-fw",
        profile: ["ARSON"],
        help: "help-menu-data-analysis-models",
        submenu: [
          // Edición
          {
            name: "patterns-edition",
            link: "/analisis-datos/modelos-analiticos/editar",
            icon: "fas fa-edit fa-fw",
            profile: ["ARSON"],
            help: "help-menu-data-analysis-models",
          },
          // Patrones
          {
            name: "patterns-list",
            link: "/analisis-datos/modelos-analiticos/patrones",
            icon: "fas fa-chart-area fa-fw",
            profile: ["ARSON"],
            help: "help-menu-data-analysis-models",
          },
          // Listado de contadores
          {
            name: "patterns-meters-category",
            link: "/analisis-datos/modelos-analiticos/listado",
            icon: "fas fa-folder-tree fa-fw",
            profile: ["ARSON"],
            help: "help-menu-data-analysis-models",
          },
        ],
      },
    ],
  },
  // ANCHOR Ficheros de salida
  {
    name: "output-file",
    link: null,
    icon: "fas fa-file-export fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-data-analysis-queries",
    submenu: [
      // Aqualia
      {
        name: "Aqualia",
        link: "/ficheros/aqualia",
        icon: "fa fa-file-download fa-fw",
        profile: ["ARSON"],
        help: "help-menu-output-files",
      },
      // Descarga de fichero
      {
        name: "file-download",
        link: "/ficheros/descarga",
        icon: "fa fa-file-download fa-fw",
        profile: [
          "ARSON",
          "ADMIN_ENTIDAD",
          "ADMIN_CLIENTE",
          "ADMIN_AGRUPACION",
          "USUARIO_CLIENTE",
          "USUARIO_ENTIDAD",
          "USUARIO_AGRUPACION",
        ],
        help: "help-menu-output-files-aqualia",
      },
      // Plantilla de ficheros
      {
        name: "file-template",
        link: "/ficheros/plantillas/listado",
        icon: "fa fa-file fa-fw",
        profile: ["ARSON"],
        help: "help-menu-output-files-templates",
      },
      // Exportación periódica
      {
        name: "file-template-export",
        link: "/ficheros/plantillas/exportacion",
        icon: "fa fa-calendar-day fa-fw",
        profile: ["ARSON"],
        help: "help-menu-output-files-templates-export",
      },
      // Generación periódica
      {
        name: "file-timer",
        link: "/ficheros/horarios",
        icon: "fa fa-clock fa-fw",
        profile: ["ARSON"],
        help: "help-menu-output-files-timing",
      },
      // EK280
      {
        name: "ek-file-configuration",
        link: "/ficheros/ek280",
        icon: "fa fa-gauge fa-fw",
        subicon: "fa fa-clock fa-fw",
        profile: ["ARSON"],
        help: "help-menu-output-files-ek",
      },
    ],
  },
  // ANCHOR Sospechas
  {
    name: "suspicions",
    link: "/sospechas",
    icon: "fas fa-comment-dots",
    disabled: "disabled",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-suspicions",
  },
  // ANCHOR Estado de la red
  {
    name: "network-state",
    link: null,
    icon: "fas fa-info fa-fw",
    profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE", "ADMIN_AGRUPACION"],
    help: "help-menu-network-state",
    submenu: [
      // Global
      {
        name: "global",
        link: "/estado-red/global",
        icon: "fa fa-globe fa-fw",
        profile: ["ARSON"],
        help: "help-menu-network-state-global",
      },
      // Entidad
      {
        name: "entity",
        link: "/estado-red/entidad",
        icon: "fa fa-sitemap fa-fw",
        profile: ["ARSON", "ADMIN_ENTIDAD", "ADMIN_CLIENTE"],
        help: "help-menu-network-state-entity",
      },
      // Agrupación
      {
        name: "groups",
        link: "/estado-red/agrupacion",
        icon: "fa fa-map fa-fw",
        profile: [
          "ARSON",
          "ADMIN_ENTIDAD",
          "ADMIN_CLIENTE",
          "ADMIN_AGRUPACION",
        ],
        help: "help-menu-network-state-agrupation",
      },
      // KPIs
      {
        name: "KPIs",
        link: "/estado-red/kpis",
        icon: "fas fa-broadcast-tower",
        profile: ["ARSON", "ADMIN_CLIENTE"],
        help: "help-menu-network-state-kpis",
      },
      // Resumen global
      {
        name: "total-devices",
        link: "/estado-red/resumen-global",
        icon: "fas fa-tachometer-alt fa-fw",
        profile: ["ARSON"],
        help: "help-menu-totals-summary",
      },
    ],
  },
  // ANCHOR Sensórica
  {
    name: "sensory",
    link: null,
    icon: "fas fa-wave-square fa-fw",
    profile: [
      "ARSON",
      "ADMIN_CLIENTE",
      "USUARIO_CLIENTE",
      "ADMIN_ENTIDAD",
      "USUARIO_ENTIDAD",
      "ADMIN_AGRUPACION",
      "USUARIO_AGRUPACION",
    ],
    help: "help-menu-sensory",
    submenu: [
      // Fugas
      {
        name: "leaks-map",
        link: "/sensorica/fugas",
        icon: "fa fa-faucet-drip fa-fw",
        profile: [
          "ARSON",
          "ADMIN_CLIENTE",
          "USUARIO_CLIENTE",
          "ADMIN_ENTIDAD",
          "USUARIO_ENTIDAD",
          "ADMIN_AGRUPACION",
          "USUARIO_AGRUPACION",
        ],
        help: "help-menu-leaks-map",
      },
      // Presión
      {
        name: "pressure-map",
        link: "/sensorica/presion",
        icon: "fa fa-tachometer-alt fa-fw",
        profile: ["ARSON"],
        help: "help-menu-pressure-map",
      },
      // Limnímetros
      {
        name: "flow-map",
        link: "/sensorica/caudal",
        icon: "fa fa-water fa-fw",
        profile: ["ARSON"],
        help: "help-menu-flow-map",
      },
    ],
  },
];

export class MenuItem {
  public name: string;
  public link: string;
  public icon: string;
  public subicon: string;
  public profile: string[];
  public disabled?: string | boolean;
  public submenu?: MenuItem[];
  public shortcut?: string;

  constructor() {
    this.name = null;
    this.link = null;
    this.icon = null;
    this.profile = null;
    this.disabled = null;
  }
}
