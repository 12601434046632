import { Gateway } from "../../interfaces/GatewayGlobalInterface.type";

// Respuesta de la asignación
export class RequestResponse {
  // BackEnd
  public nroSerie?: string;
  public responseCode?: number;

  // FrontEnd
  public deviceLink?: string;
  public deviceId?: number;
  public deviceMetrologyType?: number;
  public requestState?: string;
  public highlightCurrent?: boolean;
  public highlightError?: boolean;
  public highlightSuccess?: boolean;
  public highlightWarning?: boolean;
  public commandResponse?: string;
  public errorText?: string;
  public action?: string;
  public assignable?: boolean;

  // Asignación/Desasignación
  public newGatewayId?: number;
  public newGatewayUnidadVenta?: string;
  public newGatewayAmei?: string;
  public codeModifyNewGateway?: number;
  public newGatewayLink?: string;
  public oldGatewayId?: number;
  public oldGatewayUnidadVenta?: string;
  public oldGatewayAmei?: string;
  public codeModifyOldGateway?: number;
  public oldGatewayLink?: string;

  // Reasignación
  public reassignResult?: number;
  public deleteGatewayId?: number;
  public checked?: boolean;
  public agrupation?: string;

  // Actualización SF/ADR
  public min_sf?: number;
  public adrPermission?: boolean;
  public sf?: number;
  public txPower?: number;

  // Guardado de App EUI
  public unidadVenta?: string;
  public appEui?: string;
  public entity?: number;
  public entityId?: number;
  public manufacturer?: string;
  public deleteAppEuis?: boolean;

  // Optimización
  public nroRedundantGateways?: number;

  // Relanzar gateway
  public gateway?: Gateway;
  public relaunch?: any;

  // Sustituir gateway
  public deleteEndPoints?: boolean;

  // Asignar mejor gateway redundante
  public gatewayId?: number;
  public gatewayUnidadVenta?: string;
  public task?: string;
  public gatewayLink?: string;

  // Activación
  public activationData: object[];

  constructor() {
    this.nroSerie = null;
    this.responseCode = null;
    this.deviceLink = null;
    this.deviceId = null;
    this.deviceMetrologyType = null;
    this.requestState = null;
    this.highlightCurrent = null;
    this.highlightError = null;
    this.highlightSuccess = null;
    this.highlightWarning = null;
    this.commandResponse = null;
    this.errorText = "";
    this.assignable = null;
  }
}
