import { Component, Input, OnInit } from "@angular/core";
// HighCharts
import Highcharts from "highcharts";
import heatmap from "highcharts/modules/heatmap";
heatmap(Highcharts);
// Translate
import { TranslateService } from "@ngx-translate/core";
// Interfaces
import {
  GraphHeatmapAxisData,
  GraphHeatmapData,
} from "../GraphInterface.type";
// Variables
import { GRAPH_CONFIG } from "../GRAPH_CONFIG";

@Component({
  selector: "app-graph-heatmap",
  templateUrl: "./graph-heatmap.component.html",
  styleUrls: ["./graph-heatmap.component.scss"],
})
export class GraphHeatmapComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Gráfica
  @Input()
  get data(): GraphHeatmapData {
    return this._data;
  }
  set data(data: any) {
    this._data = data;
    if (this._data) {
      this.setChartOptions();
    }
  }
  _data: any;
  @Input() width: number;
  @Input() height: number;
  @Input() tooltip: any;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag: boolean = false;
  chartOptions: any;
  options: any = {};
  @Input() xAxis: GraphHeatmapAxisData;
  @Input() yAxis: GraphHeatmapAxisData;
  @Input() colors: any[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private translate: TranslateService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Ajuste de opciones de gráfica
    let defaultOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );

    for (let attribute in defaultOptions.lang) {
      if (defaultOptions.lang[attribute].constructor === Array) {
        defaultOptions.lang[attribute] = defaultOptions.lang[attribute].map(
          (data: string) => {
            return this.translate.instant(data);
          }
        );
      } else {
        defaultOptions.lang[attribute] = this.translate.instant(
          defaultOptions.lang[attribute]
        );
      }
    }

    this.options = {
      accessibility: defaultOptions.accessibility,
      lang: defaultOptions.lang,
    };

    this.options.xAxis = {};
    this.options.plotOptions = {};

    Highcharts.setOptions(this.options);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de las opciones de la gráfica
  setChartOptions(): void {
    this.chartOptions = {
      chart: {
        backgroundColor: "transparent",
        borderWidth: 0,
        type: "heatmap",
        style: {
          overflow: "visible",
        },
        width: this.width,
        height: this.height,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        labels: { enabled: this.xAxis ? true : false },
        categories: this.xAxis?.categories ? this.xAxis.categories : [],
        title: { text: this.xAxis?.title ? this.xAxis.title : "" },
      },
      yAxis: {
        labels: { enabled: this.yAxis ? true : false },
        categories: this.yAxis?.categories ? this.yAxis.categories : [],
        title: { text: this.yAxis?.title ? this.yAxis.title : "" },
      },
      legend: {
        align: "center",
        layout: "horizontal",
        margin: 0,
      },
      series: [
        {
          name: this._data.serieTitle,
          borderWidth: 1,
          data: this._data.serie,
        },
      ],
      colorAxis: {
        stops: this.colors,
      },
      plotOptions: {
        heatmap: {
          // pointPadding: 5,
        },
        series: {
          stickyTracking: false,
        },
      },
      tooltip: this.getTooltip(),
    };
  }

  // Obtención del tooltip dependiendo de la gráfica
  getTooltip(): any {
    const self = this;
    switch (this.tooltip) {
      case "communicationReport":
        return {
          formatter: function () {
            return (
              "<b>" +
              this.series.xAxis.categories[this.point.x] +
              ":</b> " +
              this.point.value +
              " " +
              self.translate.instant("devices").toLowerCase()
            );
          },
          enabled: true,
        };
      default:
        break;
    }
  }
}
