// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { NotificationControllerService } from "../../../services/server/NotificationController.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { EntityDefinition } from "../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../table-module/TableInterface.type";
import { ImportColumn } from "../../table-module/TableInterface.type";
import { NotificationImportResponse } from "../notification.type";

@Component({
  selector: "app-notification-import",
  templateUrl: "./notification-import.component.html",
  styleUrls: ["./notification-import.component.scss"],
})
export class NotificationImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;
  currentUser: string;
  sessionProfile: string;

  // CUPS
  cupsName: string;
  cupsLabel: string;

  // Archivo
  importColumns: ImportColumn[];

  // Tabla
  rowNumbers: true;
  tableData: NotificationImportResponse[];
  showTable: boolean = false;
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "state",
      data: "state",
      search: "state",
      sort: "state",
      alter: {
        condition: "state",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "notifications-send",
      data: "cups",
      search: "cups",
      sort: "cups",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      extraInfo: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private NotificationController: NotificationControllerService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentUser = this.SessionDataService.getCurrentUser();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entityCupsConf = this.SessionDataService.getEntityCupsConf().subscribe(() => {
      this.ReloadComponentService.reload();
    });

    if (this.currentEntity && this.currentEntityCupsConf) {
      this.getCupsData();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.entityCupsConf.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del nombre y el label del CUPS de la entidad
  getCupsData(): void {
    this.cupsName = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.name;
    this.cupsLabel = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.label;
    this.getImportColumns();
  }

  // Obtención de las columnas a importar
  getImportColumns(): void {
    let importColumns: ImportColumn[] = [
      {
        title: "CUPS",
        info: this.cupsName
          ? this.cupsName
          : this.translate.instant("cups-import"),
        required: true,
        profile: true,
      },
      {
        title: "CABECERA",
        info: this.translate.instant("notification-header"),
        required: false,
        profile: true,
      },
      {
        title: "MENSAJE",
        info: this.translate.instant("notification-message"),
        required: true,
        profile: true,
      },
    ];
    this.importColumns = importColumns;
  }

  // Importación de usuario
  sendNotifications(file: File): void {
    let userFormData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file"),
        this.translate.instant("close")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("question-import")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            let tableData: NotificationImportResponse[] = [];
            userFormData.set("file", file);
            this.NotificationController.sendNotifications(
              userFormData
            ).subscribe((response) => {
              if (response["code"] == 0 || response["code"] == 1) {
                response["body"]?.forEach(
                  (notification: NotificationImportResponse) => {
                    if (notification.responseCode != 0) {
                      notification.responseCodeText = this.translate.instant(
                        "notificationError" + notification.responseCode
                      );
                      if (
                        notification.responseCodeText.includes(
                          "notificationError"
                        )
                      ) {
                        notification.responseCodeText =
                          this.translate.instant("error-text") +
                          " " +
                          notification.responseCode;
                      }
                    }
                    notification.state =
                      notification.responseCode == 0 ? true : false;
                    tableData.push(notification);
                  }
                );
              }

              if (response["code"] == 0) {
                this.ToastService.fireToastWithConfirmation(
                  "success",
                  this.translate.instant("success-import")
                );
              } else if (response["code"] == 1 && response["body"]) {
                this.ToastService.fireToastWithConfirmation(
                  "warning",
                  this.translate.instant("some-user-error")
                );
              }

              this.showTable = tableData.length > 0 ? true : false;
              if (this.showTable) {
                setTimeout(
                  () => this.viewportScroller.scrollToAnchor("import-errors"),
                  0
                );
              }
              this.tableData = tableData;
            });
          }
        });
    }
  }
}
