<!-- Modal de cambio de entidad -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ (data.candidate ? "candidate" : "manufacturer") | translate }}
    </h5>
  </div>
  <div class="dialog-body mbus-manufacturer-dialog">
    <mat-form-field appearance="standard">
      <mat-label>{{ "manufacturer-id" | translate }}</mat-label>
      <input
        matInput
        type="text"
        maxlength="4"
        minlength="4"
        [(ngModel)]="data.identificador"
        placeholder="{{ 'id' | translate }}..."
        required
      />
    </mat-form-field>
    <mat-form-field appearance="standard" *ngIf="!data.candidate">
      <mat-label>{{ "key" | translate }}</mat-label>
      <input
        matInput
        appHexInput
        maxlength="32"
        minlength="32"
        type="text"
        [(ngModel)]="data.clave"
        placeholder="{{ 'key' | translate }}..."
        required
      />
    </mat-form-field>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="
        (!data.candidate && data.clave?.length < 32) ||
        data.identificador?.length < 4
      "
      (click)="save()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
