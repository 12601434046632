<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{
        data.maintenance.id ? ("update" | translate) : ("create" | translate)
      }}
    </h5>
  </div>
  <div class="dialog-body" [ngSwitch]="data.type">
    <!-- Modal de mantenimiento hijo -->
    <div *ngSwitchCase="'child'">
      <!-- Texto -->
      <div class="maintenance-modal-input">
        <mat-form-field appearance="standard">
          <mat-label>{{ "text" | translate }}</mat-label>
          <textarea
            matInput
            [(ngModel)]="data.maintenance.historicalText"
            placeholder="{{ 'text' | translate }}..."
            required
          ></textarea>
        </mat-form-field>
      </div>
      <!-- Fecha -->
      <div class="maintenance-modal-input">
        <mat-form-field appearance="standard">
          <mat-label>{{ "date" | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="data.maintenance.timestampParsed"
            type="datetime-local"
            placeholder="{{ 'date' | translate }}..."
            required
          />
        </mat-form-field>
      </div>
      <div class="maintenance-modal-input">
        <mat-checkbox class="mat-option" [(ngModel)]="data.localTimezone">
          Europe/Madrid
        </mat-checkbox>
      </div>
    </div>
    <!-- Modal de mantenimiento padre -->
    <div *ngSwitchCase="'father'">
      <!-- Texto -->
      <div class="maintenance-modal-input">
        <mat-form-field appearance="standard">
          <mat-label> {{ "text" | translate }}</mat-label>
          <textarea
            matInput
            rows="5"
            [(ngModel)]="data.maintenance.historicalText"
            placeholder="{{ 'text' | translate }}..."
            required
          >
          </textarea>
        </mat-form-field>
      </div>
      <!-- Grupo -->
      <div class="maintenance-modal-input">
        <app-material-select
          [fullWidth]="true"
          [title]="'group' | translate"
          [bindValue]="'id'"
          [bindLabel]="'value'"
          [selected]="initialGroup"
          [data]="historicalGroupDropdown"
          (selectedOption)="data.maintenance.historicalGroup = $event?.id"
          [required]="true"
        ></app-material-select>
      </div>
      <!-- Estado -->
      <div class="maintenance-modal-input">
        <app-material-select
          [fullWidth]="true"
          [title]="'state' | translate"
          [bindValue]="'id'"
          [bindLabel]="'descripcion'"
          [selected]="initialState"
          [data]="stateDropdown"
          (selectedOption)="data.maintenance.historicalState = $event?.id"
          [required]="true"
        ></app-material-select>
      </div>
      <!-- Fecha -->
      <div class="maintenance-modal-input">
        <mat-form-field appearance="standard">
          <mat-label> {{ "date" | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="data.maintenance.timestampParsed"
            type="datetime-local"
            placeholder="{{'date' | translate}..."
            required
          />
        </mat-form-field>
      </div>
      <!-- Zona horaria -->
      <div class="maintenance-modal-input">
        <mat-checkbox [(ngModel)]="localTimezone"> Europe/Madrid </mat-checkbox>
      </div>
    </div>
    <div *ngSwitchDefault></div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="saveMaintenance()"
      [disabled]="
        data.maintenance.historicalText == null ||
        data.maintenance.historicalText == '' ||
        data.maintenance.timestampParsed == null ||
        (data.type == 'father' &&
          (data.maintenance.historicalGroup == null ||
            data.maintenance.historicalState == null))
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
