import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicio propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { ManagementControllerService } from "../../../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
// Variables
import {
  MAINTENANCE_GROUPS,
  Maintenance,
} from "../../../../management/ManagementInterface.type";
import { LOCAL_TIMEZONE } from "../../../../../../global/LOCAL_TIMEZONE";

@Component({
  selector: "app-gateway-management-dialog",
  templateUrl: "./gateway-management-dialog.component.html",
  styleUrls: ["./gateway-management-dialog.component.scss"],
})
export class GatewayManagementDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  historicalGroupDropdown: object[] = MAINTENANCE_GROUPS.map((group) => {
    return {
      id: group.id,
      value: this.translate.instant(group.value),
    };
  });
  initialGroup: number;
  stateDropdown: object[] = [
    { id: 0, descripcion: this.translate.instant("pending") },
    { id: 1, descripcion: this.translate.instant("finished") },
  ];
  initialState: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.initialGroup = this.data.maintenance.historicalGroup;
    this.initialState = this.data.maintenance.historicalState;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de mantenimiento
  saveMaintenance(): void {
    this.data.maintenance.id != null
      ? this.updateChildMaintenance()
      : this.newMaintenance();
  }

  // Nuevo mantenimiento
  newMaintenance(): void {
    let cleanData: Maintenance = new Maintenance();
    Object.keys(cleanData).forEach(
      (key) => (cleanData[key] = this.data.maintenance[key])
    );
    delete cleanData.childsFlag;
    delete cleanData.extraTableData;
    delete cleanData.historicalStateParsed;
    cleanData.id = null;
    cleanData.gateway = this.data.gatewayId;
    cleanData.timestamp = parseInt(
      this.DateParserService.toTimestamp(
        this.data.maintenance.timestampParsed,
        "YYYY/MM/DD HH:mm",
        this.data.localTimezone ? LOCAL_TIMEZONE : null
      )
    );
    delete cleanData.timestampParsed;
    this.ManagementController.save(cleanData).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("success-save")
        );
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }

  // Edición del mantenimiento
  updateChildMaintenance(): void {
    let cleanData: Maintenance = new Maintenance();
    Object.keys(cleanData).forEach(
      (key) => (cleanData[key] = this.data.maintenance[key])
    );
    delete cleanData.childsFlag;
    delete cleanData.extraTableData;
    delete cleanData.historicalStateParsed;
    cleanData.timestamp = parseInt(
      this.DateParserService.toTimestamp(
        this.data.maintenance.timestampParsed,
        "YYYY/MM/DD HH:mm",
        this.data.localTimezone ? LOCAL_TIMEZONE : null
      )
    );
    delete cleanData.timestampParsed;
    this.ManagementController.update(cleanData).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("action-edited-success")
        );
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
