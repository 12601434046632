import { Injectable } from "@angular/core";
// Moment
import * as moment_timezone from "moment-timezone";
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class DateParserService {
  constructor(private SessionDataService: SessionDataService) {}

  // Conversión a formato fecha
  parseDate(timestamp: number, format?: string, timezone?: string): string {
    return timestamp != null
      ? moment_timezone(timestamp)
          .tz(
            timezone
              ? timezone
              : this.SessionDataService.getCurrentAgrupation()?.timezone.toString()
          )
          ?.format(format)
      : "-";
  }

  // Conversión sin formato específico
  parseDateWithoutFormat(timestamp: number): moment.Moment {
    return timestamp != null
      ? moment_timezone(timestamp).tz(
          this.SessionDataService.getCurrentAgrupation()?.timezone.toString()
        )
      : null;
  }

  // Obtención de fecha actual en formato string
  getDate(unit?: string): string {
    return moment().format(unit ? unit : "ll");
  }

  // Obtención de fecha con formato
  getMomentDate(date: any, unit: string): string {
    return moment(date).format(unit);
  }

  // Obtención de la fecha actual
  getNow(): any {
    return moment();
  }

  // Obtención del timestamp actual
  getNowTimestamp(): any {
    return moment().valueOf();
  }

  // Conversión a timestamp
  toTimestamp(date: string, format: string, timezone?: string): string {
    return moment
      .tz(
        date,
        format,
        timezone
          ? timezone
          : this.SessionDataService.getCurrentAgrupation()?.timezone
      )
      .valueOf()
      .toString();
  }

  // Conversión a timestamp
  toRawTimestamp(date: string, format: string, timezone?: string): number {
    return moment
      .tz(
        date,
        format,
        timezone
          ? timezone
          : this.SessionDataService.getCurrentAgrupation()?.timezone
      )
      .valueOf();
  }

  // Obtención de la última semana
  getLastDays(
    amount?: moment_timezone.DurationInputArg1,
    unit?: moment.unitOfTime.DurationConstructor
  ): { startDate: moment.Moment; endDate: moment.Moment } {
    return {
      startDate: amount
        ? moment()
            .startOf("day")
            .subtract(amount, unit ? unit : "days")
        : moment(0),
      endDate: moment().endOf("day"),
    };
  }

  // Obtención de las últimas semanas naturales
  getLastNaturalWeeks(amount?: moment_timezone.DurationInputArg1): {
    startDate: moment.Moment;
    endDate: moment.Moment;
  } {
    return {
      startDate: moment().startOf("isoWeek").subtract(amount, "week"),
      endDate: moment().endOf("day"),
    };
  }

  // Obtención de los últimos años naturales
  getLastNaturalYears(amount?: moment_timezone.DurationInputArg1): {
    startDate: moment.Moment;
    endDate: moment.Moment;
  } {
    return {
      startDate: moment().startOf("year").subtract(amount, "year"),
      endDate: moment().endOf("day"),
    };
  }

  // Obtención de la hora del reloj
  getClock(format: string): string {
    return moment_timezone()
      .tz(this.SessionDataService.getCurrentAgrupation()?.timezone)
      ?.format(format);
  }

  // Obtención de todo el rango posible de fechas
  getAll(): { startDate: moment.Moment; endDate: moment.Moment } {
    return { startDate: moment(0), endDate: moment() };
  }

  // Conversión del formato de fecha del input a timestamp
  getInputDateTimestamp(
    date: string,
    timezone?: string,
    format?: string
  ): string {
    if (date) {
      let dateArray = date.split("T");
      let dateHour = dateArray[1];
      let dateDay = dateArray[0].split("-");
      return this.toTimestamp(
        dateDay[2] + "/" + dateDay[1] + "/" + dateDay[0] + " " + dateHour,
        format ? format : "L HH:mm",
        timezone
      );
    } else {
      return null;
    }
  }

  // Obtención del offset de timezone
  getTimezoneOffset(timezone: string): any {
    let offset = moment_timezone().tz(timezone).utcOffset();
    return offset != null
      ? "GMT" + (offset >= 0 ? "+" : "-") + offset / 60
      : null;
  }

  getTimezoneOffsetMinutes(timezone: string): any {
    return moment_timezone().tz(timezone).utcOffset();
  }
}
