import { Component, Input, Output, EventEmitter } from "@angular/core";
import { PanelMenuOption } from "../MaterialInterface.type";

@Component({
  selector: "app-material-panel-menu",
  templateUrl: "./material-panel-menu.component.html",
  styleUrls: ["./material-panel-menu.component.scss"],
})
export class MaterialPanelMenuComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input()
  get options(): PanelMenuOption[] {
    return this._options;
  }
  set options(options: PanelMenuOption[]) {
    if (options) {
      let bottomOptions = options.filter(
        (option: PanelMenuOption) => option.bottom
      );
      if (
        bottomOptions?.length > 0 &&
        bottomOptions.some((option: PanelMenuOption) => option.visible)
      ) {
        this._options = options
          .filter((option: PanelMenuOption) => !option.bottom)
          .concat({
            action: "separator",
            icon: null,
            text: null,

            visible: true,
          })
          .concat(bottomOptions);
      } else {
        this._options = options;
      }
    }
  }
  _options: PanelMenuOption[];
  @Input() onlyButton: boolean;
  @Input() icon: string;
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() updating: boolean;
  @Input() withoutFormat: boolean;
  submenuOptions: PanelMenuOption[];
  @Output() action = new EventEmitter<string>();
  @Output() range = new EventEmitter<number>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/
  
  constructor() {}
}
