import { Injectable } from "@angular/core";
import {
  InternalDevice,
  InternalDeviceMinimal,
} from "../../../DeviceInterface.type";

@Injectable({
  providedIn: "root",
})
export class InternalDeviceParseService {
  constructor() {}

  // Conversión de objeto de dispositivo mínimo a objeto completo
  parseDevices(newDevices: InternalDeviceMinimal[]): InternalDevice[] {
    let devices: InternalDevice[] = newDevices.map(
      (device: InternalDeviceMinimal) => {
        let parsedDevice: InternalDevice = {
          // BackEnd
          id: device.id,
          nroSerie: device.ns,
          sf: device.sf,
          sfMin: device.min_sf,
          adrPermission: device.adr_pr == null ? null : device.adr_pr == 1,
          rssi: device.rssi,
          snr: device.snr,
          lastCommunication: device.lc,
          lastReadedValue: device.lrv,
          lastJoinTimestamp: device.ljt,
          lastReadedTimestamp: device.lrt,
          activate: device.act,
          fabricante: device.f,
          devType: device.d,
          installation: device.i,
          metrologyType: device.mt,
          isBMode: device.isBMode,
          latitude: device.lt,
          longitude: device.lg,
          rfModule: device.rf,
          nroRedundantGateways: device.nrg,
          alias: device.a,
          pc: device.pc,
          tipo: device.tp,
          hasImage: device.im ? true : false,
        };
        return parsedDevice;
      }
    );
    return devices;
  }
}
