<!-- Modal de cambio de agrupación -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "move-readings" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <div class="move-readings-advise">
      {{ "move-readings-deletion-advise" | translate }}: <span>{{ data }}</span>
    </div>
    <div>{{ "move-readings-target-advise" | translate }}:</div>
    <div class="move-readings-to">
      <mat-form-field appearance="standard">
        <mat-label>{{ "serial-number" | translate }}</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="toMeter"
          placeholder="{{ 'serial-number' | translate }}..."
          required
        />
      </mat-form-field>
      <button mat-button (click)="checkMove()">
        {{ "accept" | translate }}
      </button>
    </div>
    <div *ngIf="moveCheckData" class="move-readings-check">
      <span>{{ "readings" | translate }}:</span>
      <div>
        {{ "start-date" | translate }}:
        {{ moveCheckData?.minTimestampReadingsParsed }}
      </div>
      <div>
        {{ "end-date" | translate }}:
        {{ moveCheckData?.maxTimestampReadingsParsed }}
      </div>
      <div>
        {{ "total" | translate }}:
        {{ moveCheckData?.countReadings }}
      </div>
      <span>{{ "consumptions" | translate }}:</span>
      <div>
        {{ "start-date" | translate }}:
        {{ moveCheckData?.minTimestampConsumptionsParsed }}
      </div>
      <div>
        {{ "end-date" | translate }}:
        {{ moveCheckData?.maxTimestampConsumptionsParsed }}
      </div>
      <div>
        {{ "total" | translate }}:
        {{ moveCheckData?.countConsumptions }}
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="!moveCheckData"
      (click)="moveReadingsConsumptions()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
