// ANCHOR Agrupaciones con fichero
export interface AgrupationFiles {
  hour: string;
  agrupations: number[];
  selected?: number;
  showTree?: boolean;
  showHour?: boolean;
  mode?: number;
}

// ANCHOR Cambios en fichero
export interface UpdatedAgrupationFiles {
  agrupationIdList: number[];
  hour: number;
  modo: number;
}

// ANCHOR Fichero ATM
export interface AtmResponse {
  foundedMeters: number;
  unfoundedMeters: number;
  unfoundedMetersList: string[];
  foundedReadings: number;
  unfoundedReadings: number;
  unfoundedReadingsList: string[];
  file: any;
  fileStr: string;
}

// ANCHOR Ventanas de envío de ficheros EK280
export interface EkFileWindows {
  hora1: string;
  hora2: string;
  hora3: string;
}

// ANCHOR Job de Aqualia
export interface AqualiaJob {
  //Back End
  id: string;
  type: string;
  group: string;
  name: string;
  timestamp: number;
  agrupations: AqualiaJobAgrupation[];

  // Front End
  timestampParsed?: string;
  highlightCurrentJob?: boolean;
  highlightError?: boolean;
  error?: number;
  extraTableData?: any;
  fileSent?: number;
}

// ANCHOR Agrupación de job de Aqualia
export interface AqualiaJobAgrupation {
  //Back End
  agrupation: number;
  entity: number;
  files: AqualiaJobFile[];
  error: string;
  agrupationDescription: string;
  entityDescription: string;

  // Front End
  extraTableData?: any;
  highlightError?: boolean;
  highlightWarning?: boolean;
  nameFile?: string;
  sizeFileFtp?: number;
  sended?: boolean;
}

// ANCHOR Fichero de job de Aqualia
export interface AqualiaJobFile {
  // Back End
  nameFile: string;
  wasPending: boolean;
  sizeFileFtp: number;
  sended: boolean;
  validatedInFtp: boolean;

  // Front End
  entity?: number;
  agrupation?: number;
}

// ANCHOR Fichero de e Aqualia
export interface AqualiaFile {
  entity: number;
  agrupation: number;
  fileName: string;
  sended: boolean;
}

// ANCHOR Datos de fichero
export interface OutputFileData {
  agrupations: number[];
  extraData: number[];
}

// ANCHOR Plantilla de fichero
export class FileTemplate {
  // Back End
  public id: number;
  public templateName: string;
  public extraData: number[];
  public dias: number[];
  public limiteDias: number;
  public tipo: number;
  public entity: number;
  public language: string;

  // Fron End
  public extraDataParsed?: string;
  public tipoParsed?: string;
  public diasParsed?: string;
  public diasArray?: boolean[];
  public languageParsed?: string;

  constructor() {
    this.id = null;
    this.templateName = null;
    this.extraData = null;
    this.dias = null;
    this.limiteDias = null;
    this.tipo = null;
    this.entity = null;
    this.language = null;
  }
}

// ANCHOR Cron de fichero
export class FileCron {
  // Back End
  public id: number;
  public name: string;
  public agrupations: number[];
  public frecuencia: number;
  public detalleFrecuencia: string;
  public hora: string;
  public template: number;
  public emails: string;
  public ftpConfig: number;
  public fechaCreacion?: number;

  // Fron End
  public fechaCreacionParsed?: string;
  public templateParsed?: string;
  public frecuenciaParsed?: string;
  public agrupationsParsed?: string;
  public weekDays?: boolean[];
  public monthDays?: string;
  public monthDaysArray?: boolean[];
  public dayInterval?: string;
  public modalInfoData?: any;
  public ftpParsed?: string;
  public horaLocal?: string;
  public frecuenciaLocal?: string;

  constructor() {
    this.id = null;
    this.name = null;
    this.agrupations = null;
    this.frecuencia = null;
    this.detalleFrecuencia = null;
    this.hora = null;
    this.template = null;
    this.emails = null;
    this.ftpConfig = null;
  }
}

// ANCHOR FTP
export interface Ftp {
  // Back End
  id: number;
  url: string;
  port: number;
  user: string;
  password: string;
  type: number;
  rootPath: string;
  processedRootPath: string;
  localPath: string;
  processedLocalPath: string;
  ownerProcess: string;

  // Fron End
  ftpParsed: string;
}
