import { Component, Input, AfterViewInit } from "@angular/core";
// Fichero
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";

@Component({
  selector: "app-manufacturer-dialog",
  templateUrl: "./manufacturer-dialog.component.html",
  styleUrls: ["./manufacturer-dialog.component.scss"],
})
export class ManufacturerDialogComponent implements AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  selectedManufacturer: number;
  kem2File: File;
  kem2Password: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  // Corrección de anchura de cuerpo del modal reseteando tabla
  ngAfterViewInit(): void {
    setTimeout(() => (this.selectedManufacturer = null), 0);
  }

  // Descarga de fichero KEM2
  downloadKem2(): void {
    let formData: FormData = new FormData();
    formData.set("file", this.kem2File);
    formData.set("pw", this.kem2Password);
    this.MeterController.importKem2(formData).subscribe((response) => {
      saveAs(response, this.kem2File.name + ".csv");
      this.SessionDataService.sendDialogAction({ action: "close" });
    });
  }
}
