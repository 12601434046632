<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>
          {{ "frames-logs" | translate }}:
          {{ unidadVenta != null ? unidadVenta : ("no-data" | translate) }}</b
        >
      </h5>

      <!-- Menú de opciones del panel -->
      <app-material-panel-menu
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>
    <div class="panel-body">
      <div class="frames-logs-filters">
        <button
          *ngIf="gatewayFrames"
          mat-button
          class="mat-button-icon"
          (click)="relaunchFrames()"
        >
          <div class="filters-icon">
            <i class="fas fa-sync-alt"></i>
          </div>
          {{ "frames-relaunch" | translate }}
        </button>

        <!-- Resaltar tramas repetidas -->
        <button
          *ngIf="gatewayFrames"
          mat-button
          class="mat-button-icon"
          [ngClass]="{ inactive: !framesHighlighted }"
          (click)="
            framesHighlighted = !framesHighlighted;
            updateFramesFilter(framesHighlighted)
          "
          [disabled]="
            !originalGatewayFrames || originalGatewayFrames?.length == 0
          "
        >
          <div class="filters-icon">
            <i *ngIf="!framesHighlighted" class="fas fa-slash"></i>
            <i class="fas fa-eye"></i>
          </div>
          {{ "highlight-frames" | translate }}
        </button>

        <!-- Filtrado de tramas repetidas -->
        <button
          *ngIf="gatewayFrames"
          mat-button
          class="mat-button-icon"
          [ngClass]="{ inactive: !framesFiltered }"
          (click)="framesFiltered = !framesFiltered; updateFramesFilter()"
          [disabled]="
            !originalGatewayFrames || originalGatewayFrames?.length == 0
          "
        >
          <div class="filters-icon">
            <i *ngIf="!framesFiltered" class="fas fa-slash"></i>
            <i class="fas fa-filter"></i>
          </div>
          {{ "filter-frames" | translate }}
        </button>
      </div>

      <!-- Tabla de gateways -->
      <app-table-controller
        [export]="true"
        [exportFileName]="exportFileName"
        [columns]="columns"
        [rowNumbers]="true"
        [orderBy]="orderBy"
        [maxReg]="tableMaxReg"
        (tableAction)="tableActions($event.action.name, $event.element)"
        [tableGlobalActions]="tableGlobalActions"
        (tableGlobalAction)="tableGlobalAction($event.title)"
        [highlightRow]="tableHighlightRow"
        [dateRangeSelector]="true"
        [initialDate]="dataInitialDate"
        (dataRequest)="getData($event.from, $event.to)"
        [specialFilter]="true"
        (selectedDataUpdateFlag)="tableSelectedData = $event"
        [data]="gatewayFrames"
      >
      </app-table-controller>
    </div>
  </div>
</section>
