<div class="cesium-map-controller" *ngIf="mapReady; else elseBlock">
  <div *ngIf="filterCoverByGateway" class="coverage-heatmap-filter-gateway">
    <span
      >{{ "filtered-by-gateway" | translate }}:
      <b>{{ filteredByGateway?.name }}</b></span
    >
    <button type="button" (click)="resetFilter('filterCoverByGateway')">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <app-cesium-map
    [bounds]="bounds"
    [mapType]="mapType"
    [coverageLayersData]="coverageLayersData"
    [drawAgrupationOutline]="drawAgrupationOutline"
    [agrupationLayerData]="agrupationLayerData"
    [allowKml]="allowKml"
    [updatedEntity]="updatedEntity"
    (updateTooltip)="updateTooltip($event)"
    (updateLocationTooltip)="updateLocationTooltip($event)"
    (updateLocationsFlag)="updateLocations($event)"
    (mapReset)="mapReset.emit()"
    (action)="mapAction($event.action, $event.data)"
    [mapData]="mapTypeData"
  ></app-cesium-map>
</div>

<!-- Spinner de carga -->
<ng-template #elseBlock>
  <div class="loading-spinner">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</ng-template>
