<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b
            >{{ "advanced-analytics" | translate }}
            {{
              timestampParsed != null
                ? " - " +
                  ("total-consumption-last-month" | translate) +
                  ": " +
                  timestampParsed
                : null
            }}</b
          >
        </h5>
      </div>

      <div #advancedAnalyticsPanel class="panel-body advanced-analytics-panel">
        <div
          *ngIf="mapData"
          id="advanced-analytics-filters"
          class="advanced-analytics-filters"
        >
          <!-- Valor de consumo máximo -->
          <div class="form-group advanced-analytics-range">
            <label for="consumptionMaxValue">
              {{ "consumption" | translate }} <=
              {{ consumptionRange | number : "1.0-0" }} m³
            </label>
            <div class="advanced-analytics-range-limits">
              <span>0</span
              ><span>{{
                consumptionMaxValue != null ? consumptionMaxValue : 0
              }}</span>
            </div>
            <input
              type="range"
              class="pointer"
              max="{{ consumptionMaxValue }}"
              min="0"
              step="any"
              [(ngModel)]="consumptionRange"
              (change)="updateMapData()"
              [disabled]="mapData.length == 0"
            />
            <div class="advanced-analytics-filters-options">
              <div>
                <input
                  type="radio"
                  id="filter"
                  name="dataFilter"
                  value="filter"
                  (click)="resetFilter(true)"
                  [disabled]="mapData.length > 0 ? false : true"
                  checked
                />
                <label for="filter">{{ "filter" | translate }}</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="reduce"
                  name="dataFilter"
                  value="reduce"
                  (click)="resetFilter(false)"
                  [disabled]="mapData.length > 0 ? false : true"
                />
                <label for="reduce">{{ "reduce" | translate }}</label>
              </div>
            </div>
          </div>

          <!-- Radio -->
          <div class="form-group advanced-analytics-range">
            <label for="radio"> Radio {{ radio | number : "1.0-0" }} </label>
            <div class="advanced-analytics-range-limits">
              <span>{{ "min" | translate }}</span
              ><span>{{ "max" | translate }}</span>
            </div>
            <input
              type="range"
              class="pointer"
              max="25"
              min="0.000000001"
              step="any"
              [(ngModel)]="radius"
              (change)="updateMapData()"
              [disabled]="mapData.length == 0"
            />
          </div>
        </div>

        <!-- Mapa de calor -->
        <div #mapContainer>
          <app-map-controller
            *ngIf="mapHeight"
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            [heatLayerData]="mapData"
            [heatLayerRadius]="radius"
            [heatLayerRange]="consumptionRange"
            [disableCluster]="true"
            [disableLegend]="true"
            [disableMeasure]="true"
            [gatewaysData]="[]"
            [metersData]="[]"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
