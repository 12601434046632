// @angular
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ControlAlertsControllerService } from "../../../../services/server/ControlAlertsController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
// Interfaces
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ControlMeter } from "../ControlInterface.type";

@Component({
  selector: "app-control-modal",
  templateUrl: "./control-modal.component.html",
  styleUrls: [
    "./control-modal.component.scss",
    "../../../../modules/card-module/card/card.component.scss",
  ],
})
export class ControlModalComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  valveStateRequestTimer: any;
  valveStateRequestTimerMS: number = 30000;

  // Tabla
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  onlyTable: boolean = true;
  orderBy: object = { attribute: "initDate", reverse: true };
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "date",
      data: "initDateParsed",
      search: "initDateParsed",
      sort: "initDate",
      date: true,
      visible: true,
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "serial-number",
      data: "contadorNroSerie",
      search: "contadorNroSerie",
      sort: "contadorNroSerie",
      link: "deviceLink",
      visible: true,
    },
    {
      title: "event",
      data: "eventDetails",
      search: "eventDetails",
      sort: "eventDetails",
      visible: true,
    },
  ];

  // Tarjetas
  cardsData: any;

  // Estados de la válvula
  valveStateText: any = {
    0: this.translate.instant("valve-close"),
    1: this.translate.instant("valve-open"),
    2: this.translate.instant("valve-pending"),
    3: this.translate.instant("close-command"),
    4: this.translate.instant("open-command"),
    5: this.translate.instant("open-command-sent"),
  };

  // Instrucciones
  instructions: string[] = [
    "instruction1",
    "instruction2",
    "instruction3",
    "instruction4",
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ControlAlertsController: ControlAlertsControllerService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.parseValveState();
    this.updateValveState();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearInterval(this.valveStateRequestTimer);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización de los datos del modal
  parseValveState() {
    this.data.meters.forEach((meter) => {
      this.valveStateText[meter.meterValveState]
        ? (meter.valveStateParsed = this.valveStateText[meter.meterValveState])
        : this.translate.instant("unknown");
      meter.pendingState =
        meter.meterValveState != 0 &&
        meter.meterValveState != 1 &&
        meter.meterValveState != 2;
    });
  }

  // Apertura de válvula
  openValve(meter: ControlMeter): void {
    let toastTitle: string =
      this.data.valveState == 1 || this.data.valveState == 2
        ? this.translate.instant("open-valve-already-question")
        : this.translate.instant("open-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MeterController.openValve(meter.meterId).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("open-valve-sucessfull")
                );
                meter.meterValveState = response["body"];
                this.parseValveState();
              }
            }
          );
        }
      }
    );
  }

  // Lectura de la válvula
  readValve(meter: ControlMeter): void {
    this.ToastService.fireAlertWithOptions(
      "info",
      this.translate.instant("read-valve-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.readValve(meter.meterId).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("check-state-sucessfull")
            );
            meter.meterValveState = response["body"];
            this.parseValveState();
          }
        });
      }
    });
  }

  // Cierre de válvula
  closeValve(meter: ControlMeter): void {
    let toastTitle: string =
      this.data.valveState == 0
        ? this.translate.instant("close-valve-already-question")
        : this.translate.instant("close-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MeterController.closeValve(meter.meterId).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("close-valve-sended")
                );
                meter.meterValveState = response["body"];
                this.parseValveState();
              }
            }
          );
        }
      }
    );
  }

  // Finalización de la alerta
  finishAlarm(): void {
    this.ToastService.fireAlertWithOptions(
      "info",
      this.translate.instant("finish-alarm-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.ControlAlertsController.finishAlert(this.data.alertId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("finish-alarm-successful")
              );
              clearInterval(this.valveStateRequestTimer);
              this.SessionDataService.sendDialogAction({ action: "reload" });
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          }
        );
      }
    });
  }

  // Actualización del estado de la alerta
  updateValveState(): void {
    clearInterval(this.valveStateRequestTimer);
    this.valveStateRequestTimer = setInterval(
      () => this.getValveState(),
      this.valveStateRequestTimerMS
    );
  }

  // Obtención del estado de la válvula
  getValveState() {
    this.MeterController.readValveModal(this.data.alertId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.data.currentAlarm = response["body"]?.currentAlarm;
          this.data.currentAlarmParsed =
            response["body"]["currentAlarm"] != null
              ? this.translate.instant(
                  "AlertMeter" + response["body"]["currentAlarm"]
                )
              : this.translate.instant("no-alerts");
          this.data.meters = response["body"]?.meters;
          this.parseValveState();
          this.data.events = response["body"]?.events.map((event) => {
            if (event.contador) {
              event.deviceLink =
                this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  event.metrologyType,
                  event.contador
                );
            }
            return event;
          });
        }
      }
    );
  }
}
