<!-- Mapa de selección de contadores -->
<div class="meter-list-map">
  <div
    id="map-panel-anchor"
    style="position: absolute; top: -20rem; left: 0"
  ></div>
  <app-map-table-selector
    *ngIf="meters && gateways"
    [dataList]="dataList"
    [originalDataList]="originalDataList"
    [selectedData]="selectedMeters"
    [table]="table"
    [filterAttribute]="'nroSerie'"
    [anchorToScroll]="'table-panel'"
    (updateData)="updateData.emit($event)"
  >
  </app-map-table-selector>

  <!-- Mapa de contadores -->
  <app-map-controller
    *ngIf="meters && gateways"
    [mapHeight]="mapHeight"
    [mapType]="mapType"
    [freeDrawEnabled]="true"
    (selectedDevices)="selectMeters($event)"
    (actionFlag)="selectSingleMeter($event)"
    [gatewaysData]="gateways"
    [metersData]="meters"
  >
  </app-map-controller>
</div>
