// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { ImportColumn } from "../../../../../modules/table-module/TableInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
// Variables
import { ImportResponseDevice, METER_IMPORT_COLUMNS } from "./import-columns";

@Component({
  selector: "app-device-location-import",
  templateUrl: "./device-location-import.component.html",
  styleUrls: ["./device-location-import.component.scss"],
})
export class DeviceLocationImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionProfile: string;

  // Importación
  importColumns: ImportColumn[];

  // Tabla
  tableData: ImportResponseDevice[];
  showTable: boolean = false;
  exportFileName: string =
    this.translate.instant("import-result") +
    " " +
    this.DateParserService.getDate();
  orderBy: object = { attribute: "state", reverse: false };
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "serial-number",
      data: "nro_serie",
      search: "nro_serie",
      sort: "nro_serie",
      visible: true,
    },
    {
      title: "latitude",
      data: "latitude",
      search: "latitude",
      sort: "latitude",
      visible: true,
    },
    {
      title: "longitude",
      data: "longitude",
      search: "longitude",
      sort: "longitude",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(): void {
    this.getImportColumns();
  }

  // Obtención de las columnas a importar
  getImportColumns(): void {
    let importColumns: ImportColumn[] = JSON.parse(
      JSON.stringify(METER_IMPORT_COLUMNS)
    );
    importColumns.map((column: ImportColumn) => {
      column.profile =
        column.profilesList.length == 0
          ? true
          : column.profilesList.includes(this.sessionProfile);
      column.info = this.translate.instant(column.info);
    });
    this.importColumns = importColumns;
  }

  // Importación de dispositivo
  importDevice(file: File): void {
    let formData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        "warning",
        this.translate.instant("question-import")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let tableData: ImportResponseDevice[] = [];
          formData.set("file", file);
          this.MeterController.coordsImport(formData).subscribe((response) => {
            if (response["code"] == 0 || response["code"] == 1) {
              response["body"]?.forEach((device: ImportResponseDevice) => {
                if (device.code != 0) {
                  device.responseCodeText = this.translate.instant(
                    "httpError" + device.code
                  );
                  if (device.responseCodeText.includes("httpError")) {
                    device.responseCodeText =
                      this.translate.instant("error-text") + " " + device.code;
                  }
                  tableData.push(device);
                }
              });

              if (response["code"] == 0) {
                this.ToastService.fireToastWithConfirmation(
                  "success",
                  this.translate.instant("success-import")
                );
              } else if (response["code"] == 1 && response["body"]) {
                this.ToastService.fireToastWithConfirmation(
                  "warning",
                  this.translate.instant("some-meter-error")
                );
              }
              this.showTable = tableData.length > 0 ? true : false;
              this.tableData = tableData;
            }
          });
        }
      });
    }
  }
}
