// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import { DeactivatedDevice } from "../../../DeviceInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-meterslistdeactivated",
  templateUrl: "./meter-deactivated.component.html",
  styleUrls: ["./meter-deactivated.component.scss"],
})
export class MetersListDeactivatedComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Tabla
  tableMaxReg: number = 100;
  meterList: DeactivatedDevice[];
  tableSelectedData: DeactivatedDevice[];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn | TableSelectColumn)[];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "meters",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 0 },
        active: false,
      },
      {
        name: "gas-meters",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 2 },
        active: false,
      },
      {
        name: "sensors",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 1 },
        active: false,
      },
      {
        name: "concentrators",
        columnSearch: "isConcentrator",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "erase-selection",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
  ];

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setColumns();
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.updating = true;
    this.MeterController.tableDeactivated(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let meterList: DeactivatedDevice[] = response["body"];
          meterList.forEach((meter: DeactivatedDevice) => {
            meter.metrologyTypeParsed =
              meter.metrologyType != null
                ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
                : "-";
            meter.deviceLink =
              this.DeviceRouteSelectorService.getDeviceRouteUrl(
                meter.metrologyType,
                meter.id
              );
            meter.isConcentrator =
              meter.metrologyType == 3 || meter.metrologyType == 5;
          });
          this.meterList = meterList;
        }
        this.updating = false;
      }
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "delete-meter",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: {
              attribute: null,
              rule:
                this.sessionProfile == "ARSON" ||
                this.sessionProfile == "ADMIN_CLIENTE" ||
                this.sessionProfile == "ADMIN_ENTIDAD" ||
                this.sessionProfile == "ADMIN_AGRUPACION",
            },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "type",
        data: "metrologyTypeParsed",
        search: "metrologyTypeParsed",
        sort: "metrologyTypeParsed",
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "deviceLink",
      },
      {
        title: "date-last-value",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, meter: DeactivatedDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "delete-meter":
        this.deleteMeter(meter);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "erase-selection":
        if (this.tableSelectedData.length == 1) {
          this.deleteMeter(this.tableSelectedData[0]);
        } else {
          this.deleteMultiple();
        }
        break;
      default:
        break;
    }
  }

  // Redirección a contador
  showMeter(meter: DeactivatedDevice): void {
    let currentEntity: Entity = this.SessionDataService.getCurrentEntity();
    let agrupation: Agrupation = meter.agrupation
      ? currentEntity.agrupations.find(
          (agrupation: Agrupation) => (agrupation.id = meter.agrupation)
        )
      : {
          entity: null,
          id: null,
          name: "-",
          showAllEntity: null,
          timezone: currentEntity.agrupations[0].timezone,
        };
    this.SessionDataService.sendAgrupation(agrupation);
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.id
    );
  }

  // Eliminación de contador
  deleteMeter(meter: DeactivatedDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("delete-meter-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.removeMeter(
          meter.id,
          this.currentAgrupation.id
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("meter-deleted")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Reseteo de múltiples contadores
  deleteMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-delete-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: DeactivatedDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("delete", data);
      }
    });
  }

  // Actualización de los datos
  updateData(): void {
    this.meterList = null;
    this.getData();
  }
}
