// ANCHOR Horario de satélite
export class SateliteSchedule {
  public id?: number;
  public programa?: number;
  public opcionCambioVI?: number;
  public active: {
    enabled: boolean;
    date: {
      startDate: string;
      endDate: string;
    };
  };
  public interval: {
    selected: string;
    byInterval: number;
    byDay: number[];
  };
  public turns: SateliteTurn[];

  constructor() {
    this.active = {
      enabled: false,
      date: {
        startDate: null,
        endDate: null,
      },
    };
    this.interval = {
      selected: null,
      byInterval: 0,
      byDay: [0, 0, 0, 0, 0, 0, 0],
    };
    this.turns = [
      {
        id: null,
        date: { startTime: "00:00", endTime: "00:00" },
        pulses: 0,
      },
    ];
  }
}

// ANCHOR Turno de satélite
export interface SateliteTurn {
  id: number;
  date: { startTime: string; endTime: string };
  pulses: number;
}

// Semana
export const WEEK = [
  "week-monday",
  "week-tuesday",
  "week-wednesday",
  "week-thursday",
  "week-friday",
  "week-saturday",
  "week-sunday",
];

// ANCHOR Datos de satélite
export interface SateliteData {
  id: number;
  satelite: number;
  activo: number;
  programa: number;
  diaDesde: number;
  mesDesde: number;
  anyoDesde: number;
  diaHasta: number;
  mesHasta: number;
  anyoHasta: number;
  frecuencia: number[];
  tipo: number;
  opcionCambioVI: number;
  listaTurnos: SateliteTurnData[];
}

// ANCHOR Guardado de satélite
export interface SateliteSaveData {
  satelite: number;
  activo: number;
  programa: number;
  diaDesde: number;
  mesDesde: number;
  anyoDesde: number;
  diaHasta: number;
  mesHasta: number;
  anyoHasta: number;
  frecuencia: number[];
  tipo: number;
  opcionCambioVI: number;
  estado?: number;
  listaTurnos: SateliteTurnData[];
}

// ANCHOR Datos de turno
export interface SateliteTurnData {
  id: number;
  minutoInicio: number;
  horaInicio: number;
  minutoFin: number;
  horaFin: number;
  pulsos: number;
}
