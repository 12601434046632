// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interface
import { QueryData } from "../../screens/dashboard/queries/QueryInterface.type";

@Injectable({
  providedIn: "root",
})
export class QueriesControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Contadores por consumo y hora
  getMetersByConsumtionAndHour(data: QueryData): Observable<object> {
    return this.HttpRequestService.post("/query/altoConsumo", data);
  }

  // Contadores por consumo y hora
  getStoppedMeters(data: QueryData): Observable<object> {
    return this.HttpRequestService.post("/query/nuloConsumo", data);
  }

  // Contadores por consumo y hora
  getNegativeConsumption(data: QueryData): Observable<object> {
    return this.HttpRequestService.post("/query/negativoConsumo", data);
  }

  // Contadores por consumo y hora
  getLeaks(data: QueryData): Observable<object> {
    return this.HttpRequestService.post("/query/fuga", data);
  }

  // Contadores por picos anómalos
  getAbnormalSpikes(data: QueryData): Observable<object> {
    return this.HttpRequestService.post("/query/picosAnomalos", data);
  }
}
