import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { NotificationControllerService } from "../../../services/server/NotificationController.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { NotificationModalComponent } from "../notification-modal/notification-modal.component";

@Injectable({
  providedIn: "root",
})
export class NotificationModalService {
  notificationData: any;

  constructor(
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private NotificationController: NotificationControllerService,
    private SessionDataService: SessionDataService
  ) {}

  showNotifications(id?: number): void {
    this.notificationData = null;
    this.getData(id);
    this.MaterialDialogService.openDialog(NotificationModalComponent, null);
  }

  getData(id?: number): void {
    let notificationUrl: Observable<object> = id
      ? this.NotificationController.getCupsNotifications(id)
      : this.NotificationController.getUserNotifications();
    let dateFormat = this.SessionDataService.getCurrentDateFormat();
    notificationUrl.subscribe((response) => {
      if (response["code"] == 0) {
        let notificationData: any[] = response["body"];
        notificationData?.map((notification: any) => {
          notification.fechaEnvioParsed = this.DateParserService.parseDate(
            notification.fechaEnvio,
            dateFormat + " HH:mm:ss"
          );
          notification.pending = !notification.leida;
        });
        this.notificationData = notificationData;
        this.SessionDataService.sendNotificationData(this.notificationData);
      }
    });
  }
}
