import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class FrameDescriptorService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  LoRaWAN_MBus_Descriptor(frame) {
    if (frame) {
      var frameBytes = this.SepemDecoderService.hexToBytes(frame);
      var fType = (frameBytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              case 0x01:
                return "PING";
              case 0x4d:
                return "ACU_CLEAR";
              case 0x4e:
                return "UNKNOWN_CLEAR";
              case 0x4f:
                return "AJUSTES";
              case 0x50:
                return "DISPOSITIVOS";
              case 0x51:
                return "FABRICANTES";
              case 0x52:
                return "CANDIDATOS";
              case 0x53:
                return "RESET";
              case 0x54:
                return "HELLO";
              case 0x55:
                return "VALORES_CNT";
              case 0x56:
                return "DESCONOCIDOS";
              case 0x57:
                return "FIJOS";
              case 0x58 /* Reenvío de tramas de contadores */:
                return "CMD REENVIAR";
              case 0xc9:
                return "ACTUALIZACIÓN FW";
              case 200:
                return "MULTICAST_CFG";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              case 0x4d:
                return "CMD ACU_CLEAR";
              case 0x4e:
                return "CMD UNKNOWN_CLEAR";
              case 0x4f /* Listado de ajustes */:
                return "CMD AJUSTES";
              case 0x50:
                return "CMD DISPOSITIVOS";
              case 0x51:
                return "CMD FABRICANTES";
              case 0x52:
                return "CMD CANDIDATOS";
              case 0x54:
                return "PETICIÓN HELLO";
              case 0x55:
                return "PETICIÓN VALORES_CNT";
              case 0x57:
                return "CMD FIJOS";
              case 0x58:
                return "CMD REENVIAR";
              case 0xc9:
                return "ACTUALIZACIÓN FW";
              case 200:
                return "MULTICAST_CFG";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
