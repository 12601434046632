// @angular
import { Component, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { CronManagementControllerService } from "../../../../services/server/CronManagementController.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import { Job, TriggerInfo } from "../ManagementInterface.type";
// Componentes
import { ManagementCronDialogComponent } from "./management-cron-dialog/management-cron-dialog.component";

@Component({
  selector: "app-management-cron",
  templateUrl: "./management-cron.component.html",
  styleUrls: ["./management-cron.component.scss"],
})
export class ManagementCronComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Table
  tableMaxReg: number = 100;
  jobList: Job[];
  tableHighlightRow: TableHighlightRow[] = [
    { condition: "highlightNoExist", color: "red", title: "job-exist-no" },
    { condition: "highlightPaused", color: "yellow", title: "job-paused" },
  ];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "Triggers",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
    },
    {
      title: "group",
      data: "jobGroup",
      search: "jobGroup",
      sort: "jobGroup",
      visible: true,
    },
    {
      title: "name",
      data: "jobName",
      search: "jobName",
      sort: "jobName",
      visible: true,
    },
    {
      title: "job-created-deployment",
      data: "createdOnDeployment",
      search: "createdOnDeployment",
      sort: "createdOnDeployment",
      alter: {
        condition: "createdOnDeployment",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "job-exist",
      data: "existsJob",
      search: "existsJob",
      sort: "existsJob",
      alter: {
        condition: "existsJob",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "description",
      data: null,
      search: null,
      sort: null,
      extraInfo: true,
      visible: true,
    },
  ];

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CronManagementController: CronManagementControllerService,
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.updating = true;
    let jobList = [];
    this.CronManagementController.getJobs().subscribe((response) => {
      if (response["code"] == 0) {
        jobList = response["body"];
        jobList?.forEach((job: Job) => {
          job.extraInfoData = job.description;
          job.highlightNoExist = !job.existsJob;
          if (job.triggers) {
            job.triggers.forEach((trigger: TriggerInfo) => {
              trigger.paused = trigger.state == "PAUSED";
              trigger.modalInfo = true;
              trigger.modalInfoData = {
                title:
                  this.translate.instant("group") +
                  ": " +
                  trigger.triggerGroup +
                  " / " +
                  this.translate.instant("name") +
                  ": " +
                  trigger.triggerName,
                html:
                  `<h4><b>` +
                  this.translate.instant("job-data") +
                  `: </b></h4>
                    <p>` +
                  trigger.jobData +
                  `</p>`,
              };
              trigger.runDisabled = !job.existsJob;
            });

            // Tabla de triggers
            job.extraTableData = {
              rowNumbers: true,
              data: job.triggers,
              columns: [
                {
                  title: "action",
                  data: [
                    {
                      name: "execute",
                      tooltip: "cron-execute",
                      icon: "fas fa-level-down-alt run-icon",
                      visible: { attribute: null, rule: true },
                      disabled: "runDisabled",
                    },
                    {
                      name: "edit-cron-exp",
                      tooltip: "job-edit-cron",
                      icon: "fas fa-clock",
                      visible: { attribute: null, rule: true },
                      disabled: false,
                    },
                    {
                      name: "edit-mf",
                      tooltip: "job-edit-mf",
                      icon: "fas fa-edit",
                      visible: { attribute: null, rule: true },
                      disabled: false,
                    },
                    {
                      name: "pause",
                      tooltip: "job-pause",
                      icon: "fas fa-pause",
                      visible: { attribute: "paused", rule: false },
                      disabled: false,
                    },
                    {
                      name: "resume",
                      tooltip: "job-resume",
                      icon: "fas fa-play",
                      visible: { attribute: "paused", rule: true },
                      disabled: false,
                    },
                  ],
                  visible: true,
                },
                {
                  title: "Cron",
                  data: "cronExpression",
                  search: "cronExpression",
                  sort: "cronExpression",
                  visible: true,
                },
                {
                  title: "cron-next-date",
                  data: "nextTimestampParsed",
                  search: "nextTimestampParsed",
                  sort: "nextTimestamp",
                  date: true,
                  dateLocal: true,
                  visible: true,
                },
                {
                  title: "cron-previous-date",
                  data: "previousTimestampParsed",
                  search: "previousTimestampParsed",
                  sort: "previousTimestamp",
                  date: true,
                  dateLocal: true,
                  visible: true,
                },
                {
                  title: "cron-creation-date",
                  data: "startTimestampParsed",
                  search: "startTimestampParsed",
                  sort: "startTimestamp",
                  date: true,
                  dateLocal: true,
                  visible: true,
                },
                {
                  title: "state",
                  data: "state",
                  search: "state",
                  sort: "state",
                  visible: true,
                },
                {
                  title: "job-data",
                  data: "jobData",
                  search: "jobData",
                  sort: "jobData",
                  modalInfo: true,
                  visible: true,
                },
                {
                  title: "Misfire",
                  data: "misfire",
                  search: "misfire",
                  sort: "misfire",
                  visible: true,
                },
              ],
            };
          } else {
            job.highlightNoExist = true;
          }
          job.highlightPaused = job.triggers?.some(
            (trigger: TriggerInfo) => trigger.paused
          );
        });
      }
      this.jobList = jobList
        .sort((a, b) => Number(b.highlightNoExist) - Number(a.highlightNoExist))
        .sort((a, b) => Number(b.highlightPaused) - Number(a.highlightPaused));
      this.updating = false;
    });
  }

  // Acciones de la tabla de triggers
  extraTableActions(action: string, job: Job, trigger: TriggerInfo): void {
    switch (action) {
      case "execute":
        this.runJob(job, trigger);
        break;
      case "edit-cron-exp":
        this.MaterialDialogService.openDialog(ManagementCronDialogComponent, {
          action: "editCron",
          currentTrigger: trigger,
          updatedCronExp: trigger.cronExpression,
          cronExp: trigger.cronExpression.split(" "),
        });
        break;
      case "edit-mf":
        this.MaterialDialogService.openDialog(ManagementCronDialogComponent, {
          action: "editMisfire",
          currentTrigger: trigger,
          updatedMisfire: trigger.misfire,
        });
        break;
      case "pause":
        this.pauseJob(job, trigger);
        break;
      case "resume":
        this.resumeJob(job, trigger);
        break;
      default:
        break;
    }
  }

  // Ejecución inmediata de Cron
  runJob(job: Job, trigger: TriggerInfo): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("job-run-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CronManagementController.runJob({
          job: {
            group: job.jobGroup,
            name: job.jobName,
          },
          trigger: {
            group: trigger.triggerGroup,
            name: trigger.triggerName,
          },
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("cron-executed")
            );
          }
        });
      }
    });
  }

  // Pausa de job
  pauseJob(job: Job, trigger: TriggerInfo): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("job-pause-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CronManagementController.pauseJob({
          group: trigger.triggerGroup,
          name: trigger.triggerName,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("job-paused")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Reanudación de job
  resumeJob(job: Job, trigger: TriggerInfo): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("job-resume-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CronManagementController.resumeJob({
          group: trigger.triggerGroup,
          name: trigger.triggerName,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("job-resumed")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }
}
