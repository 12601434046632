<section class="content-header subscriber-content-header">
  <div class="container-fluid">
    <div class="panel panel-default subscriber-config-panel">
      <div class="panel-heading subscriber-cups-separator no-select">
        <div class="subscriber-cups-separator-title">
          <i class="fas fa-cog subscriber-cups-separator-icon"></i>
          <div class="subscriber-cups-title subscriber-cups-meters-title">
            {{ "configuration" | translate }}
          </div>
        </div>

        <div
          class="panel-back"
          title="{{ 'back' | translate }}"
          (click)="back()"
        >
          <i class="fas fa-chevron-left"></i>
        </div>
      </div>

      <div
        *ngIf="userData; else elseBlock"
        class="panel-body user-form-body subscriber-config-form"
      >
        <!-- Nombre de usuario -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "user" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userData.username"
              placeholder="{{ 'user' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Nombre -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "name" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userData.name"
              placeholder="{{ 'name' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Apellido -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "surname" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userData.surname"
              placeholder="{{ 'surname' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Teléfono -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "phone" | translate }}</mat-label>
            <input
              matInput
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [(ngModel)]="userData.phoneNumber"
              placeholder="{{ 'phone' | translate }}..."
              (change)="checkPhoneSMS()"
            />
          </mat-form-field>
        </div>

        <!-- Teléfono2 -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "phone" | translate }} 2</mat-label>
            <input
              matInput
              type="text"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [(ngModel)]="userData.phoneNumber2"
              placeholder="{{ 'phone' | translate }}..."
              (change)="checkPhoneSMS()"
            />
          </mat-form-field>
        </div>

        <!-- Email -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              [(ngModel)]="userData.email"
              placeholder="Email..."
              (change)="checkEmail()"
            />
          </mat-form-field>
        </div>

        <!-- Contraseña -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>
              {{
                userId == null
                  ? ("password" | translate)
                  : ("new-password" | translate)
              }}</mat-label
            >
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              [(ngModel)]="userData.password"
              name="password"
              autocomplete="new-password"
              [maxLength]="20"
              [minLength]="10"
              placeholder="{{
                userId == null
                  ? ('password' | translate) + '...'
                  : ('new-password' | translate) + '...'
              }}"
              [required]="userId == null ? 'true' : 'false'"
              title="{{
                userId != null
                  ? ('password-info' | translate)
                  : ('password-requeriment' | translate)
              }}"
              required
            />
          </mat-form-field>
          <!-- Aclaración de contraseña -->
          <div class="user-form-password-icons">
            <i
              class="fas fa-info-circle pointer"
              (click)="showPasswordRequeriments = !showPasswordRequeriments"
            ></i>
            <span
              class="user-form-password-requeriments"
              *ngIf="showPasswordRequeriments"
              >{{ "password-requeriment" | translate }}</span
            >
            <i
              class="fas fa-eye pointer"
              (click)="showPassword = !showPassword"
            ></i>
          </div>
        </div>

        <!-- Repetir contraseña -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>
              {{
                userId == null
                  ? ("password" | translate)
                  : ("password-repeat" | translate)
              }}</mat-label
            >
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              [(ngModel)]="repeatedPassword"
              name="password"
              autocomplete="password-repeat"
              [maxLength]="20"
              [minLength]="10"
              placeholder="{{
                userId == null
                  ? ('password' | translate) + '...'
                  : ('password-repeat' | translate) + '...'
              }}"
              [required]="userId == null ? 'true' : 'false'"
            />
          </mat-form-field>
        </div>

        <!-- Idioma -->
        <div class="user-form-attribute">
          <app-material-select
            [title]="'language' | translate"
            [bindValue]="'id'"
            [bindLabel]="'descripcion'"
            [selected]="languageInitialSelection"
            [data]="languagesList"
            (selectedOption)="userData.language = $event?.id"
            [required]="true"
          ></app-material-select>
        </div>

        <div class="user-form-control">
          <div class="user-form-buttons">
            <!-- Actualizar -->
            <button
              mat-button
              class="mat-button-icon-text"
              [disabled]="
                userData.username == null ||
                userData.name == null ||
                userData.surname == null ||
                userData.language == null ||
                password?.length > 0
                  ? 10 > password?.length
                  : null
              "
              (click)="saveUserData(); updateLanguage()"
            >
              <i class="fas fa-save"></i>
              {{ "save" | translate }}
            </button>
            <!-- Reiniciar -->
            <button
              mat-button
              class="mat-button-icon-text"
              (click)="reset($event)"
            >
              <i class="fas fa-sync-alt"></i>
              {{ "restart" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
