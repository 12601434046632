import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { HomeControllerService } from "../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { DeviceTypeService } from "../../../../services/shared/DeviceTypeService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../../interfaces/DeviceGlobalInterface.type";
import { TotalDevices } from "../NetworkStateInterface.type";
import { TableDataColumn } from "../../../../modules/table-module/TableInterface.type";

@Component({
  selector: "app-totals-summary",
  templateUrl: "./totals-summary.component.html",
  styleUrls: ["./totals-summary.component.scss"],
})
export class TotalsSummaryComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  clients: Client[];
  clientsSub: Subscription;
  totals: TotalDevices[];
  clientDropdownData: { id: number; value: string }[];
  selectedClient: number = -1;

  // Tabla
  exportFileName: string =
    this.translate.instant("total-devices") + " " + moment().format("ll");
  highlightRow: object[] = [
    { condition: "clientTotal", color: "green", title: "client-total" },
    { condition: "entityTotal", color: "blue", title: "entity-total" },
  ];

  rowNumbers: boolean = true;
  tableMaxReg: number = 5000;
  tableData: TotalDevices[] = [];
  columns: TableDataColumn[] = [
    {
      title: "client",
      data: "client",
      search: "client",
      sort: "client",
      visible: true,
    },
    {
      title: "entity",
      data: "entity",
      search: "entity",
      sort: "entity",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupation",
      search: "agrupation",
      sort: "agrupation",
      visible: true,
    },
    {
      title: "meters-in-network",
      data: "devices",
      search: "devices",
      sort: "devices",
      numerical: true,
      visible: true,
    },
    {
      title: "MBUS",
      data: "mbus",
      search: "mbus",
      sort: "mbus",
      numerical: true,
      visible: true,
    },
    {
      title: "Wavenis",
      data: "wavenis",
      search: "wavenis",
      sort: "wavenis",
      numerical: true,
      visible: true,
    },
    {
      title: "UNE",
      data: "une",
      search: "une",
      sort: "une",
      numerical: true,
      visible: true,
    },
    {
      title: "external",
      data: "external",
      search: "external",
      sort: "external",
      numerical: true,
      visible: true,
    },
    {
      title: "sensor",
      data: "sensors",
      search: "sensors",
      sort: "sensors",
      numerical: true,
      visible: true,
    },
    {
      title: "satelite",
      data: "satelites",
      search: "satelites",
      sort: "satelites",
      numerical: true,
      visible: true,
    },
    {
      title: "concentrator",
      data: "concentrators",
      search: "concentrators",
      sort: "concentrators",
      numerical: true,
      visible: true,
    },
    {
      title: "without-communication",
      data: "withoutCom",
      search: "withoutCom",
      sort: "withoutCom",
      numerical: true,
      visible: true,
    },
    {
      title: "no-communicates",
      data: "noCom",
      search: "noCom",
      sort: "noCom",
      numerical: true,
      visible: true,
    },
    {
      title: "no-assigned",
      data: "noAssign",
      search: "noAssign",
      sort: "noAssign",
      numerical: true,
      visible: true,
    },
    {
      title: "with-alarms",
      data: "withAlarms",
      search: "withAlarms",
      sort: "withAlarms",
      numerical: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private deviceMinimalParse: MapDeviceMinimalParseService,
    private DeviceTypeService: DeviceTypeService,
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.clients = this.SessionDataService.getCurrentClientList();
    this.clientsSub = this.SessionDataService.getClientList().subscribe(
      (clientList) => {
        this.clients = clientList;
        this.getClientDropdown();
      }
    );

    if (this.clients) {
      this.getClientDropdown();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientsSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del desplegable de clientes
  getClientDropdown(): void {
    this.clientDropdownData = this.clients.map((client, i) => {
      return { id: i, value: client.clientName };
    });
    this.clientDropdownData?.unshift({
      id: -1,
      value: this.translate.instant("global"),
    });
  }

  // Obtención del resumen de totales
  getTotalsSummary(): void {
    this.SessionDataService.sendLockSpinner(true);
    this.spinner.show("spinner-hard");
    this.tableData = null;
    this.totals = [];
    if (this.selectedClient >= 0) {
      this.getAgrupationTotals(this.selectedClient, 0, 0);
    } else {
      this.getAgrupationTotals(0, 0, 0);
    }
  }

  // Obtención de los totales de agrupación
  getAgrupationTotals(
    clientIndex: number,
    entityIndex: number,
    agrupationIndex: number
  ): void {
    if (this.clients[clientIndex]) {
      if (this.clients[clientIndex].entityList[entityIndex]) {
        if (
          this.clients[clientIndex].entityList[entityIndex].agrupations[
            agrupationIndex
          ]
        ) {
          this.getTotals(clientIndex, entityIndex, agrupationIndex);
        } else {
          this.getAgrupationTotals(clientIndex, ++entityIndex, 0);
        }
      } else if (this.selectedClient < 0) {
        this.getAgrupationTotals(++clientIndex, 0, 0);
      } else {
        this.getTotalsSum();
      }
    } else {
      this.getTotalsSum();
    }
  }

  // Obtención de totales de cliente/entidad
  getTotalsSum(): void {
    let tableData: TotalDevices[] = [];
    this.clients.forEach((client) => {
      let clientAgrupations = this.totals.filter(
        (total) => total.client == client.clientName
      );
      if (clientAgrupations.length > 0) {
        tableData.push(
          this.getRowTotal(client.clientName, null, null, clientAgrupations)
        );
      }

      client.entityList.forEach((entity) => {
        let entityAgrupations = this.totals.filter(
          (total) => total.entity == entity.entity
        );
        if (entityAgrupations.length > 0) {
          tableData.push(
            this.getRowTotal(
              client.clientName,
              entity.entity,
              null,
              entityAgrupations
            )
          );

          tableData = tableData.concat(
            entityAgrupations.sort((a, b) =>
              a.agrupation.localeCompare(b.agrupation)
            )
          );
        }
      });
    });

    if (this.selectedClient < 0) {
      let clientsTotals = tableData.filter((data) => data.clientTotal);
      tableData.unshift(this.getRowTotal(null, null, null, clientsTotals));
    }
    this.SessionDataService.sendLockSpinner(false);
    this.spinner.hide("spinner-hard");
    this.tableData = tableData;
  }

  // Obtención de fila de totales
  getRowTotal(
    client: string,
    entity: string,
    agrupation: string,
    totals: TotalDevices[]
  ): TotalDevices {
    let rowTotal: TotalDevices = new TotalDevices();
    rowTotal.client = !client
      ? this.translate.instant("total")
      : !entity
      ? this.translate.instant("total") + " " + client
      : client;
    rowTotal.entity =
      entity && !agrupation
        ? this.translate.instant("total") + " " + entity
        : entity;
    rowTotal.agrupation = agrupation;
    rowTotal.clientTotal = client && !entity;
    rowTotal.entityTotal = entity && !agrupation;
    totals.forEach((total) => {
      rowTotal.devices += total.devices;
      rowTotal.mbus += total.mbus;
      rowTotal.wavenis += total.wavenis;
      rowTotal.une += total.une;
      rowTotal.external += total.external;
      rowTotal.sensors += total.sensors;
      rowTotal.satelites += total.satelites;
      rowTotal.concentrators += total.concentrators;
      rowTotal.withoutCom += total.withoutCom;
      rowTotal.noCom += total.noCom;
      rowTotal.noAssign += total.noAssign;
      rowTotal.withAlarms += total.withAlarms;
    });
    return rowTotal;
  }

  // Obtención de totales por tipo
  getTotals(
    clientIndex: number,
    entityIndex: number,
    agrupationIndex: number
  ): void {
    if (
      !this.clients[clientIndex].entityList[entityIndex].agrupations[
        agrupationIndex
      ].showAllEntity
    ) {
      let total: TotalDevices = new TotalDevices();
      total.client = this.clients[clientIndex].clientName;
      total.entity = this.clients[clientIndex].entityList[entityIndex].entity;
      total.agrupation =
        this.clients[clientIndex].entityList[entityIndex].agrupations[
          agrupationIndex
        ].name;
      this.HomeController.getMarkers(
        this.clients[clientIndex].entityList[entityIndex].agrupations[
          agrupationIndex
        ].id
      ).subscribe((response) => {
        // Respuesta de mapa
        if (response["code"] == 0) {
          // Dispositivos
          let devices = this.deviceMinimalParse.parseDevices(
            response["body"]["contadores"]
          );
          if (devices?.length > 0) {
            devices.forEach((device: MapDevice) => {
              let deviceType = this.DeviceTypeService.getDeviceTypeByMask(
                device.tipo,
                device.metrologyType
              );
              // Contadores
              if (
                (device.metrologyType == METROLOGY_TYPE.WATER ||
                  device.metrologyType == METROLOGY_TYPE.GAS) &&
                device.unidadVentaGw
              ) {
                total.devices++;
              }
              // Contadores MBus
              if (
                (device.metrologyType == METROLOGY_TYPE.WATER ||
                  device.metrologyType == METROLOGY_TYPE.GAS) &&
                deviceType == "MBUS"
              ) {
                total.mbus++;
              }
              // Contadores Wavenis
              if (
                (device.metrologyType == METROLOGY_TYPE.WATER ||
                  device.metrologyType == METROLOGY_TYPE.GAS) &&
                deviceType == "WAVENIS"
              ) {
                total.wavenis++;
              }
              // Contadores UNE
              if (deviceType == "UNE") {
                total.une++;
              }
              // Contadores Externos
              if (
                deviceType == "EK280" ||
                deviceType == "PLUM" ||
                deviceType == "EXTERNO" ||
                deviceType == "ERM"
              ) {
                total.external++;
              }
              // Sensores
              if (
                (device.metrologyType == METROLOGY_TYPE.SENSOR ||
                  device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR) &&
                device.unidadVentaGw
              ) {
                total.sensors++;
              }
              // Satélites
              if (
                device.metrologyType == METROLOGY_TYPE.SATELITE &&
                device.unidadVentaGw
              ) {
                total.satelites++;
              }
              // Concentradores
              if (deviceType == "LW_UNE_CON" && device.unidadVentaGw) {
                total.concentrators++;
              }
              // Sin comunicación
              if (deviceType == "NO_LORA_NO_COM") {
                total.withoutCom++;
              }
              // No comunican
              if (
                (deviceType == "LW" ||
                  deviceType == "LW_MBUS_CON" ||
                  deviceType == "LW_UNE_CON" ||
                  deviceType == "LW_MBUS") &&
                !device.comunica &&
                device.unidadVentaGw
              ) {
                total.noCom++;
              }
              // No asignados
              if (
                (deviceType == "LW" ||
                  deviceType == "LW_MBUS_CON" ||
                  deviceType == "LW_UNE_CON" ||
                  deviceType == "LW_MBUS") &&
                !device.unidadVentaGw
              ) {
                total.noAssign++;
              }
              // Con alarmas
              if (device.alarm) {
                total.withAlarms++;
              }
            });
          }
        }
        this.totals.push(total);
        this.getAgrupationTotals(clientIndex, entityIndex, ++agrupationIndex);
      });
    } else {
      this.getAgrupationTotals(clientIndex, entityIndex, ++agrupationIndex);
    }
  }
}
