import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
// Moment
import * as moment from "moment";
// Servicios propios
import { DatePickerConfigService } from "../../../services/shared/DatePickerConfigService.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-material-date-selector",
  templateUrl: "./material-date-selector.component.html",
  styleUrls: ["./material-date-selector.component.scss"],
})
export class MaterialDateSelectorComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  componentInitiated: boolean = false;
  @Input() dateRangeLabel: string;
  @Input() singleDatePicker: boolean;
  @Input() error: boolean;
  @Input() disabled: boolean;
  @Input() hint: string;
  @Input() initialDateNull: boolean;
  @Input() maxDate: moment.Moment;
  @Input()
  get maxInterval(): number {
    return this._maxInterval;
  }
  set maxInterval(maxInterval: number) {
    this._maxInterval = maxInterval;
    this.checkInterval();
  }
  _maxInterval: number;
  @Input()
  get minInterval(): number {
    return this._minInterval;
  }
  set minInterval(minInterval: number) {
    this._minInterval = minInterval;
    this.checkInterval();
  }
  _minInterval: number;
  defaultDateRange = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: moment().endOf("day"),
  };
  @Input() dateRange: { startDate: moment.Moment; endDate: moment.Moment };
  @ViewChild("dateRangeSelector") dateRangeSelector: ElementRef;
  daterangePickerLang: any;
  daterangePickerRanges: any;
  dateRangeSelected: any;
  @Output() dateRangeUpdated = new EventEmitter<any>();
  @ViewChild("dateMenuTrigger") dateMenuTrigger: MatMenuTrigger;
  renewdSession: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private clipboard: Clipboard,
    private DatePickerConfigService: DatePickerConfigService,
    private SessionDataService: SessionDataService
  ) {
    this.setDefaultOptions();
  }

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Inicialización de fecha inicial
    this.dateRangeSelected = this.dateRange
      ? { ...this.dateRange }
      : this.initialDateNull
      ? null
      : { ...this.defaultDateRange };

    // Comprobación de intervalo de fechas
    this.checkInterval();

    // Actualización de selectores por defecto al renovar sesión
    this.renewdSession =
      this.SessionDataService.getSessionRenewedFlag().subscribe(() =>
        this.setDefaultOptions()
      );

    setTimeout(() => (this.componentInitiated = true), 0);
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.renewdSession.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de opciones por defecto
  setDefaultOptions(): void {
    let datePickerData =
      this.DatePickerConfigService.setDateRangePickerOptions();
    this.daterangePickerLang = datePickerData.daterangePickerLang;
    this.daterangePickerRanges = datePickerData.daterangePickerRanges;
  }

  // Chequeo de intervalo de fechas
  checkInterval(): void {
    let interval = Math.round(
      (this.dateRangeSelected?.endDate?.valueOf() -
        this.dateRangeSelected?.startDate?.valueOf()) /
        (24 * 3600000)
    );
    if (
      (this.initialDateNull && !this.componentInitiated) ||
      ((!this._maxInterval ||
        (this._maxInterval && interval <= this._maxInterval)) &&
        (!this._minInterval ||
          (this._minInterval && interval >= this._minInterval)))
    ) {
      this.error = false;
      this.dateRangeUpdated.emit(this.dateRangeSelected);
    } else {
      this.error = true;
      this.dateRangeUpdated.emit({ startDate: null, endDate: null });
    }
  }

  // Meú de fecha
  openDateMenu(e: MouseEvent): void {
    e.preventDefault();
    this.dateMenuTrigger?.openMenu();
  }

  // Copiar fecha
  copyDate(): void {
    this.clipboard.copy(JSON.stringify(this.dateRangeSelected));
  }

  // Pegar fecha
  pasteDate(): void {
    navigator.clipboard.readText().then((date) => {
      let dateParsed = JSON.parse(date);
      if (dateParsed.startDate && dateParsed.endDate) {
        this.dateRangeSelected = {
          startDate: moment(dateParsed.startDate),
          endDate: moment(dateParsed.endDate),
        };
        this.checkInterval();
      }
    });
  }

  // Actualización de rango en selector
  updateSelectorDateRange(dateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): void {
    this.dateRangeSelected = dateRange;
  }
}
