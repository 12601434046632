<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "cron-manager" | translate }}</b>
        </h5>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [title]="updateTitle"
          [disabled]="updating"
          [updating]="updating"
          (action)="getData()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'cron'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [highlightRow]="tableHighlightRow"
          [extraTableNoCollapse]="true"
          (extraTableAction)="
            extraTableActions(
              $event.action.name,
              $event.parentElement,
              $event.childElement
            )
          "
          [specialFilter]="true"
          [data]="jobList"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
