<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "management-network-simulation-result" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Tabla -->
    <app-table-controller
      *ngIf="tableData"
      [tableId]="'networkOptimizationSimulation'"
      [columns]="columns"
      [rowNumbers]="true"
      [maxReg]="100"
      [specialFilter]="true"
      [highlightRow]="tableHighlightRow"
      [legendAlwaysActive]="true"
      [data]="tableData"
      (getExtraTableDataFlag)="
        $event.extraTableData.data
          ? null
          : getExtraTableData($event, simulationResult)
      "
    >
    </app-table-controller>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
