// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment";
// Servicios propios
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { MeterService } from "../../devices/meter/MeterService.service";
import { SuspicionsService } from "../../../../services/shared/SuspicionsService.service";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import { ALARM_STATES, AlarmData } from "../AlarmInterface.type";
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
// Componentes
import { AlarmEditDialogComponent } from "../alarm-edit-dialog/alarm-edit-dialog.component";

@Component({
  selector: "app-alarm",
  templateUrl: "./alarms-list.component.html",
  styleUrls: ["./alarms-list.component.scss"],
})
export class AlarmsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;

  // Tabla
  dateRange: string = history.state.data;
  tableData: AlarmData[];
  tableSelectedData: AlarmData[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  groupBy: object = { groupAttribute: "contador", groupName: "nroSerie" };
  claveMaestraKeyVisibility: boolean = null;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment };
  exportFileName: string =
    this.translate.instant("alarms-export") +
    " " +
    this.DateParserService.getDate();
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "edit-alarms",
      icon: "fas fa-edit",
      selectionRequired: true,
    },
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
  ];
  quickFiltersExclusion: boolean[] = [true];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "active",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: false },
        active: this.dateRange == "month",
      },
      {
        name: "inactive",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: "disabled",
        columnSearch: "disabled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "enabled",
        columnSearch: "enabled",
        condition: { type: "boolean", rule: true },
        active: this.dateRange != "full",
      },
    ],
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];

  // Mapa
  mapType: string = "alarmsMeterList";
  mapHeight: number = window.innerHeight - 520;
  devices: AlarmData[];
  gateways: [] = [];
  selectedDevices: AlarmData[];
  tableFilteredByMap: boolean = false;

  // Select
  alarmSelectOptions: MaterialSelectOption[] = [
    { value: true, name: this.translate.instant("alarm-all") },
    { value: false, name: this.translate.instant("alarm-active-enabled") },
  ];
  alarmSelect: boolean;

  dialog: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MaterialDialogService: MaterialDialogService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private requestQueue: RequestQueueService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entityCupsConf = this.SessionDataService.getEntityCupsConf().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getAlarms(this.from, this.to);
        }
      }
    );

    // Inicialización
    if (this.currentAgrupation && this.currentEntityCupsConf) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConf.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getDate();
    this.getAlarms(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de alarmas
  getAlarms(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let url = this.alarmSelect
      ? this.AlarmController.getAlarmsFromTo(
          this.currentAgrupation.id,
          from,
          to
        )
      : this.AlarmController.getActiveEnabledAlarmsFromTo(
          this.currentAgrupation.id,
          from,
          to
        );
    url.subscribe((response) => {
      if (response["code"] == 0) {
        let claveMaestraKeyArray: string[] = [];
        let alarmList = response["body"];
        alarmList.forEach((alarm: AlarmData) => {
          alarm.metrologyType = alarm.metrology_type;
          // Claves maestras
          claveMaestraKeyArray.push(alarm.claveMaestraKey);
          // Código de alarma
          alarm.code != null
            ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
            : "";
          alarm.code != null
            ? (alarm.description = this.translate.instant(
                "AlertDescription" + alarm.code
              ))
            : "";
          if (
            alarm.description == "" ||
            alarm.description == "AlertDescription" + alarm.code
          ) {
            alarm.description = this.translate.instant("no-details");
          }
          alarm.enabled = !alarm.disabled;
          // Estado de la alarma
          alarm.stateParsed = ALARM_STATES[alarm.state]
            ? this.translate.instant(ALARM_STATES[alarm.state])
            : null;
          // Información extra
          alarm.modalInfo = true;
          alarm.modalInfoData = {
            title: this.translate.instant("alarm-info"),
            html:
              `<h4><b> (` +
              this.translate.instant("code") +
              " " +
              alarm.code +
              ") " +
              alarm.name +
              `: </b></h4>
                   <pre class="pre-without-format">` +
              alarm.description +
              `</pre>`,
          };
          alarm.loadGraph =
            alarm.metrologyType == METROLOGY_TYPE.WATER ||
            alarm.metrologyType == METROLOGY_TYPE.GAS ||
            alarm.metrologyType == METROLOGY_TYPE.SATELITE;
        });
        // Visibilidad de la columna de clave maestra
        if (claveMaestraKeyArray.length > 0) {
          if (claveMaestraKeyArray.reduce((a, b) => a || b)) {
            this.claveMaestraKeyVisibility = true;
          } else {
            this.claveMaestraKeyVisibility = null;
          }
        }
        this.tableData =
          this.SessionDataService.getCurrentProfile() == "ARSON" &&
          this.SessionDataService.getCurrentSuspicionActive()
            ? alarmList
            : this.SuspicionsService.filterSuspicions(alarmList);
        this.setColumns(this.tableData.some((alarm) => alarm.fabricante == 23));
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(bmeter: boolean): void {
    if (bmeter) {
      this.tableGlobalActions.unshift({
        title: "clean-alarms",
        icon: "fas fa-trash",
        selectionRequired: true,
      });
    }
    let cups: EntityDefinition = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    );

    this.columns = [
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "to-pending",
            tooltip: "to-pending",
            icon: "fas fa-binoculars",
            visible: { attribute: "disabled", rule: false },
            disabled: false,
          },
          {
            name: "disable",
            tooltip: "disable",
            icon: "fas fa-eraser disable",
            visible: { attribute: "disabled", rule: false },
            disabled: false,
            warning: true,
          },
          {
            name: "enable",
            tooltip: "enable",
            icon: "fas fa-plus enable",
            visible: { attribute: "disabled", rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: cups?.name ? cups?.name : "CUPS",
        data: "claveMaestraKey",
        search: "claveMaestraKey",
        sort: "claveMaestraKey",
        visible: cups && this.claveMaestraKeyVisibility,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "enabled",
        data: "enabled",
        search: "enabled",
        sort: "enabled",
        alter: {
          condition: "enabled",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
      },
      {
        title: "last-consumption-min",
        data: "lastConsumptionMinParsed",
        search: "lastConsumptionMinParsed",
        sort: "lastConsumptionMin",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-max",
        data: "lastConsumptionMaxParsed",
        search: "lastConsumptionMaxParsed",
        sort: "lastConsumptionMax",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-total",
        data: "lastConsumptionTotalParsed",
        search: "lastConsumptionTotalParsed",
        sort: "lastConsumptionTotal",
        numerical: true,
        visible: true,
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "state",
        visible: true,
      },
      {
        title: "start-date",
        data: "initDateParsed",
        search: "initDateParsed",
        sort: "initDate",
        date: true,
        visible: true,
      },
      {
        title: "end-date",
        data: "finalDateParsed",
        search: "finalDateParsed",
        sort: "finalDate",
        date: true,
        visible: true,
      },
      {
        title: "alarms",
        data: "name",
        search: "name",
        sort: "name",
        modalInfo: true,
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
    ];
  }

  // Obtención del rango de fechas inicial
  getDate(): void {
    switch (this.dateRange) {
      case "full":
        this.alarmSelect = false;
        this.dataInitialDate = this.DateParserService.getLastDays();
        break;
      case "month":
        this.alarmSelect = true;
        this.dataInitialDate = this.DateParserService.getLastDays("1", "month");
        break;
      default:
        this.alarmSelect = true;
        this.dataInitialDate = this.DateParserService.getLastDays("5");
        break;
    }
  }

  // Acciones de la tabla
  tableActions(action: string, alarm: AlarmData): void {
    switch (action) {
      case "show-detail":
        this.DeviceRouteSelectorService.getDeviceRoute(
          alarm.metrology_type,
          alarm.contador
        );
        break;
      case "edit":
        this.editAlarm(alarm);
        break;
      case "enable":
        this.enableAlarm(alarm);
        break;
      case "disable":
        this.disableAlarm(alarm);
        break;
      case "load-graph":
        this.MeterService.getAlarmGraph(
          alarm,
          alarm.contador,
          alarm.metrology_type
        );
        break;
      case "to-pending":
        this.MeterService.toCheck({
          id: alarm.contador,
          nroSerie: alarm.nroSerie,
        });
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "edit-alarms":
        this.MaterialDialogService.openDialog(
          AlarmEditDialogComponent,
          this.tableSelectedData
        );
        break;
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "clean-alarms":
        this.requestQueue.setTask(
          "cleanAlarms",
          this.tableSelectedData
            .filter((alarm) => alarm.fabricante == 23)
            .map((alarm) => {
              return {
                id: alarm.contador,
                nroSerie: alarm.nroSerie,
                metrologyType: alarm.metrologyType,
              };
            })
        );
        break;
      default:
        break;
    }
  }

  // Habilitar alarma
  enableAlarm(alarm: AlarmData): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("alarm-enabled-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.enableAlarms(alarm.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("alarm-enabled")
            );
            this.getAlarms(this.from, this.to);
          }
        });
      }
    });
  }

  // Deshabilitar alarma
  disableAlarm(alarm: AlarmData): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("alarm-disabled-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.disableAlarms(alarm.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("alarm-disabled")
            );
            this.getAlarms(this.from, this.to);
          }
        });
      }
    });
  }

  // Editar alarma
  editAlarm(alarm: AlarmData): void {
    this.MaterialDialogService.openDialog(AlarmEditDialogComponent, [alarm]);
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((device: AlarmData) => {
          return device.contador;
        }),
        to: this.to,
      },
    });
  }

  // Actualización de los datos seleccionados en la tabla
  selectedDataUpdate(data: AlarmData[]): void {
    this.tableSelectedData = [...data];
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    let devices: AlarmData[] = [];
    this.tableSelectedData.forEach((device: AlarmData) => {
      let pushedDeviceIndex: number = devices?.findIndex(
        (pushedDevice: AlarmData) => pushedDevice.nroSerie == device.nroSerie
      );
      if (pushedDeviceIndex >= 0) {
        if (devices[pushedDeviceIndex].initDate < device.initDate) {
          devices.splice(pushedDeviceIndex, 1);
          devices.push(device);
        }
      } else {
        devices.push(device);
      }
    });
    this.devices = devices;
    setTimeout(() => this.viewportScroller.scrollToAnchor("map-panel"), 0);
  }

  // Ir a revisión
  goToPending(): void {
    this.router.navigate(["dispositivos/listado/revision"]);
  }
}
