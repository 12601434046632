// @angular
import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpRequest,
  HttpClient,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, OperatorFunction, throwError, Subject } from "rxjs";
import { catchError, finalize, timeout, tap } from "rxjs/operators";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { BrowserStorageLocalService } from "../BrowserStorageServices/BrowserStorageLocalService.service";
import { ToastService } from "../ToastService.service";
import { SessionDataService } from "../SessionDataService.service";
// Interfaces
import { SessionData } from "../../../interfaces/SessionGlobalInterface.type";
// Variable de entorno
import { ENVIRONMENT } from "../../../../environments/environment";
// Variables
import {
  ROUTES_WITH_MEDIUM_TIMEOUT,
  ROUTES_WITH_LARGE_TIMEOUT,
  ROUTES_WITHOUT_TIMEOUT,
  ROUTES_OUT_OF_SPINNER,
  LARGE_TIMEOUT,
  MEDIUM_TIMEOUT,
  STANDARD_TIMEOUT,
  WAIT_TIMEOUT,
  ROUTES_WAIT_QUESTION,
} from "./TIMEOUTS";

@Injectable({
  providedIn: "root",
})
export class HttpRequestService {
  /***************************************************************************/
  // Variables
  /***************************************************************************/

  spinnerTimer: ReturnType<typeof setTimeout>;
  requestCancel = new Subject<boolean>();

  /***************************************************************************/
  // Constructor
  /***************************************************************************/

  constructor(
    private http: HttpClient,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // Peticiones
  /***************************************************************************/

  // GET
  get(url: string, headers?: any): Observable<any> {
    return this.http.get(ENVIRONMENT.API_ENDPOINT + url, headers);
  }

  // POST
  post(url: string, data: any, headers?: any): Observable<ArrayBuffer> {
    return this.http.post(ENVIRONMENT.API_ENDPOINT + url, data, headers);
  }

  // Cancelar petición
  sendRequestCancel(): void {
    this.requestCancel.next(null);
  }

  getRequestCancel(): Observable<any> {
    return this.requestCancel.asObservable();
  }

  /***************************************************************************/
  // Funciones
  /***************************************************************************/

  // Obtención del token guardado en sessionStorage
  getToken() {
    let token: string;
    let sessionData: SessionData =
      this.BrowserStorageLocalService.getJsonValue("session");
    if (sessionData) {
      token = sessionData.token;
    }
    return token;
  }

  // Filtrado de las peticiones
  urlFilter(requestUrl: string, routes: any): boolean {
    return routes?.some((route: string) => requestUrl.includes(route));
  }

  // Timeout
  timeoutWhen<T>(cond: boolean, value: number): OperatorFunction<T, T> {
    return function (source: Observable<T>): Observable<T> {
      return cond ? source.pipe(timeout(value)) : source;
    };
  }

  // Preguntar antes de seguir con timeout
  askBeforeTimeout<T>(requestTimeout: number): NodeJS.Timeout {
    if (requestTimeout > STANDARD_TIMEOUT) {
      return setTimeout(() => {
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("http-timeout-question"),
          null,
          this.translate.instant("cancel"),
          this.translate.instant("http-wait")
        ).then((userConfirmation: boolean) => {
          if (!userConfirmation) {
            this.sendRequestCancel();
          }
        });
      }, WAIT_TIMEOUT[requestTimeout]);
    }
    return;
  }

  // Cálculo del timeout para las rutas que lo necesiten
  setUrlTimeout(url: string): number {
    if (this.urlFilter(url, ROUTES_WITHOUT_TIMEOUT)) {
      return null;
    } else if (this.urlFilter(url, ROUTES_WITH_LARGE_TIMEOUT)) {
      return LARGE_TIMEOUT;
    } else {
      return this.urlFilter(url, ROUTES_WITH_MEDIUM_TIMEOUT)
        ? MEDIUM_TIMEOUT
        : STANDARD_TIMEOUT;
    }
  }

  // Mostrar spinner
  showSpinner(spinnerActive: boolean): void {
    if (spinnerActive) {
      this.spinner.show("spinner-soft");
      if (this.spinnerTimer) {
        clearTimeout(this.spinnerTimer);
      }
      this.spinnerTimer = setTimeout(() => {
        this.spinner.hide("spinner-soft");
        this.spinner.show("spinner-hard");
      }, 1000);
    }
  }

  // Ocultar spinner
  hideSpinner(): void {
    if (!this.SessionDataService.getCurrentLockSpinner()) {
      this.spinner.hide("spinner-soft");
      this.spinner.hide("spinner-hard");
    }
  }

  // Errores
  httpErrorManagement(event: any): void {
    if (event.type === HttpEventType.Response) {
      if (typeof event.body?.code === "number" && event.body.code != 0) {
        this.getErrorText(event.body);
      }
    }
  }

  // Texto de error
  getErrorText(error: any) {
    switch (error.code) {
      // Error http
      case -1:
        error?.name
          ? this.ToastService.fireToastWithConfirmation(
              "error",
              this.translate.instant("error-text") +
                (error?.name
                  ? ": " +
                    error?.name +
                    (error?.error ? " - " + error?.error?.error : "")
                  : "")
            )
          : this.ToastService.fireToastWithConfirmation(
              "error",
              this.translate.instant("unknown-error")
            );
        break;
      // Error interno
      case 1:
        if (
          this.SessionDataService.getCurrentProfile() == "ARSON" &&
          error.message
        ) {
          this.ToastService.fireToastWithConfirmation(
            "error",
            "Error (" +
              error.code +
              "): " +
              this.translate.instant("httpError" + error.code) +
              ". " +
              this.translate.instant(error.message)
          );
        } else {
          this.ToastService.fireToastWithConfirmation(
            "error",
            "Error (" +
              error.code +
              "): " +
              this.translate.instant("httpError" + error.code)
          );
        }
        break;
      // Error de acceso denegado
      case 1000:
        this.router.navigate(["/acceso-denegado"]);
        break;
      // Error de sesión caducada
      case 18:
        this.SessionDataService.sendSessionExpiredFlag();
        break;
      // Error de CUPS ya existente controlado en componente
      case 1028:
        break;
      // Error de nombre de asociación ya existente controlado en componente
      case 2302:
        break;
      // Resto de errores
      default:
        this.ToastService.fireToastWithConfirmation(
          "error",
          "Error (" +
            error.code +
            "): " +
            this.translate.instant("httpError" + error.code)
        );
        break;
    }
  }
  /***************************************************************************/
  // Intercepción de petición
  /***************************************************************************/

  // Intercepción de llamada HTTP
  request(
    req: HttpRequest<any>,
    next: HttpHandler,
    modifiedRequest: HttpRequest<any>,
    requestTimeout: number,
    spinnerActive: boolean
  ): Observable<HttpEvent<any>> {
    // Activación de spinner de carga
    this.showSpinner(spinnerActive);
    // Pregunta para espera de timeout
    let waitTimer = this.urlFilter(req.url, ROUTES_WAIT_QUESTION)
      ? this.askBeforeTimeout(requestTimeout)
      : null;
    return (
      next
        .handle(
          req.url.includes("openweathermap") || req.url.includes("mirutaaguas")
            ? req
            : modifiedRequest
        )
        // Timeout variable dependiendo de la ruta
        .pipe(this.timeoutWhen(requestTimeout ? true : false, requestTimeout))
        // Comprobación de los errores en la respuesta del servidor
        .pipe(
          tap((event) => {
            this.httpErrorManagement(event);
          })
        )
        // Limpieza de timeout y spinner
        .pipe(
          finalize(() => {
            if (waitTimer) {
              clearTimeout(waitTimer);
            }
            if (
              spinnerActive &&
              !this.urlFilter(req.url, ROUTES_OUT_OF_SPINNER)
            ) {
              this.hideSpinner();
              clearTimeout(this.spinnerTimer);
            }
          }),
          // Control de errores en la petición http
          catchError((error: HttpErrorResponse) => {
            if (
              ENVIRONMENT.production &&
              (error.status == 0 || error.status == 503)
            ) {
              this.SessionDataService.sendMaintenance(true);
            } else {
              // Ocultar spinner de carga
              if (
                spinnerActive &&
                !this.urlFilter(req.url, ROUTES_OUT_OF_SPINNER)
              ) {
                this.hideSpinner();
                clearTimeout(this.spinnerTimer);
              }
              // Error
              let httpError = JSON.parse(JSON.stringify(error));
              httpError.code = -1;
              this.getErrorText(httpError);
            }
            return throwError(() => error);
          })
        )
    );
  }
}
