<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "file" | translate }}: {{ data.fileToShowName }}
    </h5>
  </div>
  <div class="dialog-body">
    <div class="output-files-aqualia-dialog">
      <ng-container *ngIf="data.fileToShow != null">
        <pre>{{ data.fileToShow | json }}</pre>
      </ng-container>
      <ng-container *ngIf="data.fileToShow == null">
        {{ "file-not-found" | translate }}
      </ng-container>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
