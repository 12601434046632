import { Injectable } from "@angular/core";
import * as data from "../../../assets/profiles/profiles.json";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  profiles: any = (data as any).default;

  constructor() {}

  getProfile(profile: string): string {
    return this.profiles[profile];
  }

  getProfileList(): { id: number; name: string }[] {
    let profiles = [];
    for (let profile in this.profiles) {
      profiles.push({ id: profile, name: this.profiles[profile] });
    }
    return profiles;
  }
}
