// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class SensoryControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Fugas
  /***************************************************************************/

  // Datos de ruido, frecuencia, amplitud y temperatura de sensor
  getLeakDetectorData(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/leakDetector/device/" +
        deviceId +
        "/measures/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Obtención de lecturas de ruido acústico
  getAcousticReadings(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/acoustic/meter/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Último dato de ruido, frecuencia, amplitud y temperatura de sensor
  getLastAcousticReadings(
    agrupationId: number,
    data: { meters: number[]; timestamp: number }
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/leakDetector/sensor/last-value/" + agrupationId,
      data
    );
  }

  // Obtención de la última lectura de ruido acústico
  getLastLeakDetectorData(
    agrupationId: number,
    data: { meters: number[]; timestamp: number }
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/acoustic/meters/last-value/" + agrupationId,
      data
    );
  }

  // Datos de ruido, frecuencia, amplitud y temperatura de sensor
  getAcousticReadingsByGroup(
    deviceList: number[],
    agrupationId: number,
    from: string,
    to: string
  ): Observable<object> {
    return this.HttpRequestService.post("/leakDetector/measures/by-sensors", {
      deviceList: deviceList,
      agrupation: agrupationId,
      from: from,
      to: to,
    });
  }

  // Obtención de lecturas de ruido acústico
  getLeakDetectorDataByGroup(
    deviceList: number[],
    agrupationId: number,
    from: string,
    to: string
  ): Observable<object> {
    return this.HttpRequestService.post("/acoustic/measures/by-meters", {
      deviceList: deviceList,
      agrupation: agrupationId,
      from: from,
      to: to,
    });
  }
}
