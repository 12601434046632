// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Moment
import moment_timezone from "moment-timezone";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { OutputFilesControllerService } from "../../../../../services/server/OutputFilesController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { FileCron, FileTemplate, Ftp } from "../../OutputFileInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { WEEK } from "../../../devices/SateliteInterface.type";
import { MaterialSelectOption } from "../../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-output-files-template-cron",
  templateUrl: "./output-files-template-cron.component.html",
  styleUrls: ["./output-files-template-cron.component.scss"],
})
export class OutputFilesTemplateCronComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Fichero
  cron: FileCron;
  selectedAgrupations: Agrupation[];
  templateList: FileTemplate[];
  frecuency = {
    1: this.translate.instant("cron-daily"),
    2: this.translate.instant("cron-weekly"),
    3: this.translate.instant("cron-monthly"),
  };
  @ViewChild("cronDaily") cronDaily: ElementRef;
  @ViewChild("cronWeekly") cronWeekly: ElementRef;
  @ViewChild("cronMonthly") cronMonthly: ElementRef;
  week = WEEK;
  agrupationList: Agrupation[];
  ftpList: Ftp[];

  // Zona horaria
  timezoneOptions: MaterialSelectOption[];
  tzNames: string[];
  initialTimezone: string;
  cronTimezone: string;
  cronDaysGap: number = 0;

  // API REST
  apiRestOptions = [{ id: "AWA" }, { id: "GEISER" }];

  // Conectores externos
  externalConectors = [{ id: "KAFKA" }, { id: "FIWARE" }];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private router: Router,
    private SessionDataService: SessionDataService,
    private OutputFilesController: OutputFilesControllerService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.router.navigate(["/ficheros/plantillas/exportacion"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
      }
    );

    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.tzNames = moment_timezone.tz.names();
    this.timezoneOptions = this.tzNames?.map((timezone: string) => {
      return { value: timezone, name: timezone };
    });
    this.cronTimezone = this.currentAgrupation.timezone;

    this.agrupationList = this.currentEntity.agrupations.filter(
      (agrupation) => !agrupation.showAllEntity
    );

    if (history.state.data) {
      this.cron = history.state.data;
      this.cron.horaLocal = this.getCronLocalHour(this.cron.hora);
    } else {
      this.cron = new FileCron();
    }
    this.parseCronFrecuency();

    this.OutputFilesController.getTemplateList(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.templateList = response["body"];
        }
      }
    );

    this.OutputFilesController.getFtpList(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let ftpList: Ftp[] = response["body"];
          ftpList?.forEach((ftp) => {
            ftp.ftpParsed =
              "URL: " +
              ftp.url +
              ";  " +
              this.translate.instant("port") +
              ": " +
              ftp.port +
              ";  " +
              this.translate.instant("user") +
              ": " +
              ftp.user;
          });
          this.ftpList = ftpList;
        }
      }
    );
  }

  // Descarga de fichero
  saveCron(): void {
    let data = {
      id: this.cron.id,
      agrupations: this.selectedAgrupations.map((agrupation) => agrupation.id),
      name: this.cron.name,
      frecuencia: this.cron.frecuencia,
      hora: this.getCronUTCHour(),
      detalleFrecuencia: this.getFrecuencyDetail(),
      template: this.cron.template,
      emails: this.cron.emails,
      ftpConfig: this.cron.ftpConfig,
    };

    this.OutputFilesController.saveCron(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.router.navigate(["/ficheros/plantillas/exportacion"]);
      }
    });
  }

  // Obtención del detalle de frecuencia
  getFrecuencyDetail(): string {
    switch (this.cron.frecuencia) {
      case 1:
        return this.cron.dayInterval;
      case 2:
        return this.getCronDays(this.cron.weekDays, 7);
      case 3:
        return this.getCronDays(this.cron.monthDaysArray, 28);
      default:
        break;
    }
  }

  // Focus en inputs de frecuencia al seleccionar
  inputFocus(): void {
    setTimeout(() => {
      switch (this.cron.frecuencia) {
        case 1:
          this.cronDaily.nativeElement.focus();
          break;
        case 2:
          this.cronWeekly.nativeElement.focus();
          break;
        case 3:
          this.cronMonthly.nativeElement.focus();
          break;
        default:
          break;
      }
    }, 0);
  }

  // Parseo de días de la semana de cron
  parseCronFrecuency(): void {
    this.cron.weekDays = [false, false, false, false, false, false, false];
    this.cron.monthDaysArray = [].constructor(28);

    switch (this.cron.frecuencia) {
      case 1:
        this.cron.dayInterval = this.cron.detalleFrecuencia;
        break;
      case 2:
        this.cron.detalleFrecuencia?.split(",")?.forEach((day) => {
          let index = parseInt(day) - 1 + this.cronDaysGap;
          this.cron.weekDays[index > 6 ? 0 : index < 0 ? 6 : index] = true;
        });
        break;
      case 3:
        this.cron.detalleFrecuencia.split(",").forEach((day) => {
          let index = parseInt(day) - 1 + this.cronDaysGap;
          this.cron.monthDaysArray[index > 27 ? 0 : index < 0 ? 27 : index] =
            true;
        });
        break;
      default:
        break;
    }
  }

  // Parseo de días de la semana de cron
  getCronDays(daysArray: boolean[], maxIndex: number): string {
    let days: number[] = [];
    daysArray.forEach((day, i) => {
      let index = i + 1 + this.cronDaysGap;
      if (day) {
        days.push(index > maxIndex ? 1 : index <= 0 ? maxIndex : index);
      }
    });
    return days.join(",");
  }

  // Obtención de la hora UTC acorde a la zona horaria
  getCronUTCHour(): string {
    let offset = moment_timezone().tz(this.cronTimezone).utcOffset() / 60;
    let hour = parseInt(this.cron.horaLocal.split(":")[0]);
    this.cronDaysGap = hour - offset > 23 ? 1 : hour - offset < 0 ? -1 : 0;
    hour =
      hour - offset > 23
        ? hour - offset - 24
        : hour - offset < 0
        ? hour - offset + 24
        : hour - offset;
    return (
      (hour < 10 ? "0" + hour : hour) + ":" + this.cron.horaLocal.split(":")[1]
    );
  }

  // Obtención de la hora local acorde a la UTC
  getCronLocalHour(cronHour: string): string {
    let offset =
      moment_timezone().tz(this.currentAgrupation.timezone).utcOffset() / 60;
    let hour = parseInt(cronHour.split(":")[0]);
    this.cronDaysGap = hour + offset > 23 ? 1 : hour + offset < 0 ? -1 : 0;
    hour =
      hour + offset > 23
        ? hour + offset - 24
        : hour + offset < 0
        ? hour + offset + 24
        : hour + offset;
    return (hour < 10 ? "0" + hour : hour) + ":" + cronHour.split(":")[1];
  }
}
