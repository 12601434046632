import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TrelloApiControllerService {
  APIKey = "5759ec62a71ebc5a7140affb93f4ff72";
  APIToken = "d9699d6ebdca9a464d82eb41decd3c3e8cad0b084c839aa220bdc5f263269edb";
  idMembers = "60c72cfdf46cf1696c472d3f";

  constructor(private http: HttpClient) {}

  // API de Trello
  createCard(
    idList: string,
    cardName: string,
    desc: string,
  ): Observable<object> {
    return this.http.post(
      "https://api.trello.com/1/cards?idList=" +
        idList +
        "&key=" +
        this.APIKey +
        "&token=" +
        this.APIToken +
        "&name=" +
        cardName +
        "&desc=" +
        desc +
        "&pos=top" + 
        "&idMembers=" + 
        this.idMembers,
      null,
      {
        headers: new HttpHeaders({
          Accept: "application/json",
        }),
      }
    );
  }
}
