import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
// Interfaces
import { Client } from "../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../interfaces/CupsGlobalInterface.type";
import { Task } from "../../modules/task-module/request-progress-panel/request-progress-task-interface";
import { MenuItem } from "../../modules/nav-module/navbar-side/navbar-menu";

@Injectable({
  providedIn: "root",
})
export class SessionDataService {
  // Datos de usuario
  // Perfil
  private profile = new Subject<string>();
  private currentProfile: string;
  // Telecontrol
  private remoteControlActive = new Subject<any>();
  private currentRemoteControlActive: any;
  // Nombre de usuario
  private user = new Subject<string>();
  private currentUser: string;
  // Id de usuario
  private userId = new Subject<number>();
  private currentUserId: number;
  // Formato numérico
  private numberFormat = new Subject<string>();
  private currentNumberFormat: string;
  // Formato fecha
  private dateFormat = new Subject<string>();
  private currentDateFormat: string;

  // Datos de agrupación
  // Agrupación
  private agrupation = new Subject<Agrupation>();
  private currentAgrupation: Agrupation;

  // Datos de entidad
  // Entidad
  private entity = new Subject<Entity>();
  private currentEntity: Entity;
  // Lista de entidades
  private entityList = new Subject<Entity[]>();
  private currentEntityList: Entity[];

  // Datos de cliente
  // Cliente
  private client = new Subject<Client>();
  private currentClient: Client;
  // Lista de clientes
  private clientList = new Subject<Client[]>();
  private currentClientList: Client[];

  // Datos de CUPS
  // Lista de CUPS
  private cupsList = new Subject<any>();
  private currentCupsList: any;
  // Configuración de CUPS de entidad
  private entityCupsConf = new Subject<EntityDefinition[]>();
  private currentEntityCupsConf: EntityDefinition[];

  // Datos de abonado
  // Lista de CUPS de abonado
  private subscriberCupsList = new Subject<any>();
  private currentSubscriberCupsList: any;
  // Datos de CUPS de abonado
  private subscriberCupsData = new Subject<any>();
  private currentSubscriberCupsData: any;
  // CUPS en curso de abonado
  private subscriberCups = new Subject<any>();
  private currentSubscriberCups: any;

  // Datos de sesión
  // Mantenimiento
  private maintenance = new Subject<boolean>();
  private currentMaintenance: boolean;
  // Usuario logueado
  private userLogged = new Subject<boolean>();
  private currentUserLogged: boolean;
  // Carga de datos de sesión
  private sessionLoadingFlag = new Subject<any>();
  private currentSessionLoadingFlag: any;
  // Sesión caducada
  private sessionExpiredFlag = new Subject<any>();
  // Sessión renovada
  private sessionRenewedFlag = new Subject<any>();
  // Recarga de url
  private urlReloadFlag = new Subject<any>();
  private currentUrlReloadFlag: any;
  // Idioma
  private language = new Subject<string>();
  private currentLanguage: string;
  // Actualización de navegación
  private updateNavFlag = new Subject<any>();
  // Actualización de configuración de entidad
  private updateEntityCupsConfFlag = new Subject<any>();
  // Actualización de caja de búsqueda de dispositivos
  private updateSearchBoxFlag = new Subject<any>();
  // Coordenadas de clima
  private weatherCoords = new Subject<any>();
  private currentWeatherCoords: any;
  // Notificaciones
  private notificationData = new Subject<any>();
  private currentNotificationData: any;
  // Loop de notificaciones
  private notificationLoop = new Subject<any>();
  // Logout
  private logoutFlag = new Subject<any>();
  // Spinner
  private disableSpinner = new Subject<boolean>();
  private currentDisableSpinner: boolean;
  private spinnerText = new Subject<string>();
  private currentSpinnerText: string;
  private lockSpinner = new Subject<boolean>();
  private currentLockSpinner: boolean;
  // Datos de componente
  private componentData = new Subject<any>();
  private currentComponentData: any;
  // Acción de modal
  private dialogAction = new Subject<any>();
  private currentDialogAction: any;
  // Perfil original para cambio de sesión
  private originalProfile = new Subject<any>();
  private currentOriginalProfile: any;
  // Tareas en progreso
  private wip = new Subject<Task[]>();
  private currentWip: Task[];
  // Acción sobre tareas en progreso
  private taskAction = new Subject<{
    index: number;
    action: string;
    task?: Task;
  }>();
  private currentTaskAction: { index: number; action: string; task?: Task };
  // Menú
  private navbarMenu = new Subject<MenuItem[]>();
  private currentNavbarMenu: MenuItem[];
  // Nueva pestaña
  private newTab = new Subject<boolean>();
  private currentNewTab: boolean;
  // Cancelar navegación
  private cancelNavigation = new Subject<boolean>();
  private currentCancelNavigation: boolean;
  // Ayuda
  private helpActive = new Subject<boolean>();
  private currentHelpActive: boolean;
  // Sospechas
  private suspicionActive = new Subject<boolean>();
  private currentSuspicionActive: boolean;
  // Recarga de panel
  private reloadPanelFlag = new Subject<boolean>();
  // Selecciones guardadas
  private savedSelection = new Subject<any[]>();
  private currentSavedSelection: any[];
  // Patrones guardados
  private savedPatterns = new Subject<any>();
  private currentSavedPatterns: any;
  // Mapa Leaflet
  private mapData = new Subject<any>();
  private currentMapData: any;

  // Cesium
  private cesiumData = new Subject<any>();
  private currentCesiumData: any;
  private cesiumAction = new Subject<any>();
  private currentCesiumAction: any;

  // Canal de comunicación entre pestañas
  private channel4Broadcast = new Subject<any>();
  private currentChannel4Broadcast: any;

  // Games
  private gameActive = new Subject<boolean>();
  private currentGameActive: boolean;

  constructor() {}

  // Limpieza del stream de datos completo
  clearAll() {
    this.clearUserLogged();
    this.clearProfile();
    this.clearUser();
    this.clearUserId();
    this.clearNumberFormat();
    this.clearClient();
    this.clearEntity();
    this.clearAgrupation();
    this.clearEntityList();
    this.clearClientList();
    this.clearCupsList();
    this.clearEntityCupsConf();
    this.clearSubscriberCups();
    this.clearSubscriberCupsList();
    this.clearSubscriberCupsData();
    this.clearRemoteControlActive();
    this.clearUpdateNavFlag();
    this.clearUpdateEntityCupsConfFlag();
    // this.clearUpdateSearchBoxFlag();
    this.clearEntityCupsConf();
    this.clearSessionLoadingFlag();
    this.clearUrlReloadFlag();
    this.clearWeatherCoords();
    this.clearNotificationData();
    this.clearNotificationLoop();
    this.clearLogoutFlag();
    this.clearDisableSpinner();
    this.clearLockSpinner();
    this.clearComponentData();
    this.clearOriginalProfile();
    // this.clearWip();
    // this.clearTaskAction();
    this.clearNewTab();
    this.clearCancelNavigation();
    this.clearHelpActive();
    this.clearSuspicionActive();
    this.clearGameActive();
    this.clearSavedSelection();
  }

  // Mantenimiento
  sendMaintenance(maintenance: boolean): void {
    this.maintenance.next(maintenance);
    this.currentMaintenance = maintenance;
  }

  clearMaintenance(): void {
    this.maintenance.next(null);
    this.currentMaintenance = null;
  }

  getMaintenance(): Observable<boolean> {
    return this.maintenance.asObservable();
  }

  getCurrentMaintenance(): boolean {
    return this.currentMaintenance;
  }

  // UserLogged
  sendUserLogged(userLogged: boolean): void {
    this.userLogged.next(userLogged);
    this.currentUserLogged = userLogged;
  }

  clearUserLogged(): void {
    this.userLogged.next(null);
    this.currentUserLogged = null;
  }

  getUserLogged(): Observable<boolean> {
    return this.userLogged.asObservable();
  }

  getCurrentUserLogged(): boolean {
    return this.currentUserLogged;
  }

  // Profile
  sendProfile(profile: string): void {
    this.profile.next(profile);
    this.currentProfile = profile;
  }

  clearProfile(): void {
    this.profile.next(null);
    this.currentProfile = null;
  }

  getProfile(): Observable<string> {
    return this.profile.asObservable();
  }

  getCurrentProfile(): string {
    return this.currentProfile;
  }

  // User
  sendUser(user: string): void {
    this.user.next(user);
    this.currentUser = user;
  }

  clearUser(): void {
    this.user.next(null);
    this.currentUser = null;
  }

  getUser(): Observable<string> {
    return this.user.asObservable();
  }

  getCurrentUser(): string {
    return this.currentUser;
  }

  // User Id
  sendUserId(userId: number): void {
    this.userId.next(userId);
    this.currentUserId = userId;
  }

  clearUserId(): void {
    this.userId.next(null);
    this.currentUserId = null;
  }

  getUserId(): Observable<number> {
    return this.userId.asObservable();
  }

  getCurrentUserId(): number {
    return this.currentUserId;
  }

  // Number format
  sendNumberFormat(numberFormat: string): void {
    this.numberFormat.next(numberFormat);
    this.currentNumberFormat = numberFormat;
  }

  clearNumberFormat(): void {
    this.numberFormat.next(null);
    this.currentNumberFormat = null;
  }

  getNumberFormat(): Observable<string> {
    return this.numberFormat.asObservable();
  }

  getCurrentNumberFormat(): string {
    return this.currentNumberFormat;
  }

  // Date format
  sendDateFormat(dateFormat: string): void {
    this.dateFormat.next(dateFormat);
    this.currentDateFormat = dateFormat;
  }

  clearDateFormat(): void {
    this.dateFormat.next(null);
    this.currentDateFormat = null;
  }

  getDateFormat(): Observable<string> {
    return this.dateFormat.asObservable();
  }

  getCurrentDateFormat(): string {
    return this.currentDateFormat;
  }

  // Client
  sendClient(client: Client): void {
    this.client.next(client);
    this.currentClient = client;
  }

  clearClient(): void {
    this.client.next(null);
    this.currentClient = null;
  }

  getClient(): Observable<Client> {
    return this.client.asObservable();
  }

  getCurrentClient(): Client {
    return this.currentClient;
  }

  // Entity
  sendEntity(entity: Entity): void {
    this.entity.next(entity);
    this.currentEntity = entity;
    this.clearSavedSelection();
  }

  clearEntity(): void {
    this.entity.next(null);
    this.currentEntity = null;
  }

  getEntity(): Observable<Entity> {
    return this.entity.asObservable();
  }

  getCurrentEntity(): Entity {
    return this.currentEntity;
  }

  // Agrupation
  sendAgrupation(agrupation: Agrupation): void {
    this.agrupation.next(agrupation);
    this.currentAgrupation = agrupation;
  }

  clearAgrupation(): void {
    this.agrupation.next(null);
    this.currentAgrupation = null;
  }

  getAgrupation(): Observable<Agrupation> {
    return this.agrupation.asObservable();
  }

  getCurrentAgrupation(): Agrupation {
    return this.currentAgrupation;
  }

  // Language
  sendLanguage(language: string): void {
    this.language.next(language);
    this.currentLanguage = language;
  }

  clearLanguage(): void {
    this.language.next(null);
    this.currentLanguage = null;
  }

  getLanguage(): Observable<string> {
    return this.language.asObservable();
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  // Lista de entidades del cliente en curso
  sendEntityList(entityList: Entity[]): void {
    this.entityList.next(entityList);
    this.currentEntityList = entityList;
  }

  clearEntityList(): void {
    this.entityList.next(null);
    this.currentEntityList = null;
  }

  getEntityList(): Observable<Entity[]> {
    return this.entityList.asObservable();
  }

  getCurrentEntityList(): Entity[] {
    return this.currentEntityList;
  }

  // Lista de entidades del cliente en curso
  sendClientList(clientList: any): void {
    this.clientList.next(clientList);
    this.currentClientList = clientList;
  }

  clearClientList(): void {
    this.clientList.next(null);
    this.currentClientList = null;
  }

  getClientList(): Observable<any> {
    return this.clientList.asObservable();
  }

  getCurrentClientList(): any {
    return this.currentClientList;
  }

  // Telecontrol
  sendRemoteControlActive(remoteControlActive: boolean): void {
    this.remoteControlActive.next(remoteControlActive);
    this.currentRemoteControlActive = remoteControlActive;
  }

  clearRemoteControlActive(): void {
    this.remoteControlActive.next(null);
    this.currentRemoteControlActive = null;
  }

  getRemoteControlActive(): Observable<boolean> {
    return this.remoteControlActive.asObservable();
  }

  getCurrentRemoteControlActive(): boolean {
    return this.currentRemoteControlActive;
  }

  // Nav flag
  sendUpdateNavFlag(): void {
    this.updateNavFlag.next(null);
  }

  clearUpdateNavFlag(): void {
    this.updateNavFlag.next(null);
  }

  getUpdateNavFlag(): Observable<any> {
    return this.updateNavFlag.asObservable();
  }

  // Search box flag
  sendUpdateSearchBoxFlag(): void {
    this.updateSearchBoxFlag.next(null);
  }

  clearUpdateSearchBoxFlag(): void {
    this.updateSearchBoxFlag.next(null);
  }

  getUpdateSearchBoxFlag(): Observable<any> {
    return this.updateSearchBoxFlag.asObservable();
  }

  // EntityCupsConf box flag
  sendUpdateEntityCupsConfFlag(): void {
    this.updateEntityCupsConfFlag.next(null);
  }

  clearUpdateEntityCupsConfFlag(): void {
    this.updateEntityCupsConfFlag.next(null);
  }

  getUpdateEntityCupsConfFlag(): Observable<any> {
    return this.updateEntityCupsConfFlag.asObservable();
  }

  // Configuración de CUPS para entidad en curso
  sendEntityCupsConf(entityCupsConf: EntityDefinition[]): void {
    this.entityCupsConf.next(entityCupsConf);
    this.currentEntityCupsConf = entityCupsConf;
  }

  clearEntityCupsConf(): void {
    this.entityCupsConf.next(null);
    this.currentEntityCupsConf = null;
  }

  getEntityCupsConf(): Observable<EntityDefinition[]> {
    return this.entityCupsConf.asObservable();
  }

  getCurrentEntityCupsConf(): EntityDefinition[] {
    return this.currentEntityCupsConf;
  }

  // Flag de sesión en carga
  sendSessionLoadingFlag(sessionLoadingFlag: boolean): void {
    this.sessionLoadingFlag.next(sessionLoadingFlag);
    this.currentSessionLoadingFlag = sessionLoadingFlag;
  }

  clearSessionLoadingFlag(): void {
    this.sessionLoadingFlag.next(null);
    this.currentSessionLoadingFlag = null;
  }

  getSessionLoadingFlag(): Observable<boolean> {
    return this.sessionLoadingFlag.asObservable();
  }

  getCurrentSessionLoadingFlag(): boolean {
    return this.currentSessionLoadingFlag;
  }

  // Flag de sesión caducada
  sendSessionExpiredFlag(): void {
    this.sessionExpiredFlag.next(null);
  }

  getSessionExpiredFlag(): Observable<any> {
    return this.sessionExpiredFlag.asObservable();
  }

  // Flag de sesión renovada
  sendSessionRenewedFlag(): void {
    this.sessionRenewedFlag.next(null);
  }

  getSessionRenewedFlag(): Observable<any> {
    return this.sessionRenewedFlag.asObservable();
  }

  // Flag de recarga de url
  sendUrlReloadFlag(urlReloadFlag: boolean): void {
    this.urlReloadFlag.next(urlReloadFlag);
    this.currentUrlReloadFlag = urlReloadFlag;
  }

  clearUrlReloadFlag(): void {
    this.urlReloadFlag.next(null);
    this.currentUrlReloadFlag = null;
  }

  getUrlReloadFlag(): Observable<boolean> {
    return this.urlReloadFlag.asObservable();
  }

  getCurrentUrlReloadFlag(): boolean {
    return this.currentUrlReloadFlag;
  }

  // Lista de CUPS de abonado
  sendSubscriberCupsList(subscriberCupsList: any): void {
    this.subscriberCupsList.next(subscriberCupsList);
    this.currentSubscriberCupsList = subscriberCupsList;
  }

  clearSubscriberCupsList(): void {
    this.subscriberCupsList.next(null);
    this.currentSubscriberCupsList = null;
  }

  getSubscriberCupsList(): Observable<any> {
    return this.subscriberCupsList.asObservable();
  }

  getCurrentSubscriberCupsList(): any {
    return this.currentSubscriberCupsList;
  }

  // Datos de CUPS de abonado
  sendSubscriberCupsData(subscriberCupsData: any): void {
    this.subscriberCupsData.next(subscriberCupsData);
    this.currentSubscriberCupsData = subscriberCupsData;
  }

  clearSubscriberCupsData(): void {
    this.subscriberCupsData.next(null);
    this.currentSubscriberCupsData = null;
  }

  getSubscriberCupsData(): Observable<any> {
    return this.subscriberCupsData.asObservable();
  }

  getCurrentSubscriberCupsData(): any {
    return this.currentSubscriberCupsData;
  }

  // Datos de CUPS de abonado
  sendSubscriberCups(subscriberCups: any): void {
    this.subscriberCups.next(subscriberCups);
    this.currentSubscriberCups = subscriberCups;
  }

  clearSubscriberCups(): void {
    this.subscriberCups.next(null);
    this.currentSubscriberCups = null;
  }

  getSubscriberCups(): Observable<any> {
    return this.subscriberCups.asObservable();
  }

  getCurrentSubscriberCups(): any {
    return this.currentSubscriberCups;
  }

  // Coordenadas de agrupación
  sendWeatherCoords(weatherCoords: any): void {
    this.weatherCoords.next(weatherCoords);
    this.currentWeatherCoords = weatherCoords;
  }

  clearWeatherCoords(): void {
    this.weatherCoords.next(null);
    this.currentWeatherCoords = null;
  }

  getWeatherCoords(): Observable<any> {
    return this.weatherCoords.asObservable();
  }

  getCurrentWeatherCoords(): any {
    return this.currentWeatherCoords;
  }

  // Lista de CUPS
  sendCupsList(cupsList: any): void {
    this.cupsList.next(cupsList);
    this.currentCupsList = cupsList;
  }

  clearCupsList(): void {
    this.cupsList.next(null);
    this.currentCupsList = null;
  }

  getCupsList(): Observable<any> {
    return this.cupsList.asObservable();
  }

  getCurrentCupsList(): any {
    return this.currentCupsList;
  }

  // Notificaciones
  sendNotificationData(notificationData: any): void {
    this.notificationData.next(notificationData);
    this.currentNotificationData = notificationData;
  }

  clearNotificationData(): void {
    this.notificationData.next(null);
    this.currentNotificationData = null;
  }

  getNotificationData(): Observable<any> {
    return this.notificationData.asObservable();
  }

  getCurrentNotificationData(): any {
    return this.currentNotificationData;
  }

  // Loop de notificaciones
  sendNotificationLoop(notificationLoop: boolean): void {
    this.notificationLoop.next(notificationLoop);
  }

  clearNotificationLoop(): void {
    this.notificationLoop.next(null);
  }

  getNotificationLoop(): Observable<any> {
    return this.notificationLoop.asObservable();
  }

  // Flag de logout
  sendLogoutFlag(): void {
    this.logoutFlag.next(null);
  }

  clearLogoutFlag(): void {
    this.logoutFlag.next(null);
  }

  getLogoutFlag(): Observable<any> {
    return this.logoutFlag.asObservable();
  }

  // Deshabilitación del spinner de carga
  sendDisableSpinner(disableSpinner: boolean): void {
    this.disableSpinner.next(disableSpinner);
    this.currentDisableSpinner = disableSpinner;
  }

  clearDisableSpinner(): void {
    this.disableSpinner.next(null);
    this.currentDisableSpinner = null;
  }

  getDisableSpinner(): Observable<boolean> {
    return this.disableSpinner.asObservable();
  }

  getCurrentDisableSpinner(): boolean {
    return this.currentDisableSpinner;
  }

  // Bloqueo del spinner de carga
  sendLockSpinner(lockSpinner: boolean): void {
    this.lockSpinner.next(lockSpinner);
    this.currentLockSpinner = lockSpinner;
  }

  clearLockSpinner(): void {
    this.lockSpinner.next(null);
    this.currentLockSpinner = null;
  }

  getLockSpinner(): Observable<boolean> {
    return this.lockSpinner.asObservable();
  }

  getCurrentLockSpinner(): boolean {
    return this.currentLockSpinner;
  }

  // Texto del spinner de carga
  sendSpinnerText(spinnerText: string): void {
    this.spinnerText.next(spinnerText);
    this.currentSpinnerText = spinnerText;
  }

  clearSpinnerText(): void {
    this.spinnerText.next(null);
    this.currentSpinnerText = null;
  }

  getSpinnerText(): Observable<string> {
    return this.spinnerText.asObservable();
  }

  getCurrentSpinnerText(): string {
    return this.currentSpinnerText;
  }

  // Datos de componente
  sendComponentData(componentData: any): void {
    this.componentData.next(componentData);
    this.currentComponentData = componentData;
  }

  clearComponentData(): void {
    this.componentData.next(null);
    this.currentComponentData = null;
  }

  getComponentData(): Observable<any> {
    return this.componentData.asObservable();
  }

  getCurrentComponentData(): any {
    return this.currentComponentData;
  }

  // Acción de modal
  sendDialogAction(dialogAction: any): void {
    this.dialogAction.next(dialogAction);
    this.currentDialogAction = dialogAction;
  }

  clearDialogAction(): void {
    this.dialogAction.next(null);
    this.currentDialogAction = null;
  }

  getDialogAction(): Observable<any> {
    return this.dialogAction.asObservable();
  }

  getCurrentDialogAction(): any {
    return this.currentDialogAction;
  }

  // Perfil original para cambio de sesión
  sendOriginalProfile(originalProfile: any): void {
    this.originalProfile.next(originalProfile);
    this.currentOriginalProfile = originalProfile;
  }

  clearOriginalProfile(): void {
    this.originalProfile.next(null);
    this.currentOriginalProfile = null;
  }

  getOriginalProfile(): Observable<any> {
    return this.originalProfile.asObservable();
  }

  getCurrentOriginalProfile(): any {
    return this.currentOriginalProfile;
  }

  // Tareas en progreso
  sendWip(wip: Task[]): void {
    this.wip.next(wip);
    this.currentWip = wip;
  }

  clearWip(): void {
    this.wip.next(null);
    this.currentWip = null;
  }

  getWip(): Observable<Task[]> {
    return this.wip.asObservable();
  }

  getCurrentWip(): Task[] {
    return this.currentWip;
  }

  // Acción sobre tareas en progreso
  sendTaskAction(taskAction: {
    index: number;
    action: string;
    task?: Task;
  }): void {
    this.taskAction.next(taskAction);
    this.currentTaskAction = taskAction;
  }

  clearTaskAction(): void {
    this.taskAction.next(null);
    this.currentTaskAction = null;
  }

  getTaskAction(): Observable<{ index: number; action: string; task?: Task }> {
    return this.taskAction.asObservable();
  }

  getCurrentTaskAction(): { index: number; action: string; task?: Task } {
    return this.currentTaskAction;
  }

  // Acción sobre tareas en progreso
  sendNavbarMenu(navbarMenu: MenuItem[]): void {
    this.navbarMenu.next(navbarMenu);
    this.currentNavbarMenu = navbarMenu;
  }

  clearNavbarMenu(): void {
    this.navbarMenu.next(null);
    this.currentNavbarMenu = null;
  }

  getNavbarMenu(): Observable<MenuItem[]> {
    return this.navbarMenu.asObservable();
  }

  getCurrentNavbarMenu(): MenuItem[] {
    return this.currentNavbarMenu;
  }

  // Nueva pestaña
  sendNewTab(data: boolean): void {
    this.newTab.next(data);
    this.currentNewTab = data;
  }

  clearNewTab(): void {
    this.newTab.next(null);
    this.currentNewTab = null;
  }

  getNewTab(): Observable<boolean> {
    return this.newTab.asObservable();
  }

  getCurrentNewTab(): boolean {
    return this.currentNewTab;
  }

  // Cancelar navegación
  sendCancelNavigation(data: boolean): void {
    this.cancelNavigation.next(data);
    this.currentCancelNavigation = data;
  }

  clearCancelNavigation(): void {
    this.cancelNavigation.next(null);
    this.currentCancelNavigation = null;
  }

  getCancelNavigation(): Observable<boolean> {
    return this.cancelNavigation.asObservable();
  }

  getCurrentCancelNavigation(): boolean {
    return this.currentCancelNavigation;
  }

  // Ayuda
  sendHelpActive(data: boolean): void {
    this.helpActive.next(data);
    this.currentHelpActive = data;
  }

  clearHelpActive(): void {
    this.helpActive.next(null);
    this.currentHelpActive = null;
  }

  getHelpActive(): Observable<boolean> {
    return this.helpActive.asObservable();
  }

  getCurrentHelpActive(): boolean {
    return this.currentHelpActive;
  }

  // Sospechas
  sendSuspicionActive(data: boolean): void {
    this.suspicionActive.next(data);
    this.currentSuspicionActive = data;
  }

  clearSuspicionActive(): void {
    this.suspicionActive.next(null);
    this.currentSuspicionActive = null;
  }

  getSuspicionActive(): Observable<boolean> {
    return this.suspicionActive.asObservable();
  }

  getCurrentSuspicionActive(): boolean {
    return this.currentSuspicionActive;
  }

  // Selecciones guardadas
  sendSavedSelection(data: any[]): void {
    this.savedSelection.next(data);
    this.currentSavedSelection = data;
  }

  clearSavedSelection(): void {
    this.savedSelection.next(null);
    this.currentSavedSelection = null;
  }

  getSavedSelection(): Observable<any[]> {
    return this.savedSelection.asObservable();
  }

  getCurrentSavedSelection(): any[] {
    return this.currentSavedSelection;
  }

  // Patrones guardadas
  sendSavedPatterns(data: any): void {
    this.savedPatterns.next(data);
    this.currentSavedPatterns = data;
  }

  clearSavedPatterns(): void {
    this.savedPatterns.next(null);
    this.currentSavedPatterns = null;
  }

  getSavedPatterns(): Observable<any> {
    return this.savedPatterns.asObservable();
  }

  getCurrentSavedPatterns(): any {
    return this.currentSavedPatterns;
  }

  // Games
  sendGameActive(data: boolean): void {
    this.gameActive.next(data);
    this.currentGameActive = data;
  }

  clearGameActive(): void {
    this.gameActive.next(null);
    this.currentGameActive = null;
  }

  getGameActive(): Observable<boolean> {
    return this.gameActive.asObservable();
  }

  getCurrentGameActive(): boolean {
    return this.currentGameActive;
  }

  // Recarga de panel
  sendReloadPanelFlag(): void {
    this.reloadPanelFlag.next(null);
  }

  getReloadPanelFlag(): Observable<boolean> {
    return this.reloadPanelFlag.asObservable();
  }

  // Map data
  sendMapData(mapData: any): void {
    this.mapData.next(mapData);
    this.currentMapData = mapData;
  }

  clearMapData(): void {
    this.mapData.next(null);
    this.currentMapData = null;
  }

  getCurrentMapData(): any {
    return this.currentMapData;
  }

  // Cesium data
  sendCesiumData(cesiumData: any): void {
    this.cesiumData.next(cesiumData);
    this.currentCesiumData = cesiumData;
  }

  clearCesiumData(): void {
    this.cesiumData.next(null);
    this.currentCesiumData = null;
  }

  getCesiumData(): Observable<any> {
    return this.cesiumData.asObservable();
  }

  getCurrentCesiumData(): any {
    return this.currentCesiumData;
  }

  // Cesium action
  sendCesiumAction(cesiumAction: any): void {
    this.cesiumAction.next(cesiumAction);
    this.currentCesiumAction = cesiumAction;
  }

  clearCesiumAction(): void {
    this.cesiumAction.next(null);
    this.currentCesiumAction = null;
  }

  getCesiumAction(): Observable<any> {
    return this.cesiumAction.asObservable();
  }

  getCurrentCesiumAction(): any {
    return this.currentCesiumAction;
  }

  // Canal de comunicación entre pestañas
  sendChannel4Broadcast(channel4Broadcast: any): void {
    this.channel4Broadcast.next(channel4Broadcast);
    this.currentChannel4Broadcast = channel4Broadcast;
  }

  clearChannel4Broadcast(): void {
    this.channel4Broadcast.next(null);
    this.currentChannel4Broadcast = null;
  }

  getChannel4Broadcast(): Observable<any> {
    return this.channel4Broadcast.asObservable();
  }

  getCurrentChannel4Broadcast(): any {
    return this.currentChannel4Broadcast;
  }
}
