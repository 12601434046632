// @angular
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { SensoryLeakService } from "../../SensoryLeak.service";
// Interfaces
import { MapDevice } from "../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-sensory-leaks-dialog",
  templateUrl: "./sensory-leaks-dialog.component.html",
  styleUrls: ["./sensory-leaks-dialog.component.scss"],
})
export class SensoryLeaksDialogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: { sensors: MapDevice[]; data: MapDevice[] };
  comenentDataSub: Subscription;

  // Gráfica
  graphData: any;
  graphSeries: any[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  metersGraph: any;
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  from: string;
  to: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private SensoryLeakService: SensoryLeakService,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.comenentDataSub = this.SessionDataService.getComponentData().subscribe(
      (data) => {
        if (data.action == "noiseGraph") {
          this.chartOptions = data.chartOptions;
        }
      }
    );

    this.loadGraph();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.comenentDataSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.highchartsOptions = this.SensoryLeakService.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.metersGraph = null;
    this.graphData = this.SensoryLeakService.loadGraphData(
      this.from,
      this.to,
      this.data.sensors
    );
  }

  // Visualización de consumo de contadores en rango
  showMeters(): void {
    // this.SensoryLeakService.showMeters(
    //   this.from,
    //   this.to,
    //   this.data.sensors,
    //   this.data.data
    // );
  }
}
