<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "agrupations-edition" | translate }}</b>
        </h5>
      </div>

      <div #agrupationPanel class="panel-body agrupation-edition-panel">
        <!-- Mapa de contadores -->
        <div
          *ngIf="!noVirtualAgrupation; else elseBlock"
          (click)="mapDisabled ? pendingEditionAdvise() : null"
        >
          <app-map-controller
            *ngIf="mapHeight"
            [ngClass]="{ 'agrupation-map-disabled': mapDisabled }"
            [mapType]="mapType"
            [mapHeight]="mapHeight"
            [disableCluster]="true"
            [disableLegend]="true"
            [drawAgrupationOutline]="true"
            [agrupationEditionActive]="true"
            [agrupationEditionDisabled]="agrupationEditionDisabled"
            (agrupationChanges)="updateAgrupationChanges($event)"
            [gatewaysData]="[]"
            [metersData]="devices"
          >
          </app-map-controller>
        </div>

        <!-- Tabla de cambios en agrupaciones -->
        <div *ngIf="agrupationChanges" id="agrupation-edition-table">
          <table class="agrupation-edition-changes">
            <tr>
              <th colspan="2">
                <div class="agrupation-edition-warning">
                  {{ "agrupation-devices-change-result" | translate }}:
                  <span>{{ agrupationChanges.newAgrupationName }}</span>
                  ({{ "agrupation-devices-change-reverted" | translate }})
                </div>
              </th>
            </tr>
            <tr>
              <th>{{ "groups" | translate }}</th>
              <th>{{ "devices" | translate }}</th>
            </tr>
            <tr
              *ngFor="
                let agrupation of agrupationChanges.newDevices;
                index as i
              "
            >
              <td>
                <div class="agrupation-change-device">
                  <i class="fas fa-times" (click)="removeAgrupation(i)"></i>
                  {{ agrupation.agrupationName }}
                </div>
              </td>
              <td>
                <div class="agrupation-change-device-list">
                  <ng-container
                    *ngFor="let device of agrupation.devices; index as j"
                  >
                    <div class="agrupation-change-device">
                      <i
                        *ngIf="!device.inProgress && !device.changed"
                        class="fas fa-times"
                        (click)="removeDevice(agrupation, j)"
                      ></i>
                      <i
                        *ngIf="device.inProgress"
                        class="fas fa-spinner fa-pulse"
                      ></i>
                      <i *ngIf="device.changed" class="fas fa-check"></i>
                      {{ device.nroSerie }}
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="agrupationChanges" class="agrupation-edition-save">
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="changeDevicesAgrupation()"
          >
            <i class="fas fa-save"></i>
            {{ "save" | translate }}
          </button>
          <button mat-button (click)="cancelChanges()">
            {{ "cancel" | translate }}
          </button>
        </div>

        <!-- Spinner de carga -->
        <ng-template #elseBlock>
          <div class="management-no-virtual">
            <i class="fas fa-exclamation-triangle"></i>
            {{ "agrupations-edition-disabled" | translate }}
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
