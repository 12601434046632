import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Gateway,
  GatewayOptions,
} from "../../../interfaces/GatewayGlobalInterface.type";
import { ManagementGateway } from "../management/ManagementInterface.type";

@Injectable({
  providedIn: "root",
})
export class GatewayVersionParserService {
  // Valores del campo micro:
  microValues: object = {
    // 0: "Silicon Labs EFM32GG11B820F2048",
    // 1: "ST Microelectronics STM32L496VG",
    0: "SiLabs",
    1: "ST",
  };
  // Valores del campo banda:
  bandValues: object = {
    0: "EU868",
    1: "AS923",
  };

  // Valores de la máscara:
  // bit 0: El equipo tiene memoria MRAM QSPI
  // bit 1: El Gateway tiene GPS
  // bit 2: Puerto local de comunicaciones habilitado
  // bit 3: Debug a través del puerto local habilitado
  // bit 4: El equipo tiene memoria MRAM SPI
  optionsValues = [
    this.translate.instant("gateway-options-bit0"),
    this.translate.instant("gateway-options-bit1"),
    this.translate.instant("gateway-options-bit2"),
    this.translate.instant("gateway-options-bit3"),
    this.translate.instant("gateway-options-bit4"),
  ];

  constructor(private translate: TranslateService) {}

  getBand(band: number): string {
    return this.bandValues[band];
  }

  getMicro(micro: number): string {
    return this.microValues[micro];
  }

  getOptionsText(options: number): string[] {
    let optionsMask = options?.toString(2);
    return this.optionsValues.filter(
      (option: string, i) =>
        optionsMask?.charAt(optionsMask.length - (1 + i)) == "1"
    );
  }

  getOptions(options: number): GatewayOptions {
    let optionsParsed = options?.toString(2);
    return {
      mramQspi: optionsParsed?.charAt(optionsParsed.length - 1) == "1",
      gps: optionsParsed?.charAt(optionsParsed.length - 2) == "1",
      localPort: optionsParsed?.charAt(optionsParsed.length - 3) == "1",
      debug: optionsParsed?.charAt(optionsParsed.length - 4) == "1",
      mramSpi: optionsParsed?.charAt(optionsParsed.length - 5) == "1",
      lte: optionsParsed?.charAt(optionsParsed.length - 6) == "1",
    };
  }

  setGatewayOptions(gateway: Gateway | ManagementGateway): void {
    let optionsParsed = gateway.options?.toString(2);
    gateway.mramQspi = optionsParsed?.charAt(optionsParsed.length - 1) == "1";
    gateway.gps = optionsParsed?.charAt(optionsParsed.length - 2) == "1";
    gateway.localPort = optionsParsed?.charAt(optionsParsed.length - 3) == "1";
    gateway.debug = optionsParsed?.charAt(optionsParsed.length - 4) == "1";
    gateway.mramSpi = optionsParsed?.charAt(optionsParsed.length - 5) == "1";
    gateway.lte = optionsParsed?.charAt(optionsParsed.length - 6) == "1";
  }
}
