<div class="material-tree-wrapper">
  <div class="material-tree-controls">
    <input
      matInput
      type="text"
      autocomplete="off"
      [(ngModel)]="searchedNode"
      (keyup)="filterTree()"
      placeholder="{{ 'search' | translate }}..."
    />
    <i
      class="pointer fas"
      [ngClass]="expanded ? 'fa-compress-alt' : 'fa-expand-alt'"
      (click)="expanded = !expanded; updateExpanded(); checkScroll()"
    ></i>
  </div>
  <div
    #matTreeContainer
    class="material-tree-container"
    (scroll)="updateScroll()"
  >
    <app-material-tree
      [nodes]="_nodes"
      (expandedUpdate)="expanded = $event"
      (parentExpanded)="checkScroll()"
      (selectedChange)="updateSelectedNodes()"
    ></app-material-tree>
  </div>
  <div *ngIf="showScroll" class="material-tree-extra-nodes">
    <div>
      <i *ngIf="!minScroll" class="fas fa-sort-up"></i>
      <i *ngIf="!maxSroll" class="fas fa-sort-down"></i>
    </div>
  </div>
</div>
