// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { Gateway } from "../../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { GatewayInstockDialogComponent } from "../gateways-instock/gateway-instock-dialog/gateway-instock-dialog.component";
import { GatewayAvailableDialogComponent } from "./gateway-available-dialog/gateway-available-dialog.component";

@Component({
  selector: "app-gatewaysavailable",
  templateUrl: "./gateways-available.component.html",
  styleUrls: ["./gateways-available.component.scss"],
})
export class GatewaysAvailableComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;
  dialog: Subscription;

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("gateway-unassigned-export") +
    " " +
    moment().format("ll");
  tableData: Gateway[];
  tableSelectedData: Gateway[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "change-entity",
          tooltip: "gateway-change-entity",
          icon: "fas fa-sitemap",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "activate",
          tooltip: "activate",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "deactivate",
          tooltip: "deactivate",
          icon: "fas fa-warehouse",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "Amei",
      data: "amei",
      search: "amei",
      sort: "amei",
      visible: true,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      visible: true,
    },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "change-entity",
      icon: "fas fa-sitemap",
      selectionRequired: true,
      help: "help-table-change-entity",
    },
    {
      title: "deactivate",
      icon: "fas fa-warehouse",
      selectionRequired: true,
      help: "help-table-deactivate",
    },
  ];

  // Filtros
  nroSerieFilter: string = "";
  unidadVentaFilter: string = "";
  ameiFilter: string = "";

  // Importación de archivo
  fileToUploadKeys: File[] = [];
  fileNameKeys: string[] = [];
  fileNames: string;
  csv: boolean;
  @ViewChild("importInput") importInput: ElementRef;

  // Menú de opciones del componente
  newIcon: string = "fa fa-plus";
  newTitle: string = this.translate.instant("new");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayController: GatewayControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getData();
        }
      }
    );

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.GatewayController.getEntityAvailableGateways(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"] != null) {
          let tableData: Gateway[] = response["body"];
          tableData.forEach((gateway: Gateway) => {
            gateway.lastCommunicationParsed = this.DateParserService.parseDate(
              gateway.lastCommunication,
              "L HH:mm:ss"
            );
          });
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, gateway: Gateway): void {
    switch (action) {
      case "show-gateway":
        this.router.navigate(["/gateways/detalle/gateway/" + gateway.id]);
        break;
      case "change-entity":
        this.changeGatewayEntity([gateway.id]);
        break;
      case "activate":
        this.MaterialDialogService.openDialog(
          GatewayAvailableDialogComponent,
          gateway
        );
        break;
      case "deactivate":
        this.changeGatewayEntity([gateway.id], true);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "change-entity":
        this.changeGatewayEntity(
          this.tableSelectedData?.map((gateway) => gateway.id)
        );
        break;
      case "deactivate":
        this.changeGatewayEntity(
          this.tableSelectedData?.map((gateway) => gateway.id),
          true
        );
        break;
      default:
        break;
    }
  }

  // Cambio de entidad de un gateway
  changeGatewayEntity(gatewaysIds: number[], deactivate?: boolean): void {
    this.MaterialDialogService.openDialog(GatewayInstockDialogComponent, {
      action: "changeEntity",
      entity: deactivate ? null : this.currentEntity.id,
      gateways: gatewaysIds,
    });
  }
}
