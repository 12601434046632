<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "edit" | translate }}: {{ data.unidadVenta }}
    </h5>
  </div>
  <div class="dialog-body">
    <div>
      <!-- Latitud -->
      <mat-form-field
        class="activation-dialog-input-number"
        appearance="standard"
      >
        <mat-label>{{ "latitude" | translate }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="data.latitude"
          step="0.01"
          min="-90"
          max="90"
          required
        />
      </mat-form-field>
      <!-- Longitud -->
      <mat-form-field
        class="activation-dialog-input-number"
        appearance="standard"
      >
        <mat-label>{{ "longitude" | translate }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="data.longitude"
          step="0.01"
          min="-180"
          max="180"
          required
        />
      </mat-form-field>
    </div>
    <!-- Comentarios -->
    <mat-form-field class="activation-dialog-input-area" appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="data.comments"></textarea>
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="
        data.longitude != null && data.latitude != null ? false : true
      "
      (click)="activateGateway()"
    >
      <i class="fas fa-redo-alt"></i>
      {{ "activate" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
