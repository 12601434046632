<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>

      <h5>
        <b>
          {{ "groups" | translate }} | {{ "user-log" | translate }}:
          {{
            currentAgrupation != null
              ? currentAgrupation.name
              : ("no-data" | translate)
          }}</b
        >
      </h5>
      <div class="loading" *ngIf="loading">
        <i class="fas fa-sync-alt fa-spin"></i>
      </div>
    </div>

    <div class="panel-body">
      <!-- Tabla de log de usuarios -->
      <app-table-controller
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="tableMaxReg"
        [export]="true"
        [exportFileName]="exportFileName"
        [dateRangeSelector]="true"
        [initialDate]="dataInitialDate"
        (dataRequest)="getData($event.from, $event.to)"
        [specialFilter]="true"
        [data]="tableData"
      >
      </app-table-controller>
    </div>
  </div>
</section>
