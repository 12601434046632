<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>
          <h5>
            <b> {{ "file-template-export" | translate }}</b>
          </h5>

          <!-- Botón guardar -->
          <app-material-panel-menu
            [onlyButton]="true"
            [icon]="'fas fa-save'"
            [title]="'save' | translate"
            (action)="saveCron()"
            [disabled]="
              !cron?.name ||
              !cron?.template ||
              selectedAgrupations?.length == 0 ||
              cron?.frecuencia == null ||
              (cron?.frecuencia == 1 && cron?.dayInterval == null) ||
              (cron?.frecuencia == 2 && !cron?.weekDays) ||
              (cron?.frecuencia == 3 && !cron?.monthDaysArray) ||
              !cron?.horaLocal
            "
          >
          </app-material-panel-menu>
        </div>
      </div>

      <div class="panel-body">
        <div *ngIf="cron; else elseBlock">
          <div class="output-files-name">
            <mat-form-field appearance="standard">
              <mat-label>{{ "name" | translate }}</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="cron.name"
                placeholder="{{ 'name' | translate }}..."
                required
              />
            </mat-form-field>

            <!-- Plantilla -->
            <app-material-select
              [title]="('template' | translate) + ' *'"
              [bindValue]="'id'"
              [bindLabel]="'templateName'"
              [selected]="cron.template"
              [data]="templateList"
              (selectedOption)="cron.template = $event.id"
            ></app-material-select>
          </div>

          <div class="output-files-selectors">
            <!-- Agrupaciones -->
            <app-material-select
              [multiple]="true"
              [clearable]="true"
              [fullWidth]="true"
              [filter]="true"
              [title]="('agrupations' | translate) + ' *'"
              [bindValue]="'id'"
              [bindLabel]="'name'"
              [data]="agrupationList"
              [selected]="cron.agrupations"
              (selectedOption)="selectedAgrupations = $event"
            ></app-material-select>
          </div>

          <!-- Emails -->
          <div class="output-files-cron-emails">
            <mat-form-field appearance="standard">
              <mat-label> {{ "Email" | translate }}</mat-label>
              <input
                matInput
                [placeholder]="'Email' | translate"
                type="text"
                [(ngModel)]="cron.emails"
              />
              <mat-hint>{{ "cron-emails-info" | translate }}</mat-hint>
            </mat-form-field>
          </div>

          <!-- FTP -->
          <div class="output-files-disabled-selectors">
            <app-material-select
              [fullWidth]="true"
              [noSelection]="true"
              [title]="'FTP' | translate"
              [bindValue]="'id'"
              [bindLabel]="'ftpParsed'"
              [selected]="cron.ftpConfig"
              [data]="ftpList"
              (selectedOption)="cron.ftpConfig = $event?.id"
            ></app-material-select>

            <!-- API REST -->
            <app-material-select
              [fullWidth]="true"
              [noSelection]="true"
              [title]="'API REST' | translate"
              [bindValue]="'id'"
              [bindLabel]="'id'"
              [data]="apiRestOptions"
              (selectedOption)="(null)"
              [disabled]="true"
            ></app-material-select>

            <!-- Conectores externos -->
            <app-material-select
              [fullWidth]="true"
              [noSelection]="true"
              [title]="'external-connectors' | translate"
              [bindValue]="'id'"
              [bindLabel]="'id'"
              [data]="externalConectors"
              (selectedOption)="(null)"
              [disabled]="true"
            ></app-material-select>
          </div>

          <!-- Periodo de tiempo -->
          <div class="output-files-time-selector">
            <div>
              <span>{{ "cron-frequency" | translate }} *</span>
              <mat-radio-group
                class="output-files-time-selector-inputs"
                [(ngModel)]="cron.frecuencia"
                (change)="inputFocus()"
                required
              >
                <!-- Diario -->
                <mat-radio-button
                  class="mat-radio"
                  [value]="1"
                  (click)="cronDaily.click()"
                >
                  <mat-form-field appearance="standard">
                    <mat-label> {{ "cron-daily" | translate }}</mat-label>
                    <input
                      #cronDaily
                      matInput
                      type="number"
                      [placeholder]="'interval' | translate"
                      [disabled]="cron.frecuencia != 1"
                      [(ngModel)]="cron.dayInterval"
                    />
                    <mat-hint *ngIf="cron.frecuencia == 1">{{
                      "cron-daily-info" | translate
                    }}</mat-hint>
                  </mat-form-field>
                </mat-radio-button>
                <!-- Semanal -->
                <mat-radio-button class="mat-radio" [value]="2">
                  <mat-form-field
                    appearance="standard"
                    class="output-files-cron-days-input"
                  >
                    <mat-label> {{ "cron-weekly" | translate }}</mat-label>
                    <div
                      *ngIf="cron.weekDays && cron.frecuencia == 2"
                      class="output-files-cron-days"
                    >
                      <!-- Selector de días -->
                      <div
                        [ngClass]="{
                          disabled: cron.frecuencia != 2
                        }"
                      >
                        <span
                          *ngFor="let day of week; index as i"
                          [ngClass]="{
                            'output-files-cron-days-selected': cron.weekDays[i],
                            'output-files-cron-days-disabled': !cron.weekDays[i]
                          }"
                          (click)="cron.weekDays[i] = !cron.weekDays[i]"
                          >{{ day | translate }}
                        </span>
                      </div>
                    </div>
                    <input
                      #cronWeekly
                      matInput
                      type="number"
                      [disabled]="cron.frecuencia != 2"
                      [ngClass]="{ hidden: cron.frecuencia == 2 }"
                    />
                    <mat-hint *ngIf="cron.frecuencia == 2">{{
                      "cron-weekly-info" | translate
                    }}</mat-hint>
                  </mat-form-field>
                </mat-radio-button>
                <!-- Mensual -->
                <mat-radio-button class="mat-radio" [value]="3">
                  <mat-form-field
                    appearance="standard"
                    class="output-files-cron-month-input"
                  >
                    <mat-label> {{ "cron-monthly" | translate }}</mat-label>
                    <div
                      *ngIf="cron.frecuencia == 3"
                      class="output-files-cron-days"
                    >
                      <!-- Selector de días -->
                      <div
                        [ngClass]="{
                          disabled: cron.frecuencia != 3
                        }"
                      >
                        <span
                          *ngFor="let day of cron.monthDaysArray; index as i"
                          [ngClass]="{
                            'output-files-cron-days-selected':
                              cron.monthDaysArray[i],
                            'output-files-cron-days-disabled':
                              !cron.monthDaysArray[i]
                          }"
                          (click)="
                            cron.monthDaysArray[i] = !cron.monthDaysArray[i]
                          "
                          >{{ i + 1 }}
                        </span>
                      </div>
                    </div>
                    <input
                      #cronMonthly
                      matInput
                      type="text"
                      [disabled]="cron.frecuencia != 3"
                      [ngClass]="{ hidden: cron.frecuencia == 3 }"
                    />
                    <mat-hint *ngIf="cron.frecuencia == 3">{{
                      "cron-monthly-info" | translate
                    }}</mat-hint>
                  </mat-form-field>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- Hora -->
            <div class="output-files-cron-hour">
              <span>{{ "hour" | translate }} *</span>
              <!-- Zona horaria -->
              <div class="agrupation-form-select">
                <app-material-select
                  *ngIf="currentAgrupation"
                  [title]="'timezone' | translate"
                  [filter]="true"
                  [bindValue]="'value'"
                  [bindLabel]="'name'"
                  [selected]="currentAgrupation.timezone"
                  [data]="timezoneOptions"
                  (selectedOption)="cronTimezone = $event?.value"
                  [required]="true"
                ></app-material-select>
              </div>
              <input
                [(ngModel)]="cron.horaLocal"
                type="time"
                id="hour"
                name="hour"
                placeholder="HH:mm:ss"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
