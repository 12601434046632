<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{
            associationId
              ? ("meters-groups-edit" | translate)
              : ("meters-groups-add" | translate)
          }}</b>
        </h5>

        <!-- Botón guardar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="saveIcon"
          [title]="saveTitle"
          [disabled]="
            associationName == null ||
            associationName == '' ||
            associationDescription == null ||
            associationDescription == ''
          "
          (action)="associationId ? editAssociation() : newAssociation()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Formulario de creación de asociación -->
        <div *ngIf="tableData" class="association-form">
          <mat-form-field appearance="standard">
            <mat-label>{{ "name" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="associationName"
              placeholder="{{ 'name' | translate }}..."
              required
            />
          </mat-form-field>
          <mat-form-field appearance="standard">
            <mat-label>{{ "description" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="associationDescription"
              placeholder="{{ 'description' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'dataManagementAssociationNew'"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="maxReg"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="associationName"
          [cupsFilters]="filters"
          [initialLockSelected]="true"
          (selectedDataUpdateFlag)="selectMapByTable()"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [data]="tableData"
        >
        </app-table-controller>

        <div class="meter-list-map">
          <app-map-controller
            [mapType]="mapType"
            [mapHeight]="500"
            [activateAllLayers]="activateAllLayers"
            (actionFlag)="selectTableByMap([$event])"
            [freeDrawEnabled]="true"
            (selectedDevices)="selectTableByMap($event)"
            [gatewaysData]="[]"
            [metersData]="mapData"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
