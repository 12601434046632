<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "entities-list" | translate }}</b>
        </h5>

        <!-- Botón añadir -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newAgrupation()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'entityList'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="tableMaxReg"
          [highlightRow]="tableHighlightRow"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="entityList"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
