// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../../../services/shared/DeviceRouteSelectorService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { GatewayAlarm } from "../../GatewayInterface.type";

@Component({
  selector: "app-alarm",
  templateUrl: "./gateway-alarms.component.html",
  styleUrls: ["./gateway-alarms.component.scss"],
})
export class GatewayAlarmsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("alarms-export") + " " + this.DateParserService.getDate();
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("6");
  gatewayId: number;
  unidadVenta: string;
  gatewayAlarms: GatewayAlarm[];
  orderBy: object = { attribute: "initDate", reverse: true };
  from: string;
  to: string;
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-device",
          tooltip: "show-detail",
          icon: "fas fa-tachometer-alt",
          visible: { attribute: null, rule: true },
          disabled: "deviceLinkDisabled",
        },
      ],
      visible: true,
    },
    {
      title: "serial-number",
      data: "contadorNroSerie",
      search: "contadorNroSerie",
      sort: "contadorNroSerie",
      visible: true,
    },
    {
      title: "start-date",
      data: "initDateParsed",
      search: "initDateParsed",
      sort: "initDate",
      date: true,
      visible: true,
    },
    {
      title: "end-date",
      data: "finalDateParsed",
      search: "finalDateParsed",
      sort: "finalDate",
      date: true,
      visible: true,
    },
    {
      title: "code",
      data: "codeText",
      search: "codeText",
      sort: "codeText",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GatewayController: GatewayControllerService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.GatewayController.getGatewayAlarms(
      this.gatewayId,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let gatewayAlarms: GatewayAlarm[] = response["body"];
          gatewayAlarms.forEach((gatewayAlarm: GatewayAlarm) => {
            gatewayAlarm.deviceLinkDisabled = gatewayAlarm.contador
              ? false
              : true;
            gatewayAlarm.codeText =
              gatewayAlarm.code != null
                ? this.translate.instant("GatewayAlarm" + gatewayAlarm.code)
                : "-";
          });
          this.gatewayAlarms = gatewayAlarms;
        } else {
          this.gatewayAlarms = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, gatewayAlarm: GatewayAlarm): void {
    switch (action) {
      case "show-device":
        this.DeviceRouteSelectorService.updateAgrupation(gatewayAlarm.agrupationId);
        this.DeviceRouteSelectorService.getDeviceRoute(
          gatewayAlarm.metrology_type,
          gatewayAlarm.contador
        );
        break;
      default:
        break;
    }
  }
}
