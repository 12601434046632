import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { ColorService } from "../../../services/shared/ColorService.service";
import { TOGGLE_COLORS } from "./material-toggle-colors";

@Component({
  selector: "app-material-toggle",
  templateUrl: "./material-toggle.component.html",
  styleUrls: ["./material-toggle.component.scss"],
})
export class MaterialToggleComponent implements OnInit, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() onColor: string;
  @Input() offColor: string;
  @Input() disabled: boolean;
  @Input() toggle: boolean;
  @Input() onText: string;
  @Input() offText: string;
  @Output() change = new EventEmitter<boolean>();
  @ViewChild("slideToggle") slideToggle: ElementRef;
  initialStatus: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private ColorService: ColorService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.initialStatus = this.toggle;
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit() {
    this.updateToggle();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/
  
  // Actualización de los estilos del toggle
  updateToggle() {
    this.slideToggle?.nativeElement?.style.setProperty(
      "--toggle-text",
      "'" + (this.toggle ? this.onText : this.offText) + "'"
    );

    this.slideToggle?.nativeElement?.style.setProperty(
      "--toggle-color",
      this.toggle
        ? this.ColorService.getHexColor(TOGGLE_COLORS[this.onColor])
        : this.ColorService.getHexColor(TOGGLE_COLORS[this.offColor])
    );

    this.slideToggle?.nativeElement?.style.setProperty(
      "--toggle-color-light",
      this.toggle
        ? this.ColorService.lightenDarkenColor(
            this.ColorService.getHexColor(TOGGLE_COLORS[this.onColor]),
            20
          )
        : this.ColorService.lightenDarkenColor(
            this.ColorService.getHexColor(TOGGLE_COLORS[this.offColor]),
            20
          )
    );

    this.slideToggle?.nativeElement?.style.setProperty(
      "--toggle-color-dark",
      this.toggle
        ? this.ColorService.lightenDarkenColor(
            this.ColorService.getHexColor(TOGGLE_COLORS[this.onColor]),
            -20
          )
        : this.ColorService.lightenDarkenColor(
            this.ColorService.getHexColor(TOGGLE_COLORS[this.offColor]),
            -20
          )
    );
  }
}
