import { Injectable } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { DateParserService } from "../../services/shared/DateParserService.service";
// Variables
import { GRAPH_CONFIG } from "./GRAPH_CONFIG";

@Injectable({
  providedIn: "root",
})
export class GraphOptionsService {
  constructor(
    private DateParserService: DateParserService,
    private translate: TranslateService
  ) {}

  // Asignación de las opciones concretas para la gráfica
  getDefaultHighchartsOptions(exportFileName?: string): Options {
    let highchartsOptions: Options = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    highchartsOptions.tooltip = {
      enabled: true,
      dateTimeLabelFormats: {
        day: "%e " + this.translate.instant("of") + " %B",
        hour: "%e " + this.translate.instant("of") + " %B, %H:%M",
        week:
          this.translate.instant("week") +
          " %d " +
          this.translate.instant("of") +
          " %B %H:%M",
      },
    };

    highchartsOptions.exporting = {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
      filename:
        (exportFileName
          ? exportFileName
          : this.translate.instant("graph-export")) +
        " " +
        this.DateParserService.getDate(),
    };

    return highchartsOptions;
  }
}
