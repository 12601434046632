import { Component, Input, OnInit } from "@angular/core";
// Servicios propios
import { EkConfigurationControllerService } from "../../../../../services/server/EkConfigurationController.service";
import { ekTestData } from "../../DeviceInterface.type";
import { TableDataColumn } from "../../../../../modules/table-module/TableInterface.type";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-ek280-test",
  templateUrl: "./ek280-test.component.html",
  styleUrls: ["./ek280-test.component.scss"],
})
export class Ek280TestComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  testData: ekTestData[];

  // Table
  columns: TableDataColumn[] = [
    {
      title: "date",
      data: "fechaHoraParsed",
      search: "fechaHoraParsed",
      sort: "fechaHora",
      date: true,
      visible: true,
    },
    {
      title: "serial-number",
      data: "numeroSerie",
      search: "numeroSerie",
      sort: "numeroSerie",
      visible: true,
    },
    {
      title: "ek-total-uncorrected-volume",
      data: "volumenSinCorregirTotal",
      search: "volumenSinCorregirTotal",
      sort: "volumenSinCorregirTotal",
      visible: true,
    },
    {
      title: "ek-total-corrected-volume",
      data: "volumenCorregidoTotal",
      search: "volumenCorregidoTotal",
      sort: "volumenCorregidoTotal",
      visible: true,
    },
    {
      title: "ek-total-energy",
      data: "energiaTotal",
      search: "energiaTotal",
      sort: "energiaTotal",
      visible: true,
    },
    {
      title: "ek-volume-uncorrected-error",
      data: "volumenSinCorregirError",
      search: "volumenSinCorregirError",
      sort: "volumenSinCorregirError",
      visible: true,
    },
    {
      title: "ek-total-corrected-volume-error",
      data: "volumenCorregidoTotalError",
      search: "volumenCorregidoTotalError",
      sort: "volumenCorregidoTotalError",
      visible: true,
    },
    {
      title: "ek-total-energy-error",
      data: "energiaErrorTotalError",
      search: "energiaErrorTotalError",
      sort: "energiaErrorTotalError",
      visible: true,
    },
    {
      title: "ek-pressure",
      data: "presion",
      search: "presion",
      sort: "presion",
      visible: true,
    },
    {
      title: "ek-temperature",
      data: "temperatura",
      search: "temperatura",
      sort: "temperatura",
      visible: true,
    },
    {
      title: "ek-factor-correction",
      data: "factorCorreccion",
      search: "factorCorreccion",
      sort: "factorCorreccion",
      visible: true,
    },
    {
      title: "ek-compressibility-factor-measurement",
      data: "factorCompresibilidadMedicion",
      search: "factorCompresibilidadMedicion",
      sort: "factorCompresibilidadMedicion",
      visible: true,
    },
    {
      title: "ek-base-compressibility-factor",
      data: "factorCompresibilidadBase",
      search: "factorCompresibilidadBase",
      sort: "factorCompresibilidadBase",
      visible: true,
    },
    {
      title: "ek-not-corrected-flow-total",
      data: "caudalSinCorregirTotal",
      search: "caudalSinCorregirTotal",
      sort: "caudalSinCorregirTotal",
      visible: true,
    },
    {
      title: "ek-corrected-flow-total",
      data: "caudalCorregidoTotal",
      search: "caudalCorregidoTotal",
      sort: "caudalCorregidoTotal",
      visible: true,
    },
    {
      title: "ek-state",
      data: "estado",
      search: "estado",
      sort: "estado",
      visible: true,
    },
    {
      title: "ek-gsm-reception-level",
      data: "nivelRecepcionGSM",
      search: "nivelRecepcionGSM",
      sort: "nivelRecepcionGSM",
      visible: true,
    },
    {
      title: "ek-tension-battery-modem",
      data: "tensionBateriaModem",
      search: "tensionBateriaModem",
      sort: "tensionBateriaModem",
      visible: true,
    },
    {
      title: "ek-remaining-battery-life",
      data: "tiempoRestanteVidaBateria",
      search: "tiempoRestanteVidaBateria",
      sort: "tiempoRestanteVidaBateria",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EkConfigurationController: EkConfigurationControllerService,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getTestData();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de la configuración
  getTestData(): void {
    this.EkConfigurationController.ekTest(this.data.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let testData = response["body"];
          this.testData = [testData];
        }
      }
    );
  }
}
