// @angular
import { Component, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
//Interfaces
import {
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import { GatewayCommand } from "../ManagementInterface.type";
// Componentes
import { ManagementCommandsTailDialogComponent } from "./management-commands-tail-dialog/management-commands-tail-dialog.component";

@Component({
  selector: "app-management-commands-tail",
  templateUrl: "./management-commands-tail.component.html",
  styleUrls: ["./management-commands-tail.component.scss"],
})
export class ManagementCommandsTailComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Gateways
  commandList: GatewayCommand[];

  // Table
  tableMaxReg: number = 100;
  columns: (TableSelectColumn | TableDataColumn)[] = [
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "gateway",
      data: "gateway",
      search: "gateway",
      sort: "gateway",
      visible: true,
    },
    {
      title: "device",
      data: "contador",
      search: "contador",
      sort: "contador",
      visible: true,
    },
    {
      title: "entity",
      data: "entityDes",
      search: "entityDes",
      sort: "entityDes",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupationDes",
      search: "agrupationDes",
      sort: "agrupationDes",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "command",
      data: "commandDes",
      search: "commandDes",
      sort: "commandDes",
      visible: true,
    },
    {
      title: "subcommand",
      data: "subcommandDes",
      search: "subcommandDes",
      sort: "subcommandDes",
      visible: true,
    },
  ];
  tableGlobalActions: TableGlobalAction[];

  // Relanzar cola
  gatewayList: GatewayCommand[];
  selectedCommands: GatewayCommand[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getCommandList();
  }

  // Obtención de la lista de gateways
  getCommandList(): void {
    let commandList: GatewayCommand[] = null;
    this.gatewayList = [];
    this.ManagementController.getCommandsTail().subscribe((response) => {
      commandList = [];
      if (response["code"] == 0) {
        commandList = response["body"];

        commandList.forEach((command: GatewayCommand) => {
          if (
            !this.gatewayList.find(
              (gateway: GatewayCommand) =>
                gateway.gatewayId == command.gatewayId
            )
          ) {
            this.gatewayList.push(command);
          }
        });
      }
      this.commandList = commandList;
      this.setTableGlobalActions();
    });
  }

  // Seteo de las acciones globales de la tabla
  setTableGlobalActions() {
    this.tableGlobalActions = [
      {
        title: "relaunch-tail",
        icon: "fas fa-redo-alt",
        disabled: this.gatewayList?.length == 0,
        help: "help-table-relaunch",
      },
      {
        title: "commands-delete-action",
        icon: "fas fa-trash",
        selectionRequired: true,
        class: "btn-red",
        help: "help-table-commands-delete",
      },
    ];
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "commands-delete-action":
        this.deleteTail();
        break;
      case "relaunch-tail":
        this.MaterialDialogService.openDialog(
          ManagementCommandsTailDialogComponent,
          this.gatewayList
        );
        break;
      default:
        break;
    }
  }

  // Borrado de comandos seleccionados de la cola
  deleteTail(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-tail")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.ManagementController.deleteCommands(
          this.selectedCommands.map((command: GatewayCommand) => command.id)
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("commands-deleted")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }
}
