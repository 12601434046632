<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "languages-management" | translate }}</b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>

        <input
          #mergeInput
          class="hidden"
          type="file"
          accept=".json"
          id="file"
          (click)="resetFile()"
          (change)="mergeFiles($event.target.files)"
          multiple
        />
      </div>

      <div class="panel-body">
        <div *ngIf="languagesReady; else elseBlock">
          <!-- Filtro -->
          <div class="management-language-filter">
            <span>{{ "filter-label" | translate }}:</span>
            <input
              type="text"
              [(ngModel)]="filterLabel"
              (keyup.enter)="labelActive = filterLabel"
            />
            <button
              mat-button
              class="mat-button-icon"
              (click)="labelActive = filterLabel"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>

          <!-- Filtros rápidos -->
          <div class="management-language-quickfilters">
            <span>{{ "filters-extra" | translate }}</span>
            <button
              *ngFor="let quickFilter of quickFilters"
              mat-button
              class="mat-button-icon-text"
              (click)="
                filterLabel = quickFilter.filter; labelActive = filterLabel
              "
            >
              <i [ngClass]="quickFilter.icon"></i>
              {{ quickFilter.text }}
            </button>
          </div>

          <!-- Etiquetas -->
          <div class="management-languages-label-title">
            <span>{{ "label" | translate }}</span>
            <span>{{ "language" | translate }}</span>
          </div>

          <!-- Traducciones -->
          <ng-container *ngFor="let label of labels; index as i">
            <div
              *ngIf="
                label?.includes(labelActive) ||
                alarmLabels[label]?.includes(labelActive)
              "
              class="management-language-label"
            >
              <span
                >{{ alarmLabels[label] ? alarmLabels[label] : label }}
                {{ label.includes("AlertDescription") ? "(desc)" : "" }}</span
              >
              <i
                class="management-languages-expand pointer"
                [ngClass]="
                  hideLanguages[i] ? 'fas fa-caret-right' : 'fas fa-caret-down'
                "
                (click)="hideLanguages[i] = !hideLanguages[i]"
              ></i>
              <div class="management-language-languages">
                <ng-container *ngFor="let language of langs">
                  <div
                    *ngIf="
                      language == 'es' || (language != 'es' && hideLanguages[i])
                    "
                  >
                    <span>{{
                      language != "es-ca" ? language.toUpperCase() : "CA"
                    }}</span
                    ><textarea
                      appTabInput
                      ng-trim="false"
                      type="text"
                      [(ngModel)]="languages[language][label]"
                    ></textarea></div
                ></ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
