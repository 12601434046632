// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { SimControllerService } from "../../../../services/server/SimController.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import { SimCard, SimResponse } from "../DeviceInterface.type";
import { ImportColumn } from "../../../../modules/table-module/TableInterface.type";
// Componentes
import { TableControllerComponent } from "../../../../modules/table-module/table-controller/table-controller.component";

@Component({
  selector: "app-device-sim-cards",
  templateUrl: "./device-sim-cards.component.html",
  styleUrls: ["./device-sim-cards.component.scss"],
})
export class DeviceSimCardsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Table
  rowNumbers: boolean = true;
  tableMaxReg: number = 20;
  simList: SimCard[];
  selectedSims: SimCard[];
  newSim: number;
  exportFileName: string =
    this.translate.instant("sim-cards") + " " + this.DateParserService.getDate();
  highlightRow: TableHighlightRow[] = [
    { condition: "updateFail", color: "red", title: "error-text" },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-eraser",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "ICCID",
      data: "iccid",
      search: "iccid",
      sort: "iccid",
      visible: true,
    },
    {
      title: "state",
      data: "estadoParsed",
      search: "estadoParsed",
      sort: "estadoParsed",
      visible: true,
    },
    {
      title: "IP",
      data: "ip",
      search: "ip",
      sort: "ip",
      visible: true,
    },
    {
      title: "sim-activation-date",
      data: "fechaActivacionParsed",
      search: "fechaActivacionParsed",
      sort: "fechaActivacion",
      date: true,
      visible: true,
    },
    {
      title: "sim-registration-date",
      data: "fechaRegistroParsed",
      search: "fechaRegistroParsed",
      sort: "fechaRegistro",
      date: true,
      visible: true,
    },
    {
      title: "sim-deletion-date",
      data: "fechaEliminacionParsed",
      search: "fechaEliminacionParsed",
      sort: "fechaEliminacion",
      date: true,
      visible: true,
    },
    {
      title: "type",
      data: "nombre",
      search: "nombre",
      sort: "nombre",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nserie",
      search: "nserie",
      sort: "nserie",
      visible: true,
    },
    {
      title: "entity",
      data: "entity",
      search: "entity",
      sort: "entity",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupation",
      search: "agrupation",
      sort: "agrupation",
      visible: true,
    },
  ];
  simStates: object = {
    1: this.translate.instant("sim-state-active"),
    2: this.translate.instant("sim-state-paired"),
    3: this.translate.instant("sim-state-registered"),
    4: this.translate.instant("sim-state-down"),
  };

  // Botón actualizar
  updateTitle: string = this.translate.instant("update-matooma");
  updating: boolean = false;

  // Archivo
  importColumns: ImportColumn[] = [
    { title: "ICCID", info: "ICCID", required: true, profile: true },
  ];

  // Tabla
  importResponseData: SimResponse[];
  showTable: boolean = false;
  importResponseDataColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "state",
      data: "state",
      search: "state",
      sort: "state",
      alter: {
        condition: "state",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "ICCID",
      data: "iccid",
      search: "iccid",
      sort: "iccid",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      extraInfo: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SimController: SimControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(simFails?: number[]): void {
    this.simList = [];

    this.SimController.getSimCards().subscribe((response) => {
      if (response["code"] == 0) {
        let simList = response["body"];
        simList.forEach((sim: SimCard) => {
          sim.estadoParsed = this.simStates[sim.estado]
            ? this.simStates[sim.estado]
            : this.translate.instant("unknown");
          sim.isPlum = sim.nombre == "plum";
          sim.nombre = sim.nombre?.toUpperCase();
          sim.updateFail = simFails?.length > 0 && simFails.includes(sim.id);
        });
        this.simList = simList;
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, sim: SimCard): void {
    switch (action) {
      case "delete":
        this.deleteSim(sim.id);
        break;
      case "edit":
        break;
      default:
        break;
    }
  }

  // Alta de nueva SIM
  registerSim(): void {
    this.SimController.saveSimCard(this.newSim).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("save"));
        this.ReloadComponentService.reload();
      }
    });
  }

  // Borrado de SIM
  deleteSim(simId: number): void {
    this.ToastService
      .fireAlertWithOptions(
        "warning",
        this.translate.instant("sim-delete-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.SimController.deleteSimCard(simId).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("sim-deleted")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }

  // Refresco de SIMs
  refreshSimCards(): void {
    this.updating = true;
    this.SimController
      .refreshSimCards(
        this.selectedSims.map((sim: SimCard) => {
          return sim.id;
        })
      )
      .subscribe((response) => {
        if (response["code"] == 0) {
          let simFails = response["body"];
          this.getData(simFails);
          this.tableController.resetRowSelection();
          let toastIcon = simFails?.length > 0 ? "warning" : "success";
          let toastText =
            simFails?.length > 0
              ? this.translate.instant("sim-refresh-error")
              : this.translate.instant("sim-refresh");
          this.ToastService.fireToast(toastIcon, toastText);
        }
        this.updating = false;
      });
  }

  // Importación de sims
  importSimCards(file: File) {
    let simFormData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file"),
        this.translate.instant("close")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("question-import")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            let importResponseData: SimResponse[] = [];
            simFormData.set("file", file);
            this.SimController.importSimCards(simFormData).subscribe((response) => {
              if (response["code"] == 0 || response["code"] == 1) {
                importResponseData = response["body"];
                importResponseData?.forEach((sim: SimResponse) => {
                  sim.responseCodeText = this.translate.instant(
                    "ekFileError" + sim.responseCode
                  );
                  if (sim.responseCodeText.includes("ekFileError")) {
                    sim.responseCodeText =
                      this.translate.instant("error-text") +
                      " " +
                      sim.responseCode;
                  }
                  sim.state = sim.responseCode == 0 ? true : false;
                });
              }

              if (response["code"] == 0) {
                this.ToastService.fireToastWithConfirmation(
                  "success",
                  this.translate.instant("success-import")
                );
              } else if (response["code"] == 1 && response["body"]) {
                this.ToastService.fireToastWithConfirmation(
                  "warning",
                  this.translate.instant("some-sim-error")
                );
              }

              this.showTable = importResponseData.length > 0 ? true : false;
              this.importResponseData = importResponseData;
              this.ReloadComponentService.reload();
            });
          }
        });
    }
  }
}
