// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import { Metrological } from "../../screens/dashboard/data-management/DataManagementInterface.type";

@Injectable({
  providedIn: "root",
})
export class MetrologicalControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Formulario
  /***************************************************************************/

  // Listado de las configuraciones metrológicas de una agrupación
  getMetrological(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/list/" + agrupationId
    );
  }

  // Configuración de metrológica de un dispositivo
  getDeviceMetrological(deviceId: number) {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/get/" + deviceId
    );
  }

  // Guardar/Actualizar la configuración metrológica de un dispositivo (Contador de gas) desde el formulario
  saveMetrological(data: Metrological): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/metrological-conf/save/",
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Listado
  /***************************************************************************/

  // Eliminar configuración metrológica asociada a un dispositivo
  deleteMetrological(metrologicalId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/delete/" + metrologicalId
    );
  }

  // Importación de un fichero de configuraciones metrológicas de dispositivos
  import(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/metrological-conf/import/" + entityId,
      data
    );
  }

  // Listado de las configuraciones metrológicas de una agrupación
  getList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/list/" + agrupationId
    );
  }
}
