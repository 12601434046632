<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "update-agrupations" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <div class="alarms-entity-agrupations">
      <app-material-select
        [filter]="true"
        [fullWidth]="true"
        [multiple]="true"
        [clearable]="true"
        [title]="'groups' | translate"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [data]="data.agrupations"
        (selectedOption)="data.selected = $event"
      ></app-material-select>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="updateAgrupations()"
    >
      <i class="fas fa-sync-alt"></i>
      {{ "update" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
