export const NAVBAR_SHORTCUTS = {
  Q: "/dispositivos/listado/asignados",
  W: "/dispositivos/listado/no-asignados",
  E: "/dispositivos/listado/desactivados",
  R: "/dispositivos/listado/interno",
  // T: "/dispositivos/listado/sustituciones",
  // Y: "/dispositivos/listado/revision",
  // U: "/dispositivos/listado/interno-gprs",
  // I: "/dispositivos/listado/sin-comunicacion",
  // O: "/dispositivos/listado/error-activacion",
  // P: "/dispositivos/listado/bateria-baja",
  A: "/gateways/listado/asignados",
  S: "/gateways/listado/disponibles",
  D: "/gateways/listado/en-stock",
  F: "/gateways/appeui",
  //   G: "",
  //   H: "",
  //   J: "",
  //   K: "",
  //   L: "",
  //   Ñ: "",
  //   Z: "",
  //   X: "",
  //   C: "",
  //   V: "",
  //   B: "",
  //   N: "",
  //   M: "",

  // Búsqueda inversa
  "/dispositivos/listado/asignados": "Q",
  "/dispositivos/listado/no-asignados": "W",
  "/dispositivos/listado/desactivados": "E",
  "/dispositivos/listado/interno": "R",
  // "/dispositivos/listado/sustituciones": "T",
  // "/dispositivos/listado/interno": "Y",
  // "/dispositivos/listado/interno-gprs": "U",
  // "/dispositivos/listado/sin-comunicacion": "I",
  // "/dispositivos/listado/error-activacion": "O",
  // "/dispositivos/listado/bateria-baja": "P",
  "/gateways/listado/asignados": "A",
  "/gateways/listado/disponibles": "S",
  "/gateways/listado/en-stock": "D",
  "/gateways/appeui": "F",
  // "": "G",
  // "": "H",
  // "": "J",
  // "": "K",
  // "": "L",
  // "": "Ñ",
  // "": "Z",
  // "": "X",
  // "": "C",
  // "": "V",
  // "": "B",
  // "": "N",
  // "": "M",
};
