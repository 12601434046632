// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import { ClientUpdate } from "../../interfaces/ClientGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class ClientControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Guardar un cliente
  saveClient(data: ClientUpdate): Observable<object> {
    return this.HttpRequestService.post("/client/save", data);
  }

  // Actualización de un cliente
  editClient(data: ClientUpdate): Observable<object> {
    return this.HttpRequestService.post("/client/update", data);
  }

  // Eliminar un cliente
  removeClient(clientId: number): Observable<object> {
    return this.HttpRequestService.get("/client/delete/" + clientId);
  }

  // Información formulario de actualizar un cliente
  getClient(clientId: number): Observable<object> {
    return this.HttpRequestService.get("/client/show/" + clientId);
  }

  // Listado de clientes
  getClientList(): Observable<object> {
    return this.HttpRequestService.get("/client/list-all");
  }
}
