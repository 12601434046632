// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { CupsControllerService } from "../../../../../services/server/CupsController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { DeviceUserLog } from "../../../devices/DeviceInterface.type";

@Component({
  selector: "app-meteruserlog",
  templateUrl: "./cups-log-user.component.html",
  styleUrls: ["./cups-log-user.component.scss"],
})
export class CupsUserLogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  sessionProfile: string;

  // Cups
  cups: string;
  id: number;
  entityCups: EntityDefinition;

  // Tabla
  tableData: DeviceUserLog[];
  tableSelectedData: DeviceUserLog[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  columns: (TableActionColumn | TableDataColumn)[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.entityCups = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    );

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Configuración de CUPS
    this.entityCupsConfSub = this.SessionDataService
      .getEntityCupsConf()
      .subscribe((currentEntityCupsConf) => {
        this.currentEntityCupsConf = currentEntityCupsConf;
        this.entityCups = this.currentEntityCupsConf?.find(
          (column: EntityDefinition) => column.colPosition == 0
        );
      });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.cups = history.state.data;
    this.id = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.setColumns();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obención de los datos de la tabla
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.CupsController.getCupsUserLog(this.id, from, to).subscribe(
      (response) => {
        if (response["code"] == 0) {
          if (response["body"]) {
            let userLogList: DeviceUserLog[] = response["body"];
            userLogList.forEach((log: DeviceUserLog) => {
              log.eventTypeParsed =
                log.eventType != null
                  ? this.translate.instant("UserEvent" + log.eventType)
                  : "-";
            });
            this.tableData = response["body"];
          } else {
            this.tableData = [];
          }
        }
      }
    );
  }

  // Seteo de las columnas
  setColumns(): void {
    this.columns = [
      {
        title: "sales-unit",
        data: "gwUnidadVenta",
        search: "gwUnidadVenta",
        sort: "gwUnidadVenta",
        visible: this.sessionProfile == "ARSON",
      },
      {
        title: "date",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "event",
        data: "eventTypeParsed",
        search: "eventTypeParsed",
        sort: "eventTypeParsed",
        visible: true,
      },
      {
        title: "details",
        data: "eventDetails",
        search: "eventDetails",
        sort: "eventDetails",
        visible: true,
      },
      {
        title: "command-response",
        data: "responseCommand",
        search: "responseCommand",
        sort: "responseCommand",
        visible: this.sessionProfile == "ARSON",
      },
    ];
  }
}
