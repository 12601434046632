export const MANUFACTURER_INDEX = {
  // Genérico
  GENERIC: 0,
  GENERIC_GENERIC: 0,
  GENERIC_UNE: 1,
  GENERIC_EXTERNAL: 2,
  GENERIC_MBUS: 3,
  // Conthidra
  CONTHIDRA: 1,
  CONTHIDRA_WATER: 0,
  CONTHIDRA_JANZ: 1,
  CONTHIDRA_RF_JANZ: 2,
  CONTHIDRA_JANZ_MYWATER_2: 3,
  CONTHIDRA_RF_JANZ_MYWATER_2: 4,
  CONTHIDRA_MBUS: 5,
  CONTHIDRA_MBUS_OMS: 6,
  CONTHIDRA_EREGISTER: 7,
  // Fidegas
  FIDEGAS: 2,
  FIDEGAS_D195i: 0,
  FIDEGAS_BP_D195i: 1,
  FIDEGAS_CO_D195i: 2,
  FIDEGAS_CO_NAT_MC: 3,
  FIDEGAS_CO_NAT_D30X: 4,
  // Axioma
  AXIOMA: 3,
  AXIOMA_QALCOSONIC_W1: 0,
  AXIOMA_QALCOSONIC_W1_923: 1,
  AXIOMA_QALCOSONIC_W1_MBUS: 2,
  AXIOMA_QALCOSONIC_W1_MBUS_LORA: 3,
  // Diehl
  DIEHL: 4,
  DIEHL_HRLc_G3: 0,
  DIEHL_RF_HRLc_G3: 1,
  DIEHL_IZAR_R3_4: 2,
  DIEHL_HRLc_G3_V4: 3,
  DIEHL_RF_HRLc_G3_V4: 4,
  DIEHL_HRLc_G3_V4_2: 5,
  DIEHL_RF_HRLc_G3_V4_2: 6,
  DIEHL_NBIoT: 7,
  // Honeywell
  HONEYWELL: 5,
  HONEYWELL_MERLIN_HB_G3: 0,
  HONEYWELL_RF_MERLIN_HB_G3: 1,
  HONEYWELL_V200H: 2,
  HONEYWELL_MERLIN_HB_G3_MBUS_LORA: 3,
  HONEYWELL_WAVENIS: 4,
  HONEYWELL_UNE: 5,
  HONEYWELL_MBUS_ELS: 6,
  HONEYWELL_BK_G4_E: 7,
  HONEYWELL_MERLIN_MBUS: 8,
  // Itron
  ITRON: 6,
  ITRON_CYBLE4IoT: 0,
  ITRON_RF_CYBLE4IoT: 1,
  ITRON_CYBLE5IoT: 2,
  ITRON_RF_CYBLE5IoT: 3,
  ITRON_INTELIS_WSOURCE: 4,
  ITRON_MBUS_ITW: 5,
  ITRON_CYBLE5_LW_MBUS: 6,
  ITRON_RF_CYBLE5_LW_MBUS: 7,
  ITRON_MERLIN_MBUS: 8,
  // Arson
  ARSON: 7,
  ARSON_LBEP: 0,
  ARSON_METANO_D195i: 1,
  ARSON_BP_D195i: 2,
  ARSON_CO_D195i: 3,
  ARSON_CO_NAT_MC_LORA: 4,
  ARSON_CO_NAT_D30X_LW: 5,
  ARSON_DEMO_METER: 6,
  // Sagemcom
  SAGEMCOM: 8,
  SAGEMCOM_SICONIA_WM15L: 0,
  SAGEMCOM_RF_SICONIA_WM15L: 1,
  SAGEMCOM_EF4EVO: 2,
  SAGEMCOM_EF4EVO_MBUS: 3,
  SAGEMCOM_GAS_GLP: 4,
  SAGEMCOM_SICONIA_WM_NBIoT: 5,
  SAGEMCOM_EF4EVO_MBUS_NBIoT: 6,
  // Sensing Labs
  SENSINGLABS: 9,
  SENSINGLABS_SENLAB_M: 0,
  SENSINGLABS_RF_SENLAB_M: 1,
  // G2 Misuratori
  G2MISURATORI: 10,
  G2MISURATORI_G2_IPS_CLW: 0,
  // Externo
  EXTERNAL: 11,
  EXTERNAL_SECTORIAL_1: 0,
  EXTERNAL_HERMES: 1,
  // Aiut
  AIUT: 12,
  AIUT_APULSEx35F5_GAS: 0,
  AIUT_RF_APULSEx35F5_GAS: 1,
  AIUT_ALEVEL_1F5: 2,
  // Zhong Yi
  ZHONGYI: 13,
  ZHONGYI_G4_0: 0,
  ZHONGYI_MIG_4: 1,
  // Zenner
  ZENNER: 14,
  ZENNER_EDC: 0,
  ZENNER_RF_EDC: 1,
  ZENNER_IUW: 2,
  // Arson Metering
  ARSONMETERING: 15,
  ARSONMETERING_LW_UNE: 0,
  ARSONMETERING_SATELITE: 1,
  ARSONMETERING_LW_MBUS: 2,
  ARSONMETERING_OWASYS: 3,
  ARSONMETERING_N1: 4,
  ARSONMETERING_N3: 5,
  ARSONMETERING_N4: 6,
  // Integra
  INTEGRA: 16,
  INTEGRA_TOPAS_SONIC: 0,
  // ERM
  ERM: 17,
  ERM_ERMNRTG: 0,
  // Beta
  BETA: 18,
  BETA_WG4: 0,
  // Kromschroeder
  KROMSCHROEDER: 19,
  KROMSCHROEDER_EK280: 0,
  // Contazara
  CONTAZARA: 20,
  CONTAZARA_UNE: 0,
  // PLUM
  PLUM: 21,
  PLUM_MACR6: 0,
  PLUM_MACR6_NBIOT: 1,
  // APATOR
  APATOR: 22,
  APATOR_UG_G4: 0,
  // BMeter
  BMETER: 23,
  BMETER_RFM_LR3_4_CLIPON: 0,
  BMETER_RFM_LR3_4: 1,
  BMETER_HYDRODIGIT: 2,
  BMETER_HYDRODIGIT_COMBO: 3,
  // Hidroconta
  HIDROCONTA: 24,
  HIDROCONTA_WATER: 0,
  HIDROCONTA_RFM_IRIS: 1,
  // AIMEI
  AIMEI: 25,
  AIMEI_ULTRASONIC_WATER_868: 0,
  AIMEI_ULTRASONIC_WATER_923: 1,
  AIMEI_TK_L_EU868: 2,
  AIMEI_TK_L_AS923: 3,
  AIMEI_RF_TK_L_EU868: 4,
  AIMEI_RF_TK_L_AS923: 5,
  // SAPPEL
  SAPPEL: 27,
  SAPPEL_IZAR_R3_4: 1,
  // VIEWSHINE
  VIEWSHINE: 26,
  VIEWSHINE_U_WR2: 0,
  // AIMEI
  KAMSTRUP: 28,
  KAMSTRUP_FLOWIQ2200: 0,
  KAMSTRUP_MULTICAL_MBUS: 1,
  KAMSTRUP_MULTICAL_LW: 2,
  KAMSTRUP_MULTICAL_NBIoT: 3,
  // ADD ENPROTECH
  ADDENPROTECH: 29,
  ADDENPROTECH_WM: 0,
  ADDENPROTECH_WM_VALVE: 1,
  // Sewerin
  SEWERIN: 30,
  SEWERIN_LEAK_DETECTOR: 0,
  // GWF
  GWF: 31,
  SONICO_NANO: 0,
  // CONSTRAT
  CONSTRAT: 32,
  CONSTRAT_LSV_ZY_SMART: 0,
  CONSTRAT_AQUAGUARD: 1,
};
