import { Injectable } from "@angular/core";
import { SessionDataService } from "./SessionDataService.service";
import { Client } from "../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../interfaces/AgrupationGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class NavigationHelperService {
  constructor(private SessionDataService: SessionDataService) {}

  // Búsqueda de entidad en el cliente en curso
  findEntityInCurrentClient(attribute: string, value: string | number): Entity {
    return this.SessionDataService.getCurrentEntityList().find((entity: Entity) => {
      if (attribute == "id") {
        return entity.id == value;
      } else {
        return entity.entity == value;
      }
    });
  }

  // Búsqueda de entidad en todos los clientes
  findEntityInAllClients(
    attribute: string,
    value: string | number
  ): { client: Client; entity: Entity } {
    let entity: Entity;
    let client = this.SessionDataService.getCurrentClientList().find((client: Client) => {
      entity = client.entityList.find((entity: Entity) => {
        if (attribute == "id") {
          return entity.id == value;
        } else {
          return entity.entity == value;
        }
      });
      if (entity) {
        return true;
      }
    });
    return { client: client, entity: entity };
  }

  // Búsqueda de agrupación en la entidad en curso
  findAgrupationInCurrentEntity(
    attribute: string,
    value: string | number
  ): Agrupation {
    return this.SessionDataService
      .getCurrentEntity()
      .agrupations.find((agrupation: Agrupation) => {
        if (attribute == "id") {
          return agrupation.id == value;
        } else {
          return agrupation.name == value;
        }
      });
  }

  // Búsqueda de agrupación en todas las entidades del cliente
  findAgrupationInAllEntities(
    attribute: string,
    value: string | number
  ): { entity: Entity; agrupation: Agrupation } {
    let agrupation: Agrupation;
    let entity = this.SessionDataService.getCurrentEntityList().find((entity: Entity) => {
      agrupation = entity.agrupations.find((agrupation: Agrupation) => {
        if (attribute == "id") {
          return agrupation.id == value;
        } else {
          return agrupation.name == value;
        }
      });
      if (agrupation) {
        return true;
      }
    });
    return { entity: entity, agrupation: agrupation };
  }

  // Búsqueda de agrupación en todas las entidades de todos los clientes
  findAgrupationInAllClients(
    attribute: string,
    value: string | number
  ): { client: Client; entity: Entity; agrupation: Agrupation } {
    let agrupation: Agrupation;
    let entity: Entity;
    let client = this.SessionDataService.getCurrentClientList().find((client: Client) => {
      entity = client.entityList.find((entity: Entity) => {
        agrupation = entity.agrupations.find((agrupation: Agrupation) => {
          if (attribute == "id") {
            return agrupation.id == value;
          } else {
            return agrupation.name == value;
          }
        });
        if (agrupation) {
          return true;
        }
      });
      if (entity) {
        return true;
      }
    });

    return { client: client, entity: entity, agrupation: agrupation };
  }
}
