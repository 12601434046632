import { Component } from '@angular/core';

@Component({
  selector: 'app-water-effect',
  templateUrl: './water-effect.component.html',
  styleUrls: ['./water-effect.component.scss']
})
export class WaterEffectComponent {

}
