import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { DetailDevice } from "../../DeviceInterface.type";
import { DeviceReplaceData } from "./device-replace-dialog-interface";
// Componentes
import { MeterSubstitutionDialogComponent } from "../../meter/meter-list/meter-substitutions/meter-substitution-dialog/meter-substitution-dialog.component";

@Component({
  selector: "app-device-replace-dialog",
  templateUrl: "./device-replace-dialog.component.html",
  styleUrls: ["./device-replace-dialog.component.scss"],
})
export class DeviceReplaceDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: { meter: DetailDevice; type: string };
  replaceData: DeviceReplaceData;
  replaceId: number;
  replaceDate: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.replaceData = {
      entity: this.SessionDataService.getCurrentEntity().entity,
      agrupation: this.SessionDataService.getCurrentAgrupation().name,
      nroSerieOld: this.data.meter.nroSerie,
      nroSerieNew: null,
    };

    if (this.data.type == "all") {
      this.replaceData.latitude = this.data.meter.latitude;
      this.replaceData.longitude = this.data.meter.longitude;
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Activar contador
  replaceMeter(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("replace-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        if (this.replaceDate) {
          this.replaceData.timestamp =
            this.DateParserService.getInputDateTimestamp(
              this.replaceDate,
              null,
              "DD/MM/YYYY HH:mm"
            );
        }
        let request =
          this.data.type == "all"
            ? this.MeterController.replaceFullDevice(this.replaceData)
            : this.MeterController.replaceOnlyMeter(this.replaceData);
        request.subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("replace-device-sucessfull")
            );
            this.replaceId = response["body"];
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
            this.MaterialDialogService.openDialog(
              MeterSubstitutionDialogComponent,
              {
                show: "images",
                substitution: {
                  id: this.replaceId,
                  nroSerieNew: this.replaceData.nroSerieNew,
                  nroSerieOld: this.replaceData.nroSerieOld,
                },
                oldImage: null,
                newImage: null,
              }
            );
          }
        });
      }
    });
  }
}
