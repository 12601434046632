import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";

// Directives
import { DirectivesModule } from "../../directives/directives.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

// Graph
import { GraphModule } from "../graph-module/graph.module";

// Cards
import { CardsControllerComponent } from "./cards-controller/cards-controller.component";
import { CardComponent } from "./card/card.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,

    // Directives
    DirectivesModule,

    // Graph
    GraphModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [CardsControllerComponent, CardComponent],
  exports: [CardsControllerComponent, CardComponent],
})
export class CardModule {}
