<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "ek-file-configuration" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <h5 class="ek-windows-warning">
          <i class="fas fa-exclamation-triangle"></i>
          <b>{{ "ek-windows-warning" | translate }}</b>
          <i class="fas fa-exclamation-triangle"></i>
        </h5>

        <!-- Ventana 1 -->
        <div class="form-group ek-config-window">
          <h4>{{ "window" | translate }} 1</h4>
          <div>
            <label for="firstWindow">{{ "send-time" | translate }}:</label>
            <input
              [(ngModel)]="windows.hora1"
              type="time"
              id="firstWindow"
              name="firstWindow"
              placeholder="HH:mm:ss"
              required
            />
          </div>
        </div>
        <!-- Ventana 2 -->
        <div class="form-group ek-config-window">
          <h4>{{ "window" | translate }} 2</h4>
          <div>
            <label for="secondWindow">{{ "send-time" | translate }}:</label>
            <input
              [(ngModel)]="windows.hora2"
              type="time"
              id="secondWindow"
              name="secondWindow"
              placeholder="HH:mm:ss"
              required
            />
          </div>
        </div>
        <!-- Ventana 3 -->
        <div class="form-group ek-config-window">
          <h4>{{ "window" | translate }} 3</h4>
          <div>
            <label for="thirdWindow">{{ "send-time" | translate }}:</label>
            <input
              [(ngModel)]="windows.hora3"
              type="time"
              id="thirdWindow"
              name="thirdWindow"
              placeholder="HH:mm:ss"
              required
            />
          </div>
        </div>

        <!-- Guardar -->
        <div class="ek-file-config-buttons">
          <button type="button" class="btn btn-primary" click="updateWindows()">
            <i class="fas fa-save"></i>
            {{ "save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
