// @angular
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
// Componentes
import { TableControllerComponent } from "../../../../modules/table-module/table-controller/table-controller.component";
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import {
  TableSelectColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import {
  WARNINGS,
  WarningData,
  WarningType,
} from "../ManagementInterface.type";

@Component({
  selector: "app-management-global-warnings",
  templateUrl: "./management-global-warnings.component.html",
  styleUrls: ["./management-global-warnings.component.scss"],
})
export class ManagementGlobalWarningsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tabla
  exportFileName: string =
    this.translate.instant("incidents") +
    " " +
    this.DateParserService.getDate();
  tableSelectedData: WarningData[];
  tableData: WarningData[];
  tableMaxReg: number = 20;
  columns: (TableSelectColumn | TableDataColumn)[];
  highlightRow: object[] = [
    { condition: "newWarning", color: "red", title: "incidents-new" },
  ];
  warningTypes: WarningType[] = [
    // Completo
    {
      name: this.translate.instant("all"),
      value: "all",
      types: null,
    },
    // Entidad
    {
      name: this.translate.instant("entity"),
      value: "entity",
      types: [WARNINGS.ENTITY_GATEWAYS],
    },
    // Operadora
    {
      name: this.translate.instant("operator"),
      value: "operator",
      types: [WARNINGS.OPERATOR_GATEWAYS],
    },
    // Gateways
    {
      name: this.translate.instant("gateways"),
      value: "gateways",
      types: [],
    },
    // Contadores
    {
      name: this.translate.instant("meters"),
      value: "meters",
      types: [WARNINGS.METERS_JOIN_NOT_SUCCESS],
    },
  ];
  selectorWarningTypes: WarningType[];
  warningTypeSelected: WarningType;
  warnings: WarningData[];
  entityWarningsTotal: number;
  meterWarningsTotal: number;
  gatewayWarningsTotal: number;
  operatorWarningsTotal: number;
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Actualizar
  prevWarnings: WarningData[];
  refreshTimer: number = 5; // Minutos
  refreshInterval: any;

  // Mapa
  mapType: string = "warnings";
  mapHeight: number = 400;
  devices: any[];
  gateways: any[];
  mapDevices: any[];
  mapGateways: any[];
  @ViewChild(MapControllerComponent)
  mapController: MapControllerComponent;
  activateAllLayers;
  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    public ToastService: ToastService,
    public translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getWarnings();
    // this.updateWarningsRefresh();
  }

  // Obtención de los datos de alarmas
  getWarnings(): void {
    this.prevWarnings = this.warnings
      ? JSON.parse(JSON.stringify(this.warnings))
      : null;
    this.entityWarningsTotal = 0;
    this.operatorWarningsTotal = 0;
    this.meterWarningsTotal = 0;
    this.gatewayWarningsTotal = 0;
    this.gateways = [];
    this.devices = [];
    this.AlarmController.getGlobalWarnings().subscribe((response) => {
      let warnings: WarningData[] = [];
      if (response["code"] == 0) {
        warnings = response["body"];
        warnings.forEach((warning: WarningData) => {
          warning.typeParsed = this.translate.instant(
            "warnings" + warning.type
          );
          this.processWarningType(warning);
          if (this.prevWarnings) {
            this.checkNewWarnings(warning);
          }
        });
      }
      this.warnings = warnings;
      this.mapDevices = [...this.devices];
      this.mapGateways = [...this.gateways];
      this.setSelectorWarningTypes();
      this.getTableData();
      this.setColumns();
      this.getMapData();
    });
  }

  // Procesado de sospecha según tipo
  processWarningType(warning: WarningData): void {
    switch (warning.type) {
      // Total de sospechas de entidad
      case WARNINGS.ENTITY_GATEWAYS:
        warning.selectDisabled = true;
        this.entityWarningsTotal++;
        break;
      // Total de sospechas de operadora
      case WARNINGS.OPERATOR_GATEWAYS:
        warning.selectDisabled = true;
        this.operatorWarningsTotal++;
        break;
      // Total de sospechas de contadores
      case WARNINGS.METERS_JOIN_NOT_SUCCESS:
        this.meterWarningsTotal++;
        // Contadores del mapa
        if (warning.meter != null) {
          warning.link = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            warning.metrology,
            warning.meter
          );
          this.devices.push({
            id: warning.meter,
            nroSerie: warning.nroSerie,
            latitude: warning.mtLatitude,
            longitude: warning.mtLongitude,
            warning: warning.type,
          });
        }
        break;
      // Total de sospechas de gateways
      case WARNINGS.METERS_JOIN_NOT_SUCCESS:
        this.gatewayWarningsTotal++;
        // Gateways del mapa
        if (warning.gateway != null) {
          warning.link = "/gateways/detalle/gateway/" + warning.gateway;
          this.gateways.push({
            id: warning.gateway,
            unidadVenta: warning.unidadVenta,
            latitude: warning.gwLatitude,
            longitude: warning.gwLongitude,
            warning: warning.type,
          });
        }
        break;
      default:
        break;
    }
  }

  // Obtención de los datos de la tabla acorde a tipo de sospechas
  getTableData(): void {
    if (this.warningTypeSelected.value != "all") {
      this.tableData = this.warnings.filter((warning: WarningData) =>
        this.warningTypeSelected.types.includes(warning.type)
      );
    } else {
      this.tableData = [...this.warnings];
    }
  }

  // Obtención de los datos del mapa acorde a tipo de sospechas
  getMapData(): void {
    if (
      this.warningTypeSelected.value == "all" ||
      this.warningTypeSelected.value == "gateways"
    ) {
      this.mapGateways = [...this.gateways];
    } else {
      this.mapGateways = [];
    }
    if (
      this.warningTypeSelected.value == "all" ||
      this.warningTypeSelected.value == "meters"
    ) {
      this.mapDevices = [...this.devices];
    } else {
      this.mapDevices = [];
    }
    this.activateAllLayers = !this.activateAllLayers;
  }

  // Seteo de los tipos de sospechas con totales
  setSelectorWarningTypes(): void {
    this.warningTypes[0].name += " (" + this.warnings.length + ")";
    this.warningTypes[1].name += " (" + this.entityWarningsTotal + ")";
    this.warningTypes[2].name += " (" + this.operatorWarningsTotal + ")";
    this.warningTypes[3].name += " (" + this.gatewayWarningsTotal + ")";
    this.warningTypes[4].name += " (" + this.meterWarningsTotal + ")";
    this.selectorWarningTypes = this.warningTypes;
    this.warningTypeSelected = this.warningTypes[0];
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible:
          (this.warningTypeSelected.value == "all" &&
            (this.gateways.length > 0 || this.devices.length > 0)) ||
          this.warningTypeSelected.value == "gateways" ||
          this.warningTypeSelected.value == "meters"
            ? true
            : null,
      },
      {
        title: "sales-unit",
        data: "unidadVenta",
        search: "unidadVenta",
        sort: "unidadVenta",
        link: "link",
        linkCheck: {
          condition: "entity",
          attribute: "entity",
          check: "id",
        },
        visible:
          (this.warningTypeSelected.value == "all" &&
            this.gateways.length > 0) ||
          this.warningTypeSelected.value == "gateways"
            ? true
            : null,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        link: "link",
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupation",
          check: "id",
        },
        visible:
          (this.warningTypeSelected.value == "all" &&
            this.devices.length > 0) ||
          this.warningTypeSelected.value == "meters"
            ? true
            : null,
      },
      {
        title: "entity",
        data: "entityDescription",
        search: "entityDescription",
        sort: "entityDescription",
        visible: true,
      },
      {
        title: "groups",
        data: "agrupationDescription",
        search: "agrupationDescription",
        sort: "agrupationDescription",
        visible:
          (this.warningTypeSelected.value == "all" &&
            this.devices.length > 0) ||
          this.warningTypeSelected.value == "meters"
            ? true
            : null,
      },
      {
        title: "operator",
        data: "operadoraDescription",
        search: "operadoraDescription",
        sort: "operadoraDescription",
        visible:
          (this.warningTypeSelected.value == "all" &&
            this.operatorWarningsTotal > 0) ||
          this.warningTypeSelected.value == "operator"
            ? true
            : null,
      },
      {
        title: "date",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "type",
        data: "typeParsed",
        search: "typeParsed",
        sort: "typeParsed",
        visible: true,
      },
    ];
  }

  // Actualización de mapa por selección en tabla
  updateMap(): void {
    this.devices.map((device) => {
      device.selected = this.warnings.some(
        (warning) => warning.meter == device.id && warning.selected
      );
    });
    this.gateways.map((gateway) => {
      gateway.selected = this.warnings.some(
        (warning) => warning.gateway == gateway.id && warning.selected
      );
    });
    this.mapController?.updateSelected();
  }

  // Obtención de avisos
  updateWarningsRefresh(): void {
    clearInterval(this.refreshInterval);
    this.refreshInterval = setInterval(() => {
      this.getWarnings();
    }, this.refreshTimer * 60 * 1000);
  }

  // Comprobación de si existen nuevas alarmas
  checkNewWarnings(warning: WarningData) {
    if (
      this.prevWarnings?.find(
        (prevWarning: WarningData) => prevWarning.id == warning.id
      )
    ) {
      warning.newWarning = false;
    } else {
      warning.newWarning = true;
    }
  }
}
