// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// File Saver
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  GatewayTension,
  GatewayVoltage,
  GatewayWinterMode,
  LoadSettings,
} from "../../GatewayInterface.type";

@Component({
  selector: "app-gatewayconfigure",
  templateUrl: "./gateway-configure.component.html",
  styleUrls: ["./gateway-configure.component.scss"],
})
export class GatewayConfigureComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;

  // Gateway
  gatewayId: number;
  unidadVenta: string;

  // Opciones del modo invierno
  dataUpKnown: boolean = true;
  dataUpUnknown: boolean = true;
  jr_main_known: boolean = true;
  jr_network_known: boolean = true;
  jr_unknown: boolean = true;
  propietary: boolean = true;
  alarms: boolean = true;
  file: boolean = true;

  // Opciones de dirección de servidor
  environtment: string;
  environtmentList: object[] = [
    { id: 0, descripcion: "Desarrollo" },
    { id: 1, descripcion: "Producción" },
    { id: 2, descripcion: "iGota" },
  ];
  formatGw: boolean = true;
  generateGwFile: boolean = true;

  // Opciones de calibración
  tension: GatewayTension;
  voltage: number;
  gatewayIp: { server: string; ip: string; port: string };

  // Opciones de ajuste de carga
  loadSetings: LoadSettings = {
    tensionMaximaCarga: null,
    tensionMantenimiento: null,
    corrienteMaximaCarga: null,
    umbralMantenimiento: null,
    umbralCargaRapida: null,
  };

  // Operadores
  operatorList: string[] = [];
  selectedOperator: string;
  currentOperators: { country: string | null; plmns: string | null }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.getOperatorList();
  }

  // Borrar datalogger
  deleteDatalogger(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("log-remove-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.removeDatalogger(this.gatewayId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("log-remove-sucessful")
              );
            }
          }
        );
      }
    });
  }

  // Borrar tramas
  deleteFrames(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("frames-remove-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.removeFrames(this.gatewayId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("frames-remove-sucessful")
              );
            }
          }
        );
      }
    });
  }

  // Guardar
  save(onlyIp?: boolean): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("configurate-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = {
          generateGwFile: onlyIp ? false : this.generateGwFile,
          formatGw: onlyIp ? false : this.formatGw,
          environtment: this.environtment,
        };
        this.GatewayController.configureGateway(this.gatewayId, data).subscribe(
          (response) => {
            if (this.generateGwFile == true) {
              if (response != null && response["code"] != 1000) {
                saveAs(
                  response,
                  this.unidadVenta +
                    " " +
                    this.translate.instant("gateway-file") +
                    " " +
                    moment().format("L") +
                    ".csv"
                );
                this.ToastService.fireToast(
                  "info",
                  this.translate.instant("gateway-deleted")
                );
              } else {
                this.ToastService.fireToast(
                  "error",
                  this.translate.instant("not-csv-download")
                );
              }
            } else {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("success-gateway-configure")
                );
              }
            }
          }
        );
      }
    });
  }

  // Calibración
  calibration(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("calibrate-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: GatewayVoltage = {
          voltage: this.voltage,
          gateway: this.gatewayId,
        };
        this.GatewayController.voltaje(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("success-gateway-calibration")
            );
          }
        });
      }
    });
  }

  // Obtención de la tensión
  showTension(): void {
    this.GatewayController.showTension(this.gatewayId).subscribe((response) => {
      if (response["code"] == 0) {
        this.tension = response["body"];
      }
    });
  }

  // Obtención de la ip
  showIp(): void {
    this.GatewayController.showIp(this.gatewayId).subscribe((response) => {
      if (response["code"] == 0) {
        this.gatewayIp = { server: null, ip: null, port: null };
        this.gatewayIp.ip = response["body"]?.serverIpPort
          ?.split(",")[0]
          ?.replace(/"/g, "");
        this.gatewayIp.port = response["body"]?.serverIpPort?.split(",")[1];
        this.gatewayIp.server = response["body"]?.environment;
      }
    });
  }

  // Lectura de opciones de invierno
  readWinter(): void {
    this.GatewayController.winterRead(this.gatewayId).subscribe((response) => {
      if (response["code"] == 0) {
        this.dataUpKnown = response["body"]["dataUpsConocidos"];
        this.dataUpUnknown = response["body"]["dataUpsMicError"];
        this.jr_main_known = response["body"]["joinRequestPrincipalConocidos"];
        this.jr_network_known =
          response["body"]["joinRequestRedundanteConocidos"];
        this.jr_unknown = response["body"]["joinRequestDesconocidos"];
        this.propietary = response["body"]["propietaryRequestData"];
        this.alarms = response["body"]["alarmData"];
        this.file = response["body"]["dataloggerContadores"];
      }
    });
  }

  // Actualización de opciones de invierno
  updateWinter(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("update-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: GatewayWinterMode = {
          dataUpsConocidos: this.dataUpKnown,
          dataUpsMicError: this.dataUpUnknown,
          joinRequestPrincipalConocidos: this.jr_main_known,
          joinRequestRedundanteConocidos: this.jr_network_known,
          joinRequestDesconocidos: this.jr_unknown,
          propietaryRequestData: this.propietary,
          alarmData: this.alarms,
          dataloggerContadores: this.file,
        };
        this.GatewayController.winterUpdate(this.gatewayId, data).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("success-winter-update")
              );
            }
          }
        );
      }
    });
  }

  // Lectura de carga
  readChage(): void {
    this.GatewayController.chargeRead(this.gatewayId).subscribe((response) => {
      if (response["code"] == 0) {
        this.loadSetings = response["body"]?.chargeConfiguration;
      }
    });
  }

  // Actualizar carga
  updateChage(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("update-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.chargeUpdate(
          this.gatewayId,
          this.loadSetings
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("success-charge-update")
            );
          }
        });
      }
    });
  }

  // Formateo de la memoria del gateway
  formatMemory(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("gateway-format-memory-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.formatMemory(this.gatewayId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("gateway-memory-formatted")
              );
            }
          }
        );
      }
    });
  }

  // Guardar configuración de operador
  saveOperatorConfig(): void {
    this.GatewayController.saveBackupOperators(
      this.gatewayId,
      this.selectedOperator
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.currentOperators = null;
        this.ToastService.fireToast("success", this.translate.instant("saved"));
      }
    });
  }

  // Obtención del listado de operadores
  getOperatorList(): void {
    this.GatewayController.getBackupOperators().subscribe((response) => {
      if (response["code"] == 0) {
        this.operatorList = response["body"];
      }
    });
  }

  // Leer operador
  readOperator(): void {
    this.currentOperators = [];
    this.GatewayController.readBackupOperators(this.gatewayId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let operators = response["body"];
          operators.forEach(
            (operator: { country: string; plmns: string[] }) => {
              this.currentOperators.push({
                country: operator?.country,
                plmns: operator?.plmns?.join(", "),
              });
            }
          );
        }
      }
    );
  }
}
