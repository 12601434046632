export const LAST_PATCH_NOTES_USER = {
  es: `### Versión 5.40 (14/10/2024)

* Usuario -> Formulario:
  - Corregido control de guardado para que no se permita salvar los cambios si alguno de los campos requeridos no está rellenado.
* CUPS -> Detalle:
  - Corregido un error por el que no se cargaba la gráfica de consumo si se había sustituido un contador del CUPS.
* Dispositivo -> Nuevo:
  - Corregido un error por el que no se cargaba automáticamente fabricante/modelo para OWASYS en precarga.
* Dispositivo -> Detalle:
  - Añadido resalte de fugas en gráfica de consumo.
`,
  en: `### Version 5.40 (14/10/2024)

`,
  "es-ca": null,
  fr: null,
  it: null,
  el: null,
};
