import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";
import { fromEvent, merge, of, Subscription, timer } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Directive({
  selector: "[appLongPress]",
})
export class LongPressDirective implements OnDestroy {
  private eventSubscribe: Subscription;
  @Input() threshold: number;

  @Output() mouseLongPress = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const MOUSE_DOWN = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      "mousedown"
    ).pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map(() => true) // turn on threshold counter
    );
    const TOUCH_START = fromEvent(elementRef.nativeElement, "touchstart").pipe(
      map(() => true)
    );
    const TOUCH_END = fromEvent(elementRef.nativeElement, "touchend").pipe(
      map(() => false)
    );
    const MOUSE_UP = fromEvent<MouseEvent>(window, "mouseup").pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map(() => false) // reset threshold counter
    );
    this.eventSubscribe = merge(MOUSE_DOWN, MOUSE_UP, TOUCH_START, TOUCH_END)
      .pipe(
        switchMap((state) =>
          state ? timer(this.threshold ? this.threshold : 500, 100) : of(null)
        ),
        filter((value) => value != null)
      )
      .subscribe(() => this.mouseLongPress.emit());
  }

  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
    }
  }
}
