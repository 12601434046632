// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class LoginControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  login(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/login/", data);
  }

  getVersions(): Observable<object> {
    return this.HttpRequestService.get("/im-alive/version/");
  }
}
