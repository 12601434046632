// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { GatewayService } from "../../../GatewayService.service";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  GatewayReset,
  GatewayResetThread,
} from "../../../GatewayInterface.type";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-reset",
  templateUrl: "./gateway-resets.component.html",
  styleUrls: ["./gateway-resets.component.scss"],
})
export class GatewayResetComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 100;
  exporFileName: string;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("1", "month");
  gatewayId: number;
  unidadVenta: string;
  gatewayResets: GatewayReset[] = [];
  waitForData: boolean = true;
  orderBy: object = { attribute: "timestamp", reverse: true };
  from: string;
  to: string;
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "version",
      data: "fwVersion",
      search: "fwVersion",
      sort: "fwVersion",
      visible: true,
    },
    {
      title: "event",
      data: "event",
      search: "event",
      sort: "event",
      visible: true,
    },
    {
      title: "number-threads",
      data: "threadCount",
      search: "threadCount",
      sort: "threadCount",
      visible: true,
    },
    {
      title: "registerR0",
      data: "registerR0",
      search: "registerR0",
      sort: "registerR0",
      visible: true,
    },
    {
      title: "registerR1",
      data: "registerR1",
      search: "registerR1",
      sort: "registerR1",
      visible: true,
    },
    {
      title: "registerR2",
      data: "registerR2",
      search: "registerR2",
      sort: "registerR2",
      visible: true,
    },
    {
      title: "registerR3",
      data: "registerR3",
      search: "registerR3",
      sort: "registerR3",
      visible: true,
    },
    {
      title: "registerR12",
      data: "registerR12",
      search: "registerR12",
      sort: "registerR12",
      visible: true,
    },
    {
      title: "registerLR",
      data: "registerLR",
      search: "registerLR",
      sort: "registerLR",
      visible: true,
    },
    {
      title: "meters",
      data: "programCounter",
      search: "programCounter",
      sort: "programCounter",
      visible: true,
    },
    {
      title: "thread",
      data: "threads",
      search: "threads",
      sort: "threads",
      visible: true,
    },
    {
      title: "state",
      data: "threadsState",
      search: "threadsState",
      sort: "threadsState",
      visible: true,
    },
    {
      title: "CRC",
      data: "crc",
      search: "crc",
      sort: "crc",
      visible: true,
    },
  ];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private GatewayController: GatewayControllerService,
    private GatewayService: GatewayService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.setPanelMenuOptions();
    this.exporFileName =
      this.translate.instant("reset-log-export") +
      " " +
      this.unidadVenta +
      " " +
      this.translate.instant("gateway-file") +
      " " +
      this.DateParserService.getDate();
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.waitForData = false;
    this.from = from;
    this.to = to;
    this.GatewayController.getReset(
      this.gatewayId,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]) {
          let gatewayResets: GatewayReset[] =
            response["body"]["dataloggerResetItems"];
          gatewayResets.forEach((reset: GatewayReset) => {
            let threads: string[] = [];
            let threadsState: string[] = [];
            reset.resetEvent != null
              ? (reset.event = this.translate.instant(
                  "GatewayLogEvent0" + reset.resetEvent
                ))
              : "-";
            if (reset.event == "GatewayLogEvent0" + reset.resetEvent) {
              reset.event =
                this.translate.instant("unknown") + " " + reset.resetEvent;
            }
            reset.threadFunctionStateList?.forEach(
              (reset: GatewayResetThread) => {
                threads.push(reset.threadFunction);
                threadsState.push(reset.threadState);
              }
            );
            reset.threads = threads.join(",");
            reset.threadsState = threadsState.join(",");
          });
          this.gatewayResets = gatewayResets;
        } else {
          this.gatewayResets = [];
        }
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = this.GatewayService.getLogPanelMenu("reset-log");
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    this.GatewayService.getLogMenuAction(
      action,
      this.gatewayId,
      this.unidadVenta
    );
  }
}
