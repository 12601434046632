// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Servicios propios
import { AgrupationControllerService } from "../../../../services/server/AgrupationController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { AgrupationData } from "../AgrupationInterface.type";

@Component({
  selector: "app-agrupationlist",
  templateUrl: "./agrupations-list.component.html",
  styleUrls: ["./agrupations-list.component.scss"],
})
export class AgrupationListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  currentAgrupation: Agrupation;
  entitySub: Subscription;
  agrupationSub: Subscription;

  // Botón nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new-agrupation");

  // Tabla
  agrupationList: AgrupationData[];
  exportFileName: string =
    this.translate.instant("agrupation-export") +
    " " +
    moment_timezone(moment()).format("ll");
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  tableHighlightRow: object[] = [
    {
      condition: "highlightAgrupation",
      color: "green",
      title: "current-agrupation",
    },
  ];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "edit-agrupation",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete-agrupation",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "groups",
      data: "descripcion",
      search: "descripcion",
      sort: "descripcion",
      visible: true,
    },
    {
      title: "alias",
      data: "alias",
      search: "alias",
      sort: "alias",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AgrupationController: AgrupationControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de entidad y agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de la lista de agrupaciones
  getData(): void {
    this.AgrupationController.getAgrupationList(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let agrupationList: AgrupationData[] = response["body"];
        if (this.SessionDataService.getCurrentProfile() != "ARSON") {
          agrupationList = agrupationList.filter(
            (agrupation) => !agrupation.hideInVirtualMap
          );
        }
        this.agrupationList = agrupationList.map(
          (agrupation: AgrupationData) => {
            agrupation.highlightAgrupation =
              agrupation.id == this.currentAgrupation?.id ? true : false;
            return agrupation;
          }
        );
      } else {
        this.agrupationList = [];
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, agrupation: AgrupationData): void {
    switch (action) {
      case "edit-agrupation":
        this.router.navigate([
          "/agrupaciones/formulario/editar/" + agrupation.id,
        ]);
        break;
      case "delete-agrupation":
        this.deleteAgrupation(agrupation);
        break;
      default:
        break;
    }
  }

  // Redirección a creación de agrupación
  newAgrupation(): void {
    this.router.navigate(["/agrupaciones/formulario/nuevo"]);
  }

  // Borrado de agrupación
  deleteAgrupation(agrupation: AgrupationData): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-group-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AgrupationController.deleteAgrupation(agrupation.id).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("group-deleted")
              );
              if (agrupation.id == this.currentAgrupation.id) {
                this.SessionDataService.sendAgrupation(
                  this.currentEntity.agrupations.find(
                    (entityAgrupation: Agrupation) =>
                      entityAgrupation.id != agrupation.id
                  )
                );
              }
              this.SessionDataService.clearUpdateNavFlag();
              this.ReloadComponentService.reload();
            }
          }
        );
      }
    });
  }
}
