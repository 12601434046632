// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  BalanceAlarmThreshold,
  BalanceEdition,
  BalanceGraphRequestData,
  GraphRequestData,
} from "../../screens/dashboard/data-analysis/DataAnalysisInterface.type";
import { ConsumptionProfile } from "../../screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-edit/data-analysis-patterns";

@Injectable({
  providedIn: "root",
})
export class DataAnalysisControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Datos de consumos para mapa de calor
  heatmap(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/heat-maps/consumption/agrupation/" + agrupationId
    );
  }

  /***************************************************************************/
  // ANCHOR Balance
  /***************************************************************************/

  // Borrado de sector hidraúlico
  delete(balanceId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/hydraulic/delete/" + balanceId);
  }

  // Datos de lecturas de sector hidraúlico
  getBalanceReadings(data: BalanceGraphRequestData): Observable<object> {
    return this.HttpRequestService.post("/sector/hydraulic/readings/", data);
  }

  // Listado de balances de sector hidraúlico
  getBalanceList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/sector/hydraulic/list/" + agrupationId
    );
  }

  // Datos de lecturas individuales/grupal de dispositivos
  getGraphGroup(type: number, data: GraphRequestData): Observable<object> {
    let typeURL: string;

    if (type == 1) {
      typeURL = "readings-multiple";
    } else if (type == 2) {
      typeURL = "readings-simple";
    }

    return this.HttpRequestService.post("/" + typeURL + "/agrupation/", data);
  }

  // Desplegable de números de serie de todos los dispoistivos a los que el usuario tiene permisos en la entidad
  getMetersDropdown(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/nroserie/entity/" + entityId);
  }

  // Listado de CUPS para el desplegable del abonado
  getCupsDropdown(): Observable<object> {
    return this.HttpRequestService.get("/guest/cm/dropdown");
  }

  // Búsqueda de dispositivos por coordenadas
  getGraphMarkers(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/contador/find-by-coordinates", data);
  }

  /***************************************************************************/
  // ANCHOR Editar
  /***************************************************************************/

  // Datos de sector hidraúlico
  show(balanceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/sector/update-hydraulic/show/" + balanceId
    );
  }

  // Actualización de datos de sector hidraúlico
  edit(agrupationId: number, data: BalanceEdition): Observable<object> {
    return this.HttpRequestService.post(
      "/sector/update-hydraulic/" + agrupationId,
      data
    );
  }

  // Añadir hijo a sector hidraúlico
  addChild(
    agrupationId: number,
    balanceId: number,
    data: number[]
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/sector/hydraulic/add-ch/" + agrupationId + "/" + balanceId,
      data
    );
  }

  // Quitar hijo de sector hidraúlico
  removeChild(
    agrupationId: number,
    balanceId: number,
    data: number[]
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/sector/hydraulic/subtract-ch/" + agrupationId + "/" + balanceId,
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Detalle
  /***************************************************************************/

  // Mapa de sector hidraúlico
  getMap(balanceId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/hydraulic/main/" + balanceId);
  }

  // Actualización de umbral de sector hidraúlico
  updateTheshold(data: BalanceAlarmThreshold): Observable<object> {
    return this.HttpRequestService.post("/sector-hydrulic-conf/update/", data);
  }

  // Creación de umbral de sector hidraúlico
  saveThreshold(data: BalanceAlarmThreshold): Observable<object> {
    return this.HttpRequestService.post("/sector-hydrulic-conf/save/", data);
  }

  /***************************************************************************/
  // ANCHOR Nuevo
  /***************************************************************************/

  // Listado de dispositivos de sector hidraúlico
  table(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/list-devices/" + agrupationId);
  }

  // Guardado de dispositivos de sector hidraúlico
  save(agrupationId: number, data: BalanceEdition): Observable<object> {
    return this.HttpRequestService.post(
      "/sector/save-hydraulic/" + agrupationId,
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Patrones
  /***************************************************************************/

  // Listado de perfiles de consumo
  getConsumptionProfiles(): Observable<object> {
    return this.HttpRequestService.get("/consumption-profile/list");
  }

  // Borrado de perfiles de consumo
  deleteConsumptionProfiles(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/consumption-profile/delete", data);
  }

  // Guardado de perfiles de consumo
  saveConsumptionProfiles(data: ConsumptionProfile[]): Observable<object> {
    return this.HttpRequestService.post("/consumption-profile/save", data);
  }
}
