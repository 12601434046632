// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";

@Component({
  selector: "app-gatewayupdate",
  templateUrl: "./gateway-update.component.html",
  styleUrls: ["./gateway-update.component.scss"],
})
export class GatewayUpateComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;

  // Fichero
  fileToImport: File = null;
  versionFail: boolean = false;

  // Gateway
  gatewayId: number;
  unidadVenta: string;
  hwVersion: string;
  fwVersion: string;

  // Progreso de actualización
  overwrite: boolean;
  loading: boolean;
  perfil: string;
  nonces: any;
  errorProgress: boolean;
  interval: any;
  cancelled: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private GatewayController: GatewayControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data.unidadVenta;
    this.hwVersion = history.state.data.hwVersion;
    this.fwVersion = history.state.data.fwVersion;
    this.overwrite = false;
    this.loading = false;
    this.errorProgress = false;
  }

  // Comprobación de si la versión de firmware del fichero coincide con la del gateway
  checkFileVersion(version: string): void {
    // HW
    let hwIndex = version.toUpperCase().indexOf("HW");
    let hwFileVersion: string;
    if (hwIndex) {
      hwFileVersion = version.substring(hwIndex + 2, hwIndex + 8);
    }

    // Comprobación
    if (
      !hwFileVersion ||
      hwFileVersion.toUpperCase() != this.hwVersion?.toUpperCase()
    ) {
      this.versionFail = true;
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("version-not-match")
      );
    } else {
      this.versionFail = false;
    }
  }

  // Detención de la actualización
  stop(): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("question-cancel-process")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          clearInterval(this.interval);
          this.nonces = null;
          this.loading = true;
          this.cancelled = true;
          this.cancel();
        }
      });
  }

  // Cancelación por error
  cancelByError(errorText: string): void {
    clearInterval(this.interval);
    this.nonces = null;
    this.loading = true;
    this.cancelled = true;
    this.cancel(errorText);
  }

  // Cancelación de la actualización
  cancel(errorText?: string): void {
    this.GatewayController.updateFirmwareCancel(this.gatewayId).subscribe(
      (response) => {
        this.loading = false;
        if (response["code"] == 0) {
          this.ToastService.fireToastWithConfirmation(
            errorText ? "error" : "warning",
            errorText
              ? errorText + ". " + this.translate.instant("update-canceled")
              : this.translate.instant("update-canceled")
          );
        }
      }
    );
  }

  // Importación de firmware
  import(): void {
    this.loading = false;
    this.errorProgress = false;
    this.cancelled = false;
    let formData: FormData = new FormData();
    if (!this.fileToImport) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file"),
        this.translate.instant("close")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("update-question")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.loading = true;
            this.nonces = 0;
            formData.set(
              "file",
              new File([this.fileToImport], this.fileToImport.name)
            );
            this.GatewayController.updateFirmware(
              this.gatewayId,
              formData.get("file"),
              this.overwrite ? 0 : 1
            ).subscribe((response) => {
              if (response["code"] == 0) {
                this.GatewayController.updateFirmwareTimer(
                  this.gatewayId,
                  0
                ).subscribe((responseFirstTimer) => {
                  clearInterval(this.interval);
                  if (responseFirstTimer["code"] == 0 && !this.cancelled) {
                    this.loading = false;
                    this.nonces = 100;
                    this.ToastService.fireToast(
                      "success",
                      this.translate.instant("update-finished")
                    );
                  } else {
                    this.loading = false;
                    this.errorProgress = true;
                    this.cancelByError(this.translate.instant("unknown-error"));
                  }
                });
                // Bucle de llamadas para comprobar el progreso
                let nextDate: moment.Moment = moment().add(2, "minutes");
                this.interval = setInterval(() => {
                  let currentDate: moment.Moment = moment();
                  this.GatewayController.updateFirmwareTimer(
                    this.gatewayId,
                    response["body"]
                  ).subscribe((responseTimer) => {
                    if (responseTimer["code"] == 1800 && !this.cancelled) {
                      clearInterval(this.interval);
                      this.loading = false;
                      this.nonces = 100;
                      this.ToastService.fireToast(
                        "success",
                        this.translate.instant("update-finished")
                      );
                    } else if (responseTimer["code"] == 0) {
                      let diff: moment.Duration = moment.duration(
                        nextDate.diff(currentDate)
                      );
                      if (diff.asMinutes() <= 0) {
                        if (this.nonces == responseTimer["body"]) {
                          clearInterval(this.interval);
                          this.loading = false;
                          this.errorProgress = true;
                          this.cancelByError(
                            this.translate.instant("gateway-not-response")
                          );
                        } else {
                          nextDate = moment().add(2, "minutes");
                        }
                      }
                      if (responseTimer["body"] != null) {
                        this.nonces = responseTimer["body"];
                      }
                    } else {
                      clearInterval(this.interval);
                      this.loading = false;
                      this.errorProgress = true;
                      this.cancelByError(
                        this.translate.instant("unknown-error")
                      );
                    }
                  });
                }, 15000);
              } else {
                this.loading = false;
              }
            });
          }
        });
    }
  }
}
