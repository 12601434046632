// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { ImportColumn } from "../../../../../modules/table-module/TableInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
// Variables
import { ImportResponseDevice, METER_IMPORT_COLUMNS } from "./import-columns";

@Component({
  selector: "app-device-replace",
  templateUrl: "./device-replace.component.html",
  styleUrls: ["./device-replace.component.scss"],
})
export class DeviceReplaceComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Importación
  importColumns: ImportColumn[];

  // Tabla
  tableData: ImportResponseDevice[];
  showTable: boolean = false;
  exportFileName: string =
    this.translate.instant("import-result") + " " + this.DateParserService.getDate();
  orderBy: object = { attribute: "state", reverse: false };
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "state",
      data: "uploadState",
      search: "uploadState",
      sort: "uploadState",
      boolean: true,
      alter: {
        condition: "uploadState",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "CUPS",
      data: "claveMaestra",
      search: "claveMaestra",
      sort: "claveMaestra",
      visible: true,
    },
    {
      title: "current-device",
      data: "deviceActual",
      search: "deviceActual",
      sort: "deviceActual",
      visible: true,
    },
    {
      title: "new-device",
      data: "deviceNuevo",
      search: "deviceNuevo",
      sort: "deviceNuevo",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(): void {
    this.getImportColumns();
  }

  // Obtención de las columnas a importar
  getImportColumns(): void {
    let importColumns: ImportColumn[] = JSON.parse(
      JSON.stringify(METER_IMPORT_COLUMNS)
    );
    importColumns.map((column: ImportColumn) => {
      column.profile =
        column.profilesList.length == 0
          ? true
          : column.profilesList.includes(this.sessionProfile);
      column.info = this.translate.instant(column.info);
    });
    this.importColumns = importColumns;
  }

  // Importación de dispositivo
  importDevice(file: File): void {
    let formData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("question-import")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            let tableData: ImportResponseDevice[] = [];
            formData.set("file", file);
            this.MeterController.replace(formData).subscribe((response) => {
              if (response["code"] == 0 || response["code"] == 1) {
                response["body"]?.forEach((device: ImportResponseDevice) => {
                  device.responseCodeText = this.translate.instant(
                    "httpError" + device.codError
                  );
                  if (device.responseCodeText.includes("httpError")) {
                    device.responseCodeText =
                      this.translate.instant("error-text") +
                      " " +
                      device.codError;
                  }
                  tableData.push(device);
                });

                if (response["code"] == 0) {
                  this.ToastService.fireToastWithConfirmation(
                    "success",
                    this.translate.instant("success-import")
                  );
                } else if (response["code"] == 1 && response["body"]) {
                  this.ToastService.fireToastWithConfirmation(
                    "warning",
                    this.translate.instant("some-meter-error")
                  );
                }
                this.showTable = tableData.length > 0 ? true : false;
                this.tableData = tableData;
              }
            });
          }
        });
    }
  }
}
