// @angular
import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  CommunicationReportCards,
  ManagementCommunicationReport,
} from "../ManagementInterface.type";
import {
  GraphHeatmapAxisData,
  GraphHeatmapData,
} from "../../../../modules/graph-module/GraphInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";
import { LOCAL_TIMEZONE } from "../../../../global/LOCAL_TIMEZONE";

@Component({
  selector: "app-management-communication-report",
  templateUrl: "./management-communication-report.component.html",
  styleUrls: ["./management-communication-report.component.scss"],
})
export class ManagementCommunicationReportComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Gráfica
  graphData: any[];
  graphSeries: any[];
  manufacturerGraphSeries: any[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  heatMapData: GraphHeatmapData;
  heatMapXAxis: GraphHeatmapAxisData = {
    title: "",
    categories: [],
  };
  period: number = 5;
  colors = [
    [0, "#b4e1fa"],
    [0.333333333333, "#b4e1fa"],

    [0.333333333334, "#fafa28"],
    [0.666666666666, "#fafa28"],

    [0.666666666667, "#fa2828"],
    [1, "#fa2828"],
  ];
  showGlobalSerie: boolean = false;

  // Variables de las tarjetas
  cardsData: CommunicationReportCards;
  communications: ManagementCommunicationReport[];
  filteredCommunications: ManagementCommunicationReport[];

  // Filtro fabricante/modelo
  selectedManufacturer: number;
  selectedModel: { manufacturerId: string; device: string };
  deviceList: { idFabricante: number; idDevType: number }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GraphOptionsService: GraphOptionsService,
    private ManagementController: ManagementControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.heatMapXAxis.categories = this.getHeatMapCategories();
    this.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.heatMapData = null;
    this.cardsData = null;
    if (from && to) {
      let fromMoment = this.getFromMoment(parseInt(from));
      this.getManufacturerData(from, to, fromMoment);
    }
  }

  // Obtención de los datos por fabricante
  getManufacturerData(from: string, to: string, fromMoment: object): void {
    this.ManagementController.getCommunicationReportByManufacturer(
      from,
      to
    ).subscribe((response) => {
      if (
        response["code"] == 0 &&
        response["body"] &&
        response["body"].length > 0
      ) {
        this.graphData = this.parseResponse(response["body"], fromMoment);
        this.updateGraph();
      }
    });
  }

  // Obtención de las categorías del eje X
  getHeatMapCategories(): string[] {
    let categories = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j = j + this.period) {
        categories.push(
          (i > 9 ? String(i) : "0" + i) + ":" + (j > 9 ? String(j) : "0" + j)
        );
      }
    }
    return categories;
  }

  // Obtención de la fecha inicial estructurada
  getFromMoment(from: number): object {
    let fromString = this.DateParserService.parseDate(
      from,
      "L HH:mm:ss",
      LOCAL_TIMEZONE
    );
    let fromDate = fromString.split(" ")[0].split("/");
    let fromTime = fromString.split(" ")[1].split(":");
    return {
      day: fromDate[0],
      month: fromDate[1],
      year: fromDate[2],
      hour: fromTime[0],
      minute: fromTime[1],
    };
  }

  // Parseo de la respuesta
  parseResponse(
    response: ManagementCommunicationReport[],
    fromMoment: any
  ): number[][] {
    response.map((data: ManagementCommunicationReport) => {
      // Mes y año
      data.month =
        data.day >= parseInt(fromMoment.day)
          ? parseInt(fromMoment.month)
          : parseInt(fromMoment.month) + 1;
      data.year = fromMoment.year;
      if (data.month > 12) {
        data.month = 1;
        data.year++;
      }

      // Conversión a timestamps
      data.timestamp = moment(
        (data.day > 9 ? data.day : "0" + data.day) +
          "/" +
          (data.month > 9 ? data.month : "0" + data.month) +
          "/" +
          data.year +
          " " +
          (data.hour > 9 ? data.hour : "0" + data.hour) +
          ":" +
          (data.minute > 9 ? data.minute : "0" + data.minute),
        "L HH:mm"
      ).valueOf();
    });

    response.sort((a, b) => a.timestamp - b.timestamp);
    this.communications = response;
    this.deviceList = response.map((data: ManagementCommunicationReport) => {
      return { idFabricante: data.fabricante, idDevType: data.devType };
    });

    return response.map((data: ManagementCommunicationReport) => {
      return [data.timestamp, data.metersCount];
    });
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("communication-report")
      );
    highchartsOptions.plotOptions.series.dataGrouping.units = [
      ["minute", [this.period]],
    ];
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["legend"] = { enabled: false };
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0].title.text = this.translate.instant("devices");
    chartOptions["series"] = this.graphSeries;
    chartOptions["rangeSelector"]["buttons"] = [
      {
        type: "year",
        text: "minute-selector",
        dataGrouping: {
          units: [["minute", [this.period]]],
        },
        events: null,
      },
      {
        type: "year",
        text: "hour-selector",
        dataGrouping: {
          approximation: "sum",
          forced: true,
          units: [["hour", [1]]],
        },
        events: null,
      },
      {
        type: "year",
        text: "day-selector",
        dataGrouping: {
          approximation: "sum",
          forced: true,
          units: [["day", [1]]],
        },
        events: null,
      },
    ];
    this.chartOptions = chartOptions;
  }

  // Obtención de los datos de las tarjetas
  getCards(communications: ManagementCommunicationReport[]): void {
    let cardsData: CommunicationReportCards = {
      communicationReportDevicesDay: null,
    };
    if (communications?.length > 0) {
      cardsData.communicationReportDevicesDay = {
        data: this.getDevicesByDay(),
        type: "number",
      };
    } else {
      cardsData.communicationReportDevicesDay = {
        data: null,
        type: "number",
      };
    }
    this.cardsData = cardsData;
  }

  // Obtención de la media de dispositivos por día
  getDevicesByDay(): number {
    let totalDays =
      this.communications[this.communications.length - 1].day -
      this.communications[0].day +
      1;
    return Math.floor(
      this.communications
        .map((res) => res.metersCount)
        .reduce((a, b) => a + b) / Math.abs(totalDays)
    );
  }

  // Obtención de los datos para la gráfica de calor
  getHeatMapData(communications: ManagementCommunicationReport[]): number[][] {
    if (communications?.length > 0) {
      let heatMapData: number[][] = [];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60 / this.period; j++) {
          let metersCount = communications
            .filter(
              (data: ManagementCommunicationReport) =>
                data.hour == i && data.minute == j * this.period
            )
            .map((res) => res.metersCount);
          heatMapData.push([
            i * (60 / this.period) + j,
            0,
            metersCount.length > 0 ? metersCount.reduce((a, b) => a + b) : 0,
          ]);
        }
      }
      return heatMapData;
    } else {
      return [];
    }
  }

  // Obtención de las horas punta
  getRushHours(communications: ManagementCommunicationReport[]): string[] {
    if (communications?.length > 0) {
      let maxDevices = Math.max(
        ...communications.map((data: ManagementCommunicationReport) => {
          return data.metersCount;
        })
      );
      let rushHours = communications.filter(
        (data: ManagementCommunicationReport) =>
          data.metersCount > 0.95 * maxDevices
      );

      let uniqueRushHours = [];
      rushHours.forEach((data: ManagementCommunicationReport) => {
        let hour =
          (data.hour > 9 ? data.hour : "0" + data.hour) +
          ":" +
          (data.minute > 9 ? data.minute : "0" + data.minute);
        if (!uniqueRushHours.includes(hour)) {
          uniqueRushHours.push(hour);
        }
      });
      return uniqueRushHours.sort((a, b) => b - a);
    } else {
      return [];
    }
  }

  // Actualización de la gráfica
  updateGraph(): void {
    // Filtrado de datos
    if (this.selectedManufacturer != null) {
      this.filteredCommunications = this.getFilteredCommunications();
    }
    // Gráfica de calor
    this.heatMapData = {
      graphTitle: this.translate.instant("communication-report-heatmap"),
      serieTitle: this.translate.instant("devices"),
      serie: this.getHeatMapData(
        this.selectedManufacturer != null
          ? this.filteredCommunications
          : this.communications
      ),
    };
    // Tarjetas
    this.getCards(
      this.selectedManufacturer != null
        ? this.filteredCommunications
        : this.communications
    );
    // Gráfica
    this.setGraphSeries();
  }

  // Seteo de la series de la gráfica
  setGraphSeries() {
    let filteredSerie: any[];
    if (this.selectedManufacturer != null) {
      filteredSerie = this.filteredCommunications?.map(
        (data: ManagementCommunicationReport) => {
          return [data.timestamp, data.metersCount];
        }
      );
      filteredSerie = this.fillSerieGaps(filteredSerie);
    }

    if (this.selectedManufacturer != null) {
      this.graphSeries = [
        {
          id: "manufacturer",
          name: this.translate.instant("devices"),
          data: filteredSerie,
          color: "#ff9800",
          dataGrouping: { approximation: "sum" },
        },
      ];
      if (this.showGlobalSerie) {
        this.graphSeries.push({
          id: "global",
          name: this.translate.instant("devices"),
          data: this.graphData,
          color: "#42a5f5",
          dataGrouping: { approximation: "sum" },
        });
      }
    } else {
      this.graphSeries = [
        {
          id: "global",
          name: this.translate.instant("devices"),
          data: this.graphData,
          color: "#42a5f5",
          dataGrouping: { approximation: "sum" },
        },
      ];
    }

    this.setChartsOptions();
  }

  // Filtrado de comunicaciones de fabricante/modelo
  getFilteredCommunications(): ManagementCommunicationReport[] {
    let filteredCommunications = this.communications.filter(
      (communication: ManagementCommunicationReport) => {
        if (this.selectedModel != null) {
          return (
            communication.fabricante ==
              parseInt(this.selectedModel.manufacturerId) &&
            communication.devType == parseInt(this.selectedModel.device)
          );
        } else {
          return communication.fabricante == this.selectedManufacturer;
        }
      }
    );
    return filteredCommunications;
  }

  // Relleno de valores anteriores y posteriores
  fillSerieGaps(filteredCommunications: number[][]): number[][] {
    let filteredCommunicationsParsed: number[][] = [];
    filteredCommunications.forEach((communication: number[]) => {
      if (
        !filteredCommunications.some(
          (prevCommunication: number[]) =>
            prevCommunication[0] == communication[0] - this.period * 60000
        )
      ) {
        filteredCommunicationsParsed.push([
          communication[0] - this.period * 60000,
          0,
        ]);
      }
      filteredCommunicationsParsed.push(communication);
      if (
        !filteredCommunications.some(
          (prevCommunication: number[]) =>
            prevCommunication[0] == communication[0] + this.period * 60000
        )
      ) {
        filteredCommunicationsParsed.push([
          communication[0] + this.period * 60000,
          0,
        ]);
      }
    });
    return filteredCommunicationsParsed;
  }
}
