// @angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Servicios propios
import { ToastService } from "../../../services/shared/ToastService.service";
import { UserControllerService } from "../../../services/server/UserController.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { GraphControllerComponent } from "../../graph-module/graph-controller/graph-controller.component";
import { NotificationModalService } from "../../notification-module/notification-service/notification-modal.service";
// Variables
import { GRAPH_CONFIG } from "../../graph-module/GRAPH_CONFIG";
import { METROLOGY_TYPE } from "../../../interfaces/DeviceGlobalInterface.type";
// Interfaces
import { MaterialSelectConfig } from "../../material-module/MaterialInterface.type";

@Component({
  selector: "app-subscriber-cups",
  templateUrl: "./subscriber-cups.component.html",
  styleUrls: ["./subscriber-cups.component.scss"],
})
export class SubscriberCupsComponent implements OnInit, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Gráfica inicializado
  graphInitiated = false;

  // CUPS
  @Input() cupsList: any;
  @Input()
  get cupsData(): any {
    return this._cupsData;
  }
  set cupsData(cupsData: any) {
    if (cupsData) {
      this._cupsData = cupsData;
      this.SessionDataService.sendAgrupation({
        entity: null,
        id: null,
        name: null,
        showAllEntity: null,
        timezone: this._cupsData.timeZone
          ? this._cupsData.timeZone
          : "Europe/Madrid",
      });
      this.selectedMeterId =
        this.cupsList[this.currentCupsIndex]?.contadoresList[0]?.id;
      this.initialMeterSelection = this.selectedMeterId;
      this.updateGraphFilters(
        this.cupsList[this.currentCupsIndex]?.contadoresList[0]
      );
      setTimeout(() => this.getCupsTable(this._cupsData), 0);

      // Carga de gráfica
      if (this.graphInitiated) {
        this.highchartsOptions = null;
        this.loadGraph();
        this.loadGraphData();
      } else {
        this.loadGraph();
      }
    }
  }
  _cupsData: any;
  currentCupsId: number;
  currentCupsIndex: number;
  selectedMeterId: number;
  initialMeterSelection: number;
  @ViewChild("cupsContainer") cupsContainer: ElementRef;

  // Tabla de datos
  tableData: object[];

  // Mapa
  mapType: string = "meterList";
  mapHeight: number = 300;
  initialZoom: number = 17;

  // Gráfica
  graphSeries: object[];
  graphData: object[];
  graphType: number = 2;
  graphFilters: MaterialSelectConfig[] = [
    {
      title: this.translate.instant("type"),
      options: [
        { value: 1, name: this.translate.instant("index") },
        { value: 2, name: this.translate.instant("hour-consumption") },
        { value: 3, name: this.translate.instant("consumption-normalized") },
      ],
      selected: 2,
    },
  ];
  highchartsOptions: object;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: moment().endOf("day"),
  };
  @ViewChild(GraphControllerComponent)
  GraphController: GraphControllerComponent;
  from: string;
  to: string;

  // Alarmas personalizadas
  showAlarmsConfig = false;
  sessionUser: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public notificationModal: NotificationModalService,
    private SessionDataService: SessionDataService,
    private UserController: UserControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionUser = this.SessionDataService.getCurrentUser();
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.cupsContainer.nativeElement.style.setProperty("--cups-map-index", 55);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.currentCupsId = this.cupsList[0].id;
    this.currentCupsIndex = 0;
    this.selectedMeterId =
      this.cupsList[this.currentCupsIndex]?.contadoresList[0]?.id;
    this.SessionDataService.sendSubscriberCups(this.currentCupsId);
    this.initialMeterSelection = this.selectedMeterId;
  }

  /***************************************************************************/
  // ANCHOR Tarjetas de contador
  /***************************************************************************/

  // Creación de las tarjetas de contador
  getCupsTable(cupsData: any): void {
    let selectedMeterNroSerie = this.cupsList[
      this.currentCupsIndex
    ]?.contadoresList.find(
      (meter) => meter.id == this.selectedMeterId
    )?.nroSerie;
    let selectedMeter = cupsData.contadores.find(
      (meter) => meter.nroSerie == selectedMeterNroSerie
    );
    this.tableData = [
      {
        text: "last-know-value",
        subText:
          " (" +
          (selectedMeter?.lastReadedTimestampParsed != null
            ? selectedMeter?.lastReadedTimestampParsed
            : this.translate.instant("unknown")) +
          ")",
        data:
          selectedMeter?.lastReadedValue != null
            ? selectedMeter?.lastReadedValue + " m³"
            : this.translate.instant("unknown"),
      },
      {
        text: "last-data-month",
        data:
          selectedMeter?.consumptionCards.lastMonthlyValue != null
            ? selectedMeter?.consumptionCards.lastMonthlyValue +
              (selectedMeter?.metrologyType == METROLOGY_TYPE.WATER
                ? " m³"
                : " Nm³")
            : this.translate.instant("unknown"),
      },
      {
        text: "last-month-consumption",
        data:
          selectedMeter?.consumptionCards.lastMonth != null
            ? selectedMeter?.consumptionCards.lastMonth +
              (selectedMeter?.metrologyType == METROLOGY_TYPE.WATER
                ? " m³"
                : " Nm³")
            : this.translate.instant("unknown"),
      },
      {
        text: "consumption-month-average",
        data:
          selectedMeter?.consumptionCards.monthlyAverage != null
            ? selectedMeter?.consumptionCards.monthlyAverage +
              (selectedMeter?.metrologyType == METROLOGY_TYPE.WATER
                ? " m³"
                : " Nm³")
            : this.translate.instant("unknown"),
      },
      {
        text: "current-month-consumption",
        data:
          selectedMeter?.consumptionCards.currentMonth != null
            ? selectedMeter?.consumptionCards.currentMonth +
              (selectedMeter?.metrologyType == METROLOGY_TYPE.WATER
                ? " m³"
                : " Nm³")
            : this.translate.instant("unknown"),
      },
      {
        text: "consumption-average-daily",
        data:
          selectedMeter?.consumptionCards.dailyAverage != null
            ? selectedMeter?.consumptionCards.dailyAverage +
              (selectedMeter?.metrologyType == METROLOGY_TYPE.WATER
                ? " m³"
                : " Nm³")
            : this.translate.instant("unknown"),
      },
    ];
  }

  /***************************************************************************/
  // ANCHOR Gráfica
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from?: any, to?: any): void {
    if (!from || !to) {
      this.from = moment_timezone(this.defaultDateRange.startDate)
        .tz(
          this._cupsData.timeZone ? this._cupsData.timeZone : "Europe/Madrid",
          true
        )
        .startOf("day")
        .valueOf()
        .toString();
      this.to = moment_timezone(this.defaultDateRange.endDate)
        .tz(
          this._cupsData.timeZone ? this._cupsData.timeZone : "Europe/Madrid",
          true
        )
        .endOf("day")
        .valueOf()
        .toString();
    } else {
      this.from = from;
      this.to = to;
    }

    this.UserController.getReadings({
      claveMaestraId: this.currentCupsId,
      type: this.graphType,
      meter: this.selectedMeterId,
      from: this.from,
      to: this.to,
    }).subscribe((response) => {
      let graphData: any[] = [];
      if (response["code"] == 0 && response["body"]) {
        graphData = response["body"];
      }

      this.graphData = graphData;
      this.getSeries();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    let graphSeries: any[] = [];

    // Lecturas
    graphSeries[0] = {
      id: "valor",
      name:
        this.graphType == 1
          ? this.translate.instant("value")
          : this.graphType == 2
          ? this.translate.instant("consumption")
          : this.translate.instant("consumption-normalized"),
      type: this.graphType == 1 ? "line" : "area",
      data: this.graphData,
      tooltip: {
        valueSuffix:
          this.graphType == 1 || this.graphType == 2 ? " m³" : " Nm³",
        valueDecimals: 3,
      },
      color: "#42a5f5",
      navigatorOptions: {
        type: this.graphType == 1 ? "line" : "area",
      },
    };

    this.graphSeries = graphSeries;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );

    highchartsOptions["tooltip"] = {
      enabled: true,
      dateTimeLabelFormats: {
        day: "%e " + this.translate.instant("of") + " %B",
        hour: "%e " + this.translate.instant("of") + " %B, %H:%M",
        week:
          this.translate.instant("week") +
          " %d " +
          this.translate.instant("of") +
          " %B %H:%M",
      },
    };
    highchartsOptions["scrollbar"] = {
      barBackgroundColor: "rgba(0,0,0,0.25)",
      buttonBackgroundColor: "rgba(255,255,255,1)",
      buttonBorderRadius: 5,
      trackBorderRadius: 5,
    };
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    highchartsOptions["exporting"] = {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
      filename:
        this.translate.instant("meters-export") + " " + moment().format("ll"),
    };

    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == 1 || this.graphType == 2
        ? " m³"
        : this.graphType == 3
        ? " Nm³"
        : " kWh");
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == 1
        ? this.translate.instant("value")
        : this.graphType == 2
        ? this.translate.instant("consumption")
        : this.translate.instant("consumption-normalized");
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
    this.graphInitiated = true;
  }

  // Actualización del CUPS en curso
  updateCurrentCups(cupsId: number, cupsIndex: number): void {
    this.currentCupsId = cupsId;
    this.currentCupsIndex = cupsIndex;
    this.cupsContainer.nativeElement.style.setProperty(
      "--cups-map-index",
      (cupsIndex + 1) * 55
    );
    this.SessionDataService.sendSubscriberCups(cupsId);
  }

  // Selección del contador en el mapa
  selectMeter(selectedMeter: any) {
    this.selectedMeterId = this.cupsList[
      this.currentCupsIndex
    ]?.contadoresList.find(
      (meter: any) => (meter.nroSerie = selectedMeter.nroSerie)
    )?.id;
  }

  // Actualización de tipos de gráfica
  updateGraphFilters(selectedMeter: any): void {
    let selectedMeterMetrology = this._cupsData.contadores?.find(
      (meter) => meter?.nroSerie == selectedMeter?.nroSerie
    )?.metrologyType;
    if (selectedMeterMetrology == METROLOGY_TYPE.GAS) {
      this.graphFilters = [
        {
          title: this.translate.instant("type"),
          options: [
            { value: 1, name: this.translate.instant("index") },
            { value: 2, name: this.translate.instant("hour-consumption") },
            {
              value: 3,
              name: this.translate.instant("consumption-normalized"),
            },
          ],
          selected: 3,
        },
      ];
      this.graphType = 3;
    } else {
      this.graphFilters = [
        {
          title: this.translate.instant("type"),
          options: [
            { value: 1, name: this.translate.instant("index") },
            { value: 2, name: this.translate.instant("hour-consumption") },
          ],
          selected: 2,
        },
      ];
      this.graphType = 2;
    }
  }
}
