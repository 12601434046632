// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { ImportColumn } from "../../../../../modules/table-module/TableInterface.type";
// Variables
import { ImportResponse, READINGS_IMPORT_COLUMNS } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-readings-import",
  templateUrl: "./device-readings-import.component.html",
  styleUrls: ["./device-readings-import.component.scss"],
})
export class DeviceReadingsImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Importación
  importColumns: ImportColumn[];
  force: boolean = false;
  importResponse: ImportResponse;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(): void {
    this.importColumns = JSON.parse(
      JSON.stringify(READINGS_IMPORT_COLUMNS)
    ).map((column: ImportColumn) => {
      column.info = this.translate.instant(column.info);
      return column;
    });
  }

  // Importación de dispositivo
  importDevice(file: File): void {
    let formData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("question-import")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            formData.set("file", file);
            this.MeterController.importReadings(this.force, formData).subscribe(
              (response) => {
                if (response["code"] == 0) {
                  let importResponse: ImportResponse = response["body"];
                  importResponse.contadoresNoExistentesParsed =
                    importResponse.contadoresNoExistentesONoAutorizados?.join(
                      ", "
                    );
                  this.importResponse = importResponse;
                  if (this.importResponse.totalErrores == 0) {
                    this.ToastService.fireToast(
                      "success",
                      this.translate.instant("success-import")
                    );
                  } else {
                    this.ToastService.fireToast(
                      "error",
                      this.translate.instant("httpError2029")
                    );
                  }
                }
              }
            );
          }
        });
    }
  }
}
