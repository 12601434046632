<!-- Modal de edición de EK280 -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "edit" | translate }}: {{ data.editModalData.nroSerie }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Código -->
    <div class="ek-config-input">
      <mat-form-field appearance="standard">
        <mat-label for="ek-code"> {{ "code" | translate }}</mat-label>
        <input
          matInput
          type="text"
          id="ek-code"
          maxlength="8"
          placeholder="{{ 'code' | translate }}..."
          [(ngModel)]="data.editModalData.codigo"
          required
        />
      </mat-form-field>
    </div>
    <!-- Número de serie (Módulo RF) -->
    <div class="ek-config-input">
      <mat-form-field appearance="standard">
        <mat-label for="ek-nserie"> {{ "rf-module" | translate }}</mat-label>
        <input
          matInput
          type="text"
          id="ek-nserie"
          placeholder="{{ 'rf-module' | translate }}..."
          [(ngModel)]="data.editModalData.nserie"
          required
        />
      </mat-form-field>
    </div>
    <!-- Descripción -->
    <div class="ek-config-input">
      <mat-form-field appearance="standard">
        <mat-label for="ek-descripcion"> {{ "description" | translate }}</mat-label>
        <textarea
          matInput
          rows="4"
          id="ek-descripcion"
          placeholder="{{ 'description' | translate }}..."
          [(ngModel)]="data.editModalData.descripcion"
          required
        ></textarea>
      </mat-form-field>
    </div>
    <!-- ICCID -->
    <div *ngIf="data.sessionProfile == 'ARSON'" class="ek-config-input-select">
      <app-material-select
        [fullWidth]="true"
        [title]="'ICCID' | translate"
        [bindValue]="'id'"
        [bindLabel]="'iccid'"
        [disabled]="data.sessionProfile != 'ARSON'"
        [selected]="initialSimSelection"
        [data]="updatedSimList"
        (selectedOption)="data.editModalData.simId = $event?.id"
        [required]="true"
      ></app-material-select>
    </div>
    <!-- Ventana 1 -->
    <div
      *ngIf="data.sessionProfile == 'ARSON'"
      class="form-group ek-config-window"
    >
      <h4>{{ "window" | translate }} 1</h4>
      <div>
        <label for="firstWindow">{{ "start-time" | translate }}:</label>
        <input
          [(ngModel)]="data.editModalData.ventana1Parsed"
          type="time"
          id="firstWindow"
          name="firstWindow"
          placeholder="HH:mm:ss"
          [disabled]="data.sessionProfile != 'ARSON'"
          required
        />
      </div>
      <div class="ek-config-duration">
        <label for="firstWindowDuration">{{ "duration" | translate }}:</label>
        <input
          [(ngModel)]="data.editModalData.duracion1"
          type="number"
          id="firstWindowDuration"
          name="firstWindowDuration"
          min="0"
          placeholder=""
          [disabled]="data.sessionProfile != 'ARSON'"
          required
        />
        <span>{{ "minutes" | translate }}</span>
      </div>
    </div>
    <!-- Ventana 2 -->
    <div
      *ngIf="data.sessionProfile == 'ARSON'"
      class="form-group ek-config-window"
    >
      <h4>{{ "window" | translate }} 2</h4>
      <div>
        <label for="secondWindow">{{ "start-time" | translate }}:</label>
        <input
          [(ngModel)]="data.editModalData.ventana2Parsed"
          type="time"
          id="secondWindow"
          name="secondWindow"
          placeholder="HH:mm:ss"
          [disabled]="data.sessionProfile != 'ARSON'"
          required
        />
      </div>
      <div class="ek-config-duration">
        <label for="secondWindowDuration">{{ "duration" | translate }}:</label>
        <input
          [(ngModel)]="data.editModalData.duracion2"
          type="number"
          id="secondWindowDuration"
          name="secondWindowDuration"
          min="0"
          placeholder=""
          [disabled]="data.sessionProfile != 'ARSON'"
          required
        />
        <span>{{ "minutes" | translate }}</span>
      </div>
    </div>
    <!-- Ventana 3 -->
    <div
      *ngIf="data.sessionProfile == 'ARSON'"
      class="form-group ek-config-window"
    >
      <h4>{{ "window" | translate }} 3</h4>
      <div>
        <label for="thirdWindow">{{ "start-time" | translate }}:</label>
        <input
          [(ngModel)]="data.editModalData.ventana3Parsed"
          type="time"
          id="thirdWindow"
          name="thirdWindow"
          placeholder="HH:mm:ss"
          [disabled]="data.sessionProfile != 'ARSON'"
          required
        />
      </div>
      <div class="ek-config-duration">
        <label for="thirdWindowDuration">{{ "duration" | translate }}:</label>
        <input
          [(ngModel)]="data.editModalData.duracion3"
          type="number"
          id="thirdWindowDuration"
          name="thirdWindowDuration"
          min="0"
          placeholder=""
          [disabled]="data.sessionProfile != 'ARSON'"
          required
        />
        <span>{{ "minutes" | translate }}</span>
      </div>
    </div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="
        data.editModalData.codigo == null ||
        data.editModalData.nserie == null ||
        data.editModalData.descripcion == null ||
        data.editModalData.simId == null ||
        data.editModalData.duracion1 == null ||
        data.editModalData.ventana1Parsed == null ||
        data.editModalData.duracion2 == null ||
        data.editModalData.ventana2Parsed == null ||
        data.editModalData.duracion3 == null ||
        data.editModalData.ventana3Parsed == null
      "
      (click)="saveConfig()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>

    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
