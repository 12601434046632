<div class="panel panel-default">
  <div class="panel-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>
    <h5>
      <b>{{ "sim-table" | translate }}</b>
    </h5>
  </div>

  <div class="panel-body">
    <!-- Desplegable de SIM -->
    <div *ngIf="sessionProfile == 'ARSON' && simList" class="device-sim-select">
      <app-material-select
        *ngIf="simList"
        [title]="'sim-available' | translate"
        [bindValue]="'id'"
        [bindLabel]="'iccid'"
        [selected]="simList[0]?.id"
        [data]="simList"
        (selectedOption)="simSelected = $event?.id"
      ></app-material-select>
      <button
        mat-button
        class="mat-button-icon-text"
        [disabled]="!simSelected"
        (click)="saveSim()"
      >
        <i class="fas fa-save"></i>
        {{ "save" | translate }}
      </button>
    </div>

    <!-- Tabla de sims -->
    <div>
      <!-- Tabla -->
      <app-table-controller
        [tableId]="'deviceSimTable'"
        [export]="false"
        [columns]="columns"
        [maxReg]="maxReg"
        [onlyTable]="true"
        [paginationDisabled]="true"
        [data]="tableData"
      >
      </app-table-controller>
    </div>
  </div>
</div>
