import { Injectable } from "@angular/core";
import { BrowserStorageSecureService } from "./BrowserStorageSecureService.service";

@Injectable({
  providedIn: "root",
})
export class BrowserStorageLocalService {
  constructor(
    private BrowserStorageSecureService: BrowserStorageSecureService
  ) {}

  // Set the json data to local
  setJsonValue(key: string, value: any): void {
    this.BrowserStorageSecureService.secureLocalStorage.setItem(key, value);
  }

  // Get the json value from local
  getJsonValue(key: string): any {
    return this.BrowserStorageSecureService.secureLocalStorage.getItem(key);
  }

  // Clear the local
  clearToken(): any {
    return this.BrowserStorageSecureService.secureLocalStorage.clear();
  }
}
