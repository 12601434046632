<div class="date-selector">
  <mat-form-field
    appearance="standard"
    [ngClass]="{ 'mat-error': error }"
    (contextmenu)="!disabled ? openDateMenu($event) : null"
  >
    <mat-label for="dateRangeSelected">{{
      dateRangeLabel ? dateRangeLabel : ("date-range" | translate)
    }}</mat-label>
    <input
      #dateRangeSelector
      ngxDaterangepickerMd
      matInput
      appDateRangeInput
      [isSingleDate]="singleDatePicker"
      (dateRangeUpdated)="dateRangeSelected = $event; checkInterval()"
      type="text"
      name="daterangePicker"
      placeholder="{{ 'date-range' | translate }}..."
      [locale]="daterangePickerLang"
      [(ngModel)]="dateRangeSelected"
      [ranges]="!singleDatePicker ? daterangePickerRanges : null"
      [showCustomRangeLabel]="!singleDatePicker"
      [alwaysShowCalendars]="true"
      [maxDate]="maxDate"
      [closeOnAutoApply]="true"
      [autoApply]="true"
      [singleDatePicker]="singleDatePicker"
      (datesUpdated)="checkInterval()"
      autocomplete="off"
      [disabled]="disabled"
    />
    <mat-hint *ngIf="hint" [ngClass]="{ 'date-error': error }">{{
      hint
    }}</mat-hint>
  </mat-form-field>

  <!-- Menú de selector de fecha -->
  <div
    class="date-selector-menu"
    [matMenuTriggerFor]="dateMenu"
    #dateMenuTrigger="matMenuTrigger"
  >
    <mat-menu #dateMenu="matMenu" class="date-menu">
      <button
        mat-menu-item
        (click)="copyDate()"
        *ngIf="dateRangeSelected?.startDate && dateRangeSelected?.endDate"
      >
        <mat-icon class="material-menu-icon">
          <i class="fas fa-copy"></i
        ></mat-icon>
        <span>{{ "copy" | translate }}</span>
      </button>
      <button mat-menu-item (click)="pasteDate()">
        <mat-icon class="material-menu-icon">
          <i class="fas fa-paste"></i
        ></mat-icon>
        <span>{{ "paste" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
