// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AssociationControllerService } from "../../../../services/server/AssociationController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ImportColumn } from "../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  ImportResponseCups,
  TableImportResponse,
} from "../DataManagementInterface.type";

@Component({
  selector: "app-import",
  templateUrl: "./data-management-import.component.html",
  styleUrls: ["./data-management-import.component.scss"],
})
export class ManagementImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Columnas de CUPS
  loadingCups: boolean = true;
  cupsColumns: EntityDefinition[];
  importColumns: ImportColumn[];
  importMeters: string = this.route.snapshot.paramMap.get("element");

  // Tabla
  tableData: TableImportResponse[];
  showTable: boolean = false;
  orderBy: object = { attribute: "state", reverse: false };
  exportFileName: string =
    this.translate.instant("import-result") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "state",
      data: "state",
      search: "state",
      sort: "state",
      alter: {
        condition: "state",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      extraInfo: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private AssociationController: AssociationControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private route: ActivatedRoute,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getExtraColumns();
  }

  // Obtención de las columnas de CUPS
  getExtraColumns(): void {
    this.AssociationController.getColumns(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let extraColumns: EntityDefinition[] = response["body"].filter(
            (column: EntityDefinition) =>
              column.colPosition > 0 && column.colPosition < 99
          );
          let serialNumber: EntityDefinition = response["body"].filter(
            (column: EntityDefinition) => column.colPosition == 100
          );

          if (extraColumns.length > 0) {
            this.cupsColumns = response["body"].map(
              (column: EntityDefinition) => {
                column.description = column.description
                  ? column.description + "."
                  : "";
                return column;
              }
            );

            if (!serialNumber) {
              this.cupsColumns.unshift({
                colPosition: 100,
                label: "NRO_SERIE",
                name: "NRO_SERIE",
                text: this.translate.instant("serial-number"),
                required: true,
                show: true,
              });
            } else {
              this.cupsColumns.find(
                (column: EntityDefinition) => column.colPosition == 100
              )["text"] = this.translate.instant("serial-number");
            }
          }
        }
        this.loadingCups = false;
        this.setImportColumns();
        this.setResponseColumns();
      }
    );
  }

  // Redirección a configuración de CUPS
  goToCupsConfig(): void {
    this.router.navigate(["/gestion-datos/configuracion"]);
  }

  // Actualización de columnas dependiendo del tipo de importación
  updateColumns(): void {
    this.setResponseColumns();
    this.setImportColumns();
  }

  // Seteo de columnas de la respuesta
  setResponseColumns(): void {
    this.columns = [
      {
        title: "state",
        data: "state",
        search: "state",
        sort: "state",
        alter: {
          condition: "state",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
      },
      {
        title: "number-serie",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: this.importMeters != "cups" ? true : null,
      },
      {
        title: "error-text",
        data: "responseCodeText",
        search: "responseCodeText",
        sort: "responseCodeText",
        extraInfo: true,
        visible: true,
      },
    ];
  }

  // Obtención de las columnas a importar
  setImportColumns(): void {
    this.importColumns = null;
    let importColumns: ImportColumn[] = [];
    this.cupsColumns?.forEach((column: EntityDefinition) => {
      if (column.colPosition == 100 && this.importMeters != "cups") {
        importColumns.push({
          title: column.label,
          info: column.text,
          required: column.required,
          profile: true,
        });
      }
      if (column.colPosition != 100) {
        importColumns.push({
          title: column.label,
          info: column.name,
          extraInfo: column.description,
          required: column.required,
          profile: true,
        });
      }
    });
    this.importColumns = importColumns;
  }

  // Importación de archivo
  importFile(file: File): void {
    let formData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file"),
        this.translate.instant("close")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        "warning",
        this.translate.instant("question-import")
      ).then((userConfirmation: Boolean) => {
        if (userConfirmation) {
          let tableData: TableImportResponse[] = [];
          formData.set("file", file);
          let request =
            this.importMeters != "cups"
              ? this.AssociationController.import(
                  this.currentEntity.id,
                  formData
                )
              : this.AssociationController.importOnlyCups(
                  this.currentEntity.id,
                  formData
                );

          request.subscribe((response) => {
            if (response["code"] == 0 || response["code"] == 1) {
              response["body"]?.responseList?.forEach(
                (cups: ImportResponseCups) => {
                  cups.responseCodeText = this.translate.instant(
                    "httpError" + cups.responseCode
                  );
                  if (cups.responseCodeText.includes("httpError")) {
                    cups.responseCodeText =
                      this.translate.instant("error-text") +
                      " " +
                      cups.responseCode;
                  }
                  cups.state = cups.responseCode == 0 ? true : false;
                  tableData.push({
                    nroSerie: cups.nroSerie,
                    state: cups.state,
                    responseCodeText: cups.responseCodeText,
                    errorCode: cups.responseCode,
                  });
                }
              );
            }

            if (response["code"] == 0) {
              this.ToastService.fireToastWithConfirmation(
                "success",
                this.translate.instant("success-import")
              );
            } else if (response["code"] == 1 && response["body"]) {
              this.ToastService.fireToastWithConfirmation(
                "warning",
                this.translate.instant("some-element-error")
              );
            }

            this.showTable = tableData.length > 0 ? true : false;
            if (this.showTable) {
              setTimeout(
                () => this.viewportScroller.scrollToAnchor("import-errors"),
                0
              );
            }
            this.tableData = tableData;
          });
        }
      });
    }
  }
}
