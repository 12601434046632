export const METER_DEACTIVATE_COLUMNS = [
  {
    title: "NRO_SERIE",
    info: "nro-serie-import",
    required: true,
    profilesList: [],
    preselect: [],
  },
];

