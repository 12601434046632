import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { CupsControllerService } from "../../../../../services/server/CupsController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { UserControllerService } from "../../../../../services/server/UserController.service";
// Interface
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  CupsAssignableDevice,
  CupsSensor,
  CupsSubscriber,
  CupsUser,
} from "../../CupsInterface.type";

@Component({
  selector: "app-cups-detail-dialog",
  templateUrl: "./cups-detail-dialog.component.html",
  styleUrls: ["./cups-detail-dialog.component.scss"],
})
export class CupsDetailDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  dropdownData: any[];

  sensorTableColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "manufacturer",
      data: "manufacturerText",
      search: "manufacturerText",
      sort: "manufacturerText",
      visible: true,
    },
    {
      title: "model",
      data: "modelText",
      search: "modelText",
      sort: "modelText",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    public SessionDataService: SessionDataService,
    private UserController: UserControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit() {
    if (this.data.action == "subscriber") {
      this.getSubscriberList();
    } else if (this.data.action == "replace" || this.data.action == "assign") {
      this.getDropdown(this.data.metrologyType);
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Asignación de dispositivo a CUPS
  deviceAssign(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("assign-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CupsController.meterAsignate(
          this.data.cupsId,
          this.data.selected
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("cups-assigned")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Sustitución de dispositivo
  deviceReplace(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("replace-device-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CupsController.replaceMeter(
          this.data.deviceId,
          this.data.selected
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("replace-device-sucessfull")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Obtención del desplegable del modal
  getDropdown(metrologyType: string): void {
    if (metrologyType == "-1") {
      let waterMetersRequest: Observable<object> =
        this.CupsController.meterDropdown(
          this.SessionDataService.getCurrentAgrupation().id,
          "0"
        );
      let gasMetersRequest: Observable<object> =
        this.CupsController.meterDropdown(
          this.SessionDataService.getCurrentAgrupation().id,
          "2"
        );
      let watersMeters: CupsAssignableDevice[] = [];
      let gasMeters: CupsAssignableDevice[] = [];

      forkJoin([waterMetersRequest, gasMetersRequest]).subscribe((results) => {
        if (results[0]["code"] === 0 && results[0]["body"].length > 0) {
          watersMeters = results[0]["body"].map(
            (meter: CupsAssignableDevice) => {
              return { id: meter.id, nroSerie: meter.nroSerie };
            }
          );
        }
        if (results[1]["code"] === 0 && results[1]["body"].length > 0) {
          gasMeters = results[1]["body"].map((meter: CupsAssignableDevice) => {
            return { id: meter.id, nroSerie: meter.nroSerie };
          });
        }
        this.dropdownData = watersMeters.concat(gasMeters);
      });
    } else {
      this.CupsController.meterDropdown(
        this.SessionDataService.getCurrentAgrupation().id,
        metrologyType
      ).subscribe((response) => {
        if (response["code"] == 0) {
          let deviceList: CupsAssignableDevice[] = response["body"]
            ? response["body"]
            : [];
          this.dropdownData = deviceList;
        } else {
          this.dropdownData = [];
        }
      });
    }
  }

  // Guardar
  save(): void {
    switch (this.data.action) {
      case "subscriber":
        this.selectSubscriber();
        break;
      case "replace":
        this.deviceReplace();
        break;
      case "assign":
        this.deviceAssign();
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla de sensores
  tableActions(action: string, sensor: CupsSensor): void {
    switch (action) {
      case "delete":
        this.SessionDataService.sendDialogAction({
          action: "delete",
          data: sensor.id,
        });
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Abonado
  /***************************************************************************/

  // Obtención de la lista de abonados
  getSubscriberList() {
    this.UserController.getSubscriberList(this.data.cupsId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.dropdownData = response["body"]?.map(
            (subscriber: CupsSubscriber) => {
              subscriber.label =
                this.translate.instant("user") +
                ": " +
                subscriber.username +
                "; " +
                this.translate.instant("name") +
                ": " +
                subscriber.name +
                " " +
                subscriber.surname;
              return subscriber;
            }
          );
        }
      }
    );
  }

  // Actualización del abonado asociado al CUPS
  selectSubscriber(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("subscriber-save-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: CupsUser = {
          userId: this.data.selectedSubscriber,
          cupsList: [this.data.cupsId],
        };
        this.CupsController.updateSubscriber(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }
}
