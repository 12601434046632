// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// File Saver
import saveAs from "file-saver";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Servicios propios
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { DomControllerService } from "../../../../../services/shared/DomControllerService.service";
import { GatewayVersionParserService } from "../../GatewaVersionParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
  TableGlobalAction,
  TableHighlightRow,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  Gateway,
  GatewayGraphData,
  GatewayMongoGraphData,
} from "../../../../../interfaces/GatewayGlobalInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import { GraphColorByPoint } from "../../../../../modules/graph-module/GraphInterface.type";
import { GATEWAY_STATES } from "../../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { GatewayDetailDialogComponent } from "../../gateway-detail/gateway-detail-dialog/gateway-detail-dialog.component";

@Component({
  selector: "app-gatewayslistassign",
  templateUrl: "./gateways-assigned.component.html",
  styleUrls: ["./gateways-assigned.component.scss"],
})
export class GatewaysListAssignComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("gateway-export") +
    " " +
    this.DateParserService.getDate();
  tableData: Gateway[];
  tableDataOriginal: Gateway[];
  tableSelectedData: Gateway[];
  orderBy: object = { attribute: "unidadVenta", reverse: false };
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "end-points-total",
      icon: "fas fa-tachometer-alt",
      selectionRequired: true,
      help: "help-table-end-points",
    },
  ];
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightNoActive",
      color: "yellow",
      title: "inactive",
    },
  ];
  columns: (TableActionColumn | TableDataColumn | TableSelectColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        // {
        //   name: "change-state",
        //   tooltip: "change-state",
        //   icon: "fas fa-arrows-alt-h",
        //   visible: { attribute: null, rule: true },
        //   disabled: false,
        //   warning: true,
        // },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "AMEI",
      data: "amei",
      search: "amei",
      sort: "amei",
      visible: true,
    },
    {
      title: "version-firmware",
      data: "fwVersion",
      search: "fwVersion",
      sort: "fwVersion",
      visible: true,
    },
    {
      title: "version-hardware",
      data: "hwVersion",
      search: "hwVersion",
      sort: "hwVersion",
      visible: true,
    },
    {
      title: "band",
      data: "bandaParsed",
      search: "bandaParsed",
      sort: "bandaParsed",
      visible: true,
    },
    {
      title: "micro",
      data: "microParsed",
      search: "microParsed",
      sort: "microParsed",
      visible: true,
    },
    {
      title: "gateway-options-mramqspi",
      data: "mramQspi",
      search: "mramQspi",
      sort: "mramQspi",
      boolean: true,
      alter: {
        condition: "mramQspi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramspi",
      data: "mramSpi",
      search: "mramSpi",
      sort: "mramSpi",
      boolean: true,
      alter: {
        condition: "mramSpi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-gps",
      data: "gps",
      search: "gps",
      sort: "gps",
      boolean: true,
      alter: {
        condition: "gps",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "LTE",
      data: "lte",
      search: "lte",
      sort: "lte",
      boolean: true,
      alter: {
        condition: "lte",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "channels",
      data: "canalesParsed",
      search: "canalesParsed",
      sort: "canalesParsed",
      numerical: true,
      visible: true,
    },
    {
      title: "gateway-options-port",
      data: "localPort",
      search: "localPort",
      sort: "localPort",
      boolean: true,
      alter: {
        condition: "localPort",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-debug",
      data: "debug",
      search: "debug",
      sort: "debug",
      boolean: true,
      alter: {
        condition: "debug",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      visible: true,
    },
    {
      title: "installation",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      visible: true,
    },
    {
      title: "last-file",
      data: "lastFileReceivedParsed",
      search: "lastFileReceivedParsed",
      sort: "lastFileReceived",
      date: true,
      visible: true,
    },
    {
      title: "signal",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-battery",
      data: "lastVbatParsed",
      search: "lastVbatParsed",
      sort: "lastVbat",
      numerical: true,
      visible: true,
    },
    {
      title: "main-meters",
      data: "nroMainContadoresParsed",
      search: "nroMainContadoresParsed",
      sort: "nroMainContadores",
      numerical: true,
      visible: true,
    },
    {
      title: "redundant-meter-number",
      data: "nroRedundantContadoresParsed",
      search: "nroRedundantContadoresParsed",
      sort: "nroRedundantContadores",
      numerical: true,
      visible: true,
    },
    {
      title: "meters",
      data: "totalMetersParsed",
      search: "totalMetersParsed",
      sort: "totalMeters",
      numerical: true,
      visible: true,
    },
    {
      title: "end-points-total",
      data: "endPoints",
      search: "endPoints",
      sort: "endPoints",
      numerical: true,
      visible: true,
    },
  ];

  // Menú de opciones del componente
  exportIcon: string = "fa fa-download";
  exportTitle: string = this.translate.instant("export-all-gateways");

  // Gráfica
  graphSeries: object[];
  graphData: GatewayGraphData[];
  mongoGraphData: GatewayMongoGraphData[][];
  highchartsOptions: object;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("6");
  graphTemperatureActive: boolean = false;
  graphNoiseActive: boolean = false;
  from: string;
  to: string;
  graphSource: string = "mongo";
  colorByPoint: GraphColorByPoint;
  sateliteShowVcc: boolean = true;
  seriesToggle: { serie: string; visible: boolean }[] = [
    { serie: "tension", visible: true },
  ];

  // Selector de agrupación
  agrupationList: Agrupation[];
  selectedAgrupation: Agrupation;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DomControllerService: DomControllerService,
    private GatewayController: GatewayControllerService,
    private GatewayVersionParserService: GatewayVersionParserService,
    private GraphOptionsService: GraphOptionsService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.selectedAgrupation = this.currentAgrupation;
    this.agrupationList = [this.currentAgrupation].concat(
      this.currentEntity?.agrupations.filter(
        (agrupation: Agrupation) => agrupation.showAllEntity
      )
    );
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.GatewayController.getInstalledGateways(
      this.selectedAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let tableData: Gateway[] = response["body"];
          tableData.forEach((gateway: Gateway) => {
            gateway.totalMeters =
              gateway.nroMainContadores + gateway.nroRedundantContadores;
            gateway.mainMeters = gateway.nroMainContadores;
            gateway.stateParsed = gateway.state
              ? this.translate.instant(GATEWAY_STATES[gateway.state])
              : null;
            gateway.highlightNoActive =
              gateway.state != GATEWAY_STATES.ACTIVATED;
            // Versión
            gateway.bandaParsed = this.GatewayVersionParserService.getBand(
              gateway.banda
            );
            gateway.microParsed = this.GatewayVersionParserService.getMicro(
              gateway.micro
            );
            // Canales
            let canalesBinary = gateway.canales?.toString(2);
            gateway.canalesParsed = canalesBinary
              ?.split("")
              ?.filter((value) => value).length;
            // Opciones
            if (gateway.options != null) {
              this.GatewayVersionParserService.setGatewayOptions(gateway);
            }
          });
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, gateway: Gateway): void {
    switch (action) {
      case "show-gateway":
        this.router.navigate(["/gateways/detalle/gateway/" + gateway.id]);
        break;
      case "change-state":
        this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
          action: "changeState",
          gateway: gateway,
        });
        break;
      default:
        break;
    }
  }

  // Exportación de todos los gateways
  exportAllGateways(): void {
    this.GatewayController.download(this.selectedAgrupation.id).subscribe(
      (response) => {
        if (response != null && response["code"] != 1000) {
          saveAs(
            response,
            this.translate.instant("gateways-list-export") +
              " " +
              this.DateParserService.getDate() +
              ".csv"
          );
        }
      }
    );
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-selected-graph":
        this.loadGraph();
        this.DomControllerService.goToElement("#gateways-assigned-graph");
        break;
      case "end-points-total":
        this.getEndPoints();
        break;
      default:
        break;
    }
  }

  // Obtención de los puntos finales de los gateways seleccionados
  getEndPoints(): void {
    this.tableSelectedData.map(
      (gateway) =>
        (gateway.endPoints = this.translate.instant("waiting-response") + "...")
    );
    this.tableSelectedData.forEach((gateway) => {
      this.GatewayController.getEndPointsTotal(gateway.id).subscribe(
        (response) => {
          if (response["code"] == 0) {
            gateway.endPoints =
              response["body"] != null
                ? response["body"]
                : this.translate.instant("unknown");
          } else {
            gateway.endPoints = this.translate.instant("unknown");
          }
        }
      );
    });
  }

  /***************************************************************************/
  // ANCHOR Gráfica
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.highchartsOptions
      ? this.loadGraphData(this.from, this.to)
      : this.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let mongoGraphData: GatewayMongoGraphData[][] = [];
    let requestArray: Observable<object>[] = this.tableSelectedData.map(
      (gateway: Gateway) => {
        return this.GatewayController.getMongoGraph(
          gateway.id,
          this.from,
          this.to
        );
      }
    );

    forkJoin(requestArray).subscribe((results) => {
      results.forEach((result) => {
        if (result["code"] === 0) {
          mongoGraphData.push(result["body"]["statusGwList"]);
        }
      });
      this.mongoGraphData = mongoGraphData;
      this.getSeries();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    const self = this;
    let colors: string[] = [
      "#7cb5ec",
      "#434348",
      "#90ed7d",
      "#f7a35c",
      "#8085e9",
      "#f15c80",
      "#e4d354",
      "#2b908f",
      "#f45b5b",
      "#91e8e1",
    ];
    let vbatsSeries: object[] = [];
    let chargeSeries: object[] = [];
    let graphSeries: object[][] = [];
    this.graphData = [];
    this.graphSeries = [];

    for (let i = 0; i < this.mongoGraphData?.length; i++) {
      if (this.mongoGraphData[i]?.length > 0) {
        this.graphData[i] = {
          gatewayId: null,
          vbats: [],
          charge: [],
          temperature: [],
        };

        this.graphData[i].gatewayId = this.mongoGraphData[i][0].gw;
        this.mongoGraphData[i]
          .sort((a, b) => a.tm - b.tm)
          .map((data: GatewayMongoGraphData) => {
            this.graphData[i].vbats.push([data.tm, data.vbat]);
            this.graphData[i].charge.push([
              data.tm - 3600000,
              data.carga,
              data.luz,
              data.parcial,
            ]);
            this.graphData[i].temperature.push([data.tm, data.temp]);
          });
      }
    }

    // Series
    for (let i = 0; i < this.graphData?.length; i++) {
      if (this.graphData[i]?.gatewayId) {
        let gateway = this.tableData.find(
          (gateway: Gateway) => gateway.id == this.graphData[i]?.gatewayId
        )?.unidadVenta;

        // Serie de valores de tensión
        if (this.sateliteShowVcc) {
          vbatsSeries = [
            {
              id: gateway + " tension",
              name: gateway + ": " + this.translate.instant("tension"),
              type: "line",
              navigatorOptions: {
                type: "line",
              },
              tooltip: {
                pointFormat:
                  '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                valueSuffix: " v",
                valueDecimals: 3,
              },
              color: colors[i % 10],
              data: this.graphData[i].vbats,
              dataGrouping: { approximation: "average" },
              zIndex: 1,
              yAxis: 1,
            },
          ];
        }

        // Serie de valores de carga
        this.colorByPoint = {
          serie: 1,
          color: "#f9d71c",
          conditionIndex: 3,
          conditionValue: 1,
          legend:
            `<div>
              <i class="fas fa-circle" style="color:#f9d71c; margin-right: 0.5rem;"></i>
              <span>` +
            this.translate.instant("partial-values") +
            `</span>
            </div>`,
        };
        chargeSeries = [
          {
            id: gateway + " carga",
            name: gateway + ": " + this.translate.instant("load"),
            type: "line",
            navigatorOptions: {
              type: "line",
            },
            tooltip: {
              valueDecimals: 3,
              pointFormatter: function () {
                return (
                  `<span style="color:` +
                  this.color +
                  `">` +
                  this.series.name +
                  "</span>: <b>" +
                  Highcharts.numberFormat(this.y, 3) +
                  ` mAh</b> |
                      <span style="color:` +
                  this.color +
                  `"> ` +
                  self.translate.instant("time-light") +
                  "</span>: <b>" +
                  Math.floor(this.series.options.data[this.index][2] / 60)
                    .toString()
                    .padStart(2, "0") +
                  ":" +
                  (this.series.options.data[this.index][2] % 60)
                    .toString()
                    .padStart(2, "0") +
                  "</b><br>"
                );
              },
            },
            color: colors[i % 10],
            data: this.graphData[i].charge,
            dataGrouping: { approximation: "sum" },
          },
        ];

        graphSeries[i] = vbatsSeries.concat(chargeSeries);
      }
    }

    graphSeries.forEach((gatewaySeries: any) =>
      gatewaySeries.forEach((serie: any) => this.graphSeries.push(serie))
    );

    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("gateways-export")
      );
    highchartsOptions.scrollbar = { enabled: false };
    highchartsOptions.plotOptions.series.marker.enabled = false;
    highchartsOptions.tooltip.split = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    delete chartOptions["yAxis"];
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"] = [
      // Batería
      {
        title: {
          text: this.translate.instant("battery-load"),
          style: {
            color: "#42a5f5",
            fontWeight: "bold",
          },
        },
        labels: {
          format: "{value} mAh ",
          style: {
            color: "#42a5f5",
          },
        },
        min: 0,
        visible: true,
        opposite: false,
      },
    ];
    if (this.sateliteShowVcc) {
      chartOptions["yAxis"].push(
        // Tensión
        {
          title: {
            text: this.translate.instant("battery-tension"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value} v",
            style: {
              color: "#ef5350",
            },
          },
          min: 0,
          // max: 20,
          visible: true,
          opposite: true,
        }
      );
    }
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }
}
