<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "edit" | translate }}: {{ data.nroSerie }}</h5>
  </div>
  <div class="dialog-body">
    <!-- devEui -->
    <div>
      <mat-form-field class="alarm-edit-comment" appearance="standard">
        <mat-label>Dev Eui</mat-label>
        <input
          matInput
          [(ngModel)]="data.devEui"
          appHexInput
          placeholder="{{ 'introduce' | translate }} Dev Eui  (16 {{
            'character' | translate
          }})..."
          maxlength="16"
          minlength="16"
          required
        />
      </mat-form-field>
    </div>
    <!-- appEui -->
    <div>
      <mat-form-field class="alarm-edit-comment" appearance="standard">
        <mat-label>App Eui</mat-label>
        <input
          matInput
          [(ngModel)]="data.appEui"
          appHexInput
          placeholder="{{ 'introduce' | translate }} App Eui (16 {{
            'character' | translate
          }})..."
          maxlength="16"
          minlength="16"
          required
        />
      </mat-form-field>
    </div>
    <!-- Network key -->
    <div>
      <mat-form-field class="alarm-edit-comment" appearance="standard">
        <mat-label>Network key</mat-label>
        <input
          matInput
          [(ngModel)]="data.networkKey"
          appHexInput
          placeholder="{{ 'introduce' | translate }} Network Key (32 {{
            'character' | translate
          }})..."
          maxlength="32"
          minlength="32"
          required
        />
      </mat-form-field>
    </div>
    <!-- Application key -->
    <div>
      <mat-form-field class="alarm-edit-comment" appearance="standard">
        <mat-label>Application Key</mat-label>
        <input
          matInput
          [(ngModel)]="data.applicationKey"
          appHexInput
          placeholder="{{ 'introduce' | translate }} Application Key  (32 {{
            'character' | translate
          }})..."
          maxlength="32"
          minlength="32"
          required
        />
      </mat-form-field>
    </div>
    <!-- Other keys -->
    <div
      *ngFor="let otherKey of data.otherKeys; index as i; trackBy: trackByFn"
    >
      <div>
        <mat-form-field class="alarm-edit-comment" appearance="standard">
          <mat-label>Other Keys {{ i + 1 }}</mat-label>
          <input
            matInput
            appHexInput
            placeholder="{{ 'introduce' | translate }} Other Keys (32 {{
              'character' | translate
            }})..."
            maxlength="32"
            minlength="32"
            [(ngModel)]="data.otherKeys[i]"
            required
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="
        data.devEui && data.appEui && data.networkKey && data.applicationKey
          ? false
          : true
      "
      (click)="saveMeter()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
