import {
  Component,
  Injectable,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { Subject } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  constructor(private translate: TranslateService) {}

  firstPageLabel = this.translate.instant("paginator-first-page");
  itemsPerPageLabel = this.translate.instant("paginator-register-page");
  lastPageLabel = this.translate.instant("paginator-last-page");
  nextPageLabel = this.translate.instant("paginator-next-page");
  previousPageLabel = this.translate.instant("paginator-previous-page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("paginator-one");
    }
    let amountPages = Math.ceil(length / pageSize);
    return (
      this.translate.instant("paginator-page") +
      " " +
      (page + 1) +
      " " +
      this.translate.instant("of") +
      " " +
      amountPages
    );
  }
}

/**
 * @title Paginator internationalization
 */
@Component({
  selector: "app-material-paginator",
  templateUrl: "./material-paginator.component.html",
  styleUrls: ["./material-paginator.component.scss"],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
})
export class MaterialPaginatorComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input()
  get dataLength(): number {
    return this._dataLength;
  }
  set dataLength(dataLength: number) {
    this._dataLength = dataLength;
    this.updateGoto();
  }
  _dataLength: number;
  @Input()
  get maxReg(): number {
    return this._maxReg;
  }
  set maxReg(maxReg: number) {
    this._maxReg = maxReg;
    this.updateGoto();
  }
  _maxReg: number;
  @Input() dataPage: number;
  @Input() currentPage: number;
  goTo: number;
  pageNumbers: number[];
  pageSizeOptions: number[] = [10, 50, 100, 500, 1000, 5000];
  @Output() paginatorUpdated = new EventEmitter<PageEvent>();
  @Output() goToPage = new EventEmitter<number>();

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.updateGoto();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  updateGoto() {
    this.goTo = (this.currentPage || 0) + 1;
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this._dataLength / this.maxReg); i++) {
      this.pageNumbers.push(i);
    }
  }
}
