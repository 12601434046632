<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "reviewed-list" | translate }}</b>
        </h5>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'metersReview'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [orderBy]="orderBy"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          [highlightRow]="highlightRow"
          [quickFilters]="quickFilters"
          (tableAction)="tableActions($event.action.name, $event.element)"
          (dataRequest)="getData($event.from, $event.to)"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [initialSearch]="meterToSearch"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="meterList"
        >
        </app-table-controller>

        <div class="meter-list-map">
          <app-map-selector
            *ngIf="mapSelectorActive"
            id="meter-list-map"
            [dataList]="meterList"
            [originalDataList]="originalMeterList"
            [tableSelectedData]="tableSelectedData"
            [table]="tableController"
            [filterAttribute]="'nroSerie'"
            [anchorToScroll]="'table-panel'"
            (updateData)="meterList = $event"
          >
          </app-map-selector>
        </div>
      </div>
    </div>
  </div>
</section>
