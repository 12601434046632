<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b
            >{{ "coverage-contrast" | translate }}:
            {{ unidadVenta ? unidadVenta : ("unknown" | translate) }}</b
          >
        </h5>
      </div>

      <div #coverageContrastPanel class="panel-body coverage-contrast-panel">
        <!-- Mapa de contadores -->
        <div class="coverage-contrast-map">
          <app-map-controller
            *ngIf="mapHeight"
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            [imageOverlayOpacity]="contrastOpacity"
            [allowKml]="true"
            [gatewaysData]="gateways"
            [metersData]="devices"
          >
          </app-map-controller>

          <!-- Opacidad del contraste -->
          <div *ngIf="mapHeight && devices" class="coverage-contrast-opacity">
            <label for="radio">
              {{ "coverage-opacity" | translate }}
            </label>
            <div>
              <span>{{ "min" | translate }}</span
              ><span>{{ "max" | translate }}</span>
            </div>
            <input
              type="range"
              class="pointer"
              max="1"
              min="0.1"
              step="any"
              [(ngModel)]="contrastOpacity"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
