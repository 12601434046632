<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>

          <!-- Título -->
          <h5>{{ "user-list" | translate }}</h5>

          <!-- Menú de opciones del panel -->
          <app-material-panel-menu
            [options]="panelMenuOptions"
            (action)="menuAction($event)"
          ></app-material-panel-menu>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla de usuarios -->
        <app-table-controller
          [tableId]="'userList'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [highlightRow]="highlightRow"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [specialFilter]="true"
          [data]="userList"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
