<div class="dialog-container">
  <!-- Título del modal -->
  <div class="dialog-header">
    <h5 class="dialog-title">
      <i class="fas fa-exclamation-triangle"></i>
      <span class="control-modal-title-separator"></span>
      {{ data.cupsName ? data.cupsName : "CUPS" }}:
      <span class="control-modal-title-separator"></span>
      <b>{{ data.cups.cups ? data.cups.cups : "" }}</b>
      <i
        *ngIf="data?.cups?.id"
        class="fas fa-link control-modal-title-link"
        [routerLink]="['/cups/detalle/' + data.cups.id]"
      ></i>
      <span class="control-modal-title-separator"> |</span>
      {{ "meter" | translate }}:
      <span class="control-modal-title-separator"></span>
      <b>{{ data.cups.meterNroSerie ? data.cups.meterNroSerie : "" }}</b>
      <i
        *ngIf="data?.cups?.meterId"
        class="fas fa-link control-modal-title-link"
        [routerLink]="['/dispositivos/detalle/contador/' + data.cups.meterId]"
      ></i>
      <span class="control-modal-title-separator"> |</span>
      {{ "cause" | translate }}:
      <span class="control-modal-title-separator"></span>
      <b>{{ data.alarmCause ? data.alarmCause : "" }}</b>
    </h5>
  </div>

  <div class="dialog-body">
    <!-- Instrucciones -->
    <div class="control-modal-instructions">
      <h4>
        <b>{{ "instructions" | translate }}:</b>
      </h4>
      <ul>
        <li *ngFor="let instruction of instructions; index as i">
          <b>{{ i + 1 }}. </b>
          {{ instruction | translate }}
        </li>
      </ul>
    </div>

    <!-- Usuario -->
    <div class="control-modal-user">
      <!-- Tabla para tamaño escritorio -->
      <table class="control-user-table">
        <tr>
          <th>
            <i class="fas fa-user fa-fw"></i>
            {{ "user" | translate }}
          </th>
          <td>
            {{ data.user?.name != null ? data.user.name : "-" }}
            {{ data.user?.surname != null ? data.user.surname : "" }}
          </td>
        </tr>
        <tr>
          <th>
            <i class="fas fa-phone fa-fw"></i>
            {{ "phone" | translate }}
          </th>
          <td>
            {{
              data.user?.firstPhoneNumber != null
                ? data.user.firstPhoneNumber
                : ""
            }}
            {{
              data.user?.firstPhoneNumber != null &&
              data.user?.firstPhoneNumber != "" &&
              data.user?.secondPhoneNumber != null
                ? " / "
                : ""
            }}
            {{
              data.user?.secondPhoneNumber != null
                ? data.user.secondPhoneNumber
                : ""
            }}
          </td>
        </tr>
        <tr>
          <th>
            <i class="fa fa-map-signs fa-fw"></i>
            {{ "address" | translate }}
          </th>
          <td>
            {{ data.cups?.address != null ? data.cups.address : "" }}
            {{ data.user?.city != null ? " (" + data.user.city + ", " : "" }}
            {{ data.user?.province != null ? data.user.province + ")" : "" }}
          </td>
        </tr>
      </table>
    </div>

    <div class="control-modal-procedure">
      <!-- Estados -->
      <div class="control-modal-states">
        <!-- Contador -->
        <div class="control-modal-info-box">
          <div
            class="info-box"
            [ngClass]="
              data.currentAlarm != null
                ? 'bg-red-gradient'
                : 'bg-green-gradient'
            "
          >
            <span class="info-box-icon bg-none">
              <i class="fa fa-tachometer-alt"></i>
            </span>
            <div class="info-box-content control-modal-info-content">
              <span class="info-box-text">
                {{ "current-state" | translate }}
              </span>
              <span class="info-box-number">
                {{ data.currentAlarmParsed }}
              </span>
            </div>
          </div>
        </div>
        <!-- Válvula -->
        <ng-container *ngFor="let meter of data.meters">
          <div
            *ngIf="meter.meterValveState != null"
            class="control-modal-info-box"
          >
            <div class="info-box bg-red-gradient">
              <span class="info-box-icon bg-none">
                <i class="fa fa-faucet"></i>
              </span>
              <div class="info-box-content control-modal-info-content">
                <div class="modal-info-box-column">
                  <div>
                    <span class="info-box-text">
                      {{ "valve-state" | translate }}: {{ meter.meterNroSerie }}
                    </span>
                    <span class="info-box-number">
                      {{ meter.valveStateParsed }}
                    </span>
                  </div>
                  <div class="control-modal-button-container">
                    <button
                      class="btn btn-warning control-modal-button"
                      (click)="openValve(meter)"
                      [disabled]="meter.pendingState"
                    >
                      <i
                        class="fas"
                        [ngClass]="
                          meter.pendingState
                            ? 'fa-spinner fa-spin'
                            : 'fa-lock-open'
                        "
                      ></i>
                      {{ "open" | translate }}
                    </button>
                    <button
                      class="btn btn-warning control-modal-button"
                      (click)="closeValve(meter)"
                      [disabled]="meter.pendingState"
                    >
                      <i
                        class="fas"
                        [ngClass]="
                          meter.pendingState
                            ? 'fa-spinner fa-spin'
                            : 'fas fa-lock'
                        "
                      ></i>
                      {{ "close" | translate }}
                    </button>
                    <button
                      class="btn btn-primary control-modal-button"
                      (click)="readValve(meter)"
                      [disabled]="meter.pendingState"
                    >
                      <i
                        class="fas"
                        [ngClass]="
                          meter.pendingState
                            ? 'fa-spinner fa-spin'
                            : 'fa-search'
                        "
                      ></i>
                      {{ "check-state" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div></ng-container
        >
      </div>
    </div>

    <!-- Tabla de alertas -->
    <div class="control-modal-table">
      <app-table-controller
        [tableId]="'controlModal'"
        [export]="false"
        [columns]="columns"
        [rowNumbers]="rowNumbers"
        [maxReg]="tableMaxReg"
        [onlyTable]="onlyTable"
        [orderBy]="orderBy"
        [data]="data.events"
      >
      </app-table-controller>
    </div>
  </div>

  <div class="dialog-footer control-modal-footer">
    <button mat-button (click)="finishAlarm()">
      {{ "finish-alarm" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
