// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interface
import { SateliteSaveData } from "../../screens/dashboard/devices/SateliteInterface.type";

@Injectable({
  providedIn: "root",
})
export class SateliteControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Obtener programas de satelite
  getSateliteScheduler(sateliteId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/satelite/" + sateliteId + "/programas/listado"
    );
  }

  // Guardar programas de satelite
  saveSateliteScheduler(data: SateliteSaveData): Observable<object> {
    return this.HttpRequestService.post("/satelite/modifyPrograms", data);
  }
}
