<!-- Spinner de carga transparente -->
<ngx-spinner
  *ngIf="!disableSpinner"
  name="spinner-soft"
  zIndex="999999"
  color="#000000"
  bdColor="rgba(0,0,0,0)"
  type="null"
>
</ngx-spinner>

<!-- Spinner de carga -->
<ngx-spinner
  *ngIf="!disableSpinner"
  name="spinner-hard"
  zIndex="999999"
  bdColor="rgba(51,51,51,0.8)"
  color="#FFFFFF"
  type="cog"
>
  <h5
    [ngClass]="{ 'spinner-text': !hideDesktopMenus && gameActive && !showGame }"
  >
    <i>{{ spinnerText ? spinnerText : ("loading-data" | translate) }}</i>
  </h5>

  <div
    *ngIf="!hideDesktopMenus && gameActive && sessionProfile == 'ARSON'"
    class="loading-screen-game"
  >
    <div *ngIf="!showGame" class="spinner-game-advise">
      {{ "spinner-game-advise" | translate }}
      <button (click)="showGame = true">
        {{ "spinner-game-play" | translate }}
      </button>
    </div>
    <app-snake *ngIf="showGame"></app-snake>
  </div>
</ngx-spinner>

<!-- Spinner de carga -->
<ng-template #elseBlock>
  <div class="loading-spinner">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</ng-template>

<div class="wrapper" (dragover)="enableDrop ? $event.preventDefault() : null">
  <!-- Barra de navegación superior -->
  <app-navbar-top
    *ngIf="sessionProfile != 'ABONADO'"
    [loadSession]="loadSession"
    [hideDesktopMenus]="hideDesktopMenus"
    (logoutFlag)="logout()"
    (logoImgUrlUpdate)="logoImgUrlUpdate($event)"
  >
  </app-navbar-top>

  <!-- Barra de navegación lateral -->
  <app-navbar-side
    *ngIf="sessionProfile != 'ABONADO' && !hideDesktopMenus"
    [logoImgUrl]="logoImgUrl"
    (sidebarUpdate)="sidebarLocked = $event"
  >
  </app-navbar-side>

  <!-- Barra de navegación de abonado -->
  <app-subscriber-nav
    *ngIf="sessionProfile == 'ABONADO'"
    (logoutFlag)="logout()"
  >
  </app-subscriber-nav>

  <!-- Progreso de tareas -->
  <app-request-progress-panel
    *ngIf="sessionProfile != 'ABONADO'"
  ></app-request-progress-panel>

  <!-- Contenedor -->
  <div
    *ngIf="!cesiumMapActive"
    [@routeAnimations]="prepareRoute(outlet)"
    [ngClass]="{
      'dashboard-container': sessionProfile != 'ABONADO',
      'subscriber-dashboard-container': sessionProfile == 'ABONADO',
      'dashboard-container-toggled': sidebarLocked
    }"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </div>

  <!-- Cesium map -->
  <div
    *ngIf="sessionProfile != 'ABONADO'"
    class="dashboard-container"
    [ngClass]="{
      hidden: !cesiumMapActive,
      'dashboard-container-toggled': sidebarLocked
    }"
  >
    <app-cesium-map-container
      (cesiumMapActive)="cesiumMapActive = $event"
    ></app-cesium-map-container>
  </div>

  <!-- Footer -->
  <app-footerbar
    [subscriberFooter]="sessionProfile == 'ABONADO'"
    [sessionProfile]="sessionProfile"
    [appVersion]="appVersion"
    [sidebarLocked]="sidebarLocked"
  >
  </app-footerbar>
</div>
