// @angular
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { DeviceTypeService } from "../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-map-modal",
  templateUrl: "./map-modal.component.html",
  styleUrls: ["./map-modal.component.scss"],
})
export class MapModalComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  kmlExport: object[] = [
    { value: "all", name: this.translate.instant("all") },
    { value: "gateways", name: this.translate.instant("gateways") },
    { value: "devices", name: this.translate.instant("devices") },
    { value: "selection", name: this.translate.instant("selection") },
  ];
  kmlSelected: string = "gateways";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DeviceTypeService: DeviceTypeService,
    public router: Router,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  exportKml(): void {
    let kml: string =
      `<?xml version="1.0" encoding="UTF-8"?> 
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom"> 
<Document>
  <name>` +
      this.SessionDataService.getCurrentAgrupation().name +
      `</name>
<Style id="G_NO_COM"><IconStyle><scale>2</scale>
  <Icon><href>http://maps.google.com/mapfiles/kml/pushpin/red-pushpin.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ffffffff</color><scale>1</scale></LabelStyle></Style>
<Style id="G_COM"><IconStyle><scale>2</scale>
  <Icon><href>http://maps.google.com/mapfiles/kml/pushpin/blue-pushpin.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ffffffff</color><scale>1</scale></LabelStyle></Style>
<Style id="G_NO_DEV"><IconStyle><scale>2</scale>
  <Icon><href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ffffffff</color><scale>1</scale></LabelStyle></Style>

<Style id="D_OK"><IconStyle><scale>1</scale><Icon><href>http://maps.google.com/mapfiles/kml/paddle/grn-circle.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ff00ffff</color><scale>0</scale></LabelStyle></Style>
<Style id="D_PEN"><IconStyle><scale>1</scale><Icon><href>http://maps.google.com/mapfiles/kml/paddle/purple-circle.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ff00ffff</color><scale>0</scale></LabelStyle></Style>
<Style id="D_FAIL"><IconStyle><scale>1</scale><Icon><href>http://maps.google.com/mapfiles/kml/paddle/red-circle.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ff00ffff</color><scale>0</scale></LabelStyle></Style>
<Style id="D_MBUS"><IconStyle><scale>1</scale><Icon><href>http://maps.google.com/mapfiles/kml/paddle/blu-circle.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ff00ffff</color><scale>0</scale></LabelStyle></Style>
<Style id="D_EXT"><IconStyle><scale>1</scale><Icon><href>http://maps.google.com/mapfiles/kml/paddle/wht-circle.png</href></Icon>
  <hotSpot x="32" y="0" xunits="pixels" yunits="pixels"/></IconStyle>
  <LabelStyle><color>ff00ffff</color><scale>0</scale></LabelStyle></Style>
  `;

    if (this.kmlSelected == "gateways" || this.kmlSelected == "all") {
      kml +=
        `<Folder><name>` +
        this.translate.instant("gateways") +
        `</name><open>0</open>`;
      this.data.gateways.forEach((gateway) => {
        kml +=
          `<Placemark>
          <name>` +
          gateway.unidadVenta +
          `</name>
          <description>` +
          (gateway.contadoresPrincipal?.length == 0 &&
          gateway.contadoresRedundante?.length == 0
            ? this.translate.instant("without-devices")
            : gateway.comunica
            ? this.translate.instant("communicates")
            : this.translate.instant("no-communicates")) +
          `</description>
          <styleUrl>#` +
          (gateway.contadoresPrincipal?.length == 0 &&
          gateway.contadoresRedundante?.length == 0
            ? "G_NO_DEV"
            : gateway.comunica
            ? "G_COM"
            : "G_NO_COM") +
          `</styleUrl>
          <color>7fff0000</color>
          <Point><gx:drawOrder>2</gx:drawOrder>
            <coordinates>` +
          gateway.longitude +
          `,` +
          gateway.latitude +
          `</coordinates>
          </Point>
        </Placemark>`;
      });
      kml += `</Folder>`;
    }
    if (
      this.kmlSelected == "devices" ||
      this.kmlSelected == "all" ||
      this.kmlSelected == "selection"
    ) {
      if (this.SessionDataService.getCurrentAgrupation().showAllEntity) {
        let entity = this.SessionDataService.getCurrentEntity();
        entity.agrupations.forEach((agrupation) => {
          if (!agrupation.showAllEntity) {
            kml += `<Folder><name>` + agrupation.name + `</name><open>0</open>`;
            kml += this.getDevicesXML(
              this.data.devices.filter(
                (device) => device.agrupation == agrupation.id
              )
            );
            kml += `</Folder>`;
          }
        });
      } else {
        kml +=
          `<Folder><name>` +
          this.translate.instant("devices") +
          `</name><open>0</open>`;
        kml += this.getDevicesXML(this.data.devices);
        kml += `</Folder>`;
      }
    }
    kml += `</Document>
    </kml>`;
    saveAs(
      new Blob([kml], {
        type: "application/xml",
      }),
      this.SessionDataService.getCurrentAgrupation().name + ".kml"
    );
    this.SessionDataService.sendDialogAction({ action: "close" });
  }

  // Obtención de dispositivo
  getDevicesXML(devices): string {
    let kml = "";
    devices.forEach((device) => {
      if (
        this.kmlSelected != "selection" ||
        (this.kmlSelected == "selection" && device.selected)
      ) {
        let deviceType = this.DeviceTypeService.getDeviceTypeByMask(
          device.tipo
        );
        kml +=
          `<Placemark>
            <name>` +
          device.nroSerie +
          `</name>
            <description>` +
          (device.unidadVentaGw ? "Gateway: " + device.unidadVentaGw : "") +
          `</description>
            <styleUrl>` +
          this.getDeviceColor(
            deviceType,
            device.unidadVentaGw,
            device.comunica
          ) +
          `</styleUrl>
            <Point><gx:drawOrder>1</gx:drawOrder>
              <coordinates>` +
          device.longitude +
          `,` +
          device.latitude +
          `</coordinates>
            </Point>
          </Placemark>`;
      }
    });
    return kml;
  }

  // Obtención del color de marcador
  getDeviceColor(
    deviceType: string,
    gateway: string,
    comunica: boolean
  ): string {
    if (deviceType == "MBUS" || deviceType == "WAVENIS") {
      return "D_MBUS";
    } else if (deviceType == "EXTERNO" || deviceType == "NO_LORA_NO_COM") {
      return "D_EXT";
    } else if (!gateway) {
      return "D_PEN";
    } else if (comunica) {
      return "D_OK";
    } else {
      return "D_FAIL";
    }
  }
}
