<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "entity-delete-conf-edit" | translate }}: {{ data.clientName }} |
      {{ data.entityDescripcion }}
    </h5>
  </div>
  <div class="dialog-body">
    <div class="management-entity-conf-edit">
      <label for="events">{{ "entity-data-erase" | translate }}</label>
      <app-material-toggle
        [onText]="'enabled' | translate"
        onColor="enabled"
        [offText]="'disabled' | translate"
        offColor="disabled"
        [toggle]="data.enabled"
        (change)="data.enabled = $event"
      ></app-material-toggle>
    </div>
    <div class="management-entity-conf-edit">
      <label for="days">{{ "days" | translate }}</label>
      <input type="number" name="days" [(ngModel)]="data.days" min="0" />
    </div>
    <div class="management-entity-conf-edit">
      <label for="readings">{{ "readings" | translate }}</label>
      <app-material-toggle
        [onText]="'enabled' | translate"
        onColor="enabled"
        [offText]="'disabled' | translate"
        offColor="disabled"
        [toggle]="data.readings"
        (change)="data.readings = $event"
      ></app-material-toggle>
    </div>
    <div class="management-entity-conf-edit">
      <label for="consumptions">{{ "consumptions" | translate }}</label>
      <app-material-toggle
        [onText]="'enabled' | translate"
        onColor="enabled"
        [offText]="'disabled' | translate"
        offColor="disabled"
        [toggle]="data.consumptions"
        (change)="data.consumptions = $event"
      ></app-material-toggle>
    </div>
    <div class="management-entity-conf-edit">
      <label for="events">{{ "events" | translate }}</label>
      <app-material-toggle
        [onText]="'enabled' | translate"
        onColor="enabled"
        [offText]="'disabled' | translate"
        offColor="disabled"
        [toggle]="data.events"
        (change)="data.events = $event"
      ></app-material-toggle>
    </div>
    <div class="management-entity-conf-edit">
      <label for="frames">{{ "frames" | translate }}</label>
      <app-material-toggle
        [onText]="'enabled' | translate"
        onColor="enabled"
        [offText]="'disabled' | translate"
        offColor="disabled"
        [toggle]="data.frames"
        (change)="data.frames = $event"
      ></app-material-toggle>
    </div>
    <div class="management-entity-conf-edit">
      <label for="alarms">{{ "alarms" | translate }}</label>
      <app-material-toggle
        [onText]="'enabled' | translate"
        onColor="enabled"
        [offText]="'disabled' | translate"
        offColor="disabled"
        [toggle]="data.alarms"
        (change)="data.alarms = $event"
      ></app-material-toggle>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="
        SessionDataService.sendDialogAction({ action: 'save', data: data });
        SessionDataService.sendDialogAction({ action: 'close' })
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
