// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// interfaces
import { Test } from "../../screens/dashboard/coverage/CoverageInterface.type";

@Injectable({
  providedIn: "root",
})
export class CoverageControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Datos de test de cobertura por agrupación filtrados por entidad
  getCoverage(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/testcobertura/agrupation/" + agrupationId
    );
  }

  // Datos de test de cobertura por agrupación
  getCoverageArson(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/testcobertura/all-gateways/agrupation/" + agrupationId
    );
  }

  // Borrado de test de cobertura
  deleteTest(data: Test): Observable<object> {
    return this.HttpRequestService.post("/testcobertura/delete/", data);
  }

  // Datos de cobertura MBus por agrupación
  getMbusCoverage(
    agrupationId: number,
    devicesIds: number[]
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/best-rssi/" + agrupationId,
      devicesIds
    );
  }
}
