// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { ManufacturerService } from "../../../../../../services/shared/ManufacturerService.service";
import { DeviceTypeService } from "../../../../../../services/shared/DeviceTypeService.service";
import { MeterService } from "../../MeterService.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { DeviceAgrupationDialogComponent } from "../../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { MeterAssignedDialogComponent } from "./meter-assigned-dialog/meter-assigned-dialog.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";
import { AssignedDevice, METER_ORDER } from "../../../DeviceInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";
import { VALVE_STATES } from "../../../DeviceInterface.type";

@Component({
  selector: "app-meterslistassign",
  templateUrl: "./meter-assigned.component.html",
  styleUrls: ["./meter-assigned.component.scss"],
})
export class MetersListAssignComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  deviceType: string;

  // Tabla
  tableSelectedData: AssignedDevice[];
  tableMaxReg: number = 100;
  meterList: AssignedDevice[];
  isGasAgrupation: boolean;
  originalMeterList: AssignedDevice[];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  quickFilters: TableQuickFilter[][];
  quickFiltersExclusion: boolean[] = [true, true];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "save-selection",
      icon: "fas fa-save",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
    {
      title: "show-consumption-evolution",
      icon: "fas fa-faucet",
      selectionRequired: true,
      help: "help-table-evolution",
    },
    {
      title: "association-new",
      icon: "fas fa-layer-group",
      selectionRequired: true,
      profiles: ["ARSON", "ADMIN_CLIENTE", "ADMIN_ENTIDAD", "ADMIN_AGRUPACION"],
      help: "help-table-asoc",
    },
    {
      title: "change-agrupation",
      icon: "fas fa-map",
      selectionRequired: true,
      profiles: ["ARSON", "ADMIN_CLIENTE", "ADMIN_ENTIDAD", "ADMIN_AGRUPACION"],
      help: "help-table-agrupation",
    },
    {
      title: "change-location",
      icon: "fas fa-map-marked-alt",
      selectionRequired: true,
      profiles: ["ARSON", "ADMIN_CLIENTE", "ADMIN_ENTIDAD", "ADMIN_AGRUPACION"],
      help: "help-table-evolution",
    },
    {
      title: "substitution-order",
      icon: "fas fa-exchange-alt",
      selectionRequired: true,
      profiles: ["ARSON"],
      submenu: [
        {
          title: "substitution-only-meter",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "substitution-only-rf",
          icon: "fas fa-tower-broadcast",
        },
        {
          title: "substitution-all",
          icon: "fas fa-sync-alt",
        },
      ],
    },
    {
      title: "deactivate-multiple",
      icon: "fas fa-eraser",
      selectionRequired: true,
      profiles: ["ARSON", "ADMIN_CLIENTE", "ADMIN_ENTIDAD", "ADMIN_AGRUPACION"],
      class: "btn-red",
      help: "help-table-deactivate",
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  stayOnRoute: boolean = false;

  // Menú de componente
  panelMenuOptions: PanelMenuOption[];
  exportIcon: string = "fas fa-download";
  exportTitle: string = this.translate.instant("export-all-devices");

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation")
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        } else {
          this.stayOnRoute = false;
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.deviceType = history.state.data;
    this.setPanelMenuOptions();
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.mapSelectorActive = false;
    this.MeterController.table(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          // Columnas extra
          let extraColumns: EntityDefinition[] = this.parseEntityDefinitions(
            response["body"]["entityDefinitions"]
          );
          let cups: EntityDefinition = extraColumns.find(
            (column: EntityDefinition) => column.colPosition == 0
          );
          let cupsIndex: number = extraColumns.findIndex(
            (column: EntityDefinition) => column.colPosition == 0
          );
          if (cupsIndex >= 0) {
            extraColumns.splice(cupsIndex, 1);
          }

          // Contadores
          let meterList: AssignedDevice[] =
            this.MeterService.parseAssignedMeterList(
              response["body"]["meterList"]
            );
          meterList.forEach((meter: AssignedDevice) => {
            meter.metrologyTypeParsed =
              meter.metrologyType != null
                ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
                : "-";
            meter.cups = meter.isAssigned ? meter.clave : null;
            meter.valveStateParsed =
              meter.valveState != null &&
              meter.valveState != -1 &&
              VALVE_STATES[meter.valveState]
                ? this.translate.instant(VALVE_STATES[meter.valveState].text)
                : this.translate.instant("unknown");
            meter.agrupationName = this.currentEntity.agrupations.find(
              (agrupation: Agrupation) => agrupation.id == meter.agrupation
            )?.name;
            meter.deviceType = this.ManufacturerService.getDeviceType(
              meter.fabricante,
              meter.devType
            );
            meter.isConcentrator =
              meter.metrologyType == 3 || meter.metrologyType == 5;
            meter.loadGraph =
              meter.metrologyType == METROLOGY_TYPE.WATER ||
              meter.metrologyType == METROLOGY_TYPE.GAS ||
              meter.metrologyType == METROLOGY_TYPE.SATELITE;
            meter.inNetwork = meter.comunica;
          });
          this.meterList = meterList;
          this.originalMeterList = meterList;
          this.isGasAgrupation = this.meterList.some(
            (meter) => meter.metrologyType == METROLOGY_TYPE.GAS
          );
          this.setColumns(cups, extraColumns);
          this.setQuickFilters();
        }
      }
    );
  }

  // Parseo de columnas de CUPS
  parseEntityDefinitions(entityDefinitions): EntityDefinition[] {
    return entityDefinitions.map((entityDefinition) => {
      return {
        id: entityDefinition.id,
        entity: entityDefinition.e,
        colPosition: entityDefinition.p,
        name: entityDefinition.n,
        label: entityDefinition.l,
        description: entityDefinition.d,
        show: entityDefinition.s,
        required: entityDefinition.r,
      };
    });
  }

  // Seteo de los filtros extra
  setQuickFilters(): void {
    this.quickFilters = [
      [
        {
          name: "in-network",
          columnSearch: "inNetwork",
          condition: { type: "boolean", rule: true },
          active: this.deviceType?.includes("active"),
        },
      ],
      [
        {
          name: "meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: 0 },
          active:
            this.deviceType?.includes("meter") ||
            this.deviceType?.includes("mbus") ||
            this.deviceType?.includes("une") ||
            this.deviceType?.includes("lw")
              ? true
              : false,
        },
        {
          name: "gas-meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: 2 },
          active:
            this.deviceType?.includes("meter") ||
            this.deviceType?.includes("lw"),
        },
        {
          name: "sensors",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: 1 },
          active: this.deviceType?.includes("sensor"),
        },
        {
          name: "concentrators",
          columnSearch: "isConcentrator",
          condition: { type: "boolean", rule: true },
          active: this.deviceType?.includes("concentrator"),
        },
        {
          name: "satelite",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: 4 },
          active: this.deviceType?.includes("satelite"),
        },
      ],
      [
        {
          name: "LoRaWAN",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "LW" },
          active: this.deviceType?.includes("lw"),
        },
        {
          name: "MBUS",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "MBUS" },
          active: this.deviceType?.includes("mbus"),
        },
        {
          name: "UNE",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "UNE" },
          active: this.deviceType?.includes("une"),
        },
        {
          name: "LoRaWAN MBUS",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "LW MBUS" },
          active: this.deviceType?.includes("lw"),
        },
        {
          name: this.translate.instant("external"),
          columnSearch: "deviceType",
          condition: { type: "text", rule: "EXTERNAL" },
          active: false,
        },
        {
          name: "ERM",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "ERM" },
          active: false,
        },
        {
          name: "EK280",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "EK280" },
          active: false,
        },
        {
          name: "PLUM",
          columnSearch: "deviceType",
          condition: { type: "text", rule: "PLUM" },
          active: false,
        },
        {
          name: this.translate.instant("no-lora-no-com"),
          columnSearch: "deviceType",
          condition: { type: "text", rule: "NO COM" },
          active: false,
        },
      ],
    ];
  }

  // Seteo de las columnas de la tabla
  setColumns(cups: EntityDefinition, extraColumns: EntityDefinition[]): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "meter-orders",
            tooltip: "meter-orders",
            icon: "fas fa-list-check",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "deactivate",
            tooltip: "deactivate",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: !(
              this.sessionProfile == "ARSON" ||
              this.sessionProfile == "ADMIN_ENTIDAD" ||
              this.sessionProfile == "ADMIN_CLIENTE" ||
              this.sessionProfile == "ADMIN_AGRUPACION"
            ),
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "type",
        data: "metrologyTypeParsed",
        search: "metrologyTypeParsed",
        sort: "metrologyTypeParsed",
        visible: true,
      },
      {
        title: cups?.name,
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: cups != null ? true : null,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "rf-module",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "FCV",
        data: "fcv",
        search: "fcv",
        sort: "fcv",
        visible: this.isGasAgrupation ? true : null,
      },
      {
        title: "PCS",
        data: "pcs",
        search: "pcs",
        sort: "pcs",
        visible: this.isGasAgrupation ? true : null,
      },
      {
        title: "groups",
        data: "agrupationName",
        search: "agrupationName",
        sort: "agrupationName",
        visible: this.currentAgrupation.showAllEntity ? true : null,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
      {
        title: "date-last-value",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: "month-consumption",
        data: "consumptionCardsLastMonthParsed",
        search: "consumptionCardsLastMonthParsed",
        sort: "consumptionCardsLastMonth",
        numerical: true,
        visible: true,
      },
      {
        title: "graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
      },
      {
        title: "readings-percentage",
        data: "pcParsed",
        search: "pcParsed",
        sort: "pc",
        numerical: true,
        visible: this.meterList.some(
          (meter: AssignedDevice) =>
            this.DeviceTypeService.getDeviceTypeByMask(meter.tipo) == "MBUS"
        )
          ? true
          : null,
      },
      {
        title: "last-consumption-min",
        data: "lastConsumptionMinParsed",
        search: "lastConsumptionMinParsed",
        sort: "lastConsumptionMin",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-max",
        data: "lastConsumptionMaxParsed",
        search: "lastConsumptionMaxParsed",
        sort: "lastConsumptionMax",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-total",
        data: "lastConsumptionTotalParsed",
        search: "lastConsumptionTotalParsed",
        sort: "lastConsumptionTotal",
        numerical: true,
        visible: true,
      },
      {
        title: "valve-state",
        data: "valveStateParsed",
        search: "valveStateParsed",
        sort: "valveStateParsed",
        visible: this.meterList?.some(
          (meter: AssignedDevice) => meter.valveState != null
        )
          ? true
          : null,
      },
      {
        title: "valve-state-date",
        data: "valveStateChangedTimestamp",
        search: "valveStateChangedTimestamp",
        sort: "valveStateChangedTimestamp",
        date: true,
        visible: this.meterList?.some(
          (meter: AssignedDevice) => meter.valveState != null
        )
          ? true
          : null,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: this.sessionProfile == "ARSON" ? true : null,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: this.sessionProfile == "ARSON" ? true : null,
      },
    ];
    extraColumns.forEach((extraColumn: EntityDefinition) => {
      let attributePosition: string =
        extraColumn.colPosition <= 9
          ? "col0" + extraColumn.colPosition
          : "col" + extraColumn.colPosition;
      if (extraColumn.colPosition != 100) {
        let newColumn: TableDataColumn = {
          title: extraColumn.name,
          data: attributePosition,
          search: attributePosition,
          sort: attributePosition,
          long: true,
          visible: true,
        };
        columns.push(newColumn);
      }
    });
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: AssignedDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "meter-orders":
        this.router.navigate(["/dispositivos/detalle/ordenes/" + meter.id], {
          state: { data: meter },
        });
        break;
      case "deactivate":
        this.resetMeter(meter);
        break;
      case "load-graph":
        this.MeterService.getAlarmGraph(meter);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "show-consumption-evolution":
        this.router.navigate(["/analisis-datos/evolucion-consumo"], {
          state: {
            data: this.tableSelectedData.map((device: AssignedDevice) => {
              return device.id;
            }),
          },
        });
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "change-location":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "changeLocation",
          meters: this.tableSelectedData,
        });
        break;
      case "deactivate-multiple":
        this.resetMultiple();
        break;
      case "association-new":
        this.router.navigate(["/analisis-datos/asociaciones/nuevo"], {
          state: {
            data: this.tableSelectedData.map(
              (device: AssignedDevice) => device.id
            ),
          },
        });
        break;
      case "save-selection":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "saveSelection",
          meters: this.tableSelectedData.map((meter) => {
            return {
              id: meter.id,
              nroSerie: meter.nroSerie,
              metrologyType: meter.metrologyType,
            };
          }),
        });
        break;
      case "substitution-only-meter":
        this.saveSubstitutionMeterOrder(METER_ORDER.SUSTITUTION_ONLY_DEVICE);
        break;
      case "substitution-only-rf":
        this.saveSubstitutionMeterOrder(METER_ORDER.SUSTITUTION_RF_MODULE);
        break;
      case "substitution-all":
        this.saveSubstitutionMeterOrder(
          METER_ORDER.SUSTITUTION_DEVICE_COMPLETE
        );
        break;
      default:
        break;
    }
  }

  // Guardado de orden de sustitución
  saveSubstitutionMeterOrder(order: number): void {
    this.ToastService.fireAlertWithTextarea(
      "info",
      this.translate.instant("substitution-order"),
      this.translate.instant("comments"),
      null,
      this.translate.instant("save"),
      this.translate.instant("cancel")
    ).then((userComments) => {
      if (userComments) {
        this.MeterController.saveOrder({
          meterList: this.tableSelectedData.map((device) => device.id),
          type: order,
          comments: userComments,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
          }
        });
      }
    });
  }

  // Redirección a contador
  showMeter(meter: AssignedDevice): void {
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.id
    );
  }

  // Reseteo de contador
  resetMeter(meter: AssignedDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Reseteo de múltiples contadores
  resetMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: AssignedDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("deactivate", data);
      }
    });
  }

  // Exportación de todos los contadores
  exportAll(): void {
    this.MeterController.download(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response != null && response["code"] != 1000) {
          saveAs(
            response,
            this.translate.instant("all-meters-list-export") +
              " " +
              this.DateParserService.getDate() +
              ".csv"
          );
        }
      }
    );
  }

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "update-data",
        icon: "fas fa-sync-alt",
        text: this.translate.instant("update"),
        visible: true,
      },
      {
        action: "export-all",
        icon: "fas fa-download",
        text: this.translate.instant("export-all-devices"),
        visible:
          this.sessionProfile == "ARSON" ||
          this.sessionProfile == "ADMIN_ENTIDAD" ||
          this.sessionProfile == "ADMIN_CLIENTE" ||
          this.sessionProfile == "ADMIN_AGRUPACION",
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "update-data":
        this.meterList = null;
        this.getData();
        break;
      case "export-all":
        this.exportAll();
        break;
      default:
        break;
    }
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((meter: AssignedDevice) => {
          return meter.id;
        }),
        to: null,
      },
    });
  }

  // Cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: this.tableSelectedData,
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }
}
