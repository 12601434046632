import { Card } from "../../../modules/card-module/CardInterface.type";

// ANCHOR Tarjetas
export class HomeCards {
  public meters?: Card;
  public concentrators?: Card;
  public satelites?: Card;
  public alerts?: Card;
  public gateways?: Card;
  public noComunication?: Card;
  public noAssigned?: Card;
  public lastAlarms?: Card;
  public sensors?: Card;
  public dayConsume?: Card;
  public monthConsume?: Card;
  public mbus?: Card;
  public wavenis?: Card;
  public une?: Card;
  public external?: Card;
  public nbiot?: Card;
  public valves?: Card;
  public txn?: Card;

  constructor() {
    this.alerts = null;
    this.gateways = null;
    this.noComunication = null;
    this.noAssigned = null;
    this.lastAlarms = null;
    this.dayConsume = null;
    this.monthConsume = null;
  }
}
