// @angular
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SensoryControllerService } from "../../../../../services/server/SensoryController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
// Interfaces
import {
  DetailDevice,
  DetailDeviceDataSerie,
} from "../../DeviceInterface.type";
import { SensorData } from "../../SensorInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-sensor-data",
  templateUrl: "./sensor-data.component.html",
  styleUrls: ["./sensor-data.component.scss"],
})
export class SensorDataComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() meter: DetailDevice;
  componentInitiated: boolean = false;

  // Gráfica
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  graphSeries: DetailDeviceDataSerie[];
  graphData: SensorData[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  from: string;
  to: string;
  @Output() noiseData = new EventEmitter<any>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GraphOptionsService: GraphOptionsService,
    private SensoryController: SensoryControllerService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.setHighchartsOptions();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadGraphData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let request =
      this.meter.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
        ? this.SensoryController.getLeakDetectorData(
            this.meter.id,
            this.from,
            this.to
          )
        : this.SensoryController.getAcousticReadings(
            this.meter.id,
            this.from,
            this.to
          );
    request.subscribe((response) => {
      if (response["code"] == 0) {
        this.graphData = response["body"];
        this.getSeries();
      }
    });
  }

  getSeries(): void {
    if (this.meter.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR) {
      this.graphSeries = [
        // Ruido
        {
          id: "noise",
          name: this.translate.instant("noise"),
          type: "area",
          fillOpacity: 0.2,
          data: this.graphData.map((data) => [data.timestamp, data.noise]),
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            valueSuffix: "",
          },
          yAxis: 0,
          color: "#ef5350",
        },
        // Frecuencia
        {
          id: "frequency",
          name: this.translate.instant("frequency"),
          type: "line",
          data: this.graphData.map((data) => [data.timestamp, data.frequency]),
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            valueSuffix: " Hz",
          },
          yAxis: 1,
          color: "#000",
        },
        // Amplitud
        {
          id: "amplitude",
          name: this.translate.instant("amplitude"),
          type: "line",
          data: this.graphData.map((data) => [data.timestamp, data.amplitude]),
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            valueSuffix: "",
          },
          yAxis: 2,
          color: "#4caf50",
        },
        // Temperatura
        {
          id: "temperature",
          name: this.translate.instant("temperature"),
          type: "line",
          data: this.graphData.map((data) => [data.timestamp, data.temp]),
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            valueSuffix: "°C",
          },
          yAxis: 3,
          color: "#ff9800",
        },
      ];
    } else {
      this.graphSeries = [
        // Ruido
        {
          id: "noise",
          name: this.translate.instant("noise"),
          type: "area",
          fillOpacity: 0.2,
          data: this.graphData.map((data) => [data.timestamp, data.value]),
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            valueSuffix: "",
          },
          yAxis: 0,
          color: "#ef5350",
        },
      ];
    }
    if (!this.componentInitiated) {
      this.noiseData.emit(this.graphSeries[0]);
      this.componentInitiated = true;
    }
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    const self = this;
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("meters-export")
      );
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    const self = this;
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["chart"]["height"] = "35%";
    if (this.meter.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR) {
      chartOptions["legend"]["enabled"] = true;
      chartOptions["yAxis"] = [
        // Ruido
        {
          title: {
            text: this.translate.instant("noise"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#ef5350",
            },
          },
          opposite: false,
          min: 0,
          max: 3000,
          showLastLabel: true,
          endOnTick: false,
        },
        // Frecuencia
        {
          title: {
            text: this.translate.instant("frequency"),
            style: {
              color: "#000",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value} Hz",
            style: {
              color: "#000",
            },
          },
          opposite: false,
          min: 0,
          max: 1000,
          showLastLabel: true,
          endOnTick: false,
        },
        // Amplitud
        {
          title: {
            text: this.translate.instant("amplitude"),
            style: {
              color: "#4caf50",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#4caf50",
            },
          },
          opposite: true,
          min: 0,
          max: 3000,
          showLastLabel: true,
          endOnTick: false,
        },
        // Temperatura
        {
          title: {
            text: this.translate.instant("temperature"),
            style: {
              color: "#ff9800",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}°C",
            style: {
              color: "#ff9800",
            },
          },
          opposite: true,
          min: 0,
          max: 50,
          showLastLabel: true,
          endOnTick: false,
        },
      ];
    } else {
      chartOptions["legend"]["enabled"] = false;
      chartOptions["yAxis"] = [
        // Ruido
        {
          title: {
            text: this.translate.instant("noise"),
            style: {
              color: "#000",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#000",
            },
          },
          opposite: false,
          min: 0,
          max: 3000,
          showLastLabel: true,
          endOnTick: false,
        },
      ];
    }
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }
}
