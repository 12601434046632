<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "events-list" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Tabla de alertas -->
    <div class="control-modal-table">
      <app-table-controller
        [tableId]="'alertHistoricEvents'"
        [export]="false"
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="100"
        [data]="data"
      >
      </app-table-controller>
    </div>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
