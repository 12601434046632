import { ExtraTableData } from "../../../modules/table-module/TableInterface.type";

// ANCHOR Datos de concentrador MBUS
export interface MbusConcentratorInfo {
  contadorInfoList: MbusMeter[];
  lastNroContadores: number;
  lastTimestamp: number;
  lastVBat: number;
  nroContadoresMedio: number;
  consumoBase: number;
  consumoLw: number;
  consumoMbus: number;
  consumoTransmision: number;
  listaReintentos: MbusMaskRetry[];
}

export interface MbusMaskRetry {
  contador: number;
  id: string;
  timestamp: number;
  value: number;
}

// ANCHOR Concentrador MBUS
export interface MbusMeter {
  // Back End
  elemento: string;
  elementoId: number;
  latitude: number;
  longitude: number;
  nroComunicaciones?: number;
  rssi?: number;
  ultimaComunicacion?: number;

  // Fron End
  comunica?: boolean;
}

// ANCHOR Concentrador MBUS para mapa
export interface MapMbusMeter {
  id: number;
  nroSerie: string;
  rssi?: number;
  latitude: number;
  longitude: number;
  lastComm?: number;
  totalComms?: number;
  tipo: number;
  metrologyType: number;
  unidadVentaGw?: string;
  comunica?: boolean;
  main?: boolean;
}

// ANCHOR Registro MBUS
export interface MbusRegister {
  nroContadores: number;
  timestamp: number;
  vbat: number;
}

// ANCHOR LW MBUS inactivos/desconocidos
export interface LWMbusInactiveOrUnkown {
  // Back End
  id: number;
  concentrador?: number;
  dirMbus?: string;
  rssi?: number;
  timestamp?: number;
  contador?: number;
  nro_serie?: string;
  fabricante?: number;
  password?: string;
  tipo?: number;
  metrologyType?: number;
  latitude?: number;
  longitude?: number;

  // Front End
  timestampParsed?: string;
  extraTableData?: ExtraTableData;
  decryptable?: boolean;
  nroSeriecalculated?: string;
  nroSerieToShow?: string;
  highlightCalculated?: string;
  concentratorTotal?: number;
}

// ANCHOR Datos de LW MBUS desconocido
export interface LWMbusUnknownData {
  // Back End
  id: number;
  latitude: number;
  longitude: number;
  nroSerie: string;
  lista?: LWMbusInactiveOrUnkown[];

  // Front End
  timestamp?: number;
  timestampParsed?: string;
  concentratorLink?: string;
  concentrator?: boolean;
  rssi: number;
  selected?: boolean;
  currentDevice?: string;
  undecryptable?: boolean;
}

// ANCHOR Concentrador asociado a contador MBUS
export interface MbusMeterConcentrator {
  id: number;
  nroSerie: string;
  rssi: number;
  latitude: number;
  longitude: number;
  lastComm: number;
  totalComms: number;
  tipo?: number;
  metrologyType?: number;
  unidadVentaGw?: string;
  comunica?: boolean;
  concentratorLink?: string;
  main?: boolean;
}

// ANCHOR LW MBUS contador fijado
export interface LwMbusMainMeter {
  id: number;
  concentrador: number;
  contador: number;
  dirMbus: string;
  rssi: number;
  timestamp: number;
  nro_serie: string;
}

// ANCHOR Hijo de concentrador
export interface ConcentratorChildMeter {
  id: number;
  nroSerie: string;
  rssi?: number;
  latitude?: number;
  longitude?: number;
  lastComm?: number;
  totalComms?: number;
  tipo?: number;
  metrologyType?: number;
  timestamp?: number;
  valor?: number;
  main?: boolean;
  privateKey?: boolean;
}

// ANCHOR Comando de LW MBUS
export interface LwMbusCommand {
  // Back End
  id: number;
  contador: number;
  gateway: number;
  gatewayDes: string;
  puerto: number;
  payload: string;
  timestampEnvio: number;
  nroComando: number;
  nroReintento: number;
  tipoProceso: number;
  responseCode: number;

  // Fron End
  tipoProcesoParsed: string;
  puertoParsed: string;
  timestampEnvioParsed: string;
  responseCodeParsed: string;
}

// ANCHOR Comandos LW MBUS
export const LW_MBUS_COMMAND = {
  LM_BORRADO_ACUMULADOS: 77,
  LM_BORRADO_DISPOSITIVOS: 78,
  LM_AJUSTES: 79,
  LM_DISPOSITIVOS: 80,
  LM_FABRICANTES: 81,
  LM_FABRICANTES_CANDIDATOS: 82,
  LM_DATALOGGER_PORT: 85,
  LM_DESCONOCIDOS: 86,
  LM_FIJOS: 87,
  LM_REINTENTO: 88,
  77: "LM_BORRADO_ACUMULADOS",
  78: "LM_BORRADO_DISPOSITIVOS",
  79: "LM_AJUSTES",
  80: "LM_DISPOSITIVOS",
  81: "LM_FABRICANTES",
  82: "LM_FABRICANTES_CANDIDATOS",
  85: "LM_DATALOGGER_PORT",
  86: "LM_DESCONOCIDOS",
  87: "LM_FIJOS",
  88: "LM_REINTENTO",
};

// ANCHOR Procesos de LW MBUS
export const LW_MBUS_PROCESS = {
  LW_PROCESO_INDIVIDUAL: 0,
  LW_PROCESO_CONFIGURACION: 1,
  LW_PROCESO_REINTENTO: 2,
  0: "LW_PROCESO_INDIVIDUAL",
  1: "LW_PROCESO_CONFIGURACION",
  2: "LW_PROCESO_REINTENTO",
};
