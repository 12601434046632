import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { IpControllerService } from "../../../../../services/server/IpController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interface
import { SaveIp, UpdatedWhiteIp } from "../IpLlistInterface.type";
import { Client } from "../../../../../interfaces/ClientGlobalInterface.type";

@Component({
  selector: "app-ip-list-dialog",
  templateUrl: "./ip-list-dialog.component.html",
  styleUrls: ["./ip-list-dialog.component.scss"],
})
export class IpListDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  clientList: Client[];
  initialSelection: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private IpController: IpControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.clientList = this.SessionDataService.getCurrentClientList();
    this.data.ip.clientId = this.clientList.find(
      (client: Client) => client.clientName == this.data.ip.client
    )?.clientId;
    this.initialSelection = this.data.ip.clientId;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización de IP
  updateIp(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("update-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: UpdatedWhiteIp = {
          id: this.data.ip.id,
          ip: this.data.ip.clientIp,
          client: this.data.ip.clientId,
        };
        this.IpController.updateWhiteIP(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Guardado de IP
  saveIp(): void {
    let data: SaveIp = {
      ip: this.data.ip.clientIp,
      client: this.data.ip.clientId,
    };
    this.IpController.saveWhiteIP(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
