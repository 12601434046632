// @angular
import { Component, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment_timezone from "moment-timezone";
import * as moment from "moment";
// Servicios propios
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { IpControllerService } from "../../../../services/server/IpController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { BlackIp, WhiteIp } from "./IpLlistInterface.type";
// Componentes
import { IpListDialogComponent } from "./ip-list-dialog/ip-list-dialog.component";

@Component({
  selector: "app-clientlist",
  templateUrl: "./ip-list.component.html",
  styleUrls: ["./ip-list.component.scss"],
})
export class IPListComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tabla de manteniemnto de los gateways
  tableMaxReg: number = 10;
  exportBlacklist: string =
    this.translate.instant("ip-blacklist-export") +
    " " +
    moment_timezone(moment()).format("ll");
  blacklist: BlackIp[];
  blacklistColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "IP",
      data: "clientIp",
      search: "clientIp",
      sort: "clientIp",
      visible: true,
    },
    {
      title: "ip-attemps",
      data: "loginAttempts",
      search: "loginAttempts",
      sort: "loginAttempts",
      visible: true,
    },
    {
      title: "ip-first-time",
      data: "firstLoginTimeParsed",
      search: "firstLoginTimeParsed",
      sort: "firstLoginTime",
      date: true,
      visible: true,
    },
    {
      title: "ip-last-login",
      data: "lastLoginTimeParsed",
      search: "lastLoginTimeParsed",
      sort: "lastLoginTime",
      date: true,
      visible: true,
    },
  ];

  exportWhitelist: string =
    this.translate.instant("ip-whitelist-export") +
    " " +
    moment_timezone(moment()).format("ll");
  whitelist: WhiteIp[];
  whitelistColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "update",
          tooltip: "update",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "IP",
      data: "clientIp",
      search: "clientIp",
      sort: "clientIp",
      visible: true,
    },
    {
      title: "client",
      data: "client",
      search: "client",
      sort: "client",
      visible: true,
    },
    {
      title: "ip-register",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
  ];
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new-ip");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private IpController: IpControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getData();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos de la tabla
  getData(): void {
    this.IpController.getBlockedIpList().subscribe((response) => {
      if (response["code"] == 0) {
        // Blacklist
        let blackList: BlackIp[] = response["body"]?.blacklist;
        this.blacklist = blackList;

        // Whitelist
        let whitelist: WhiteIp[] = response["body"]?.whiteList;
        this.whitelist = whitelist;
      } else {
        this.blacklist = [];
        this.whitelist = [];
      }
    });
  }

  // Acciones de la tabla blacklist
  blacklistTableActions(action: string, ip: BlackIp): void {
    switch (action) {
      case "delete":
        this.deleteIp(ip, false);
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla whitelist
  whitelistTableActions(action: string, ip: WhiteIp): void {
    switch (action) {
      case "delete":
        this.deleteIp(ip, true);
        break;
      case "update":
        this.MaterialDialogService.openDialog(IpListDialogComponent, {
          ip: ip,
          updating: true,
        });
        break;
      default:
        break;
    }
  }

  // Nueva IP
  newIp(): void {
    this.MaterialDialogService.openDialog(IpListDialogComponent, {
      ip: {
        clientIp: null,
        clientId: null,
      },
      updating: false,
    });
  }

  // Borrado de IP
  deleteIp(ip: any, whitelist: boolean): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("delete-ip-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let url = whitelist
            ? this.IpController.removeWhiteIP(ip.id)
            : this.IpController.removeIP(ip.clientIp);
          url.subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("ip-deleted")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }
}
