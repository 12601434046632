<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="modal-title">{{ data.title }}</h5>
  </div>
  <!-- HTML -->
  <div *ngIf="data.html" class="dialog-body" [innerHtml]="parsedHtml"></div>
  <!-- Gráfica -->
  <div *ngIf="data.graph" class="dialog-body">
    <div *ngIf="data.graphHtml" [innerHtml]="data.graphHtml"></div>
    <div class="table-dialog-graph">
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [noTypeSelector]="true"
        [data]="chartOptions"
        [noDateSelector]="true"
      >
      </app-graph-controller>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
