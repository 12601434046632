// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, forkJoin, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componente
import { AlarmsListComponent } from "../alarms-table/alarms-table.component";
import { AlarmsDialogComponent } from "../alarms-dialog/alarms-dialog.component";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  DeviceAlarm,
  AlarmStateData,
  DeviceAlarmChecks,
  AlarmConfiguredDevice,
  AlarmsUpdate,
} from "../../AlarmInterface.type";

@Component({
  selector: "app-alarmconfiguration",
  templateUrl: "./alarms-configuration-agrupation.component.html",
  styleUrls: ["./alarms-configuration-agrupation.component.scss"],
})
export class AlarmsAgrupationConfigurationComponent
  implements OnInit, OnDestroy
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionLanguage: string;

  // Alarmas
  devicesList: DeviceAlarm[];
  devicesNotification: boolean = true;
  configuredDevices: AlarmConfiguredDevice[];
  // configuredCups: any[];
  alarmsType: string = "agrupation";
  @ViewChild(AlarmsListComponent) alarmsList: AlarmsListComponent;
  virtualAgrupation: boolean = false;

  // Menú
  panelMenuOptions = [
    {
      action: "save",
      icon: "fas fa-save fa-fw",
      text: this.translate.instant("save"),
      visible: true,
    },
    {
      action: "save-template",
      icon: "fas fa-paste fa-fw",
      text: this.translate.instant("save-entity-template"),
      visible: true,
    },
    {
      action: "load-template",
      icon: "fas fa-sync-alt fa-fw",
      text: this.translate.instant("load-entity-template"),
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Construtor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    // Escucha de cambios en los valores de entidad y agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation && this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let configuredDevices: Observable<object> =
      this.AlarmController.getConfigured(this.currentAgrupation.id);
    // let configuredCups = this.alarmsCtrl.getConfiguredCups(this.currentAgrupation.id);

    forkJoin([configuredDevices]).subscribe((results) => {
      if (results[0]["code"] === 0) {
        this.configuredDevices = results[0] ? results[0]["body"] : [];
        // this.configuredCups = results[1] ? results[1]["body"] : [];
        this.getData();
      }
    });
  }

  // Obtención de la lista de alarmas
  getData(): void {
    this.AlarmController.getAlarms(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"]) {
          let devicesList: DeviceAlarm[] = response["body"]["allMeterAlarms"];

          devicesList.forEach((device: DeviceAlarm) => {
            let manufacturer: { manufacturerText: string; deviceText: string } =
              this.ManufacturerService.getManufacturer(
                device?.fabricante?.toString(),
                device?.devType?.toString(),
                this.sessionLanguage
              );
            device.manufacturer =
              manufacturer.manufacturerText +
              (manufacturer.deviceText ? " - " + manufacturer.deviceText : "");
            device.configured = false;

            // Creación del campo checks asociado a los códigos de alarma del dispositivo
            device.checks = Object.keys(device.alarmList).map((key) => {
              return {
                id: device.alarmList[key],
                check: true,
                notification: true,
              };
            });

            // Comprobación de las alarmas de agrupación
            device.checks.forEach((alarm: DeviceAlarmChecks) => {
              response["body"]["disabledList"].forEach(
                (alarmDisabled: AlarmStateData) => {
                  if (
                    alarm.id == alarmDisabled.alarm_code &&
                    device.devType == alarmDisabled.dev_type &&
                    device.fabricante == alarmDisabled.fabricante
                  ) {
                    alarm.check = false;
                  }
                }
              );
            });

            // Comprobación de las notificaciones de agrupación
            device.checks.forEach((alarm: DeviceAlarmChecks) => {
              response["body"]["disabledNotificationList"].forEach(
                (notification: AlarmStateData) => {
                  if (
                    alarm.id == notification.alarm_code &&
                    device.devType == notification.dev_type &&
                    device.fabricante == notification.fabricante
                  ) {
                    alarm.notification = false;
                  }
                }
              );
            });

            // Comprobación de los dispositivos configurados
            this.configuredDevices.forEach(
              (configuredDevice: AlarmConfiguredDevice) => {
                if (
                  device.fabricante == configuredDevice.fabricante &&
                  device.devType == configuredDevice.devType
                ) {
                  device["configured"] = true;
                }
              }
            );

            device.allAlarmsCheck = !device.checks.find(
              (alarm: DeviceAlarmChecks) => !alarm.check
            );
            device.allNotificationsCheck = !device.checks.find(
              (alarm: DeviceAlarmChecks) => !alarm.notification
            );
          });

          this.devicesList = [
            ...devicesList.sort((a, b) =>
              a.manufacturer.localeCompare(b.manufacturer)
            ),
          ];
        } else {
          if (response["body"] == null) {
            this.virtualAgrupation = true;
          }
          this.devicesList = [];
        }
      }
    );
  }

  // Mostrar modal de elementos configurados
  showModal(device: DeviceAlarm): void {
    this.MaterialDialogService.openDialog(AlarmsDialogComponent, {
      configuredDevices: this.configuredDevices,
      device: device,
    });
  }

  // Guardar cambios
  saveAlarms(): void {
    this.ToastService
      .fireAlertWithOptions("warning", this.translate.instant("change-alarms"))
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let data: AlarmsUpdate = this.getUpdatedData();
          this.AlarmController.updateAlarms(data).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("change-alarms-sucessfull")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }

  // Obtención de los datos de alarmas actualizados
  getUpdatedData(): AlarmsUpdate {
    let updatedDevicesList: DeviceAlarm[] =
      this.alarmsList.getUpdatedDevicesList();
    let disabledAlarms: AlarmStateData[] = [];
    let disabledNotifications: AlarmStateData[] = [];

    updatedDevicesList.map((device: DeviceAlarm) =>
      device.checks.map((alarm: DeviceAlarmChecks) => {
        if (!alarm.check) {
          disabledAlarms.push({
            alarm_code: alarm.id,
            dev_type: device.devType,
            fabricante: device.fabricante,
          });
        }
        if (!alarm.notification) {
          disabledNotifications.push({
            alarm_code: alarm.id,
            dev_type: device.devType,
            fabricante: device.fabricante,
          });
        }
      })
    );

    return {
      agrupation: this.currentAgrupation.id,
      disabledList: disabledAlarms,
      disabledNotificationList: disabledNotifications,
    };
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "save":
        this.saveAlarms();
        break;
      case "save-template":
        this.saveEntityTemplate();
        break;
      case "load-template":
        this.loadTemplate();
        break;
      default:
        break;
    }
  }

  // Guardado en plantilla de entidad
  saveEntityTemplate(): void {
    this.ToastService
      .fireAlertWithOptions(
        "warning",
        this.translate.instant("save-entity-template-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let data: AlarmsUpdate = this.getUpdatedData();
          this.AlarmController.partialUpdateEntityAlarmsTemplate(
            data
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast("success", this.translate.instant("saved"));
            }
          });
        }
      });
  }

  // Carga de plantilla en agrupación
  loadTemplate(): void {
    this.ToastService
      .fireAlertWithOptions("warning", this.translate.instant("change-alarms"))
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.AlarmController.applyEntityAlarmsTemplate(
            [this.currentAgrupation.id],
            this.currentEntity.id
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("change-alarms-sucessfull")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }
}
