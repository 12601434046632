// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import { TooltipOptions, Options } from "highcharts";
// Servicios propios
import { NetworkStateControllerService } from "../../../../services/server/NetworkStateController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  NetworkStateCards,
  NetworkStateGraphSerie,
} from "../NetworkStateInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-billingAgrupation",
  templateUrl: "./network-state-agrupation.component.html",
  styleUrls: ["./network-state-agrupation.component.scss"],
})
export class BillingAgrupationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Variables de las tarjetas
  cardsData: NetworkStateCards;
  cardsLoading: boolean;
  updateTitle: string =
    this.translate.instant("update") + " " + this.translate.instant("cards");

  // Gráfica
  graphSeries: NetworkStateGraphSerie[];
  graphTooltip: TooltipOptions = {
    valueDecimals: 0,
    split: false,
    headerFormat: "",
    pointFormat:
      '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
    dateTimeLabelFormats: {
      day: "%e " + this.translate.instant("of") + " %B",
      hour: "%e " + this.translate.instant("of") + " %B, %H:%M",
      week:
        this.translate.instant("week") +
        " %d " +
        this.translate.instant("of") +
        " %B %H:%M",
    },
  };
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private NetworkStateController: NetworkStateControllerService,
    private GraphOptionsService: GraphOptionsService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getCards();
  }

  // Obtención de los datos de las tarjetas y la tabla
  getCards(reload?: boolean): void {
    this.cardsLoading = true;
    let cardsData: NetworkStateCards = new NetworkStateCards();
    let request: Observable<object> =
      this.NetworkStateController.getAgrupationCards(this.currentAgrupation.id);

    if (reload) {
      request = this.NetworkStateController.getAgrupationNewCards(
        this.currentAgrupation.id
      );
    }

    request.subscribe((response) => {
      if (response["code"] == 0) {
        cardsData.metersInNetwork = {
          data: response["body"]["contadoresAsignados"],
          type: "number",
        };
        cardsData.metersValidRead = {
          data: response["body"]["contadoresLeidosDia"],
          type: "number",
        };
        cardsData.metersReadLastMonth = {
          data: response["body"]["contadoresLeidosMes"],
          type: "number",
        };
      }
      this.cardsData = cardsData;
      this.cardsLoading = false;
      this.setHighchartsOptions();
    });
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    let series: NetworkStateGraphSerie[] = [];
    this.NetworkStateController.getAgrupationGraph(
      this.currentAgrupation.id,
      from,
      to
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        series = [
          {
            id: "agrupacion",
            name: this.translate.instant("meters-installed-by-day"),
            data: response["body"].map((data, i) => {
              if (i > 0) {
                return [data[0], data[1] - response["body"][i - 1][1]];
              } else {
                return [data[0], 0];
              }
            }),
            tooltip: this.graphTooltip,
            dataGrouping: { approximation: "sum" },
            type: "column",
            yAxis: 0,
            color: "#42a5f5",
          },
          {
            id: "agrupacion",
            name:
              this.translate.instant("meters") +
              " " +
              this.translate.instant("actives").toLowerCase(),
            data: response["body"],
            tooltip: this.graphTooltip,
            dataGrouping: { approximation: "sum" },
            yAxis: 1,
            color: "#ef5350",
          },
        ];
      }

      this.graphSeries = series;
      this.setChartsOptions();
    });
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions = this.GraphOptionsService.getDefaultHighchartsOptions(
      this.translate.instant("billing-agrupation-export")
    );
    highchartsOptions.plotOptions.series.dataGrouping.units = [["day", [1]]];
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["rangeSelector"]["buttons"].shift();
    chartOptions["legend"] = { enabled: false };
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"] = [
      {
        title: {
          text: this.translate.instant("meters-installed-by-day"),
          style: {
            color: "#42a5f5",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            color: "#42a5f5",
          },
        },
        navigatorOptions: {
          type: "column",
        },
        visible: true,
        opposite: true,
      },
      {
        title: {
          text:
            this.translate.instant("meters") +
            " " +
            this.translate.instant("actives")?.toLowerCase(),
          style: {
            color: "#ef5350",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            color: "#ef5350",
          },
        },
        visible: true,
        opposite: false,
      },
    ];
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }
}
