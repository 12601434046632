/*
 * Copyright (C) 2023 Hermann Sewerin GmbH
 *
 * Redistribution and use in source and binary forms, with or without modification, are permitted provided that the following conditions are met:
 *
 * 1. Redistributions of source code must retain the above copyright notice, this list of conditions and the following disclaimer.
 *
 * 2. Redistributions in binary form must reproduce the above copyright notice, this list of conditions and the following disclaimer in the documentation and/or other materials provided with the distribution.
 *
 * 3. Neither the name of the copyright holder nor the names of its contributors may be used to endorse or promote products derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS “AS IS”
 * AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
 * WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
 * ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE
 * FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
 * DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR
 * SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED
 * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
 * SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */

/**
 * Decodes the given input for the SEP-EM protocol.
 *
 * @param {any} input - The input to be decoded.
 *
 * @returns {Object} An object with the following possible structures:
 * 1. On error:
 *   - { errors: string[]; data?: undefined; warnings?: undefined; }
 * 2. On success with warnings:
 *   - { data: {}; warnings: any[]; errors?: undefined; }
 * 3. On success without warnings:
 *   - { data: {}; errors?: undefined; warnings?: undefined; }
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors or warnings.
 */

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SepemDecoderService {
  constructor() {}

  sepem_decoder(input) {
    const self = this;
    if (input) {
      if (input.bytes) {
        if (input.bytes.length < 52) {
          var outputData = {};
          var outputWarnings = [];
          var identifiers = {
            255: {
              /* 0xFF */ name: "EvalABC",
              length: 7,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalABC_Noise =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];
                  var evalABC_Frequency =
                    bytes[3] == 255 && bytes[4] == 255
                      ? "Measurement not available."
                      : (bytes[3] << 8) + bytes[4];
                  var evalABC_Amplitude =
                    bytes[5] == 255 && bytes[6] == 255
                      ? "Measurement not available."
                      : (bytes[5] << 8) + bytes[6];

                  this.data = {
                    EvalABC_Noise: evalABC_Noise,
                    EvalABC_Frequency: evalABC_Frequency,
                    EvalABC_Amplitude: evalABC_Amplitude,
                  };

                  this.warnings = {
                    EvalABC_Noise: self.checkLimitMax(evalABC_Noise, 3000)
                      ? "EvalABC_Noise limit max: 3000 exceeded."
                      : null,
                    EvalABC_Frequency: self.checkLimitMax(
                      evalABC_Frequency,
                      1000
                    )
                      ? "EvalABC_Frequency limit max: 1000 exceeded."
                      : null,
                    EvalABC_Amplitude: self.checkLimitMax(
                      evalABC_Amplitude,
                      3000
                    )
                      ? "EvalABC_Amplitude limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalABC: "Incorrect EvalABC length.",
                  };
                }
              },
              warnings: {},
            },
            254: {
              /* 0xFE */ name: "EvalA7Days",
              length: 15,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalA7Days_Noise_Today =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];
                  var evalA7Days_Noise_Day_1 =
                    bytes[3] == 255 && bytes[4] == 255
                      ? "Measurement not available."
                      : (bytes[3] << 8) + bytes[4];
                  var evalA7Days_Noise_Day_2 =
                    bytes[5] == 255 && bytes[6] == 255
                      ? "Measurement not available."
                      : (bytes[5] << 8) + bytes[6];
                  var evalA7Days_Noise_Day_3 =
                    bytes[7] == 255 && bytes[8] == 255
                      ? "Measurement not available."
                      : (bytes[7] << 8) + bytes[8];
                  var evalA7Days_Noise_Day_4 =
                    bytes[9] == 255 && bytes[10] == 255
                      ? "Measurement not available."
                      : (bytes[9] << 8) + bytes[10];
                  var evalA7Days_Noise_Day_5 =
                    bytes[11] == 255 && bytes[12] == 255
                      ? "Measurement not available."
                      : (bytes[11] << 8) + bytes[12];
                  var evalA7Days_Noise_Day_6 =
                    bytes[13] == 255 && bytes[14] == 255
                      ? "Measurement not available."
                      : (bytes[13] << 8) + bytes[14];

                  this.data = {
                    EvalA7Days_Noise_Today: evalA7Days_Noise_Today,
                    EvalA7Days_Noise_Day_1: evalA7Days_Noise_Day_1,
                    EvalA7Days_Noise_Day_2: evalA7Days_Noise_Day_2,
                    EvalA7Days_Noise_Day_3: evalA7Days_Noise_Day_3,
                    EvalA7Days_Noise_Day_4: evalA7Days_Noise_Day_4,
                    EvalA7Days_Noise_Day_5: evalA7Days_Noise_Day_5,
                    EvalA7Days_Noise_Day_6: evalA7Days_Noise_Day_6,
                  };

                  this.warnings = {
                    EvalA7Days_Noise_Today: self.checkLimitMax(
                      evalA7Days_Noise_Today,
                      3000
                    )
                      ? "EvalA7Days_Noise_Today limit max: 3000 exceeded."
                      : null,
                    EvalA7Days_Noise_Day_1: self.checkLimitMax(
                      evalA7Days_Noise_Day_1,
                      3000
                    )
                      ? "EvalA7Days_Noise_Day_1 limit max: 3000 exceeded."
                      : null,
                    EvalA7Days_Noise_Day_2: self.checkLimitMax(
                      evalA7Days_Noise_Day_2,
                      3000
                    )
                      ? "EvalA7Days_Noise_Day_2 limit max: 3000 exceeded."
                      : null,
                    EvalA7Days_Noise_Day_3: self.checkLimitMax(
                      evalA7Days_Noise_Day_3,
                      3000
                    )
                      ? "EvalA7Days_Noise_Day_3 limit max: 3000 exceeded."
                      : null,
                    EvalA7Days_Noise_Day_4: self.checkLimitMax(
                      evalA7Days_Noise_Day_4,
                      3000
                    )
                      ? "EvalA7Days_Noise_Day_4 limit max: 3000 exceeded."
                      : null,
                    EvalA7Days_Noise_Day_5: self.checkLimitMax(
                      evalA7Days_Noise_Day_5,
                      3000
                    )
                      ? "EvalA7Days_Noise_Day_5 limit max: 3000 exceeded."
                      : null,
                    EvalA7Days_Noise_Day_6: self.checkLimitMax(
                      evalA7Days_Noise_Day_6,
                      3000
                    )
                      ? "EvalA7Days_Noise_Day_6 limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalA7Days: "Incorrect EvalA7Days length.",
                  };
                }
              },
              warnings: {},
            },
            253: {
              /* 0xFD */ name: "EvalABCMonth",
              length: 7,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalABCMonth_Noise =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];
                  var evalABCMonth_Frequency =
                    bytes[3] == 255 && bytes[4] == 255
                      ? "Measurement not available."
                      : (bytes[3] << 8) + bytes[4];
                  var evalABCMonth_Amplitude =
                    bytes[5] == 255 && bytes[6] == 255
                      ? "Measurement not available."
                      : (bytes[5] << 8) + bytes[6];

                  this.data = {
                    EvalABCMonth_Noise: evalABCMonth_Noise,
                    EvalABCMonth_Frequency: evalABCMonth_Frequency,
                    EvalABCMonth_Amplitude: evalABCMonth_Amplitude,
                  };

                  this.warnings = {
                    EvalABCMonth_Noise: self.checkLimitMax(
                      evalABCMonth_Noise,
                      3000
                    )
                      ? "EvalABCMonth_Noise limit max: 3000 exceeded."
                      : null,
                    EvalABCMonth_Frequency: self.checkLimitMax(
                      evalABCMonth_Frequency,
                      1000
                    )
                      ? "EvalABCMonth_Frequency limit max: 1000 exceeded."
                      : null,
                    EvalABCMonth_Amplitude: self.checkLimitMax(
                      evalABCMonth_Amplitude,
                      3000
                    )
                      ? "EvalABCMonth_Amplitude limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalABCMonth: "Incorrect EvalABCMonth length.",
                  };
                }
              },
              warnings: {},
            },
            252: {
              /* 0xFC */ name: "EvalNoise",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalNoise =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];

                  this.data = {
                    EvalNoise: evalNoise,
                  };

                  this.warnings = {
                    EvalNoise: self.checkLimitMax(evalNoise, 3000)
                      ? "EvalNoise limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalNoise: "Incorrect EvalNoise length.",
                  };
                }
              },
              warnings: {},
            },
            251: {
              /* 0xFB */ name: "EvalFrequency",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalFrequency =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];

                  this.data = {
                    EvalFrequency: evalFrequency,
                  };

                  this.warnings = {
                    EvalFrequency: self.checkLimitMax(evalFrequency, 1000)
                      ? "EvalFrequency limit max: 1000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalFrequency: "Incorrect EvalFrequency length.",
                  };
                }
              },
              warnings: {},
            },
            250: {
              /* 0xFA */ name: "EvalAmplitude",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalAmplitude =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];

                  this.data = {
                    EvalAmplitude: evalAmplitude,
                  };

                  this.warnings = {
                    EvalAmplitude: self.checkLimitMax(evalAmplitude, 3000)
                      ? "EvalAmplitude limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalAmplitude: "Incorrect EvalAmplitude length.",
                  };
                }
              },
              warnings: {},
            },
            249: {
              /* 0xF9 */ name: "ActEvalNoise",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var actEvalNoise =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];

                  this.data = {
                    ActEvalNoise: actEvalNoise,
                  };

                  this.warnings = {
                    ActEvalNoise: self.checkLimitMax(actEvalNoise, 3000)
                      ? "ActEvalNoise limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    ActEvalNoise: "Incorrect ActEvalNoise length.",
                  };
                }
              },
              warnings: {},
            },
            248: {
              /* 0xF8 */ name: "FAB",
              length: 5,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var type = String(bytes[1]).padStart(3, "0");
                  var model = String(bytes[2]).padStart(2, "0");
                  var serial = String((bytes[3] << 8) + bytes[4]).padStart(
                    6,
                    "0"
                  );

                  this.data = {
                    FAB: type + " " + model + " " + serial,
                  };

                  this.warnings = {
                    FAB: self.checkLimitMin(serial, 1)
                      ? "Incorrect FAB number."
                      : null,
                  };
                } else {
                  this.warnings = {
                    FAB: "Incorrect FAB length.",
                  };
                }
              },
              warnings: {},
            },
            247: {
              /* 0xF7 */ name: "DeviceNr",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  this.data = {
                    DeviceNr: (bytes[1] << 8) + bytes[2],
                  };
                } else {
                  this.warnings = {
                    DeviceNr: "Incorrect DeviceNr length.",
                  };
                }
              },
              warnings: {},
            },
            246: {
              /* 0xF6 */ name: "DevState",
              length: 5,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var str1 = bytes[1] << 24;
                  var str2 = bytes[2] << 16;
                  var str3 = bytes[3] << 8;
                  var str4 = bytes[4];
                  var devState = (str1 + str2 + str3 + str4)
                    .toString(16)
                    .padStart(8, "0");

                  this.data = {
                    DevState: devState,
                  };
                } else {
                  this.warnings = {
                    DevState: "Incorrect DevState length.",
                  };
                }
              },
              warnings: {},
            },
            245: {
              /* 0xF5 */ name: "MeasTime",
              length: 8,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var year = (
                    (((bytes[1] << 8) + bytes[2]) & 0xfff0) >>
                    4
                  ).toString();
                  var month = (((bytes[1] << 8) + bytes[2]) & 0x000f)
                    .toString()
                    .padStart(2, "0");
                  var day = bytes[3].toString().padStart(2, "0");
                  var hour = (
                    (((bytes[4] << 16) + (bytes[5] << 8) + bytes[6]) &
                      0xfc0000) >>
                    18
                  )
                    .toString()
                    .padStart(2, "0");
                  var minute = (
                    (((bytes[4] << 16) + (bytes[5] << 8) + bytes[6]) &
                      0x03f000) >>
                    12
                  )
                    .toString()
                    .padStart(2, "0");
                  var date = new Date(
                    parseInt(year),
                    parseInt(month) - 1,
                    day,
                    parseInt(hour),
                    parseInt(minute)
                  );
                  var duration = (
                    ((bytes[4] << 16) + (bytes[5] << 8) + bytes[6]) &
                    0x000fff
                  ).toString();
                  var counter = bytes[7].toString();

                  this.data = {
                    MeasTime:
                      day +
                      "/" +
                      month +
                      "/" +
                      year +
                      " - " +
                      hour +
                      ":" +
                      minute +
                      "; " +
                      "Duration: " +
                      duration +
                      " min.; " +
                      "Counter = " +
                      counter,
                  };

                  this.warnings = {
                    MeasTime_Date: self.checkDate(
                      year,
                      parseInt(month) - 1,
                      day,
                      hour,
                      minute
                    )
                      ? date >= new Date(2000, 0, 1, 0, 0)
                        ? date <= new Date()
                          ? null
                          : "MeasTime date max exceeded."
                        : "MeasTime date min exceeded."
                      : "MeasTime date format inncorect.",
                    MeasTime_Duration: self.checkLimitMin(duration, 1)
                      ? "MeasTime duration limit min: 1 exceeded."
                      : self.checkLimitMax(duration, 1439)
                      ? "MeasTime duration limit max: 1439 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    MeasTime: "Incorrect MeasTime length.",
                  };
                }
              },
              warnings: {},
            },
            244: {
              /* 0xF4 */ name: "MeasTimeExt",
              length: 11,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var year = ((bytes[1] << 8) + bytes[2]).toString();
                  var month = bytes[3].toString().padStart(2, "0");
                  var day = bytes[4].toString().padStart(2, "0");
                  var hour = bytes[5].toString().padStart(2, "0");
                  var minute = bytes[6].toString().padStart(2, "0");
                  var date = new Date(year, month - 1, day, hour, minute);
                  var duration = ((bytes[7] << 8) + bytes[8]).toString();
                  var counter = ((bytes[9] << 8) + bytes[10]).toString();

                  this.data = {
                    MeasTimeExt:
                      day +
                      "/" +
                      month +
                      "/" +
                      year +
                      " - " +
                      hour +
                      ":" +
                      minute +
                      "; " +
                      "Duration: " +
                      duration +
                      " min.; " +
                      "Counter = " +
                      counter,
                  };

                  this.warnings = {
                    MeasTimeExt_Date: self.checkDate(
                      year,
                      month - 1,
                      day,
                      hour,
                      minute
                    )
                      ? date >= new Date(2000, 0, 1, 0, 0)
                        ? date <= new Date()
                          ? null
                          : "MeasTimeExt date max exceeded."
                        : "MeasTimeExt date min exceeded."
                      : "MeasTimeExt date format inncorect.",
                    MeasTimeExt_Duration: self.checkLimitMin(duration, 1)
                      ? "MeasTimeExt duration limit min: 1 exceeded."
                      : self.checkLimitMax(duration, 1439)
                      ? "MeasTimeExt duration limit max: 1439 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    MeasTimeExt: "Incorrect MeasTimeExt length.",
                  };
                }
              },
              warnings: {},
            },
            243: {
              /* 0xF3 */ name: "BattCap",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var battCap = bytes[1].toString();

                  this.data = {
                    BattCap: battCap + " %",
                  };

                  this.warnings = {
                    BattCap: self.checkLimitMax(battCap, 100)
                      ? "BattCap limit max: 100 % exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    BattCap: "Incorrect BattCap length.",
                  };
                }
              },
              warnings: {},
            },
            242: {
              /* 0xF2 */ name: "TimeStamp",
              length: 7,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var year = ((bytes[1] << 8) + bytes[2]).toString();
                  var month = bytes[3].toString().padStart(2, "0");
                  var day = bytes[4].toString().padStart(2, "0");
                  var hour = bytes[5].toString().padStart(2, "0");
                  var minute = bytes[6].toString().padStart(2, "0");
                  var date = new Date(year, month - 1, day, hour, minute);

                  this.data = {
                    TimeStamp:
                      day +
                      "/" +
                      month +
                      "/" +
                      year +
                      " - " +
                      hour +
                      ":" +
                      minute +
                      ";",
                  };

                  this.warnings = {
                    TimeStamp: self.checkDate(
                      year,
                      month - 1,
                      day,
                      hour,
                      minute
                    )
                      ? date >= new Date(2000, 0, 1, 0, 0)
                        ? date <= new Date()
                          ? null
                          : "TimeStamp date max exceeded."
                        : "TimeStamp date min exceeded."
                      : "TimeStamp date format inncorect.",
                  };
                } else {
                  this.warnings = {
                    TimeStamp: "Incorrect TimeStamp length.",
                  };
                }
              },
              warnings: {},
            },
            241: {
              /* 0xF1 */ name: "Error",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var error = (bytes[1] << 8) + bytes[2];

                  this.data = {
                    Error: error,
                  };
                } else {
                  this.warnings = {
                    Error: "Incorrect Error length.",
                  };
                }
              },
              warnings: {},
            },
            240: {
              /* 0xF0 */ name: "Temp",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var temp = bytes[1] > 128 ? bytes[1] - 256 : bytes[1];

                  this.data = {
                    Temp: temp.toString() + " \u2103",
                  };

                  this.warnings = {
                    Temp: self.checkLimitMin(temp, -40)
                      ? "Temp limit min: -40 \u2103 exceeded."
                      : self.checkLimitMax(temp, 85)
                      ? "Temp limit max: 85 \u2103 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    Temp: "Incorrect Temp length.",
                  };
                }
              },
              warnings: {},
            },
            239: {
              /* 0xEF */ name: "Sensity",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var sensity =
                    2 * (bytes[1] > 128 ? bytes[1] - 256 : bytes[1]);

                  this.data = {
                    Sensity: sensity.toString() + " dBm",
                  };

                  this.warnings = {
                    Sensity: self.checkLimitMin(sensity, -127)
                      ? "Sensity limit min: -127 dBm exceeded."
                      : self.checkLimitMax(sensity, 0)
                      ? "Sensity limit max: 0 dBm exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    Sensity: "Incorrect Sensity length.",
                  };
                }
              },
              warnings: {},
            },
            238: {
              /* 0xEE */ name: "Sensor",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var sensor =
                    bytes[1] == 81
                      ? "Noise"
                      : bytes[1] == 82
                      ? "Pressure"
                      : bytes[1] == 84
                      ? "Temperature"
                      : "Unknown";

                  this.data = {
                    Sensor: sensor,
                  };

                  this.warnings = {
                    Sensor:
                      bytes[1] == 81 || bytes[1] == 82 || bytes[1] == 84
                        ? null
                        : "Sensor unknown.",
                  };
                } else {
                  this.warnings = {
                    Sensor: "Incorrect Sensor length.",
                  };
                }
              },
              warnings: {},
            },
            237: {
              /* 0xED */ name: "Interval",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var interval = (bytes[1] << 8) + bytes[2];

                  this.data = {
                    Interval: interval,
                  };

                  this.warnings = {
                    Interval: self.checkLimitMin(interval, 1)
                      ? "Interval limit min: 1 exceeded."
                      : self.checkLimitMax(interval, 3600)
                      ? "Interval limit max: 3600 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    Interval: "Incorrect Interval length.",
                  };
                }
              },
              warnings: {},
            },
            236: {
              /* 0xEC */ name: "DevEUI",
              length: 9,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var str1 = bytes[1].toString(16).padStart(2, "0") + ":";
                  var str2 = bytes[2].toString(16).padStart(2, "0") + ":";
                  var str3 = bytes[3].toString(16).padStart(2, "0") + ":";
                  var str4 = bytes[4].toString(16).padStart(2, "0") + ":";
                  var str5 = bytes[5].toString(16).padStart(2, "0") + ":";
                  var str6 = bytes[6].toString(16).padStart(2, "0") + ":";
                  var str7 = bytes[7].toString(16).padStart(2, "0") + ":";
                  var str8 = bytes[8].toString(16).padStart(2, "0");
                  var devEUI =
                    str1 + str2 + str3 + str4 + str5 + str6 + str7 + str8;

                  this.data = {
                    DevEUI: devEUI,
                  };
                } else {
                  this.warnings = {
                    DevEUI: "Incorrect DevEUI length.",
                  };
                }
              },
              warnings: {},
            },
            235: {
              /* 0xEB */ name: "TelIdentExt",
              length: 6,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var str1 = bytes[1].toString(16).padStart(2, "0") + ":";
                  var str2 = bytes[2].toString(16).padStart(2, "0") + ":";
                  var str3 = bytes[3].toString(16).padStart(2, "0") + ":";
                  var str4 = bytes[4].toString(16).padStart(2, "0") + ":";
                  var str5 = bytes[5].toString(16).padStart(2, "0");
                  var telIdentExt = str1 + str2 + str3 + str4 + str5;

                  this.data = {
                    TelIdentExt: telIdentExt,
                  };
                } else {
                  this.warnings = {
                    TelIdentExt: "Incorrect TelIdentExt length.",
                  };
                }
              },
              warnings: {},
            },
            234: {
              /* 0xEA */ name: "BattVoltage",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var battVoltage = bytes[1] / 20.0;

                  this.data = {
                    BattVoltage: battVoltage.toString() + " V",
                  };

                  this.warnings = {
                    BattVoltage: self.checkLimitMax(battVoltage, 4)
                      ? "BattVoltage limit max: 4 V exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    BattVoltage: "Incorrect BattVoltage length.",
                  };
                }
              },
              warnings: {},
            },
            233: {
              /* 0xE9 */ name: "BattVoltage1",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var battVoltage1 = bytes[1] / 20.0;

                  this.data = {
                    BattVoltage1: battVoltage1.toString() + " V",
                  };

                  this.warnings = {
                    BattVoltage1: self.checkLimitMax(battVoltage1, 4)
                      ? "BattVoltage1 limit max: 4 V exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    BattVoltage1: "Incorrect BattVoltage1 length.",
                  };
                }
              },
              warnings: {},
            },
            232: {
              /* 0xE8 */ name: "BattVoltage2",
              length: 2,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var battVoltage2 = bytes[1] / 20.0;

                  this.data = {
                    BattVoltage2: battVoltage2.toString() + " V",
                  };

                  this.warnings = {
                    BattVoltage2: self.checkLimitMax(battVoltage2, 4)
                      ? "BattVoltage2 limit max: 4 V exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    BattVoltage2: "Incorrect BattVoltage2 length.",
                  };
                }
              },
              warnings: {},
            },
            231: {
              /* 0xE7 */ name: "Histo",
              length: 14,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var histo = "";
                  for (var k = 0; k < 13; k++) {
                    histo = histo + bytes[k + 1] / 2;
                    if (k < 12) {
                      histo = histo + ":";
                    }
                  }

                  this.data = {
                    Histo: histo,
                  };

                  histo = null;
                  for (var j = 0; j < 13; j++) {
                    if (self.checkLimitMax(bytes[j + 1] / 2, 100)) {
                      histo = "Histo limit max: 100 % exceeded.";
                      break;
                    }
                  }
                  this.warnings = {
                    Histo: histo,
                  };
                } else {
                  this.warnings = {
                    Histo: "Incorrect Histo length.",
                  };
                }
              },
              warnings: {},
            },
            230: {
              /* 0xE6 */ name: "MsgLimitCounter",
              length: 3,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var msgLimitCounter = (bytes[1] << 8) + bytes[2];

                  this.data = {
                    MsgLimitCounter: msgLimitCounter,
                  };
                } else {
                  this.warnings = {
                    MsgLimitCounter: "Incorrect MsgLimitCounter length.",
                  };
                }
              },
              warnings: {},
            },
            229: {
              /* 0xE5 */ name: "EvalABC3Days",
              length: 13,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalABC_1 =
                    (bytes[1] << 24) +
                    (bytes[2] << 16) +
                    (bytes[3] << 8) +
                    bytes[4];
                  var evalABC_2 =
                    (bytes[5] << 24) +
                    (bytes[6] << 16) +
                    (bytes[7] << 8) +
                    bytes[8];
                  var evalABC_3 =
                    (bytes[9] << 24) +
                    (bytes[10] << 16) +
                    (bytes[11] << 8) +
                    bytes[12];

                  var evalABC3Days_Noise =
                    evalABC_1 == -1
                      ? "Measurement not available."
                      : ((evalABC_1 & 0xfff00000) >> 20) & 0x0fff;
                  var evalABC3Days_Frequency =
                    evalABC_1 == -1
                      ? "Measurement not available."
                      : (4 * ((evalABC_1 & 0x000ff000) >> 12)) & 0x03ff;
                  var evalABC3Days_Amplitude =
                    evalABC_1 == -1
                      ? "Measurement not available."
                      : evalABC_1 & 0x00000fff;
                  var evalABC3Days_Noise_1 =
                    evalABC_2 == -1
                      ? "Measurement not available."
                      : ((evalABC_2 & 0xfff00000) >> 20) & 0x0fff;
                  var evalABC3Days_Frequency_1 =
                    evalABC_2 == -1
                      ? "Measurement not available."
                      : (4 * ((evalABC_2 & 0x000ff000) >> 12)) & 0x03ff;
                  var evalABC3Days_Amplitude_1 =
                    evalABC_2 == -1
                      ? "Measurement not available."
                      : evalABC_2 & 0x00000fff;
                  var evalABC3Days_Noise_2 =
                    evalABC_3 == -1
                      ? "Measurement not available."
                      : ((evalABC_3 & 0xfff00000) >> 20) & 0x0fff;
                  var evalABC3Days_Frequency_2 =
                    evalABC_3 == -1
                      ? "Measurement not available."
                      : (4 * ((evalABC_3 & 0x000ff000) >> 12)) & 0x03ff;
                  var evalABC3Days_Amplitude_2 =
                    evalABC_3 == -1
                      ? "Measurement not available."
                      : evalABC_3 & 0x00000fff;

                  this.data = {
                    EvalABC3Days_Noise: evalABC3Days_Noise,
                    EvalABC3Days_Frequency:
                      evalABC3Days_Frequency == 48 ||
                      evalABC3Days_Frequency == 52
                        ? 50
                        : evalABC3Days_Frequency,
                    EvalABC3Days_Amplitude: evalABC3Days_Amplitude,
                    EvalABC3Days_Noise_1: evalABC3Days_Noise_1,
                    EvalABC3Days_Frequency_1:
                      evalABC3Days_Frequency_1 == 48 ||
                      evalABC3Days_Frequency_1 == 52
                        ? 50
                        : evalABC3Days_Frequency_1,
                    EvalABC3Days_Amplitude_1: evalABC3Days_Amplitude_1,
                    EvalABC3Days_Noise_2: evalABC3Days_Noise_2,
                    EvalABC3Days_Frequency_2:
                      evalABC3Days_Frequency_2 == 48 ||
                      evalABC3Days_Frequency_2 == 52
                        ? 50
                        : evalABC3Days_Frequency_2,
                    EvalABC3Days_Amplitude_2: evalABC3Days_Amplitude_2,
                  };

                  this.warnings = {
                    EvalABC3Days_Noise: self.checkLimitMax(
                      evalABC3Days_Noise,
                      3000
                    )
                      ? "EvalABC3Days_Noise limit max: 3000 exceeded."
                      : null,
                    EvalABC3Days_Frequency: self.checkLimitMax(
                      evalABC3Days_Frequency,
                      1000
                    )
                      ? "EvalABC3Days_Frequency limit max: 1000 exceeded."
                      : null,
                    EvalABC3Days_Amplitude: self.checkLimitMax(
                      evalABC3Days_Amplitude,
                      3000
                    )
                      ? "EvalABC3Days_Amplitude limit max: 3000 exceeded."
                      : null,
                    EvalABC3Days_Noise_1: self.checkLimitMax(
                      evalABC3Days_Noise_1,
                      3000
                    )
                      ? "EvalABC3Days_Noise_1 limit max: 3000 exceeded."
                      : null,
                    EvalABC3Days_Frequency_1: self.checkLimitMax(
                      evalABC3Days_Frequency_1,
                      1000
                    )
                      ? "EvalABC3Days_Frequency_1 limit max: 1000 exceeded."
                      : null,
                    EvalABC3Days_Amplitude_1: self.checkLimitMax(
                      evalABC3Days_Amplitude_1,
                      3000
                    )
                      ? "EvalABC3Days_Amplitude_1 limit max: 3000 exceeded."
                      : null,
                    EvalABC3Days_Noise_2: self.checkLimitMax(
                      evalABC3Days_Noise_2,
                      3000
                    )
                      ? "EvalABC3Days_Noise_2 limit max: 3000 exceeded."
                      : null,
                    EvalABC3Days_Frequency_2: self.checkLimitMax(
                      evalABC3Days_Frequency_2,
                      1000
                    )
                      ? "EvalABC3Days_Frequency_2 limit max: 1000 exceeded."
                      : null,
                    EvalABC3Days_Amplitude_2: self.checkLimitMax(
                      evalABC3Days_Amplitude_2,
                      3000
                    )
                      ? "EvalABC3Days_Amplitude_2 limit max: 3000 exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    EvalABC3Days: "Incorrect EvalABC3Days length.",
                  };
                }
              },
              warnings: {},
            },
            225: {
              /* 0xE1 */ name: "ErrorMsg",
              length: 1,
              data: function (bytes) {
                this.data = {
                  MessageTypeText: "Error",
                };
              },
              warnings: {},
            },
            224: {
              /* 0xE0 */ name: "TestMsg",
              length: 1,
              data: function (bytes) {
                this.data = {
                  MessageTypeText: "Test",
                };
              },
              warnings: {},
            },
            208: {
              /* 0xD0 */ name: "Checksum",
              length: 3,
              payload: [],
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  this.data = (bytes[1] << 8) + bytes[2];
                } else {
                  this.warnings = {
                    Checksum: "Incorrect Checksum length.",
                  };
                }
              },
              correct: function (bytes, index) {
                bytes = [].concat(bytes);
                bytes.splice([index], this.length);
                return this.data == self.checkCRC(bytes, "ascii");
              },
              warnings: {},
            },
            192: {
              /* 0xC0 */ name: "Summary",
              length: 9,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var evalNoise =
                    bytes[1] == 255 && bytes[2] == 255
                      ? "Measurement not available."
                      : (bytes[1] << 8) + bytes[2];
                  var evalFrequency =
                    bytes[3] == 255 && bytes[4] == 255
                      ? "Measurement not available."
                      : (bytes[3] << 8) + bytes[4];
                  var evalAmplitude =
                    bytes[5] == 255 && bytes[6] == 255
                      ? "Measurement not available."
                      : (bytes[5] << 8) + bytes[6];
                  var flags = bytes[7].toString(16);
                  var battCap = bytes[8].toString();

                  this.data = {
                    Summary:
                      evalNoise.toString() +
                      "; " +
                      evalFrequency.toString() +
                      "; " +
                      evalAmplitude.toString() +
                      "; " +
                      "0x" +
                      flags +
                      "; " +
                      battCap +
                      "%",
                  };

                  this.warnings = {
                    Summary_evalNoise: self.checkLimitMax(evalNoise, 3000)
                      ? "Summary evalNoise limit max: 3000 exceeded."
                      : null,
                    Summary_evalFrequency: self.checkLimitMax(
                      evalFrequency,
                      1000
                    )
                      ? "Summary evalFrequency limit max: 1000 exceeded."
                      : null,
                    Summary_evalAmplitude: self.checkLimitMax(
                      evalAmplitude,
                      3000
                    )
                      ? "Summary evalAmplitude limit max: 3000 exceeded."
                      : null,
                    Summary_battCap: self.checkLimitMax(battCap, 100)
                      ? "Summary battCap limit max: 100 % exceeded."
                      : null,
                  };
                } else {
                  this.warnings = {
                    Summary: "Incorrect Summary length.",
                  };
                }
              },
              warnings: {},
            },
            74: {
              /* 0x4A */ name: "JoinedString",
              length: 6,
              data: function (bytes) {
                if (bytes && bytes.length == this.length) {
                  var str1 = String.fromCharCode(parseInt(bytes[0], 10));
                  var str2 = String.fromCharCode(parseInt(bytes[1], 10));
                  var str3 = String.fromCharCode(parseInt(bytes[2], 10));
                  var str4 = String.fromCharCode(parseInt(bytes[3], 10));
                  var str5 = String.fromCharCode(parseInt(bytes[4], 10));
                  var str6 = String.fromCharCode(parseInt(bytes[5], 10));
                  var messageTypeText = str1 + str2 + str3 + str4 + str5 + str6;

                  this.data = {
                    MessageTypeText: messageTypeText,
                  };

                  this.warnings = {
                    MessageTypeText:
                      messageTypeText == "JOINED"
                        ? null
                        : "JoinedString different than JOINED.",
                  };
                } else {
                  this.warnings = {
                    MessageTypeText: "Incorrect MessageTypeText length.",
                  };
                }
              },
              warnings: {},
            },
          };

          /* Verify payload */
          for (var i = 0; i < input.bytes.length; i++) {
            if (input.bytes[i] in identifiers) {
              var identifier = Object.create(identifiers[input.bytes[i]]);
              var payload = this.checkPayload(
                input.bytes,
                i,
                i + (identifier.length - 1)
              );
              if (payload) {
                identifier.data(payload);
                if (identifier.name === "Checksum") {
                  if (!identifier.correct(input.bytes, i)) {
                    return {
                      errors: ["Incorrect Checksum."],
                    };
                  }
                } else {
                  /* Concat data */
                  outputData = Object.assign(outputData, identifier.data);

                  /* Concat warnings */
                  var warnings = Object.keys(identifier.warnings);
                  for (var j = 0; j < warnings.length; j++) {
                    if (warnings[j] in identifier.warnings) {
                      if (
                        identifier.warnings[warnings[j]] != null &&
                        typeof identifier.warnings[warnings[j]] !== "undefined"
                      ) {
                        outputWarnings.push(identifier.warnings[warnings[j]]);
                      }
                    }
                  }
                }
                i += identifier.length - 1;
              } else {
                outputWarnings.push("Incorrect payload length.");
                i += identifier.length - 1;
              }
            } else {
              outputWarnings.push("Unknown identifier at position " + i + ".");
            }
          }

          if (outputWarnings && outputWarnings.length > 0) {
            return {
              data: outputData,
              warnings: outputWarnings,
            };
          } else {
            return {
              data: outputData,
            };
          }
        } else {
          return {
            errors: ["Oversized Input.bytes."],
          };
        }
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown Input."],
      };
    }
  }

  /* Helper Methods */
  checkLimitMin(value, min): boolean {
    return value == "Measurement not available."
      ? false
      : value < min
      ? true
      : false;
  }

  checkLimitMax(value, max): boolean {
    return value == "Measurement not available."
      ? false
      : value > max
      ? true
      : false;
  }

  checkDate(year, month, day, hours, minutes): boolean {
    var d = new Date(year, month, day, hours, minutes);
    return d.getFullYear() == year &&
      d.getMonth() == month &&
      d.getDate() == day &&
      d.getHours() == hours &&
      d.getMinutes() == minutes
      ? true
      : false;
  }

  checkPayload(bytes, start, end): boolean | any[] {
    var result = [];
    while (start <= end) {
      if (bytes.indexOf(bytes[start]) !== -1) {
        result.push(bytes[start]);
        start++;
      } else {
        return false;
      }
    }
    return result;
  }

  bytesToHex(bytes): string {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  hexToAscii(hex): any[] {
    var ascii = [];
    hex = hex.toUpperCase();
    for (var i = 0; i < hex.length; i++) {
      ascii.push(hex.charCodeAt(i));
    }
    return ascii;
  }

  checkLowBit(bit): number {
    var n = 0;
    while (((bit >> n) & 1) === 0 && n < 8) {
      n++;
    }
    return n;
  }

  checkCRC(data, type): number {
    var bit,
      lowBit,
      crc = 0,
      poly = 0x10811; // CRC16 Kermit
    data = type === "ascii" ? this.hexToAscii(this.bytesToHex(data)) : data;
    for (var n = 0; n < data.length; n++) {
      bit = data[n] ^ crc;
      while ((lowBit = this.checkLowBit(bit)) < 8) {
        bit = bit ^ (poly << lowBit);
      }
      crc = bit >> 8;
    }
    return crc;
  }

  objectAssign(source, target) {
    var _source = {};
    var _target = [];

    if (source) {
      _source = Object(source);
      if (target) {
        _target = Object.keys(target);

        for (var i = 0; i < _target.length; i++) {
          if (Object.prototype.hasOwnProperty.call(target, _target[i])) {
            _source[_target[i]] = target[_target[i]];
          }
        }
      }
    }
    return _source;
  }

  hexToBytes(hex): any[] {
    if (hex && hex.length) {
      var bytes = [];
      for (var i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
      }
      return bytes;
    } else {
      return null;
    }
  }
}
