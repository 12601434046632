/**
 * PArse the given complete LoRaWAN frame
 *
 * @param {any} frame - The LoRaWAN frame to be parsed.
 *
 * @returns {Object} An object with the following possible structures:
 *
 * @example
 *   const result = LoRaWAN_parser({bytes:frameInHex});
 *
 * @note
 * - Make sure that the frame is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoraWanParserService {
  constructor() {}

  LoRaWAN_parser(frame) {
    if (frame) {
      if (frame.bytes) {
        var outputData = {};
        /* CABECERA LORAWAN*/
        var headerData = {};
        var mhdr = frame.bytes[0];
        var fType = (mhdr >> 5) & 0x07;
        var major = mhdr & 0x03;
        switch (fType) {
          case 0:
            headerData["FTYPE"] = "JOIN REQUEST";
            outputData["LORAWAN_HEADER"] = headerData;
            outputData["JOIN_DATA"] = {
              JoinEUI: this.readU64LsbToStr(frame.bytes, 1),
              DevEUI: this.readU64LsbToStr(frame.bytes, 9),
              DevNonce: this.readU16Lsb(frame.bytes, 17)
                .toString(16)
                .padStart(4, "0"),
            };
            return outputData;
          case 1:
            headerData["FTYPE"] = "JOIN ACCEPT";
            outputData["LORAWAN_HEADER"] = headerData;
            return outputData;
          case 2:
          case 4:
            headerData["FTYPE"] =
              fType == 2 ? "UNCONFIRMED DATA UP" : "CONFIRMED DATA UP";
            headerData["DevAddr"] =
              "0x" +
              this.readU32Lsb(frame.bytes, 1).toString(16).padStart(8, "0");
            var fctrl = frame.bytes[5];
            var fOptsLen = fctrl & 0x0f;
            headerData["FCtrl"] = {
              FCtrl: "0x" + fctrl.toString(16).padStart(2, "0"),
              ADR: (fctrl & 0x80) != 0,
              ADRACKReq: (fctrl & 0x40) != 0,
              ACK: (fctrl & 0x20) != 0,
              ClassB: (fctrl & 0x10) != 0,
              FOptsLen: fOptsLen,
            };
            headerData["FCnt"] = this.readU16Lsb(frame.bytes, 6);
            if (fOptsLen != 0) {
              headerData["FOpts"] = this.bytesToHex(
                frame.bytes.slice(8, 8 + fOptsLen)
              );
            }
            outputData["LORAWAN_HEADER"] = headerData;
            if (frame.bytes.length >= 9 + fOptsLen + 4) {
              let fport = frame.bytes[8 + fOptsLen];
              outputData["fport"] = fport;
              if (frame.bytes.length >= 10 + fOptsLen + 4) {
                outputData["payload"] = this.bytesToHex(
                  frame.bytes.slice(9 + fOptsLen, frame.bytes.length - 4)
                );
              }
            }
            return outputData;
          case 3:
          case 5:
            headerData["FTYPE"] =
              fType == 3 ? "UNCONFIRMED DATA DOWN" : "CONFIRMED DATA DOWN";
            headerData["DevAddr"] =
              "0x" +
              this.readU32Lsb(frame.bytes, 1).toString(16).padStart(8, "0");
            var fctrl = frame.bytes[5];
            var fOptsLen = fctrl & 0x0f;
            headerData["FCtrl"] = {
              FCtrl: "0x" + fctrl.toString(16).padStart(2, "0"),
              ADR: (fctrl & 0x80) != 0,
              ACK: (fctrl & 0x20) != 0,
              FPending: (fctrl & 0x10) != 0,
              FOptsLen: fOptsLen,
            };
            headerData["FCnt"] = this.readU16Lsb(frame.bytes, 6);
            if (fOptsLen != 0) {
              headerData["FOpts"] = this.bytesToHex(
                frame.bytes.slice(8, 8 + fOptsLen)
              );
            }
            outputData["LORAWAN_HEADER"] = headerData;
            let fport = frame.bytes[8 + fOptsLen];
            if (frame.bytes.length >= 9 + fOptsLen + 4) {
              fport = frame.bytes[8 + fOptsLen];
              outputData["fport"] = fport;
              if (frame.bytes.length >= 10 + fOptsLen + 4) {
                outputData["payload"] = this.bytesToHex(
                  frame.bytes.slice(9 + fOptsLen, frame.bytes.length - 4)
                );
              }
            }
            return outputData;
          case 6:
            headerData["FTYPE"] = "RFU";
            outputData["LORAWAN_HEADER"] = headerData;
            return outputData;
          case 7:
            headerData["FTYPE"] = "PROPRIETARY";
            outputData["LORAWAN_HEADER"] = headerData;
            return outputData;
        }
      } else {
        return {
          errors: ["Unknown frame.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frame."],
      };
    }
  }

  /*helper functions*/
  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  readU64LsbToStr(bytes, start) {
    var str = [];
    for (var i = 7; i >= 0; i--) {
      str += bytes[start + i].toString(16).padStart(2, "0");
    }
    return str;
  }
}
