import { Component, Input } from "@angular/core";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { GatewayMeter } from "../../GatewayInterface.type";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";

@Component({
  selector: "app-gateway-relaunch-frames-dialog",
  templateUrl: "./gateway-relaunch-frames-dialog.component.html",
  styleUrls: ["./gateway-relaunch-frames-dialog.component.scss"],
})
export class GatewayRelaunchFramesDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  relaunchOnlyMic: boolean = false;
  relaunchWithAlarms: boolean = false;
  relaunchInitDate: string;
  relaunchInitHour: string;
  relaunchFinalDate: string;
  relaunchFinalHour: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private requestQueue: RequestQueueService,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Relanzamiento de tramas
  relaunchFrames(devices: GatewayMeter): void {
    let startDate =
      this.relaunchInitDate.split("-").reverse().join("/") +
      " " +
      (this.relaunchInitHour ? this.relaunchInitHour : "00:00");
    let endDate =
      this.relaunchFinalDate.split("-").reverse().join("/") +
      " " +
      (this.relaunchFinalHour ? this.relaunchFinalHour : "23:59");
    this.requestQueue.setTask("relaunchGateway", devices, {
      gateway: this.data.gateway,
      relaunch: {
        relaunchOnlyMic: this.relaunchOnlyMic,
        relaunchWithAlarms: this.relaunchWithAlarms,
        startDate: this.DateParserService.toTimestamp(startDate, "L HH:mm"),
        endDate: this.DateParserService.toTimestamp(endDate, "L HH:mm"),
      },
    });
  }
}
