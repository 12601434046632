// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class NetworkStateControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Agrupación
  /***************************************************************************/

  // Obtener los valores de las tarjetas de una agrupación
  getAgrupationCards(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/facturacion/cards/agrupation/" + agrupationId
    );
  }

  // Actualizar los valores de las tarjetas de una agrupación
  getAgrupationNewCards(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/facturacion/cards-instant/agrupation/" + entityId
    );
  }

  // Gráfico de dispositivos por agrupación
  getAgrupationGraph(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/facturacion/meters-graph/agrupation/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  /***************************************************************************/
  // ANCHOR Entidad
  /***************************************************************************/

  // Obtener los valores de las tarjetas de una entidad
  getEntityCards(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/facturacion/cards/" + entityId);
  }

  // Actualizar los valores de las tarjetas de una entidad
  getEntityNewCards(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/facturacion/cards-instant/" + entityId
    );
  }

  // Gráfico de dispositivos por entidad
  getEntityGraph(
    entityId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/facturacion/meters-graph/" + entityId + "/from/" + from + "/to/" + to
    );
  }

  // Obtener los valores de las tarjetas de todas las entidades
  getEntityAll(): Observable<object> {
    return this.HttpRequestService.get("/facturacion/cards/all");
  }

  /***************************************************************************/
  // ANCHOR KPIs
  /***************************************************************************/

  // Obtener los valores de las tarjetas
  getKpisCards(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/kpi-data/last/" + agrupationId);
  }

  // Gráfico de kpis
  getKpisGraph(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/kpi-data/agrupation/" + agrupationId + "/from/" + from + "/to/" + to
    );
  }

  // Gráfico de kpis
  getKpisGraphByEntity(
    entityId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/kpi-data/entity/" + entityId + "/from/" + from + "/to/" + to
    );
  }
}
