// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Moment
import * as moment from "moment";
// Servicios propios
import { NetworkStateControllerService } from "../../../../services/server/NetworkStateController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { RedirectToService } from "../../../../services/shared/RedirectToService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { AllEntityTableData, EntityTableData } from "../NetworkStateInterface.type";
import { KpisGraphData } from "../NetworkStateInterface.type";

@Component({
  selector: "app-network-state-global",
  templateUrl: "./network-state-global.component.html",
  styleUrls: ["./network-state-global.component.scss"],
})
export class NetworkStateGlobalComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionProfile: string;
  currentClientList: Client[];
  clientListSub: Subscription;
  selectedClient: Client;
  initialSelectedClient: number;

  // Tabla
  exportFileName: string =
    this.translate.instant("billing-entity-export") +
    " " +
    moment().format("ll");
  rowNumbers: boolean = true;
  tableMaxReg: number = 50;
  allEntityData: AllEntityTableData[];
  tableData: AllEntityTableData[];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-detail",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "agrupations",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
    },
    {
      title: "name",
      data: "descripcion",
      search: "descripcion",
      sort: "descripcion",
      visible: true,
    },
    {
      title: "meters-in-network",
      data: "contadoresAsignadosParsed",
      search: "contadoresAsignadosParsed",
      sort: "contadoresAsignados",
      numerical: true,
      visible: true,
    },
    {
      title: "network-valid-read",
      data: "contadoresLeidosDiaParsed",
      search: "contadoresLeidosDiaParsed",
      sort: "contadoresLeidosDia",
      numerical: true,
      visible: true,
    },
    {
      title: "network-valid-read-percentage",
      data: "readedPercentageParsed",
      search: "readedPercentageParsed",
      sort: "readedPercentage",
      numerical: true,
      visible: true,
    },
    {
      title: "network-last-month",
      data: "contadoresLeidosMesParsed",
      search: "contadoresLeidosMesParsed",
      sort: "contadoresLeidosMes",
      numerical: true,
      visible: false,
    },
    {
      title: "network-last-month-percentage",
      data: "lastMonthPercentageParsed",
      search: "lastMonthPercentageParsed",
      sort: "lastMonthPercentage",
      numerical: true,
      visible: false,
    },
    {
      title: "kpis-last-day-percentage",
      data: "entityLastDayPercentageParsed",
      search: "entityLastDayPercentageParsed",
      sort: "entityLastDay",
      numerical: true,
      visible: true,
    },
    {
      title: "kpis-last-day-graph",
      data: null,
      graph: true,
      visible: true,
    },
  ];
  updateIcon: string = "fas fa-sync-alt";
  updateTitle: string = this.translate.instant("update");

  // Gráficas
  defaultDateRange = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: moment().endOf("day"),
  };
  tooltip: any = {
    pointFormatter: function () {
      return (
        `<span style="color:` +
        this.color +
        `">` +
        this.series.name +
        `</span>: <b>` +
        Highcharts.numberFormat(this.y, 2) +
        `%</b>`
      );
    },
  };

  // Desplegable
  clientDropdownData: Client[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private NetworkStateController: NetworkStateControllerService,
    private RedirectToService: RedirectToService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentClientList = this.SessionDataService.getCurrentClientList();
    this.selectedClient = this.currentClientList
      ? this.currentClientList[0]
      : null;
    this.getClientDropdownData();

    this.clientListSub = this.SessionDataService
      .getClientList()
      .subscribe((clientList) => {
        this.currentClientList = clientList;
        this.selectedClient = this.currentClientList
          ? this.currentClientList[0]
          : null;
        this.getClientDropdownData();
      });

    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  getClientDropdownData(): void {
    this.clientDropdownData = this.currentClientList
      ? JSON.parse(JSON.stringify(this.currentClientList))
      : null;
    this.clientDropdownData?.unshift({
      clientId: -1,
      clientName: this.translate.instant("global"),
      entityList: null,
    });
    this.initialSelectedClient = this.selectedClient?.clientId;
  }

  // Obtención de los datos de las tarjetas y la tabla
  getData(): void {
    let allEntityData: AllEntityTableData[] = [];

    this.NetworkStateController.getEntityAll().subscribe((response) => {
      if (response["code"] == 0) {
        allEntityData = response["body"]?.listaFacturacionEntidades;
        allEntityData.forEach((entity: AllEntityTableData) => {
          // Porcentaje válidos
          entity.readedPercentage =
            entity.contadoresAsignados > 0
              ? parseFloat(
                  (
                    (entity.contadoresLeidosDia / entity.contadoresAsignados) *
                    100
                  ).toFixed(2)
                )
              : 0;
          // Porcentaje mes anterior
          entity.lastMonthPercentage =
            entity.contadoresAsignados > 0
              ? parseFloat(
                  (
                    (entity.contadoresLeidosMes / entity.contadoresAsignados) *
                    100
                  ).toFixed(2)
                )
              : 0;

          // Agrupaciones
          if (entity.agrupations?.length > 0) {
            entity.agrupations.forEach((agrupation: EntityTableData) => {
              // Porcentaje válidos
              agrupation.readedPercentage =
                agrupation.contadoresAsignados > 0
                  ? parseFloat(
                      (
                        (agrupation.contadoresLeidosDia /
                          agrupation.contadoresAsignados) *
                        100
                      ).toFixed(2)
                    )
                  : 0;
              // Porcentaje mes anterior
              agrupation.lastMonthPercentage =
                agrupation.contadoresAsignados > 0
                  ? parseFloat(
                      (
                        (agrupation.contadoresLeidosMes /
                          agrupation.contadoresAsignados) *
                        100
                      ).toFixed(2)
                    )
                  : 0;
            });

            // Columnas de la tabla anidada
            entity.extraTableData = {
              rowNumbers: true,
              data: entity.agrupations,
              columns: [
                {
                  title: "action",
                  data: [
                    {
                      name: "show-detail",
                      tooltip: "show-detail",
                      icon: "fas fa-eye",
                      visible: { attribute: null, rule: true },
                      disabled: false,
                    },
                  ],
                  visible: true,
                },
                {
                  title: "name",
                  data: "agrupationName",
                  search: "agrupationName",
                  sort: "agrupationName",
                  visible: true,
                },
                {
                  title: "meters-in-network",
                  data: "contadoresAsignadosParsed",
                  search: "contadoresAsignadosParsed",
                  sort: "contadoresAsignados",
                  numerical: true,
                  visible: true,
                },
                {
                  title: "network-valid-read",
                  data: "contadoresLeidosDiaParsed",
                  search: "contadoresLeidosDiaParsed",
                  sort: "contadoresLeidosDia",
                  numerical: true,
                  visible: true,
                },
                {
                  title: "network-valid-read-percentage",
                  data: "readedPercentageParsed",
                  search: "readedPercentageParsed",
                  sort: "readedPercentage",
                  numerical: true,
                  visible: true,
                },
                {
                  title: "network-last-month",
                  data: "contadoresLeidosMesParsed",
                  search: "contadoresLeidosMesParsed",
                  sort: "contadoresLeidosMes",
                  numerical: true,
                  visible: false,
                },
                {
                  title: "network-last-month-percentage",
                  data: "lastMonthPercentageParsed",
                  search: "lastMonthPercentageParsed",
                  sort: "lastMonthPercentage",
                  numerical: true,
                  visible: false,
                },
                {
                  title: "kpis-last-day-percentage",
                  data: "agrupationLastDayParsed",
                  search: "agrupationLastDayParsed",
                  sort: "agrupationLastDay",
                  numerical: true,
                  visible: true,
                },
                {
                  title: "kpis-last-day",
                  data: null,
                  graph: true,
                  visible: true,
                },
              ],
            };
          }
        });
      }

      // Ordenamiento de entidades
      allEntityData.sort((a, b) => a.descripcion.localeCompare(b.descripcion));

      // Ordenamiento de agrupaciones
      allEntityData.map((entity: AllEntityTableData) =>
        entity.agrupations.sort((a, b) =>
          a.agrupationName.localeCompare(b.agrupationName)
        )
      );

      this.allEntityData = allEntityData;

      // Obtención de las gráficas
      this.getGraphData();
    });
  }

  // Obtención de los datos de las gráficas
  getGraphData(): void {
    this.allEntityData.forEach((entity: AllEntityTableData) => {
      this.getEntityGraphData(
        entity,
        this.defaultDateRange.startDate.valueOf().toString(),
        this.defaultDateRange.endDate.valueOf().toString()
      );
    });
  }

  // Acciones de la tabla
  tableActions(action: string, entityData: AllEntityTableData): void {
    switch (action) {
      case "show-detail":
        let clientSelected = this.currentClientList.find((client: Client) =>
          client.entityList.some((entity: Entity) => entity.id == entityData.id)
        );
        let entitySelected = clientSelected.entityList.find(
          (entity: Entity) => entity.id == entityData.id
        );
        this.SessionDataService.sendEntity(entitySelected);
        if (this.SessionDataService.getCurrentNewTab()) {
          this.SessionDataService.clearNewTab();
          this.RedirectToService.openNewTab("/estado-red/entidad");
        } else {
          this.router.navigate(["/estado-red/entidad"]);
        }
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla
  extraTableActions(
    action: string,
    entityData: AllEntityTableData,
    agrupationData: EntityTableData
  ): void {
    switch (action) {
      case "show-detail":
        let clientSelected = this.currentClientList.find((client: Client) =>
          client.entityList.some((entity: Entity) => entity.id == entityData.id)
        );
        let entitySelected = clientSelected.entityList.find(
          (entity: Entity) => entity.id == entityData.id
        );
        let agrupationSelected = entitySelected.agrupations.find(
          (agrupation: Agrupation) =>
            agrupation.id == agrupationData.agrupationId
        );
        this.SessionDataService.sendEntity(entitySelected);
        this.SessionDataService.sendAgrupation(agrupationSelected);
        if (this.SessionDataService.getCurrentNewTab()) {
          this.SessionDataService.clearNewTab();
          this.RedirectToService.openNewTab("/estado-red/agrupacion");
        } else {
          this.router.navigate(["/estado-red/agrupacion"]);
        }
        break;
      default:
        break;
    }
  }

  // Obtención de los datos del gráfico
  getEntityGraphData(
    entity: AllEntityTableData,
    from: string,
    to: string
  ): void {
    this.NetworkStateController.getKpisGraphByEntity(
      entity.id,
      from,
      to
    ).subscribe((response) => {
      let graphData: KpisGraphData[] = [];
      if (response["code"] == 0 && response["body"]) {
        graphData = response["body"]?.filter(
          (data: KpisGraphData) =>
            data.fabricante == null && data.devType == null
        );
      }
      entity.agrupationSeries = this.getAgrupationSeries(graphData, entity);
      this.getEntitySeries(entity);
    });
  }

  // Obtención de la serie entidad
  getEntitySeries(entity: AllEntityTableData): void {
    let entitySeries: number[][] = [];

    // Suma de todos los valores de cada serie para cada timestamp
    for (let agrupation in entity.agrupationSeries) {
      entity.agrupationSeries[agrupation].forEach(
        (agrupationSerie: number[]) => {
          let timestampIndex = entitySeries.findIndex(
            (entitySerie: number[]) => entitySerie[0] == agrupationSerie[0]
          );
          if (timestampIndex >= 0) {
            entitySeries[timestampIndex][2] += agrupationSerie[2];
            entitySeries[timestampIndex][3] += agrupationSerie[3];
          } else {
            entitySeries.push(JSON.parse(JSON.stringify(agrupationSerie)));
          }
        }
      );
    }

    // Cálculo del valor porcentual del total
    entitySeries = entitySeries.map((serie: number[]) => {
      serie[1] =
        serie[3] > 0
          ? parseFloat(((serie[2] / serie[3]) * 100).toFixed(2))
          : null;
      return serie;
    });

    // Actualización de datos de entidad
    entity.graphData = {
      series: [
        {
          id: "lastDaySerie",
          name: this.translate.instant("kpis-day"),
          data: entitySeries.sort((a, b) => a[0] - b[0]),
          tooltip: this.tooltip,
        },
      ],
      min: 0,
      max: 100,
      type: "area",
    };
    entity.entityLastDay =
      entitySeries.length > 0 && entitySeries[entitySeries.length - 1][1]
        ? entitySeries[entitySeries.length - 1][1]
        : null;

    // Actualización de agrupaciones de entidad
    entity.agrupations.forEach((agrupation: EntityTableData) => {
      agrupation.agrupationLastDay =
        entity.agrupationSeries[agrupation.agrupationId]?.length > 0 &&
        entity.agrupationSeries[agrupation.agrupationId][
          entity.agrupationSeries[agrupation.agrupationId].length - 1
        ][1]
          ? entity.agrupationSeries[agrupation.agrupationId][
              entity.agrupationSeries[agrupation.agrupationId]?.length - 1
            ][1]
          : null;
      agrupation.graphData = {
        series: [
          {
            id: "lastDaySerie",
            name: this.translate.instant("kpis-day"),
            data: entity.agrupationSeries[agrupation.agrupationId],
            tooltip: this.tooltip,
          },
        ],
        min: 0,
        max: 100,
        type: "area",
      };
    });
    // Actualización de la tabla
    this.updateTable();
  }

  // Obtención de las series de últmo día por agrupaciones
  getAgrupationSeries(graphData: KpisGraphData[], entity): object {
    let agrupationSeries: object = {};
    // Lecturas por agrupación
    graphData?.forEach((data: KpisGraphData) => {
      if (!agrupationSeries[data.agrupation]) {
        agrupationSeries[data.agrupation] = [];
      }
      agrupationSeries[data.agrupation].push([
        data.timestamp,
        data.lastDayAverage,
        data.metersReadedLastDay,
        data.totalMetersLastDay,
      ]);
    });

    // Ordenamiento
    for (let agrupation in agrupationSeries) {
      agrupationSeries[agrupation].sort((a, b) => a[0] - b[0]);
    }

    return agrupationSeries;
  }

  // Actualización de tabla
  updateTable(): void {
    // Filtrado por cliente
    if (this.selectedClient?.clientId >= 0) {
      let filteredEntityData = this.allEntityData?.filter(
        (entity: AllEntityTableData) => {
          if (
            this.selectedClient.entityList?.some(
              (clientEntity: Entity) => clientEntity.id == entity.id
            )
          ) {
            return entity;
          }
        }
      );
      this.tableData = JSON.parse(JSON.stringify(filteredEntityData));
    } else {
      this.tableData = this.allEntityData;
    }
  }
}
