// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Servicios propios
import { HomeControllerService } from "../../../../../services/server/HomeController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { MapDeviceMinimalParseService } from "../../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { MapDevice } from "../../../../../interfaces/DeviceGlobalInterface.type";
import { MapGateway } from "../../../../../interfaces/GatewayGlobalInterface.type";

@Component({
  selector: "app-gateway-coverage-contrast",
  templateUrl: "./gateway-coverage-contrast.component.html",
  styleUrls: ["./gateway-coverage-contrast.component.scss"],
})
export class GatewayCoverageContrastComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Stream de datos de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  entitySub: Subscription;

  // Arrays de contadores y gateways
  devices: MapDevice[];
  gateways: MapGateway[];
  gatewayId: number;
  unidadVenta: string;

  // Mapa
  mapType: string = "standard";
  @ViewChild("coverageContrastPanel") coverageContrastPanel: ElementRef;
  mapHeight: number;
  contrastOpacity: number;

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.mapHeight =
      this.coverageContrastPanel?.nativeElement?.offsetHeight - 40;
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private deviceMinimalParse: MapDeviceMinimalParseService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(
      () =>
        (this.mapHeight =
          this.coverageContrastPanel?.nativeElement?.offsetHeight - 40),
      0
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.devices = null;
    this.gateways = null;
    this.getMapData();
  }

  // Obtención de los array de contadores y gateways
  getMapData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.devices = response["body"]["contadores"]
            ? this.deviceMinimalParse.parseDevices(
                response["body"]["contadores"]
              )
            : [];
          this.gateways = response["body"]["gateways"]
            ? response["body"]["gateways"]?.filter(
                (gateway: MapGateway) => gateway.id == this.gatewayId
              )
            : [];
        }
      }
    );
  }
}
