// @angular
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { EntityControllerService } from "../../../services/server/EntityController.service";
import { LanguageControllerService } from "../../../services/language/LanguageController.service";
import { DataAnalysisControllerService } from "../../../services/server/DataAnalysisController.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { BrowserStorageLocalService } from "../../../services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { AssociationControllerService } from "../../../services/server/AssociationController.service";
import { TokenCheckService } from "../../../services/shared/TokenCheckService.service";
import { DeviceRouteSelectorService } from "../../../services/shared/DeviceRouteSelectorService.service";
import { WeatherApiControllerService } from "../../../services/server/WeatherApiController.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { NotificationControllerService } from "../../../services/server/NotificationController.service";
import { NotificationModalService } from "../../../modules/notification-module/notification-service/notification-modal.service";
import { UserControllerService } from "../../../services/server/UserController.service";
import { ClientParserService } from "../../../services/shared/ClientParser.service";
// Componentes
import { NavbarResponsiveComponent } from "../navbar-responsive/navbar-responsive.component";
import { NavbarTopSearchBoxComponent } from "./navbar-top-search-box/navbar-top-search-box.component";
// Interfaces
import { Client } from "../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../interfaces/CupsGlobalInterface.type";
import { SessionData } from "../../../interfaces/SessionGlobalInterface.type";
import { CUSTOM_URLS } from "../../../screens/login/CUSTOM_URLS";

/***************************************************************************/
// APARTADOS
// Variables
// Constructor
// Inicialización del componente
// Destrucción del componente
// Funciones
// Carga y actualización de sesión
// Idioma
// Filtrado de listas
// Obtención de listados de cliente, entidad y agrupación
// Cambio de cliente, entidad o agrupación
// Buscador de dispositivos y CUPS
// Reloj y tiempo
// Configuración de usuario y notificaciones
/***************************************************************************/

@Component({
  selector: "app-navbar-top",
  templateUrl: "./navbar-top.component.html",
  styleUrls: ["./navbar-top.component.scss"],
})
export class NavbarTopComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Stream de datos de sesión
  userSub: Subscription;
  profileSub: Subscription;
  agrupationSub: Subscription;
  entitySub: Subscription;
  clientSub: Subscription;
  languageSub: Subscription;
  updateNavFlagSub: Subscription;
  updateSearchBoxFlagSub: Subscription;
  updateEntityCupsConfFlagSub: Subscription;

  // Datos de sesión
  sessionProfile: string;
  sessionUser: string;
  sessionUserName: { name: string; surname: string };
  sessionLanguage: string;
  languageName: string;
  sessionData: SessionData;
  currentEntityCupsConf: EntityDefinition[];
  currentCupsName: string;
  @Input() loadSession: boolean;

  // Datos de cliente, entidades y agrupaciones
  userData: any;
  currentClient: Client;
  currentEntity: Entity;
  currentAgrupation: Agrupation;
  clientList: Client[] = [];
  clientListOriginal: Client[] = [];
  entityList: Entity[] = [];
  entityListOriginal: Entity[] = [];
  agrupationList: Agrupation[] = [];
  agrupationListOriginal: Agrupation[] = [];
  updateEntity: boolean = true;
  updateAgrupation: boolean = true;
  uniqueClient: boolean = false;
  updateSession: boolean;

  // Lista de contadores y CUPS
  meterList: any[] = [];
  meterListOriginal: any[] = [];
  cupsList: any[] = [];
  cupsListOriginal: any[] = [];
  searchLabel: string;

  // Notificaciones pendientes
  pendingNotifications: number = 0;

  // Reloj y buscador
  clockTime: { date: string; time: string; offset: number } = {
    date: null,
    time: null,
    offset: null,
  };
  clockTimer: any;
  @ViewChild(NavbarResponsiveComponent)
  navbarResponsive: NavbarResponsiveComponent;
  @ViewChild(NavbarTopSearchBoxComponent)
  navbarTopSearchBox: NavbarTopSearchBoxComponent;

  // Logo
  logoImg: any;
  logoImgUrl: any;
  logoLoading: boolean = true;
  @Output() logoImgUrlUpdate = new EventEmitter<any>();

  // Logout
  @Output() logoutFlag = new EventEmitter<any>();

  // Responsive menu
  showResponsiveMenuFlag: boolean = false;
  @Input() hideDesktopMenus: boolean;
  customLogo = CUSTOM_URLS.find((url) =>
    window.location.href.includes(url.urlText)
  );
  navbarLogo: string = this.customLogo
    ? this.customLogo.img
    : "assets/img/logos/arsondatamini.ico";

  // API clima
  weatherData: any;
  weatherCoordsSub: Subscription;
  currentWeatherCoords: any;
  weatherLoading: boolean = false;
  weatherUpdateEnabled: boolean = false;
  weatherTypes: any = {
    thunderstorm: "fas fa-bolt",
    drizzle: "fas fa-cloud-rain",
    rain: "fas fa-cloud-showers-heavy",
    snow: "fas fa-snowflake",
    atmosphere: "fas fa-wind",
    clear: "fas fa-sun",
    clouds: "fas fa-cloud",
  };

  // Notificaciones
  notificationsRequestTimerMS: number = 600000;
  notificationsRequestTimer: any;
  notificationLoopSub: Subscription;

  // Ayuda
  helpActive: boolean;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.helpActive) {
      this.toggleHelp();
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ClientParserService: ClientParserService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private domSanitizer: DomSanitizer,
    private EntityController: EntityControllerService,
    private AssociationController: AssociationControllerService,
    private LanguageController: LanguageControllerService,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private DataAnalysisController: DataAnalysisControllerService,
    private NotificationController: NotificationControllerService,
    private NotificationModalService: NotificationModalService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private TokenCheckService: TokenCheckService,
    private translate: TranslateService,
    private UserController: UserControllerService,
    private WeatherApiController: WeatherApiControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionData = this.BrowserStorageLocalService.getJsonValue("session");
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionUser = this.SessionDataService.getCurrentUser();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.getUserName();
    this.languageSelector();
    // Formato numérico
    this.SessionDataService.sendNumberFormat(
      this.sessionData?.numberFormat
        ? this.sessionData.numberFormat
        : this.sessionLanguage
    );
    // Formato de fecha
    this.SessionDataService.sendDateFormat(
      this.sessionData?.dateFormat ? this.sessionData.dateFormat : "L"
    );

    // Carga de datos desde servidor
    this.loadData();
    if (this.sessionProfile != "ADMIN_INSTALLATION") {
      this.getPendingNotifications();
      clearInterval(this.notificationsRequestTimer);
      this.notificationsRequestTimer = setInterval(
        () => this.getPendingNotifications(),
        this.notificationsRequestTimerMS
      );
    }

    // Escucha de cambios en los valores de perfil y usuario
    this.profileSub = this.SessionDataService.getProfile().subscribe(
      (profile) => {
        this.sessionProfile = profile;
      }
    );

    this.userSub = this.SessionDataService.getUser().subscribe((user) => {
      this.sessionUser = user;
    });

    // Escucha de cambios en los valores de agrupación, entidad y cliente
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        if (this.currentAgrupation) {
          if (
            agrupation?.id != this.currentAgrupation.id ||
            this.updateSession
          ) {
            this.setCurrentAgrupation(agrupation);
            this.weatherData = null;
            this.updateSessionData();
          }
        } else {
          this.setCurrentAgrupation(agrupation);
          this.updateSessionData();
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      if (this.currentEntity) {
        if (entity?.id != this.currentEntity.id || this.updateSession) {
          this.setCurrentEntity(entity);
        }
      } else {
        this.setCurrentEntity(entity);
      }
    });

    this.updateEntityCupsConfFlagSub =
      this.SessionDataService.getUpdateEntityCupsConfFlag().subscribe(() =>
        this.getEntityCupsConfiguration(this.currentEntity)
      );

    this.clientSub = this.SessionDataService.getClient().subscribe((client) => {
      if (this.currentClient) {
        if (
          client.clientId != this.currentClient.clientId ||
          this.updateSession
        ) {
          this.setCurrentClient(client);
        }
      } else {
        this.setCurrentClient(client);
      }
    });

    // Escucha de cambios en los valores de lenguaje
    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language) => {
        this.sessionLanguage = language;
        this.languageSelector();
        this.updateSessionData();
      }
    );

    // Escucha de cambios en los valores de coordenadas de clima
    this.weatherCoordsSub =
      this.SessionDataService.getWeatherCoords().subscribe((weatherCoords) => {
        this.currentWeatherCoords = weatherCoords;
        this.weatherUpdateEnabled = true;
      });

    // Escucha de petición de actualización de barra de navegación
    this.updateNavFlagSub =
      this.SessionDataService.getUpdateNavFlag().subscribe(() => {
        this.updateSession = true;
        this.clientList = [];
        this.clientListOriginal = [];
        this.entityList = [];
        this.entityListOriginal = [];
        this.agrupationList = [];
        this.agrupationListOriginal = [];
        if (this.SessionDataService.getCurrentUserLogged()) {
          this.loadData();
        }
      });

    this.updateSearchBoxFlagSub =
      this.SessionDataService.getUpdateSearchBoxFlag().subscribe(() => {
        this.getSearchDropdown();
      });

    // Escucha de actualización del loop de notificaciones
    this.notificationLoopSub =
      this.SessionDataService.getNotificationLoop().subscribe(
        (notificationLoop) => {
          clearInterval(this.notificationsRequestTimer);
          if (notificationLoop) {
            this.notificationsRequestTimer = setInterval(
              () => this.getPendingNotifications(),
              this.notificationsRequestTimerMS
            );
          }
        }
      );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
    this.profileSub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.clientSub.unsubscribe();
    this.languageSub.unsubscribe();
    this.weatherCoordsSub.unsubscribe();
    this.updateNavFlagSub.unsubscribe();
    this.updateSearchBoxFlagSub.unsubscribe();
    this.updateEntityCupsConfFlagSub.unsubscribe();
    this.notificationLoopSub.unsubscribe();
    clearInterval(this.clockTimer);
    clearInterval(this.notificationsRequestTimer);
    this.SessionDataService.clearAll();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del nombre de usuario
  getUserName(): void {
    this.UserController.getUserName(this.sessionUser).subscribe((response) => {
      if (response["code"] == 0) {
        this.sessionUserName = response["body"];
      }
    });
  }

  // Aviso de carga del logo
  onLogoLoad(): void {
    this.logoLoading = false;
  }

  // Mostrar menú responsive
  showResponsiveMenu(): void {
    this.showResponsiveMenuFlag = !this.showResponsiveMenuFlag;
  }

  // Logout
  logout(): void {
    this.logoutFlag.emit();
  }

  // Relogin
  relogin(): void {
    this.ToastService.firePasswordInput().then((password: string) => {
      if (password) {
        this.TokenCheckService.renewSession(password);
      }
    });
  }

  // Activación/Desactivación de la ayuda
  toggleHelp(): void {
    this.helpActive = !this.SessionDataService.getCurrentHelpActive();
    this.SessionDataService.sendHelpActive(this.helpActive);
    this.helpActive
      ? document.body.classList.add("help-active")
      : document.body.classList.remove("help-active");
  }

  /***************************************************************************/
  // ANCHOR Carga y actualización de sesión
  /***************************************************************************/

  // Carga de datos de cliente
  loadData(): void {
    this.sessionData = this.BrowserStorageLocalService.getJsonValue("session");
    let originalProfile = this.SessionDataService.getCurrentOriginalProfile();
    this.EntityController.getEntityBy(
      originalProfile == "ARSON" ? "ARSON" : this.sessionProfile
    ).subscribe((response) => {
      this.userData = response["body"];
      if (this.userData) {
        // Carga de datos para perfil ARSON
        if (this.sessionProfile == "ARSON" || originalProfile == "ARSON") {
          this.getClientList();
          this.SessionDataService.sendClientList(this.clientListOriginal);
          // Carga de sesión guardada
          if (this.loadSession || this.updateSession) {
            this.updateEntity = false;
            this.updateAgrupation = false;
            // Actualización de cliente en curso
            this.loadCurrentClient();
            // Actualización de entidad en curso
            this.loadCurrentEntity();
            // Actualización de agrupación en curso
            this.loadCurrentAgrupation();
            // Actualización de tiempo restante de sesión
            this.TokenCheckService.sessionTimeRemaining();
            // Actualización de carga de sesión finalizada
            this.updateSession = false;
            this.SessionDataService.sendSessionLoadingFlag(false);
          } else {
            this.SessionDataService.sendClient(this.clientListOriginal[0]);
          }
          // Carga de datos para perfil no ARSON
        } else {
          this.uniqueClient = true;
          // Actualización de cliente en curso
          this.SessionDataService.sendClient({
            clientId: null,
            clientName: null,
            entityList: this.userData,
          });
          // Carga de sesión guardada
          if (this.loadSession || this.updateSession) {
            this.updateEntity = false;
            this.updateAgrupation = false;
            // Actualización de entidad en curso
            this.loadCurrentEntity();
            // Actualización de agrupación en curso
            this.loadCurrentAgrupation();
            // Actualización de tiempo restante de sesión
            this.TokenCheckService.sessionTimeRemaining();
            // Actualización de carga de sesión finalizada
            this.updateSession = false;
            this.SessionDataService.sendSessionLoadingFlag(false);
          }
        }
      }
    });
  }

  // Precarga de cliente
  loadCurrentClient(): void {
    if (this.sessionData.current.client) {
      let currentClient = this.clientListOriginal?.find(
        (client: any) =>
          client.clientId == this.sessionData.current.client.clientId
      );
      if (currentClient) {
        this.SessionDataService.sendClient(currentClient);
      } else {
        this.SessionDataService.sendClient(this.clientList[0]);
      }
    } else {
      this.SessionDataService.sendClient(this.clientList[0]);
    }
  }

  // Precarga de entidad
  loadCurrentEntity(): void {
    if (this.sessionData.current.entity) {
      let currentEntity = this.currentClient?.entityList?.find(
        (entity: any) => entity.id == this.sessionData.current.entity.id
      );
      if (currentEntity) {
        this.SessionDataService.sendEntity(currentEntity);
      } else {
        this.SessionDataService.sendEntity(this.currentClient?.entityList[0]);
      }
    } else {
      this.SessionDataService.sendEntity(this.currentClient?.entityList[0]);
    }
  }

  // Precarga de agrupación
  loadCurrentAgrupation(): void {
    if (this.sessionData.current.agrupation) {
      let currentAgrupation = this.currentEntity?.agrupations?.find(
        (agrupation: any) =>
          agrupation.id == this.sessionData.current.agrupation?.id
      );
      if (currentAgrupation) {
        this.SessionDataService.sendAgrupation(currentAgrupation);
      } else {
        this.SessionDataService.sendAgrupation(
          this.currentEntity?.agrupations[0]
        );
      }
    } else {
      this.SessionDataService.sendAgrupation(
        this.currentEntity?.agrupations[0]
      );
    }
  }

  // Actualización de los datos de sesión en localStorage
  updateSessionData(): void {
    let updatedSessionData: any =
      this.BrowserStorageLocalService.getJsonValue("session");
    updatedSessionData["current"] = {};
    updatedSessionData["current"]["client"] = this.currentClient;
    updatedSessionData["current"]["entity"] = this.currentEntity;
    updatedSessionData["current"]["agrupation"] = this.currentAgrupation;
    updatedSessionData["language"] = this.sessionLanguage;
    this.BrowserStorageLocalService.setJsonValue("session", updatedSessionData);
  }

  /***************************************************************************/
  // ANCHOR Idioma
  /***************************************************************************/

  // Selector de idioma
  languageSelector(): void {
    switch (this.sessionLanguage) {
      case "en":
        this.languageName = "English";
        break;
      case "es":
        this.languageName = "Castellano";
        break;
      case "ca":
        this.languageName = "Català";
        break;
      case "es-ca":
        this.languageName = "Català";
        break;
      case "fr":
        this.languageName = "Français";
        break;
      case "it":
        this.languageName = "Italiano";
        break;
      default:
        this.languageName = "English";
        break;
    }
  }

  // Cambio del idioma del usuario
  switchLanguage(newLanguage: string): void {
    this.SessionDataService.sendLanguage(newLanguage);
    // Actualización del idioma en el perfil del usuario
    this.LanguageController.changeLang(
      newLanguage == "es-ca" ? "CA" : newLanguage.toUpperCase()
    ).subscribe();
  }

  // Cambio de formato numérico
  switchNumberFormat(format: string): void {
    this.SessionDataService.sendNumberFormat(format);
    this.sessionData = this.BrowserStorageLocalService.getJsonValue("session");
    this.sessionData.numberFormat = format;
    this.BrowserStorageLocalService.setJsonValue("session", this.sessionData);
    this.SessionDataService.sendReloadPanelFlag();
  }

  // Cambio de formato de fecha
  switchDateFormat(format: string): void {
    this.SessionDataService.sendDateFormat(format);
    this.sessionData = this.BrowserStorageLocalService.getJsonValue("session");
    this.sessionData.dateFormat = format;
    this.BrowserStorageLocalService.setJsonValue("session", this.sessionData);
    this.SessionDataService.sendReloadPanelFlag();
  }

  /***************************************************************************/
  // ANCHOR Filtrado de listas
  /***************************************************************************/

  // Filtrado de las listas de clientes, entidades y agrupaciones
  dropdownFilter(
    inputData: string,
    dataType: string,
    cupsSelected?: boolean
  ): void {
    switch (dataType) {
      case "client":
        this.clientList = this.filterArrayOfObjects(
          this.clientListOriginal,
          ["clientName"],
          inputData
        );
        break;
      case "entity":
        this.entityList = this.filterArrayOfObjects(
          this.entityListOriginal,
          ["entity", "alias"],
          inputData
        );
        break;
      case "agrupation":
        this.agrupationList = this.filterArrayOfObjects(
          this.agrupationListOriginal,
          ["name", "alias"],
          inputData
        );
        break;
      case "search":
        if (!cupsSelected) {
          this.meterList = this.filterArrayOfObjects(
            this.meterListOriginal,
            ["descripcion"],
            inputData
          );
        } else {
          this.cupsList = this.filterArrayOfObjects(
            this.cupsListOriginal,
            ["descripcion"],
            inputData
          );
        }
      default:
        break;
    }
  }

  // Filtrado de array de desplegable
  filterArray(array: any, inputData: string): void {
    return array.filter((arrayElement: any) => {
      if (arrayElement?.toUpperCase().includes(inputData.toUpperCase())) {
        return arrayElement;
      }
    });
  }

  // Filtrado de array de objetos de desplegable
  filterArrayOfObjects(
    array: any,
    attributes: string[],
    inputData: string
  ): any[] {
    return array.filter((arrayElement: any) => {
      if (
        attributes.some((attribute) =>
          arrayElement[attribute]
            ?.toUpperCase()
            .includes(inputData.toUpperCase())
        )
      ) {
        return arrayElement;
      }
    });
  }

  // Reseteo de cajas de búsqueda y arrays de listas
  resetSearchBox(searchBox: any): void {
    switch (searchBox) {
      case "client":
        this.clientList = [...this.clientListOriginal];
        break;
      case "entity":
        this.entityList = [...this.entityListOriginal];
        break;
      case "agrupation":
        this.agrupationList = [...this.agrupationListOriginal];
        break;
      case "search":
        this.meterList = [...this.meterListOriginal];
        this.cupsList = [...this.cupsListOriginal];
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Obtención de listados de cliente, entidad y agrupación
  /***************************************************************************/

  // Obtención del logo de la entidad
  getEntityImg(): void {
    this.EntityController.getImg(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.logoImg = response["body"];
          if (this.logoImg) {
            this.logoImgUrl = this.domSanitizer.bypassSecurityTrustUrl(
              "data:image/png;base64, " + this.logoImg
            );
            this.logoImgUrlUpdate.emit(this.logoImgUrl);
          } else {
            let customLogo = CUSTOM_URLS.find((url) =>
              window.location.href.includes(url.urlText)
            );
            this.logoImgUrl = customLogo?.img
              ? customLogo?.img
              : "assets/img/logos/arsondata.png";
            this.logoImgUrlUpdate.emit(this.logoImgUrl);
          }
        }
      }
    );
  }

  // Obtención del array de la lista de clientes
  getClientList(): void {
    this.clientListOriginal = this.userData.map((client: Client) => client);
    this.sortBy(this.clientListOriginal, "clientName");
    this.clientList = [...this.clientListOriginal];

    // Parseo de textos
    this.clientListOriginal.forEach((client: Client) => {
      this.ClientParserService.parseClient(client);
    });
  }

  // Obtención del array de la lista de entidades
  getEntityList(): void {
    this.entityListOriginal = this.currentClient.entityList.map(
      (entity: Entity) => entity
    );
    this.sortBy(this.entityListOriginal, "entity");
    this.entityList = [...this.entityListOriginal];
    this.SessionDataService.sendEntityList(this.entityListOriginal);
  }

  // Obtención del array de la lista de agrupaciones
  getAgrupationList(): void {
    this.agrupationListOriginal =
      this.sessionProfile == "ARSON"
        ? this.currentEntity.agrupations.map(
            (agrupation: Agrupation) => agrupation
          )
        : this.currentEntity.agrupations.filter(
            (agrupation: Agrupation) => !agrupation.hideInVirtualMap
          );
    this.sortBy(this.agrupationListOriginal, "name");
    let virtual = this.agrupationListOriginal.findIndex(
      (agrupation: Agrupation) => agrupation.showAllEntity
    );
    if (virtual >= 0) {
      this.changePosition(this.agrupationListOriginal, virtual, 0);
    }
    this.agrupationList = [...this.agrupationListOriginal];
  }

  // Ordenación de un array de objetos por atributo
  sortBy(data: any, attribute: string): void {
    data.sort((a: any, b: any) => {
      return a[attribute].localeCompare(b[attribute]);
    });
  }

  // Reordenación de array
  changePosition(array: any[], index: number, newIndex: number): void {
    let element = array[index];
    array.splice(index, 1);
    array.splice(newIndex, 0, element);
  }

  // Actualización del cliente en curso al seleccionarlo en el desplegable
  updateCurrentClient(selectedClient: Client): void {
    this.SessionDataService.sendClient(selectedClient);
  }

  // Actualización de la entidad en curso al seleccionarla en el desplegable
  updateCurrentEntity(selectedEntity: Entity): void {
    this.SessionDataService.sendEntity(selectedEntity);
  }

  // Actualización de la agrupación en curso al seleccionarla en el desplegable
  updateCurrentAgrupation(selectedAgrupation: Agrupation): void {
    this.SessionDataService.sendAgrupation(selectedAgrupation);
  }

  /***************************************************************************/
  // ANCHOR Cambio de cliente, entidad o agrupación
  /***************************************************************************/

  // Selección del cliente en curso
  setCurrentClient(client: Client): void {
    if (client) {
      this.currentClient = client;
      this.currentClient.itemsCount = this.clientListOriginal.length;
      this.getEntityList();
      if (this.updateEntity) {
        this.SessionDataService.sendEntity(this.entityListOriginal[0]);
      }
    } else {
      this.currentClient = null;
      setTimeout(() => this.router.navigate(["/clientes/formulario/nuevo"]), 0);
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-client")
      );
    }
    this.updateEntity = true;
  }

  // Selección de la entidad en curso
  setCurrentEntity(entity: Entity): void {
    this.navbarTopSearchBox?.resetSearchBoxText();
    this.navbarResponsive?.resetSearchDevice();
    if (entity) {
      this.getEntityCupsConfiguration(entity);
      this.currentEntity = entity;
      this.currentEntity.itemsCount = this.entityListOriginal.length;
      this.getEntityImg();
      setTimeout(() => this.getSearchDropdown(), 0);
      this.getAgrupationList();
      // Autoselección de primera agrupación no virtual
      if (this.updateAgrupation) {
        this.SessionDataService.sendAgrupation(
          this.agrupationListOriginal.filter(
            (agrupation: Agrupation) => !agrupation.showAllEntity
          )[0]
        );
      }
      if (!this.uniqueClient && this.currentClient.clientId != -1) {
        this.checkEntityClient();
      }
    } else {
      this.currentEntity = null;
      setTimeout(
        () => this.router.navigate(["/entidades/formulario/nuevo"]),
        0
      );
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-entity")
      );
    }
    this.updateAgrupation = true;
  }

  // Selección de la agrupación en curso
  setCurrentAgrupation(agrupation: Agrupation): void {
    if (agrupation) {
      this.currentAgrupation = agrupation;
      this.currentAgrupation.itemsCount = this.agrupationListOriginal.length;
      this.currentAgrupation.virtual = this.currentAgrupation.showAllEntity;
      this.getClock();
      this.checkAgrupationEntity();
    } else {
      this.currentAgrupation = null;
      if (this.currentEntity) {
        setTimeout(
          () => this.router.navigate(["/agrupaciones/formulario/nuevo"]),
          0
        );
        this.ToastService.fireToast(
          "warning",
          this.translate.instant("must-agrupation")
        );
      }
    }
  }

  // Obtención de la configuración de CUPS para la entidad en curso
  getEntityCupsConfiguration(entity: Entity): void {
    if (entity?.id) {
      this.AssociationController.listEntityConfig(entity.id).subscribe(
        (response) => {
          if (response["code"] == 0) {
            this.currentEntityCupsConf = response["body"];
            this.currentCupsName = this.currentEntityCupsConf?.find(
              (column: EntityDefinition) => column.colPosition == 0
            )?.name;
            this.SessionDataService.sendEntityCupsConf(
              this.currentEntityCupsConf
            );
          }
        }
      );
    } else {
      this.SessionDataService.clearEntityCupsConf();
    }
  }

  /***************************************************************************/
  // ANCHOR Buscador de dispositivos y CUPS
  /***************************************************************************/

  // Obtención de los listados de búsqueda
  getSearchDropdown(): void {
    this.meterList = null;
    this.cupsList = null;
    if (
      this.sessionProfile != "ABONADO" &&
      this.sessionProfile != "ADMIN_INSTALLATION"
    ) {
      this.getMetersCupsDropdown();
    } else if (this.sessionProfile != "ADMIN_INSTALLATION") {
      this.getOnlyCupsDropdown();
    }
  }

  // Obtención de la lista de contadores y cups
  getMetersCupsDropdown(): void {
    this.DataAnalysisController.getMetersDropdown(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.searchLabel = response["body"]["fieldName"];
        this.meterList = response["body"]["nroSerieList"].map((meter: any) => {
          return {
            descripcion: meter.nroSerie,
            id: meter.id,
            agrupation: meter.agrupation,
            type: meter.metrologyType,
          };
        });
        this.cupsList = response["body"]["dropdownList"].map((cups: any) => {
          return {
            descripcion: cups.clave,
            id: cups.id,
          };
        });
        this.sortBy(this.meterList, "descripcion");
        this.sortBy(this.cupsList, "descripcion");
        this.meterListOriginal = [...this.meterList];
        this.cupsListOriginal = [...this.cupsList];
        this.SessionDataService.sendCupsList(this.cupsList);
      }
    });
  }

  // Obtención de solo la lista de CUPS
  getOnlyCupsDropdown(): void {
    this.DataAnalysisController.getCupsDropdown().subscribe((response) => {
      if (response["code"] == 0) {
        this.searchLabel = response["body"]["fieldName"];
        this.cupsList = response["body"]["dropdownList"].map((cups: any) => {
          return {
            descripcion: cups.clave,
            id: cups.id,
          };
        });
        this.sortBy(this.cupsList, "descripcion");
        this.cupsListOriginal = [...this.cupsList];
        this.SessionDataService.sendCupsList(this.cupsList);
      }
    });
  }

  // Redirección al contador o cups seleccionado en la búsqueda
  goToSelection(cupsSelected: boolean, selection: any): void {
    if (cupsSelected) {
      this.router.navigate(["/cups/detalle/", selection.id]);
    } else {
      this.SessionDataService.sendAgrupation(
        this.agrupationListOriginal.find((agrupation: any) => {
          return agrupation.id === selection.agrupation;
        })
      );
      this.DeviceRouteSelectorService.getDeviceRoute(
        selection.type,
        selection.id
      );
    }
  }

  // Comprobación de si la agrupación se encuentra en la entidad en curso
  checkAgrupationEntity(): void {
    if (
      this.currentAgrupation.entity &&
      this.currentAgrupation.entity != this.currentEntity.id
    ) {
      let foundEntity: any = this.entityListOriginal.find(
        (entity) => entity.id == this.currentAgrupation.entity
      );
      this.updateAgrupation = false;
      this.SessionDataService.sendEntity(foundEntity);
    }
  }

  // Comprobación de si la entidad se encuentra en el cliente en curso
  checkEntityClient(): void {
    if (this.currentEntity.client != this.currentClient.clientId) {
      let foundClient: any = this.clientListOriginal.find(
        (client) => client.clientId == this.currentEntity.client
      );
      this.updateEntity = false;
      if (foundClient) {
        this.SessionDataService.sendClient(foundClient);
      } else {
        this.SessionDataService.sendClient({
          clientId: -1,
          clientName: "-",
          entityList: [this.currentEntity],
        });
      }
    }
  }

  /***************************************************************************/
  // ANCHOR Reloj y tiempo
  /***************************************************************************/

  // Inicialización del reloj
  getClock(): void {
    if (this.currentAgrupation?.timezone) {
      this.clockTime.offset = this.DateParserService.getTimezoneOffset(
        this.currentAgrupation?.timezone
      );
      this.clockTimer = setInterval(() => {
        if (this.currentAgrupation && this.currentAgrupation.name != "-") {
          let format: string = "DD/MM - HH:mm:ss";
          let clockTime: any = this.DateParserService.getClock(format);
          this.clockTime.date = clockTime?.substring(
            0,
            clockTime?.indexOf("-") - 1
          );
          this.clockTime.time = clockTime?.substring(
            clockTime?.indexOf("-") + 1
          );
        } else {
          this.clockTime.date = null;
          this.clockTime.time = null;
          clearInterval(this.clockTimer);
        }
      }, 1000);
    }
  }

  // Actualización de los datos de clima
  updateWeatherData(): void {
    this.weatherLoading = true;
    this.weatherUpdateEnabled = false;
    let lang: string =
      this.sessionLanguage == "es-ca" ? "ca" : this.sessionLanguage;

    if (
      this.currentWeatherCoords?.latitude != null &&
      this.currentWeatherCoords?.longitude != null
    ) {
      this.WeatherApiController.getWeatherData(
        this.currentWeatherCoords.latitude,
        this.currentWeatherCoords.longitude,
        lang
      ).subscribe((response) => {
        if (response["weather"][0]) {
          this.weatherData = {
            text: response["weather"][0]["description"],
            icon: this.weatherTypes[
              response["weather"][0]["main"].toLowerCase()
            ],
            temp: response["main"]["temp"],
            maxTemp: response["main"]["temp_max"],
            minTemp: response["main"]["temp_min"],
            pressure: response["main"]["pressure"],
            humidity: response["main"]["humidity"],
            sunrise: this.DateParserService.parseDate(
              response["sys"]["sunrise"] * 1000,
              "HH:mm"
            ),
            sunset: this.DateParserService.parseDate(
              response["sys"]["sunset"] * 1000,
              "HH:mm"
            ),
            clouds: response["clouds"]["all"],
          };
        } else {
          this.ToastService.fireToast(
            "error",
            this.translate.instant("weather-load-error")
          );
        }
        this.weatherLoading = false;
      });
    } else {
      this.weatherLoading = false;
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("weather-data-error")
      );
    }
  }

  /***************************************************************************/
  // ANCHOR Configuración de usuario y notificaciones
  /***************************************************************************/

  // Borrado de configuración local
  deleteUserLocalSettings(): void {
    let configData: any =
      this.BrowserStorageLocalService.getJsonValue("config");
    if (configData) {
      let userConfigIndex: number = configData.findIndex(
        (config: any) => config.user == this.SessionDataService.getCurrentUser()
      );
      configData.splice(userConfigIndex, 1);
    }
    this.BrowserStorageLocalService.setJsonValue("config", configData);
    this.ToastService.fireToast(
      "success",
      this.translate.instant("user-local-configuration-deleted")
    );
  }

  // Obtención del número de notificaciones pendientes
  getPendingNotifications() {
    this.NotificationController.getUserPendingNotifications().subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.pendingNotifications = response["body"];
          if (this.pendingNotifications > 0) {
            this.ToastService.fireToastWithConfirmation(
              "info",
              this.translate.instant("notifications-new"),
              this.translate.instant("show"),
              this.translate.instant("close")
            ).then((userConfirmation: boolean) => {
              if (userConfirmation) {
                this.NotificationModalService.showNotifications();
              }
            });
          }
        }
      }
    );
  }

  // Actualización de las restricciones de usuario
  updateUserRestrictions(userRestrictionsActive: boolean): void {
    this.UserController.updateRestrictions(userRestrictionsActive).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            userRestrictionsActive
              ? this.translate.instant("user-restrictions-activated")
              : this.translate.instant("user-restrictions-disabled")
          );
        }
      }
    );
  }

  // Actualización de visualización de las sospechas
  updateUserSuspicion(userSuspicionActive: boolean): void {
    this.SessionDataService.sendSuspicionActive(userSuspicionActive);
    this.SessionDataService.sendReloadPanelFlag();
  }
}
