import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appTabInput]",
})
export class TabInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  handleTab(e: KeyboardEvent) {
    if (e.key === "Tab") {
      let target = <HTMLTextAreaElement>e.target;
      //   tab was pressed
      //   get caret position/selection
      let start = target.selectionStart;
      let end = target.selectionEnd;
      // set textarea value to: text before caret + tab + text after caret
      target.value =
        target.value.substring(0, start) + "\t" + target.value.substring(end);
      // put caret at right position again
      target.selectionStart = target.selectionEnd = start + 1;
      // prevent the focus lose
      e.preventDefault();
      return false;
    }
  }
}
