<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "meters-groups" | translate }}</b>
        </h5>

        <!-- Botón nuevo -->
        <app-material-panel-menu
          *ngIf="tableData"
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newAssociation()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'dataManagementAssociation'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="maxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>

    <!-- Gráfica de consumo -->
    <div *ngIf="graphType" class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ graphAssociation.name }}</b>
        </h5>
      </div>

      <div class="panel-body meter-detail-graph">
        <!-- Gráfica -->
        <app-graph-controller
          [highchartsOptions]="highchartsOptions"
          [data]="chartOptions"
          [chartConstructor]="chartConstructor"
          [showCumulative]="showCumulative"
          [showCumulativeTotal]="showCumulative"
          [dateRange]="defaultDateRange"
          (dataRequest)="loadGraphData($event.from, $event.to)"
          [filters]="graphFilters"
          (filterFlag)="updateGraphData($event)"
          [seriesToCheck]="[0]"
        >
        </app-graph-controller>
      </div>
    </div>
  </div>
</section>
