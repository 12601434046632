<!-- Edición de misfire -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 *ngIf="data?.action == 'newGateway'" class="dialog-title">
      {{ "new" | translate }} gateway
    </h5>
    <h5 *ngIf="data?.action == 'changeEntity'" class="dialog-title">
      {{ "change-entity" | translate }}
    </h5>
    <h5 *ngIf="data?.action == 'newEntity'" class="dialog-title">
      {{ "assign-entity" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Gateway nuevo -->
    <div *ngIf="data?.action == 'newGateway'" class="gateway-unnassigned-new">
      <mat-form-field appearance="standard">
        <mat-label> {{ "serial-number" | translate }} </mat-label>
        <input
          matInput
          placeholder="{{ 'serial-number' | translate }} (16 carac.)..."
          maxlength="16"
          minlength="16"
          [(ngModel)]="data.gateway.nroSerie"
          required
        />
      </mat-form-field>
      <!-- Unidad de venta -->
      <mat-form-field appearance="standard">
        <mat-label> {{ "sales-unit" | translate }} </mat-label>
        <input
          matInput
          placeholder="{{ 'sales-unit' | translate }} (12 carac.)..."
          maxlength="12"
          minlength="12"
          [(ngModel)]="data.gateway.unidadVenta"
          required
        />
      </mat-form-field>
      <!-- Amei -->
      <mat-form-field appearance="standard">
        <mat-label> AMEI </mat-label>
        <input
          matInput
          placeholder="AMEI (16 carac.)..."
          maxlength="16"
          minlength="16"
          [(ngModel)]="data.gateway.amei"
          required
        />
      </mat-form-field>
      <!-- Canales -->
      <app-material-select
        [title]="'channels' | translate"
        [noSelection]="true"
        [bindValue]="'value'"
        [bindLabel]="'label'"
        [selected]="preselectedChannel"
        [data]="gatewayChannels"
        (selectedOption)="data.gateway.canales = $event?.value"
      ></app-material-select>
      <!-- Latitud -->
      <mat-form-field appearance="standard">
        <mat-label> {{ "latitude" | translate }} </mat-label>
        <input
          matInput
          type="number"
          step=".01"
          placeholder="{{ 'latitude' | translate }}..."
          [(ngModel)]="data.gateway.latitude"
        />
      </mat-form-field>
      <!-- Longitud -->
      <mat-form-field appearance="standard">
        <mat-label>{{ "longitude" | translate }} </mat-label>
        <input
          matInput
          type="number"
          step=".01"
          placeholder="{{ 'longitude' | translate }}..."
          [(ngModel)]="data.gateway.longitude"
        />
      </mat-form-field>
    </div>

    <!-- Cambio de entidad -->
    <div
      *ngIf="data?.action == 'newEntity' || data?.action == 'changeEntity'"
      class="gateway-unnassigned-new"
    >
      <app-material-select
        [title]="'entity' | translate"
        [noSelection]="true"
        [filter]="true"
        [bindValue]="'id'"
        [bindLabel]="'entity'"
        [selected]="data?.entity"
        [data]="entityList"
        (selectedOption)="selectedEntity = $event?.id"
      ></app-material-select>

      <!-- Tabla de errores -->
      <app-table-controller
        *ngIf="errorList"
        [tableId]="'gatewaysEntityErrors'"
        [onlyTable]="true"
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="20"
        [data]="errorList"
      >
      </app-table-controller>
    </div>
  </div>

  <div class="dialog-footer">
    <!-- Gateway nuevo -->
    <button
      *ngIf="data?.action == 'newGateway'"
      mat-button
      (click)="newGateway()"
      [disabled]="
        data.gateway.nroSerie == null ||
        data.gateway.nroSerie == '' ||
        data.gateway.nroSerie?.length < 16 ||
        data.gateway.unidadVenta == null ||
        data.gateway.unidadVenta == '' ||
        data.gateway.unidadVenta?.length < 12 ||
        data.gateway.amei == null ||
        data.gateway.amei?.length < 16
      "
    >
      {{ "save" | translate }}
    </button>
    <!-- Nueva entidad -->
    <button
      *ngIf="data?.action == 'newEntity' || data?.action == 'changeEntity'"
      mat-button
      (click)="changeEntity()"
    >
      {{ "save" | translate }}
    </button>

    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
