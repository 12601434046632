import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appHexInput]",
})
export class HexInputDirective {
  constructor(private el: ElementRef) {}

  // Control de entrada de teclado hexadecimal
  @HostListener("keypress", ["$event"]) clsAlphaNoOnly(e: any): boolean {
    var regex: RegExp = new RegExp("^[a-fA-F0-9 ]+$");
    var str: string = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
}
