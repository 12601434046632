<!-- Mapa de cambio de localización -->
<div *ngIf="_changeLocationActive && mapDevice" class="panel panel-default">
  <div class="panel-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>
    <h5>
      <b>{{ "change-location" | translate }} </b>
    </h5>
  </div>
  <div class="panel-body">
    <!-- Mapa de contador -->
    <app-map-controller
      [mapType]="mapType"
      [mapHeight]="mapHeight"
      [initialZoom]="initialZoom"
      [disableLegend]="true"
      [disableMeasure]="true"
      (meterNewLocation)="setDeviceNewLocation($event)"
      [disableCluster]="true"
      [searchAdress]="true"
      [hideMenu]="true"
      [gatewaysData]="[]"
      [metersData]="mapDevice"
    >
    </app-map-controller>

    <div class="device-detail-location-buttons">
      <div>
        <!-- Latitud -->
        <mat-form-field
          class="activation-dialog-input-number"
          appearance="standard"
        >
          <mat-label>{{ "latitude" | translate }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="mapDevice[0].latitude"
            step="0.01"
            min="-90"
            max="90"
            placeholder="{{ 'latitude' | translate }}..."
            (change)="updateMap()"
          />
        </mat-form-field>

        <!-- Longitud -->
        <mat-form-field
          class="activation-dialog-input-number"
          appearance="standard"
        >
          <mat-label>{{ "longitude" | translate }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="mapDevice[0].longitude"
            step="0.01"
            min="-180"
            max="180"
            placeholder="{{ 'longitude' | translate }}..."
            (change)="updateMap()"
          />
        </mat-form-field>
        <button
          mat-button
          class="mat-button-icon-text"
          [disabled]="
            mapDevice[0].latitude == null ||
            mapDevice[0].longitude == null ||
            mapDevice[0].latitude > 90 ||
            mapDevice[0].latitude < -90 ||
            mapDevice[0].longitude > 180 ||
            mapDevice[0].longitude < -180
          "
          (click)="changeDevicePosition()"
        >
          <i class="fas fa-map-marker"></i>
          {{ "change-location" | translate }}
        </button>
      </div>
      <div>
        <button mat-button (click)="closeChangeLocation()">
          {{ "close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
